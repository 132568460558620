import React, { Component } from "react";
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import './Interests.css';
import axios from 'axios';
import { config } from '../Config/Config';
import sceem from '../images/sceem-logo.svg';
import moment from "moment-timezone";
import SimpleCrypto from "simple-crypto-js";
import { AiOutlineShareAlt } from "react-icons/ai";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class DetailedAdvertisement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            campaignName: "",
            campaignUrl: "",
            campaignPaymentType: "",
            description: "",
            startDate: "",
            endDate: "",
            createdAt:"",
            imageUrl: "",
            type: ""


        }

    }
    componentDidMount() {
       // console.log("new", this.props.match.params.id)
        this.getCampaignById();
    }
    onClickUrl = () => {
        window.open(this.state.campaignUrl)
    }
    getCampaignById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + `user/getCampaignById/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
             //   console.log(response);
                if (response.data.status === true) {
                  //  console.log("success!!");
                    self.setState({
                        campaignName: response.data.campaign.campaignName,
                        campaignPaymentType: response.data.campaign.campaignPaymentType,
                        campaignUrl: response.data.campaign.campaignUrl,
                        description: response.data.campaign.description,
                        startDate: response.data.campaign.startDate,
                        endDate: response.data.campaign.endDate,
                        createdAt: response.data.campaign.createdAt,
                        imageUrl: response.data.campaign.imageUrl,
                        type: response.data.campaign.type,
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    shareAdv = (d) => {
        if (navigator.share) {
            navigator.share({
                url: `https://www.sceem.org/adv/${d}/${this.props.match.params.id}`,
            }).then(() => {
               // console.log('Thanks for sharing!', d);
            }).catch(err => {
                console.log("Error while using Web share API:",err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
    }
    render() {
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <div>
                    <div style={{marginTop:"2.5rem",textAlign:"right",marginRight:"0.5rem"}}><AiOutlineShareAlt size="20px" onClick={() => this.shareAdv(moment(this.state.createdAt).format("YYYYMMDD"))} /></div>
                    <center><img src={this.state.imageUrl === "uploads/1649942289609advertisement.png" ? config.userUrl + this.state.imageUrl : this.state.imageUrl} className="full-adv" style={{ marginTop:"-0.5rem" }} /></center>
                    {/* <img alt="" src={config.userUrl + this.state.imageUrl} className="full-adv" /> */}
                    <div className="campaign-name">{this.state.campaignName}</div>
                    <p className="terms-sm">{this.state.description}</p>
                    <p className="terms-sm">Start Date: {this.state.startDate.split("T")[0].split("-").reverse().join("-")}</p>
                    <p className="terms-sm">End Date: {this.state.endDate.split("T")[0].split("-").reverse().join("-")}</p>
                    <div className="campaign-url" style={{ wordWrap: "break-word" }}>Url: <span onClick={this.onClickUrl}>{this.state.campaignUrl}</span></div>
                </div>
                <br /><br /><br />

                <BottomMenu />
            </div >
        )
    }
}
