import React, { Component } from "react";
import { ProgressBar, Container, Col, Row } from "react-bootstrap";
import { Link, NavLink } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import pollImg from '../images/polls-1.png';
import './Poll.css';
import BottomMenu from "../BottomMenu/BottomMenu";
import "../LandingPage/arrowsOnBottomOrTop.css";
import "../LandingPage/hideScrollbar.css";
import axios from 'axios';
import { config } from '../Config/Config';
import '../LandingPage/RecommendedAuthors.css';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class HistoryPolls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            pollHistory: [],
            isLoading: true
        }
    }
    componentDidMount() {
        this.getHistoryPolls();
    }
    getHistoryPolls = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getHistoryPolls', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                  //  console.log("success");
                    self.setState({
                        pollHistory: response.data.pollHistory,
                        isLoading: false
                    })
                } else {
                    self.setState({
                        pollHistory: response.data.pollHistory,
                        isLoading: false
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });

    };


    render() {
        const { pollHistory, isLoading } = this.state;
        if (isLoading) {
           // console.debug("renders: Loading...");
            return <div className="App"></div>;
        }
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        const days = pollHistory.map((i, idx) => {
            var eDate = new Date(i.endDate)
            var tDate = new Date()
            const utc2 = Date.UTC(eDate.getFullYear(), eDate.getMonth(), eDate.getDate());
            const utc1 = Date.UTC(tDate.getFullYear(), tDate.getMonth(), tDate.getDate());
            return Math.floor((utc2 - utc1) / _MS_PER_DAY);
        })
      //  console.log("days", days)
        const totalDays = pollHistory.map((i, idx) => {
            var lastDate = new Date(i.endDate)
            var firstDate = new Date(i.startDate)
            const utc2 = Date.UTC(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate());
            const utc1 = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
            return Math.floor((utc2 - utc1) / _MS_PER_DAY);
        })
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <div style={{ marginTop: "6.5rem" }}>
                </div>
                <div className="current-poll-fixed">
                    <div style={{ marginTop: "10px", display: "flex" }}>
                        <NavLink to="/polls/currentpolls" activeClassName="poll-active" className="ml-4 link" >
                            <div className="currentpoll-Text ml-2 mr-2">Current Polls</div></NavLink>
                        <NavLink to="/polls/historypolls" activeClassName="poll-active" className="poll-rec-left link" >
                            <div className="currentpoll-Text ml-2 mr-2">History Polls </div></NavLink>
                    </div >
                </div><br />
                <div className="ml-4 mr-4">
                    {pollHistory.length === 0 ? <center className="mt-4">You have not answered any of the poll</center> :
                        <div>
                            {pollHistory.map((data, idx) => {
                                return (
                                    <Link to={`/pollresults/${data.id}`} style={{ color: "black" }} className="link">
                                        {/* <ProgressBar className="pg-bar" now={100 - ((days[idx] / totalDays[idx]) * 100)} style={{ marginLeft: "14%", width: "79.9%", height: "15px" }} variant={"Bar"} /> */}
                                        <div className="mt-3">
                                            {/* <div className=" history-poll-circle">
                                    </div> */}
                                            <div style={{ width: "90%" }} className=" history-poll-rectangle">
                                            </div>
                                            <Row style={{ width: "100%", marginTop: "-1.5rem" }}>
                                                <Col xs={2}>
                                                    <h2 className="idx">{idx + 1}</h2>
                                                </Col>
                                                <Col xs={6}>
                                                    {data.pollQuestion !== null && data.pollQuestion > data.pollQuestion.substring(0, 40) ?
                                                        <div className="ml-2 poll-ques-size">{data.pollQuestion.substring(0, 40)}...</div> :
                                                        <div className="ml-2 poll-ques-size">{data.pollQuestion}</div>}
                                                    {/* <div className="ml-2" style={{ fontSize: "14px", marginTop: "-2.1rem" }}>{days[idx]} days left</div> */}
                                                </Col>
                                                {/* {console.log("dd", data.topicName.toLowerCase().replace(/ /g, '') + ".png")} */}
                                                <Col xs={4}>
                                                    <img alt="" src={"../" + data.topicName.replace(/ *\([^]*\) */g, "") + ".png"} className="poll-topic-imgs" />
                                                    {/* <div className="days-left">{days[idx]} days left</div> */}
                                                </Col>
                                            </Row>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>}
                </div>
                <center className="bottom-history-text">Showing last 5 polls history</center><br /><br /><br />
                <BottomMenu />
            </div >
        )
    }
}
