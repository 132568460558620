import React, { Component } from "react";
import { Button, Col, Row, FormControl } from "react-bootstrap";
import { Link, NavLink, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import './RecommendedAuthors.css';
import axios from 'axios';
import { config } from '../Config/Config';
import back from '../images/backarrow.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class RecommendedAuthors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            authors: [],
            authorIds: [],
            userFollowedAuthors: [],
            isLoading: true,
            isFirstTime: false,
            redirect: false,
            searchValue: "",
            searchModal: false,
            searchResults: [],
            result: false,
            isScreenLoading: true,
            isSearchLoading: false
        }
        this.onAddingItem = this.onAddingItem.bind(this)
    }
    handleBack = () => {
        this.props.history.goBack()
    }
    onAddingItem = (e, value) => {
        const { authors, searchResults } = this.state
        //console.log("item", value, authors, searchResults)
        // const updated = authors.map(data => (data.uuid === value) ?
        //     { ...data, isAdded: !data.isAdded } : data)
        // console.log("update", updated)
        // const updatedArr = searchResults?.map(data => (data.uuid === value) ?
        //     { ...data, isAdded: !data.isAdded } : data)
        // console.log("updateArr", updatedArr)
        let user = authors.find(id => id.uuid === value)
        let updated = authors
        let updatedSearchResults = searchResults
        if (!user) {
            user = searchResults?.find(id => id.uuid === value)
            updatedSearchResults = searchResults?.filter(id => id.uuid !== value)
            user.isAdded = !user.isAdded
            updatedSearchResults = [...updatedSearchResults, user]
        } else {
            updated = authors?.filter(id => id.uuid !== value)
            user.isAdded = !user.isAdded
            updated = [...updated, user]
        }
        this.setState({ authors: [...updated], searchResults: [...updatedSearchResults], isLoading: false })
    }
    componentDidMount = () => {
        this.getRecommendedAuthors();
        this.checkUserStatus();
    }
    onSearchChangeHandler = (e) => {
        //console.log("length", e.target.value)
        this.search(e.target.value);
        this.setState({ searchValue: e.target.value });
      //  console.log("a", e.target.value.length)
    };
    checkUserStatus = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/checkUserStatus', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    if (response.data.userStatus === "topicsAdded") {
                        self.setState({ isFirstTime: true })
                    } else {
                        self.setState({ isFirstTime: false })
                    }
                }
                else {
                   // console.log("checkerror", response.data.message)
                    document.getElementById('status').innerText = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                }
            })
            .catch(function (error) {
                console.log("error");
            });
    };
    // getAuthors = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + this.state.token
    //     }
    //     var self = this
    //     axios.get(config.userUrl + 'user/getAuthors', { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status === true) {
    //                 self.setState({
    //                     authors: response.data.authors,
    //                 })
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error");
    //         });
    // };
    getRecommendedAuthors = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAuthorRecommendation', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        authors: response.data.authors,
                        isScreenLoading: false
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getUserFollowedAuthors = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getUserFollowedAuthors', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        userFollowedAuthors: response.data.userFollowedPeople
                    })
                   // console.log("userFollowedPeople", response.data.userFollowedPeople)
                    if (response.data.userFollowedPeople.length === 0) {
                       // console.log("FIRSTTIME USER")
                        self.setState({
                            isFirstTime: true
                        })
                    } else {
                        console.log("USER")
                    }
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    followAuthors = (newUser) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        const { authors, searchResults } = this.state;
        let userArr = [...authors, ...searchResults];
        let authorId = [];
        authorId = userArr.filter(item => (item.isAdded === true) ? item.uuid : null)
        //console.log("authorId", authorId)
        var payload = {
            "authorIds": authorId.map(item => item.uuid),
            "isFirstTime": this.state.isFirstTime
        }
        var self = this
        const exp = new Date()
        if (newUser) {
            var newUserArr = [];
            for (var i = 0; i < authorId.length; i++) {
                newUserArr[i] = ({ uuid: authorId[i].uuid, profilePicture: authorId[i].profilePicture, name: authorId[i].name, isAdded: !(authorId[i].isAdded) })
            }
            var item = {
                "latestUserFollowedAuthors": JSON.stringify(newUserArr),
                "expiry": exp.getTime() + 60 * 60 * 100
            }
           // console.log("new", item)
            localStorage.setItem("getUserFollowedAuthors", JSON.stringify(item))
        }
        axios.post(config.userUrl + 'user/followAuthors', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                  //  console.log("success", payload);
                    var localArr = localStorage.getItem("getUserFollowedAuthors")
                    var newLocalArr = JSON.parse(localArr)
                    var newAuthorArr = JSON.parse(newLocalArr.latestUserFollowedAuthors)
                    var authArr = [];
                    for (var i = 0; i < authorId.length; i++) {
                        authArr[i] = ({ uuid: authorId[i].uuid, profilePicture: authorId[i].profilePicture, name: authorId[i].name, isAdded: !(authorId[i].isAdded) })
                    }
                  //  console.log("newLocalArr", authorId, newLocalArr, authArr)
                    var userFollowedArr = newAuthorArr.concat(authArr)
                  //  console.log("userArr", userFollowedArr)
                    var authorArray = userFollowedArr.filter((e, i, a) => a.findIndex(e2 => (e2.name === e.name)) === i)
                  //  console.log("userFollowedArr", userFollowedArr, authorArray)
                    var item = {
                        "latestUserFollowedAuthors": JSON.stringify(authorArray),
                        "expiry": exp.getTime() + 60 * 60 * 100
                    }
                    //console.log("new", item)
                    localStorage.setItem("getUserFollowedAuthors", JSON.stringify(item))

                    self.setState({
                        redirect: true
                    })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    search = async val => {
        this.setState({ isSearchLoading: true })
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this;
        axios.get(config.userUrl + `user/searchActiveUsers?search=${val}`, { headers: headers })
            .then(function (response) {
               // console.log("searchActiveUsers", response);
                if (response.data.status === true) {
                   // console.log("searchActiveUsers retrieved successfull", response.data);
                   // console.log("length1", response.data.searchUser)
                    let filteredData;
                    if (self.state.searchValue.length !== 0 && response.data.searchUser.length > 0) {
                        let userFollowers = JSON.parse(localStorage.getItem("getUserFollowedAuthors"))
                       // console.log("userFollowers", userFollowers)
                        let userFollowedArr = userFollowers ? JSON.parse(userFollowers.latestUserFollowedAuthors) : []
                       // console.log("userFollowedArr", userFollowedArr,response.data.searchUser.length)
                         filteredData = response.data.searchUser.filter((item) => {
                            if (self.state.authors.find((data) => data.uuid === item.uuid)) {
                                return
                            } else return item
                        }).filter(data => !userFollowedArr.find(item => item.uuid === data.uuid))
                        self.setState({ searchResults: filteredData, searchModal: true, result: false })
                     //   console.log("filteredData", filteredData, self.state.userFollowedAuthors, self.state.result)
                    } else {
                        self.setState({ searchResults: [], searchModal: false,result: true })
                      //  console.log("Rrr", self.state.result,filteredData.length, response.data.searchUser.length )
                    }
                    if (filteredData.length === 0 || response.data.searchUser.length === 0 ) {
                        self.setState({ searchResults: [], searchModal: false, result: true })
                      //  console.log("length",filteredData, response.data.searchUser.length, self.state.result)
                    } 
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });

        self.setState({ isSearchLoading: false })
    };
    closeSearch = (e) => {
      //  console.log("search")
        this.setState({ searchValue: "", searchModal: false,result:false })
    }
    render() {
        const { redirect, searchResults, result, searchModal, searchValue, userFollowedAuthors, isFirstTime, authors, isScreenLoading, isLoading } = this.state
       // console.log("followed", userFollowedAuthors)
        if (isScreenLoading) {
         //   console.debug("renders: Loading...");
            return <div className="App"></div>;
        }

        let authorId = [];
        authorId = authors.filter(item => (item.isAdded === true) ? item.uuid : null)
        let searchResultsArr = [];
        searchResultsArr = searchResults?.filter(item => (item.isAdded === true) ? item.uuid : null)
        function checkUserFollowedAuthors(checkItem) {
            if (!isFirstTime) {
                return (userFollowedAuthors?.filter(item => item.uuid == checkItem.uuid).length != 0)
            }
        }
        if (isLoading) {
            // console.log("isLoading")
            authors.forEach(item => checkUserFollowedAuthors(item) ? item.isAdded = true : item.isAdded = false)
            searchResults?.forEach(item => checkUserFollowedAuthors(item) ? item.isAdded = true : item.isAdded = false)
        }
        if (redirect) {
            return <Redirect to="/authors/following" />
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
                <div style={{ marginTop: "6.5rem" }}>
                </div>
                <div className="recommended-fixed">
                    {/* <img alt="" src={back} className="landing-Image" onClick={this.handleBack} /><br /><br /> */}
                    <div style={{ marginTop: "13px", display: "flex", marginLeft: "15px" }}>
                        <NavLink to="/authors/recommended" activeClassName="profile-active" className="ml-3 link" >
                            <div className="followers-Text ml-1 mr-1">Recommended </div></NavLink>
                        {isFirstTime ? null :
                            <NavLink to="/authors/following" activeClassName="profile-active" className="rec-left link" >
                                <div className="followers-Text ml-1 mr-1">Following</div></NavLink>}

                    </div>
                </div>
                <div className="ml-2 mr-2"> <FormControl
                    type="text"
                    placeholder="Search"
                    value={searchValue}
                    onChange={e => this.onSearchChangeHandler(e)}
                    id="search" className="abc form-control search_box"
                    aria-label="Search keyword"
                /></div>
                {result == false ? "" : <div className="error ml-2" style={{ marginTop: "-4px" }} onKeyUp={e => this.closeSearch(e)}>No Results found</div>}
                {authors.length == 0 ? <center className="mt-4">No Recommended authors</center> :
                    <div>
                        {authors.map((data, i) => (
                            <div>
                                <Row style={{ width: "100%", marginRight: "2px", marginLeft: "8px" }}>
                                    <Col xs={3}>
                                        <Link to={{
                                            pathname: `/getprofilebyid/${data.name}`,
                                            state: {
                                                id: data.uuid
                                            }
                                        }} style={{ cursor: "pointer", color: "black" }} className="border-underline">
                                            <img alt="" src={config.userUrl + data.profilePicture} className="ml-2 rec-profile" />
                                        </Link>
                                    </Col>
                                    <Col xs={5}>
                                        <Link to={{
                                            pathname: `/getprofilebyid/${data.name}`,
                                            state: {
                                                id: data.uuid
                                            }
                                        }} style={{ cursor: "pointer", color: "black" }} className="border-underline">
                                            <div className="rec-name">{data.name}</div>
                                            <div className="rec-follow">{data.followersCount} followers </div>
                                        </Link>
                                    </Col>
                                    <Col xs={1}></Col>
                                    <Col xs={3} key={i} value={data.uuid}
                                        onClick={(e) => this.onAddingItem(e, data.uuid)}>{data.isAdded ?
                                            <i class="fa fa-minus-circle fa-2x plus" aria-hidden="true"></i> :
                                            <i class="fa fa-plus-circle fa-2x plus" aria-hidden="true"></i>}
                                    </Col>
                                </Row><hr className="ml-3 mr-3" />
                            </div>

                        ))}

                    </div>}
                {searchModal ?
                    <div>
                        <div>
                            {searchResults.slice(0, 5).map((data, i) => (
                                <div>
                                    <Row style={{ width: "100%", marginRight: "2px", marginLeft: "8px" }}>
                                        <Col xs={3}>
                                            <Link to={{
                                                pathname: `/getprofilebyid/${data.name}`,
                                                state: {
                                                    id: data.uuid
                                                }
                                            }} style={{ cursor: "pointer", color: "black" }} className="border-underline">
                                                <img alt="" src={config.userUrl + data.profilePicture} className="ml-2 rec-profile" />
                                            </Link>
                                        </Col>
                                        <Col xs={5}>
                                            <Link to={{
                                                pathname: `/getprofilebyid/${data.name}`,
                                                state: {
                                                    id: data.uuid
                                                }
                                            }} style={{ cursor: "pointer", color: "black" }} className="border-underline">
                                                <div className="rec-name">{data.name}</div>
                                                <div className="rec-follow">{data.followerscount} followers</div>
                                            </Link>
                                        </Col>
                                        <Col xs={1}></Col>
                                        <Col xs={3} key={i} value={data.uuid}
                                            onClick={(e) => this.onAddingItem(e, data.uuid)}>{data.isAdded ?
                                                <i class="fa fa-minus-circle fa-2x plus" aria-hidden="true"></i> :
                                                <i class="fa fa-plus-circle fa-2x plus" aria-hidden="true"></i>}
                                        </Col>
                                    </Row><hr className="ml-3 mr-3" />
                                </div>
                            ))}
                        </div>
                    </div>
                    : ""}
                {isFirstTime ?
                    <Button variant="default" onClick={() => this.followAuthors(true)} className="landing-btn float">Next</Button>
                    :
                    <div>
                        <Button variant="default" onClick={() => this.followAuthors(false)} className="landing-btn float">Save</Button>
                        <BottomMenu />
                    </div>
                }
            </div>
        )
    }
}

// {authors.length !== 0 ?
//     <div>
//         {authors.map((data, i) => (
//             <div>
//                 <Row style={{ width: "100%", marginRight: "2px" }}>
//                     <Col xs={3}>
//                         <img alt="" src={data.profilePicture} className="ml-2 rec-profile" />
//                     </Col>
//                     <Col xs={5}>
//                         <div className="rec-name">{data.name}</div>
//                         <div className="rec-follow">{data.followersCount} followers / {data.likesCount} likes</div>
//                     </Col>
//                     <Col xs={1}></Col>
//                     <Col xs={3} key={i} value={data.uuid}
//                         onClick={(e) => this.onAddingItem(e, data.uuid)}>{data.isAdded ?
//                             <i class="fa fa-plus-circle fa-2x plus" aria-hidden="true"></i> :
//                             <i class="fa fa-minus-circle fa-2x plus" aria-hidden="true"></i>}
//                     </Col>
//                 </Row><hr className="ml-3 mr-3" />
//             </div>

//         ))}
//         <div>

//             <Button variant="default" onClick={this.followAuthors} className="landing-btn float">Save</Button>

//             <BottomMenu />
//         </div>
//     </div> : <div>No Recommended Authors
//         <BottomMenu /></div>}
