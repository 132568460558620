import React, { Component } from "react";
import { Button, Form, Col, Card, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import circle from '../images/circle.png';
import circleblue from '../images/circle-blue.png';
import google from '../images/google.png';
import facebook from '../images/facebook.png';
import back from '../images/backarrow.png';
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validEmailRegex =
    /^[a-z0-9+_.-]+@[a-z0-9.-]+$/
const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/
const validPhoneNoRegex = RegExp(
    /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/i
);
// const validateForm = errors => {
//     let valid = true;
//     Object.values(errors).forEach(val => val.length > 0 && (valid = false));
//     return valid;
// };
export default class CreateOpportunity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            typeOfOpportunity: "",
            type: "",
            company: "",
            location: "",
            tenure: "",
            url: "",
            vacancies: "",
            applyByDate: "",
            remuneration: "",
            nameOfTheContactPerson: "",
            phoneNumber: "",
            briefJobDescription: "",
            typeOfOpportunityError: '',
            typeError: '',
            companyError: '',
            locationError: '',
            tenureError: '',
            vacanciesError: '',
            remunerationError: '',
            briefJobDescriptionError: '',
            applyByDateError: '',
            home: false,
            error: "Please select the details"
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.validateTypeOfOpportunity = this.validateTypeOfOpportunity.bind(this);
        this.validateType = this.validateType.bind(this);
        this.validateCompany = this.validateCompany.bind(this);
        this.validateLocation = this.validateLocation.bind(this);
        this.validateTenure = this.validateTenure.bind(this);
        this.validateVacancies = this.validateVacancies.bind(this);
        this.validateRemuneration = this.validateRemuneration.bind(this);
        this.validateApplyByDate = this.validateApplyByDate.bind(this);
        this.validateBriefJobDescription = this.validateBriefJobDescription.bind(this);
        this.validateField = this.validateField.bind(this);
    }
    onChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }
    // componentDidMount() {
    //     this.getOpportunityDetails();
    // }

    handleBlur(event) {
        const { name } = event.target;
        this.validateField(name);
        return;
    }

    handleSubmit(event) {
        event.preventDefault();
        //console.log(this.state);
        let formFileds = [
            "typeOfOpportunity",
            "type",
            "company",
            "location",
            "tenure",
            "url",
            "remuneration",
            "nameOfTheContactPerson",
            "phoneNumber",
            "briefJobDescription",
            "startDate",
            "endDate",

        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = this.validateField(field) && isValid;
        });
        this.saveOpportunityDetails()
    }
    getOpportunityDetails = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getSavedOpportunityDetails', { headers: headers })
            .then(function (response) {
                if (response.data === true) {
                    //  console.log(response, "opportunity created successfully!!")
                }
            })
            .catch(function (error) {
                console.log(error);
                document.getElementById('error').innerText = error
            });
    }
    saveOpportunityDetails = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "typeOfOpportunity": this.state.typeOfOpportunity,
            "type": parseInt(this.state.type),
            "company": this.state.company,
            "vacancies": this.state.vacancies,
            "location": this.state.location,
            "tenure": this.state.tenure,
            "url": this.state.url,
            "remuneration": this.state.remuneration,
            "nameOfTheContactPerson": this.state.nameOfTheContactPerson,
            "phoneNumber": this.state.phoneNumber,
            "briefJobDescription": this.state.briefJobDescription,
            "applyByDate": this.state.applyByDate
        }
        var self = this
        axios.post(config.userUrl + 'user/saveOpportunityDetails ', payload, { headers: headers })
            .then(function (response) {
                // console.log("response", response)
                if (response.data.status === true) {
                    //  console.log(response, "opportunity created successfully!!")
                    self.setState({ home: true })
                }
            })
            .catch(function (error) {
                console.log("payload", payload, error)
                document.getElementById('error').innerText = error
            });
    }
    validateField(name) {
        let isValid = false;
        if (name === "typeOfOpportunity") isValid = this.validateTypeOfOpportunity();
        else if (name === "type") isValid = this.validateType();
        else if (name === "company") isValid = this.validateCompany();
        else if (name === "location") isValid = this.validateLocation();
        else if (name === "tenure") isValid = this.validateTenure();
        else if (name === "remuneration") isValid = this.validateRemuneration();
        else if (name === "briefJobDescription") isValid = this.validateBriefJobDescription();
        else if (name === "vacancies") isValid = this.validateVacancies();
        else if (name === "applyByDate") isValid = this.validateApplyByDate();
        return isValid;
    }

    validateTypeOfOpportunity() {
        let typeOfOpportunityError = "";
        const value = this.state.typeOfOpportunity;
        if (value.trim() === "") typeOfOpportunityError = "select type of opportunity";
        this.setState({
            typeOfOpportunityError
        });
        return typeOfOpportunityError === "";
    }
    validateType() {
        let typeError = "";
        const value = this.state.type;
        if (value.trim === "") typeError = "Please select type";
        this.setState({
            typeError
        });
        return typeError === "";
    }
    validateCompany() {
        let companyError = "";
        const value = this.state.company;
        if (value.trim() === "") companyError = "Please add the company";
        this.setState({
            companyError
        });
        return companyError === "";
    }
    validateLocation() {
        let locationError = "";
        const value = this.state.location;
        if (value.trim() === "") locationError = "Please add location";
        this.setState({
            locationError
        });
        return locationError === "";
    }
    validateTenure() {
        let tenureError = "";
        const value = this.state.tenure;
        if (value.trim() === "") tenureError = "Please add tenure";
        this.setState({
            tenureError
        });
        return tenureError === "";
    }
    // validateUrl() {
    //     let urlError = "";
    //     const value = this.state.url;
    //     if (value.trim() === "") urlError = "Please add url";
    //     this.setState({
    //         urlError
    //     });
    //     return urlError === "";
    // }
    validateRemuneration() {
        let remunerationError = "";
        const value = this.state.remuneration;
        if (value.trim() === "") remunerationError = "Please add remuneration";
        this.setState({
            remunerationError
        });
        return remunerationError === "";
    }
    validateVacancies() {
        let vacanciesError = "";
        const value = this.state.vacancies;
        if (value.trim() === "") vacanciesError = "Please add vacancies";
        this.setState({
            vacanciesError
        });
        return vacanciesError === "";
    }
    validateBriefJobDescription() {
        let briefJobDescriptionError = "";
        const value = this.state.briefJobDescription;
        if (value.trim() === "") briefJobDescriptionError = "Please add Brief JobDescription";
        this.setState({
            briefJobDescriptionError
        });
        return briefJobDescriptionError === "";
    }
    validateApplyByDate() {
        let applyByDateError = "";
        const value = this.state.applyByDate;
        if (value.trim === null) applyByDateError = "Please enter valid date";
        this.setState({
            applyByDateError
        });
        return applyByDateError === "";
    }
    render() {
        const { home } = this.state
        if (home) {
            return <Redirect to="/home" />
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
                <Form onSubmit={this.handleSubmit} style={{ marginTop: "5rem" }}>
                    <Link to="/home"><img alt="" src={back} className="back_arrow_" /></Link><div className="your-fav ml-5" style={{position:"fixed",top:"3.4vh"}}> <div className="screen-topgap ml-4">Create an Opportunity</div></div>
                    <div className="card-opp-top"></div>
                 <div style={{ marginLeft: "5%", marginRight: "5%", width: "90%" }} >
                        <Card className="createopportunity-card"> <div style={{marginLeft: "3%", marginRight: "3%" }} > <div className="form-group mt-2">
                            <input type="text"
                                className="form-control form-bg-opp"
                                aria-describedby="text"
                                value={this.state.company}
                                name="company"
                                placeholder="Organization Name"
                                onChange={this.onChange}
                                onBlur={this.handleBlur}
                            />
                            <div className="div">{this.state.companyError && (
                                <div className="error">{this.state.companyError}</div>
                            )}</div>
                        </div>

                            <div className="form-group mt-3">
                                <input type="text"
                                    className="form-control form-bg-opp"
                                    aria-describedby="text"
                                    value={this.state.url}
                                    name="url"
                                    placeholder="Organization Url"
                                    onChange={this.onChange}
                                    onBlur={this.handleBlur}
                                />
                            </div>
                        </div>
                        </Card>
                        <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                            <div className="form-group mt-2">
                                <div className="form-check form-check-inline mt-2 form_check" style={{ display: "flex" }}>
                                    <input name="typeOfOpportunity" className="form-check" type="radio" value="Job" checked={this.state.typeOfOpportunity === "Job"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        Job
                                    </label>
                                    <input name="typeOfOpportunity" className="ml-3  form-check" type="radio" value="Internship" checked={this.state.typeOfOpportunity === "Internship"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        Internship
                                    </label>
                                    <input name="typeOfOpportunity" className="ml-3 form-check" type="radio" value="Consultant" checked={this.state.typeOfOpportunity === "Consultant"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        Consultant
                                    </label>
                                </div>
                                <div className="form-check form-check-inline form_check mt-2" style={{ display: "flex" }}>
                                    <input name="tenure" className="form-check" type="radio" value="Less than a month" checked={this.state.tenure === "Less than a month"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        1 M
                                    </label>
                                    <input name="tenure" className="ml-2 form-check" type="radio" value="3 Months" checked={this.state.tenure === "3 Months"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        3 M
                                    </label>
                                    <input name="tenure" className="ml-2 form-check" type="radio" value="6 Months" checked={this.state.tenure === "6 Months"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        6 M
                                    </label>
                                    <input name="tenure" className="ml-2 form-check" type="radio" value="9 Months" checked={this.state.tenure === "9 Months"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        9 M
                                    </label>
                                    <input name="tenure" className="ml-2 form-check" type="radio" value="Permanent" checked={this.state.tenure === "Permanent"} disabled={this.state.typeOfOpportunity === "Internship" || this.state.typeOfOpportunity === "Consultant"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        Permanent
                                    </label>
                                </div>
                                <div className="form-check form-check-inline form_check mt-2" style={{ display: "flex" }}>
                                    <input name="type" className="form-check" type="radio" value="1" checked={this.state.type === "1"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        Part-time
                                    </label>
                                    <input name="type" className="ml-3 form-check" type="radio" value="2" checked={this.state.type === "2"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        Full-time
                                    </label>
                                </div>
                                {((this.state.typeOfOpportunity.length === null) || (this.state.type.length === 0) || (this.state.tenure.length === 0)) ?
                                    <div className="div error">{this.state.error}</div> : <div></div>}
                                <Row style={{ width: "100%" }} className="mt-3">
                                    <Col xs={6}>
                                        <div className="form-group">
                                            <input type="number"
                                                style={{ width: "118%" }}
                                                className="form-control form-bg-opp"
                                                aria-describedby="text"
                                                value={this.state.vacancies}
                                                name="vacancies"
                                                placeholder="Vacancies"
                                                onChange={this.onChange}
                                                onBlur={this.handleBlur}
                                            />
                                            <div className="div">{this.state.vacanciesError && (
                                                <div className="error">{this.state.vacanciesError}</div>
                                            )}</div>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="form-group">
                                            <input type="text"
                                                style={{ width: "118%" }}
                                                className="ml-1 form-control form-bg-opp"
                                                aria-describedby="text"
                                                value={this.state.location}
                                                name="location"
                                                placeholder="Location"
                                                onChange={this.onChange}
                                                onBlur={this.handleBlur}
                                            />
                                            <div className="div">{this.state.locationError && (
                                                <div className="error">{this.state.locationError}</div>
                                            )}</div>

                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%" }}>
                                    <Col xs={6}>
                                        <input type="text"
                                            style={{ width: "118%" }}
                                            className="form-control form-bg-opp"
                                            aria-describedby="text"
                                            value={this.state.remuneration}
                                            name="remuneration"
                                            placeholder="Remuneration (INR)"
                                            onChange={this.onChange}
                                            onBlur={this.handleBlur}
                                        />
                                        <div className="div">{this.state.remunerationError && (
                                            <div className="error">{this.state.remunerationError}</div>
                                        )}</div>
                                    </Col>
                                    <Col xs={6}>
                                        {/* <input type="date"
                                            style={{ width: "118%" }}
                                            className="ml-1 form-control form-bg-opp"
                                            aria-describedby="text"
                                            value={this.state.applyByDate}
                                            name="applyByDate"
                                            onChange={this.onChange}
                                            onBlur={this.handleBlur}
                                        /> */}
                                        <DatePicker
                                            className="ml-1 form-control form-bg-opp-date"
                                            dateFormat="dd/MM/yyyy"
                                            selected={this.state.applyByDate}
                                            minDate={new Date()}
                                            onChange={(date) => this.setState({ applyByDate: date })}
                                            placeholderText="Last date to apply"
                                        />
                                        <div className="div">{this.state.applyByDateError && (
                                            <div className="error">{this.state.applyByDateError}</div>
                                        )}</div>
                                    </Col>
                                </Row>
                                <div className="form-group mt-3">
                                    <textarea rows={2} type="text"
                                        className="form-control"
                                        style={{ resize: "none", fontSize: "12px" }}
                                        value={this.state.briefJobDescription}
                                        name="briefJobDescription"
                                        placeholder="Brief Job Description"
                                        onChange={this.onChange}
                                        onBlur={this.handleBlur}
                                    />
                                    <div className="div">{this.state.briefJobDescriptionError && (
                                        <div className="error">{this.state.briefJobDescriptionError}</div>
                                    )}</div>
                                </div>
                            </div>
                        </div>
                        </Card >
                        <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                            <Row style={{ width: "100%" }}>
                                <Col xs={6}>
                                    <div className="form-group mt-2">
                                        <input type="text"
                                            style={{ width: "118%" }}
                                            className="form-control form-bg-opp"
                                            aria-describedby="text"
                                            value={this.state.nameOfTheContactPerson}
                                            name="nameOfTheContactPerson"
                                            placeholder="Contact person"
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className="form-group mt-2">
                                        <input type="number"
                                            style={{ width: "118%" }}
                                            className="ml-1 form-control form-bg-opp"
                                            aria-describedby="text"
                                            value={this.state.phoneNumber}
                                            name="phoneNumber"
                                            placeholder="Contact person phone"
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        </Card>

                    </div >
                    {/* <div className="ml-4"><input type="checkbox" checked={this.state.checkboxToshowDetails} onClick={this.handleCheck} /> <span className="signup-agree-text"> &nbsp;Show contact details</span></div> */}
                    <center>
                        {/* <div id="status" className="errormsg"></div> */}
                        {/* <div id="error" className="errormsg"></div> */}
                        <Button variant="default" type="submit" className="btn-block opportunity-btn mt-4">Create</Button><br />
                    </center>
                </Form >
            </div >
        );
    }
}
