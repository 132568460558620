import React, { useState, useEffect } from "react";
import { Button, Navbar, Carousel, Nav, Form, Container, Card, Col, Row, Image } from "react-bootstrap";
import './LandingPage.css';
import games from '../images/games-u.svg';
import rewards from '../images/rewards-u.svg';
import qna from '../images/qna-u.svg';
// import quizzes from '../images/quiz-services.svg';
// import networking from '../images/networking-services.svg';
import networking from '../images/networking-u.svg';
import quizzes from '../images/quiz-u.svg';
import adds from '../images/add-u.svg';
import opportunity from '../images/opportunity-u.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import Loading from "../Loading/Loading";
import { Link } from 'react-router-dom';
import intro from '../images/sceem intro.svg';
import features from '../images/sceem features.svg';
import preferenceImg from '../images/sceem preferences.svg';
import legalaudit from '../images/Legal and Audit.svg';
import people from '../images/People.svg';
import globaltrends from '../images/Global trends.svg';
import governance from '../images/Governance.svg';
import leadership from '../images/Leadership_new.svg';
import fullfilment from '../images/Fullfilment.svg';
import gogreen from '../images/Go Green.svg';
import industry from '../images/Industry 4.0.svg';
import customercentric from '../images/Customer centric_new.svg';
import Login from "../Login/Login";
import topicsCovered from '../images/topics covered.png';
import featuresS from '../images/features.png';
import chain from '../images/4E Chain.svg';

export default function LandingPage() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => { setIsLoading(false) }, 4000);
    }, []);
    if (isLoading) {
        return <Loading />
    }
    return (
        <div>
            {!localStorage.getItem("junkStore") ?
                <div style={{ fontFamily: "Noto Sans" }}>
                    <Carousel variant="dark" controls={false} pause="hover" >
                        <Carousel.Item variant="dark" interval={3000} style={{ height: "92vh" }}>
                            <div>
                                <div className="mt-2 ml-2 mr-2">     <img src="sceem intro.svg" alt="" style={{ width: "100%" }} /></div>
                                <br /><br/>
                                <div className="l-page-top">Strategic 4E Platform</div>
                                <div className="mt-4 l-page-text">Connecting Enterprises, Experts, Enthusiasts, Entrepreneurs in one "Knowledge Chain"</div>
                                {/* <div className="mt-4 l-page-top">Creating Knowledge Chain</div>
                                    <div className="mt-2 l-page-text"><b>Supply Chain 4Es Platform (sceem)</b> is for <b>E</b>nterprises, <b>E</b>ntrepreneurs, <b>E</b>xperts, <b>E</b>nthusiasts. It is to create a knowledge chain on latest trends, technology innovations and business advancements
                                        under one roof of supply chain economy.</div> */}
                                {/* <center className="mt-2"><img src="4E Chain.png" alt="" style={{ width: "90%" }} /></center> */}

                                {/* <br /><br /><br /><br /><br /><br /> */}
                            </div>
                        </Carousel.Item>
                        <Carousel.Item variant="dark" interval={3000} style={{ height: "92vh" }}>
                            <div className="features-vh">
                                <div>
                                    <div className="ml-2 mr-2">  <img src={features} alt="" style={{ width: "100%" }} /></div>
                                    <br /><br/>
                                    <div className="mt-2"></div>
                                    <div className="l-page-top">Best Features</div>
                                    <div className="mt-4 l-page-text">Build your network, share knowledge, opportunities, play interesting games, get rewarded and recognised.</div>

                                    {/* <div className="l-page-top">Features</div>
                                    <center className="mt-2"><img src={featuresS} alt="" style={{ width: "90%" }} /></center> */}
                                    {/* <center >
                                        <div className="flip-lr">
                                            <Row style={{ width: "100%" }} className="mt-1 no-gutters">
                                                <Col md={3} xs={3}>
                                                    <center className="team-role">

                                                        <img src={networking} className="offer-each-icon" />

                                                        <div className="mt-1 offer-text">Networking</div>
                                                    </center>
                                                </Col>

                                                <Col md={3} xs={3}>
                                                    <center className="team-role">
                                                        <img src={quizzes} className="offer-each-icon" />

                                                        <div className="mt-1 offer-text">Polls</div>
                                                    </center>
                                                </Col>
                                                <Col md={3} xs={3}>

                                                    <center className="team-role">
                                                        <img src={opportunity} className="offer-each-icon" />

                                                        <div className="mt-1 offer-text">Opportunities</div>
                                                    </center>

                                                </Col>
                                                <Col md={3} xs={3}>
                                                    <center className="team-role">
                                                        <img src={games} className="offer-each-icon" />

                                                        <div className="mt-1 offer-text">Games</div>
                                                    </center>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="flip-lr-2">
                                            <Row style={{ width: "100%" }} className="mt-1 no-gutters">
                                                <Col md={4} xs={4}>
                                                    <center className="team-role">
                                                        <img src={qna} className="offer-each-icon" />

                                                        <div className="mt-1 offer-text">Ask an Expert</div>
                                                    </center>
                                                </Col>
                                                <Col md={4} xs={4}>
                                                    <center className="team-role">
                                                        <img src={adds} className="offer-each-icon" />

                                                        <div className="mt-1 offer-text">Advertisements</div>
                                                    </center>
                                                </Col>
                                                <Col md={4} xs={4}>
                                                    <center className="team-role">
                                                        <img src={rewards} className="offer-each-icon" />

                                                        <div className="mt-1 offer-text">Rewards</div>
                                                    </center>
                                                </Col>
                                            </Row>
                                        </div>
                                    </center> */}
                                    {/* <div className="mt-2"></div>
                                    <br /><br /><br /> */}
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item variant="dark" interval={3000} style={{ height: "92vh" }}>
                            <div className="ml-2 mr-2" >
                                <div>
                                    <center className="mt-2">     <img src={preferenceImg} alt="" style={{ width: "100%" }} /></center>
                                    <br /><br/><br/>
                                    <div className="mt-3"></div>
                                    <div className="l-page-top"><b>Nurture your Interests</b></div>
                                    <div className="mt-4 l-page-text">Choose from wide range of topics Go green, Industrial 4.0, Cybersecurity, Governance, People and Customer Centric.</div>

                                    {/* <div className="mt-4 l-page-top">Topics of Interest</div>
                                    <center className="mt-4"><img src={topicsCovered} alt="" style={{ width: "98%" }} /></center> */}
                                    {/* <center>
                                        <div className="mt-2 row no-gutters">
                                            <Col xs={4}>
                                                <div>
                                                    <img src={industry} className="topic-each-icon" /><br />
                                                    <span className="games-Text">Industry 4.0</span>

                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                <div>
                                                    <img src={gogreen} className="topic-each-icon" /><br />
                                                    <span className="games-Text">Go Green</span>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                <div>
                                                    <img src={leadership} className="mt-2 topic-each-icon" /><br />
                                                    <span className="games-Text">Leadership</span>
                                                </div>
                                            </Col>
                                        </div>
                                        <div className="row no-gutters">
                                            <Col xs={4}>
                                                <div><img src={fullfilment} className="topic-each-icon" /><br />
                                                    <span className="games-Text">Fullfilment</span>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                <div>
                                                    <img src={legalaudit} className="topic-each-icon" /><br />
                                                    <span className="games-Text">Legal Audit</span>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                <div><img src={governance} className="topic-each-icon" /><br />
                                                    <span className="games-Text">Governance</span>
                                                </div>
                                            </Col>
                                        </div>
                                        <div className="row no-gutters">
                                            <Col xs={4}>
                                                <div><img src={people} className="mt-3 topic-each-icon" /><br />
                                                    <span className="games-Text">People</span>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                <div><img src={globaltrends} className="topic-each-icon" /><br />
                                                    <span className="games-Text">Global Trends</span>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                <div><img src={customercentric} className="topic-each-icon" /><br />
                                                    <span className="games-Text">Customer Centric</span>
                                                </div>
                                            </Col>
                                        </div>
                                    </center> */}
                                </div>
                            </div>
                            {/* <div className="mt-2"></div>
                            <br /> */}

                        </Carousel.Item>
                    </Carousel>
                    <Link to="/login"><div style={{ position: "fixed", bottom: "0.5rem", fontFamily: "Noto Sans", fontSize: "18px", fontWeight: 600, right: "5%", color: "#31197C" }}>Skip</div></Link>



                </div> :
                <Login />

            }
        </div>
    )
}