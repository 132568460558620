import React, { Component } from "react";
import { Button, Card, Row, Col, Carousel, Modal, Container, CarouselItem } from "react-bootstrap";
import { Link, withRouter } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import sceemlogo from '../images/sceem-logo.svg';
import announcement from '../images/announcement.svg';
import contact from '../images/contactmanagement.png';
import moneybag from '../images/rewards-cup.svg';
import compliance from '../images/compliance-g.png';
import newmembers from '../images/new member.png';
import { GiGamepadCross } from "react-icons/gi";
import './Interests.css';
import moment from 'moment-timezone';
import polls from '../images/polls-new.svg';
import opportunity from '../images/opportunity.png';
import book from '../images/term-new.svg';
import games from '../images/games-new.svg';
import authorPublished from '../images/author-published.svg';
import authorImg from '../images/author2.svg';
// import polls from '../images/Poll.png';
// import opportunity from '../images/opportunity.png';
// import book from '../images/termbook.svg';
import BottomMenu from "../BottomMenu/BottomMenu";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "./arrowsOnBottomOrTop.css";
import "./hideScrollbar.css";
import axios from 'axios';
import { config } from '../Config/Config';
import sceem from '../images/sceem-logo.svg';
import { osName, mobileVendor, deviceType, mobileModel } from 'react-device-detect';
import stats from '../images/stats.png';
import SimpleCrypto from "simple-crypto-js";
import AnnouncementPopup from "./AnnouncementPopup";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            email: localStorage.getItem('email'),
            userTopics: [],
            userFollowedAuthors: [],
            userFollowers: [],
            userPosts: [],
            paidCampaigns: [],
            freeCampaigns: [],
            defaultCampaigns: [],
            rewardsCount: "",
            isProfileLoading: true,
            isUserTopicsLoading: true,
            isUserFollowedAuthorsLoading: true,
            isUserPostsLoading: true,
            name: "",
            profilePicture: "",
            post: "All Articles",
            clicked: false,
            navigate: false,
            localAdv: [],
            notificationFlag: false,
            showAnnouncement: true,
            term: "",
            termDefinition: "",
            termId: "",
            termUsage: "",
            index: 0,
            isLoad: true,
            gamesCount: "",
            newUsersCount: "",
            opportunitiesCount: "",
            pollsCount: "",
            allSme: [],
            smeTopics: [],
            allOfferings: [],
            commercialPlans: [],
            allEvents: [],
            annPopup: false,
            showPasswordPopup: false
        }

    }
    componentDidMount = () => {
        this.getAllSmeOfferings();
        this.getAllSmeTopics();
        this.getAllCommercialPlans();
        this.getAllPaidSme();
        this.getUserProfile();
        this.getUserFollowedAuthors();
        this.getUserTopics();
        this.getUserPosts();
        this.getAdvertisements();
        this.detectDevice();
        this.getNotificationFlag();
        this.getTermRandomly();
        this.getTotalRewardPointsForIndividual();
        this.getAllEventsData();
        // if (true) {
        //     document.getElementById('password').style.display = 'flex';
        // }
        setTimeout(() => {
            this.setState({ showAnnouncement: false })
        }, 10000)
        if (localStorage.getItem("announcement")) {
            this.setState({ annPopup: true })
            // let timeleft = 10;
            // var downloadTimer = setInterval(function () {
            //     if (timeleft <= 1) {
            //         clearInterval(downloadTimer);
            //     } else {
            //         document.getElementById("countdown").innerHTML = timeleft + " seconds";
            //     }
            //     timeleft -= 1;
            // }, 1000);
        }
        if (localStorage.getItem("msg")) {
            // document.getElementById('password').style.display = 'flex';
            this.setState({ showPasswordPopup: true })
        } else {
            this.setState({ showPasswordPopup: false })
            // console.log("No message")
        }
        // if (localStorage.getItem("announcement")) {
        //     document.getElementById("announcement").style.display = 'flex';
        // } else {
        //     console.log("No announcement")
        // }
    }
    renderAdvertisement = (adIndex, defaultAdShown) => {
        const { paidCampaigns, freeCampaigns, defaultCampaigns } = this.state;
        const ads = [...paidCampaigns, ...freeCampaigns];

        // console.log("Rendering ad index:", adIndex); 
        // console.log("Default Ad Shown:", defaultAdShown);

        let ad = ads[adIndex];

        // If no ads from paid/free, use default ad
        if (!ad && ads.length === 0 && defaultCampaigns.length > 0 && !defaultAdShown) {
            console.log("No paid/free ads, showing default ad");
            ad = defaultCampaigns[0];
            defaultAdShown = true;
        } else if (!ad && ads.length === 0 && defaultCampaigns.length > 0) {
            ad = defaultCampaigns[0];
        } else if (!ad) {
            console.log("No ad available to show"); // Debugging line
            return null; // No ad to show
        }

        return (
            <Link to={`/adv/${ad?.id}`} className="link" key={`ad-${adIndex}`} style={{ fontFamily: "Noto Sans" }}>
                <div style={{ marginLeft: "10px", marginRight: "10px", border: "1px solid black", borderRadius: "10px", height: "4rem", background: "transparent", position: "relative" }}>
                    <Row style={{ width: "100%" }} className="no-gutters">
                        <Col xs={9} style={{ borderRight: "1px solid black", height: "3.9rem", marginTop: "-4px", position: "relative" }}>
                            <div style={{ marginTop: "6px" }}>
                                {ad?.campaignName.length > 53 ? (
                                    <div className="camp-name-home">{ad?.campaignName.substring(0, 53)}...</div>
                                ) : (
                                    <div className="camp-name-home">{ad?.campaignName}</div>
                                )}
                            </div>
                            <div className="camp-col">
                                <i className="fa fa-certificate fa-stack-2x"></i>
                                <span className="fa fa-stack-1x" style={{ color: "#31197C" }}>
                                    <span style={{ marginLeft: "6px", fontWeight: "600", fontFamily: "Open Sans", fontSize: "19px" }}>
                                        {ad?.campaignPaymentType === "paid" ? "P" : ad?.campaignPaymentType === "free" ? "F" : "D"}
                                    </span>
                                </span>
                            </div>
                        </Col>
                        <Col xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img
                                src={ad?.imageUrl === "uploads/1649942289609advertisement.png" ? config.userUrl + ad?.imageUrl : ad?.imageUrl}
                                className="adv-image-home"
                            />
                        </Col>
                    </Row>
                </div>
            </Link>
        );
    };
    getAllSmeTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getAllRegisterTopics', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    self.setState({ smeTopics: response.data.topics })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getAllCommercialPlans = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getAllCommercialPlans', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    self.setState({ commercialPlans: response.data.commercialPlans })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    self.setState({ allOfferings: response.data.offerings })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getNotificationFlag = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getNotificationFlag', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    self.setState({ notificationFlag: response.data.flag })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    getTotalRewardPointsForIndividual = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getTotalRewardPointsForIndividual', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    localStorage.setItem("rewards", response.data.getTotalRewardPointsForIndividual[0]?.count)
                    self.setState({
                        rewardsCount: localStorage.getItem("rewards"),
                        gamesCount: response.data.game,
                        newUsersCount: response.data.newUser,
                        opportunitiesCount: response.data.userOpportunityCount,
                        pollsCount: response.data.polls,
                        isLoad: false
                    })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    getAllEventsData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllSmeEventsData', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        allEvents: response.data.eventsData
                    })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    getTermRandomly = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllTermRandomly', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        term: response.data.terms.term,
                        termDefinition: response.data.terms.definition,
                        termUsage: response.data.terms.usage,
                        termId: response.data.terms.id,
                    })
                    // console.log(response)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    detectDevice = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var payload = {
            "email": this.state.email,
            "deviceType": deviceType,
            "mobileModel": mobileModel,
            "osName": osName,
            "mobileVendor": mobileVendor
        }
        axios.post(config.apiUrl + 'auth/storeDeviceProperties', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
                if (response.data.status === true) {
                    //console.log("success")
                } else {
                    console.log("err", payload)
                }
            })
            .catch(function (error) {
                console.log("payload", payload, error)
            });
    }
    getUserFollowedAuthors = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        var localFollowers = localStorage.getItem('getUserFollowedAuthors')
        //console.log("local", localFollowers)
        var followersData = JSON.parse(localFollowers)
        const exp = new Date()
        if ((localFollowers === null) || (exp.getTime() > followersData.expiry)) {
            // console.log("from local axios")
            axios.get(config.userUrl + 'user/getUserFollowedAuthors', { headers: headers })
                .then(function (response) {
                    if (response.data.status === true) {
                        self.setState({ userFollowedAuthors: response.data.getUserFollowedAuthors, userFollowers: response.data.userFollowers, isUserFollowedAuthorsLoading: false })
                        var item = {
                            "latestUserFollowedAuthors": JSON.stringify(response.data.getUserFollowedAuthors),
                            "expiry": exp.getTime() + 60 * 60 * 100
                        }
                        localStorage.setItem("getUserFollowedAuthors", JSON.stringify(item))
                    } else {
                        console.log("else", response.data)
                    }
                })
                .catch(function (error) {
                    console.log("Error");
                });
        } else {
            this.setState({
                userFollowedAuthors: JSON.parse(followersData.latestUserFollowedAuthors)
            })
            //  console.log("from local authors", JSON.parse(followersData.latestUserFollowedAuthors))
        }
        // let headers = {
        //     "Content-Type": 'application/json',
        //     "Authorization": "Bearer " + this.state.token
        // }
        // var self = this
        // axios.get(config.userUrl + 'user/getUserFollowedPeople', { headers: headers })
        //     .then(function (response) {
        //         // console.log(response);
        //         if (response.data.status === true) {
        //             self.setState({ userFollowedAuthors: response.data.userFollowedPeople })
        //             return axios.get(config.userUrl + 'user/getUserTopics', { headers: headers });
        //         }
        //     })
        // .then(function (response) {
        //     //console.log(response);
        //     if (response.data.status === true) {
        //         console.log("success");
        //         self.setState({ userTopics: response.data.topics })
        //         return axios.get(config.userUrl + 'user/getPosts', { headers: headers });
        //     }
        // })
        // .then(function (response) {
        //     // console.log(response);
        //     if (response.data.status === true) {
        //         console.log("success");
        //         self.setState({ userPosts: response.data.posts })
        //     } else {
        //         console.log(response.data)
        //     }
        // })
        // .catch(error => console.log(error.response));
    };
    getAllPaidSme = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllPaidSme', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    self.setState({ allSme: response.data.data })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    getUserTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getUserTopics', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    self.setState({ userTopics: response.data.topics, isUserTopicsLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    getUserPosts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        var localPosts = localStorage.getItem('posts')
        var postData = JSON.parse(localPosts)
        const exp = new Date()
        if ((localPosts === null) || (exp.getTime() > postData.expiry)) {
            axios.get(config.userUrl + 'user/getPosts', { headers: headers })
                .then(function (response) {
                    if (response.data.status === true) {
                        self.setState({ userPosts: response.data.posts, isUserPostsLoading: false })
                        var item = {
                            "latestPosts": JSON.stringify(response.data.posts),
                            "expiry": exp.getTime() + 60 * 60 * 100
                        }
                        localStorage.setItem("posts", JSON.stringify(item))
                    } else {
                        console.log(response.data)
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        } else {
            this.setState({
                userPosts: JSON.parse(postData.latestPosts)
            })
        }

    };

    getAdvertisements = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        var localAdv = localStorage.getItem('adv')
        var ads = JSON.parse(localAdv)
        const exp = new Date()
        if ((localAdv === null) || (exp.getTime() > ads.expiry)) {
            axios.get(config.userUrl + 'user/getCampaignsExplorePage', { headers: headers })
                .then(function (response) {
                    //console.log(response);
                    if (response.data.status === true) {
                        //console.log("success from db");
                        self.setState({
                            paidCampaigns: response.data.paidCampaigns,
                            freeCampaigns: response.data.freeCampaigns,
                            defaultCampaigns: response.data.defaultCampaigns
                        })
                        var item = {
                            "advPaid": JSON.stringify(response.data.paidCampaigns),
                            "advFree": JSON.stringify(response.data.freeCampaigns),
                            "advDefault": JSON.stringify(response.data.defaultCampaigns),
                            "expiry": exp.getTime() + 60 * 60 * 100
                        }
                        localStorage.setItem("adv", JSON.stringify(item))

                    } else {
                        console.log(response.data)
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        } else {
            // console.log("from local campaigns")
            this.setState({
                paidCampaigns: JSON.parse(ads.advPaid),
                freeCampaigns: JSON.parse(ads.advFree),
                defaultCampaigns: JSON.parse(ads.advDefault)
            })
        }


    };
    handlePostChange = (e) => {
        const topic = e.target.value;
        const { clicked } = this.state;
        if (!topic || topic === "ALL TOPICS") {
            this.setState({ post: "All Articles", clicked: !clicked });
        } else {
            this.setState({ post: topic, clicked: !clicked });
        }
    };
    selectedPosts = (e, topic) => {
        const { clicked } = this.state
        if (!topic) {
            this.setState({ post: "All Articles", clicked: !clicked })
        } else {
            this.setState({ post: topic, clicked: !clicked })
        }
    }
    // componentDidMount() {
    //     window.addEventListener("beforeunload", this.logoutLocal());
    // }
    // componentWillUnmount() {
    //     window.removeEventListener("beforeunload", this.logoutLocal());
    // }
    // logoutLocal = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + this.state.token,
    //         "applicationType": "mobile"
    //     }
    //     var self = this
    //     axios.get(config.apiUrl + 'auth/logout', { headers: headers })
    //         .then(function (response) {
    //             // console.log(response);
    //             if (response.data.status === true) {
    //                 localStorage.clear();
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error", error);
    //         });
    // }
    getUserProfile = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getProfile', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    let user = response.data.user;
                    self.setState(
                        {
                            name: user.name,
                        }
                    );
                    localStorage.setItem("planType", user.merchantType)
                    if ((user.profilePicture === null) && (user.gender === "0")) {
                        self.setState({
                            profilePicture: "uploads/1652942537335Avatar%20Users2_37.png",
                        })
                    } else if ((user.profilePicture === null) && (user.gender === "1")) {
                        self.setState({
                            profilePicture: "uploads/1652942450250Avatar Users2_31.png",
                        })
                    } else {
                        self.setState({
                            profilePicture: user.profilePicture,
                        })
                    }
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    handleSelect = (selectedIndex, e) => {
        this.setState({ index: selectedIndex })
    };

    showNone = () => {
        // localStorage.removeItem("password")
        // document.getElementById('password').style.display = 'none';

        localStorage.removeItem("msg")
        this.setState({ showPasswordPopup: false })
    }
    noAnnouncement() {
        localStorage.removeItem("announcement")
        document.getElementById('announcement').style.display = 'none';
    }
    // showTerm() {
    //     document.getElementById('term').style.display = 'flex';
    // }
    // hideTerm() {
    //     document.getElementById('term').style.display = 'none';
    // }
    closeAnnouncementPopup = () => {
        this.setState({ annPopup: false })
        localStorage.removeItem("announcement")
    }
    // navigateToGames = () => {
    //     if (localStorage.getItem("planType") == 1) {
    //         this.showPlan()
    //     } else {
    //         this.props.history.push('/games');
    //     }
    // }
    showPlan() {
        document.getElementById('plan').style.display = 'flex';
    }
    hidePlan() {
        document.getElementById('plan').style.display = 'none';
    }

    render() {

        const { gamesCount, showPasswordPopup, allEvents, annPopup, isLoad, allOfferings, commercialPlans, paidCampaigns, freeCampaigns, defaultCampaigns, smeTopics, userFollowers, newUsersCount, pollsCount, opportunitiesCount, isProfileLoading, showAnnouncement, notificationFlag, isUserFollowedAuthorsLoading, isUserPostsLoading, isUserTopicsLoading, userFollowedAuthors, userPosts, post } = this.state;
        const kFormatter = (num) => {
            return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num)
        }
        let filteredData = userPosts.filter((item) => {
            if (post != "All Articles") {
                // console.log("post",post,item.topicName)
                return (post === item.topicName.replace(/ *\([^]*\) */g, ""))
            }
            else {
                // console.log("post item",item)
                return item
            }
        })
        let adIndex = 0;
        let defaultAdShown = false;
        let ads = [...paidCampaigns, ...freeCampaigns];
        let adsLength = ads.length;
        const formatRatingNumber = (number) => {
            const roundedNumber = Math.round(number * 100) / 100; // Round to two decimal places
            const decimalPart = roundedNumber - Math.floor(roundedNumber);

            if (decimalPart === 0) {
                return Math.floor(roundedNumber); // Remove decimal part if it's zero
            } else {
                return roundedNumber.toFixed(1); // Keep two decimal places otherwise
            }
        }
        if (isLoad) {
            // console.debug("renders: Loading...");
            return <div className="App"></div>;
        }
        const getPlan = (planId) => {
            const item = commercialPlans.find(txt => txt.id === planId);
            return item ? item.plan : "";
        }
        const getOfferings = (off) => {
            const items = allOfferings
                .filter(data => off.includes(data.id))
                .map(data => data.text);
            const result = items.join(', ');

            //  console.log("result", result);
            return result;
        }
        // const getSmeTopics = (t) => {
        //     const items = smeTopics
        //         .filter(data => t.includes(data.id))
        //         .map(data => data.topicName);
        //     const result = items.join(', ');

        //     console.log("result", result);
        //     return result;
        // }
        const getSmeTopics = (t) => {
            const items = smeTopics
                .filter(data => t.includes(data.id))
                .map(data => data.topicName);

            return items;
        };
        const getSmeTopicIcon = (t) => {
            const items = smeTopics
                .filter(data => t.includes(data.id))
                .map(data => "../" + data.topicName + ".png"); // Use require to ensure webpack resolves the path correctly

            return items;
        };
        // if (isProfileLoading) {
        //     console.debug("isProfileLoading");
        //     return <div className="App"></div>;
        // }
        // if (isUserFollowedAuthorsLoading) {
        //     console.debug("isUserFollowedAuthorsLoading");
        //     return <center className="App">Loading</center>;
        // }
        // if (isUserPostsLoading) {
        //     console.debug("isUserPostsLoading");
        //     return <div className="App"></div>;
        // }
        // if (isUserTopicsLoading) {
        //     console.debug("isUserTopicsLoading");
        //     return <div className="App"></div>;
        // }
        const paid = this.state.paidCampaigns.map((i, idx) => {
            var a = new Date(i.endDate)
            var publish = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return publish;

        })
        const free = this.state.freeCampaigns.map((i, idx) => {
            var a = new Date(i.endDate)
            var publish = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return publish;

        })
        const getOffText = (offeringId) => {
            const item = allOfferings.find(txt => txt.id === offeringId);
            return item ? item.text : "";
        }
        return (
            <div className="home-bg">
                {annPopup && <AnnouncementPopup showAnnouncement={showAnnouncement} closeAnnouncementPopup={this.closeAnnouncementPopup} msg={localStorage.getItem("announcement")} />}
                <div className="top_bar">
                    <img src={loginlogo} className="login-logo" />
                    {this.state.token ?
                        <Link to="/usernotifications">{notificationFlag ?
                            <i class="fa fa-bell fa-lg bell-icon" aria-hidden="true"></i>
                            : <i class="fa fa-bell-o fa-lg bell-icon-empty" aria-hidden="true"></i>}</Link> : null}
                    {newUsersCount > 0 ? <Link to="/registeredusers" className="link text-black"><div style={{ width: "7.8rem", fontSize: "14px", color: "#31197c", fontFamily: "Noto Sans", padding: "1px", position: "relative", top: "6%", left: "55%" }}><b className="ml-3">Welcome</b> <br /><span style={{ fontSize: "12px" }}>{newUsersCount} New Members</span></div>
                    </Link> :
                        <></>}

                </div>
                <div className="screen-top-gap-home">
                    <div className="main">
                        <ScrollMenu>
                            <Link to={`/userdetails`} className="link">
                                <div className="mt-2 ml-3">
                                    <img src={config.userUrl + this.state.profilePicture} className="auth-icon1" />
                                    {this.state.name !== null && this.state.name > this.state.name.substring(0, 8) ?
                                        <p className="text-userN">{this.state.name.substring(0, 8)}..</p>
                                        : <p className="text-userN">{this.state.name}</p>}
                                </div>
                            </Link>

                            {userFollowedAuthors.map((data, id) => {
                                return (
                                    <Link to={{
                                        pathname: `/authordetails/${data.uuid}`,
                                        state: data.uuid
                                    }}  >
                                        <div className="scroll-authors">
                                            <Col xs={3}>
                                                <center>
                                                    <div className={data?.postPublishedByUserWithinLastWeek ? "auth-dot" : "auth-dot-none"}>  <img src={data.profilePicture === null ? authorImg : config.userUrl + data.profilePicture} className="auth-icon" /></div>
                                                    {data.name !== null && data.name > data.name.substring(0, 8) ?
                                                        <p className="text-authorname">{data.name.substring(0, 8)}..</p>
                                                        : <p className="text-authorname">{data.name}</p>}
                                                </center></Col>
                                        </div>
                                    </Link>
                                )
                            })
                            }
                            {/* {userFollowers.map((data, id) => {
                                return (
                                    <Link to={{
                                        pathname: `/authordetails/${data.uuid}`,
                                        state: data.uuid
                                    }}  >
                                        <div className="scroll-authors">
                                            <Col xs={3}>
                                                <center>
                                                    <div className={data.postPublishedByUserWithinLastWeek ? "auth-dot" : "auth-dot-none"}>  <img src={config.userUrl + data.profilePicture} className="auth-icon" /></div>
                                                    {data.name !== null && data.name > data.name.substring(0, 8) ?
                                                        <p className="text-authorname">{data.name.substring(0, 8)}...</p>
                                                        : <p className="text-authorname">{data.name}</p>}
                                                </center></Col>
                                        </div>
                                    </Link>
                                )
                            })
                            } */}
                        </ScrollMenu >
                    </div >
                    {/* <div className="your-interests">Your Interests</div> */}

                    {/* <div id="password" className="modal-pop">
                        <form className="modal-content-log">
                            <div className="_container">
                                <p className="mt-2">Reset your Password</p>
                                <center><Button variant="default" onClick={this.showNone} className="deletebutton">Ok</Button></center>
                            </div>
                        </form>
                    </div> */}
                    <Modal show={showPasswordPopup} size="lg"
                        aria-labelledby="contained-modal-title-vcenter"

                        centered>
                        <Modal.Body>
                            <div>
                                <div className="ml-1">  </div>
                                <div style={{ fontSize: "18px", color: "#31197C", fontWeight: 600, marginTop: "-4px", textAlign: "center" }}>Reset your Password</div>
                            </div>
                            <center><Button variant="default" onClick={this.showNone} className="mt-3 deletebutton">Ok</Button></center>
                        </Modal.Body>
                    </Modal>
                    {/* <div id="announcement" className="modal-pop">
                        <form className="modal-content-log">
                            <div className="_container">
                                <center><img src={sceemlogo} className="announcement-sceem-logo" /></center>
                                <h5 className="mt-2"><b>Announcement</b></h5>
                                <center>   <img src={announcement} className="announcement-sceem-icon" /></center>
                                <p className="mt-2">{localStorage.getItem("announcement")}</p>
                                {showAnnouncement ? <center><div id="countdown"></div></center> :
                                    <Button variant="default" onClick={this.noAnnouncement} className="deletebutton">Ok</Button>}
                            </div>
                        </form>
                    </div> */}
                    {/* <div className="ml-2 mr-2">
                        <div className="ml-2 new-members-wrapper">
                            <div>
                                <div onClick={this.showTerm} className="new-members-sm-all">
                                    <div className="flex">   <img src={stats} style={{ width: "20px" }} /> <span className="ml-2 new-text">Term</span></div>
                                    <div className="mt-2 term-Text">{this.state.term}</div></div>
                            </div>
                            <div>
                                <div className="new-members-sm-all">
                                    <div className="flex">   <img src={stats} style={{ width: "20px" }} /> <span className="ml-2 new-text">Rewards</span></div>
                                </div>
                                 <span className="new-text">Rewards</span></div> 
                            </div>
                            <div>
                                <Link to="/polls/currentpolls" className="link text-black">
                                    <div className="new-members-sm-all">
                                        <div className="flex">   <img src={stats} style={{ width: "20px" }} /> <span className="ml-2 new-text">Polls</span></div>

                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div> */}
                    {/* style={{background:"white",paddingLeft:"5px",paddingRight:"5px",paddingBottom:"5px",paddingTop:"2.5px",borderRadius:"10px"}} */}
                    {/* <div className="new-members-main" >
                        <ScrollMenu> */}
                    <div id="plan" className="modal-pop">
                        <form className="modal-content-log">
                            <div className="_container">
                                <p className="mt-2">Please upgrade your plan</p>
                                <center><Button variant="default" onClick={this.hidePlan} className="deletebutton">Ok</Button></center>
                            </div>
                        </form>
                    </div>
                    <div className="New_Members ml-1 mr-1">

                        <div className="new-members-sm-all-rewards">
                            <Link to="/leaderboard" className="link text-black">
                                <div className="flex" style={{ justifyContent: "space-around" }}>
                                    <div> <img src={moneybag} style={{ width: "20px" }} /></div>
                                    <div className="term-Text_box">{kFormatter(this.state.rewardsCount)}</div>
                                </div>

                            </Link>
                        </div>
                        {/* <span className="new-text">Rewards</span></div> */}


                        <div className="new-members-sm-all-games">
                            <Link to="/games" className="link text-black">
                                <div className="flex" style={{ justifyContent: "space-around" }}>
                                    <div><img src={games} style={{ width: "20px" }} /></div>
                                    <div className="term-Text_box">{gamesCount + 1}</div>
                                </div>
                            </Link>
                        </div>
                        <div className="new-members-sm-all-games">
                            <Link to="/polls/currentpolls" className="link text-black">
                                <div className="flex" style={{ justifyContent: "space-around" }}>
                                    <div><img src={polls} style={{ width: "20px" }} /></div>
                                    <div className="term-Text_box">{pollsCount}</div>
                                </div>

                            </Link>
                        </div>
                        <div >
                            <Carousel indicators={false} slide={false} controls={false} style={{ marginTop: "-9px" }}>
                                <Carousel.Item variant="dark" interval={2000} >
                                    <div className="new-members-sm_all_">
                                        <Link to={`/term/${this.state.termId}`} className="link text-black">
                                            <div className="flex" style={{ justifyContent: "space-around" }}>
                                                <div><img src={book} style={{ width: "20px" }} /></div>
                                                <div className="term-Text-t"><div>  {this.state.term}</div></div>
                                            </div>
                                        </Link>
                                    </div>
                                    {/* <div class="new-members-sm_all_term">
                                        <a href={`/term/${this.state.termId}`} class="linktt text-black">
                                            <img src={book} alt="Book Icon" />
                                            <div class="term-Text-tt">
                                              {this.state.term}
                                            </div>
                                        </a>
                                    </div> */}
                                </Carousel.Item>
                                {/* {opportunitiesCount > 0 ?
                                    <Carousel.Item variant="dark" interval={2000} >
                                        <div className="new-members-sm_all_">
                                            <Link to="/viewopportunity" className="link text-black">
                                                <div className="flex" style={{ justifyContent: "space-around", alignItems: "center" }}>
                                                    <div> <img src={opportunity} style={{ width: "20px" }} /></div>
                                                    <div className="term-Text_box">{opportunitiesCount}</div>
                                                </div>

                                            </Link>
                                        </div>
                                    </Carousel.Item> : ""} */}
                                {/* {newUsersCount > 0 ?
                                    <Carousel.Item variant="dark" interval={2000} >
                                        <div className="new-members-sm_all_">
                                            <Link to="/registeredusers" className="link text-black">
                                                <div className="flex">   <span className="new-members-img-bg"> <img src={newmembers} style={{ width: "20px" }} /></span> <span className="ml-2 new-text">New Members</span></div>
                                                <div className="term-Text">{newUsersCount}</div>
                                            </Link>
                                        </div>
                                    </Carousel.Item> : ""} */}
                                {/* {pollsCount > 0 ?
                                    <Carousel.Item variant="dark" interval={2000} >
                                        <div className="new-members-sm_all_">
                                            <Link to="/polls/currentpolls" className="link text-black">
                                                <div className="flex">   <span className="new-members-img-bg"> <img src={polls} style={{ width: "20px" }} /></span>
                                                    <span className="ml-2"> <div className="term-Text_box">{pollsCount}</div></span></div>

                                            </Link>
                                        </div>
                                    </Carousel.Item> : ""} */}
                            </Carousel>
                        </div>
                        {/* <div className="new-members-sm-all_">
                                    <Link to="/sceemlore" className="link text-black">
                                        <div className="flex">   <span className="new-members-img-bg"> <img src={stats} style={{ width: "20px" }} /></span> <span className="ml-2 new-text">Sceemlore</span></div>
                                        <div className="mt-2 term-Text">10/20</div>
                                    </Link>
                                </div> */}

                    </div><br /><br />
                    {/* <div className="mt-3" >
                        <Carousel variant="dark" controls={false}>
                            {this.state.paidCampaigns.map((data, id) => {
                                return (
                                    <Carousel.Item variant="dark" interval={2000} >
                                        <Link to={`/adv/${data.id}`} className="link">
                                            <div className="adv-body">
                                                <div className="adv-shadow">
                                                    <section>
                                                        <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                                            {data.campaignName !== null && data.campaignName > data.campaignName.substring(0, 38) ?
                                                                <b className="camp-name">{data.campaignName.substring(0, 38)}...</b> : <b className="camp-name">{data.campaignName}</b>}
                                                            <div className="camp-col">  <span>
                                                                <i class="ml-5 fa fa-certificate fa-stack-2x"></i>
                                                                <span class="fa fa-stack-1x" style={{ color: "#31197C" }}>
                                                                    <span style={{ fontWeight: "600", fontFamily: "Open Sans", marginLeft: "97px", fontSize: "19px" }}>
                                                                        P
                                                                    </span>
                                                                </span>
                                                            </span></div>
                                                        </div>
                                                    </section>
                                                    <section>   <img src={data.imageUrl === "uploads/1649942289609advertisement.png" ? config.userUrl + data.imageUrl : data.imageUrl} className="adv-image_" /></section>
                                                </div>

                                            </div>
                                        </Link>
                                    </Carousel.Item>
                                )
                            })}
                            {this.state.freeCampaigns.map((data, id) => {
                                return (
                                    <Carousel.Item variant="dark" interval={2000} >
                                        <Link to={`/adv/${data.id}`} className="link">
                                            <div className="adv-body">
                                                <div className="adv-shadow">
                                                    <section>
                                                        <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                                            {data.campaignName !== null && data.campaignName > data.campaignName.substring(0, 38) ?
                                                                <b className="camp-name">{data.campaignName.substring(0, 38)}...</b> : <b className="camp-name">{data.campaignName}</b>}

                                                            <div className="camp-col">  <span>
                                                                <i class="ml-5 fa fa-certificate fa-stack-2x"></i>
                                                                <span class="fa fa-stack-1x" style={{ color: "#31197C" }}>
                                                                    <span style={{ fontWeight: "600", fontFamily: "Open Sans", marginLeft: "97px", fontSize: "19px" }}>
                                                                        F
                                                                    </span>
                                                                </span>
                                                            </span></div>
                                                        </div>
                                                    </section>
                                                    <section>   <img src={data.imageUrl === "uploads/1649942289609advertisement.png" ? config.userUrl + data.imageUrl : data.imageUrl} className="adv-image_" /></section>
                                                </div>
                                            </div>
                                        </Link>
                                    </Carousel.Item>
                                )
                            })}
                        </Carousel>
                    </div> */}

                    {/* {this.state.paidCampaigns.length == 0 && this.state.freeCampaigns.length == 0 ?
                        <div className="mt-2">   {this.state.defaultCampaigns.map((data, id) => {
                            return (
                                <Link to={`/adv/${data.id}`} className="link">
                                    <div className="adv-body">
                                        <div className="adv-shadow">
                                            <section>
                                                <div style={{ marginTop: "6px", marginLeft: "5px" }}><b className="camp-name">{data.campaignName}</b>
                                                    <div className="camp-col">  <span>
                                                        <i class="ml-5 fa fa-certificate fa-stack-2x"></i>
                                                        <span class="fa fa-stack-1x" style={{ color: "#31197C" }}>
                                                            <span style={{ fontWeight: "600", fontFamily: "Open Sans", marginLeft: "97px", fontSize: "19px" }}>
                                                                D
                                                            </span>
                                                        </span>
                                                    </span></div>
                                                </div>
                                            </section>
                                            <section>   <img src={data.imageUrl === "uploads/1649942289609advertisement.png" ? config.userUrl + data.imageUrl : data.imageUrl} className="adv-image_" /></section>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}
                        </div> : ""} */}

                    {/* <div className="mt-2" >
                        <Carousel variant="dark" controls={false}>
                            {this.state.allSme.map((data, id) => {
                                return (
                                    <Carousel.Item variant="dark" interval={2000} >
                                        <Link to={`/profilesme/${data.smeuserid}`} className="link">
                                            <div className="adv-body">
                                                <div className="adv-shadow">
                                                    <section>
                                                        <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                                            <div style={{ marginTop: "-8px", fontSize: "13px", fontWeight: 600, color: "black" }}>Expertise</div>
                                                            <div style={{ fontSize: "12px", lineHeight: "14px", color: "black" }}>{getSmeTopics(data.topicId)}</div>
                                                            <div style={{ fontSize: "13px", fontWeight: 600, color: "black" }}>Offerings</div>
                                                            <div style={{ marginTop: "-1px", fontSize: "12px", lineHeight: "14px", color: "black" }}>{getOfferings(data.offering)}</div>
                                                            <div style={{ fontSize: "12px", lineHeight: "14px", color: "black" }} className="mb-3"><b>Commercial</b>: {getPlan(data.commercial)}</div>

                                                        </div>
                                                    </section>
                                                    <section style={{alignItems:"center",justifyContent:"center"}}>
                                                            <img src={config.userUrl + data.profilePicture} style={{ width: "50px" }} />
                                                            <div className="mt-1" style={{ fontSize: "12px", lineHeight: "14px", color: "black", fontWeight: 600, }}>{data.name}</div>
                                                            <div className="mt-1" style={{ fontSize: "12px", lineHeight: "14px", color: "black", fontWeight: 600, }}>{data.avg_assessment}</div>
                                                   
                                                    </section>
                                                </div>

                                            </div>
                                        </Link>
                                    </Carousel.Item>
                                )
                            })}
                        </Carousel>
                    </div> */}

                    <div style={{ marginTop: "15px", width: "90%", marginLeft: "25px", marginRight: "20px" }}>
                        <Carousel variant="dark" controls={false} >
                            {this.state.allSme.map((data, id) => {
                                return (
                                    <Carousel.Item variant="dark" interval={4000} key={id} indicators={false} >
                                        <Link to={`/profilesme/${data.smeuserid}`} className="link">
                                            <div>
                                                <div style={{ marginTop: "-3px", color: "white", background: "#31197C", textAlign: "center", borderTopRightRadius: "10px", width: "95.5%", padding: "2px 10px 4px 10px", fontSize: "15px", fontWeight: 600, fontFamily: "Noto Sans" }}>Connect with {data.name}</div>
                                                <div style={{ marginTop: "-2px", width: "95.5%", backgroundColor: "#c0b4f4", height: "auto", padding: "6px 3px 8px 3px", borderBottomRightRadius: "15px", }}>
                                                    <Row style={{ padding: "1px 5px 5px 5px", marginLeft: "6px", borderBottomRightRadius: "50px", borderTopRightRadius: "50px", background: "linear-gradient(to right bottom, #efe2ef, #e9e2f2, #e1e3f5, #d9e4f6, #d1e5f5, #d1e5f5, #d1e5f5, #d1e5f5, #d9e4f6, #e1e3f5, #e9e2f2, #efe2ef)", width: "96%", }}>
                                                        <Col xs={9} style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}>
                                                            <div style={{ height: "3.2rem" }}>
                                                                <div style={{ lineHeight: "16px" }}>
                                                                    <div style={{ marginLeft: "-8px" }}>
                                                                        {getSmeTopicIcon(data.topicId).map((imageUrl, index) => (
                                                                            <span key={index} >
                                                                                <img src={imageUrl} style={{ width: "16px" }} alt="Topic icon" />&nbsp;
                                                                                <span style={{ fontSize: "12px", color: "black" }}>{getSmeTopics(data.topicId)[index]}</span><br />
                                                                            </span>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={3} style={{ border: "none", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <center style={{ marginTop: "-1px" }}>
                                                                <img src={config.userUrl + data.profilePicture} style={{ float: "right", marginRight: "-14px", marginTop: "1px", width: "52px" }} />
                                                            </center>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Link>
                                    </Carousel.Item>
                                );
                            })}
                        </Carousel>
                    </div>


                    {/* <Col xs={3}>
                            <Button variant="default" className="btn-new-members-sm-all">
                                <span className="int-text">New Members</span></Button>
                        </Col>
                        <Col xs={3}>
                            <Button variant="default" className="btn-new-members-sm-all">
                                <span className="int-text">New Polls</span></Button>
                        </Col>
                        <Col xs={3}>
                            <Button variant="default" className="btn-new-members-sm-all">
                                <span className="int-text">New Jobs</span></Button>
                        </Col> */}
                    <div className="topics-select">
                        <div className="ml-2"><b>{filteredData.length} Article(s)</b></div>
                        <div>
                            <select name="post" className="uppercase-bold" style={{ border: "none", background: "transparent" }} onChange={this.handlePostChange} value={this.state.post}>
                                <option value="ALL TOPICS" style={{ border: "none", background: "transparent" }} className="uppercase-bold">ALL TOPICS</option>
                                {this.state.userTopics && this.state.userTopics.map(item =>
                                    <option style={{ border: "none", background: "transparent" }} className="uppercase-bold" key={item.id} value={item.topicName.replace(/ *\([^]*\) */g, "")}>{item.topicName.replace(/ *\([^]*\) */g, "")}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    {/* <div className="mt-1 interests_main" >
                        <ScrollMenu>
                            <div style={{ marginRight: "-12px" }}>
                                <Button variant="default" className="outline-btn ml-4 btn-outline-sm" onClick={(e) => { this.selectedPosts(e, "All Articles") }}>
                                    <img src={contact} className="btn-icon" />
                                    <span className="int-text">&nbsp;&nbsp;&nbsp;All Topics</span></Button>

                            </div>

                            {this.state.userTopics.map((data, id) => {
                                return (
                                    <div className="scroll_interests">
                                        <Col xs={10} key={id}>
                                            <Button variant="default" className="btn-outline-sm-all" onClick={(e) => { this.selectedPosts(e, data.topicName) }}>

                                              </Button>
                                        </Col>

                                    </div>
                                )
                            })}
                        </ScrollMenu>
                    </div > */}

                    <div className="articleCard-height">
                        {filteredData.length > 0 ? (
                            filteredData.map((data, i) => (
                                <React.Fragment key={i}>
                                    {/* Show advertisement after every two articles */}
                                    {i > 0 && i % 2 === 0 && !defaultAdShown && (adsLength === 0 ? this.renderAdvertisement(0, defaultAdShown = true) : this.renderAdvertisement(adIndex++, defaultAdShown))}
                                    <Card className="articleCard">
                                        <Link to={{ pathname: `/article/${data.title.replace(/ /g, "_")}/${data.id}` }} className="link">
                                            <Row style={{ width: "100%" }} className="no-gutters px-2">
                                                <Col xs={2}>
                                                    <div className="auth-center">
                                                        <img alt="" src={config.userUrl + data.authorProfilePicture} style={{ width: "38px" }} />
                                                    </div>
                                                </Col>
                                                <Col xs={10}>
                                                    <div className="titleText mr-3">{data?.title}</div>
                                                </Col>
                                            </Row>
                                            <Row style={{ width: "100%", marginTop: "-2px", marginBottom: "2px" }}>
                                                <Col>
                                                    <div className="ml-2 homeArticleText">{data.authorName}</div>
                                                </Col>
                                                <Col>
                                                    <div className="ml-2 homeArticleText">{data.topicName.replace(/ *\([^]*\) */g, "")}</div>
                                                </Col>
                                            </Row>
                                        </Link>
                                    </Card>
                                </React.Fragment>
                            ))
                        ) : (
                            // If no articles, show ads
                            <div className="no-data-ads">
                                {adsLength > 0 ? (
                                    Array.from({ length: adsLength }).map((_, i) => (
                                        <React.Fragment key={`ad-empty-${i}`}>
                                            {this.renderAdvertisement(adIndex++, defaultAdShown)}
                                        </React.Fragment>
                                    ))
                                ) : (
                                    // If no paid or free ads, show default ad
                                    <React.Fragment>
                                        {this.renderAdvertisement(0, defaultAdShown = true)}
                                    </React.Fragment>
                                )}
                            </div>
                        )}

                        {/* Render remaining ads after the articles if filteredData is not empty */}
                        {filteredData.length > 0 && Array.from({ length: adsLength - adIndex }).map((_, i) => (
                            <React.Fragment key={`ad-remaining-${i}`}>
                                {this.renderAdvertisement(adIndex++, defaultAdShown)}
                            </React.Fragment>
                        ))}

                        {/* Handle case where filteredData is empty and ads still need to be shown */}
                        {/* {filteredData.length === 0 && Array.from({ length: adsLength }).map((_, i) => (
                            <React.Fragment key={`ad-empty-${i}`}>
                                {this.renderAdvertisement(adIndex++, defaultAdShown)}
                            </React.Fragment>
                        ))} */}
                        {allEvents.length !== 0 && allEvents.map((event, i) => (
                            <Link to={`/detailedevent/${event.id}`} className="link" key={event.id} style={{ fontFamily: "Noto Sans" }}>
                                <div style={{ marginLeft: "10px", marginRight: "10px", border: "1px solid #FF942C", borderRadius: "10px", height: "5.5rem", background: "transparent", position: "relative" }}>
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        <Col xs={9} style={{ borderRight: "1px solid #FF942C", height: "5.4rem", marginTop: "-4px", position: "relative" }}>
                                            <div style={{ marginTop: "6px" }}>
                                                {/* {ad?.campaignName.length > 53 ? (
                                                <div className="camp-name-home">{ad?.campaignName.substring(0, 53)}...</div>
                                            ) : (
                                                <div className="camp-name-home">{ad?.campaignName}</div>
                                            )} */}
                                                <div className="camp-name-home-event"><strong>{getOffText(event.offeringId)} event on {event.agenda}</strong></div>
                                                <div className="camp-name-home" style={{ fontWeight: 1000 }}><i>{moment(event.bookingDate).format("DD-MM-YYYY")}@{event.time}</i></div>
                                            </div>
                                            <div className="camp-col">
                                                <i className="fa fa-certificate fa-stack-2x"></i>
                                                <span className="fa fa-stack-1x" style={{ color: "#31197C" }}>
                                                    <span style={{ marginLeft: "6px", fontWeight: "600", fontFamily: "Open Sans", fontSize: "19px" }}>
                                                        {event.status == 1 ? "F" : "P"}
                                                    </span>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <img
                                                src={config.userUrl + event.profilePicture}
                                                className="adv-image-home"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Link>
                        ))}
                    </div>

                    <br />
                    {/* <Container>
                        <Row>
                            <Col xs={3} sm={3}>
                                <center>
                                    <Link to="/viewopportunity" className="link text-black">
                                        <img src={opportunity} style={{ width: "38px" }} /><br />
                                        <div style={{ fontSize: "12px" }}>Opportunities</div>
                                    </Link>
                                </center>
                            </Col>
                            <Col xs={3} sm={3}>
                                <center>
                                    <Link to="/polls/currentpolls" className="link text-black">
                                        <img src={poll} style={{ width: "40px" }} /><br />
                                        <div style={{ fontSize: "12px" }}>Polls</div>
                                    </Link>
                                </center>
                            </Col>
                            <Col xs={3} sm={3}>
                                <center>
                                    <Link to="/registeredusers" className="link text-black">
                                        <img src={newmembers} style={{ width: "40px" }} /><br />
                                        <div style={{ fontSize: "12px" }}>New&nbsp;Members</div>
                                    </Link>
                                </center>
                            </Col>
                            <Col xs={3} sm={3}>
                                <center>
                                    <Link to="/about" className="link text-black">
                                        <img src={stats} style={{ width: "42px", marginBottom: "-3px" }} /><br />
                                        <div style={{ fontSize: "12px" }}>Stats</div>
                                    </Link>
                                </center>
                            </Col>
                        </Row>
                    </Container> */}
                    <br />
                    {/* <Button variant="default" className="btn-outline-term" onClick={this.showTerm}>{this.state.term}</Button> */}
                    {/* <div id="term" className="modal-pop">
                        <form className="modal-content-log">
                            <div className="_container">
                                <p className="mt-2" style={{ color: "#31197C", fontSize: "20px" }}>{this.state.term}</p>
                                <p className="mt-2">{this.state.termDefinition}</p>
                                <p className="mt-2">{this.state.termUsage}</p>
                                <center><Button variant="default" onClick={this.hideTerm} className="deletebutton">Ok</Button></center>
                            </div>
                        </form>
                    </div> */}
                    <BottomMenu />
                </div >
            </div >
        )
    }
}



{/* <div className="main">
                    <HorizontalScroll>
                        {AuthorsJson.interests.map((data, id) => {
                            return (
                                <Row style={{ width: "100%" }} className="scroll-interests">
                                    <Col xs={6} key={id}><Button variant="default" className="btn-outline-sm" style={{ display: "flex" }}><img src={data.icon} className="interests" />
                                        <span className="int-text">&nbsp;&nbsp;&nbsp;{data.interest}</span></Button></Col>

                                </Row>
                            )
                        })}
                    </HorizontalScroll>
                </div> */}


// <div className="main1">
// <HorizontalScroll>
//     <Row style={{ width: "100%" }}>

//         <div className="scroll-width">
//             <Col xs={3}><img src={you} className="procurement" />
//                 <p className="text ml-2">You</p></Col>
//             <Col xs={3}><img src={author1} className="procurement" />
//                 <p className="text ml-2">Ravi</p></Col>
//             <Col xs={3}><img src={author2} className="procurement" />
//                 <p className="text ml-2">Riya</p></Col>
//             <Col xs={3}><img src={author3} className="procurement" />
//                 <p className="text ml-2">Ajay</p></Col>
//             <Col xs={3}><img src={author4} className="procurement" />
//                 <p className="text ml-2">Priya</p></Col>
//             <Col xs={3}><img src={author5} className="procurement" />
//                 <p className="text ml-2">Ram</p></Col>
//             <Col xs={3}><img src={author6} className="procurement" />
//                 <p className="text ml-2">Raj</p></Col>
//             <Col xs={3}><img src={author6} className="procurement" />
//                 <p className="text ml-2">Raj</p></Col>
//         </div>
//     </Row>
// </HorizontalScroll>
// </div>



