import React, { Component } from "react";
import { Button, Card, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import bluelogo from '../images/bluelogo.png';
import notification from '../images/Notification.png';
import './Poll.css';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import { AiOutlineShareAlt } from "react-icons/ai";
import moment from "moment-timezone";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class PollResults extends Component {
    state = {
        userAnswer: null,
        currentIndex: 0,
        options: [],
        count: [],
        disabled: true,
        completed: 0,
        pollQuestion: "",
        createdAt: "",
        endDate: "",
        isLoading: true,
        token: simpleCrypto.decrypt(localStorage.getItem('junkStore'))
    }
    componentDidMount() {
        this.getPollsResultsById();
    }
    getPollsResultsById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + `user/getPollResultById/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                 //   console.log("success");
                    const result = response.data.pollResult
                    self.setState({
                        topic: result.topic,
                        createdAt: result.createdAt,
                        pollQuestion: result.question,
                        endDate: result.endDate,
                        options: result.options,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });

    };

    sharePoll = (d) => {
        if (navigator.share) {
            navigator.share({
                url: `https://www.sceem.org/poll/${d}/${this.props.match.params.id}`,
            }).then(() => {
               // console.log('Thanks for sharing!', d);
            }).catch(err => {
                console.log("Error while using Web share API:",err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
    }
    render() {

        const { isLoading, pollQuestion, options, count, topic, uAns, userAnswer, createdAt, endDate } = this.state
        if (isLoading) {
            return <div></div>
        }
        var percent = options.map((opt, idx) => (
            Math.round((opt.percentage + Number.EPSILON) * 100 / 100)
        ))
        //console.log("percentakdhs", percent)
        return (
            <div className="poll-bg"> <div >
                <div className="topblue_bar"><img alt="" src={bluelogo} className="login-logo" />
                </div>
                <div>

                    <div className="answers-card">
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "-1.5rem", marginLeft: "1.8rem", marginRight: "1.5rem" }}>
                            <center className="topic-top"><span className="topic-name">{topic.replace(/ *\([^]*\) */g, "")}</span></center>
                            <div className="poll-share-icon"> {moment(endDate).format("DD-MM-YYYY") >= moment(Date.now()).format("DD-MM-YYYY") ? <AiOutlineShareAlt onClick={() => this.sharePoll(moment(createdAt).format("YYYYMMDD"))} /> : ""}</div></div>
                        <Col xs={12}>
                            <Card className="mt-3 poll-answer-card">
                                <div className="poll-question">{pollQuestion}</div><br />
                                {options.map((opt, idx) => (
                                    <Progressbar uAns={opt.isAnswer} id={opt.id} options={opt.option} percentage={percent[idx]} />

                                ))}
                            </Card></Col>
                        <br />
                        <Link to="/polls/historypolls"> <center><Button variant="default" className="goback-poll-btn"><b> &nbsp;Go Back to Polls &nbsp;</b></Button></center></Link>
                    </div>
                </div>
            </div >
                <br /><br />
                <BottomMenu />
            </div >
        )
    }

}

// const Progressbar = (props) => {
//     const {percentage, options, uAns, selected, id} = props;

//     const containerStyles = {
//         height: 50,
//         width: '80%',
//         backgroundColor: "white",
//         borderRadius: 10,
//         margin: 10,
//         border: "2px solid lightgrey"
//     }
//     const fillerStyles = {
//         height: '100.5%',
//         width: `${percentage}%`,
//         backgroundColor: "#EEEBFF",
//         transition: 'width 1s ease-in-out',
//         border: "1px solid #b0a4f5",
//         borderRadius: 8,
//         marginTop: '-0.09px',
//         textAlign: 'right'
//     }
//     const isSelected = {
//         border: "2px solid #DB6C00",
//         height: 50,
//         width: '80%',
//         backgroundColor: "white",
//         borderRadius: 10,
//         margin: 10
//     }
//     const labelStyles = {
//         color: 'black',
//         borderColor: "#362B79",
//         fontWeight: 600
//     }
//     return (
//         <div style={uAns ? isSelected : containerStyles}><span style={{ marginTop: "8px", marginRight: "4px", fontWeight: "600", float: "right" }}>{`${percentage}%`}</span>
//             <div style={fillerStyles} >
//                 <span style={labelStyles} ><span style={{ marginLeft: "5px", marginTop: "8px", marginBottom: "8px", float: "left" }}>{options}</span></span>
//             </div>
//         </div>
//     );
// };
const Progressbar = (props) => {
    const { percentage, options, uAns, selected, id } = props;

    const containerStyles = {
        height: 70,
        width: '98%',
        backgroundColor: "white",
        borderRadius: 10,
        margin: 10,
        border: "2px solid lightgrey",
    }
    const fillerStyles = {
        height: '100.5%',
        maxWidth: "100%",
        backgroundColor: "#EEEBFF",
        border: "1px solid #b0a4f5",
        borderRadius: 8,
        marginTop: '-0.09px',
        transition: "width 4s",
        transitionTimingFunction: "linear",
        width: `${percentage}%`,
        // animationDuration: "4s",
        // animationName: "slidein",
        // animationDirection: "normal",
    }
    const isSelected = {
        border: "2px solid #DB6C00",
        height: 70,
        width: '98%',
        backgroundColor: "white",
        borderRadius: 10,
        margin: 10
    }
    const labelStyles = {
        color: 'black',
        borderColor: "#362B79",
        fontWeight: 500,
        width: '100%',
        fontFamily: "Noto Sans",
        fontSize: "14px",
        display: "relative"

    }

    const inEffect = `
                @keyframes slidein {
                    from {
                    marginRight: "100%",
        }

                to {
                    marginRight: "0%",
        }
    }
                `;
   // console.log("uAns", uAns)
    return (
        <div style={uAns ? isSelected : containerStyles}>
            <span style={{ marginTop: "20px", marginRight: "4px", fontWeight: "600", float: "right" }}>{`${percentage}%`}</span>
            <span style={labelStyles} >
                <span style={{ marginLeft: "5px", width: "75%", marginTop: "8px", marginBottom: "8px", float: "left" }}>{options}</span>
                <div style={fillerStyles} >
                </div>

            </span>


        </div>
    );
};