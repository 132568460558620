import React, { Component } from "react";
import { Button, Container, Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import './Interests.css';
import axios from 'axios';
import { config } from '../Config/Config';
import back from '../images/backarrow.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Authors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            authors: [],
            authorIds: [],
            userFollowedPeople: [],
            isLoading: true,
            isFirstTime: false,
            redirect: false
        }
        this.onAddingItem = this.onAddingItem.bind(this)
    }
    handleBack = () => {
        this.props.history.goBack()
    }
    onAddingItem = (e) => {
        const isChecked = e.target.checked;
        const value = e.target.value;
        const { authors } = this.state
        //console.log("item", isChecked, value)
        const updated = authors.map(data => (data.uuid === value) ?
            { ...data, isAdded: !data.isAdded } : data)
       // console.log("update", updated)
        this.setState({ authors: updated, isLoading: false })
        // const isChecked = item.target.checked;
        // const value = item.target.value;
        // console.log("item", isChecked)
        // this.setState(prevState => ({ authors: prevState.authors.map(data => data.uuid === value ? { ...data, isAdded: isChecked } : data) }));
        // if (isChecked)
        //     this.setState(prevState => ({ authorIds: [...prevState.authorIds, value] }));
        // else {
        //     const newAddedAuthors = this.state.authorIds.filter(data => data !== value)
        //     this.setState({ authorIds: newAddedAuthors });
        // }
    }
    componentDidMount = () => {
        this.getAuthors();
        this.getUserFollowedPeople();
    }
    getAuthors = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAuthors', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        authors: response.data.authors,
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    getUserFollowedPeople = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getUserFollowedPeople', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                   // console.log("getUserFollowedPeople");
                    self.setState({
                        userFollowedPeople: response.data.userFollowedPeople
                    })
                    if (self.state.userFollowedPeople.length === 0) {
                       // console.log("FIRSTTIME USER")
                        self.setState({
                            isFirstTime: true
                        })
                    } else {
                        console.log("USER")
                    }
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    postAuthors = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        const { authors } = this.state;
        let authorId = [];
        authorId = authors.filter(item => (item.isAdded === true) ? item.uuid : null)
       // console.log("authorId", authorId)
        var payload = {
            "authorIds": authorId.map(item => item.uuid),
            "isFirstTime": this.state.isFirstTime
        }
        axios.post(config.userUrl + 'user/followAuthors', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                  //  console.log("success", payload);
                    window.location.href = "/home";
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        const { userFollowedPeople, isFirstTime, authors, isLoading } = this.state
        let authorId = [];
        authorId = authors.filter(item => (item.isAdded === true) ? item.uuid : null)
        function checkUserFollowedPeople(checkItem) {
            return (userFollowedPeople.filter(item => item.uuid === checkItem.uuid).length !== 0)
        }
        if (isLoading) {
           // console.log("isLoading")
            authors.forEach(item => checkUserFollowedPeople(item) ? item.isAdded = true : item.isAdded = false)
        }
        // let authorId = [];
        // authorId = authors.filter(item => (item.isAdded === true) ? item.id : null)
        // console.log("length", authorId.length)
        // function checkTopic(checkItem) {
        //     return (userTopics.filter(item => item.id == checkItem.id).length != 0)
        // }
        // if (isLoading) {
        //     console.log("isLoading")
        //     allTopics.forEach(item => checkTopic(item) ? item.isAdded = true : item.isAdded = false)
        // }
        return (
            <div>
                {/* <TopMenu /> */}
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
                <img alt="" src={back} className="landing-Image" onClick={this.handleBack} /><br />
                <p className="follow-text">Follow few people to get started</p>
                <center>
                    <div>
                        <Container>
                            <Row>
                                {authors.map((data, i) => {
                                    return (
                                        <Col xs={4} >
                                            <label class="checkbox-checked">
                                                <input type="checkbox"
                                                    value={data.uuid}
                                                    checked={authors[i].isAdded}
                                                    onChange={(e) => this.onAddingItem(e)}
                                                    className={authors[i].isAdded ? "display-checkbox" : "hide-checkbox"}
                                                    style={{ float: "right" }} /><img alt="" src={data.profilePicture} className="auth-icon" />
                                                <p className="text mr-2">{data.name}</p></label></Col>
                                    )
                                })}
                            </Row>

                        </Container>
                    </div>
                </center>
                <p className="landing-text-sm">You can always change your interests in the profile &gt;&gt;&nbsp;Interests</p>
                {/* {authorId.length == 0 ? <div><center>Please follow any of the authors</center><br /></div> :
                    <div> {isFirstTime ?
                        <Link to="/home"><Button variant="default" onClick={this.postAuthors} className="landing-btn float">Next</Button></Link>
                        :
                        <Button variant="default" onClick={this.postAuthors} className="landing-btn float">Save</Button>
                    } </div>
                } */}
                {/* {authorId.length == 0 ? <div><center>Please follow any of the authors</center><br /></div> :
                    <Button variant="default" onClick={this.postAuthors} className="landing-btn float">Next</Button>
                } */}
                <div> {isFirstTime ?
                    <Link to="/authors">
                        <Button variant="default" onClick={this.postAuthors} className="landing-btn float">Next</Button></Link>
                    :
                    <Link to="/home"><Button variant="default" onClick={this.postAuthors} className="landing-btn float">Save</Button></Link>

                } </div>


            </div>
        )
    }
}
