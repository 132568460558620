import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Modal } from "react-bootstrap";
import { Link, useLocation } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import { enqueueSnackbar } from 'notistack'
import article from '../images/article-new.jpeg';
import smefullday from '../images/smefullday.svg';
import smemorning from '../images/smemorning.svg';
import smeafternoon from '../images/smeafternoon.svg';
import smebookings from '../images/smebookings.svg';
import smerating from '../images/smerating.svg';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default function SMESlots() {
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [allTimeSlots, setAllTimeSlots] = useState([
        { id: 0, "time": "8AM" },
        { id: 2, "time": "10AM" },
        { id: 3, "time": "2PM" }
    ]);
    const [currDate, setCurrDate] = useState()
    const [selectedId, setSelectedId] = useState()
    const [showPopup, setShowPopup] = useState(false)
    const today = new Date();
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const [googleLink, setGoogleLink] = useState()
    const [agenda, setAgenda] = useState()




    useEffect(() => {
        const formattedDate = `${today.getDate()}-${months[today.getMonth()]}-${today.getFullYear()}`;
        setCurrDate(formattedDate)
        // getSmeUserData();
        // getAllSmeOfferings();
        // getAllDays();
        // getAllTimeSlots();
        // console.log("userId",userId)
    }, [])
    const handleShowLinkPopup = (id) => {
        setSelectedId(id)
        setShowPopup(true)
    }
    // const getSmeUserData = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + token,
    //         "applicationType": "mobile"
    //     }
    //     var payload = {
    //         "userId": userId
    //     }
    //     axios.post(config.userUrl + 'user/getSmeUserData', payload, { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status === true) {
    //                 setDesc(response.data.data[0].desc)
    //                 setSelectedDay(response.data.data[0].day)
    //                 setSelectedTimeSlot(response.data.data[0].time)
    //                 setSelectedOfferings(response.data.data[0].offering)
    //                 setRating(response.data.data[0].rating)
    //                 setProfilePicture(response.data.data[0].profilePicture)
    //                 setName(response.data.data[0].name)
    //                 setBookings(response.data.data[0].bookings)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error");
    //         });
    // };
    // const getAllSmeOfferings = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + token,
    //         "applicationType": "mobile"
    //     }
    //     axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status === true) {
    //                 setAllOfferings(response.data.offerings)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error");
    //         });
    // };
    // const getAllDays = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + token,
    //         "applicationType": "mobile"
    //     }
    //     axios.get(config.userUrl + 'user/getAllDays', { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status === true) {
    //                 setAllDays(response.data.days)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error");
    //         });
    // };
    // const getAllTimeSlots = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + token,
    //         "applicationType": "mobile"
    //     }
    //     axios.get(config.userUrl + 'user/getAllTimeSlots', { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status === true) {
    //                 setAllTimeSlots(response.data.timeSlots)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error");
    //         });
    // };
    // const postSmeOffering = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + token,
    //         "applicationType": "mobile"
    //     }
    //     var payload = {
    //         "isFirstTime": false,
    //         "offering": selectedOfferings,
    //         "bookings": 1,
    //         "rating": "4.2",
    //         "day": parseInt(selectedDay),
    //         "desc": desc,
    //         "time": parseInt(selectedTimeSlot)
    //     }
    //     axios.post(config.userUrl + 'user/addSmeOffering', payload, { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status === true) {
    //                 console.log("success")
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error");
    //         });
    // };
    return (
        <div >
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="screen-topgap">
                <div className="your-jitbits">Available Slots ({currDate})</div>
                <div className="ml-2 mr-2 mb-2 sme-planned-border">
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        {allTimeSlots.slice(0, 1).map((data, i) => (
                            <div className="m-2 ml-2 mr-2 slot-time" onClick={() => handleShowLinkPopup(data.id)}><center><img src={smefullday} style={selectedId === data.id ? { border: "1px solid black", background: "#ff942c", borderRadius: "15px", padding: "4px" } : { border: "1px solid black", borderRadius: "15px", padding: "4px" }} /><br />{data.time}</center></div>
                        ))}
                        {allTimeSlots.slice(1, 2).map((data, i) => (
                            <div className="m-2 ml-2 mr-2 slot-time" onClick={() => handleShowLinkPopup(data.id)}><center><img src={smemorning} style={selectedId === data.id ? { border: "1px solid black", background: "#ff942c", borderRadius: "15px", padding: "4px" } : { border: "1px solid black", borderRadius: "15px", padding: "4px" }} /><br />{data.time}</center></div>
                        ))}
                    </div>
                    <Modal show={showPopup} size="lg"
                        aria-labelledby="contained-modal-title-vcenter"

                        centered>
                        <Modal.Body>
                            <div>
                                <center className="sme-slot-popup-heading">{currDate} @ 4:00 PM</center>
                                <input type="text"
                                    className="mt-2 web-font form-control"
                                    aria-describedby="text"
                                    name="googleLink"
                                    style={{ border: '1px solid black', borderRadius: "5px", width: "100%" }}
                                    value={googleLink}
                                    onChange={(e) => setGoogleLink(e.target.value)}
                                    placeholder="Google Meet/Zoom Link"
                                />
                                <textarea type="text"
                                    className="mt-2 web-font form-control"
                                    aria-describedby="text"
                                    name="agenda"
                                    style={{ overflowY: "none", border: '1px solid black', borderRadius: "5px", width: "100%", resize: "none" }}
                                    value={agenda}
                                    onChange={(e) => setAgenda(e.target.value)}
                                    rows={4}
                                    placeholder="Agenda"
                                />
                                <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div></div>
                                    <Button variant="default" className="mt-1 prev-btn-sme" onClick={() => setShowPopup(false)}>Submit</Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>

                {/* <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div></div>
                    <Button variant="default" className="mr-2 mt-1 prev-btn-sme" >Connect</Button>
                </div> */}
            </div>
            <div className="mb-bottom"></div>
            <BottomMenu />
        </div >
    )
}
