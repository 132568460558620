import React, { Component } from "react";
import { Button, Form, Col, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import circle from '../images/circle.png';
import circleblue from '../images/circle-blue.png';
import google from '../images/google.png';
import facebook from '../images/facebook.png';
import back from '../images/backarrow.png';
import './SignUp.css';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validEmailRegex =
    /^[a-z0-9+_.-]+@[a-z0-9.-]+$/
const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/
const validPhoneNoRegex = RegExp(
    /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/i
);
// const validateForm = errors => {
//     let valid = true;
//     Object.values(errors).forEach(val => val.length > 0 && (valid = false));
//     return valid;
// };
export default class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            phone: "",
            ageGroup: "",
            gender: "",
            experience: "",
            company: "",
            designation: "",
            city: "",
            country: "",
            password: "",
            confirmPassword: "",
            fourEsError: "",
            registerFlow: true,
            showPassword: false,
            showConfirmPassword: false,
            nameError: '',
            emailError: '',
            phoneNoError: '',
            passwordError: '',
            confirmPasswordError: '',
            ageGroupError: '',
            genderError: '',
            experienceError: '',
            companyError: '',
            designationError: '',
            cityError: '',
            countryError: '',
            fourEsError: '',
            verifyEmailPage: false,
            loginPage: false,
            checked: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.validateName = this.validateName.bind(this);
        this.validateEmailAddress = this.validateEmailAddress.bind(this);
        this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
        this.validateGender = this.validateGender.bind(this);
        this.validateAgeGroup = this.validateAgeGroup.bind(this);
        this.validateExperience = this.validateExperience.bind(this);
        this.validateCompany = this.validateCompany.bind(this);
        this.validateDesignation = this.validateDesignation.bind(this);
        this.validateCity = this.validateCity.bind(this);
        this.validateCountry = this.validateCountry.bind(this);
        this.validateFourEs = this.validateFourEs.bind(this);
        this.validateField = this.validateField.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
    }
    handleCheck() {
        this.setState({ checked: !this.state.checked })
    }
    onChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }
    onChangeEmail(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
       // console.log(this.state.email)

        return;
    }

    handleBlur(event) {
        const { name } = event.target;
        this.validateField(name);
        return;
    }

    handleSubmit(event) {
        event.preventDefault();
      //  console.log(this.state);
        localStorage.setItem('name', JSON.stringify(this.state.name));
        let formFileds = [
            "name",
            "email",
            "phone",
            "password",
            "confirmPassword",
            "ageGroup",
            "gender",
            "experience",
            "company",
            "designation",
            "city",
            "country",
            "fourEs",

        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = this.validateField(field) && isValid;
        });
        if (!isValid) {
           // console.log("Please enter the details")
            document.getElementById('status').innerHTML = "<div>Please enter valid details</div>"
        } else {
            this.postDetails()
        }
    }
    checkEmail = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var payload = {
            "email": this.state.email
        }
        axios.post(config.apiUrl + 'auth/checkEmailStatus', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    document.getElementById('true').innerHTML = '<i class="fa fa-check" id="true" aria-hidden="true"></i>'

                } else {
                   // console.log(response.data.message)
                    document.getElementById('error-msg').innerText = "Email already registered!"
                    document.getElementById('false').innerHTML = '<i class="fa fa-times" id="false" aria-hidden="true"></i>'
                }
            })
            .catch(function (error) {
                console.log("error", payload,error)
              //  console.log(error);
                // document.getElementById('error').innerText = error
                // document.getElementById('false').innerHTML = '<i class="fa fa-times" id="false" aria-hidden="true"></i>'
            });
    }
    postDetails = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var encryptedPassword = simpleCrypto.encrypt(this.state.password);
        var encryptedConfirmPassword = simpleCrypto.encrypt(this.state.confirmPassword);
        var self = this;
        var payload = {
            "name": this.state.name,
            "email": this.state.email,
            "phone": this.state.phone,
            "ageGroup": this.state.ageGroup,
            "gender": this.state.gender,
            "experience": this.state.experience,
            "company": this.state.company,
            "designation": this.state.designation,
            "city": this.state.city,
            "country": this.state.country,
            "password": encryptedPassword,
            "confirmPassword": encryptedConfirmPassword,
            "fourEs": this.state.fourEs,
            "registerFlow": this.state.registerFlow
        }
        axios.post(config.apiUrl + 'auth/register', payload, { headers: headers })
            .then(function (response) {
                if (response.data.userStatus === "verified") {
                  //  console.log("User Registered successfully!!")
                    self.setState({
                        loginPage: true
                    })
                } else {
                    self.setState({
                        verifyEmailPage: true
                    })
                    console.log(response.data.message)
                    // document.getElementById('status').innerText = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                }
            })
            .catch(function (error) {
                console.log("error",error)
                document.getElementById('error').innerText = error
            });
    }
    validateField(name) {
        let isValid = false;
        if (name === "name") isValid = this.validateName();
        else if (name === "email") isValid = this.validateEmailAddress();
        else if (name === "phone") isValid = this.validatePhoneNumber();
        else if (name === "password") isValid = this.validatePassword();
        else if (name === "confirmPassword") isValid = this.validateConfirmPassword();
        else if (name === "gender") isValid = this.validateGender();
        else if (name === "ageGroup") isValid = this.validateAgeGroup();
        else if (name === "company") isValid = this.validateCompany();
        else if (name === "experience") isValid = this.validateExperience();
        else if (name === "designation") isValid = this.validateDesignation();
        else if (name === "city") isValid = this.validateCity();
        else if (name === "country") isValid = this.validateCountry();
        else if (name === "fourEs") isValid = this.validateFourEs();
        return isValid;
    }

    validateName() {
        let nameError = "";
        const value = this.state.name;
        if (value.trim() === "") nameError = "Name must be at least 5 characters long!";
        else if (value.length < 5)
            nameError = 'Name must be at least 5 characters long!';
        else if (value.length > 50)
            nameError = 'Name should not be more than 50 characters long!';
        this.setState({
            nameError
        });
        return nameError === "";
    }

    validateEmailAddress() {
        let emailError = "";
        const value = this.state.email;
        this.checkEmail()
        if (value.trim === "") emailError = "Email is required";
        else if (!validEmailRegex.test(value))
            emailError = "Please Enter Email";
        this.setState({
            emailError
        });
        return emailError === "";
    }

    validatePhoneNumber() {
        let phoneNoError = "";
        const value = this.state.phone;
        if (value.trim === "") phoneNoError = "Please enter valid phone number";
        else if (!validPhoneNoRegex.test(value))
            phoneNoError =
                "Please enter valid phone number";
        this.setState({
            phoneNoError
        });
        return phoneNoError === "";
    }

    validatePassword() {
        let passwordError = "";
        const value = this.state.password;
        if (value.trim === "") passwordError = "Password is required";
        else if (!validPasswordRegex.test(value))
            passwordError = "Min 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        this.setState({
            passwordError
        });
        return passwordError === "";
    }

    validateConfirmPassword() {
        let confirmPasswordError = "";
        if (this.state.password !== this.state.confirmPassword)
            confirmPasswordError = "Password does not match";
        this.setState({
            confirmPasswordError
        });
        return confirmPasswordError === "";
    }
    validateAgeGroup() {
        let ageGroupError = "";
        const value = this.state.ageGroup;
        if (value.trim() === "") ageGroupError = "Please select the age group";
        else if (value.length < 5)
            ageGroupError = 'Please select the age group';
        this.setState({
            ageGroupError
        });
        return ageGroupError === "";
    }
    validateGender() {
        let genderError = "";
        const value = this.state.gender;
        if (value.trim() === "") genderError = "Please select the gender";
        this.setState({
            genderError
        });
        return genderError === "";
    }
    validateCompany() {
        let companyError = "";
        const value = this.state.company;
        if (value.trim() === "") companyError = "Please add the company";
        else if (value.length < 4)
            companyError = 'Please add the company';
        this.setState({
            companyError
        });
        return companyError === "";
    }
    validateExperience() {
        let experienceError = "";
        const value = this.state.experience;
        if (value.trim() === "") experienceError = "Please select the experience";
        else if (value.length < 3)
            experienceError = 'Please select the experience';
        this.setState({
            experienceError
        });
        return experienceError === "";
    }
    validateDesignation() {
        let designationError = "";
        const value = this.state.designation;
        if (value.trim() === "") designationError = "Please add the designation";
        else if (value.length < 5)
            designationError = 'Please add the designation';
        this.setState({
            designationError
        });
        return designationError === "";
    }
    validateCity() {
        let cityError = "";
        const value = this.state.city;
        if (value.trim() === "") cityError = "Please add the city";
        else if (value.length < 3)
            cityError = 'Please add the city';
        this.setState({
            cityError
        });
        return cityError === "";
    }
    validateCountry() {
        let countryError = "";
        const value = this.state.country;
        if (value.trim() === "") countryError = "Please add the country";
        else if (value.length < 4)
            countryError = 'Please add the country';
        this.setState({
            countryError
        });
        return countryError === "";
    }
    validateFourEs() {
        let fourEsError = "";
        const value = this.state.fourEs;
        if (value.trim() === "") fourEsError = "Please select any of the 4Es";
        this.setState({
            fourEsError
        });
        return fourEsError === "";
    }
    showPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };
    showConfirmPassword = () => {
        this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    };
    render() {
        const { errors, verifyEmailPage, loginPage } = this.state

        if (verifyEmailPage) {
            return <Redirect
                to={{
                    pathname: "/verify",
                    state: { email: this.state.email, registerFlow: this.state.registerFlow, name: this.state.name }
                }} />
        }
        if (loginPage) {
            return <Redirect
                to={{
                    pathname: "/",
                    state: { email: this.state.email, registerFlow: this.state.registerFlow, name: this.state.name }
                }} />
        }
        return (
            <div>
                <div className="topbar"> <img alt="" src={loginlogo} className="login-logo mt-2" />
                    <img alt="" src={circle} className="-Circle" />
                    <Link to="/"><img alt="" src={back} className="back_arrow_" /></Link>
                    <p className="mt-2 signup-text">Sign up for&nbsp;<span className="_sceem">Sceem</span></p>
                </div>
                <img alt="" src={circleblue} className="circle_Blue" />
                <Form onSubmit={this.handleSubmit} className="form-signup">
                    <center>
                        <Row style={{ width: "100%" }}>
                            <Col xs={12}>
                                <div style={{ width: "90%" }} >
                                    <div className="login-credentials">Login credentials</div><br />
                                    <div className="box-form">
                                        <div className="form-group mt-2">
                                            <input type="email"
                                                className="form-control text-lowercase form-bg"
                                                aria-describedby="text"
                                                value={this.state.email}
                                                name="email"
                                                placeholder="Email*"
                                                onChange={this.onChangeEmail}
                                                onBlur={this.handleBlur}
                                            />
                                            <span id="true" className="showPassword tick-color-green"
                                            ></span>
                                            <span id="false" className="tick-color-red showPassword "
                                            ></span>
                                            <div className="div">{this.state.emailError && (
                                                <div className="error">{this.state.emailError}</div>
                                            )}</div>
                                            <div id="error-msg" className="div error">
                                            </div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <input type={this.state.showPassword ? "text" : "password"}
                                                name="password"
                                                className="form-control form-bg"
                                                value={this.state.password}
                                                placeholder="Password*"
                                                onChange={this.onChange}
                                                onBlur={this.handleBlur}
                                            />
                                            <span className="showPassword"
                                                onClick={this.showPassword}
                                            >
                                                {this.state.showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                            </span>
                                            <div className="div">{this.state.passwordError && (
                                                <div className="error">{this.state.passwordError}</div>
                                            )}</div>
                                        </div>
                                        {/* <p className="sm-text mt-3">Your password should contain at least one alphabet, one numeric and should be 5 to 15 characters long.</p> */}
                                        <div className="form-group mt-2">
                                            <input type={this.state.showConfirmPassword ? "text" : "password"}
                                                name="confirmPassword"
                                                className="form-control form-bg"
                                                value={this.state.confirmPassword}
                                                placeholder="Confirm Password*"
                                                onChange={this.onChange}
                                                onBlur={this.handleBlur}
                                            />
                                            <span className="showPassword"
                                                onClick={this.showConfirmPassword}
                                            >
                                                {this.state.showConfirmPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                            </span>
                                            <div className="div">{this.state.confirmPasswordError && (
                                                <div className="error">{this.state.confirmPasswordError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <select name="fourEs" value={this.state.fourEs} onBlur={this.handleBlur} onChange={this.onChange} class="form-control form-bg">
                                                <option value="">4Es*</option>
                                                <option value="Enterprises">Enterprises</option>
                                                <option value="Entrepreneurs">Entrepreneurs</option>
                                                <option value="Experts">Experts</option>
                                                <option value="Enthusiasts">Enthusiasts</option>
                                            </select>
                                            <div className="div">{this.state.fourEsError && (
                                                <div className="error">{this.state.fourEsError}</div>
                                            )}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12}>

                                <div style={{ width: "90%" }} >
                                    <div className="login-credentials">About you</div><br />
                                    <div className="box-form">
                                        <div className="form-group mt-2">
                                            <input type="text"
                                                className="form-control form-bg"
                                                aria-describedby="text"
                                                value={this.state.name}
                                                name="name"
                                                placeholder="Name*"
                                                onChange={this.onChange}
                                                onBlur={this.handleBlur}
                                            />
                                            <div className="div">{this.state.nameError && (
                                                <div className="error">{this.state.nameError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <input type="number"
                                                className="form-control form-bg"
                                                aria-describedby="number"
                                                value={this.state.phone}
                                                name="phone"
                                                placeholder="Mobile*"
                                                onChange={this.onChange}
                                                onBlur={this.handleBlur}
                                            />
                                            <div className="div">{this.state.phoneNoError && (
                                                <div className="error">{this.state.phoneNoError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <select name="gender" onBlur={this.handleBlur} onChange={this.onChange} class="form-control form-bg">
                                                <option value="">Gender*</option>
                                                <option value={0}>Male</option>
                                                <option value={1}>Female</option>
                                                <option value={2}>Others</option>
                                            </select>
                                            <div className="div">{this.state.genderError && (
                                                <div className="error">{this.state.genderError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <select name="ageGroup" value={this.state.ageGroup} onBlur={this.handleBlur} onChange={this.onChange} class="form-control form-bg">
                                                <option value="">Age Group*</option>
                                                <option value="18-25">18-25</option>
                                                <option value="25-35">25-35</option>
                                                <option value="35-55">35-55</option>
                                                <option value="55+">55+</option>
                                            </select>
                                            <div className="div">{this.state.ageGroupError && (
                                                <div className="error">{this.state.ageGroupError}</div>
                                            )}</div>
                                        </div>

                                        <div className="form-group mt-2">
                                            <input type="text"
                                                className="form-control form-bg"
                                                aria-describedby="text"
                                                value={this.state.city}
                                                name="city"
                                                placeholder="City*"
                                                onChange={this.onChange}
                                                onBlur={this.handleBlur}
                                            />
                                            <div className="div">{this.state.cityError && (
                                                <div className="error">{this.state.cityError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <input type="text"
                                                className="form-control form-bg"
                                                aria-describedby="text"
                                                value={this.state.country}
                                                name="country"
                                                placeholder="Country*"
                                                onChange={this.onChange}
                                                onBlur={this.handleBlur}
                                            />
                                            <div className="div mb-4">{this.state.countryError && (
                                                <div className="error">{this.state.countryError}</div>
                                            )}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div style={{ width: "90%" }}>
                                    <div className="login-credentials">About your work</div><br />
                                    <div className="box-form">
                                        <div className="form-group mt-2">
                                            <input type="text"
                                                className="form-control form-bg"
                                                aria-describedby="text"
                                                value={this.state.designation}
                                                name="designation"
                                                placeholder="Designation*"
                                                onChange={this.onChange}
                                                onBlur={this.handleBlur}
                                            />
                                            <div className="div">{this.state.designationError && (
                                                <div className="error">{this.state.designationError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <input type="text"
                                                className="form-control form-bg"
                                                aria-describedby="text"
                                                value={this.state.company}
                                                name="company"
                                                placeholder="Company*"
                                                onChange={this.onChange}
                                                onBlur={this.handleBlur}
                                            />
                                            <div className="div">{this.state.companyError && (
                                                <div className="error">{this.state.companyError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <select name="experience" value={this.state.experience} onBlur={this.handleBlur} onChange={this.onChange} class="form-control form-bg">
                                                <option value="">Experience*</option>
                                                <option value="0-5">0-5</option>
                                                <option value="5-10">5-10</option>
                                                <option value="10-15">10-15</option>
                                                <option value="15-20">15-20</option>
                                                <option value="20+">20+</option>
                                            </select>
                                            <div className="div">{this.state.experienceError && (
                                                <div className="error">{this.state.experienceError}</div>
                                            )}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div className="signup-terms"><input type="checkbox" checked={this.state.checked} onClick={this.handleCheck} /> <span className="signup-agree-text">I agree to the <Link to="/terms"><span className="signup-termslink-color">Terms & Conditions </span></Link>and <Link to="/dataprivacy"><span className="signup-termslink-color">Privacy Policy</span></Link></span></div><br />
                        <div style={{ marginTop: "0.8rem" }}>
                            <div id="status" className="mb-1 errormsg"></div>
                            <div id="error" className="mb-1 errormsg"></div>
                            {(this.state.email !== "" && this.state.password !== "" && this.state.confirmPassword !== "" && this.state.name !== "", this.state.phone !== "", this.state.ageGroup !== "" && this.state.gender !== "" && this.state.city !== "" && this.state.country !== "" && this.state.designation !== "", this.state.company !== "" && this.state.experience !== "") ?
                                <Button variant="default" type="submit" disabled={!this.state.checked} className="btn-block signup-btn">Sign Up</Button> : <Button variant="default" type="submit" disabled={true} className="btn-block signup-btn">Sign Up</Button>}<br />
                        </div>
                    </center>
                </Form>
            </div >
        );
    }
}
