import React, { Component } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import './Interests.css';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)
// import { ScrollMenu } from "react-horizontal-scrolling-menu";

export default class AuthorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: localStorage.getItem('name'),
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            posts: [],
            authorName: "",
            profilePicture: "",
            role: "",
            description: "",
            followers: "",
            following: "",
            likes: "",
            smeTopics: [],
            smeOfferings: [],
            allOfferings: []


        }
    }
    componentDidMount() {
       // console.log("profile", this.props)
        this.getAuthorProfile();
    }
    getAuthorProfile = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        var payload = {
            "authorId": this.props.match.params.id
        }
        axios.post(config.userUrl + `user/getAuthorProfile`, payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                   // console.log("success!!");
                    self.setState({
                        posts: response.data.posts,
                        authorName: response.data.authorDetails.name,
                        profilePicture: response.data.authorDetails.profilePicture,
                        role: response.data.userRole[0]?.roleType,
                        description: response.data.authorDetails.description,
                        followers: response.data.followerCount,
                        following: response.data.followingCount,
                        likes: response.data.likeCount
                    })
                    if (response.data.userRole[0]?.roleType === "sme") {
                        self.getAllSmeOfferings()
                        self.getSmeUserData()
                    }
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getSmeUserData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "userId": this.props.match.params.id
        }
        var self = this;
        axios.post(config.userUrl + 'user/getSmeUserData', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        smeTopics: response?.data?.topics,
                        smeOfferings: response?.data?.data[0]?.offering
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    self.setState({ allOfferings: response.data.offerings })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        const { posts, role, smeTopics, smeOfferings, allOfferings } = this.state;
        const roleName = role?.charAt(0).toUpperCase() + role?.slice(1)
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>

                <div style={{ marginTop: "4.7rem" }}>
                    <center><img alt="" src={config.userUrl + this.state.profilePicture} className="Auth-img" />
                        <div className="user-name">{this.state.authorName}</div>
                        <div className="author-text mb-2">{roleName === "Sme" ? "SME" : roleName}</div>
                        <div className="author-text ml-5 mr-5">{this.state.description}</div>
                        {/* <Button variant="default" className="follow-btn">Follow</Button><br /><br /> */}
                        <Card className="card-width-sm mt-3">
                            <Row style={{ width: "100%" }}>
                                <Col className="ml-4">
                                    <div className="numbers">{this.state.followers}</div>
                                    <div className="author-text" >Followers</div>
                                </Col>
                                <Col>
                                    <div className="numbers">{this.state.following}</div>
                                    <div className="author-text">Following</div>
                                </Col>
                                <Col>
                                    <div className="numbers">{this.state.likes}</div>
                                    <div className="author-text">Likes</div>
                                </Col>
                            </Row>
                        </Card>
                    </center>
                    {role === "author" ?
                        <div>
                            {posts.length !== 0 ?
                                <div ><br />
                                    <div className="posts mt-2 ml-4">Posts</div>
                                    <div className="ml-3 mb-2 mr-3" >
                                        <Card className="author-Card" >
                                            {posts.map((data, i) => (
                                                <Link to={{ pathname: `/article/${data.title.replace(/ /g, "_")}/${data.id}` }} className="ml-2 mr-2 link">
                                                    <Card style={{ display: "flex" }} className="favbits-card">
                                                        <Row style={{ width: "100%" }} className="no-gutters">
                                                            <Col xs={2}>
                                                                <img alt="" src={"../" + data.topicName.replace(/ *\([^]*\) */g, "") + ".png"} className="ml-2 fav-bits-topicicon" />
                                                            </Col>
                                                            <Col xs={10}>
                                                                {data.title !== null && data.title > data.title.substring(0, 70) ?
                                                                    <div className="favbits-title mr-1">{data.title.substring(0, 70)}...</div> :
                                                                    <div className="favbits-title mr-1">{data.title}</div>}
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Link>
                                            ))}
                                        </Card>
                                    </div>
                                </div>


                                : <center className="mt-5">Author hasn't published posts recently.</center>}

                        </div> :
                        (role === "sme") ?
                            <div className="mt-3 ml-3">
                                <div style={{ fontSize: "16px", fontWeight: 600 }}>Expertise</div>
                                {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {smeTopics.map((data, i) => (
                                        <div style={{ fontSize: "12px", fontWeight: 500, marginTop: "-2px" }}>{data.topicName},&nbsp;</div>
                                    ))}
                                </div> */}

                                <Card style={{ background: "#F6F6F6", width: "95.5%", borderRadius: "16px", padding: "2px 10px 10px 10px" }}>
                                    <div>
                                        {smeTopics.map((data, i) => (
                                            <div className="flex" style={{ lineHeight: "30px" }}>
                                                <span><img src={"../" + data.topicName + ".png"} style={{ width: "20px" }} alt="Topic icon" />&nbsp;</span>
                                                <span style={{ fontSize: "12px", marginTop: "2px", fontWeight: 500 }}>{data.topicName}&nbsp;</span><br />
                                            </div>
                                        ))}
                                    </div>
                                </Card>
                                <div className="mt-3" style={{ fontSize: "16px", fontWeight: 600 }}>Offerings</div>
                                <Card style={{ background: "#F6F6F6", width: "95.5%", borderRadius: "16px", padding: "1px 8px 8px 8px" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between"}}>
                                        {allOfferings.filter(data => smeOfferings.includes(data.id)).map((data, i) => (

                                            <div className="flex" style={{lineHeight: "14px" }}>
                                                <div>
                                                    <span><img src={"../" + data.text + ".svg"} alt={data.text} />&nbsp;</span>
                                                    <span className="mt-2 offerings-text">{data.text}</span><br />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Card>
                                {/* <div className="flex">
                                    {allOfferings.filter(data => smeOfferings.includes(data.id)).map((data, i) => (
                                        <center>
                                            <div>
                                                <img src={"../" + data.text + ".svg"} alt={data.text} style={{ width: "45px"}} />
                                                <div className="offerings-text">{data.text},&nbsp;</div>
                                            </div>
                                        </center>
                                    ))}
                                </div> */}
                                {/* <div className="flex">
                                    {allOfferings.filter(data => smeOfferings.includes(data.id)).map((data, i) => (
                                        <center>
                                            <div>
                                                <div className="offerings-text">{data.text},&nbsp;</div>
                                            </div>
                                        </center>
                                    ))}
                                </div> */}
                                <div className="mt-2">
                                    <Link to={`/profilesme/${this.props.match.params.id}`}>
                                        <Button
                                            variant="default"
                                            className="mt-2 mr-3 mb-1 sme-connect-next-btn"
                                            style={{ width: "auto" }}
                                        >
                                            My SME Profile
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                            :
                            <center className="mt-5"> </center>}
                    <br /><br /><br />
                </div>
                <BottomMenu />
            </div >
        )
    }
}
{/* <div className="article-main">
                                        <ScrollMenu>
                                            {posts.map((data, id) => (
                                                <div className="scroll-authorprofile-articles">
                                                    <Link to={`/article/${data.id}`}>
                                                        <Card className="authorprofile-card ml-3">
                                                            <Card.Img variant="top" src={data.image} className="jitbits-article-img" />
                                                            {data.title !== null && data.title > data.title.substring(0, 40) ?
                                                                <div> <Card.Title className="authorprofile-article-heading">{data.title.substring(0, 40)}...</Card.Title><span><img src={data.topicName.toLowerCase().replace(/ /g, '') + ".png"} className="authorprofile-img-topicIcon" /></span></div>
                                                                : <div><Card.Title className="authorprofile-article-heading">{data.title} </Card.Title><span><img src={"../" + data.topicName.toLowerCase().replace(/ /g, '') + ".png"} className="authorprofile-img-topicIcon" /></span></div>
                                                            }
                                                            <div style={{ display: "flex" }}>
                                                                <img src={data.authorProfilePicture} className="jitbits-img-Author" />
                                                            </div>
                                                        </Card>
                                                    </Link>
                                                </div>
                                            ))}
                                        </ScrollMenu>
                                    </div>
                                </div> */}
// <Card className="card-width">
//     <div className="posts">Posts</div>
//     <Row style={{ width: "100%" }}>
//         <Col xs={6}>
//             {posts.slice(0, 1).map((item, id) => {
//                 return (
//                     <div>  <Link to={`/article/${item.id}`}>
//                         <img src={item.image} style={{ marginLeft: "1.25rem", borderRadius: "12px", width: "100%", height: "100px" }} />
//                         {item.title !== null && item.title > item.title.substring(0, 18) ?
//                             <h5 style={{ fontSize: "13px", marginLeft: "1.35rem", marginBottom: "0.5rem", color: 'black' }}>{item.title.substring(0, 18)}...</h5> :
//                             <h5 style={{ fontSize: "13px", marginLeft: "1.35rem", marginBottom: "0.5rem", color: 'black' }}>{item.title}</h5>}
//                     </Link>
//                     </div>
//                 )
//             })}
//             <Col xs={12}>    {posts.slice(1, 2).map((item, id) => {
//                 return (
//                     <div>
//                         <Link to={`/article/${item.id}`}>
//                             <img src={item.image} style={{ width: "125%", marginLeft: "0.3rem", borderRadius: "12px", height: "100px" }} />
//                             {item.title !== null && item.title > item.title.substring(0, 18) ?
//                                 <h5 style={{ fontSize: "13px", marginLeft: "0.5rem", marginBottom: "0.5rem", color: 'black' }}>{item.title.substring(0, 18)}...</h5> :
//                                 <h5 style={{ fontSize: "13px", marginLeft: "0.5rem", marginBottom: "0.5rem", color: 'black' }}>{item.title}</h5>}
//                         </Link>
//                     </div>)
//             })}
//             </Col>
//         </Col>
//         <Col xs={6}>
//             {posts.slice(2, 3).map((item, id) => {
//                 return (
//                     <div>
//                         <Link to={`/article/${item.id}`}>
//                             <img src={item.image} style={{ width: "100%", marginLeft: "0.4rem", borderRadius: "12px", height: "223px" }} />
//                             {item.title !== null && item.title > item.title.substring(0, 18) ?
//                                 <h5 style={{ fontSize: "13px", marginLeft: "0.5rem", marginBottom: "0.5rem", color: 'black' }}>{item.title.substring(0, 18)}...</h5> :
//                                 <h5 style={{ fontSize: "13px", marginLeft: "0.5rem", marginBottom: "0.5rem", color: 'black' }}>{item.title}</h5>}
//                         </Link>
//                     </div>
//                 )
//             })}
//         </Col>
//     </Row>
// </Card>