import React, { Component } from "react";
import loginlogo from '../images/login-logo.png';
import back from '../images/backarrow.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import './Notifications.css';

export default class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commentsOnTopics: true,
            replies: true,
            mentions: true,
            likesOnTopics: true,
            likesOnComments: true,
            others: true
        }
        this._onTopics = this._onTopics.bind(this);
        this.onChangeReplies = this.onChangeReplies.bind(this)
        this.onChangeMentions = this.onChangeMentions.bind(this)
        this.onChangeLikesTopic = this.onChangeLikesTopic.bind(this)
        this.onChangeLikesComment = this.onChangeLikesComment.bind(this)
        this.onChangeOthers = this.onChangeOthers.bind(this)
    }
    _onTopics() {
        this.setState({ commentsOnTopics: !this.state.commentsOnTopics });
    }
    onChangeReplies() {
        this.setState({ replies: !this.state.replies });
    }
    onChangeMentions() {
        this.setState({ mentions: !this.state.mentions });
    }
    onChangeLikesTopic() {
        this.setState({ likesOnTopics: !this.state.likesOnTopics });
    }
    onChangeLikesComment() {
        this.setState({ likesOnComments: !this.state.likesOnComments });
    }
    onChangeOthers() {
        this.setState({ others: !this.state.others });
    }
    handleBack = () => {
        this.props.history.goBack()
    }
    render() {
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <img alt="" src={back} onClick={this.handleBack} className="back_arrow" />
                <p className="notification-heading">Notifications</p>
                <p className="notifications-sm">Manage your personal account notification preferences for new comments, mentions, likes and topics.</p>
                <form style={{ marginTop: "2rem" }}>
                    <label className="label-text">Notify me about comments <br />on my topics</label>  <label className="float-right">
                        <input ref="switch1" checked={this.state.commentsOnTopics} onChange={this._onTopics} className="switch1" type="checkbox" />
                        <div>
                            <div></div>
                        </div>
                    </label>
                    <hr style={{ width: "80%" }} />
                    <label className="label-text">Notify me about replies to <br />my comments</label><label className="float-right">
                        <input ref="switch1" checked={this.state.replies} onChange={this.onChangeReplies} className="switch1" type="checkbox" />
                        <div>
                            <div></div>
                        </div>
                    </label>
                    <hr style={{ width: "80%" }} />
                    <label className="label-text">Notify me about <br />mentions</label><label className="float-right">
                        <input ref="switch1" checked={this.state.mentions} onChange={this.onChangeMentions} className="switch1" type="checkbox" />
                        <div>
                            <div></div>
                        </div>
                    </label>
                    <hr style={{ width: "80%" }} />
                    <label className="label-text">Notify me about likes on <br />my topics</label><label className="float-right">
                        <input ref="switch1" checked={this.state.likesOnTopics} onChange={this.onChangeLikesTopic} className="switch1" type="checkbox" />
                        <div>
                            <div></div>
                        </div>
                    </label>
                    <hr style={{ width: "80%" }} />
                    <label className="label-text">Notify me about likes on <br />my comments</label><label className="float-right">
                        <input ref="switch1" checked={this.state.likesOnComments} onChange={this.onChangeLikesComment} className="switch1" type="checkbox" />
                        <div>
                            <div></div>
                        </div>
                    </label>
                    <hr style={{ width: "80%" }} />
                    <label className="label-text">Other (Recommendations, <br />tips and prompts)</label><label className="float-right">
                        <input ref="switch1" checked={this.state.others} onChange={this.onChangeOthers} className="switch1" type="checkbox" />
                        <div>
                            <div></div>
                        </div>
                    </label>
                </form>
                <br /><br /><br />
                <BottomMenu />
            </div >
        )
    }
}
