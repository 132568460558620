import React, { Component } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import loginlogo from '../images/login-logo.png';
import PricingData from "./PricingData.json";
import BottomMenu from "../BottomMenu/BottomMenu";
import './Pricing.css';
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "../LandingPage/arrowsOnBottomOrTop.css";
import "../LandingPage/hideScrollbar.css";
import axios from 'axios';
import { config } from '../Config/Config';
import { Redirect } from 'react-router-dom';
import bhim from '../images/BHIM-UPI.jpg';
import qrcode from '../images/QrCode.jpeg';
import phonepe from '../images/phonepe.png';
import gpay from '../images/Gpay.jpg';
import amazonpay from '../images/amazon-pay.jpg';
import paytm from '../images/paytm.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Refund extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            name: localStorage.getItem('name'),
            planId: "",
            planName: "",
            pName: "",
            amount: "",
            plans: [],
            picture: "",
            redirect: false,
            upiId: "",
            reason: ""
        }

    }
    componentDidMount() {
    }

    showNone = () => {
        var self = this;
        self.setState({
            redirect: true
        })
        document.getElementById('upgrade').style.display = 'none';

    }
    closeModal() {
        document.getElementById('sameplan').style.display = 'none';
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    postalDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "upiId": this.state.upiId,
            "reason": this.state.reason,
        }
        var self = this;
        axios.post(config.userUrl + 'user/askForRefund', payload, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                   // console.log('status')
                    self.setState({ redirect: true })
                }
            }).catch((error) => {
                console.log(error, "error")
            });
    };

    uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    handleImage = async (e) => {
       // console.log("fgh", e.target.files)
        if (e.target.files && e.target.files[0]) {
            const res = await this.uploadImage(e.target.files[0]);
            if (res) {
            //    console.log("res", res)
                this.setState({ picture: res })
            }
        }
    }
    showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }
    render() {
        const { redirect, imgRef, picture } = this.state;
        if (redirect) {
            return <Redirect to="/currentpricing" />
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <div className="screen-topgap">
                    <span className="your-jitbits">Refund</span>
                    <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                        <form>
                            <label className="mt-1 ml-1 input-text-h">UPI Id</label>
                            <input type="text input-text-h" className="ip form-control" name="upiId" onChange={this.onChange} value={this.state.upiId} />
                            <label className="ml-1 input-text-h">Reason</label>
                            <textarea type="text" className="ip form-control" style={{resize:"none"}} rows={2} name="reason" onChange={this.onChange} value={this.state.reason} />
                        </form>
                        {((this.state.upiId !== "") && (this.state.reason !== "")) ?

                            <div><Button variant="default" className="upload-trans" onClick={this.postalDetails}><div className="ml-3 mr-3">Submit</div></Button></div>
                            :
                            <div><Button variant="default" className="upload-trans" disabled="true"><div className="ml-3 mr-3">Submit</div></Button></div>}

                    </div>
                </div >

                < BottomMenu />
            </div >
        )
    }
}
