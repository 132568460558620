import React, { Component } from "react";
import { Card, Accordion, Row, Col } from "react-bootstrap";
import { Link, NavLink } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import '../NotificationBell/NotificationBell.css';
import axios from 'axios';
import { config } from '../Config/Config';
import answers from './AuthorDetails.json';
import { BsFillInfoCircleFill, BsFillPersonFill } from "react-icons/bs";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class CheckAnswers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            quesAndAnswers: [],
            isLoading: true
        }
    }
    componentDidMount() {
        this.getAllQuestionAndAnswers();
    }
    getAllQuestionAndAnswers = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllQuestionAndAnswer', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                  //  console.log("success");
                    self.setState({
                        quesAndAnswers: response.data.getAllQuestionAndAnswer,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });

    };
    render() {
        const { quesAndAnswers, isLoading } = this.state;
        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <div className="ml-4 mr-4" style={{ marginTop: "4.5rem" }}>
                    <div className="recommended-fixed">
                        {/* <img alt="" src={back} className="landing-Image" onClick={this.handleBack} /><br /><br /> */}
                        <div style={{ display: "flex", marginLeft: "15px" }}>
                            <NavLink to="/checkanswers" activeClassName="profile-active" className="link" >
                                <div className="followers-Text">Check Answers </div></NavLink>

                            <NavLink to="/myquestions" activeClassName="profile-active" className="rec-left link" >
                                <div className="followers-Text">My Questions</div></NavLink>

                        </div>
                    </div><br />
                    {/* <div className="current-poll-fixed">
                        <div style={{ marginTop: "10px", display: "flex" }}>
                            <NavLink to="/checkanswers" activeClassName="poll-active" className="ml-1 link" >
                                <div className="currentpoll-Text ml-2 mr-2">Expert Answers</div></NavLink>
                            <NavLink to="/myquestions" activeClassName="poll-active" className="poll-rec-left link" >
                                <div className="currentpoll-Text ml-2 mr-2">My Questions </div></NavLink>
                        </div >
                    </div><br /><br /> */}
                    {/* <span><div className="your-notification">
                        <NavLink to="/checkanswers" activeClassName="question-active" className="link" >Expert Answers </NavLink>
                        <NavLink to="/myquestions" activeClassName="question-active" className="link" ><span style={{ float: "right" }} className="your-notification">My Questions</span></NavLink></div></span> */}
                    <Accordion defaultActiveKey="0">{quesAndAnswers.map((data, idx) =>
                        <Card style={{ border: "none", marginTop: "10px" }} >
                            <Accordion.Toggle as={Card.Header} className="accordion-header" style={{ color: "#31197C" }} eventKey={idx.toString()}>
                                <Link style={{ color: "black", textDecoration: "none" }}>{data.question}</Link> <span style={{ float: "right" }}> <img alt="" src={data.topicDetails[0].topicName.replace(/ *\([^]*\) */g, "") + ".png"} style={{ width: "20px" }} /></span><br />
                                <div className="mt-1"><Link style={{ color: "black", textDecoration: "none" }}><i>{data.name}</i></Link> <span style={{ float: "right", fontSize: "12px", color: "black" }}> <i>{data.createdAt.split("T")[0].split("-").reverse().join("-")}</i></span></div>
                            </Accordion.Toggle>

                            {data.answerData.map((item, i) => (
                                <Accordion.Collapse className="accordion-body" eventKey={idx.toString()}>
                                    <Card.Body>{item.answer}
                                        <Row style={{ width: "100%" }}>
                                            <Col xs={2}>
                                                <img alt="" src={config.userUrl + item.profilePicture} className="qa-author" />
                                            </Col>
                                            <Col xs={5}>
                                                <div className="ml-1 mt-3 favbits-title">{item.name}</div>
                                            </Col>
                                            <Col xs={5}>
                                                <div className="qa-date mt-3 ml-2">{item.createdAt.split("T")[0].split("-").reverse().join("-")}</div>
                                            </Col>
                                        </Row><hr style={{ marginBottom: "-1.5rem" }} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            ))}
                        </Card>
                    )}
                    </Accordion>
                    <div className="mb-bottom"></div>
                </div>
                <BottomMenu />
            </div >
        )
    }
}
{/* <div style={{ marginTop: "4.5rem" }}></div>
                <div className="current-poll-fixed">
                    <div style={{ marginTop: "10px", display: "flex" }}>
                        <NavLink to="/checkanswers" activeClassName="poll-active" className="ml-4 link" >
                            <div className="currentpoll-Text ml-2 mr-2">Expert Answers</div></NavLink>
                        <NavLink to="/myquestions" activeClassName="poll-active" className="poll-rec-left link" >
                            <div className="currentpoll-Text ml-2 mr-2">My Questions </div></NavLink>
                    </div >
                </div><br /> */}