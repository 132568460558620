
import React, { useState, useEffect } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Link, NavLink } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import '../JitBits/JitBits.css';
import back from '../images/backarrow.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default function RewardsPointsHistory() {
    const [claimHistory, setClaimHistory] = useState([
        {
            "id": 1, "offerName": "Pen", "rewardPoints": 800
        },
        {
            "id": 2, "offerName": "Key-board", "rewardPoints": 800
        },
        {
            "id": 3, "offerName": "mouse", "rewardPoints": 100
        },
        {
            "id": 4, "offerName": "Key-board", "rewardPoints": 800
        },
    ])
    const [userName, setUserName] = useState("")
    const [profilePicture, setProfilePicture] = useState("")
    const [rewardPointsForNewUser, setRewardPointsForNewUser] = useState()
    const [claimTotal, setClaimTotal] = useState()
    const [userRegisterForSceemlore, setUserRegisterForSceemlore] = useState("")
    const [userCardCompleted, setUserCardCompleted] = useState("")
    const [userArticleViews, setUserArticleViews] = useState("")
    const [referralCount, setReferralCount] = useState("")
    const [totalQuestionCount, setTotalQuestionCount] = useState("")
    const [userOpportunityCount, setUserOpportunityCount] = useState("")
    const [totalAnswerCount, setTotalAnswerCount] = useState("")
    const [likesCount, setLikesCount] = useState("")
    const [followerCount, setFollowerCount] = useState("")
    const [followingCount, setFollowingCount] = useState("")
    const [postCount, setPostCount] = useState("")
    const [pollAnsweredCount, setPollAnsweredCount] = useState("")
    const [pollCreatedCount, setPollCreatedCount] = useState("")
    const [postQuestionAnsweredCount, setPostQuestionAnsweredCount] = useState("")
    const [monthActive, setMonthActive] = useState("")
    const [gameRewardPoints, setGameRewardPoints] = useState()
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        getIndividualRewardPointsHistory();
    }, []);

    const getIndividualRewardPointsHistory = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getIndividualRewardPointsHistory', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setUserRegisterForSceemlore(Number(response.data.userRewardPointsHistory[0]?.userRegisterForSceemlore))
                    setUserCardCompleted(Number(response.data.userRewardPointsHistory[0]?.userCardCompleted))
                    setUserArticleViews(Number(response.data.userRewardPointsHistory[0]?.userArticleViews))
                    setReferralCount(Number(response.data.userRewardPointsHistory[0]?.referralCount))
                    setUserOpportunityCount(Number(response.data.userRewardPointsHistory[0]?.userOpportunityCount))
                    setTotalQuestionCount(Number(response.data.userRewardPointsHistory[0]?.totalQuestionCount))
                    setTotalAnswerCount(Number(response.data.userRewardPointsHistory[0]?.totalAnswerCount))
                    setLikesCount(Number(response.data.userRewardPointsHistory[0]?.likesCount))
                    setFollowerCount(Number(response.data.userRewardPointsHistory[0]?.followerCount))
                    setFollowingCount(Number(response.data.userRewardPointsHistory[0]?.followingCount))
                    setPostCount(Number(response.data.userRewardPointsHistory[0]?.postCount))
                    setPollAnsweredCount(Number(response.data.userRewardPointsHistory[0]?.pollAnsweredCount))
                    setPollCreatedCount(Number(response.data.userRewardPointsHistory[0]?.pollCreatedCount))
                    setMonthActive(Number(response.data.userRewardPointsHistory[0]?.monthActive))
                    setPostQuestionAnsweredCount(Number(response.data.userRewardPointsHistory[0]?.postQuestionAnsweredCount))
                    setUserName(response.data.userDetails.name)
                    setProfilePicture(response.data.userDetails.profilePicture)
                    setRewardPointsForNewUser(response.data.userDetails.rewardPointsForNewUser)
                    setClaimHistory(response.data.claimHistory)
                    setGameRewardPoints(response.data.gameTotalRewardPoints[0].sum == null ? 0 : Number(response.data.gameTotalRewardPoints[0].sum))
                    setIsLoading(false)
                    var result = response.data.claimHistory.reduce(function (tot, arr) {
                        setClaimTotal(tot + arr.rewardPoints)
                        return tot + arr.rewardPoints;
                    }, 0);
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    if (isLoading) {
      //  console.debug("renders: Loading...");
        return <div className="App"></div>;
    }
    // var result = claimHistory.reduce(function(tot, arr) { 
    //     // return the sum with previous value
    //     setClaimTotal(tot + arr.rewardPoints)
    //     return tot + arr.rewardPoints;
    //   },0);

    //   console.log(result);
    return (

        <div >
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="leaderboard-topgap">
            <div><Link to="/leaderboard"><img alt="" src={back} className="claims-back_arrow" /></Link> <div style={{marginLeft:"18%",fontFamily:"Noto Sans",fontSize:"16px",fontWeight:600,color:"#ff942c"}}> &nbsp;Details <span style={{ marginTop: "-5px", float: "right", marginRight: "20px" }}><img src={config.userUrl + profilePicture} style={{ width: "40px" }} />&nbsp;{userName}</span></div></div>
                <div className="ml-4 mr-4">
                    <div>
                        <div class="mt-4 tableRewards" >
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr  >
                                        <th scope="col" className="th-heading" style={{ width: "50%" }}>Reward Name</th>
                                        <th scope="col" className="th-heading" style={{ width: "20%" }}>Reward Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="td-sm-text">Registered For Sceemlore</td>
                                        <td className="td-sm-text">+{userRegisterForSceemlore}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-sm-text">Cards Completed</td>
                                        <td className="td-sm-text">+{userCardCompleted}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-sm-text">Article Views</td>
                                        <td className="td-sm-text">+{userArticleViews}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-sm-text">Referral</td>
                                        <td className="td-sm-text">+{referralCount}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-sm-text">Opportunities</td>
                                        <td className="td-sm-text">+{userOpportunityCount}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-sm-text">Questions Raised</td>
                                        <td className="td-sm-text">+{totalQuestionCount}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-sm-text">Answered</td>
                                        <td className="td-sm-text">+{totalAnswerCount}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-sm-text">Likes</td>
                                        <td className="td-sm-text">+{likesCount}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-sm-text">Followers</td>
                                        <td className="td-sm-text">+{followerCount}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-sm-text">Following</td>
                                        <td className="td-sm-text">+{followingCount}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-sm-text">Articles Posted</td>
                                        <td className="td-sm-text">+{postCount}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-sm-text">Poll Answered</td>
                                        <td className="td-sm-text">+{pollAnsweredCount}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-sm-text">Poll Created</td>
                                        <td className="td-sm-text">+{pollCreatedCount}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-sm-text">Months Active</td>
                                        <td className="td-sm-text">+{monthActive}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-sm-text">Article Poll Question Answered</td>
                                        <td className="td-sm-text">+{postQuestionAnsweredCount}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-sm-text">Games</td>
                                        <td className="td-sm-text">+{gameRewardPoints}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-sm-text">Bonus for Registered Users</td>
                                        <td className="td-sm-text">+{rewardPointsForNewUser}</td>
                                    </tr>
                                    {/* {claimHistory.map((data, i) => (
                                        <tr>
                                            <td className="td-sm-text">{data.offerName}</td>
                                            <td className="td-sm-text">-{data.rewardPoints}</td>
                                        </tr>
                                    ))} */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="_rewards">Points Accumulated<span style={{ float: "right", marginRight: "20px" }}> + {userRegisterForSceemlore + userCardCompleted + userArticleViews + referralCount + userOpportunityCount +
                    totalQuestionCount + totalAnswerCount + likesCount + followerCount + followingCount + postCount + pollAnsweredCount
                    + pollCreatedCount + gameRewardPoints + rewardPointsForNewUser}</span></div>
                <div className="_rewards">Points Redeemed <span style={{ float: "right", marginRight: "20px" }}>- {claimTotal==undefined?0:claimTotal}</span></div>
                <div className="_rewards">Total Reward Points<span style={{ float: "right", marginRight: "20px" }}>{userRegisterForSceemlore + userCardCompleted + userArticleViews + referralCount + userOpportunityCount +
                    totalQuestionCount + totalAnswerCount + likesCount + followerCount + followingCount + postCount + pollAnsweredCount
                    + pollCreatedCount + gameRewardPoints + rewardPointsForNewUser - (claimTotal==undefined?0:claimTotal)}</span></div>

                {/* <div style={{ display: "flex" }}>Total Reward Points<div style={{ float: "right", fontFamily: "Noto Sans", fontSize: "18px", color: "#ff942c", marginRight: "8px" }}>{userRegisterForSceemlore + userCardCompleted + userArticleViews + referralCount + userOpportunityCount +
                    totalQuestionCount + totalAnswerCount + likesCount + followerCount + followingCount + postCount + pollAnsweredCount
                    + pollCreatedCount + rewardPointsForNewUser}</div></div> */}
               <Link to="/rewardshistory">
                        <Button variant="default" style={{ marginTop: "2px", marginRight: "21px", float: "right", background: "#31197C", color: "white", fontFamily: "Noto Sans" }}>Claims History</Button>
                    </Link>
            </div>
            
            <div className="mb-bottom"></div>
            <BottomMenu />
            {/* <div className="your-jitbits">Total Reward Points <span style={{ marginTop: "-8px", float: "right", marginRight: "20px" }}>{userRegisterForSceemlore + userCardCompleted + userArticleViews + referralCount + userOpportunityCount +
                            totalQuestionCount + totalAnswerCount + likesCount + followerCount + followingCount + postCount + pollAnsweredCount
                            + pollCreatedCount + rewardPointsForNewUser}</span></div> */}
        </div >
    )
}
