import React from 'react';
import './Interests.css';
import { Row, Col, Modal, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';

class Popup extends React.Component {
    constructor(props) {
        super(props);

    }
    componentDidMount() {
      //  console.log("props", this.props)
    }
    render() {
        return (
            <div className='popup-box'>
                <div className='_box'>
                    <span className="close-icon-new" onClick={this.props.closePopup}> &times;</span>
                    <center>
                    <h5>{this.props.roleAsReviewer}</h5><br />
                        <p>{this.props.reviewerText}</p>
                        <h5>{this.props.roleAsAuthor}</h5><br />
                        <p>{this.props.authorText}</p>
                        <h5>{this.props.roleAsSme}</h5><br />
                        <p>{this.props.smeText}</p>
                        {/* <h5>{this.props.data.roleAsReviewer}</h5><br />
                        <p>{this.props.data.reviewerText}</p>
                        <h5>{this.props.data.roleAsAuthor}</h5><br />
                        <p>{this.props.data.authorText}</p>
                        <h5>{this.props.data.roleAsSme}</h5><br />
                        <p>{this.props.data.smeText}</p> */}
                        <Button variant="default" className="_rolebtn" onClick={this.props.closePopup} >Ok</Button>
                    </center>
                </div>
            </div>
            // <Modal show={true} onHide={this.props.closePopup}>
            //     <h5>{this.props.data.roleName1}</h5><br />
            //     <p>{this.props.data.text1}</p><br />
            //     <h5>{this.props.data.roleName}</h5><br />
            //     <p>{this.props.data.text}</p>
            //     <Button variant="secondary" onClick={this.props.closePopup}>
            //         Close
            //     </Button>
            // </Modal>
        );
    }
}

export default Popup;

