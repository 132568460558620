import React, { Component } from "react";
import { Col } from "react-bootstrap";
import loginlogo from '../images/login-logo.png';
import './Interests.css';
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import AuthorsJson from './Authors.json';
import BottomMenu from "../BottomMenu/BottomMenu";

export default class FavAuthorsList extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <div className="home-top">Hi, Mukundh</div>
                <div><p className="explore-text ml-4">Follow your Favourite Author</p>
                </div>
                <div className="main">
                    <ScrollMenu>
                        {AuthorsJson.authors.map((data, id) => {
                            return (
                                <div className="scroll-authors">
                                    <Col xs={3}><img alt="" src={data.image} className="procurement" />
                                        <p className="text ml-2">{data.name}</p></Col>
                                </div>
                            )
                        })}
                    </ScrollMenu>
                </div>
                <div className="mt-4">
                    {AuthorsJson.authors.map((data, id) => {
                        return (
                            <div style={{ marginLeft: "2.2rem", marginRight: "2.2rem" }}>
                                <span>{data.name}</span><span className="-float">{data.topic}</span>
                                <hr style={{
                                    width: "100%",
                                    height: "0.2px",
                                    backgroundColor: "grey",
                                    opacity: "0.3",
                                    marginTop: "3px"
                                }} /></div>
                        )
                    })}
                </div><br /><br />
                <BottomMenu />
            </div >
        )
    }
}
