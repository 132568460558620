import React, { useEffect, useState } from "react";
import { Button, Form, Card } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import back from '../images/backarrow.png';
import wave from '../images/play card bottom wave.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import BottomMenu from "../BottomMenu/BottomMenu";
import OtpInput from 'react-otp-input';
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "./arrowsOnBottomOrTop.css";
import "./hideScrollbar.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const myComponentStyle = {
    width: '22px',
    height: '30px',
    color: 'black',
    paddingBottom: 4,
    borderRadius: '6px',
    marginLeft: '1px',
    marginRight: '1px',
    fontWeight: 'bold',
    fontSize: '16px',
    border: '1px solid black'
}
const borderComponentStyle = {
    width: '22px',
    height: '30px',
    color: 'black',
    paddingBottom: 4,
    borderRadius: '6px',
    marginLeft: '1px',
    marginRight: '1px',
    fontWeight: 'bold',
    fontSize: '16px',
    border: '1px solid red'
}
function generateRandomString(length) {
    var chars = 'ABCDE';
    var result = '';
    var charSet = new Set();
    while (result.length < length) {
        var randomIndex = Math.floor(Math.random() * chars.length);
        var randomChar = chars.charAt(randomIndex);

        if (!charSet.has(randomChar)) {
            result += randomChar;
            charSet.add(randomChar);
        }
    }
    return result;
}
// function shuffleArray(array) {
//     var currentIndex = array.length;
//     var temporaryValue, randomIndex;
//     // While there remain elements to shuffle
//     while (currentIndex !== 0) {
//         // Pick a remaining element
//         randomIndex = Math.floor(Math.random() * currentIndex);
//         currentIndex -= 1;
//         // Swap it with the current element
//         temporaryValue = array[currentIndex];
//         array[currentIndex] = array[randomIndex];
//         array[randomIndex] = temporaryValue;
//     }
//     return array;
// }
export default function SequenceGame(props) {
    const [word, setWord] = useState("")
    const [term, setTerm] = useState("")
    const [jumbledTerm, setJumbledTerm] = useState("")
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [noOfTrials, setNoOfTrials] = useState()
    const [userTrials, setUserTrials] = useState()
    const [gameName, setGameName] = useState("")
    const [gameDescription, setGameDescription] = useState("")
    const [gamePicture, setGamePicture] = useState("")
    const [rewardPoints, setRewardPoints] = useState()
    const [termDefinition, setTermDefinition] = useState("")
    const [termUsage, setTermUsage] = useState("")
    const [termId, setTermId] = useState("")
    const [sort, setSort] = useState("")
    const [howToPlay, setHowToPlay] = useState("")
    const [sequenceCards, setSequenceCards] = useState([])
    const [cardsDummy, setCardsDummy] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }])
    const [allThemes, setAllThemes] = useState([])
    const [selectedValue, setSelectedValue] = useState("")

    useEffect(() => {

        getGameById();
        getAllThemes();
      //  console.log("game")
    }, []);

    const onChange = (e) => {
        setSelectedValue(e.target.value)
        getParticularTheme(e.target.value);
        setWord("")
    }
    const getAllThemes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllThemes', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setAllThemes(response.data.getThemes)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    // const getTermRandomly = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + token,
    //         "applicationType": "mobile"
    //     }
    //     axios.get(config.userUrl + 'user/getTermRandomly', { headers: headers })
    //         .then(function (response) {
    //             //console.log(response);
    //             if (response.data.status === true) {
    //                 setTerm(response.data.terms.term)
    //                 setTermDefinition(response.data.terms.definition)
    //                 setTermUsage(response.data.terms.usage)
    //                 setTermId(response.data.terms.id)
    //                 setJumbledTerm(response.data.terms.term.split("").map(v => [v, Math.random()]).sort((a, b) => a[1] - b[1]).map(v => v[0]).join(" "))
    //                 // console.log(response)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error", error);
    //         });
    // };
    const getParticularTheme = (val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "theme": val
        }
        axios.post(config.userUrl + 'user/getParticularTheme', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    let word = generateRandomString(5)
                    word = word.split("")
                    let arr = response.data.allSequenceCards.map(item => {
                        return { ...item, character: word[item.sequenceNo - 1] }
                    })
                    setSequenceCards(arr)
                    setTerm(arr.sort((a, b) => a.sequenceNo - b.sequenceNo).map(item => item.character).join(""))
                    //  setJumbledTerm(response.data.terms.term.split("").map(v => [v, Math.random()]).sort((a, b) => a[1] - b[1]).map(v => v[0]).join(" "))
                    //console.log(sequenceCards)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getGameById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + `user/getGameById/${props.match.params.id}`, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setGameName(response.data.game[0].gameName)
                    setGameDescription(response.data.game[0].description)
                    setGamePicture(response.data.game[0].picture)
                    setRewardPoints(response.data.game[0].rewardPoints)
                    setNoOfTrials(response.data.game[0].numberOfTrials)
                    setUserTrials(response.data.game[0].numberOfTrials)
                    setHowToPlay(response.data.game[0].howToPlay)
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    const handleSubmit = (event) => {
        const data = new FormData(event.target);
        event.preventDefault()
        checkWord();
    }
    const checkWord = (e) => {

        if (term === word.toUpperCase()) {
           // console.log("a", word, term)
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + token,
                "applicationType": "mobile"
            }
            var payload = {
                "gameId": Number(props.match.params.id),
                "gameRewardPoint": rewardPoints
            }
            axios.post(config.userUrl + 'user/updateGameRewardPoint', payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status === true) {
                        //console.log("success!!")
                        showSuccess()
                    } else {
                        console.log("error")
                    }
                })
                .catch(function (error) {
                    console.log("error", payload,error)
                });
        } else {
            if (userTrials <= noOfTrials && userTrials != 1) {
              //  console.log("if")
                setUserTrials(userTrials - 1)
                showFailure()
                setWord("")
            } else if (userTrials > 0 && userTrials <= 1) {
               // console.log("else")
                setUserTrials(0)
                trialsExpired()
                setWord("")
            } else {
                setUserTrials(0)
                trialsExpired()
                setWord("")
            }
        }
    }
    const showHowToPlay = () => {
        document.getElementById('play').style.display = 'flex';
    }
    const hideHowToPlay = () => {
        document.getElementById('play').style.display = 'none';
    }
    const showSuccess = () => {
        document.getElementById('success').style.display = 'flex';
    }
    const hideSuccess = () => {
        document.getElementById('success').style.display = 'none';
        window.location.replace('/games')
    }
    const showFailure = () => {
        document.getElementById('failure').style.display = 'flex';
    }
    const hideFailure = () => {
        document.getElementById('failure').style.display = 'none';
    }
    const trialsExpired = () => {
        document.getElementById('trial').style.display = 'flex';
    }
    const trialsExpiredNone = () => {
        document.getElementById('trial').style.display = 'none';
        window.location.replace('/games')
    }
    // let a=sequenceCards.map(item => item.sequenceNo)
    // console.log("term", a.join(""))
    // let check=a.join("");
    // setTerm(check)

    const data = [5, 4, 2, 1, 3];
    const data1 = [{ "a": 5 }, { "b": 4, "c": 2, "e": 1, "d": 3 }]
    let updatedArray = sequenceCards.sort((a, b) => data.indexOf(a.sequenceNo) - data.indexOf(b.sequenceNo))
   // console.log(updatedArray);
    // let newArr = sequenceCards.map((item => item.sequenceNo.toString.fromCharCode(97+1)))
    // console.log(newArr)
    // var str = "b";
    // var n = str.charCodeAt(0) - 97+1;
    // console.log(n)
    return (
        <div>
            <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
            <Link to="/games"><img alt="" src={back} className="back_arrow" /></Link>
            <center> <img alt="" src={gamePicture} className="game-s-image" />
                <div style={{ fontFamily: "Noto Sans" }} className="game-seq-text">{gameDescription}<br /> <span style={{ fontSize: "18px" }}>And earn {rewardPoints} Reward Points</span></div>
                <div style={{ fontFamily: "Noto Sans", marginTop: "-1px", color: "black", fontSize: "14px" }}>Trials Left: {userTrials}/{noOfTrials}</div></center>


            <div style={{ display: "flex" }}><div className="ml-4" style={{ textAlign: "left", marginTop:"-10px",color: "#FF942c", fontSize: "20px" }}></div><Button variant="default" className="showPlay-btn-sequence" onClick={() => showHowToPlay()} >How to Play</Button></div>
            {/* <div className="ml-4 mr-4" style={{ textAlign: "justify", color: "#FF942c", fontSize: "14px" }}>{termDefinition}</div> */}
            {/* <div className="sequence-game-card">

            </div> */}<br />

            <center>
                <div className="mt-2 form-group" style={{ width: "60%" }}>
                    <select name="selectedValue" value={selectedValue} disabled={selectedValue !== "" ? true : false} onChange={onChange} class="form-drop form-control">
                        <option value="">Choose Theme to Play</option>
                        {allThemes.map((data, id) => (
                            <option value={data.theme}>{data.theme}</option>

                        ))}
                    </select>
                </div>
            </center>
            {/* <div class="card" id="card1">Card 1</div>
            <div class="card" id="card2">Card 2</div>
            <div class="card" id="card3">Card 3</div>
            <div class="card" id="card4">Card 4</div>
            <div class="card" id="card5">Card 5</div> */}
            {/* <div class="card">
                <div className="text-sequence">Go to home</div>

            </div> */}

            {selectedValue !== "" ?
                <div style={{ position: "absolute", left: "19%", transform: "translateX(-19%)" }}>
                    {/* <div className="row" style={{ width: "100%" }} >
                    {sequenceCards.map((data, i) => (
                        <div className="ml-3 col-xs-3">
                            <Card className="s-card" style={{ top:"-27px",left: `${i * 35}px`, zIndex: i }}>
                                <Card.Title className="ml-1 mt-1 sequence">{data.character}</Card.Title>
                                <Card.Title className="mt-2 text-sequence">{data.cardPhrase}</Card.Title>
                                <img src={wave} style={{ width: "24vw", position: "absolute", borderRadius: "10px", bottom: 0, left: 0 }} />
                            </Card>

                        </div>
                    ))}
                </div> */}
                    <div className="row" style={{ width: "100%" }} >
                        {sequenceCards.map((data, i) => (
                            <div className="ml-3 col-xs-3">
                                <Card className="s-card" style={{ top: i == 0 ? "-24px" : i < 2 ? "-37px" : i == 2 ? "-38px" : i == 4 ? "-10px" : "-29px", left: `${i * 35}px`, zIndex: i, transform: `rotate(${(i < 2 ? (cardsDummy.length - i - 3) * (-20) : i == 2 ? 0 : (i == 3 ? 1 : 2) * (20))}deg)` }}>
                                    <Card.Title className="ml-1 mt-1 sequence">{data.character}</Card.Title>
                                    <Card.Title className="mt-2 text-sequence">{data.cardPhrase}</Card.Title>
                                    <img src={wave} style={{ width: "28vw", position: "absolute", borderRadius: "10px", bottom: 0, left: 0 }} />
                                </Card>

                            </div>
                        ))}
                    </div>
                </div> :
                <div style={{ position: "absolute", left: "19%", transform: "translateX(-19%)" }}>
                    <div className="row" style={{ width: "100%" }} >
                        {cardsDummy.map((data, i) => (
                            <div className="ml-3 col-xs-3">
                                <Card className="s-card" style={{ top: i == 0 ? "-24px" : i < 2 ? "-37px" : i == 2 ? "-38px" : i == 4 ? "-10px" : "-29px", left: `${i * 35}px`, zIndex: i, transform: `rotate(${(i < 2 ? (cardsDummy.length - i - 3) * (-20) : i == 2 ? 0 : (i == 3 ? 1 : 2) * (20))}deg)` }}>
                                    <Card.Title className="ml-1 mt-1 sequence"></Card.Title>
                                    <Card.Title className="mt-2 text-sequence"></Card.Title>
                                    <img src={wave} style={{ width: "28vw", position: "absolute", borderRadius: "10px", bottom: 0, left: 0 }} />
                                </Card>
                                {/* <Card className="sequence-card-game">
                            <Card.Title className="ml-1 mt-1 sequence">{data.character}</Card.Title>
                            <Card.Title className="mt-2 text-sequence">{data.cardPhrase}</Card.Title>
                            <img src={wave} style={{ width: "24vw", position: "absolute", borderRadius: "10px", bottom: 0, left: 0 }} />
                        </Card> */}

                            </div>
                        ))}
                    </div>
                </div>
                // <div className="row" style={{ marginLeft: "75px", marginTop: "-27px", width: "100%" }}>
                //     {cardsDummy.map((data,i)=>(
                //     <div className="ml-3 col-xs-3">
                //         <Card className="sequence-card-game" style={{ marginLeft: `${1 * - 55}px`, zIndex: i }}>
                //             <Card.Title className="ml-1 mt-1 sequence"></Card.Title>
                //             <Card.Title className="mt-2 text-sequence"></Card.Title>
                //             <img src={wave} style={{ width: "24vw", position: "absolute", borderRadius: "10px", bottom: 0, left: 0 }} />
                //         </Card>
                //     </div>
                //     ))}
                // </div>
            }
            {/* <div class="card1">
                    <div className="text-sequence">Go to home</div>
          
            </div> */}
            {/* <div class="card">
  <div class="wave-container">
    <div class="wave"></div>
  </div>
  <div class="card-content">
   content here
  </div>
</div> */}
            {/* <div class="card">
  <div class="wave"></div>
  <div class="content">
  <div className="text-sequence">Go to home</div>
  </div>
</div> */}
            {selectedValue !== "" ?
                <Form className="forgot-email" onSubmit={handleSubmit}>
                    <center>
                        <div className="game-boxes-sequence" style={{ zIndex: 10 }}>
                            <OtpInput
                                value={word}
                                onChange={setWord}
                                numInputs={sequenceCards.length}
                                inputType="text"
                                inputStyle={myComponentStyle}
                                renderSeparator={<span></span>}
                                renderInput={(props) => <input {...props} />}
                            />
                        </div>
                        <br /><br />
                        {word.length == sequenceCards.length ?
                            <Button variant="default" type="submit" style={{ margin: "auto", position: "absolute", bottom: "4.5rem", left: "50%", transform: "translateX(-50%)" }}
                                className="btn-block login-btn">Submit</Button>
                            :
                            <Button disabled="true" variant="default" type="submit" style={{ margin: "auto", position: "absolute", bottom: "4.5rem", left: "50%", transform: "translateX(-50%)" }}
                                className="btn-block login-btn">Submit</Button>
                        }
                        <br />
                        <div id="otp" className="errormsg"></div>
                        <div id="tryanother" className="errormsg"></div>
                    </center>
                </Form> :
                <Form className="forgot-email" onSubmit={handleSubmit}>
                    <center>
                        <div className="game-boxes-sequence" style={{ zIndex: 10 }}>
                            <OtpInput
                                onChange={setWord}
                                numInputs={5}
                                inputType="text"
                                inputStyle={myComponentStyle}
                                renderSeparator={<span></span>}
                                renderInput={(props) => <input {...props} />}
                            />
                        </div>
                        <br /><br />
                        <Button disabled="true" variant="default" type="submit" style={{ margin: "auto", position: "absolute", bottom: "4.5rem", left: "50%", transform: "translateX(-50%)" }}
                            className="btn-block login-btn">Submit</Button>

                        <br />
                        <div id="otp" className="errormsg"></div>
                        <div id="tryanother" className="errormsg"></div>
                    </center>
                </Form>}
            <div id="success" className="modal-pop">
                <form className="modal-content-log">
                    <div className="_container">
                        <p className="mt-2" style={{ color: "#31197C", fontSize: "20px" }}>Correct !!</p>
                        <p className="mt-2">You have earned {rewardPoints}  Reward Points.</p>
                        <center><Button variant="default" onClick={hideSuccess} className="deletebutton">Ok</Button></center>
                    </div>
                </form>
            </div>
            <div id="failure" className="modal-pop">
                <form className="modal-content-log">
                    <div className="_container">
                        <p className="mt-2" style={{ color: "#31197C", fontSize: "20px" }}>Oops! Try again</p>
                        <p className="mt-2">No.of trials left: {userTrials}</p>
                        <center><Button variant="default" onClick={hideFailure} className="deletebutton">Ok</Button></center>
                    </div>
                </form>
            </div>
            <div id="play" className="modal-pop">
                <form className="modal-content-log">
                    <div className="_container-howtoplay">
                        <center className="mt-1" style={{ color: "#31197C", fontSize: "20px" }}>How to Play</center>
                        {/* <div className="mt-2 howToPlay">{howToPlay}</div> */}
                        <div className="mt-2" style={{ wordWrap: "break-word", width: "100%" }} dangerouslySetInnerHTML={{ __html: howToPlay }}></div>
                        <center><Button variant="default" onClick={hideHowToPlay} className="mt-2 deletebutton">Ok</Button></center>
                    </div>
                </form>
            </div>
            <div id="trial" className="modal-pop">
                <form className="modal-content-log">
                    <div className="_container">
                        <p className="mt-2" style={{ color: "#31197C", fontSize: "18px" }}>Wrong Guess. Try again.</p>
                        <p className="mt-2" style={{ color: "#31197C", fontSize: "20px" }}>0 Trials Left !!</p>
                        <center><Button variant="default" onClick={trialsExpiredNone} className="deletebutton">Ok</Button></center>
                    </div>
                </form>
            </div>
            <BottomMenu />
        </div>
    );
}


