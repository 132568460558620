import React, { Component } from "react";
import { Button, Form, Col, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import circle from '../images/circle.png';
import author1 from '../images/profile.png';
import upload from '../images/upload.png';
import circleblue from '../images/circle-blue.png';
import circleyellow from '../images/circle-reverse-yellow.png';
import './Login.css';
import axios from 'axios';
import { config } from '../Config/Config';
import TopMenu from "../TopMenu/TopMenu";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: "",
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            profilePic: localStorage.getItem('profilePic'),
            redirect: false,
            name: localStorage.getItem('name')
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleSubmit(event) {
        event.preventDefault();
        this.addUserDescription();
    }
    addProfilePicture = () => {
        window.location.href = "/favauthor"
    }
    addUserDescription = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "description": this.state.description
        }
       // console.log(payload, "payload")
        var self = this
        axios.post(config.userUrl + 'user/addDescription', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                  //  console.log("success");
                    self.setState({ redirect: true })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        // var userName = this.state.name.split("@")[0];
        const { redirect ,name} = this.state
        if (redirect) {
            return <Redirect
                to={{
                    pathname: "/interests",
                    state: { token: this.state.token }
                }}
            />
        }
        return (
            <div>
                <img alt="" src={loginlogo} className="login-logo mt-2" />
                <img alt="" src={circle} className="-Circle" />
                <p className="profile-heading">Profile</p>
                <center>
                    {this.state.profilePic !== null ? <div><img alt="" src={config.userUrl + this.state.profilePic} className="profile-icon ml-4" /><h5 className="mr-4">{name}</h5></div> : <div><img alt="" src={author1} className="profile-icon ml-4" onClick={this.addProfilePicture} /><br /><br /><h5 className="mr-4">{name}</h5></div>}
                    {/* <img alt="" src={upload} className="profile-upload" /><br /> */}
                    {/* <h5 className="mt-3 mr-4">{userName}</h5> */}
                </center>
                <br />
                <div><img alt="" src={circleblue} className="circle_blue" /></div>
                <Form onSubmit={this.handleSubmit} className="form-profile">
                    <center>
                        <div style={{ width: "80%" }} >
                            <div className="form-group">
                                <input type="text"
                                    className="form-control form-bg"
                                    aria-describedby="text"
                                    value={this.state.description}
                                    name="description"
                                    placeholder="Describe yourself in One Line*"
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                    </center>
                    <img alt="" src={circleyellow} class="circle_yellow" />
                    {this.state.description.length !== 0 && this.state.profilePic !== null ?
                        <center><Button variant="default" type="submit" className="btn-block login-btn next">Next</Button></center> :
                        <center><Button variant="default" disabled={true} type="submit" className="btn-block login-btn next">Next</Button></center>}<br />

                </Form>
            </div >
        );
    }
}
