import React, { Component } from "react";
import loginlogo from '../images/login-logo.png';
import notification from '../images/Notification.png';
import about from '../images/About.png';
import stats from '../images/stats.png';
import codeofconduct from '../images/codeofconduct.png';
import dataprivacy from '../images/Data Privacy.png';
import terms from '../images/Terms & Conditions.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import './Settings.css';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: false,
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),

        }
    }
    render() {
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                {/* <img alt="" src={notification} style={{ width: "20px" }} /> */}
                <p className="settings-new">Backdrop</p>
                <div>
                    <a href="/about" className="settings-text"><img alt="" src={stats} className="notification-icon1" />&nbsp; &nbsp;Stats</a><hr className="hr-width" />
                    {/* <a href="/notifications" className="settings-text"><img alt="" src={notification} className="notification-icon" />&nbsp; &nbsp;Notifications</a><hr className="hr-width" />
                    <a href="/security" className="settings-text"><img alt="" src={codeofconduct} className="notification-icon" />&nbsp; &nbsp;Code of Conduct</a><hr className="hr-width" /> */}
                    <a href="/dataprivacy" className="settings-text"><img alt="" src={dataprivacy} className="notification-icon" />&nbsp; &nbsp;Data Privacy</a><hr className="hr-width" />
                    <a href="/terms" className="settings-text"><img alt="" src={terms} className="notification-icon" />&nbsp; &nbsp;Terms & Conditions</a>
                </div>
                <BottomMenu />
            </div>
        )
    }
}