import React, { Component } from "react";
import { Button, Form, Col, Card, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import { config } from '../Config/Config';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class ViewAllGroups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            allGroups: [],


        }
    }
    componentDidMount() {
        this.getActiveGroups();
    }
    getActiveGroups = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getActiveGroups', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    self.setState({ allGroups: response.data.groups, isLoading: false })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        const { allGroups } = this.state;
        const updatedDate = allGroups.map((i, idx) => {
            var a = new Date(i.createdAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
                {/* <div className="screen-topgap">
                    <div className="your-jitbits">View All Groups</div>
                    <div className="ml-3 mr-3 mt-3 top-jit">
                        {allGroups.length === 0 ? <center><h4>No Groups.</h4></center> :
                            <div>
                                {allGroups.map((data, i) => (
                                    <Link to="/viewgroup">
                                    <Card style={{ display: "flex",color:"black" }} className="referrals-card">
                                        <div className="ml-1 mt-3">&nbsp; <b>{data.groupName}</b> <span style={{ float: "right", marginRight: "10px" }}> 
                                        ({data.email})</span></div>
                                    </Card>
                                    </Link>
                                ))}
                            </div>
                        }
                    </div>
                </div> */}
                <div className="screen-topgap">
                    <div className="your-jitbits">View All Groups</div>
                    <div className="ml-3 mr-3 top-jit" >
                        {this.state.allGroups.length !== 0 ?
                            <div>
                                {this.state.allGroups.map((data, i) => (
                                    <Link to={{ pathname: `/viewgroup/${data.id}`, data: this.state.allGroups }} className="ml-2 mr-2 link">
                                        <Card style={{ display: "flex" }} className="view_opp_card">
                                            <Row style={{ width: "100%", marginTop: "6px" }}>
                                                <Col xs={8}>
                                                    {/* <img src={article} className="ml-2 favbits-article-jit" /> */}
                                                    <div className="ml-2 favbits-title-new" style={{ fontSize: "17px" }}>{data.groupName}</div>
                                                    <div style={{ display: "flex" }}><img alt="" src={data.logo} className="ml-2 opp-img-Author" /></div>
                                                </Col>
                                                <Col xs={4}>
                                                    <div style={{ float: "right", color: "black", fontSize: "14px", fontWeight: "600", marginRight: "-12px" }}>{data.typeOfGroup}</div><br />

                                                    <div style={{ float: "right", color: "black", fontSize: "13px", fontWeight: "600", marginRight: "-12px" }}>{data.status}</div>


                                                    <div style={{ float: "right", color: "black",marginTop:"1.6rem", fontSize: "13px", fontWeight: "600", marginRight: "-35px" }}><b>{updatedDate[i]}</b></div>
                                                    {/* <div style={{ display: "flex", marginTop: "15px" }}><img alt="" src={author} className="ml-2 jitbits-img-Author" /><span className="ml-2 jit-top">Dinesh</span>
                                                        </div> */}

                                                </Col>
                                            </Row>
                                        </Card>
                                    </Link>
                                ))}
                            </div> : <center className="mt-5">No Groups to display</center>}
                    </div>
                </div>
                <div className="mb-bottom"></div>
                <BottomMenu />
            </div>

        );
    };
}