import React, { Component } from "react";
import { Button, Form, OverlayTrigger, Popover, Card, Image } from "react-bootstrap";
import loginlogo from '../images/login-logo.png';
import axios from 'axios';
import { config } from '../Config/Config';
import activecircle from '../images/active-cir.svg';
import needinfocircle from '../images/needinfo-cir.svg';
import rejectedcircle from '../images/rejected-empty-cir.svg';
import BottomMenu from "../BottomMenu/BottomMenu";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Invitations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            invitations: [
                {
                    createdAt: "2022-06-30T11:38:45.451Z", email: "pexivem986@web34.com", id: 1, invitationStatus: 1, invitationUserName: "qwe", name: "Srigowri", referralCode: "9uAWWD", sendByUserId: "ea250e90-9960-11ec-93fc-d9cda384cd22", updatedAt: "2022-07-02T11:38:45.451Z"
                },
                {
                    createdAt: "2022-06-30T11:38:45.451Z", email: "pex@web34.com", id: 2, invitationStatus: 2, invitationUserName: "abc", name: "Pidex", referralCode: "9uAWWD", sendByUserId: "ea250e90-9960-11ec-93fc-d9cda384cd22", updatedAt: "2022-07-02T11:38:45.451Z"
                },
                {
                    createdAt: "2022-06-30T11:38:45.451Z", email: "peem986@web34.com", id: 3, invitationStatus: 1, invitationUserName: "qwe", name: "gowri", referralCode: "9uAWWD", sendByUserId: "ea250e90-9960-11ec-93fc-d9cda384cd22", updatedAt: "2022-07-02T11:38:45.451Z"
                },
                {
                    createdAt: "2022-06-30T11:38:45.451Z", email: "vem986@web34.com", id: 4, invitationStatus: 2, invitationUserName: "pqr", name: "Dinesh", referralCode: "9uAWWD", sendByUserId: "ea250e90-9960-11ec-93fc-d9cda384cd22", updatedAt: "2022-07-02T11:38:45.451Z"
                }
            ],
            overlay: false,
            // isLoading: true

        }
    }

    componentDidMount() {
        // this.getInvitations()
    }
    getInvitations = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getInvitationData', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                    self.setState({ invitations: response.data.getInvitationData, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    overlayBox = (id) => {
        //console.log("id")
        // this.setState({ overlay: true })
    }
    render() {
        const { isLoading, overlay, invitations } = this.state;
        if (isLoading) {
            return <div className="App"></div>;
        }
        // const updatedDate = invitations.map((i, idx) => {
        //     var a = new Date(i.updatedAt)
        //     var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        //     return updated;
        // })
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
                <div className="screen-topgap">
                    <div className="your-jitbits">Sent Invitations</div>
                    <div className="ml-3 mr-3 mt-3 top-jit">
                        {invitations.length === 0 ? <center><h4>No Invitations Sent.</h4></center> :
                            <div>
                                {invitations.map((data, i) => (
                                    <Card style={{ display: "flex" }} className="ref-status-card">
                                        <div>

                                            {(data.invitationUserName.length + data.email.length) > 30 ?
                                                <OverlayTrigger
                                                    placement="top"
                                                    trigger={overlay ?
                                                        ""
                                                        : "click"}

                                                    rootClose
                                                    overlay={(
                                                        <Popover
                                                            id="test">
                                                            <Popover.Content >
                                                                <div style={{ marginTop: "-5px" }}><b>{data.email}</b></div>
                                                            </Popover.Content>
                                                        </Popover>
                                                    )}>
                                                    {overlay ?
                                                        "" :
                                                        <div>
                                                            <span style={{ float: "left", marginLeft: "1px" }}>
                                                                {data.invitationStatus == 2 ? <img src={needinfocircle} className="ref-circles" />
                                                                    : <img src={activecircle} className="ref-circles" />}</span><b className="ml-5">{data.invitationUserName}</b> ({data.email.substring(0, 21)}...)
                                                        </div>}
                                                </OverlayTrigger>
                                                :
                                                <div>
                                                    <span style={{ float: "left", marginLeft: "1px" }}>
                                                        {data.invitationStatus == 2 ? <img src={needinfocircle} className="ref-circles" />
                                                            : <img src={activecircle} className="ref-circles" />}</span><b className="ml-5">{data.invitationUserName}</b> ({data.email})
                                                </div>}
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        }
                    </div>
                </div >
                <div className="mb-bottom"></div>
                <BottomMenu />
            </div >

        );
    };
}