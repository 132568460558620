// import React, { Component } from 'react'
// import { PollData } from './PollData';
// import { Button } from "react-bootstrap";
// import './Poll.css';

// class NewPoll extends Component {
//     state = {
//         userAnswer: null,
//         quiz: [],
//         currentIndex: 0,
//         options: [],
//         PollEnd: false,
//         score: 0,
//         disabled: true
//     }

//     loadQuiz = () => {
//         const { currentIndex } = this.state
//         this.setState(() => {
//             return {
//                 question: PollData[currentIndex].question,
//                 options: PollData[currentIndex].options,
//                 answer: PollData[currentIndex].answer
//             }
//         }
//         )
//     }
//     componentDidMount() {
//         this.loadQuiz();
//     }

//     componentDidUpdate(prevProps, prevState) {
//         const { currentIndex } = this.state;
//         if (this.state.currentIndex !== prevState.currentIndex) {
//             this.setState(() => {
//                 return {
//                     disabled: true,
//                     question: PollData[currentIndex].question,
//                     options: PollData[currentIndex].options,
//                     answer: PollData[currentIndex].answer
//                 }
//             });

//         }
//     }
//     checkAnswer = answer => {
//         this.setState({
//             userAnswer: answer,
//             disabled: false
//         })
//     }

//     finishHandler = () => {
//         const { userAnswer, answer, score } = this.state
//         if (userAnswer === answer) {
//             this.setState({
//                 score: score + 1
//             })
//         }
//         if (this.state.currentIndex === PollData.length - 1) {
//             this.setState({
//                 PollEnd: true
//             })
//         }

//     }

//     render() {
//         const { question, options, currentIndex, userAnswer, PollEnd } = this.state
//         if (PollEnd) {
//             return (
//                 <div>
//                     <center className="mt-3">
//                         <h3>Poll Over. Score: {this.state.score}</h3>
//                         <p>The correct Answers for the quiz are</p>
//                         <div className="answers">
//                             {PollData.map((item, index) => (
//                                 <div className='options'
//                                     key={index}>
//                                     {item.answer}
//                                 </div>
//                             ))}
//                         </div>
//                     </center>
//                 </div>
//             )
//         }

//         return (
//             <div>
//                 <center className="mt-3">
//                     <h5>{`Question ${currentIndex + 1} of ${PollData.length}`}</h5>
//                     <h3>{question}</h3>
//                     {options.map(option => (
//                         <p key={option.id}
//                             className={`options ${userAnswer === option ? "selected" : null}`}
//                             onClick={() => this.checkAnswer(option)}>
//                             {option}
//                         </p>
//                     ))}
//                     {currentIndex === PollData.length - 1 &&
//                         <Button variant="default"
//                             className="poll-submit-btn"
//                             disabled={this.state.disabled}
//                             onClick={this.finishHandler}
//                         >Finish</Button>
//                     }
//                 </center>
//             </div>
//         )
//     }
// }

// export default NewPoll

import React, { Component } from 'react'
import { PollData } from './PollData';
import { Button } from "react-bootstrap";
import './NewPoll.css';

export default function NewPoll() {

    let poll = {
        question: "What's your favorite programming language?",
        answers: [
            "C", "Java", "PHP", "JavaScript"
        ],
        pollCount: 20,
        answersWeight: [4, 4, 2, 10],
        selectedAnswer: -1
    };

    // let pollDOM = {
    //     question: document.querySelector(".poll .question"),
    //     answers: document.querySelector(".poll .answers")
    // };

    // pollDOM.question.innerText = poll.question;
    // pollDOM.answers.innerHTML = poll.answers.map(function (answer, i) {
    //     return (
    //         `
    //         <div class="answer" onclick="markAnswer('${i}')">
    //           ${answer}
    //           <span class="percentage-bar"></span>
    //           <span class="percentage-value"></span>
    //         </div>
    //       `
    //     );
    // }).join("");

    function markAnswer(i) {
        poll.selectedAnswer = +i;

        try {
            console.log("aa", document.querySelector(".poll .answers .answer.selected"))
            document.querySelector(".poll .answers .answer.selected").classList.remove("selected");
        } catch (msg) { }
        document.querySelectorAll(".poll .answers .answer")[+i].classList.add("selected");
        showResults();
    }

    function showResults() {
        let answers = document.querySelectorAll(".poll .answers .answer");
        for (let i = 0; i < answers.length; i++) {
            let percentage = 0;
            if (i === poll.selectedAnswer) {
                percentage = Math.round(
                    (poll.answersWeight[i] + 1) * 100 / (poll.pollCount + 1)
                );
            } else {
                percentage = Math.round(
                    (poll.answersWeight[i]) * 100 / (poll.pollCount + 1)
                );
            }

            answers[i].querySelector(".percentage-bar").style.width = percentage + "%";
            answers[i].querySelector(".percentage-value").innerText = percentage + "%";
        }
    }
    return (
        <div>
            <div class="question">{poll.question}</div>
            <div class="poll">
                {poll.answers.map(function (ans, i) {
                    return (
                        <div class="answers">
                            <div class="answer" onClick={markAnswer(i)}>
                                {ans}
                                <span class="percentage-bar"></span>
                                <span class="percentage-value"></span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
