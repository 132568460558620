import React from 'react'
import { useState, useEffect } from 'react';
import { Button, Card, FormControl } from "react-bootstrap";
import loginlogo from '../images/login-logo.png';
import profile from '../images/profilepicture.svg';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import './JitBits.css';
import { Link } from 'react-router-dom';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function SearchUsers() {
    const [searchValue, setSearchValue] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [searchModal, setSearchModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))

    useEffect(() => {
    }, []);
    const onChangeHandler = (e) => {
       // console.log("length", e.target.value)
        search(e.target.value);
        setSearchValue(e.target.value)
       // console.log("a", e.target.value.length)
    };
    const search = async val => {
        setLoading(true)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + `user/searchActiveUsers?search=${val}`, { headers: headers })
            .then(function (response) {
               // console.log("searchActiveUsers", response);
                // const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                  //  console.log("searchActiveUsers retrieved successfull", response.data);
                    if (searchValue.length !== 0) {
                        // self.setState({ searchResults: response.data.searchProduct, searchModal: true })
                        setSearchResults(response.data.searchUser)
                        setSearchModal(true)
                    } else {
                        // setSearchResults: [], searchModal: false })
                        setSearchResults([])
                        setSearchModal(false)
                    }

                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });

        setLoading(false)
    };
    const closeSearch = (e) => {
       // console.log("search")
        setSearchModal(false)
        setSearchValue("")
    }
    return (
        <div>
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="screen-topgap">
                <div className="your-jitbits">Search Users</div>
                <div className="mt-2 ml-3 mr-3 top-jit" >
                    <FormControl
                        type="text"
                        placeholder="Search"
                        value={searchValue}
                        onChange={e => onChangeHandler(e)}
                        id="search" className="abc form-control search_box"
                        aria-label="Search keyword"
                    />
                    {searchModal ?
                        <div id="card-search">
                            <div >
                                {searchResults.length == 0 ? <div onKeyUp={e => closeSearch(e)}>No Results found</div> :
                                    <Card>
                                        {searchResults.slice(0, 5).map((data, i) =>
                                        (
                                            <Link to={{
                                                pathname: `/getprofilebyid/${data.name}`,
                                                state: {
                                                    id: data.uuid
                                                }
                                            }} style={{ cursor: "pointer" }} className="border-underline" onClick={() => setSearchModal(false)}>
                                                <div className="link mb-1" style={{ display: "flex" }}><img style={{ width: "45px", padding: "2px 4px" }} src={(data.profilePicture === "" || data.profilePicture === null) ? profile : config.userUrl + data.profilePicture} />&nbsp;<span className="mt-1">{data.name}</span><span className="mt-1">, &nbsp;{data.fourEs}</span></div>

                                            </Link>
                                        ))}
                                    </Card>}
                            </div>
                        </div> : ""
                    }
                </div>
            </div>
            <div className="mb-bottom"></div>
            <BottomMenu />
        </div >
    )
}

