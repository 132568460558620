import React, { useState, useEffect, useRef } from 'react';
import loginlogo from '../images/login-logo.png';
import cup from '../images/rewards-cup.svg';
import { Button, Row, Col } from 'react-bootstrap';
import BottomMenu from '../BottomMenu/BottomMenu';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import leftarrow from '../images/quiz-leftarrow.svg';
import rightarrow from '../images/quiz-rightarrow.svg';
import leftarrowgray from '../images/quiz-leftarrow-gray.svg';
import rightarrowgray from '../images/quiz-rightarrow-gray.svg';
import thumbsup from '../images/ready to take quiz.png';
import quizcup from '../images/cup.jpg';
import scoredgood from '../images/scored good.png';
import scoredless from '../images/scored less.png';
// import leftarrow from '../images/arrow-left-svgrepo-com.svg';
// import rightarrow from '../images/arrow-right-svgrepo-com.svg';
// import leftarrowgray from '../images/arrow-left-gray.svg';
// import rightarrowgray from '../images/arrow-right-gray.svg';
import gamepad from "../images/gamepad.svg";

const key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY;
const simpleCrypto = new SimpleCrypto(key);

// const topics = ['Topic 1', 'Topic 2', 'Topic 3'];

// const questions = [
//     {
//         question: 'If x and y are two consecutive even numbers and y= 3602, then x=',
//         options: ['Option W', 'Option X', 'Option Y', 'Option Z'],
//         correctAnswer: 'Option X',
//     },
//     {
//         question: 'If a and b are two consecutive even numbers and b= 302, then a=',
//         options: ['Option A', 'Option B', 'Option C', 'Option D'],
//         correctAnswer: 'Option B',
//     },
//     {
//         question: 'If p and q are two consecutive even numbers and q= 3602, then p=',
//         options: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
//         correctAnswer: 'Option 4',
//     },
// ];

const Quiz = () => {
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [allTopics, setAllTopics] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [userAnswers, setUserAnswers] = useState();
    const [showQuiz, setShowQuiz] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [questionAnswered, setQuestionAnswered] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [topicName, setTopicName] = useState('');
    const [quizId, setQuizId] = useState();
    const [totalPlayedCount, setTotalPlayedCount] = useState();
    const [totRewardPoints, setTotRewardPoints] = useState();
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [duration, setDuration] = useState(null);
    const [userRank, setUserRank] = useState();
    const [quizCompletedDate, setQuizCompletedDate] = useState();
    const [elapsedTime, setElapsedTime] = useState(0);
    const [correctAnswersCount,setCorrectAnswersCount]=useState()
    const [imageSrc,setImageSrc]=useState()
    const totalTime = 40;
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    let timer;
    const timerRef = useRef(null);
    const [questionElapsedTime, setQuestionElapsedTime] = useState(Array(questions.length).fill(0));
    useEffect(() => {
        getQuizTopics()
        setUserAnswers(Array(questions?.length).fill(null))
        //  console.log("a", Array(questions?.length).fill(null))
    }, []);
    useEffect(() => {
        const prevElapsedTime = questionElapsedTime[currentQuestion - 1];
        if (prevElapsedTime >= 40) {
            setMessage("Timer up for previous question");
            setShowMessage(true);
        } else {
            setMessage("");
            setShowMessage(false);
        }
    }, [currentQuestion]);
    // useEffect(() => {
    //     if (showQuiz) {
    //         startTimer();
    //     }
    //     return () => clearInterval(timer);
    // }, [currentQuestion, showQuiz]);

    // useEffect(() => {
    //     if (elapsedTime >= totalTime) {
    //         moveToNextQuestion();
    //     }
    // }, [elapsedTime]);

    // const startTimer = () => {
    //     setElapsedTime(0); // Reset elapsed time

    //     const intervalId = setInterval(() => {
    //         setElapsedTime(prevTime => {
    //             if (prevTime >= totalTime) {
    //                 clearInterval(intervalId);
    //                 return prevTime;
    //             }
    //             return prevTime + 1;
    //         });
    //     }, 1000);

    //     return intervalId;
    // };
    useEffect(() => {
        if (showQuiz) {
            startTimer();
        }

        return () => {
            clearInterval(timerRef.current);
        };
    }, [showQuiz]);

    useEffect(() => {
        if (elapsedTime >= totalTime) {
            if (currentQuestion === questions.length - 1) {
                handleQuizCompletion();// Finish quiz if it's the last question
            } else {
                moveToNextQuestion();
            }
        }
    }, [elapsedTime]);
    const startTimer = () => {
        timerRef.current = setInterval(() => {
            setElapsedTime(prevTime => {
                if (prevTime >= totalTime) {
                    clearInterval(timerRef.current);
                    return prevTime;
                }
                return prevTime + 1;
            });
        }, 1000);
    };
    // useEffect(() => {
    //     if (showQuiz) {
    //         startTimer();
    //     }
    //     return () => clearInterval(timer);
    // }, [showQuiz]);

    // useEffect(() => {
    //     if (elapsedTime >= totalTime) {
    //         moveToNextQuestion();
    //     }
    // }, [elapsedTime]);

    // const startTimer = () => {
    //     // setElapsedTime(0); // Reset elapsed time

    //     timer = setInterval(() => {
    //         setElapsedTime(prevTime => {
    //             if (prevTime >= totalTime) {
    //                 clearInterval(timer); // Clear the interval if time is up
    //                 return prevTime;
    //             }
    //             console.log("time", timer, prevTime)
    //             return prevTime + 1;
    //         });

    //     }, 1000);
    // };

    const getQuizTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getQuizTopics', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status == true) {
                    //  console.log("getAllTopics");
                    setAllTopics(response.data.topics)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getRandomQuizQuestionsByTopicId = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "topicId": selectedTopic
        }
        axios.post(config.userUrl + `user/getRandomQuizQuestionsByTopicId`, payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setTopicName(response.data.topicDetails.topicName)
                    setQuestions(response.data.randomQuizData)
                    setQuizId(response.data.quizId)
                    //  console.log("questions", response.data.randomQuizData)
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    function formatSecondsToHHMMSS(seconds) {
        // Calculate hours, minutes, and seconds
        const intSeconds = Math.floor(seconds);
        const fractionalSeconds = Math.round((seconds - intSeconds) * 100); // Convert fraction to milliseconds

        // Calculate hours, minutes, and seconds
        const hours = Math.floor(intSeconds / 3600);
        const minutes = Math.floor((intSeconds % 3600) / 60);
        const secs = intSeconds % 60;

        // Format hours, minutes, and seconds as two-digit strings
        const hoursStr = String(hours).padStart(2, '0');
        const minutesStr = String(minutes).padStart(2, '0');
        const secsStr = String(secs).padStart(2, '0');
        const fractionalStr = String(fractionalSeconds).padStart(2, '0').slice(0, 2); // Only two digits for fractional part

        // Return the formatted time string
        return `${hoursStr}:${minutesStr}:${secsStr}`;
    }

    const submitQuiz = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        // let rewardPoints = 2;
        // let totalRewardPoints = userAnswers?.filter((ans, index) => ans === questions[index]?.correctAnswerIndex).length

        // // console.log("userAnswers", userAnswers, totalRewardPoints)
        // let points = totalRewardPoints * rewardPoints
        // setTotRewardPoints(points)

        let totalRewardPoints = userAnswers?.reduce((acc, ans, index) => {
            //  console.log("answer", ans, acc, index);
            let points = parseInt(questions[index]?.points, 10);
            //   console.log("ans", acc + points, ans === questions[index]?.correctAnswerIndex ? acc + points : acc,acc);
            return ans === questions[index]?.correctAnswerIndex ? acc + points : acc;
        }, 0);
        let totalTimeTaken = questions.reduce((acc, question) => {
            return acc + question.duration;
        }, 0);
        // console.log("userAnswers", userAnswers, totalRewardPoints)
        console.log("totalRewards", totalRewardPoints, totalTimeTaken)
        setTotRewardPoints(totalRewardPoints);
        const end = Date.now() / 1000; // Get the current time in seconds
        setEndTime(end);
        console.log("Quiz finished at:", end);
        const timeTaken = end - startTime; // Calculate the duration in seconds
        const roundedTimeTaken = parseFloat(timeTaken.toFixed(2)); // Round to 2 decimal places
        setDuration(roundedTimeTaken);

        // let P=M * F^(t/T)
        //M * Math.pow(0.5, t / T);
        let P = totalRewardPoints * Math.pow(0.5, roundedTimeTaken / totalTimeTaken)
        console.log("P", P, Math.round(P), totalRewardPoints, roundedTimeTaken, totalTimeTaken)
        const cAnswersCount = userAnswers?.filter((ans, index) => ans === questions[index]?.correctAnswerIndex).length;
        setCorrectAnswersCount(cAnswersCount)
        const imgSrc = cAnswersCount >= 3 ? scoredgood : scoredless
        setImageSrc(imgSrc)
        var payload = {
            "quizId": quizId,
            "topicId": parseInt(selectedTopic),
            "duration": formatSecondsToHHMMSS(roundedTimeTaken),
            "allocated_time": formatSecondsToHHMMSS(totalTimeTaken),
            "curr_duration": formatSecondsToHHMMSS(roundedTimeTaken),
            "curr_allocated_time": formatSecondsToHHMMSS(totalTimeTaken),
            "quizRewardPoint": totalRewardPoints,
            "points": Math.round(P),
            "accumulatedpoints": Math.round(P)
        }
        console.log("data duration", payload, formatSecondsToHHMMSS(roundedTimeTaken))
        axios.post(config.userUrl + 'user/updateQuizRewardPoint', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    // console.log("success!!")
                    getUserRankByQuizId()
                } else {
                    console.log("error")
                }
            })
            .catch(function (error) {
                console.log("payload", payload, error)
            });
    }
    const getUserRankByQuizId = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "quizId": quizId
        }
        console.log("payload", payload)
        axios.post(config.userUrl + 'user/getUserRankByQuizId', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    if (response.data.rankData.length != 0) {
                        setUserRank(response.data.rankData[0]?.userRank)
                        setQuizCompletedDate(response.data.rankData[0]?.updatedAt)
                        console.log("userRank", response.data.rankData, response.data.rankData[0]?.userRank)
                    }
                } else {
                    console.log("error")
                }
            })
            .catch(function (error) {
                console.log("payload", payload, error)
            });
    }
    const handleTopicSelection = (topic) => {
        setSelectedTopic(topic);

    };

    const handleTopicSubmit = () => {
        if (selectedTopic) {
            setShowQuiz(true);
            getRandomQuizQuestionsByTopicId()
            const start = Date.now() / 1000; // Get the current time in seconds
            setStartTime(start);
            console.log("Quiz started at:", start);
        }
    };

    const handleAnswerSelection = (selectedAnswer) => {
        const newAnswers = [...userAnswers];
        newAnswers[currentQuestion] = selectedAnswer;
        //  console.log("ans", selectedAnswer, newAnswers[currentQuestion])
        setUserAnswers(newAnswers);
    };
    // const moveToNextQuestion = () => {
    //     if (currentQuestion < questions.length - 1) {
    //         setCurrentQuestion(prevQuestion => prevQuestion + 1);
    //         setElapsedTime(0); // Reset the timer for the next question
    //         clearInterval(timerRef.current); // Clear the previous timer
    //         startTimer(); // Start a new timer
    //     } 
    // };
    // const moveToPrevQuestion = () => {
    //     if (currentQuestion > 0) {
    //         setCurrentQuestion(prevQuestion => prevQuestion - 1);
    //         setElapsedTime(0); // Reset the timer for the previous question
    //         clearInterval(timerRef.current); // Clear the previous timer
    //         startTimer(); // Start a new timer
    //     }
    // };
    const moveToNextQuestion = () => {
        setMessage("");
        setShowMessage(false);
        setQuestionElapsedTime(prevTimes => {
            const updatedTimes = [...prevTimes];
            updatedTimes[currentQuestion] = elapsedTime; // Save elapsed time for the current question
            return updatedTimes;
        });
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(prevQuestion => prevQuestion + 1);
            setElapsedTime(0); // Reset the timer for the next question
            clearInterval(timerRef.current); // Clear the previous timer
            startTimer(); // Start a new timer
        }
    };
    // const moveToPrevQuestion = () => {
    //     if (currentQuestion > 0) {
    //         setQuestionElapsedTime(prevTimes => {
    //             const updatedTimes = [...prevTimes];
    //             updatedTimes[currentQuestion] = elapsedTime; // Save elapsed time for the current question
    //             return updatedTimes;
    //         });
    //         setCurrentQuestion(prevQuestion => prevQuestion - 1);
    //         setElapsedTime(questionElapsedTime[currentQuestion - 1]); // Set elapsed time for the previous question
    //         clearInterval(timerRef.current); // Clear the previous timer
    //         startTimer(); // Start a new timer
    //     }
    // };
    const moveToPrevQuestion = () => {
        if (currentQuestion > 0) {
            // Save the elapsed time for the current question
            setQuestionElapsedTime(prevTimes => {
                const updatedTimes = [...prevTimes];
                updatedTimes[currentQuestion] = elapsedTime;
                return updatedTimes;
            });
            const prevElapsedTime = questionElapsedTime[currentQuestion - 1];

            if (prevElapsedTime >= 40) {
                setShowMessage(true);
                setMessage("Timer up for previous question");
            } else {
                setShowMessage(false); // Ensure message is hidden if timer wasn't up
                setMessage("");
            }
            setCurrentQuestion(prevQuestion => prevQuestion - 1);
            setElapsedTime(questionElapsedTime[currentQuestion - 1]); // Set elapsed time for the previous question
            clearInterval(timerRef.current); // Clear the previous timer
            startTimer(); // Start a new timer
        }
    };
    // const moveToNextQuestion = () => {
    //     if (currentQuestion < questions.length - 1) {
    //         setCurrentQuestion(prevQuestion => prevQuestion + 1);
    //         setElapsedTime(0); // Reset the timer for the next question
    //         clearInterval(timer); // Clear the previous timer
    //         startTimer(); // Start a new timer
    //     }
    // };

    // const moveToPrevQuestion = () => {
    //     if (currentQuestion > 0) {
    //         setCurrentQuestion(prevQuestion => prevQuestion - 1);
    //         setElapsedTime(0); // Reset the timer for the previous question
    //         clearInterval(timer); // Clear the previous timer
    //         startTimer(); // Start a new timer
    //     }
    // };

    const handleNextQuestion = () => {
        moveToNextQuestion();
    };

    const handlePrevQuestion = () => {
        moveToPrevQuestion();
    };
    // const handleNextQuestion = () => {
    //     if (questionAnswered || userAnswers[currentQuestion] !== null) {
    //         setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    //         setQuestionAnswered(false); // Reset the question answered status
    //     } else {
    //         // console.log('Please select an option');
    //     }
    // };
    // const handleQuestion = () => {
    //     //   console.log('no next question');
    // }
    // const handlePrevQuestion = () => {
    //     if (currentQuestion > 0) {
    //         setCurrentQuestion((prevQuestion) => prevQuestion - 1);
    //         setQuestionAnswered(false); // Reset the question answered status
    //     }
    // };
    const handleQuestion = () => {
        //   console.log('no next question');
    }
    const handleQuizCompletion = () => {
        const isAllQuestionsAnswered = userAnswers.every((answer) => answer !== null);
        if (isAllQuestionsAnswered) {
            submitQuiz()
            setShowPopup(true);
            //     const end = Date.now() / 1000; // Get the current time in seconds
            //     setEndTime(end);
            //     console.log("Quiz finished at:", end);
            //   const timeTaken = end - startTime; // Calculate the duration in seconds
            //         setDuration(timeTaken);
            //         console.log("Time taken (seconds):", timeTaken);   if (startTime !== null) {    
            //     }
        } else {
            submitQuiz()
            setShowPopup(true);
            // console.log('Please answer all questions before completing the quiz.');
        }
    };

    const calculateScore = () => {
        let score = 0;
        //  console.log("user", userAnswers)
        userAnswers.forEach((userAnswer, index) => {
            if (userAnswer === questions[index]?.correctAnswerIndex) {
                score++;
            }
        });
        return (score / questions.length) * 100;
    };

    return (
        <div>
            <div className="top_bar">
                <img alt="" src={loginlogo} className="login-logo mt-2" />
            </div>
            {!showQuiz ? (
                <div style={{ marginTop: '2.5rem' }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="quiz-txt ml-3">Quiz Topics</div>
                        <div></div>
                    </div>
                    {/* <div className="quiz-topic">Select a Topic</div> */}
                    <div className="mt-2" style={{ height: "65vh", overflowY: "auto" }}>
                        {allTopics?.map((topic) => (
                            <div>
                                <div
                                    key={topic.id}
                                    onClick={() => handleTopicSelection(topic.topicId)}
                                    className={selectedTopic === topic.topicId ? 'quiz-t-bg' : 'quiz-t'}>
                                    <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                        <div style={{ width: "30px" }}>
                                            <img
                                                src={"../" + topic.topicName.replace(/ *\([^]*\) */g, "") + ".png"}
                                                style={{ width: "36px" }}
                                                alt={topic.topicName}
                                            />
                                        </div>
                                        <div style={{ flex: 1, marginLeft: "10px" }}>
                                            <span style={{ fontSize: "17px", color: "#31197C", fontWeight: 600 }}>{topic.topicName.replace(/ *\([^]*\) */g, "")}</span><br />
                                            <span style={{ fontSize: "13px" }}>{topic.questions?.length} Questions</span>
                                        </div>
                                        <div style={{ width: "100px", textAlign: "right" }}>
                                            <img
                                                src={gamepad}
                                                style={{ width: "30px" }}
                                                alt={"gamepad"}
                                            /><br />
                                            <span style={{ fontSize: "13px" }}>   {topic.totalquizplayedcount} Played</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                    <center>
                        {' '}
                        <Button className="btn-block quiz-topic" variant="default" onClick={handleTopicSubmit} >
                            Select a topic
                        </Button>
                    </center>
                </div>
            ) : (
                <div style={{ marginTop: '2.8rem', background: "#ECEFF0", height: "100%" }}>
                    {/* <div
                    className="ml-3 mr-3"
                    style={{
                        borderRadius: '10px',
                        height: '15px',
                        overflow: 'hidden',
                        backgroundColor: 'lightgrey',
                    }}
                >
                    <div style={{
                        width: `${((currentQuestion + 1) / questions.length) * 100}%`,
                        backgroundColor: '#ff942c',
                        marginTop: '-1px',
                        height: '15px',
                    }}>

                    </div>
                </div> */}
                    {/* <div style={{ background: "#dcc8a8", width: "94.5%", height:"105px",padding: "5px", display: "flex", alignItems: "center", justifyContent: "space-between", borderRadius: "15px", margin: "5px 10px" }}>
                        <div><img src={thumbsup} style={{ width: "80px" }} /></div>
                    </div> */}
                    <div style={{ background: "#dcc8a8", width: "94.5%", height: "105px", padding: "5px", borderRadius: "15px", margin: "5px 10px" }}>
                        <Row style={{ width: "100%" }}>
                            <Col xs={3}>
                                <div><img src={thumbsup} style={{ marginTop: "-10px", marginLeft: "4px", width: "68px" }} /></div>
                            </Col>
                            <Col xs={9}>
                                <div style={{ marginLeft: "10px", width: "100%", fontSize: "22px", fontWeight: 600, color: "#31197C" }}>{topicName.replace(/ *\([^]*\) */g, "")}<br />Quiz</div>
                            </Col>
                        </Row>

                    </div>

                    <TimerProgressBar elapsedTime={elapsedTime} totalTime={totalTime} />
                    {/* <div style={{ float: "right", marginRight: "1rem" }}>{currentQuestion + 1}/{questions.length}</div> */}
                    {/* <div className="ml-3 mr-3 mt-3" style={{ fontSize: "18px" }}>
                     
                    </div> */}
                    <div
                        className="mt-3"
                        style={{
                            border: 'none',
                            borderRadius: '10px',
                            padding: '2px 3px',
                            margin: '5px 10px',
                            backgroundColor: "#fff",
                        }}
                    >
                        <div className="ml-1" style={{ marginTop: "-3px", fontSize: "18px", fontWeight: 600, color: "#31197C" }}>
                            Question {currentQuestion + 1}/{questions.length}
                        </div>
                        <div className="quiz-ques">{questions[currentQuestion]?.question}</div>
                    </div>
                    <div className='ml-2 mr-2' style={{
                        border: 'none',
                        borderRadius: '10px',
                        padding: '3px 1px',
                        margin: '5px 2px',
                        backgroundColor: "#fff",
                        overflowY: "auto",
                        height: "39vh"
                    }}>
                        {questions[currentQuestion]?.options.map((option, index) => (
                            <div
                                key={option}
                                onClick={() => handleAnswerSelection(index)}
                                style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '5px 10px',
                                    margin: '10px 5px',
                                    cursor: questionAnswered ? 'default' : 'pointer',
                                    border:
                                        userAnswers[currentQuestion] === index
                                            ? index === questions[currentQuestion]?.correctAnswerIndex
                                                ? '3px solid #31197c'
                                                : '3px solid #31197c'
                                            : '2px solid lightgray',
                                    height: '55px',
                                    lineHeight: "18px",         // Fixed height for the option box
                                    display: 'flex',        // Use flexbox for vertical alignment
                                    alignItems: 'center',
                                    fontSize: "12px",  // Center text vertically
                                    justifyContent: 'left', // Center text horizontally (optional)
                                    textAlign: 'left'
                                }}
                            >
                                {option}
                            </div>
                        ))}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: "0 10px" }}>
                        <div
                        >
                            {/* <i class="fa-solid fa-arrow-left" style={currentQuestion === 0 ? { color: "lightgrey" } : { color: "black" }}></i> */}
                            <img src={currentQuestion === 0 ? leftarrowgray : leftarrow} style={{ width: "30px", position: "absolute", left: "15px", bottom: "4.3rem" }}
                                onClick={handlePrevQuestion}
                                disabled={currentQuestion === 0} />
                        </div>
                        <div >
                            {/* <i class="fa" style={currentQuestion === questions.length - 1 ? { color: "lightgrey" } : { color: "black" }}>➜</i> */}
                            <img src={currentQuestion === questions.length - 1 ? rightarrowgray : rightarrow} style={{ width: "30px", position: "absolute", right: "15px", bottom: "4.3rem" }} onClick={currentQuestion === questions.length - 1 ? handleQuestion : handleNextQuestion} disabled={currentQuestion === questions.length - 1} />
                        </div>
                    </div>
                    {showMessage && (
                        <div style={{ marginTop:"-7px",color: 'red', fontSize: "12px", textAlign: "center" }}>
                            {message}
                        </div>
                    )}
                    {currentQuestion === questions.length - 1 && (
                        <div>
                            <Button className="quiz-s-btn" variant="default" onClick={handleQuizCompletion}>
                                Finish Quiz
                            </Button>
                        </div>
                    )}
                </div>
            )}
            {showPopup && (
                <div
                    style={{
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: '999',
                    }}
                >
                    <div
                        style={{
                            position: 'relative',
                            backgroundColor: 'white',
                            border: '2px solid darkgrey',
                            borderRadius: '20px',
                            width: '94%',
                            zIndex: '1000',
                        }}
                    >
                        <div className='background-gradient'>
                        <center><br/>
                            <div style={{ fontSize: "24px", fontWeight: 600, color: "#31197C" }}><strong>{topicName.replace(/ *\([^]*\) */g, "")}</strong></div>
                            <div style={{ fontSize: "24px", fontWeight: 600, color: "#31197C" }}>Quiz on:  <span>{moment(Date.now()).format("DD-MM-YYYY")}</span> </div>
                            <img className="mt-2" src={imageSrc} style={{ width: "180px" }} />
                            <div style={{ fontSize: "24px", fontWeight: 600, color: "#31197C" }}>
                                {userAnswers.filter((ans, index) => ans === questions[index]?.correctAnswerIndex).length}/{questions.length}  correct.<br /></div>

                            {/* <div style={{color:"#31197C"}}><span style={{fontSize:"24px"}}> Your rank is  </span><h4 style={{fontSize:"28px",fontWeight:700}}>30</h4> */}

                            {userRank != null ?
                                <div style={{ color: "#31197C" }}><span style={{ fontSize: "24px" }}> Your Rank is  </span><h4 style={{ fontSize: "44px", fontWeight: 700 }}>{userRank}</h4></div> :
                                <div style={{ color: "#31197C" }}><span style={{ fontSize: "24px" }}> Rank not available.  </span></div>}
                            <Link to="/games"><Button variant="default" className="mt-3 deletebutton">OK</Button></Link><br /><br />
                        </center>
                        </div>
                        {/* <center>
                        <img src={cup} style={{ width: "100px" }} />
                        <h5><strong>Congrats!</strong></h5>
                        <h4 style={{ color: "#ff942c" }}>  <strong>{calculateScore().toFixed(2)}% Score</strong></h4>
                        <div>
                            <strong>Quiz Completed Successfully</strong>
                        </div>

                        <p className='mt-2'>
                            {userAnswers.filter((ans, index) => ans === questions[index]?.correctAnswerIndex).length}/{questions.length} is correct.<br />

                            <strong>You have earned {totRewardPoints} reward points.</strong>
                            {userRank != null ?
                                <strong> Your rank is <h4>{userRank}</h4> </strong> :
                                <strong></strong>}
                            {quizCompletedDate ?
                                <strong>Completed on:  <h4>{moment(quizCompletedDate).format("DD-MM-YYYY")}</h4> </strong> :
                                <strong></strong>}
                        </p>
                        <Link to="/games"><Button variant="default" className="mt-3 deletebutton">Ok</Button></Link>
                    </center> */}
                    </div>
                </div>
            )}<br /><br />
            <BottomMenu />
        </div>
    );
};


export default Quiz;

const TimerProgressBar = ({ elapsedTime, totalTime }) => {
    //  const totalTime = 60; // Total time in seconds

    // Calculate the percentage of time elapsed
    const progressPercentage = Math.min((elapsedTime / totalTime) * 100, 100);

    return (
        <div
            className="mt-3 ml-3 mr-3"
            style={{
                borderRadius: '10px',
                height: '15px',
                overflow: 'hidden',
                backgroundColor: 'lightgrey',
            }}
        >
            <div
                style={{
                    width: `${progressPercentage}%`,
                    backgroundColor: '#ff942c',
                    marginTop: '-1px',
                    height: '15px',
                }}
            />
        </div>
    );
};