import React, { Component } from "react";
import loginlogo from '../images/login-logo.png';
import notification from '../images/Notification.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class TopMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore'))
        }

    }
    render() {
        return (
            <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" />
                {this.state.token ?
                    <img alt="" src={notification} className="bell-icon" /> : null}
            </div>
        )
    }
}
