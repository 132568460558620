import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import './Interests.css';
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import AuthorsJson from './Authors.json';
import BottomMenu from "../BottomMenu/BottomMenu";

export default class FavReviewer extends Component {
    // constructor(props) {
    //     super(props);

    // }

    render() {
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <div className="home-top">Hi, Mukundh</div>
                <div><p className="explore-text ml-4">Follow your Favourite Reviewer</p>
                </div>
                <div className="main">
                    <ScrollMenu>
                        {AuthorsJson.reviewers.map((data, id) => {
                            return (
                                <div className="scroll-authors">
                                    <Col xs={3}><img alt="" src={data.image} className="favauthor" />
                                        <p className="text ml-2">{data.name}</p></Col>
                                </div>
                            )
                        })}
                    </ScrollMenu>
                </div>
                <center className="mt-4">
                    <Row style={{ width: "100%" }}>
                        {AuthorsJson.reviewers.map((data, id) => {
                            return (
                                <Col xs={4}>
                                    <Link to="/authordetails"><img alt="" src={data.image} className="favauthor" />
                                        <p className="text">{data.name}</p></Link></Col>
                            )
                        })}
                    </Row>
                </center>
                <BottomMenu />
            </div >
        )
    }
}
