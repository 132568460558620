import React, { Component } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import './Interests.css';
import { config } from '../Config/Config';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import StarRatings from 'react-star-ratings';
import 'react-circular-progressbar/dist/styles.css';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default class Sceemlore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sceemlore: [],
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            id: "",
            cardTitle: "",
            cardDescription: "",
            cardNumber: "",
            cardPicture: "",
            isLoading: true,
            redirect: false,
            isRatingAdded: false
        }
        this.goBack = this.goBack.bind(this)
    }
    goBack = () => {
        window.history.back();
    }
    componentDidMount() {
        this.getSceemloreDetail();
    }
    getSceemloreDetail = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getSceemloreDetail', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    self.setState({
                        sceemlore: response.data.getSceemloreDetail,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getSceemloreByUser = (id, val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        if (val == null) {
            val = 0
        } else {
            val = val
        }
        var payload = {
            "cardManagementId": id,
            "offset": parseInt(val)
        }
      //  console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/getSceemloreByUser', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    self.setState({
                        redirect: true,
                        id: id,
                        cardTitle: response.data.sceemloreDetail[0].cardTitle,
                        cardDescription: response.data.sceemloreDetail[0].cardDescription,
                        cardPicture: response.data.sceemloreDetail[0].cardPicture,
                        cardNumber: response.data.sceemloreDetail[0].cardNumber,
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    addRating = (data) => {
        this.setState({ isRatingAdded: true, id: data.id,cardNumber:data.cards })
    }
    render() {
        const { sceemlore, isLoading, isRatingAdded, redirect } = this.state;

        if (isLoading) {
           // console.debug("renders: Loading...");
            return <div className="App"></div>;
        }
        if (redirect) {
          //  console.log("redirect1", this.state.redirect);
            return <Redirect
                to={{
                    pathname: `/eachcard/${this.state.id}`,
                    state: { id: this.state.id, cardTitle: this.state.cardTitle, cardDescription: this.state.cardDescription, cardNumber: this.state.cardNumber, cardPicture: this.state.cardPicture }
                }}
            />
        }
        if (isRatingAdded) {
         //   console.log("redirect1", this.state.redirect);
            return <Redirect
                to={{
                    pathname: `/eachcard/${this.state.id}`,
                    state: { id: this.state.id, isRatingAdded: true,cardNumber:this.state.cardNumber }
                }}
            />
        }
        const percent = sceemlore.map((i, idx) => {
            var a = i.cardView;
            var b = i.cards;
            var c = ((a / b) * 100);
            return c;
        })
        var a = 0;
        const sceemloreCount = sceemlore.map(i => i.userId !== null)
        const count = sceemloreCount.filter(Boolean).length;

        // console.log("sceem",sceemloreCount,count)
        return (
            <div >
                <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                <div className="screen-topgap">
                    <div className="your-jitbits">Sceemlore</div>
                    <div className="mr-3 ml-3" style={{ marginTop: "-10px" }}>
                        <div>Published: {sceemlore.length}</div>
                        <div>Registered: {count}</div>
                    </div>
                    <div className="mt-2 ml-3 mr-3 top-jit" >
                        {this.state.sceemlore.length !== 0 ?
                            <div>
                                {this.state.sceemlore.map((data, i) => (
                                    <Card style={{ display: "flex" }} className={data.isValid == null ?"sceemlore-card":"sceemlore-card-topic"}>
                                        <Row style={{ width: "100%" }} className="no-gutters">
                                            <Col xs={12}>
                                                <div className="ml-2">
                                                    <div className="favbits-title-new" style={{ width: "80%" }}>{data.title}</div>
                                                    <div style={{ display: "flex" }}>
                                                        {(data.cards == data.cardView && data.rating == null) ? <div className="s-cards">{data.cards} Cards Completed</div> :
                                                            ((data.cards > data.cardView && data.cardView != null) ? <div className="s-cards">{data.cardView} out of {data.cards} Cards Completed</div> :
                                                                (data.cards == data.cardView && data.rating >= 1) ?
                                                                    <div className="s-cards">{data.cards} Cards Completed</div> :
                                                                    <div className="s-cards">{data.cards} Cards</div>)}
                                                        <div style={{ position: "absolute", right: "5px", top: "2px", width: 30, height: 30 }}>
                                                            <CircularProgressbar
                                                                value={percent[i]}
                                                                strokeWidth={50}
                                                                styles={buildStyles({
                                                                    strokeLinecap: "butt",
                                                                    pathColor: '#31197C',
                                                                })}
                                                            /></div>
                                                    </div>
                                                    <div className="s-cards" style={{ display: "flex" }}>{data.learners} Learners
                                                    </div>
                                                    <div style={{ marginTop: "-8px" }}>
                                                        <StarRatings
                                                            rating={data.avgrating == null ? 0 : parseFloat(data.avgrating)}
                                                            starRatedColor="#FF942C"
                                                            numberOfStars={5}
                                                            starDimension="20px"
                                                            starSpacing="0"
                                                        />
                                                        {(data.cards == data.cardView && data.rating != null) ? <Link to={{ pathname: `/reviewcard/${data.id}` }}><Button variant="default" className="mb-2 details-btn">Review</Button></Link> :
                                                            ((data.cards > data.cardView && data.userId !== null) ? <Button variant="default" onClick={() => this.getSceemloreByUser(data.id, data.cardView)} className="mb-2 details-btn">Continue</Button> :
                                                                ((data.cards == data.cardView && data.rating == null) ? <Button variant="default" onClick={() => this.addRating(data)} className="mb-2 details-btn">Add Rating</Button> :
                                                                    <Link to={{ pathname: `/sceemlore/${data.id}` }}><Button variant="default" className="mb-2 details-btn">Details</Button></Link>))}</div>
                                                </div>
                                                <div className="ml-2 mb-2"> <img alt="" src={"../" + data.topicName.replace(/ *\([^]*\) */g, "") + ".png"} style={{width:"25px"}} /></div>
                                            </Col>
                                        </Row>
                                    </Card>
                                ))}
                            </div> : <center className="mt-5">No Sceemlore to display</center>}
                    </div>
                </div >
                <div className="mb-bottom"></div>
                <BottomMenu />
            </div >
        )
    }
}
{/* <div> <img alt="" src={"../" + data.topicName.replace(/ *\([^]*\) *
/g,"") + ".png"}
 className="jitbits-img-topicIcon" /></div> */}