import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, NavLink, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import './RecommendedAuthors.css';
import axios from 'axios';
import { config } from '../Config/Config';
import back from '../images/backarrow.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class InvitedGroupMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            paidMembers: [],
            authorIds: [],
            paidMember: false,
            userFollowedAuthors: [],
            isLoading: true,
            isFirstTime: false,
            redirect: false,
            toggle: false,
            isScreenLoading: true
        }
        this.onAddingItem = this.onAddingItem.bind(this)
    }
    handleBack = () => {
        this.props.history.goBack()
    }
    onAddingItem = (e, value) => {
        const { paidMembers } = this.state
        //console.log("item", value)
        const updated = paidMembers.map(data => (data.uuid === value) ?
            { ...data, isAdded: !data.isAdded } : data)
       // console.log("update", updated)
        this.setState({ paidMembers: updated, isLoading: false })
    }
    componentDidMount = () => {
        this.getInvitedGroupMembers();
    }

    // checkUserStatus = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + this.state.token,
    //         "applicationType": "mobile"
    //     }
    //     var self = this
    //     axios.get(config.userUrl + 'user/checkUserStatus', { headers: headers })
    //         .then(function (response) {
    //             if (response.data.status === true) {
    //                 if (response.data.userStatus === "topicsAdded") {
    //                     self.setState({ isFirstTime: true })
    //                 } else {
    //                     self.setState({ isFirstTime: false })
    //                 }
    //             }
    //             else {
    //                 console.log("checkerror", response.data.message)
    //                 document.getElementById('status').innerText = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("error");
    //         });
    // };

    getInvitedGroupMembers = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getInvitedGroupMembers', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        paidMembers: response.data.invitedUsersArr,
                        isScreenLoading: false
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    inviteMembersToGroup = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        const { paidMembers } = this.state;
        let invitedUserIds = [];
        invitedUserIds = paidMembers.filter(item => (item.isAdded === true) ? item.uuid : null)
       // console.log("authorId", invitedUserIds)
        var payload = {
            "invitedUserIds": invitedUserIds.map(item => item.uuid),
        }
        var self = this;
       // console.log("payload", payload)
        // axios.post(config.userUrl + 'user/inviteMembersToGroup', payload, { headers: headers })
        //     .then(function (response) {
        //         console.log(response);
        //         if (response.data.status === true) {
        //             console.log("success", payload);
        //             self.setState({
        //                 redirect: true
        //             })
        //         }
        //     })
        //     .catch(function (error) {
        //         console.log("Error", error);
        //     });
    };
    handleToggle = () => {
        this.setState({ toggle: !this.state.toggle });
    }
    render() {
        const { redirect, userFollowedAuthors, isFirstTime, paidMembers, isScreenLoading, isLoading } = this.state
       // console.log("followed", userFollowedAuthors)
        if (isScreenLoading) {
           // console.debug("renders: Loading...");
            return <div className="App"></div>;
        }

        let authorId = [];
        authorId = paidMembers.filter(item => (item.isAdded === true) ? item.uuid : null)

        function checkUserFollowedAuthors(checkItem) {
            if (!isFirstTime) {
                return (userFollowedAuthors.filter(item => item.uuid == checkItem.uuid).length != 0)
            }
        }
        if (redirect) {
            return <Redirect to="/home" />
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
                <div style={{ marginTop: "4rem" }}>
                </div>
                <div className="recommended-fixed">
                    <div style={{ marginTop: "13px", display: "flex", marginLeft: "15px" }}>
                        <NavLink to="/group/invitegroup" activeClassName="profile-active" className="ml-2 link" >
                            <div className="followers-Text_ ml-1 mr-1">Invite Group Members </div></NavLink>
                        {isFirstTime ? null :
                            <NavLink to="/group/invited" activeClassName="profile-active" className="rec-left1 link" >
                                <div className="followers-Text_ ml-1 mr-1">Sent Invitations</div></NavLink>}

                    </div>
                </div><br />

                {paidMembers.length !== 0 ?
                    <div>
                        {paidMembers.map((data, i) => (
                            <div>
                                <Row style={{ width: "100%", marginRight: "2px", marginLeft: "10px" }}>
                                    <Col xs={3}>
                                        <img alt="" src={config.userUrl + data.profilePicture} className="ml-2 rec-profile" />
                                    </Col>
                                    <Col xs={5}>
                                        <div className="rec-name">{data.name}</div>
                                    </Col>
                                    <Col xs={1}></Col>
                                    {/* <Col xs={3} key={i} 
                                        onClick={(e) => this.setState({paidMember:!this.state.paidMember})}>{this.state.paidMember ?
                                            <i class="fa fa-plus-circle fa-2x plus" aria-hidden="true"></i> :
                                            <i class="fa fa-minus-circle fa-2x plus" aria-hidden="true"></i>}
                                    </Col> */}
                                    <Col xs={3} key={i} value={data.uuid}
                                        onClick={(e) => this.onAddingItem(e, data.uuid)}>{data.isAdded ?
                                            <i class="fa fa-minus-circle fa-2x plus" aria-hidden="true"></i> :
                                            <i class="fa fa-plus-circle fa-2x plus" aria-hidden="true"></i>}
                                    </Col>
                                </Row><hr className="ml-3 mr-3" />
                            </div>

                        ))}
                        {isFirstTime ?
                            <Button variant="default" onClick={() => this.inviteMembersToGroup(true)} className="landing-btn float">Next</Button>
                            :
                            <div>
                                <Button variant="default" onClick={() => this.inviteMembersToGroup(false)} className="landing-btn float">Save</Button>
                                <BottomMenu />
                            </div>
                        }
                    </div> :
                    <div><center className="mt-4">No Recommended paidMembers</center>
                        <BottomMenu />
                    </div>}
            </div>
        )
    }
}

{/* <div className="recommended-fixed">
<div style={{ marginTop: "13px", display: "flex", marginLeft: "15px" }}>
    <NavLink to="/paidMembers/recommended" activeClassName="profile-active" className="ml-3 link" >
        <div className="followers-Text ml-1 mr-1">Invite Group Members</div></NavLink>
    <NavLink to="/paidMembers/following" activeClassName="profile-active" className="rec-left1 link" >
        <div className="followers-Text ml-1 mr-1">Sent Invitations</div></NavLink>
    <label className="float-right" style={{position:"absolute",right:"-2px"}}>
        <input ref="switch1" checked={this.state.toggle} onChange={this.handleToggle} className="switch1" type="checkbox" />
        <div>
            <div></div>
        </div>
    </label>
    {isFirstTime ? null :
        <NavLink to="/paidMembers
/following" activeClassName="profile-active" className="rec-left link" >
            <div className="followers-Text ml-1 mr-1">Following</div></NavLink>}

</div>
</div><br /> */}