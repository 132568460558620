import { React, Component } from 'react';
import { Button, Row, Col, Form, Card } from "react-bootstrap";
import './Interests.css';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Report extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            articleId: this.props.data.match.params.id,
            typeOfReport: ""
        }
        this.onChange = this.onChange.bind(this)
        this.createReport = this.createReport.bind(this);
    }
    componentDidMount() {
      //  console.log("props34", this.props)
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    createReport = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "articleId": this.state.articleId,
            "typeOfReport": this.state.typeOfReport
        }
      //  console.log("payload", this.state.token, payload)
        axios.post(config.userUrl + "user/saveArticleReport", payload, { headers: headers })
            .then((response) => {
               // console.log(response);
                if (response.data.status === true) {
                 //   console.log("Report created successfully");
                  //  console.log("payload", payload)
                 //   console.log(response.data);
                } else {
                    console.log("Failed to create Report");
                }
            })
            .catch(function (error) {
                console.log("payload", payload,error)
            });
    }

    render() {
        return (
            <div>
                <div className='popup-box'>
                    <div className='_box'>
                        <span className="close-icon-cross" onClick={this.props.closePopup}> &times;</span>
                        <div>
                            <form>
                                <div >
                                    <center ><label><h4>Report</h4></label></center>
                                    <div className="form-group">
                                        <label>Type of Report</label>
                                        <Form.Check
                                            label="Unrelated"
                                            name="typeOfReport"
                                            type="radio"
                                            value="Unrelated"
                                            onChange={this.onChange}
                                        />
                                        <Form.Check
                                            label="Plagiarism"
                                            name="typeOfReport"
                                            type="radio"
                                            value="Plagiarism"
                                            onChange={this.onChange}
                                        />
                                        <Form.Check
                                            label="Unformatted"
                                            name="typeOfReport"
                                            type="radio"
                                            value="Unformatted"
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    {this.state.typeOfReport !== "" ?
                                        <center><Button variant="default" className="_rolebtn" onClick={this.createReport}>Submit</Button></center> :
                                        <center><Button variant="default" disabled="true" className="_rolebtn" onClick={this.createReport}>Submit</Button></center>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}
