import React, { Component } from "react";
import { Button, Container, Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import rectangle from '../images/rectangle.png';
import './Interests.css';
import axios from 'axios';
import { config } from '../Config/Config';
import back from '../images/backarrow.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Interests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            allTopics: [],
            topicIds: [],
            userTopics: [],
            isLoading: true,
            isTopicsLoading: true,
            isFirstTime: false,
            msg: "",
            labelArr: [{
                label: "Industry 4.0", valueUpto: 4
            }],
            topicsArr: [
                {
                    label: "Industry 4.0",
                    options: [
                        {
                            value: 1, label: 'Emerging Tech (IoT, 5G, AIML, AR/VR, 3D Printing, Cloud, Blockchain)', icon: "../Emerging Tech.png", isAdded: false
                        },
                        {
                            value: 2, label: 'Workforce 4.0 (Drones, Cobots, Robots)', icon: "../Workforce 4.0.png", isAdded: false
                        },
                        {
                            value: 3, label: 'Security (Cybersecurity, Info security, Forensics, RFID, QR Code)', icon: "../Security.png", isAdded: false
                        },
                        {
                            value: 4, label: 'Finance trends (DeFi, FinTech, Payments, Credit Risk, Crowd Financing, Trade)', icon: "../Finance trends.png", isAdded: false
                        }

                    ]
                },
                {
                    label: "Go Green",
                    options: [
                        {
                            value: 5, label: 'Circular Supply', icon: "../Circular Supply.png", isAdded: false
                        },
                        {
                            value: 6, label: 'Circular Economy', icon: "../Circular Economy.png", isAdded: false
                        },
                        {
                            value: 7, label: 'Waste Disposal', icon: "../Waste disposal.png", isAdded: false
                        }
                    ]
                },
                {
                    label: "Leadership",
                    options: [
                        {
                            value: 8, label: 'Business Transform (M&A, Organisation Design, Strategic Sourcing)', icon: "../Business Transform.png", isAdded: false
                        },
                        {
                            value: 9, label: 'Models (Maturity, Integration, Lean, JIT, Kaizen)', icon: "../Models.png", isAdded: false
                        },
                        {
                            value: 10, label: 'Business Continuity (Risk Management, Disaster Management)', icon: "../Business Continuity.png", isAdded: false
                        }
                    ]
                },
                {
                    label: "Fulfilment",
                    options: [
                        {
                            value: 11, label: 'Logistics (Optimisation, Wearhouse)', icon: "../Logistics.png", isAdded: false
                        },
                        {
                            value: 12, label: 'Procurement (Indirect, Direct, Planning, Forecasting, Inventory)', icon: "../Procurement.png", isAdded: false
                        }
                    ]
                },
                {
                    label: "Legal",
                    options: [
                        {
                            value: 13, label: 'Regulations', icon: "../Regulations.png", isAdded: false
                        },
                        {
                            value: 14, label: 'Compliance', icon: "../Compliance.png", isAdded: false
                        }
                    ]
                },
                {
                    label: "Governance",
                    options: [
                        {
                            value: 15, label: 'Management Office (Contract, Vendor, Program)', icon: "../Management Office.png", isAdded: false
                        },
                        {
                            value: 16, label: 'Audit (Internal & External)', icon: "../Audit.png", isAdded: false
                        }
                    ]
                },
                {
                    label: "People",
                    options: [
                        {
                            value: 17, label: 'Growth Mindset (Learning & Development, Certification, Talent Globalisation)', icon: "../Growth Mindset.png", isAdded: false
                        },
                        {
                            value: 18, label: 'Workspace 4.0 (Safety, Security, WFH, Employee trends)', icon: "../Workspace 4.0.png", isAdded: false
                        }
                    ]
                },

                {
                    label: "Global Trends",
                    options: [
                        {
                            value: 19, label: 'Smart Governance (Smart Cities, e-Government, e-Procurement, e-Tender)', icon: "../Smart Governance.png", isAdded: false
                        },
                        {
                            value: 20, label: 'Sharing Economy (Assets, Information, Standards, Markets, Skills, Talent)', icon: "../Sharing Economy.png", isAdded: false
                        },
                        {
                            value: 21, label: 'NextGen (Supply Chain as Service (SCaaS), Agile Practices, Global Supply Chain)', icon: "../NextGen.png", isAdded: false
                        },
                        {
                            value: 22, label: 'Localisation (MSME, Cottage, Large-scale)', icon: "../Localisation.png", isAdded: false
                        }
                    ]
                },
                {
                    label: "Customer Centric",
                    options: [
                        {
                            value: 23, label: 'Last-mile delivery', icon: "../Last-mile delivery.png", isAdded: false
                        },
                        {
                            value: 24, label: 'Support resiliency', icon: "../Support resiliency.png", isAdded: false
                        },
                        {
                            value: 25, label: 'Flawless delivery', icon: "../Flawless delivery.png", isAdded: false
                        }
                    ]
                }
            ]
        }
        this.onAddingItem = this.onAddingItem.bind(this)
    }
    componentDidMount = () => {
        this.getAllTopics();
        this.getUserTopics();
    }
    onAddingItem = (e, item) => {
        const isChecked = e.target.checked;
        const value = e.target.value;
        const { allTopics } = this.state
      //  console.log("item", isChecked, value, item)
        const updated = allTopics.map(data => (data.id === item + 1) ?
            { ...data, isAdded: !data.isAdded } : data)
      //  console.log("update", updated)
        var topic = updated.filter(item => (item.isAdded === true) ? item.id : null)
        if (topic.length <= 5) {
            this.setState({ allTopics: updated, msg: "", isLoading: false })
        } else {
          //  console.log("select upto 5")
            this.setState({ msg: "Cannot select more than 5 topics" })
        }
    }
    // onAddingItem = (e, item) => {
    //     const isChecked = e.target.checked;
    //     const value = e.target.value;
    //     const { allTopics, topicsArr } = this.state
    //     console.log("item", isChecked, value, item)
    //     topicsArr.map((data, i) => {
    //         console.log("data", data)
    //         var found = data.options.findIndex((item => item.value == value))
    //         if (found !== -1) {
    //             topicsArr[i].options[found].isAdded = !topicsArr[i].options[found].isAdded
    //             console.log("opt", data.options)
    //         }
    //     })
    //     console.log("data1", topicsArr)
    //     // var topic = topicsArr.filter(item =>
    //     //     item.options.filter(data =>
    //     //         (data.isAdded === true) ? data.value : null))
    //     var count = 0;
    //     var topic = topicsArr.map(item =>
    //         item.options.filter(data =>
    //             (data.isAdded === true) ? count++ : count
    //         )
    //     )
    //     console.log("count", count)
    //     if (count <= 5) {
    //         this.setState({ topicsArr: topicsArr, msg: "", isLoading: false })
    //     } else {
    //         console.log("select upto 5")
    //         this.setState({ msg: "Cannot select more than 5 topics" })
    //     }
    // }

    getAllTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllTopics', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                    //console.log("getAllTopics");
                    self.setState({ allTopics: response.data.topics, isTopicsLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getUserTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getUserTopics', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                   // console.log("getUserTopics");
                    self.setState({
                        userTopics: response.data.topics,
                    })
                    if (self.state.userTopics.length === 0) {
                     //   console.log("FIRSTTIME USER")
                        self.setState({
                            isFirstTime: true
                        })
                    } else {
                        console.log("USER")
                    }
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    postTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
      //  console.log("dfghj", this.state.allTopics)
        const { allTopics, topicsArr } = this.state;
        let topic = [];
        topic = allTopics.filter(item => (item.isAdded === true) ? item.id : null)
       // console.log("topic", topic)
        var payload = {
            "topicIds": topic.map(topic => topic.id),
            'isFirstTime': this.state.isFirstTime
        }
       // console.log("topic", topic, payload)
        axios.post(config.userUrl + 'user/addTopics', payload, { headers: headers })
            .then(function (response) {
             //   console.log(response);
                if (response.data.status === true) {
                    //console.log("success", payload);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        const { isTopicsLoading, topicsArr, userTopics, isFirstTime, allTopics, isLoading } = this.state;
        // var topic = [];
        // var topic = topicsArr.filter(item =>
        //     item.options.filter(data => {
        //         return (data.isAdded === true) ? data.value : null
        //     })
        // )
        // console.log("aaaa", topic)
        // function checkTopic(checkItem) {
        //     return (userTopics.filter(item => item.id == checkItem.id).length != 0)
        // }
        // if (isLoading) {
        //     topicsArr.map(item =>
        //         item.options.forEach(data => {
        //             return checkTopic(data) ? data.isAdded === true : data.isAdded === false
        //         })
        //     )

        //     console.log("isLoading", topicsArr)
        //     // topicsArr.forEach(item => checkTopic(item) ? item.isAdded = true : item.isAdded = false)
        // }
        if (isTopicsLoading) {
            return <div>Loading</div>
        }
        function checkTopic(checkItem) {
            return (userTopics.filter(item => item.id == checkItem.id).length != 0)
        }
        if (isLoading) {
            allTopics.forEach(item => checkTopic(item) ? item.isAdded = true : item.isAdded = false)
        }

        return (
            // <div>
            //     <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
            //     <img alt="" src={rectangle} className="rectangle" />
            //     <div className="choose-text">Choose your interest,</div>
            //     <div className="ml-4 error">{this.state.msg}</div>
            //     <center>
            //         <div>
            //             <Container>
            //                 <Row style={{ width: "100%" }}>
            //                     {allTopics.map((topic, i) => (
            //                         <Col xs={4} >
            //                             <label className="mt-4">
            //                                 <input type="checkbox"
            //                                     value={topic.id.toString()}
            //                                     checked={allTopics[i].isAdded}
            //                                     onChange={(e) => this.onAddingItem(e, i)}
            //                                     className={allTopics[i].isAdded ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }}
            //                                 />
            //                                 <img alt="" src={topic.topicName.replace(/ *\([^]*\) */g, "") + ".png"} className="procurement ml-2" />
            //                                 <p className="text">{topic.topicName.replace(/ *\([^]*\) */g, "")}</p>
            //                             </label>
            //                         </Col>
            //                     ))}
            //                 </Row>
            //                 <div id="status"></div>
            //             </Container>
            //         </div>
            //     </center>
            //     <p className="landing-text-sm">You can always change your interests in the profile &gt;&gt;&nbsp;Interests</p>

            //     <div> {isFirstTime ?
            //         <Link to="/authors/recommended">
            //             <Button variant="default" onClick={this.postTopics} className="landing-btn float">Next</Button></Link>
            //         :
            //         <Link to="/home"><Button variant="default" onClick={this.postTopics} className="landing-btn float">Save</Button></Link>

            //     } </div>

            // </div >

            <div>
                <div className="top_interests_bar"><img alt="" src={loginlogo} className="login-logo mt-2" />
                    {/* <img alt="" src={rectangle} className="rectangle" /> */}
                    <div className="choose-text">Choose your interest,</div>
                    <div className="ml-4 error">{this.state.msg}</div>
                </div>
                <center style={{ marginTop: "7.6rem" }}>
                    <div className="mt-4" >
                        {topicsArr.map((topic, i) => (
                            <div><div>
                                <h6 style={{ marginTop: "0.5rem", marginLeft: "1.5rem", float: "left" }}><b>{topic.label}</b></h6>
                                <Container>
                                    <Row style={{ width: "100%" }}>
                                        {topic.options.map((item, id) => (
                                            <Col xs={3} >
                                                <label>
                                                    <input type="checkbox"
                                                        value={item.value}
                                                        checked={allTopics[item.value - 1].isAdded}
                                                        onChange={(e) => this.onAddingItem(e, item.value - 1)}
                                                        className="quiz_checkbox"
                                                    /><div class="single_quiz_card">
                                                        <img alt="" src={item.label.replace(/ *\([^]*\) */g, "") + ".png"} className="procurement" />
                                                        <p className="text-topic">{item.label.replace(/ *\([^]*\) */g, "")}</p>
                                                    </div>
                                                </label>
                                            </Col>
                                        ))}
                                    </Row>
                                </Container>
                            </div><hr style={{ marginTop: "-3px" }} /></div>
                        ))}

                    </div>
                </center>
                <p className="landing-text-sm">You can always change your interests in the profile &gt;&gt;&nbsp;Interests</p>

                <div> {isFirstTime ?
                    <Link to="/authors/recommended">
                        <Button variant="default" onClick={this.postTopics} className="landing-btn float">Next</Button></Link>
                    :
                    <Link to="/home"><Button variant="default" onClick={this.postTopics} className="landing-btn float">Save</Button></Link>

                } </div>

            </div >

        )

    }
}


// handleChange = (e) => {
//     const { checked, value } = e.target;
//     let { topicIds } = this.state;
//     if (checked) {
//         topicIds = [...topicIds, value];
//     } else {
//         topicIds = topicIds.filter(el => el !== value);
//     }
//     this.setState({ topicIds }, () => console.log(this.state.topicIds));
// }


{/* <Row style={{ width: "100%" }}>
                                {this.state.topics.map((data, i) => (
                                    <Col xs={4} >
                                        <label class="checkbox-checked">
                                            <input type="checkbox"
                                                key={i}
                                                value={data.interest}
                                                checked={this.state.topics[i].isAdded}
                                                onChange={this.onAddingItem}
                                                className={this.state.topics[i].isAdded ? "display-checkbox" : "hide-checkbox"}
                                                style={{ float: "right" }} /><img alt="" src={data.icon} className="procurement" />
                                            <p className="text mr-2">{data.interest}</p></label></Col>
                                ))}
                            </Row> */}