import React, { Component } from "react";
import { Card, Accordion } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import './NotificationBell.css';
import { FaqData } from './FaqData';
import axios from 'axios';
import { config } from '../Config/Config';
import { BsFillInfoCircleFill, BsFillPersonFill } from "react-icons/bs";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class NotificationBell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            notifications: [],
            announcements:[]
        }
    }
    componentDidMount() {
        this.getNotifications();
    }
    getNotifications = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getNotifications', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    //console.log("success");
                    self.setState({
                        notifications: response.data.notifications,
                        announcements:response.data.announcement
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });

    };
    render() {
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <div className="ml-4 mr-4" style={{ marginTop: "4.5rem" }}>
                    <div className="your-notification">Your Notifications</div>
                    <Accordion defaultActiveKey="0">{this.state.notifications.map((data, idx) =>
                        <Card>
                            <Accordion.Toggle as={Card.Header} style={{ color: "#31197C" }} eventKey={idx.toString()}>
                                <a style={{ color: "#31197C", textDecoration: "none" }}>{data.type === "Event Based" ? <BsFillInfoCircleFill size="20px" /> : <BsFillPersonFill size="20px" />} &nbsp; &nbsp;{data.title}</a> <span style={{ float: "right", color: "#31197C" }}><i className="fa fa-plus angleright" aria-hidden="true"></i></span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={idx.toString()}>
                                <Card.Body>{data.description}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )}
                    </Accordion>
                    <div className="mt-1 your-notification">Announcements</div>
                    {this.state.announcements.length==0?<div>No Announcements</div>:
                    <Accordion defaultActiveKey="0">{this.state.announcements.map((data, idx) =>
                        <Card>
                            <Accordion.Toggle as={Card.Header} style={{ color: "#31197C" }} eventKey={idx.toString()}>
                                <a style={{ color: "#31197C", textDecoration: "none" }}> <BsFillPersonFill size="20px" /> &nbsp; &nbsp;Announcement</a> <span style={{ float: "right", color: "#31197C" }}><i className="fa fa-plus angleright" aria-hidden="true"></i></span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={idx.toString()}>
                                <Card.Body>{data.announcement}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )}
                    </Accordion>
    }
                    {/* 
                    <Accordion defaultActiveKey="2" style={{ color: "black" }} className="faq-width">
                        <Accordion.Item eventKey="2" className="mt-3" style={{ border: "1px solid lightgrey" }}>
                            <Accordion.Header>
                                How can I create a CV in CVeeed?
                            </Accordion.Header>
                            <Accordion.Body eventKey="2" className="answer">
                                CVeeed has provided 50+ varities of templates to its users. You can choose anyone depending on role or responsibilities or domain. Also there are interesting tips provided by experts to help in creating an impactful CV and journey.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" className="mt-3" style={{ border: "1px solid lightgrey" }}>
                            <Accordion.Header>
                                What is the uniqueness in CVeeed?
                            </Accordion.Header>
                            <Accordion.Body eventKey="3" className="answer">
                                CVeeed provides a platform to create a brand value for oneself and we recognise that you are the brand ambassdor of your
                                career progression. Hence we emcompasses your jouney towards goals and achievements as an important factor to highlight
                                in your CV. This is a three dimentional approach covering persona, personality, performance.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion><br /> */}
                    <div className="mb-bottom"></div>
                </div>
                <BottomMenu />
            </div >
        )
    }
}

{/* <Accordion defaultActiveKey="1">{FaqData.map((data, idx) =>
                        <Card>
                            <Accordion.Toggle as={Card.Header} style={{ color: "#31197C" }} eventKey={data.number}>
                                <Link style={{ color: "#31197C", textDecoration: "none" }}>{data.title}</Link> <span style={{ color: "#4FD84F" }}><i className="fa fa-angle-right angleright" aria-hidden="true"></i></span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={data.number}>
                                <Card.Body>{data.type}<br />{data.description}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )}
                    </Accordion> */}