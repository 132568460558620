import React, { Component } from "react";
import { Button, Carousel, Row, Col } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import likes from '../images/sceem-likes.png';
import writearticle from '../images/sceem-writearticle.png';
import readarticle from '../images/sceem-readarticle.png';
import qna from '../images/QnA.svg';
import followers from '../images/sceem-followers.png';
import following from '../images/sceem-following.png';
import opportunity from '../images/opportunity.png';
import referrals from '../images/referral.svg';
import pollscreated from '../images/sceem-polls created.png';
import pollsparticipate from '../images/sceem-polls participate.png';
import months from '../images/sceem-months.png';
import smebooking from '../images/smebookings1.svg';
import smerating from '../images/smerating.svg';
import './Interests.css';
import moment from "moment-timezone";
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import Popup from "./Popup";
import SimpleCrypto from "simple-crypto-js";
import { useState } from "react";
import { useEffect } from "react";
import { AiOutlineShareAlt } from "react-icons/ai";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function ProfileScreen() {
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [name, setName] = useState("")
    const [posts, setPosts] = useState([])
    const [profilePicture, setProfilePicture] = useState("")
    const [role, setRole] = useState(localStorage.getItem('role'))
    const [userRole, setUserRole] = useState("")
    const [userId, setUserId] = useState()
    const [followerCount, setFollowerCount] = useState()
    const [followingCount, setFollowingCount] = useState()
    const [likesCount, setLikesCount] = useState()
    const [monthsActive, setMonthsActive] = useState()
    const [pollAnsweredCount, setPollAnsweredCount] = useState()
    const [pollCreatedCount, setPollCreatedCount] = useState()
    const [answersCount, setAnswersCount] = useState()
    const [opportunityCount, setOpportunityCount] = useState()
    const [questionsCount, setQuestionsCount] = useState()
    const [postCount, setPostCount] = useState()
    const [referralCount, setReferralCount] = useState()
    const [userArticleViews, setUserArticleViews] = useState()
    const [description, setDescription] = useState()
    const [author, setAuthor] = useState("")
    const [reviewer, setReviewer] = useState("")
    const [sme, setSme] = useState("")
    const [disable, setDisable] = useState(false)
    const [disableSme, setDisableSme] = useState(false)
    const [disableAuthor, setDisableAuthor] = useState(false)
    const [disableReviewer, setDisableReviewer] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [authorText, setAuthorText] = useState()
    const [roleAsAuthor, setRoleAsAuthor] = useState()
    const [reviewerText, setReviewerText] = useState()
    const [roleAsReviewer, setRoleAsReviewer] = useState()
    const [smeText, setSmeText] = useState()
    const [roleAsSme, setRoleAsSme] = useState()
    const [rewardPoints, setRewardPoints] = useState()
    const [showExpress, setShowExpress] = useState(false)
    const [showSME, setShowSME] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [userTopics, setUserTopics] = useState([])
    const [topics, setTopics] = useState([])
    const [membership, setMembership] = useState()
    const [section, setSection] = useState(0)
    const [createdAt, setCreatedAt] = useState()
    const [updatedAt, setUpdatedAt] = useState()
    const [email, setEmail] = useState()
    const [uniqueCode, setUniqueCode] = useState()
    const [totalSections, setTotalSections] = useState(4)
    const [smeName, setSmeName] = useState("")
    const [smeProfilePicture, setSmeProfilePicture] = useState("")
    const [linkedin, setLinkedin] = useState("")
    const [commercial, setCommercial] = useState("")
    const [rating, setRating] = useState("")
    const [bookings, setBookings] = useState("")
    const [smeTopics, setSmeTopics] = useState([])
    const [allOfferings, setAllOfferings] = useState([])
    const [selectedOfferings, setSelectedOfferings] = useState([]);
    const [smeDesc, setSmeDesc] = useState("")
    const [showSmeUser, setShowSmeUser] = useState(false)
    useEffect(() => {
        getUserProfile();
        checkUserStatus();
        getTopics()
        // getAllSmeOfferings();
        // setTotalSections(5)
        // getSmeUserData();
        if (localStorage.getItem('role') === 'sme') {
            getAllSmeOfferings();
        } else {
            setTotalSections(4)
        }
    }, [])
    useEffect(() => {
        let interval = setInterval(() => {
            let newSection = (section + 1) % totalSections
            setSection(newSection)
        }, 10000)
        return () => clearInterval(interval)
    }, [section])
    const getSmeUserData = (uId) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "userId": uId
        }
        axios.post(config.userUrl + 'user/getSmeUserData', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                    if (response.data.data.length == 0) {
                        setShowSmeUser(false)
                    } else {
                        // setSelectedDay(response.data.data[0]?.day)
                        // setSelectedTimeSlot(response.data.data[0]?.time)
                        // setDescriptionText(response.data.data[0].description)
                        // setSmeUserId(response.data.data[0].userId)
                        setShowSmeUser(true)
                        setTotalSections(5)
                        setRating(response.data.data[0].avg_assessment)
                        setCommercial(response.data.data[0].commercial)
                        setSmeProfilePicture(response.data.data[0].profilePicture)
                        setSmeName(response.data.data[0].name)
                        setBookings(response.data.data[0].bookings)
                        setSmeTopics(response.data.topics)
                        setSelectedOfferings(response.data.data[0].offering)
                        setSmeDesc(response.data.data[0]?.desc)
                        setLinkedin(response.data.data[0].linkedin)
                    }
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    setAllOfferings(response.data.offerings)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    // const optForReviewer = () => {
    //     document.getElementById('reviewer').style.display = 'flex'
    // }
    // const showReviewerExpressInterestNone = () => {
    //     document.getElementById('reviewer').style.display = 'none'
    // }
    // const optForAuthor = () => {
    //     document.getElementById('author').style.display = 'flex'
    // }
    // const showAuthorExpressInterestNone = () => {
    //     document.getElementById('author').style.display = 'none'
    // }
    // const postExpressInterest = (e, type) => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + token,
    //         "applicationType": "mobile"
    //     }
    //     var payload = {
    //         "interestType": type
    //     }
    //     axios.post(config.userUrl + 'user/expressInterest', payload, { headers: headers })
    //         .then(function (response) {
    //             if (response.data.status === true) {
    //                 checkUserStatus()
    //                 showReviewerExpressInterestNone()
    //                 showAuthorExpressInterestNone()
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error", error);
    //         });

    // };
    const checkUserStatus = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/checkUserStatus', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    if (response.data.userStatus === "approvedForAuthor") {
                        setAuthor("Approved")
                        setDisableReviewer(true)
                        setDisableSme(true)
                        document.getElementById('true').innerHTML = '<i class="fa fa-check" id="true" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "approvedForReviewer") {
                        setReviewer("Approved")
                        setDisableAuthor(true)
                        setDisableSme(true)
                        document.getElementById('true').innerHTML = '<i class="fa fa-check" id="true" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "approvedForSme") {
                        setSme("Approved")
                        setDisableReviewer(true)
                        setDisableAuthor(true)
                        document.getElementById('true').innerHTML = '<i class="fa fa-check" id="true" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "authorStatusPending") {
                        setAuthor("Pending")
                        setDisableReviewer(true)
                        setDisableSme(true)
                        document.getElementById('pendingAuth').innerHTML = '<i class="fa fa-check" id="pendingAuth" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "reviewerStatusPending") {
                        setReviewer("Pending")
                        setDisableAuthor(true)
                        setDisableSme(true)
                        document.getElementById('pendingAuth').innerHTML = '<i class="fa fa-check" id="pendingAuth" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "smeStatusPending") {
                        setSme("Pending")
                        setDisableReviewer(true)
                        setDisableAuthor(true)
                        document.getElementById('pendingAuth').innerHTML = '<i class="fa fa-check" id="pendingAuth" aria-hidden="true"></i>'
                    }
                    else {
                        document.getElementById('status').innerText = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                    }
                } else {
                   // console.log("checkerror", response.data.message)
                    document.getElementById('status').innerText = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getUserTopics', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setTopics(response.data.topics)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getUserProfile = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getProfile', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    let user = response.data;
                    setFollowerCount(user.followerCount)
                    setFollowingCount(user.followingCount)
                    setAnswersCount(user.totalAnswerCount)
                    setQuestionsCount(user.totalQuestionCount)
                    setLikesCount(user.likesCount)
                    setMonthsActive(user.monthsActive)
                    setPollAnsweredCount(user.pollAnsweredCount)
                    setPollCreatedCount(user.pollCreatedCount)
                    setReferralCount(user.referralCount)
                    setOpportunityCount(user.userOpportunityCount)
                    setPostCount(user.postCount)
                    setUserArticleViews(user.userArticleViews)
                    setDescription(user.user.description)
                    setMembership(user.user.merchantType)
                    setName(user.user.name)
                    setEmail(user.user.email)
                    setUserId(user.user.uuid)
                    setUniqueCode(user.user.uniquecode)
                    setCreatedAt(user.user.createdAt)
                    setUpdatedAt(user.user.updatedAt)
                    setRewardPoints(Number(localStorage.getItem("rewards")))
                    localStorage.setItem("planType", user.user.merchantType)
                    if (localStorage.getItem('role') === 'sme') {
                        getAllSmeOfferings();
                        getSmeUserData(user.user.uuid);
                    }
                    if (role === "generalUser") {
                        setUserRole("User")
                    } else {
                        const data = role.charAt(0).toUpperCase() + role.slice(1)
                        setUserRole(data)
                    }
                    if ((user.user.profilePicture === null) && (user.user.gender === "0")) {
                        setProfilePicture("uploads/1652942537335Avatar%20Users2_37.png")
                    } else if ((user.user.profilePicture === null) && (user.user.gender === "1")) {
                        setProfilePicture("uploads/1652942450250Avatar Users2_31.png")
                    } else {
                        setProfilePicture(user.user.profilePicture)
                    }
                    setIsLoading(false)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    const getUserTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllRoleRequests', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setUserTopics(response.data.allRequest)
                    setIsLoading(false)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const togglePopup = () => {
        if (author === "Approved") {
            setShowPopup(!showPopup)
            setRoleAsAuthor("Author")
            setAuthorText(" Write articles with your experience and knowledge. It will be reviewed by expert reviewers before publication.");
        } else if (reviewer === "Approved") {
            setShowPopup(!showPopup)
            setRoleAsReviewer("Reviewer")
            setReviewerText("Review articles with your knowledge and expertise written by leading authors.");
        } else if (sme === "Approved") {
            setShowPopup(!showPopup)
            setRoleAsSme("SME")
            setSmeText("Answer questions with your knowledge.");
        }
        else {
            setShowPopup(!showPopup)
            setRoleAsAuthor("Author")
            setAuthorText(" Write articles with your experience and knowledge. It will be reviewed by expert reviewers before publication.")
            setRoleAsReviewer("Reviewer")
            setReviewerText("Review articles with your knowledge and expertise written by leading authors.")
            setRoleAsSme("SME")
            setSmeText("Answer questions with your experience and knowledge.")
        }
       // console.log("togglePopup")
    }
    const toggleInterest = (data) => {
        if (data === "Author") {
            setShowExpress(true)
            setRoleAsAuthor(data)
            setAuthorText("As an author, contribute to sceem community through conceptualisation, analysis, interpretation of data, in the topic of interest in the form of article. Drafting of the article is based on individual expertise and intellectual content. <br/><br/> Agreement to be accountable for all aspects of the work in the article in ensuring accuracy or integrity of any part of the work are appropriately addressed. Content of article has no commercial or promotional objective.");
        } else if (data === "Reviewer") {
            setShowExpress(true)
            setRoleAsAuthor(data)
            setAuthorText("As a reviewer, contribute to sceem community through reviewing article concept, analysis, interpretation of data based on individual subject matter expertise. Provide constructive and objective feedback while approving or rejecting the article. <br/><br/> Agreement to be accountable for all aspects of the work in the review of article and seek clarifications if necessary related to the accuracy or integrity of any part of the work.");
        } else {
            // setShowExpress(true)
            setShowSME(true)
            setRoleAsAuthor(data)
            setAuthorText("As a SME, contribute to sceem community through answering the questions raised by users. Provide constructive answers while answering the questions. <br /><br /> Agreement to be accountable for all aspects of the work in the answering the questions and seek clarifications if necessary related to the accuracy or integrity of any part of the work.");
        }
        //console.log("roleee", data)
    }

    const kFormatter = (num) => {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num)
    }
    // if (isLoading) {
    //     return <center>Loading</center>
    // }
    if (showPopup) {
        return <Popup closePopup={togglePopup} authorText={authorText} reviewerText={reviewerText} smeText={smeText} roleAsAuthor={roleAsAuthor} roleAsReviewer={roleAsReviewer} roleAsSme={roleAsSme} />
    }
    if (showExpress) {
        return <Redirect
            to={{
                pathname: "/express",
                state: { roleAsAuthor: roleAsAuthor, authorText: authorText }
            }} />
    }
    if (showSME) {
        return <Redirect
            to={{
                pathname: "/smeregister",
                state: { roleAsAuthor: roleAsAuthor, authorText: authorText }
            }} />
    }
    const shareProfile = (code) => {
        // var id = simpleCrypto.encrypt(userId);
        if (navigator.share) {
            navigator.share({
                url: `https://www.sceem.org/profile/${code}`,
            }).then(() => {
                //console.log('Thanks for sharing!');
            }).catch(err => {
                //console.log("Error while using Web share API:");
                console.log(err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
    };
    const onClickUrl = () => {
        window.open(linkedin)
    }
    return (
        <div>
            <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
            <div className="screen-topgap">
                <div style={{ display: "flex", justifyContent: "space-between" }}><div className="your-jitbits">Profile</div>
                    <div className="mr-3"><AiOutlineShareAlt size="25px" onClick={() => shareProfile(uniqueCode)} className="share-icon" /></div></div>
                <div class="bar">
                    <div class="in"></div>
                </div>
                <div className="ml-3 mr-3 sec-display">
                    <div className={`sec-color`} onClick={() => setSection(0)}><div className={`${section === 0 ? "bg-color" : ""}`}></div></div>
                    <div className={`sec-color`} onClick={() => setSection(1)}><div className={`${section === 1 ? "bg-color" : ""}`}></div></div>
                    <div className={`sec-color`} onClick={() => setSection(2)}><div className={`${section === 2 ? "bg-color" : ""}`}></div></div>
                    <div className={`sec-color`} onClick={() => setSection(3)}><div className={`${section === 3 ? "bg-color" : ""}`}></div></div>
                    {/* <div className={`sec-color`} onClick={() => setSection(4)}><div className={`${section === 4 ? "bg-color" : ""}`}></div></div> */}
                    {showSmeUser ? <div className={`sec-color`} onClick={() => setSection(4)}><div className={`${section === 4 ? "bg-color" : ""}`}></div></div> : ""}
                </div>
                <div className="mt-3 ml-3 self-text">   {section === 0 ? "Yourself" : section === 1 ? "Activities" : section === 2 ? "Contribution" : section === 4 ? "SME Profile" : "Overall"}</div>
                <div>
                    {section === 0 ?
                        <div className="mt-2 ml-3 mr-3" style={{ border: "1px solid #31197c", borderRadius: "10px" }}>
                            <center className="mt-4"><img alt="" src={config.userUrl + profilePicture} className="profile-Auth-img" /></center><Link to="/favauthor" className="edit-icon-mob"><i class="ml-3 fa fa-pencil" aria-hidden="true"></i></Link>
                            <center>
                                <div className="mt-1 user-Name">{name} <span className="author-text mb-2" style={{ color: "#31197C" }}>({userRole}, {membership == 1 ? "Anticipator" : (membership == 2 ? "Integrator" : (membership == 3 ? "Collaborator" : "Orchestrator"))})</span></div>
                                <div className="mt-1 author-text ml-5 mr-5" style={{ color: "#31197C" }}>{description}</div>
                                <div className="mt-2 rewards-earned">Reward Points Earned: <span style={{ color: "#31197C" }}>{rewardPoints}</span></div>
                            </center>
                            <div className="mt-2 ml-3 mr-3" style={{ fontSize: "12px" }}><span style={{ fontSize: "14px", fontWeight: 600 }}>Interests: </span><br />{topics.map((data, i) => <span>{data.topicName.replace(/ *\([^]*\) */g, "")}, </span>)}</div>
                            <div className="mt-2 j-date mb-2">
                                <div><b>Joined On:</b> {moment(createdAt).format('DD-MM-YYYY')}</div>
                                <div><b>Last Login:</b> {moment(updatedAt).format('DD-MM-YYYY')}</div>
                            </div>


                        </div>
                        : section === 1 ?
                            <div className="mt-3 ml-3 mr-3">
                                <div className="profile_Card_">
                                    <div><img src={readarticle} style={{ width: "28px" }} /> &nbsp;Article Reads</div>
                                    <div>{kFormatter(userArticleViews)}</div>
                                </div>
                                <div className="profile_Card_">
                                    <div><img src={followers} style={{ width: "28px" }} /> &nbsp;Followers</div>
                                    <div>{followerCount}</div>
                                </div>
                                <div className="profile_Card_">
                                    <div><img src={following} style={{ width: "28px" }} /> &nbsp;Following</div>
                                    <div>{followingCount}</div>
                                </div>
                                <div className="profile_Card_">
                                    <div><img src={pollsparticipate} style={{ width: "28px" }} /> &nbsp;Poll Answered</div>
                                    <div>{pollAnsweredCount}</div>
                                </div>
                                <div className="profile_Card_">
                                    <div><img src={qna} style={{ width: "28px" }} /> &nbsp;Questions</div>
                                    <div>{questionsCount}</div>
                                </div>
                                <div className="profile_Card_">
                                    <div><img src={months} style={{ width: "28px" }} /> &nbsp;Months Active</div>
                                    <div>{monthsActive}</div>
                                </div><br /><br /><br />
                            </div>
                            : section === 2 ?
                                <div className="mt-3 ml-3 mr-3">
                                    <div className="profile_Card_">
                                        <div><img src={qna} style={{ width: "28px" }} /> &nbsp;Answers</div>
                                        <div>{answersCount}</div>
                                    </div>
                                    <div className="profile_Card_">
                                        <div><img src={likes} style={{ width: "28px" }} /> &nbsp;Likes</div>
                                        <div>{likesCount}</div>
                                    </div>
                                    <div className="profile_Card_">
                                        <div><img src={opportunity} style={{ width: "28px" }} /> &nbsp;Opportunities</div>
                                        <div>{opportunityCount}</div>
                                    </div>
                                    <div className="profile_Card_">
                                        <div><img src={writearticle} style={{ width: "28px" }} /> &nbsp;Articles Posted</div>
                                        <div>{postCount}</div>
                                    </div>
                                    <div className="profile_Card_">
                                        <div><img src={pollscreated} style={{ width: "28px" }} /> &nbsp;Polls Created</div>
                                        <div>{pollCreatedCount}</div>
                                    </div>
                                    <div className="profile_Card_">
                                        <div><img src={referrals} style={{ width: "28px" }} /> &nbsp;Referrals</div>
                                        <div>{referralCount}</div>
                                    </div>
                                    <br /><br /><br />
                                </div>
                                : section === 4 ?
                                    <div className="mt-3 ml-2 mr-2">
                                        <div style={{ height: "46vh", border: "1px solid #31197c", borderRadius: "10px" }}>
                                            <div className="m-2">
                                                <div className="profile_Card_sme-topics">
                                                    <div><img src={smebooking} style={{ width: "23px" }} /> &nbsp;Appointments</div>
                                                    <div>{bookings}</div>
                                                </div>
                                                <div className="profile_Card_sme-topics">
                                                    <div><img src={smerating} style={{ width: "23px" }} /> &nbsp;Assessment</div>
                                                    <div>{rating}</div>
                                                </div>
                                                <div style={{ fontSize: "13px", fontWeight: 600 }}>Expertise</div>
                                                <div className="profile_Card_sme-topics" style={{ marginTop:"-1.5px",display: "flex" }}>
                                                    {smeTopics.map((data, i) => <div style={{ fontSize: "12px" }} className="mb-1">{data.topicName} </div>)}
                                                    {/* <Row style={{ width: "100%" }}>
                                                        {smeTopics.map((data, i) => (
                                                            <Col> <div style={{marginTop:"-5px", fontSize: "12px", lineHeight: "14px" }}>{data.topicName}</div>
                                                            </Col>
                                                        ))}
                                                    </Row> */}
                                                </div>
                                                <div style={{ fontSize: "13px", fontWeight: 600 }}>Offerings</div>
                                                <div className="profile_Card_sme-topics" style={{marginTop:"-1.5px", display: "flex", justifyContent: "space-around" }}>
                                                    {allOfferings.filter(data => selectedOfferings.includes(data.id)).map((data, i) => (
                                                        <div style={{ fontSize: "12px" }} className="mb-1">{data.text}</div>
                                                    ))}
                                                </div> <div className="mt-1 ml-1 mr-1" style={{ fontSize: "12px" }}><span style={{ fontSize: "13px", fontWeight: 600 }}>About Me: </span><br />
                                                    <div className="smeDescText">{smeDesc}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <br /><br /><br />
                                    </div>
                                    :
                                    <center className="mt-3">
                                        <div className="outer-circle-lg">
                                            <div className="outer-circle">
                                                <div className="readArticles"><img src={readarticle} style={{ width: "28px" }} /><br />{kFormatter(userArticleViews)}</div>
                                                <div className="followerCount"><img src={followers} style={{ width: "28px" }} /><br />{followerCount}</div>
                                                <div className="followingCount"><img src={following} style={{ width: "28px" }} /><br />{followingCount}</div>
                                                <div className="pollAnsweredCount"><img src={pollsparticipate} style={{ width: "28px" }} /><br />{pollAnsweredCount}</div>
                                                <div className="questionsCount"><img src={qna} style={{ width: "28px" }} /><br />{questionsCount}</div>
                                                <div className="monthsActive"><img src={months} style={{ width: "28px" }} /><br />{monthsActive}</div>
                                            </div>
                                            <div className="inner-circle">
                                                <img alt="" src={config.userUrl + profilePicture} className="img-userprof" />
                                                <div className="answersCount"><img src={qna} style={{ width: "28px" }} /><br />{answersCount}</div>
                                                <div className="likesCount"><img src={likes} style={{ width: "28px" }} /><br />{likesCount}</div>
                                                <div className="opportunitiesCount"><img src={opportunity} style={{ width: "28px" }} /><br />{opportunityCount}</div>
                                                <div className="postCount"><img src={writearticle} style={{ width: "28px" }} /><br />{postCount}</div>
                                                <div className="pollCreatedCount"><img src={pollscreated} style={{ width: "28px" }} /><br />{pollCreatedCount}</div>
                                                <div className="referralCount"><img src={referrals} style={{ width: "28px" }} /><br />{referralCount}</div>
                                            </div>
                                        </div>
                                        <br /><br />
                                    </center>
                    }
                    {localStorage.getItem("planType") == 1 ? <div></div> :
                        <div className="ei">
                            <center className="mt-1">Express interest<br />
                                <div style={{ marginTop: "-10px" }}>
                                    <Button variant="default" onClick={() => toggleInterest("Author")} disabled={disableAuthor} className="mt-3 _rolebtn">Author <span id={author === "Pending" ? "pendingAuth" : (author === "Approved" ? "true" : "")} className={author === "Approved" ? "showPassword tick-color-green" : (author === "Pending" ? "showPassword tick-color-amber" : "")}
                                    ></span></Button>
                                    &nbsp;&nbsp;
                                    <Button variant="default" onClick={() => toggleInterest("Reviewer")} disabled={disableReviewer} className="mt-3 _rolebtn" >Reviewer <span id={reviewer === "Pending" ? "pendingAuth" : (reviewer === "Approved" ? "true" : "")} className={reviewer === "Approved" ? "showPassword tick-color-green" : (reviewer === "Pending" ? "showPassword tick-color-amber" : "")}
                                    ></span></Button> &nbsp;
                                    <Button variant="default" onClick={() => toggleInterest("SME")} disabled={disableSme} className="mt-3 _rolebtn" >SME <span id={sme === "Pending" ? "pendingAuth" : (sme === "Approved" ? "true" : "")} className={sme === "Approved" ? "showPassword tick-color-green" : (sme === "Pending" ? "showPassword tick-color-amber" : "")}
                                    ></span></Button></div>
                                <div className="mt-2 to-know-more">To Know more <span onClick={togglePopup} className="click">Click here</span></div>
                            </center>
                        </div>
                    }
                </div>
                <br /><br /><br />

            </div>
            <BottomMenu />
        </div >
    )
}


