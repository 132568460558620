// import React, { useEffect, useState } from "react";
// import { Button, Card, Col, Row, Modal } from "react-bootstrap";
// import { Link, useLocation, Redirect } from 'react-router-dom';
// import loginlogo from '../images/login-logo.png';
// import BottomMenu from "../BottomMenu/BottomMenu";
// import axios from 'axios';
// import { config } from '../Config/Config';
// import { enqueueSnackbar } from 'notistack'
// import article from '../images/article-new.jpeg';
// import moment from 'moment-timezone';
// import smefullday from '../images/smefullday.svg';
// import smemorning from '../images/smemorning.svg';
// import smeafternoon from '../images/smeafternoon.svg';
// import smebookings from '../images/smebookings.svg';
// import smerating from '../images/smerating.svg';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import SimpleCrypto from "simple-crypto-js";
// var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
// var simpleCrypto = new SimpleCrypto(key)


// export default function UpdateSmeProfile() {
//     const [desc, setDesc] = useState('')
//     const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
//     const [selectedDay, setSelectedDay] = useState('');
//     const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
//     const [allDays, setAllDays] = useState([]);
//     const [allTimeSlots, setAllTimeSlots] = useState([]);
//     const [allOfferings, setAllOfferings] = useState([]);
//     const [selectedOfferings, setSelectedOfferings] = useState([]);
//     const [bookings, setBookings] = useState();
//     const [rating, setRating] = useState();
//     const [profilePicture, setProfilePicture] = useState();
//     const [name, setName] = useState();
//     const [smeUserId, setSmeUserId] = useState();
//     const [showConnect, setShowConnect] = useState(false)
//     const [showHome, setShowHome] = useState(false)
//     const [topics, setTopics] = useState([])
//     const [linkedin, setLinkedin] = useState()
//     const [showNoData, setShowNoData] = useState(false)

//     const handleConnect = (date) => {
//         setShowConnect(true)
//     }

//     useEffect(() => {
//         getSmeUserData();
//         getAllSmeOfferings();
//         getAllDays();
//         getAllTimeSlots();
//     }, [])

//     const getSmeUserData = () => {
//         let headers = {
//             "Content-Type": 'application/json',
//             "Authorization": "Bearer " + token,
//             "applicationType": "mobile"
//         }
//         axios.get(config.userUrl + 'user/getSmeUserDataById', { headers: headers })
//             .then(function (response) {
//                 console.log(response);
//                 if (response.data.status === true) {
//                     if (response.data.data.length == 0) {
//                         setShowNoData(true)
//                     } else {
//                         setDesc(response.data.data[0]?.desc)
//                         setSelectedDay(response.data.data[0]?.day)
//                         setSelectedTimeSlot(response.data.data[0]?.time)
//                         setTopics(response.data?.topics)
//                         setSelectedOfferings(response.data.data[0]?.offering)
//                         setLinkedin(response.data.data[0]?.linkedin)
//                         setRating(response.data.data[0]?.rating)
//                         setSmeUserId(response.data.data[0]?.userId)
//                         setProfilePicture(response.data.data[0]?.profilePicture)
//                         setName(response.data.data[0]?.name)
//                         setBookings(response.data.data[0]?.bookings)
//                     }
//                 }
//             })
//             .catch(function (error) {
//                 console.log("Error");
//             });
//     };
//     const getAllSmeOfferings = () => {
//         let headers = {
//             "Content-Type": 'application/json',
//             "Authorization": "Bearer " + token,
//             "applicationType": "mobile"
//         }
//         axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
//             .then(function (response) {
//                 console.log(response);
//                 if (response.data.status === true) {
//                     setAllOfferings(response.data.offerings)
//                 }
//             })
//             .catch(function (error) {
//                 console.log("Error");
//             });
//     };
//     const getAllDays = () => {
//         let headers = {
//             "Content-Type": 'application/json',
//             "Authorization": "Bearer " + token,
//             "applicationType": "mobile"
//         }
//         axios.get(config.userUrl + 'user/getAllDays', { headers: headers })
//             .then(function (response) {
//                 console.log(response);
//                 if (response.data.status === true) {
//                     setAllDays(response.data.days)
//                 }
//             })
//             .catch(function (error) {
//                 console.log("Error");
//             });
//     };
//     const getAllTimeSlots = () => {
//         let headers = {
//             "Content-Type": 'application/json',
//             "Authorization": "Bearer " + token,
//             "applicationType": "mobile"
//         }
//         axios.get(config.userUrl + 'user/getAllTimeSlots', { headers: headers })
//             .then(function (response) {
//                 console.log(response);
//                 if (response.data.status === true) {
//                     setAllTimeSlots(response.data.timeSlots)
//                 }
//             })
//             .catch(function (error) {
//                 console.log("Error");
//             });
//     };


//     if (showHome) {
//         return <Redirect to="/home" />
//     }
//     return (
//         <div >
//             <div>
//                 <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
//                 {showNoData ? <div className="mt-5"><center>No Data</center></div> :
//                     <div className="mt-5">
//                         <div style={{ display: "flex", marginTop: "-15px" }}><img alt="" src={config.userUrl + profilePicture} className="ml-3 jitbits-img-Author" /><span className="ml-2 jit-top">{name}</span></div>
//                         <div className="ml-2 mr-2">
//                             <Row style={{ width: "100%" }} className="no-gutters">
//                                 <Col xs={6}>
//                                     <div className="" style={{ border: "1px solid gray", borderRadius: "20px", padding: "2px 3px", height: "100%" }}>
//                                         <div style={{ display: "flex", width: "100%" }}>
//                                             <div className="ml-4 mr-2 sme-txt-size">
//                                                 <center>Bookings<br /><img src={smebookings} /><br />{bookings}</center>
//                                             </div>
//                                             <div className="ml-2 mr-2 sme-txt-size">
//                                                 <center>Ratings<br /><img src={smerating} /><br />{rating}</center>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </Col>
//                                 <Col xs={6}>
//                                     <div className="ml-2" style={{ border: "1px solid gray", borderRadius: "20px", padding: "2px 3px", height: "100%" }}>
//                                         <div style={{ display: "flex", padding: "2px" }}>
//                                             <div className="ml-2 mr-2" style={{ fontSize: "12px" }}>
//                                                 <span style={{ fontSize: "14px", fontWeight: 600 }}>Interests: </span><br />
//                                                 {topics.map((data, i) => <span key={i}>{data.topicName.replace(/ *\([^]*\) */g, "")}, </span>)}
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </Col>
//                             </Row>
//                         </div>
//                         <div className="abt-u_">Services</div>
//                         <div className="ml-2 mr-2">
//                             <Row style={{ width: "100%" }} className="no-gutters">
//                                 <Col xs={6}>
//                                     <div className="" style={{ border: "1px solid gray", borderRadius: "20px", padding: "2px 3px", height: "100%" }}>
//                                         <div style={{ display: "flex", width: "100%" }}>
//                                             {allOfferings.filter(data => selectedOfferings.includes(data.id))
//                                                 .map((data, i) => (
//                                                     <center>
//                                                         <div
//                                                             key={data.id}
//                                                             style={{
//                                                                 width: "4.5rem",
//                                                                 padding: "4px",
//                                                                 marginLeft: "7px",
//                                                                 marginTop: "10px",
//                                                                 cursor: "pointer"
//                                                             }}
//                                                         >
//                                                             <img src={data.text.replace(/ *\([^]*\) */g, "") + ".svg"} alt={data.text} /><br />
//                                                             <div className="mt-1 sme-off-text">{data.text}</div>
//                                                         </div>
//                                                     </center>
//                                                 ))}
//                                         </div>
//                                     </div>
//                                 </Col>
//                                 <Col xs={6}>
//                                     <div className="ml-2" style={{ border: "1px solid gray", borderRadius: "20px", padding: "2px 3px", height: "100%" }}>
//                                         <div style={{ display: "flex" }}>
//                                             {allTimeSlots.filter(data => data.id === selectedTimeSlot).map((data, i) => (
//                                                 <div className="m-2 slot-time"><center><img src={selectedTimeSlot === 1 ? smefullday : (selectedTimeSlot === 2) ? smemorning : smeafternoon} style={selectedTimeSlot === data.id ? { border: "none", borderRadius: "15px", padding: "4px" } : { border: "none", borderRadius: "15px", padding: "4px" }} /><br />{data.time}</center></div>
//                                             ))}
//                                         </div>
//                                     </div>
//                                 </Col>
//                             </Row>
//                         </div>
//                         <div className="abt-u_">Linkedin</div>
//                         <div className="ml-2 mr-2" style={{ border: "1px solid gray", borderRadius: "8px", padding: "2px 3px", height: "100%" }}>{linkedin ? linkedin : "Not added linkedin profile"}</div>
//                         <div className="abt-u_">Introduction</div>
//                         <div className="mt-1 sme-intro-box">
//                             <textarea type="text"
//                                 className="web-font form-control"
//                                 aria-describedby="text"
//                                 name="desc"
//                                 style={{ overflowY: "none", background: 'white', border: '1px solid gray', borderRadius: "20px", width: "100%", resize: "none" }}
//                                 value={desc}
//                                 disabled={true}
//                                 rows={8}
//                                 placeholder="Text about self"
//                                 onChange={e => setDesc(e.target.value)}
//                             />
//                         </div>

//                         <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
//                             <Button variant="default" className="ml-2 mt-1 prev-btn-sme" onClick={() => setShowHome(true)}>Back</Button>
//                             <Link to="/smeregister" style={{color:"white"}}><Button variant="default" className="mr-2 mt-1 prev-btn-sme">Update</Button></Link>
//                         </div>
//                     </div>
//                 }
//             </div>
//             <div className="mb-bottom"></div>
//             <BottomMenu />
//         </div>
//     )
// }





import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Modal, Carousel } from "react-bootstrap";
import { Link, useLocation, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import { enqueueSnackbar } from 'notistack'
import article from '../images/article-new.jpeg';
import moment from 'moment-timezone';
import smefullday from '../images/smefullday.svg';
import smemorning from '../images/smemorning.svg';
import smeafternoon from '../images/smeafternoon.svg';
import smebooking from '../images/smebookings1.svg';
import back from '../images/backarrow.png';
import curve from '../images/curve1.svg';
import smerating from '../images/smerating.svg';
import linkedinImg from '../images/linkedinblack.svg';
import DatePicker from 'react-datepicker';
import { AiOutlineShareAlt } from "react-icons/ai";
import 'react-datepicker/dist/react-datepicker.css';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default function UpdateSmeProfile() {
    const [desc, setDesc] = useState('')
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
    const [allDays, setAllDays] = useState([]);
    const [allTimeSlots, setAllTimeSlots] = useState([]);
    const [allOfferings, setAllOfferings] = useState([]);
    const [selectedOfferings, setSelectedOfferings] = useState([]);
    const [bookings, setBookings] = useState();
    const [rating, setRating] = useState();
    const [profilePicture, setProfilePicture] = useState();
    const [name, setName] = useState();
    const [smeUserId, setSmeUserId] = useState();
    const [showConnect, setShowConnect] = useState(false)
    const [showHome, setShowHome] = useState(false)
    const [formatedNewDate, setFormattedNewDate] = useState('')
    const [userSlots, setUserSlots] = useState([]);
    const [topics, setTopics] = useState([])
    const [linkedin, setLinkedin] = useState()
    const [currDate, setCurrDate] = useState()
    const [selectedId, setSelectedId] = useState()
    const [showPopup, setShowPopup] = useState(false)
    const today = new Date();
    const [showNoData, setShowNoData] = useState(false)
    const [googleLink, setGoogleLink] = useState()
    const [agenda, setAgenda] = useState()
    const [descriptionText, setDescriptionText] = useState('');
    const [uniqueCode, setUniqueCode] = useState('');
    const [commercial, setCommercial] = useState();
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const handleBack = () => {
        setShowHome(true)
    };
    const toggleCalendar = () => {
        setCalendarOpen(!calendarOpen);
    };
    useEffect(() => {
        getSmeUserData();
        getAllSmeOfferings();
        getAllDays();
        getAllTimeSlots();
    }, [])
    const onClickUrl = () => {
        window.open(linkedin)
    }
    const getSmeUserData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getSmeUserDataById', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    if (response.data.data.length == 0) {
                        setShowNoData(true)
                    } else {
                        setDesc(response.data.data[0]?.desc)
                        setSelectedDay(response.data.data[0]?.day)
                        setSelectedTimeSlot(response.data.data[0]?.time)
                        setTopics(response.data.topics)
                        setSelectedOfferings(response.data.data[0].offering)
                        setLinkedin(response.data.data[0].linkedin)
                        setDescriptionText(response.data.data[0].description)
                        setRating(response.data.data[0].avg_assessment)
                        setSmeUserId(response.data.data[0].userId)
                        setCommercial(response.data.data[0].commercial)
                        setProfilePicture(response.data.data[0].profilePicture)
                        setName(response.data.data[0].name)
                        setBookings(response.data.data[0].bookings)
                        setUniqueCode(response.data.data[0].uniquecode)
                    }
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    setAllOfferings(response.data.offerings)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllDays = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllDays', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    setAllDays(response.data.days)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllTimeSlots = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllTimeSlots', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    setAllTimeSlots(response.data.timeSlots)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const formatRatingNumber = (number) => {
        const roundedNumber = Math.round(number * 100) / 100; // Round to two decimal places
        const decimalPart = roundedNumber - Math.floor(roundedNumber);

        if (decimalPart === 0) {
            return Math.floor(roundedNumber); // Remove decimal part if it's zero
        } else {
            return roundedNumber.toFixed(1); // Keep two decimal places otherwise
        }
    }
    const shareSmeProfile = () => {
        // var id = simpleCrypto.encrypt(userId);
        if (navigator.share) {
            navigator.share({
                url: config.sceemLpUrl + `profile/${uniqueCode}`,
            }).then(() => {
                //console.log('Thanks for sharing!');
            }).catch(err => {
                //console.log("Error while using Web share API:");
                console.log(err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
    };
    if (showHome) {
        return <Redirect to="/home" />
    }
    if (showNoData) {
        return <div>
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <center className="mt-5">No Data</center>
            <BottomMenu />
        </div>
    }
    return (
        // <div >
        //     <div>
        //         <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
        //         <div className="mt-5">
        //             <div>
        //                 <div>
        //                     <img src={curve} style={{ width: "100%" }} />
        //                     <div onClick={handleBack}>  <img src={back} style={{ cursor:"pointer",width: "40px", position: "absolute", top: "4.5rem", left: "-12px" }} /></div>
        //                     <div style={{ width: "100%", position: "absolute", top: "5.8rem", left: "1rem" }}>
        //                         <Row className="no-gutters" style={{ width: "100%" }}>
        //                             <Col xs={3}>
        //                                 <img alt="" src={config.userUrl + profilePicture} style={{ width: "70px" }} />

        //                             </Col>
        //                             <Col xs={2}></Col>
        //                             <Col xs={3}>
        //                                 <Carousel indicators={false} slide={false} controls={false} style={{ marginTop: "-9px" }}>

        //                                     {topics.map((data, i) =>
        //                                         <Carousel.Item variant="dark" interval={2000} style={{ marginTop: "-0.2rem" }}>
        //                                             <Card style={{ marginRight: "2px", background: "lightblue", height: "5.5rem", border: "none", borderRadius: "15px", padding: "auto" }}>
        //                                                 <center>
        //                                                     <div key={i} style={{ lineHeight: "14px" }}> </div>
        //                                                     <div style={{ fontSize: "13px", fontWeight: 600 }}>Interests</div>
        //                                                     <div> <img src={data.topicName.replace(/ *\([^]*\) */g, "") + ".png"} style={{ width: "25px", marginTop: "-2px" }} /></div>
        //                                                     <div style={{ lineHeight: "14px", width: "70%" }} className="sme-off-text">{data.topicName.replace(/ *\([^]*\) */g, "")}</div>
        //                                                 </center>
        //                                             </Card>
        //                                         </Carousel.Item>
        //                                     )}
        //                                 </Carousel>
        //                             </Col>
        //                             <Col xs={3}>
        //                                 <Carousel indicators={false} slide={false} controls={false} style={{ marginTop: "-9px" }}>
        //                                     {allOfferings.filter(data => selectedOfferings.includes(data.id))
        //                                         .map((data, i) => (
        //                                             <Carousel.Item variant="dark" interval={2000} style={{ marginTop: "-0.2rem" }}>
        //                                                 <Card style={{ marginLeft: "2px", background: "lightblue", height: "5.5rem", border: "none", borderRadius: "15px", padding: "auto" }}>
        //                                                     <center>
        //                                                         <div style={{ fontSize: "13px", fontWeight: 600 }}>Offerings</div>
        //                                                         <img src={data.text.replace(/ *\([^]*\) */g, "") + ".svg"} alt={data.text} className="mt-1" />
        //                                                         <div className="ml-1 sme-off-text">{data.text}</div>
        //                                                     </center>
        //                                                 </Card>
        //                                             </Carousel.Item>
        //                                         ))}
        //                                 </Carousel>
        //                             </Col>
        //                         </Row>
        //                     </div>
        //                     <div style={{ width: "100%", position: "absolute", top: "23%", left: "1rem" }}>
        //                         <Row className="no-gutters" style={{ width: "100%" }}>
        //                             <Col xs={5}>
        //                                 <span style={{ marginLeft: "5%", fontSize: "14px", color: "white", fontWeight: 600 }}>{name} </span><br />
        //                                 <span style={{ fontSize: "14px", color: "white" }}>{descriptionText}</span>
        //                             </Col>
        //                             <Col xs={3}>
        //                                 <Card style={{ marginRight: "2px", marginTop: "7px", background: "lightblue", border: "none", borderRadius: "15px", padding: "auto" }}>
        //                                     <center>
        //                                         <div style={{ fontSize: "13px", fontWeight: 600 }}>Assessment</div>
        //                                         <div> <img src={smerating} style={{ width: "55px", marginTop: "-7px" }} /></div>
        //                                         <div style={{ fontSize: "15px", marginTop: "-7px" }}>{rating}</div>
        //                                     </center>
        //                                 </Card>
        //                             </Col>
        //                             <Col xs={3}>
        //                                 <Card style={{ marginLeft: "2px", marginTop: "7px", background: "lightblue", border: "none", borderRadius: "15px", padding: "auto" }}>
        //                                     <center>
        //                                         <div style={{ fontSize: "13px", fontWeight: 600 }}>Appointment</div>
        //                                         <div> <img src={mentoring} style={{ width: "55px", marginTop: "-7px" }} /></div>
        //                                         <div style={{ fontSize: "15px", marginTop: "-7px" }}>{bookings}</div>
        //                                     </center>
        //                                 </Card>
        //                             </Col>
        //                         </Row>
        //                     </div>
        //                     <div style={{ width: "100%", position: "absolute", top: "40%", left: "1rem" }}>
        //                         <Row className="no-gutters" style={{ width: "100%", marginTop: "-4px" }}>
        //                             <Col xs={4}>
        //                                 <img src={linkedinImg} className="ml-3 cursor-pointer" />
        //                             </Col>
        //                             <Col xs={3}>
        //                             </Col>
        //                             <Col xs={1}></Col>
        //                             <Col xs={3}>
        //                                 <div style={{ position: "absolute", top: "-1.5rem" }}>  <Link to="/smeregister" style={{ color: "white" }}> <Button variant="default" className="mt-1 prev-btn-sme">Update</Button></Link> </div>
        //                             </Col>
        //                         </Row>
        //                     </div>
        //                     <div style={{ width: "100%", position: "absolute", top: "48%", left: "0.1rem" }}>
        //                         <div style={{fontSize:"16px",fontWeight:600}} className="ml-2">About me</div>
        //                         <div className="mt-1 sme-intro-box-aboutme">
        //                             {desc}
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>

        //     <div className="mb-bottom"></div>
        //     <BottomMenu />
        // </div >
        // <div>
        //     <img src={curve} style={{ width: "100%", position: "absolute", top: "4.5rem", left: 0 }} />
        //     <img src={back} alt="Back" className="back_arrow-sme-profile" onClick={handleBack} />

        //     <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
        //     <div className="mt-5">
        //         <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
        //             <Row className="no-gutters" style={{ width: "100%" }}>
        //                 <Col xs={4}>
        //                     <img alt="" src={config.userUrl + profilePicture} style={{ marginLeft: "1.7rem", marginTop: "0.5rem", width: "85px" }} />
        //                 </Col>
        //                 <Col xs={1}></Col>
        //                 <Col xs={7}>
        //                     <Card className="offerings-card-smeprofile" style={{ marginLeft: "0.8rem", width: "94%" }}>
        //                         <center style={{ fontSize: "12px", fontWeight: 600 }}>Offerings</center>
        //                         <Row className="no-gutters" style={{ width: "100%" }}>
        //                             <Col>
        //                                 {allOfferings.filter(data => selectedOfferings.includes(data.id)).slice(0, 1).map((data, i) => (
        //                                     <center>
        //                                         <div className="offerings-wrapper">
        //                                             <img src={data.text.replace(/ *\([^]*\) */g, "") + ".svg"} alt={data.text} style={{ width: "45px", marginTop: "-8px" }} />
        //                                             <div className="offerings-text">{data.text}</div>
        //                                         </div>
        //                                     </center>
        //                                 ))}
        //                             </Col>
        //                             <Col>
        //                                 {allOfferings.filter(data => selectedOfferings.includes(data.id)).slice(1, 2).map((data, i) => (
        //                                     <center>
        //                                         <div className="offerings-wrapper">
        //                                             <img src={data.text.replace(/ *\([^]*\) */g, "") + ".svg"} alt={data.text} style={{ width: "45px", marginTop: "-8px" }} />
        //                                             <div className="offerings-text">{data.text}</div>
        //                                         </div>
        //                                     </center>

        //                                 ))}
        //                             </Col>
        //                         </Row>

        //                     </Card>
        //                 </Col>
        //             </Row>
        //         </div>
        //         <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
        //             <Row className="no-gutters" style={{ width: "100%" }}>
        //                 <Col xs={5}>
        //                     <center style={{ fontSize: "14px", color: "white", fontWeight: 600 }}>{name} </center>
        //                     <center style={{ fontSize: "12px", color: "white" }}>{descriptionText}</center>
        //                 </Col>
        //                 <Col xs={4}>
        //                     <Card style={{ background: "#d7deef", backgroundImage: "linear-gradient(to right, #d7deef , #9DB2DD)", height: "5.5rem", width: "78%", border: "none", borderRadius: "15px", padding: "auto", marginLeft: "10px", marginRight: "3px" }}>
        //                         <center>
        //                             <div style={{ fontSize: "12px", fontWeight: 600 }}>Assessment</div>
        //                             <img src={smerating} style={{ width: "55px", marginTop: "-7px" }} alt="Assessment" />
        //                             <div style={{ fontSize: "15px", fontWeight: 600, fontFamily: "Noto Sans", marginTop: "-7px" }} className="mb-1">{formatRatingNumber(rating)}</div>
        //                         </center>
        //                     </Card>
        //                 </Col>
        //                 <Col xs={3}>
        //                     <Card style={{ width: "100%", background: "#d7deef", backgroundImage: "linear-gradient(to right, #d7deef , #9DB2DD)", height: "5.5rem", border: "none", borderRadius: "15px", padding: "auto" }}>
        //                         <center>
        //                             <div style={{ fontSize: "12px", fontWeight: 600 }}>Appointment</div>
        //                             <img src={smebooking} style={{ width: "55px", marginTop: "-7px" }} alt="Appointment" />
        //                             <div style={{ fontSize: "15px", fontWeight: 600, fontFamily: "Noto Sans", marginTop: "-7px" }} className="mb-1">{bookings}</div>
        //                         </center>
        //                     </Card>
        //                 </Col>
        //             </Row>
        //         </div>
        //         <div style={{ marginLeft: "1rem", marginRight: "1rem", marginTop: "1.6rem" }}>
        //             <Row className="no-gutters" style={{ width: "100%" }}>
        //                 <Col xs={4}>
        //                     <img src={linkedinImg} className="ml-3 cursor-pointer" alt="LinkedIn" onClick={onClickUrl} />
        //                 </Col>
        //                 <Col xs={4}>
        //                     <center style={{ marginLeft: "-10px", color: "white", fontFamily: "Noto Sans", fontSize: "14px" }}><b>Fees</b><br /> {commercial === 1 ? "Free" : "Paid"}</center>
        //                 </Col>
        //                 <Col xs={4} >
        //                     <Link to="/smeregister" style={{ color: "white", textDecoration: "none" }}>
        //                         <Button variant="default" className="ml-4 mt-1 profile-btn-sme">Update</Button>
        //                     </Link>
        //                 </Col>
        //             </Row>
        //         </div>
        //         <div style={{ marginLeft: "1rem", marginRight: "1rem", marginTop: "2rem" }}>
        //             <div style={{ fontSize: "16px", fontWeight: 600 }} className="ml-2">Expertise</div>
        //             <div className="ml-2" style={{ display: "flex", flexWrap: "wrap" }}>
        //                 {topics.map((data, i) => (
        //                     <div style={{ fontSize: "12px", fontWeight: 500, marginTop: "-2px" }}>{data.topicName.replace(/ *\([^]*\) */g, "")},&nbsp;</div>
        //                 ))}
        //             </div>
        //             <div style={{ fontSize: "16px", fontWeight: 600 }} className="mt-2 ml-2">About me</div>
        //             <div className="mt-1 sme-intro-box-aboutme">
        //                 {desc}
        //             </div>
        //         </div>
        //     </div>
        //     <div className="mb-bottom"></div>
        //     <BottomMenu />
        // </div>
        <div>
            <div style={{ height: "96.5vh", overflow: "hidden", display: "flex", flexDirection: "column" }}>
                <img src={curve} style={{ width: "100%", position: "absolute", top: "4.5rem", left: 0 }} />
                <img src={back} alt="Back" className="back_arrow-sme-profile" onClick={handleBack} />

                <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                <div className="mt-5" style={{ flex: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
                    <div style={{ marginLeft: "1rem", marginRight: "1rem", flexShrink: 0 }}>
                        <Row className="no-gutters" style={{ width: "100%" }}>
                            <Col xs={4}>
                                <img alt="" src={config.userUrl + profilePicture} style={{ marginLeft: "1.7rem", marginTop: "0.5rem", width: "85px" }} />
                            </Col>
                            <Col xs={1}></Col>
                            <Col xs={7}>
                                <Card className="offerings-card-smeprofile" style={{ marginLeft: "0.8rem", width: "94%" }}>
                                    <center style={{ fontSize: "12px", fontWeight: 600 }}>Offerings</center>
                                    <Row className="no-gutters" style={{ width: "100%" }}>
                                        <Col>
                                            {allOfferings.filter(data => selectedOfferings.includes(data.id)).slice(0, 1).map((data, i) => (
                                                <center key={i}>
                                                    <div className="offerings-wrapper">
                                                        <img src={data.text.replace(/ *\([^]*\) */g, "") + ".svg"} alt={data.text} style={{ width: "45px", marginTop: "-8px" }} />
                                                        <div className="offerings-text">{data.text}</div>
                                                    </div>
                                                </center>
                                            ))}
                                        </Col>
                                        <Col>
                                            {allOfferings.filter(data => selectedOfferings.includes(data.id)).slice(1, 2).map((data, i) => (
                                                <center key={i}>
                                                    <div className="offerings-wrapper">
                                                        <img src={data.text.replace(/ *\([^]*\) */g, "") + ".svg"} alt={data.text} style={{ width: "45px", marginTop: "-8px" }} />
                                                        <div className="offerings-text">{data.text}</div>
                                                    </div>
                                                </center>
                                            ))}
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ marginLeft: "1rem", marginRight: "1rem", flexShrink: 0 }}>
                        <Row className="no-gutters" style={{ width: "100%" }}>
                            <Col xs={5}>
                                <center style={{ fontSize: "14px", color: "white", fontWeight: 600 }}>{name} </center>
                                <center style={{ fontSize: "12px", color: "white" }}>{descriptionText}</center>
                            </Col>
                            <Col xs={4}>
                                <Card style={{ background: "#d7deef", backgroundImage: "linear-gradient(to right, #d7deef , #9DB2DD)", height: "5.5rem", width: "78%", border: "none", borderRadius: "15px", padding: "auto", marginLeft: "10px", marginRight: "3px" }}>
                                    <center>
                                        <div style={{ fontSize: "12px", fontWeight: 600 }}>Assessment</div>
                                        <img src={smerating} style={{ width: "55px", marginTop: "-7px" }} alt="Assessment" />
                                        <div style={{ fontSize: "15px", fontWeight: 600, fontFamily: "Noto Sans", marginTop: "-7px" }} className="mb-1">{formatRatingNumber(rating)}</div>
                                    </center>
                                </Card>
                            </Col>
                            <Col xs={3}>
                                <Card style={{ width: "100%", background: "#d7deef", backgroundImage: "linear-gradient(to right, #d7deef , #9DB2DD)", height: "5.5rem", border: "none", borderRadius: "15px", padding: "auto" }}>
                                    <center>
                                        <div style={{ fontSize: "12px", fontWeight: 600 }}>Appointment</div>
                                        <img src={smebooking} style={{ width: "55px", marginTop: "-7px" }} alt="Appointment" />
                                        <div style={{ fontSize: "15px", fontWeight: 600, fontFamily: "Noto Sans", marginTop: "-7px" }} className="mb-1">{bookings}</div>
                                    </center>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    {/* <div style={{ marginLeft: "1rem", marginRight: "1rem", flexShrink: 0 }}>
                    <Row className="no-gutters" style={{ width: "100%" }}>
                        <Col xs={4}>
                            <img src={linkedinImg} className="ml-3 cursor-pointer" alt="LinkedIn" onClick={onClickUrl} />
                        </Col>
                        <Col xs={4}>
                            <center style={{ marginLeft: "-10px", color: "white", fontFamily: "Noto Sans", fontSize: "14px" }}><b>Fees</b><br /> {commercial === 1 ? "Free" : "Paid"}</center>
                        </Col>
                        <Col xs={4}>
                            <Link to="/smeregister" style={{ color: "white", textDecoration: "none" }}>
                                <Button variant="default" className="ml-4 mt-1 profile-btn-sme">Update</Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
                <div style={{ marginLeft: "1rem", marginRight: "1rem", flex: 1, overflowY: "auto", marginTop: "6.5vh", maxHeight: "calc(100vh - 6.5vh - 2rem)" }}>
                    <div>
                        <div style={{ fontSize: "16px", fontWeight: 600 }} className="ml-2">Expertise</div> */}
                    <div style={{ marginLeft: "1rem", marginRight: "1rem", flexShrink: 0 }}>
                        <Row className="no-gutters" style={{ width: "100%" }}>
                            <Col xs={4}>
                                {/* <img src={linkedinImg} className="ml-3 cursor-pointer" alt="LinkedIn" onClick={onClickUrl} /> */}
                                <Button variant="default" className="mt-1 profile-btn-sme" style={{ borderBottom: "1px solid white" }}><Link to={`/viewsmeevents/${smeUserId}`}><div style={{ fontSize: "13px", fontWeight: 600, color: "white", fontFamily: "Noto Sans" }}>View Events</div></Link></Button>
                            </Col>
                            <Col xs={4}>
                                <center style={{ marginLeft: "-10px", color: "white", fontFamily: "Noto Sans", fontSize: "14px" }}><b>Fees</b><br /> {commercial === 1 ? "Free" : "Paid"}</center>
                            </Col>
                            <Col xs={4} >
                                <Link to="/smeregister" style={{ color: "white", textDecoration: "none" }}>
                                    <Button variant="default" className="ml-4 mt-1 profile-btn-sme">Update</Button>
                                </Link>
                                <center className="ml-5 mt-1"><div onClick={() => shareSmeProfile()}><AiOutlineShareAlt size="23px" /></div></center>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ marginLeft: "1rem", marginRight: "1rem", flex: 1, overflowY: "auto", overflowX: "hidden", marginTop: "6.5vh" }}>
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ fontSize: "16px", fontWeight: 600 }} className="ml-2">Expertise</div>
                                <div> <img src={linkedinImg} style={{ marginTop: "-10px" }} className="cursor-pointer" alt="LinkedIn" onClick={onClickUrl} /></div>
                            </div>
                            <div className="ml-2" style={{ display: "flex", flexWrap: "wrap" }}>
                                {topics.map((data, i) => (
                                    <div key={i} style={{ fontSize: "12px", fontWeight: 500 }}>{data.topicName.replace(/ *\([^]*\) */g, "")},&nbsp;</div>
                                ))}
                            </div>
                            <div style={{ fontSize: "16px", fontWeight: 600 }} className="mt-2 ml-2">About me</div>
                            <div className="mt-1 sme-intro-box-aboutme">
                                {desc}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: "3.6rem" }}></div>
                <BottomMenu />
            </div>
        </div >
    )
}

const CustomInput = ({ value, onClick }) => (
    <button className="btn" onClick={onClick}>
        <i class="fa fa-calendar" id="true" aria-hidden="true"></i>
        <span className="_picker">        {value}</span>
    </button>
);



