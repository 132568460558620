import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import back from '../images/backarrow.png';
import axios from 'axios';
import { config } from '../Config/Config';
import BottomMenu from "../BottomMenu/BottomMenu";
import OtpInput from 'react-otp-input';
import SimpleCrypto from "simple-crypto-js";
import CircularText from "./CircularText";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const myComponentStyle = {
    width: '22px',
    height: '30px',
    color: 'black',
    paddingBottom: 4,
    borderRadius: '6px',
    marginLeft: '1px',
    marginRight: '1px',
    fontWeight: 'bold',
    fontSize: '16px',
}


export default function ScrambledGame(props) {
    const [word, setWord] = useState("")
    const [term, setTerm] = useState("")
    const [jumbledTerm, setJumbledTerm] = useState("")
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [noOfTrials, setNoOfTrials] = useState()
    const [userTrials, setUserTrials] = useState()
    const [gameName, setGameName] = useState("")
    const [gameDescription, setGameDescription] = useState("")
    const [gamePicture, setGamePicture] = useState("")
    const [rewardPoints, setRewardPoints] = useState()
    const [termDefinition, setTermDefinition] = useState("")
    const [termUsage, setTermUsage] = useState("")
    const [termId, setTermId] = useState("")
    const [sort, setSort] = useState("")
    const [howToPlay, setHowToPlay] = useState("")
    const inputRefs = useRef([]);

    useEffect(() => {
        getTermRandomly();
        getGameById();
       // console.log("game")
    }, []);

    const getTermRandomly = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getTermRandomly', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setTerm(response.data.terms.term)
                    setTermDefinition(response.data.terms.definition)
                    setTermUsage(response.data.terms.usage)
                    setTermId(response.data.terms.id)
                    setJumbledTerm(response.data.terms.term.split("").map(v => [v, Math.random()]).sort((a, b) => a[1] - b[1]).map(v => v[0]).join(""))
                    // console.log(response)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getGameById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + `user/getGameById/${props.match.params.id}`, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setGameName(response.data.game[0].gameName)
                    setGameDescription(response.data.game[0].description)
                    setGamePicture(response.data.game[0].picture)
                    setRewardPoints(response.data.game[0].rewardPoints)
                    setNoOfTrials(response.data.game[0].numberOfTrials)
                    setUserTrials(response.data.game[0].numberOfTrials)
                    setHowToPlay(response.data.game[0].howToPlay)
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    const handleSubmit = (event) => {
        const data = new FormData(event.target);
        event.preventDefault()
        checkWord();
        setWord("")
    }
    const checkWord = (e) => {
        const processedTerm = term.replace(/\s/g, '').toLowerCase();
        const processedWord = word && word?.map(element => (element === undefined ? '' : element)).join('').toLowerCase();
        if (processedTerm === processedWord) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + token,
                "applicationType": "mobile"
            }
            var payload = {
                "gameId": Number(props.match.params.id),
                "gameRewardPoint": rewardPoints
                // "gameRewardPoints": rewardPoints,
                // "userNumberOfTrials": userTrials
            }
            axios.post(config.userUrl + 'user/updateGameRewardPoint', payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status === true) {
                       // console.log("success!!")
                        showSuccess()
                    } else {
                        console.log("error")
                    }
                })
                .catch(function (error) {
                    console.log("error", payload,error)
                });
        } else {
            if (userTrials <= noOfTrials && userTrials != 1) {
                //console.log("if")
                setUserTrials(userTrials - 1)
                showFailure()
            } else if (userTrials > 0 && userTrials <= 1) {
             //   console.log("else")
                setUserTrials(0)
                trialsExpired()
            } else {
                setUserTrials(0)
                trialsExpired()
            }
        }
    }
    const showHowToPlay = () => {
       // console.log("red")
        document.getElementById('play').style.display = 'flex';
    }
    const hideHowToPlay = () => {
        document.getElementById('play').style.display = 'none';
    }
    const showSuccess = () => {
        document.getElementById('success').style.display = 'flex';
    }
    const hideSuccess = () => {
        document.getElementById('success').style.display = 'none';
        window.location.replace('/games')
    }
    const showFailure = () => {
        document.getElementById('failure').style.display = 'flex';
    }
    const hideFailure = () => {
        document.getElementById('failure').style.display = 'none';
    }
    const trialsExpired = () => {
        document.getElementById('trial').style.display = 'flex';
    }
    const trialsExpiredNone = () => {
        document.getElementById('trial').style.display = 'none';
        window.location.replace('/games')
    }

    return (
        <div>
            <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
            <Link to="/games"><img alt="" src={back} className="back_arrow" /></Link>
            <center> <img alt="" src={gamePicture} className="game-image" />
                <div style={{ fontFamily: "Noto Sans" }} className="game-text">{gameDescription}<br /> <span style={{ fontSize: "18px" }}>And earn {rewardPoints} Reward Points</span></div>
                <div style={{ fontFamily: "Noto Sans", color: "black", fontSize: "14px" }}>Trials Left: {userTrials}/{noOfTrials}</div></center>
            <Button variant="default" className="showPlay-btn" onClick={() => showHowToPlay()} >How to Play</Button>
            {/* <div className="ml-4" style={{ textAlign: "left", color: "#FF942c", fontSize: "20px" }}>Description</div>
            <div className="ml-4 mr-4" style={{ textAlign: "justify", color: "#FF942c", fontSize: "14px" }}>{termDefinition}</div> */}
            <div className="wrapper">
                <CircularText text={jumbledTerm?.replace(/\s/g, '')} arc={360} radius={80} />
            </div>
            <Form className="forgot-email" onSubmit={handleSubmit}>
                <center>
                    <div className="game-boxes">
                        {/* <OtpInput
                            value={word.toLowerCase()}
                            onChange={setWord}
                            numInputs={term.replace(/\s/g, '').length}
                            inputType="text"
                            inputStyle={myComponentStyle}
                            renderSeparator={<span></span>}
                            renderInput={(props) => <input {...props} />}
                        /> */}
                        {term.split('').map((alphabet, index) => (
                            <input
                                type="text"
                                key={index}
                                value={word[index]?.toLowerCase() || ''}
                                onChange={(e) => {
                                    let inputText = e.target.value.toLowerCase();
                                    if (inputText.length > 1) {
                                        // Allow only one letter per input
                                        inputText = inputText.charAt(0);
                                    }

                                    let temp = [...word];
                                    temp[index] = inputText;
                                    setWord(temp);

                                    if (term[index] === ' ' && index < term.length - 1) {
                                        let nextIndex = index + 1;

                                        // Skip over disabled inputs
                                        while (nextIndex < term.length - 1 && term[nextIndex] === ' ') {
                                            nextIndex++;
                                        }

                                        // Check if the next input is not disabled
                                        if (nextIndex < term.length && term[nextIndex] !== ' ') {
                                            inputRefs.current[nextIndex].focus();
                                        }
                                    } else if ((inputText.length > 0 || e.key === 'Enter') && index < term.length - 1) {
                                        let nextIndex = index + 1;

                                        // Skip over disabled inputs
                                        while (nextIndex < term.length - 1 && term[nextIndex] === ' ') {
                                            nextIndex++;
                                        }

                                        // Check if the next input is not disabled
                                        if (nextIndex < term.length && term[nextIndex] !== ' ') {
                                            inputRefs.current[nextIndex].focus();
                                        }
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        // Handle backspace to erase letter and move focus to the previous input
                                        let prevIndex = index - 1;

                                        while (prevIndex >= 0 && term[prevIndex] === ' ') {
                                            prevIndex--;
                                        }

                                        if (prevIndex >= 0 && term[prevIndex] !== ' ') {
                                            let temp = [...word];
                                            temp[index] = '';  // Clear the current input
                                            setWord(temp);
                                            inputRefs.current[prevIndex].focus();
                                        }
                                    }
                                }}
                                className="form-control otp-width"
                                maxLength={1}  // Limit input to one character
                                disabled={term[index] === ' '}
                                ref={(el) => (inputRefs.current[index] = el)}
                            />
                        ))}
                    </div>
                    <br /><br />
                    {word && word?.map(element => (element === undefined ? '' : element)).join('').length == term.replace(/\s/g, '').length ?
                        <Button variant="default" type="submit" style={{ margin: "auto", position: "absolute", bottom: "5rem", left: "50%", transform: "translateX(-50%)" }}
                            className="btn-block login-btn">Submit</Button>
                        :
                        <Button disabled="true" variant="default" type="submit" style={{ margin: "auto", position: "absolute", bottom: "5rem", left: "50%", transform: "translateX(-50%)" }}
                            className="btn-block login-btn">Submit</Button>
                    }
                    <br />
                    <div id="otp" className="errormsg"></div>
                    <div id="tryanother" className="errormsg"></div>
                </center>
            </Form>
            <div id="success" className="modal-pop">
                <form className="modal-content-log">
                    <div className="_container">
                        <p className="mt-2" style={{ color: "#31197C", fontSize: "20px" }}>Correct !!</p>
                        <p className="mt-2">You have earned {rewardPoints}  Reward Points.</p>
                        <center><Button variant="default" onClick={hideSuccess} className="deletebutton">Ok</Button></center>
                    </div>
                </form>
            </div>
            <div id="failure" className="modal-pop">
                <form className="modal-content-log">
                    <div className="_container">
                        <p className="mt-2" style={{ color: "#31197C", fontSize: "20px" }}>Oops! Try again</p>
                        <p className="mt-2">No.of trials left: {userTrials}</p>
                        <center><Button variant="default" onClick={hideFailure} className="deletebutton">Ok</Button></center>
                    </div>
                </form>
            </div>
            <div id="trial" className="modal-pop">
                <form className="modal-content-log">
                    <div className="_container">
                        <p className="mt-2" style={{ color: "#31197C", fontSize: "18px" }}>Wrong Guess. Try again.</p>
                        <p className="mt-2" style={{ color: "#31197C", fontSize: "20px" }}>0 Trials Left !!</p>
                        <center><Button variant="default" onClick={trialsExpiredNone} className="deletebutton">Ok</Button></center>
                    </div>
                </form>
            </div>
            <div id="play" className="modal-pop">
                <form className="modal-content-log">
                    <div className="_container-howtoplay">
                        <center className="mt-1" style={{ color: "#31197C", fontSize: "20px" }}>How to Play</center>
                        {/* <div className="mt-2">{howToPlay}</div> */}
                        <div className="mt-2" style={{ wordWrap: "break-word", width: "100%" }} dangerouslySetInnerHTML={{ __html: howToPlay }}></div>
                        <center><Button variant="default" onClick={hideHowToPlay} className="mt-2 deletebutton">Ok</Button></center>
                    </div>
                </form>
            </div>
            <BottomMenu />

        </div >
    );
}







