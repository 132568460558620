import React from 'react';
import './App.css';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import axios from 'axios';
import { config } from './Config/Config';
import loginlogo from '../src/images/login-logo.png';
import nebutech from '../src/images/nebutech-logo.svg';
import { BrowserView, MobileView, engineName, engineVersion, deviceDetect, deviceType, mobileModel, isBrowser, isMobile } from 'react-device-detect';
import SimpleCrypto from "simple-crypto-js";
import { Button, Navbar, Nav, Form, Container, Col, Row, Image } from "react-bootstrap";
import InstallButton from './Login/InstallButton';
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key);


class App extends React.Component {
  state = {
    isDisconnected: false,
    onLoad: false,
    isMounted: false,
    email: "",
    isNewVersionAvailable: false
  }

  componentDidMount() {
    console.log("componentDidMount")
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
    if (!this.getUserLoggedIn) {
      document.cookie = "userLoggedIn=true; expires = 0"
      window.addEventListener("beforeunload", this.logoutLocal);

    }
    // if (x == "true") {
    //   window.addEventListener("beforeunload", this.logoutLocal);
    // } else {
    //   this.logoutLocal();
    // }
  }
  getUserLoggedIn = () => {
    var x = document.cookie;
    if (x.split('=')[0] == "userLoggedIn") {
      return x.split('=')[1]
    }
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  componentWillUnmount() {
    // console.log("componentWillUnmount")
    // this.setState({ onLoad: true })
    // window.removeEventListener('online', this.handleConnectionChange);
    // window.removeEventListener('offline', this.handleConnectionChange);
    // window.removeEventListener("beforeunload", this.logoutLocal);
  }
  logoutLocal = (e) => {
    e.preventDefault()
    localStorage.clear();
    let headers = {
      "Content-Type": 'application/json',
      "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem('junkStore')),
      "applicationType": "mobile"
    }
    axios.get(config.apiUrl + 'auth/logout', { headers: headers })
      .then(function (response) {
        if (response.data.status === true) {
          localStorage.clear();
        }
      })
      .catch(function (error) {
        // console.log("Error", error);
      });
  }


  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(
        () => {
          fetch('//google.com', {
            mode: 'no-cors',
          })
            .then(() => {
              this.setState({ isDisconnected: false }, () => {
                return clearInterval(webPing)
              });
            }).catch(() => this.setState({ isDisconnected: true }))
        }, 2000);
      return;
    }

    return this.setState({ isDisconnected: true });
  }
  // renderContent = () => {
  //   const { isDisconnected } = this.state;
  //   if (isMobile) {
  //     return (
  //       <div> {isDisconnected ?
  //         <center className="no-connection"> <h4>No Internet connection</h4></center>
  //         : <div><Router>
  //           <ScrollToTop />
  //           <Routes />
  //         </Router>
  //         </div >}
  //       </div>
  //     )
  //   }
  //   return <center className="no-connection"> <h4> Please open in mobile...</h4> </center>
  // }

  // render() {
  //   return this.renderContent();
  // }
  // componentDidMount() {
  //   this.checkForNewVersion();
  // }

  // checkForNewVersion = async () => {
  //   try {
  //     const response = await fetch(config.userUrl + 'user/getLatestVersion');
  //     const data = await response.json();
  //     const isNewVersionAvailable = data.isNewVersionAvailable;
  //     this.setState({ isNewVersionAvailable });
  //   } catch (error) {
  //     console.error('Error checking for new version:', error);
  //   }
  // };

  // handleUpdate = () => {
  //   window.location.reload(true);
  // };
  
  render() {
    const { isDisconnected, isNewVersionAvailable } = this.state;
    const width = window.innerWidth
    return (
      <div>{width < 500 ?
        <div > <center id="turn">
          <h4 className="no-connection">Works on portrait mode, please rotate your decice</h4>
        </center>{isDisconnected ?
          <center className="no-connection"> <h4>No Internet connection</h4></center>
          : <div id="container">
            {/* {isNewVersionAvailable && (
          enqueueSnackbar("A new version was released", {
            persist: true,
            variant: "success",
            action: this.handleUpdate(),
          })
            )} */}
            <InstallButton />
            <Router>
              <ScrollToTop />
              <Routes />
            </Router>
          </div >}
        </div> : <div><Container>
          <Row>
            <Col md={1}></Col>
            <Col md={5}>
              <img src={loginlogo} style={{ marginTop: "45%" }} />
            </Col>
            <Col md={5} style={{ marginTop: "13%", marginLeft: "5%" }}>
              <div className="sceem-app" > <h5><b>Sceem App</b></h5><div style={{
                marginTop: "15px",
                marginBottom: "15px",
                border: "solid grey",
                borderWidth: "0 0 4px 0px"
              }} /></div>
              <div>Sceem is designed as mobile friendly application. It is available on all mobile devices with Chrome or Edge browsers.  You do not have to install from any playstore. </div>
              <p>
                <div className="mt-5">
                  <div>

                    Just use the same link (<a href="https://mob.sceem.org/" className="link-color">https://mob.sceem.org/</a>) and enjoy its rich features.</div>
                </div>
              </p>

            </Col>
            <Col md={1}></Col>
          </Row></Container>
          <div className="nebutecH" ><img src={nebutech} className="nebutecH-login-img" /></div>
        </div>}
      </div>
    );
  }
}

export default App;

// // import React, { Component } from 'react';
// // import './App.css';
// // import Routes from './Routes';
// // import { BrowserRouter as Router } from 'react-router-dom';

// // class App extends React.Component {
// //   state = {
// //     loading: true
// //   };

// //   componentDidMount() {
// //     this.isLoading = setTimeout(() => { this.setState({ loading: false }) }, 2000);
// //   }
// //   componentWillUnmount() {
// //     clearTimeout(this.isLoading);
// //   }
// //   render() {
// //     return (
// //       <div>
// //         <Router>
// //           <Routes />
// //         </Router>
// //       </div >
// //     );
// //   }
// // }

// // export default App;





// import React from 'react';
// import './App.css';
// import Routes from './Routes';
// import { BrowserRouter as Router } from 'react-router-dom';
// import ScrollToTop from './ScrollToTop';
// import axios from 'axios';
// import { config } from './Config/Config';
// import loginlogo from '../src/images/login-logo.png';
// import nebutech from '../src/images/nebutech-logo.svg';
// import { BrowserView, MobileView, engineName, engineVersion, deviceDetect, deviceType, mobileModel, isBrowser, isMobile } from 'react-device-detect';
// import SimpleCrypto from "simple-crypto-js";
// import { Button, Navbar, Nav, Form, Container, Col, Row, Image } from "react-bootstrap";
// import InstallButton from './Login/InstallButton';
// import { withSnackbar } from "notistack";
// import * as serviceWorker from "./service-worker";
// var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
// var simpleCrypto = new SimpleCrypto(key);


// class App extends React.Component {
//   state = {
//     isDisconnected: false,
//     onLoad: false,
//     isMounted: false,
//     email: "",
//     newVersionAvailable: false,
//     waitingWorker: {},
//   }

//   componentDidMount() {
//     console.log("componentDidMount")
//     this.handleConnectionChange();
//     window.addEventListener('online', this.handleConnectionChange);
//     window.addEventListener('offline', this.handleConnectionChange);
//     if (!this.getUserLoggedIn) {
//       document.cookie = "userLoggedIn=true; expires = 0"
//       window.addEventListener("beforeunload", this.logoutLocal);

//     }
//     // if (x == "true") {
//     //   window.addEventListener("beforeunload", this.logoutLocal);
//     // } else {
//     //   this.logoutLocal();
//     // }
//   }
//   getUserLoggedIn = () => {
//     var x = document.cookie;
//     if (x.split('=')[0] == "userLoggedIn") {
//       return x.split('=')[1]
//     }
//   }
//   onChange = (e) => {
//     this.setState({ [e.target.name]: e.target.value })
//   }
//   componentWillUnmount() {
//     // console.log("componentWillUnmount")
//     // this.setState({ onLoad: true })
//     // window.removeEventListener('online', this.handleConnectionChange);
//     // window.removeEventListener('offline', this.handleConnectionChange);
//     // window.removeEventListener("beforeunload", this.logoutLocal);
//   }
//   logoutLocal = (e) => {
//     e.preventDefault()
//     localStorage.clear();
//     let headers = {
//       "Content-Type": 'application/json',
//       "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem('junkStore')),
//       "applicationType": "mobile"
//     }
//     axios.get(config.apiUrl + 'auth/logout', { headers: headers })
//       .then(function (response) {
//         if (response.data.status === true) {
//           localStorage.clear();
//         }
//       })
//       .catch(function (error) {
//         // console.log("Error", error);
//       });
//   }


//   handleConnectionChange = () => {
//     const condition = navigator.onLine ? 'online' : 'offline';
//     if (condition === 'online') {
//       const webPing = setInterval(
//         () => {
//           fetch('//google.com', {
//             mode: 'no-cors',
//           })
//             .then(() => {
//               this.setState({ isDisconnected: false }, () => {
//                 return clearInterval(webPing)
//               });
//             }).catch(() => this.setState({ isDisconnected: true }))
//         }, 2000);
//       return;
//     }

//     return this.setState({ isDisconnected: true });
//   }
//   // renderContent = () => {
//   //   const { isDisconnected } = this.state;
//   //   if (isMobile) {
//   //     return (
//   //       <div> {isDisconnected ?
//   //         <center className="no-connection"> <h4>No Internet connection</h4></center>
//   //         : <div><Router>
//   //           <ScrollToTop />
//   //           <Routes />
//   //         </Router>
//   //         </div >}
//   //       </div>
//   //     )
//   //   }
//   //   return <center className="no-connection"> <h4> Please open in mobile...</h4> </center>
//   // }

//   // render() {
//   //   return this.renderContent();
//   // }
//   // componentDidMount() {
//   //   this.checkForNewVersion();
//   // }

//   // checkForNewVersion = async () => {
//   //   try {
//   //     const response = await fetch(config.userUrl + 'user/getLatestVersion');
//   //     const data = await response.json();
//   //     const isNewVersionAvailable = data.isNewVersionAvailable;
//   //     this.setState({ isNewVersionAvailable });
//   //   } catch (error) {
//   //     console.error('Error checking for new version:', error);
//   //   }
//   // };

//   // handleUpdate = () => {
//   //   window.location.reload(true);
//   // };
//   onServiceWorkerUpdate = (registration) => {
//     this.setState({
//       waitingWorker: registration && registration.waiting,
//       newVersionAvailable: true,
//     });
//   };

//   updateServiceWorker = () => {
//     const { waitingWorker } = this.state;
//     waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
//     this.setState({ newVersionAvailable: false });
//     window.location.reload();
//   };

//   refreshAction = () => { // Remove 'key' argument
//     return (
//       <>
//         <Button
//           className="snackbar-button"
//           size="small"
//           onClick={this.updateServiceWorker}
//         >
//           {"refresh"}
//         </Button>
//       </>
//     );
//   };

//   componentDidMount = () => {
//     const { enqueueSnackbar } = this.props;
//     const { newVersionAvailable } = this.state;

//     if (process.env.NODE_ENV === 'production') {
//       serviceWorker.register({ onUpdate: this.onServiceWorkerUpdate });
//     }

//     if (newVersionAvailable) // Show snackbar with refresh button
//       enqueueSnackbar("A new version was released", {
//         persist: true,
//         variant: "success",
//         action: this.refreshAction, // Use function reference directly
//       });
//   };
//   render() {
//     const { isDisconnected, isNewVersionAvailable } = this.state;
//     const width = window.innerWidth
//     return (
//       <div>{width < 500 ?
//         <div > <center id="turn">
//           <h4 className="no-connection">Works on portrait mode, please rotate your decice</h4>
//         </center>{isDisconnected ?
//           <center className="no-connection"> <h4>No Internet connection</h4></center>
//           : <div id="container">
//             {/* {isNewVersionAvailable && (
//           enqueueSnackbar("A new version was released", {
//             persist: true,
//             variant: "success",
//             action: this.handleUpdate(),
//           })
//             )} */}
//             <InstallButton />
//             <Router>
//               <ScrollToTop />
//               <Routes />
//             </Router>
//           </div >}
//         </div> : <div><Container>
//           <Row>
//             <Col md={1}></Col>
//             <Col md={5}>
//               <img src={loginlogo} style={{ marginTop: "45%" }} />
//             </Col>
//             <Col md={5} style={{ marginTop: "13%", marginLeft: "5%" }}>
//               <div className="sceem-app" > <h5><b>Sceem App</b></h5><div style={{
//                 marginTop: "15px",
//                 marginBottom: "15px",
//                 border: "solid grey",
//                 borderWidth: "0 0 4px 0px"
//               }} /></div>
//               <div>Sceem is designed as mobile friendly application. It is available on all mobile devices with Chrome or Edge browsers.  You do not have to install from any playstore. </div>
//               <p>
//                 <div className="mt-5">
//                   <div>

//                     Just use the same link (<a href="https://mob.sceem.org/" className="link-color">https://mob.sceem.org/</a>) and enjoy its rich features.</div>
//                 </div>
//               </p>

//             </Col>
//             <Col md={1}></Col>
//           </Row></Container>
//           <div className="nebutecH" ><img src={nebutech} className="nebutecH-login-img" /></div>
//         </div>}
//       </div>
//     );
//   }
// }

// export default App;

