import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row, Modal, Carousel, ProgressBar } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import back from '../images/backarrow.png';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import SimpleCrypto from "simple-crypto-js";
import './PaymentDetails.css';
import BankDetailsCalendar from './BankDetailsCalendar';
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function ConfigureFees() {
    const [showFees, setShowFees] = useState(true)
    const [agree, setAgree] = useState(false)
    const [topics, setTopics] = useState([
    ])
    const [factorValue, setFactorValue] = useState([
        { id: 1, low: 0, high: 5 },
        { id: 2, low: 0, high: 5 },
        { id: 3, low: 0, high: 5 }
    ])
    const [clickedValues, setClickedValues] = useState([]);
    const [allOfferings, setAllOfferings] = useState([])
    const [selectedOfferings, setSelectedOfferings] = useState([])
    const [currentTopicIndex, setCurrentTopicIndex] = useState(0);
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')));
    const [progressValue, setProgressValue] = useState();
    const [userDate, setUserDate] = useState()
    const [name, setName] = useState();
    const [offeringValues, setOfferingValues] = useState([]);
    const [totalValue, setTotalValue] = useState();
    const [paymentMethod, setPaymentMethod] = useState('bank');
    const [bankName, setBankName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [ifsCode, setIfsCode] = useState('');
    const [commercial, setCommercial] = useState(false)
    const [upiId, setUpiId] = useState('');
    const [pan, setPan] = useState('');
    const [selectedDate, setSelectedDate] = useState(null)
    const [userId, setUserId] = useState()
    const [showBankDetails, setShowBankDetails] = useState(false)
    const [redirect, setRedirect] = useState(false)

    useEffect(() => {
       // console.log("fees")
        multiplicationValue()
        getSmeUserData();
        getAllSmeOfferings();
        getBankDetailsBySmeId();
    }, [])
    const handleCheck = () => {
        setAgree(!agree)
    }
    const handleDateClick = (date) => {
        // const year = new Date().getFullYear()
        // console.log("date click type", year, typeof date, typeof month, date, month, day, moment(`${date}-${month}-${year}`, "DD-MM-YYYY").format("DD-MM-YYYY"));
        setSelectedDate(date);
        // setUserDate(moment(`${date}-${month}-${year}`, "DD-MM-YYYY").format("DD-MM-YYYY"))
    }
    const handleBankPrev = () => {
        setShowBankDetails(false)
        setShowFees(false)
        setCurrentTopicIndex(clickedValues.length - 1)
        getSmeUserData();
    }
    const handleOfferingValueChange = (index, percentage) => {
        const newValue = Math.min(5, Math.max(0, Math.round(percentage * 5)));

        setOfferingValues(prevOfferingValues => {
            const updatedOfferingValues = [...prevOfferingValues];

            if (!updatedOfferingValues[currentTopicIndex]) {
                updatedOfferingValues[currentTopicIndex] = {
                    index: currentTopicIndex,
                    id: [...selectedOfferings],
                    values: Array(selectedOfferings.length).fill(0)
                };
            }

            updatedOfferingValues[currentTopicIndex].values[index] = newValue;

            return updatedOfferingValues;
        });
    };
    // const handleOfferingValueChange = (index, percentage) => {
    //     const newValue = Math.min(5, Math.max(0, Math.round(percentage * 5)));

    //     const updatedOfferingValues = [...offeringValues];
    //     if (!updatedOfferingValues[currentTopicIndex]) {
    //         updatedOfferingValues[currentTopicIndex] = {
    //             index: currentTopicIndex,
    //             id: [selectedOfferings],
    //             values: [0, 0]
    //         };
    //     }
    //     updatedOfferingValues[currentTopicIndex].values[index] = newValue;
    //     setOfferingValues(updatedOfferingValues);
    // };
    // const handleNext = () => {
    //     if (currentTopicIndex < topics.length - 1) {
    //         setCurrentTopicIndex(currentTopicIndex + 1);
    //         if (clickedValues[currentTopicIndex + 1] !== null) {
    //             setProgressValue(clickedValues[currentTopicIndex + 1]);
    //         } else {
    //             setProgressValue(0);
    //         }
    //     }
    // };

    const handleNext = () => {
        if (currentTopicIndex < topics.length - 1) {
            const nextIndex = currentTopicIndex + 1;
            setCurrentTopicIndex(nextIndex);
            const nextProgressValue = clickedValues[nextIndex] !== null ? clickedValues[nextIndex] : 0;
            setProgressValue(nextProgressValue);
        }
    };
    const handlePrev = () => {
        if (currentTopicIndex > 0) {
            setCurrentTopicIndex(currentTopicIndex - 1);
            if (clickedValues[currentTopicIndex - 1] !== null) {
                setProgressValue(clickedValues[currentTopicIndex - 1]);
            } else {
                setProgressValue(topics[currentTopicIndex - 1].low);
            }
        }
    };
    // const handleProgressClick = (e) => {
    //     const rect = e.target.getBoundingClientRect();
    //     const x = e.clientX - rect.left;
    //     const percentage = (x / e.target.offsetWidth);
    //     const range = topics[currentTopicIndex].high - topics[currentTopicIndex].low;
    //     let newValue;

    //     if (percentage < 0.05) {
    //         newValue = topics[currentTopicIndex].low;
    //     } else {
    //         newValue = topics[currentTopicIndex].low + Math.ceil(percentage * range / 500) * 500;
    //     }

    //     const updatedClickedValues = [...clickedValues];
    //     updatedClickedValues[currentTopicIndex] = newValue;
    //     setProgressValue(newValue);
    //     setClickedValues(updatedClickedValues);
    //     console.log(" clicked", newValue, updatedClickedValues);
    // };
    const handleProgressClick = (e) => {
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const percentage = x / e.target.offsetWidth;
        const range = topics[currentTopicIndex].high - topics[currentTopicIndex].low;
        let newValue;

        if (percentage < 0.05) {
            newValue = topics[currentTopicIndex].low;
        } else {
            newValue = topics[currentTopicIndex].low + Math.ceil((percentage * range) / 500) * 500;
        }

        const updatedClickedValues = [...clickedValues];
        updatedClickedValues[currentTopicIndex] = newValue;
        setClickedValues(updatedClickedValues);
        setProgressValue(newValue);
      //  console.log("clicked", newValue, updatedClickedValues);
    };
    const checkCommercialValue = (callback) => {
        let isGreaterThanZero = false;

        clickedValues.forEach((clickedValue, index) => {
            if (multiplicationValue(clickedValue, offeringValues[index]?.values[0]) > 0 ||
                multiplicationValue(clickedValue, offeringValues[index]?.values[1]) > 0) {
                isGreaterThanZero = true;
            }
        });

        if (isGreaterThanZero) {
           // console.log("2");
            setCommercial(true);
            callback(true);
        } else {
           // console.log("1");
            setCommercial(false);
            callback(false);
        }
    };

    const handleSubmit = () => {
        checkCommercialValue((commercialValue) => {
           // console.log("check", commercialValue);
            const feesArr = topics.map((topic, index) => ({
                topicId: topic.id,
                fees: selectedOfferings.map((off, i) => ({
                    option: off,
                    fees: multiplicationValue(clickedValues[index], offeringValues[index]?.values[i])
                }))
            }));
            //console.log("Submit clicked", clickedValues, offeringValues, feesArr, totalPayment());
            totalPayment();
            addSmeOfferingFees(feesArr, commercialValue);
        });
    };
    const addSmeOfferingFees = (feesArr, commercial) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "userId": userId,
            "topicValues": clickedValues,
            "factorValues": offeringValues,
            "feesArr": feesArr,
            "commercial": commercial ? 2 : 1,
            "agree": false
        }
        //console.log("p", payload)
        axios.post(config.userUrl + 'user/addSmeOfferingFees', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                 //   console.log("successfully added fees", payload)
                    getBankDetailsBySmeId()
                    setShowBankDetails(true)
                } else {
                    console.log("else")
                }

            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    const getSmeUserData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getSmeUserDataById', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setName(response.data.data[0].name)
                    setUserId(response.data.data[0]?.userId)
                    setAgree(response.data.data[0].agree)
                    setShowFees(response.data.data[0].agree)
                    setTopics(response.data.topics)
                    setSelectedOfferings(response.data.data[0].offering)
                    if (response.data.data[0].topicValues.length === 0) {
                        setClickedValues(new Array(topics.length).fill(0));
                        setProgressValue(response.data.topics[currentTopicIndex].low)
                    } else {
                        setClickedValues(response.data.data[0].topicValues);
                        setProgressValue(response.data.data[0].topicValues[currentTopicIndex])
                    }
                    if (response.data.data[0].factorValues.length === 0) {
                        setOfferingValues(new Array(topics.length).fill({ index: 0, id: [response.data.data[0].offering], values: [0, 0] },
                            { index: 1, id: [response.data.data[0].offering], values: [0, 0] },
                            { index: 2, id: [response.data.data[0].offering], values: [0, 0] }));
                    } else {
                        setOfferingValues(response.data.data[0].factorValues)
                    }

                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setAllOfferings(response.data.offerings)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const multiplicationValue = (val, f) => {
        let value = f * val
        return value;
    }
    const sceemPercent = (val) => {
        if (val == 0) return 0;
        else {
            let value = val * 0.1
            return value;
        }
    }

    const totalPayment = () => {
        let total = 0;

        for (let i = 0; i < clickedValues.length; i++) {
            const clickedValue = clickedValues[i];
            const offeringValue = offeringValues[i]?.values;

            if (clickedValue !== undefined && offeringValue !== undefined) {
                let aValue = multiplicationValue(clickedValue, offeringValue[0]) - sceemPercent(multiplicationValue(clickedValue, offeringValue[0]));
                let bValue = multiplicationValue(clickedValue, offeringValue[1]) - sceemPercent(multiplicationValue(clickedValue, offeringValue[1]));
                total += aValue + bValue;
            }
        }

        setTotalValue(total);
       // console.log("Total Value:", total);
    };
    const handlePaymentMethodChange = (method) => {
        setPaymentMethod(method);
    };

    const handleSubmitBankDetails = () => {
        const errors = {};

        if (paymentMethod === 'bank') {
            if (!bankName.trim() || !/^[a-zA-Z]+$/.test(bankName.trim())) {
                errors.bankName = 'Bank Name is required';
            }
            if (!accountNumber.trim() || !/^\d{16}$/.test(accountNumber.trim())) {
                errors.accountNumber = 'Account number is required.';
            }
            if (!ifsCode.trim()) {
                errors.ifsCode = 'IFSCode is required';
            }
        } else if (paymentMethod === 'upi') {
            if (!upiId.trim()) {
                errors.upiId = 'UPI ID is required';
            }
        }

        if (Object.keys(errors).length === 0) {
          //  console.log('Form submitted successfully', accountNumber, bankName, ifsCode, upiId, userDate, pan);
            addSmeBankDetails()
        } else {
            Object.values(errors).forEach((error) => {
                enqueueSnackbar(error, { variant: 'error' });
            });
        }
    };
    const addSmeBankDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload;
        if (paymentMethod === 'bank') {
            payload = {
                "smeUserId": userId,
                "bankName": bankName ? simpleCrypto.encrypt(bankName) : "",
                "accountNumber": accountNumber ? simpleCrypto.encrypt(accountNumber) : "",
                "ifsCode": ifsCode ? simpleCrypto.encrypt(ifsCode) : "",
                "selectedDate": selectedDate,
                "paymentMethod": 1,
                "pan": pan ? simpleCrypto.encrypt(pan) : "",
            }
        } else {
            payload = {
                "smeUserId": userId,
                "upiId": upiId ? simpleCrypto.encrypt(upiId) : "",
                "selectedDate": selectedDate,
                "paymentMethod": 2,
                "pan": pan ? simpleCrypto.encrypt(pan) : "",
            }
        }
       // console.log("p", payload)
        axios.post(config.userUrl + 'user/addSmeBankDetails', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                 //   console.log("successfully added bank details", payload)
                    setRedirect(true)
                } else {
                    console.log("else")
                }

            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getBankDetailsBySmeId = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getBankDetailsBySmeId', { headers: headers })
            .then(response => {
                if (response.data.status === true && response.data.smeBankData.length !== 0) {
                    const smeBankData = response.data.smeBankData;
                    const decryptedBankData = {
                        bankName: smeBankData.bankName ? simpleCrypto.decrypt(smeBankData.bankName) : '',
                        accountNumber: smeBankData.accountNumber ? simpleCrypto.decrypt(smeBankData.accountNumber) : '',
                        ifsCode: smeBankData.ifsCode ? simpleCrypto.decrypt(smeBankData.ifsCode) : '',
                        pan: smeBankData.pan ? simpleCrypto.decrypt(smeBankData.pan) : '',
                        upiId: smeBankData.upiId ? simpleCrypto.decrypt(smeBankData.upiId) : '',
                        selectedDate: parseInt(smeBankData.selectedDate),
                        paymentMethod: smeBankData.paymentMethod
                    };

                    if (decryptedBankData.paymentMethod === 1) {
                        setPaymentMethod('bank');
                    } else {
                        setPaymentMethod('upi');
                    }
                    setBankName(decryptedBankData.bankName);
                    setAccountNumber(decryptedBankData.accountNumber);
                    setIfsCode(decryptedBankData.ifsCode);
                    setUpiId(decryptedBankData.upiId);
                    setPan(decryptedBankData.pan);
                    setSelectedDate(decryptedBankData.selectedDate);
                } else {
                    console.error("Error: No bank data found or status is false");
                }
            })
            .catch(error => {
                console.error("Error fetching bank details", error);
            });
    }

    return (
        <div>
            {redirect && <Redirect to="/home" />}
            {showFees ? <div>
                <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                <div style={{ marginTop: "2.4rem" }}>
                    <div className="your-jitbits-fees">Configure Fees</div>
                    <div className='ml-3 mr-3'>
                        <div className='mt-3 p-fee'>Platform Fees:</div>
                        <div className='p-fee-text-sm'>Sceem platform changes 10% fees for all commercial engagement services provided by me to the clients.
                            This fees is non-refundable.</div>
                        <div className='mt-3 p-fee'>Settlement:</div>
                        <div className='p-fee-text-sm'>Sceem provides me a transparent system to understand all scheduled meetings with financials (free, paid, refunded) along with
                            breakdown on monthly basis. Bulk payment is done monthly once on the date selected by myself to account registered</div>
                        <div className='mt-2 p-fee-text-sm'>In case of any Income Tax rules, I shall provide PAN linked to registered bank account.
                        </div>
                        <div className='mt-3 p-fee'>My Fees for services offered:
                        </div>
                        <div className='p-fee-text-sm'>Based on the given details, I agree to configure my fees for each of my service and will be changing to my own when I feel necessary.
                        </div>
                        <div className='mt-2 p-fee-text-sm'>I agree for Sceem platform to change configured by myself for my services. I also agree for Sceem platform to refund money directly to the client if in case, due to any reason , the scheduled meeting at agreed time did not take place.
                        </div>

                    </div>
                    <div className="mt-3 ml-3 mr-1" ><input type="checkbox" checked={agree} style={{ accentColor: "#31197C" }} onClick={handleCheck} /> &nbsp;<span style={{ fontWeight: "700", fontSize: "13.5px" }}>I agree</span></div><br /><br />
                    <center>
                        {agree ?
                            <Button variant="default" onClick={() => setShowFees(!showFees)} className="_rolebtn_new">Proceed</Button>
                            : <Button variant="default" disabled="true" className="_rolebtn_new">Proceed</Button>}
                    </center>
                </div>
                <BottomMenu />
            </div>
                :
                <div>
                    {showBankDetails ?
                        <div>
                            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                            <div style={{ marginTop: "2.5rem" }}>
                                <div style={{ display: "flex", height: "35px", width: "auto", color: "#ff942c", fontWeight: 600, marginLeft: "20px" }}>
                                    <div style={{ width: "30px" }}>
                                        <div onClick={handleBankPrev}>
                                            <img src={back} style={{ width: "30px" }} />
                                        </div>
                                    </div>
                                    <div className="ml-1 mt-2">Bank Details</div>
                                </div>
                                <div className='payment-details-container'>
                                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>Name: {name}</div>
                                        <div>Amount: Rs.{totalValue}</div>
                                    </div> */}
                                    <div className="mt-2 payment-methods">
                                        <div
                                            className={paymentMethod === 'bank' ? 'active' : ''}
                                            onClick={() => handlePaymentMethodChange('bank')}
                                        >
                                            Bank Account
                                        </div>
                                        <div
                                            className={paymentMethod === 'upi' ? 'active' : ''}
                                            onClick={() => handlePaymentMethodChange('upi')}
                                        >
                                            UPI
                                        </div>
                                    </div>
                                    <div className="mt-3 payment-form">
                                        <div style={{ fontWeight: 600, fontSize: "14px" }}>Account Details</div>
                                        {paymentMethod === 'bank' && (
                                            <div style={{ height: "24vh" }}>
                                                <input
                                                    type="text"
                                                    placeholder="Account Number"
                                                    value={accountNumber}
                                                    onChange={(e) => setAccountNumber(e.target.value)}
                                                />
                                                <input
                                                    type="text"
                                                    placeholder="Bank Name"
                                                    value={bankName}
                                                    onChange={(e) => setBankName(e.target.value)}
                                                />
                                                <input
                                                    type="text"
                                                    placeholder="IFSCode"
                                                    value={ifsCode}
                                                    onChange={(e) => setIfsCode(e.target.value)}
                                                />
                                            </div>
                                        )}
                                        {paymentMethod === 'upi' && (
                                            <div style={{ height: "24vh" }}>
                                                <input
                                                    type="text"
                                                    placeholder="UPI ID"
                                                    value={upiId}
                                                    onChange={(e) => setUpiId(e.target.value)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div style={{ fontWeight: 600, fontSize: "14px" }}>Sceem Settlement Date</div>
                                    <BankDetailsCalendar selectedDate={selectedDate} handleDateClick={handleDateClick} />
                                    <div className="mt-3 payment-form">
                                        <div style={{ fontWeight: 600, fontSize: "14px" }}>PAN for TDS</div>
                                        <input
                                            type="text"
                                            placeholder="Must be linked to above number"
                                            value={pan}
                                            disabled={true}
                                            onChange={(e) => setPan(e.target.value)}
                                        />
                                    </div>

                                    <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div></div>
                                        <Button variant="default"
                                        disabled={paymentMethod === 'upi'? !upiId || !selectedDate: !accountNumber || !ifsCode || !bankName || !selectedDate}
                                        className="mt-1 payment-submit-button" onClick={handleSubmitBankDetails}>Register</Button>
                                    </div>
                                </div>
                            </div>
                            <BottomMenu />
                        </div>
                        : <div>
                            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                            <div style={{ marginTop: "2.5rem" }}>
                                <div style={{ display: "flex", height: "35px", width: "auto", color: "#ff942c", fontWeight: 600, marginLeft: "20px" }}>
                                    <div style={{ width: "30px" }}> {/* Set a fixed width */}
                                        <div onClick={handlePrev}>
                                            <img src={back} style={showFees === false && currentTopicIndex === 0 ? { display: "none", width: "30px" } : { display: "block", width: "30px" }} />
                                        </div>
                                    </div>
                                    <div className="ml-1 mt-2">Fees Calculator</div>
                                </div>
                                <div className="mt-2 sme-fee-box-b ml-2 mr-2" >
                                    <div style={{ marginTop: "1.4rem", display: 'flex', justifyContent: 'space-around', borderRadius: "10px", border: "1px solid gray", padding: "10px 8px" }}>
                                        <div style={{ width: "30%" }}>
                                            <img src={"../" + topics[currentTopicIndex]?.topicName + ".png"} style={{ width: "30px" }} alt={topics[currentTopicIndex]?.topicName} /><br />
                                            <div style={{ width: "5rem", fontSize: "13px", fontFamily: "Noto Sans" }}>
                                                {topics[currentTopicIndex]?.topicName}
                                            </div>
                                        </div>
                                        <div style={{ width: "70%", cursor: "pointer", position: "relative" }}>
                                            <center style={{ fontSize: "14px" }}>
                                                {clickedValues[currentTopicIndex] !== null ? clickedValues[currentTopicIndex] : "0"}
                                            </center>
                                            <ProgressBar
                                                now={progressValue}
                                                className="custom-progress-bar"
                                                onClick={handleProgressClick}
                                                max={topics[currentTopicIndex]?.high}
                                            />
                                            <div style={{ position: "absolute", display: 'flex', justifyContent: 'space-between', fontSize: "14px", width: "100%" }}>
                                                <span>{topics[currentTopicIndex]?.low}</span>
                                                <span>Fee Range</span>
                                                <span>{topics[currentTopicIndex]?.high}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {allOfferings
                                        .filter(data => selectedOfferings.includes(data.id))
                                        .map((data, i) => (
                                            <div key={i} style={{ marginBottom: "10px", marginTop: "10px", display: 'flex', justifyContent: 'space-around', borderRadius: "10px", border: "1px solid gray", padding: "10px 8px" }}>
                                                <div style={{ width: "30%" }}>
                                                    <img src={"../" + data.text + ".svg"} alt={data.text} /><br />
                                                    <div className="sme-off-text">{data.text}</div>
                                                </div>
                                                <div style={{ width: "70%", cursor: "pointer", position: "relative" }}>
                                                    <center style={{ fontSize: "14px" }}>
                                                        {offeringValues[currentTopicIndex]?.values[i] != null ? offeringValues[currentTopicIndex].values[i] : "0"}
                                                    </center>
                                                    <ProgressBar
                                                        now={offeringValues[currentTopicIndex]?.values[i] || 0}
                                                        className="custom-progress-bar"
                                                        onClick={(e) => handleOfferingValueChange(i, (e.clientX - e.target.getBoundingClientRect().left) / e.target.offsetWidth)}
                                                        max={factorValue[currentTopicIndex]?.high}
                                                    />
                                                    <div style={{ position: "absolute", display: 'flex', justifyContent: 'space-between', fontSize: "14px", width: "100%" }}>
                                                        <span>{factorValue[currentTopicIndex]?.low}</span>
                                                        <span>Factor</span>
                                                        <span>{factorValue[currentTopicIndex]?.high}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    {offeringValues[currentTopicIndex]?.values[0] >= 0 ?
                                        <div style={{ marginTop: "3rem", border: "1px solid gray", borderRadius: "10px", padding: "5px" }}>
                                            <center>
                                                <center style={{ fontSize: "16px", fontWeight: 600 }}>Your Fees</center>
                                                <table class="table-striped" >
                                                    <thead style={{ background: "#fff", fontSize: "14px" }}>
                                                        <tr  >
                                                            <th scope="col" style={{ width: "25%" }}>Offering</th>
                                                            <th scope="col" style={{ width: "20%", textAlign: "center" }}>Display</th>
                                                            <th scope="col" style={{ width: "35%", textAlign: "center" }}>Sceem Fees</th>
                                                            <th scope="col" style={{ width: "20%", textAlign: "center" }}>Payment</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ background: "white", fontSize: "12px" }}>
                                                        <tr style={{ background: "white" }}>
                                                            {allOfferings.filter(data => selectedOfferings.includes(data.id)).slice(0, 1).map((data, i) => (
                                                                <td className="td-text">{data.text}</td>
                                                            ))}
                                                            <td className="td-text" style={{ textAlign: "center" }}>{multiplicationValue(clickedValues[currentTopicIndex], offeringValues[currentTopicIndex]?.values[0])}</td>
                                                            <td className="td-text" style={{ textAlign: "center" }}>{sceemPercent(multiplicationValue(clickedValues[currentTopicIndex], offeringValues[currentTopicIndex]?.values[0]))}</td>
                                                            <td className="td-text" style={{ textAlign: "center" }}> {multiplicationValue(clickedValues[currentTopicIndex], offeringValues[currentTopicIndex]?.values[0]) - sceemPercent(multiplicationValue(clickedValues[currentTopicIndex], offeringValues[currentTopicIndex]?.values[0]))}</td>
                                                        </tr>
                                                        <tr style={{ background: "white" }}>
                                                            {allOfferings.filter(data => selectedOfferings.includes(data.id)).slice(1, 2).map((data, i) => (
                                                                <td className="td-text">{data.text}</td>
                                                            ))}
                                                            <td className="td-text" style={{ textAlign: "center" }}>{multiplicationValue(clickedValues[currentTopicIndex], offeringValues[currentTopicIndex]?.values[1])}</td>
                                                            <td className="td-text" style={{ textAlign: "center" }}>{sceemPercent(multiplicationValue(clickedValues[currentTopicIndex], offeringValues[currentTopicIndex]?.values[1]))}</td>
                                                            <td className="td-text" style={{ textAlign: "center" }}>{multiplicationValue(clickedValues[currentTopicIndex], offeringValues[currentTopicIndex]?.values[1]) - sceemPercent(multiplicationValue(clickedValues[currentTopicIndex], offeringValues[currentTopicIndex]?.values[1]))}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </center>
                                        </div> : <></>}

                                    {currentTopicIndex === 0 && (
                                        <div>
                                            <Button variant="default" className='fees-prev-btn' style={{ display: "none" }}>Previous</Button>
                                            <Button variant="default" disabled={offeringValues[currentTopicIndex]?.values[1] >= 0 ? false : true} className='fees-next-btn' onClick={handleNext}>Next</Button>
                                        </div>
                                    )}
                                    {currentTopicIndex > 0 && currentTopicIndex < topics.length - 1 && (
                                        <div>
                                            <Button variant="default" className='fees-prev-btn' onClick={handlePrev}>Previous</Button>
                                            <Button variant="default" disabled={offeringValues[currentTopicIndex]?.values[1] >= 0 ? false : true} className='fees-next-btn' onClick={handleNext}>Next</Button>
                                        </div>
                                    )}
                                    {currentTopicIndex === topics.length - 1 && (
                                        <div>
                                            <Button variant="default" className='fees-prev-btn' onClick={handlePrev}>Previous</Button>
                                            <Button variant="default" disabled={offeringValues[currentTopicIndex]?.values[1] >= 0 ? false : true} className='fees-next-btn' onClick={handleSubmit}>Submit</Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <BottomMenu />
                        </div>}
                </div>
            }
        </div >
    )
}
