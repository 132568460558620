import React, { Component } from "react";
import { Button, Form, Col, Card, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import back from '../images/backarrow.png';
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validEmailRegex =
    /^[a-z0-9+_.-]+@[a-z0-9.-]+$/
const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/
const validPhoneNoRegex = RegExp(
    /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/i
);
// const validateForm = errors => {
//     let valid = true;
//     Object.values(errors).forEach(val => val.length > 0 && (valid = false));
//     return valid;
// };
export default class RequestForAdvertisement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            typeOfAdvertisement: "",
            timesOfAdvertisement: "",
            description: "",
            paymentType: "",
            startDate: "",
            campaignName: "",
            name: "",
            phoneNumber: "",
            email: "",
            typeOfAdvertisementError: '',
            timesOfAdvertisementError: '',
            descriptionError: '',
            paymentTypeError: '',
            startDateError: '',
            campaignNameError: "",
            nameError: "",
            phoneNumberError: "",
            emailError: "",
            home: false,
            error: "Please select the details"
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.validateTimesOfAdvertisement = this.validateTimesOfAdvertisement.bind(this);
        this.validateDescription = this.validateDescription.bind(this);
        this.validatePaymentType = this.validatePaymentType.bind(this);
        this.validateStartDate = this.validateStartDate.bind(this);
        this.validateCampaignName = this.validateCampaignName.bind(this);
        this.validateName = this.validateName.bind(this);
        this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validateField = this.validateField.bind(this);
    }
    onChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }
    // componentDidMount() {
    //     this.getOpportunityDetails();
    // }

    handleBlur(event) {
        const { name } = event.target;
        this.validateField(name);
        return;
    }

    handleSubmit(event) {
        event.preventDefault();
       // console.log(this.state);
        let formFileds = [
            "typeOfAdvertisement",
            "timesOfAdvertisement",
            "description",
            "paymentType",
            "startDate",
            "campaignName",
            "name",
            "phoneNumber",
            "email",
        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = this.validateField(field) && isValid;
        });
        this.saveAdvertisementDetails()
    }
    saveAdvertisementDetails = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "type": this.state.typeOfAdvertisement,
            "timesOfAdvertisement": this.state.timesOfAdvertisement,
            "advertiserPaymentType": this.state.paymentType,
            "description": this.state.description,
            "startDate": this.state.startDate,
            "campaignName": this.state.campaignName,
            "advertiserName": this.state.name,
            "advertiserNumber": this.state.phoneNumber,
            "advertiserEmail": this.state.email
        }
        // console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/requestForAdvertisementByUser', payload, { headers: headers })
            .then(function (response) {
               // console.log("response", response)
                if (response.data.status === true) {
                 //   console.log(response, "requestForAdvertisement created successfully!!")
                    self.setState({ home: true })
                }
            })
            .catch(function (error) {
                console.log("error", payload,error)
                document.getElementById('error').innerText = error
            });
    }
    validateField(name) {
        let isValid = false;
        if (name === "typeOfAdvertisement") isValid = this.validateTypeOfAdvertisement();
        else if (name === "timesOfAdvertisement") isValid = this.validateTimesOfAdvertisement();
        else if (name === "description") isValid = this.validateDescription();
        else if (name === "paymentType") isValid = this.validatePaymentType();
        else if (name === "startDate") isValid = this.validateStartDate();
        else if (name === "campaignName") isValid = this.validateCampaignName();
        else if (name === "name") isValid = this.validateName();
        else if (name === "phoneNumber") isValid = this.validatePhoneNumber();
        else if (name === "email") isValid = this.validateEmail();
        return isValid;
    }

    validateTypeOfAdvertisement() {
        let typeOfAdvertisementError = "";
        const value = this.state.typeOfAdvertisement;
        if (value.trim() === "") typeOfAdvertisementError = "select type of Advertisement";
        this.setState({
            typeOfAdvertisementError
        });
        return typeOfAdvertisementError === "";
    }
    validateTimesOfAdvertisement() {
        let timesOfAdvertisementError = "";
        const value = this.state.timesOfAdvertisement;
        if (value.trim() === "") timesOfAdvertisementError = "Please add Times Of Advertisement";
        this.setState({
            timesOfAdvertisementError
        });
        return timesOfAdvertisementError === "";
    }

    validateDescription() {
        let descriptionError = "";
        const value = this.state.description;
        if (value.trim() === "") descriptionError = "Please add Description";
        this.setState({
            descriptionError
        });
        return descriptionError === "";
    }
    validatePaymentType() {
        let paymentTypeError = "";
        const value = this.state.paymentType;
        if (value.trim === "") paymentTypeError = "Please select payment type";
        this.setState({
            paymentTypeError
        });
        return paymentTypeError === "";
    }
    validateStartDate() {
        let startDateError = "";
        const value = this.state.startDate;
        if (value.trim === null) startDateError = "Please enter valid date";
        this.setState({
            startDateError
        });
        return startDateError === "";
    }
    validateCampaignName() {
        let campaignNameError = "";
        const value = this.state.campaignName;
        if (value.trim === null) campaignNameError = "Please enter Campaign Name";
        this.setState({
            campaignNameError
        });
        return campaignNameError === "";
    }
    validateName() {
        let nameError = "";
        const value = this.state.name;
        if (value.trim() === "") nameError = "Please add Name";
        this.setState({
            nameError
        });
        return nameError === "";
    }
    validatePhoneNumber() {
        let phoneNumberError = "";
        const value = this.state.phoneNumber;
        if (value.trim() === "") phoneNumberError = "Please add Phone Number";
        this.setState({
            phoneNumberError
        });
        return phoneNumberError === "";
    }
    validateEmail() {
        let emailError = "";
        const value = this.state.email;
        if (value.trim() === "") emailError = "Please add email";
        this.setState({
            emailError
        });
        return emailError === "";
    }
    render() {
        const { home } = this.state
        if (home) {
            return <Redirect to="/home" />
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
                <Form onSubmit={this.handleSubmit} style={{ marginTop: "5rem" }}>
                         <Link to="/home"><img alt="" src={back} className="back_arrow_" /></Link><div className="your-fav ml-5" style={{position:"fixed",top:"3.4vh"}}> <div className="screen-topgap ml-4">Request for Advertisement</div></div>
                    <div className="card-opp-top"></div>
                    <div style={{ marginLeft: "5%", marginRight: "5%", width: "90%" }} >
                        <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                            <div className="ml-1 form-group mt-3 mr-1">
                                <input type="text"
                                    className="form-control"
                                    style={{ resize: "none", fontSize: "12px" }}
                                    value={this.state.campaignName}
                                    name="campaignName"
                                    placeholder="Campaign Name"
                                    onChange={this.onChange}
                                    onBlur={this.handleBlur}
                                />
                                <div className="div">{this.state.campaignNameError && (
                                    <div className="error">{this.state.campaignNameError}</div>
                                )}</div>
                            </div>
                            <div className="form-group mt-2">
                                <div className="ml-1 form-check form-check-inline mt-2 form_check" style={{ display: "flex" }}>
                                    <input name="typeOfAdvertisement" className="form-check" type="radio" value="commercial" checked={this.state.typeOfAdvertisement === "commercial"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        Commercial
                                    </label>
                                    <input name="typeOfAdvertisement" className="ml-3  form-check" type="radio" value="non-commercial" checked={this.state.typeOfAdvertisement === "non-commercial"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        Non-commercial
                                    </label>
                                </div>
                                <div className="ml-1 form-check form-check-inline form_check mt-2" style={{ display: "flex" }}>
                                    <input name="timesOfAdvertisement" className="form-check" type="radio" value="3" checked={this.state.timesOfAdvertisement === "3"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        3 Mon
                                    </label>
                                    <input name="timesOfAdvertisement" className="ml-2 form-check" type="radio" value="6" checked={this.state.timesOfAdvertisement === "6"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        6 Mon
                                    </label>
                                    <input name="timesOfAdvertisement" className="ml-2 form-check" type="radio" value="9" checked={this.state.timesOfAdvertisement === "9"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        9 Mon
                                    </label>
                                    <input name="timesOfAdvertisement" className="ml-2 form-check" type="radio" value="12" checked={this.state.timesOfAdvertisement === "12"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        12 Mon
                                    </label>
                                </div>
                                <div className="ml-1 form-check form-check-inline form_check mt-2" style={{ display: "flex" }}>
                                    <input name="paymentType" className="form-check" type="radio" value="free" checked={this.state.paymentType === "free"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        Free
                                    </label>
                                    <input name="paymentType" className="ml-3 form-check" type="radio" value="paid" checked={this.state.paymentType === "paid"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        Paid
                                    </label>
                                </div>
                                {((this.state.typeOfAdvertisement.length === null) || (this.state.paymentType.length === 0) || (this.state.timesOfAdvertisement.length === 0)) ?
                                    <div className="div  ml-1 error">{this.state.error}</div> : <div></div>}
                                <div className="form-group" style={{width:"83%"}}>
                                    <DatePicker
                                        className="ml-1 form-control form-bg-opp-date"
                                        dateFormat="dd/MM/yyyy"
                                        selected={this.state.startDate}
                                        minDate={new Date()}
                                        onChange={(date) => this.setState({ startDate: date })}
                                        placeholderText="Start date"
                                    />
                                    <div className="div">{this.state.startDateError && (
                                        <div className="error">{this.state.startDateError}</div>
                                    )}</div>
                                </div>
                                <div className="ml-1 form-group mt-3 mr-1">
                                    <textarea rows={2} type="text"
                                        className="form-control"
                                        style={{ resize: "none", fontSize: "12px" }}
                                        value={this.state.description}
                                        name="description"
                                        placeholder="Description"
                                        onChange={this.onChange}
                                        onBlur={this.handleBlur}
                                    />
                                    <div className="div">{this.state.descriptionError && (
                                        <div className="error">{this.state.descriptionError}</div>
                                    )}</div>
                                </div>
                            </div>
                        </div>
                        </Card >
                        <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                            <Row style={{ width: "100%" }}>
                                <Col xs={6}>
                                    <div className="ml-1 form-group mt-2">
                                        <input type="text"
                                            style={{ width: "118%" }}
                                            className="form-control form-bg-opp"
                                            aria-describedby="text"
                                            value={this.state.name}
                                            name="name"
                                            placeholder="Name"
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className="form-group mt-2">
                                        <input type="number"
                                            style={{ width: "118%" }}
                                            className="ml-1 form-control form-bg-opp"
                                            aria-describedby="text"
                                            value={this.state.phoneNumber}
                                            name="phoneNumber"
                                            placeholder="Contact person phone"
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="ml-1 form-group mt-3 mr-1">
                                <input type="text"
                                    className="form-control form-bg-opp"
                                    aria-describedby="text"
                                    value={this.state.email}
                                    name="email"
                                    placeholder="Email"
                                    onChange={this.onChange}
                                    onBlur={this.handleBlur}
                                />
                            </div>
                        </div>
                        </Card>

                    </div >
                    {/* <div className="ml-4"><input type="checkbox" checked={this.state.checkboxToshowDetails} onClick={this.handleCheck} /> <span className="signup-agree-text"> &nbsp;Show contact details</span></div> */}
                    <center>
                        {/* <div id="status" className="errormsg"></div> */}
                        {/* <div id="error" className="errormsg"></div> */}
                        <Button variant="default" type="submit" className="btn-block opportunity-btn mt-4">Raise a Request</Button><br />
                    </center>
                </Form >
            </div >
        );
    }
}
