import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import back from '../images/backarrow.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: localStorage.getItem('name'),
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            ageGroup: "",
            experience: "",
            company: "",
            city: "",
            description: ""
        }
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount = () => {
        this.getUserProfile();
    }
    onChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }
    handleBack = () => {
        this.props.history.goBack()
    }
    handleSubmit(event) {
        event.preventDefault();
        this.updateUserDetails();
    }
    getUserProfile = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getProfile', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                   // console.log("success");
                    let user = response.data.user;
                    self.setState(
                        {
                            name: user.name,
                            ageGroup: user.ageGroup,
                            experience: user.experience,
                            company: user.company,
                            city: user.city,
                            description: user.description
                        }
                    );
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    }
    updateUserDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "ageGroup": this.state.ageGroup,
            "experience": this.state.experience,
            "company": this.state.company,
            "city": this.state.city,
            "description": this.state.description
        }
        axios.post(config.userUrl + 'user/editProfile', payload, { headers: headers })
            .then(function (response) {
               //console.log(response);
                if (response.data.status === true) {
                    //console.log("success", payload);
                    document.getElementById('editprofile').style.display = 'flex'
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <Link to="/home"><img alt="" src={back} className="back_arrow" /></Link>
                <p className="terms-heading">Profile</p>
                <Form style={{ width: "84%", marginLeft: "auto", marginRight: "auto" }} onSubmit={this.handleSubmit}>
                    <Form.Group className="mb-3" >
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" className="form-edit" placeholder={this.state.name} readOnly />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Age Group</Form.Label>
                        <select name="ageGroup" value={this.state.ageGroup} onChange={this.onChange} class="form-control form-edit">
                            <option value="18-25">18-25</option>
                            <option value="25-35">25-35</option>
                            <option value="35-55">35-55</option>
                            <option value="55+">55+</option>
                        </select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Company</Form.Label>
                        <Form.Control type="text" name="company" className="form-edit" onChange={this.onChange} value={this.state.company} placeholder="Company" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Experience</Form.Label>
                        <select name="experience" value={this.state.experience} onChange={this.onChange} class="form-control form-edit">
                            <option value="0-5">0-5</option>
                            <option value="5-10">5-10</option>
                            <option value="10-15">10-15</option>
                            <option value="15-20">15-20</option>
                            <option value="20+">20+</option>
                        </select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control type="text" name="description" className="form-edit" onChange={this.onChange} value={this.state.description} placeholder="description" />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" name="city" className="form-edit" onChange={this.onChange} value={this.state.city} placeholder="Bangalore" />
                    </Form.Group>
                    <center>
                        <Button type="submit" variant="default" className="btn-block save-changes-btn">Save Changes</Button>
                        <div id="editprofile" className="modal-pop">
                            <form className="modal-content-log">
                                <div className="_container">
                                    <p className="mt-2">Profile updated successfully!!</p>
                                    <center>
                                        <Link to="/home"><Button variant="default" className="deletebutton">Ok</Button></Link></center>
                                </div>
                            </form>
                        </div>
                    </center>
                </Form>
                <br /><br /><br />
                <BottomMenu />
            </div >
        )
    }
}
