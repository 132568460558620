import React, { Component } from "react";
import { Button, Nav, Form } from "react-bootstrap";
import { Link, NavLink, Redirect } from 'react-router-dom';
import homeicon from '../images/homeicon.png';
import articleicon from '../images/articleicon.png';
import bookmarks from '../images/bookmarkicon.png';
import menuicon from '../images/menuicon.png';
import usericon from '../images/user.png';
import searchicon from '../images/search.png';
import scm from '../images/Scm networking.png';
import poll from '../images/Poll.png';
import pricing from '../images/pricing.png';
import membership from '../images/membership.svg';
import advertisementmenu from '../images/advertisementmenu.svg';
import registersme from '../images/register SME.svg';
import appointments from '../images/appointments.svg';
import referral from '../images/referral.svg';
import preference from '../images/Preference.png';
import settings from '../images/settings.png';
import logout from '../images/logout.png';
import logout1 from '../images/logout1.png';
import downarrow from '../images/downarrow.png';
import rightarrow from '../images/rightarrow.png';
import line from '../images/line.png';
import qna from '../images/QnA.svg';
import sceemlore from '../images/sceemlore-logo.svg';
import opportunity from '../images/opportunity.png';
import newmembers from '../images/new member.png';
import line1 from '../images/line2.png';
import line2 from '../images/line1.png';
import bug from '../images/bug.svg';
import './BottomMenu.css';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/
export default class BottomMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navwidth: 0,
            user: false,
            qa: false,
            showSceemSme: false,
            group: false,
            opportunity: false,
            advertisement: false,
            incident: false,
            referral: false,
            profile: false,
            toggleUser: false,
            scm: false,
            showModal: false,
            password: false,
            oldPassword: "",
            newPassword: "",
            log: false,
            redirectToLogin: false,
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            email: localStorage.getItem('email'),
            role: localStorage.getItem('role'),
            userRole: "",
            profilePicture: "",
            name: "",
            ageGroup: "",
            company: "",
            experience: "",
            city: "",
            gender: "",
            author: "",
            features: [],
            planType: "",
            membership: localStorage.getItem("planType"),
            reviewer: "",
            sme: "",
            disable: false,
            oldPasswordError: "",
            newPasswordError: "",
            userStatus: localStorage.getItem('userStatus'),
        }
        this.showAdd = this.showAdd.bind(this);
        this.showLogout = this.showLogout.bind(this);
        this.logoutFromApp = this.logoutFromApp.bind(this);
        this.postExpressInterest = this.postExpressInterest.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.validateOldPassword = this.validateOldPassword.bind(this);
        this.validateNewPassword = this.validateNewPassword.bind(this);
        this.validateField = this.validateField.bind(this);
    }
    componentDidMount = () => {
        this.checkUserStatus()
        this.getMenuItems()
    }
    handleBlur(event) {
        const { name } = event.target;
        this.validateField(name);
        return;
    }

    handleSubmit(event) {
        event.preventDefault();
        let formFileds = [
            "oldPassword",
            "newPassword"

        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = this.validateField(field) && isValid;
        });
        if (!isValid) {
            // console.log("Please enter the details")
            document.getElementById('status').innerHTML = "<div>Please enter valid details</div>"
        } else {
            this.changePassword()
        }
    }
    validateField(name) {
        let isValid = false;
        if (name === "oldPassword") isValid = this.validateOldPassword();
        else if (name === "newPassword") isValid = this.validateNewPassword();
        return isValid;
    }
    validateOldPassword() {
        let oldPasswordError = "";
        const value = this.state.oldPassword;
        if (value.trim === "") oldPasswordError = "Old Password is required";
        else if (!validPasswordRegex.test(value))
            oldPasswordError = "Min 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        this.setState({
            oldPasswordError
        });
        return oldPasswordError === "";
    }

    validateNewPassword() {
        let newPasswordError = "";
        const value = this.state.newPassword;
        if (value.trim === "") newPasswordError = "New Password is required";
        else if (!validPasswordRegex.test(value))
            newPasswordError = "Min 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        this.setState({
            newPasswordError
        });
        return newPasswordError === "";
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    // openNav = () => {
    //     document.getElementById("mySidenav").style.width = "330px";
    // }
    // closeNav = () => {
    //     document.getElementById("mySidenav").style.width = "0";
    // }
    openNav = () => {
        this.setState({
            navwidth: '330px'
        });
        this.getUserProfile()
    }

    closeNav = () => {
        this.setState({
            navwidth: 0
        });
    }
    closeMenu = (e) => {
        if (e.target.tagName === "div") {
            document.getElementById("mySidenav").style.width = "0";
        }
    }
    showUser = () => {
        this.setState({ user: !this.state.user })
    }
    showQA = () => {
        this.setState({ qa: !this.state.qa })
    }
    showSME = () => {
        this.setState({ showSceemSme: !this.state.showSceemSme })
    }
    showOpportunity = () => {
        this.setState({ opportunity: !this.state.opportunity })
    }
    showAdvertisement = () => {
        this.setState({ advertisement: !this.state.advertisement })
    }
    showReferral = () => {
        this.setState({ referral: !this.state.referral })
    }
    showGroup = () => {
        this.setState({ group: !this.state.group })
    }
    showProfile = () => {
        this.setState({ profile: !this.state.profile })
    }
    showIncident = () => {
        this.setState({ incident: !this.state.incident })
    }
    showToggleUser = () => {
        this.setState({ toggleUser: !this.state.toggleUser })
    }
    showScm = () => {
        this.setState({ scm: !this.state.scm })
    }
    showPassword = () => {
        this.setState({ password: !this.state.password })
    }
    showAdd = () => {
        document.getElementById('myBtn').style.display = 'flex'
    }
    showNone = () => {
        document.getElementById('myBtn').style.display = 'none'
    }
    addSubscribtion = () => {
        document.getElementById('subscribe').style.display = 'flex'
    }
    showNothing = () => {
        document.getElementById('subscribe').style.display = 'none'
    }
    showLogout = () => {
        document.getElementById('logout').style.display = 'flex'
    }
    showLogoutNone = () => {
        document.getElementById('logout').style.display = 'none'
    }
    close = () => {
        document.getElementById(".Modal").style.display = "none"
    }
    // optForReviewer = () => {
    //     document.getElementById('reviewer').style.display = 'flex'
    // }
    // showReviewerExpressInterestNone = () => {
    //     document.getElementById('reviewer').style.display = 'none'
    // }
    // optForAuthor = () => {
    //     document.getElementById('author').style.display = 'flex'
    // }
    // showAuthorExpressInterestNone = () => {
    //     document.getElementById('author').style.display = 'none'
    // }
    getMenuItems = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getMenuItems', { headers: headers })
            .then(function (response) {
                //console.log("menu", response);
                if (response.data.status === true) {
                    self.setState({
                        features: response.data.menuItems[0].features,
                        planType: response.data.menuItems[0].planId
                    })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    postExpressInterest = (e, type) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "interestType": type
        }
        var self = this
        axios.post(config.userUrl + 'user/expressInterest', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    self.checkUserStatus()
                    self.showReviewerExpressInterestNone()
                    self.showAuthorExpressInterestNone()
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    checkUserStatus = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/checkUserStatus', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    if (response.data.userStatus === "approvedForAuthor") {
                        self.setState({ author: "Approved", disable: true })
                        //  document.getElementById('true').innerHTML = '<i class="fa fa-check" id="true" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "approvedForReviewer") {
                        self.setState({ reviewer: "Approved", disable: true })
                        //  document.getElementById('false').innerHTML = '<i class="fa fa-check" id="false" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "approvedForSme") {
                        self.setState({ sme: "Approved", disable: true })
                        //  document.getElementById('true').innerHTML = '<i class="fa fa-check" id="true" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "authorStatusPending") {
                        self.setState({ author: "Pending", disable: true })
                        // document.getElementById('pendingAuth').innerHTML = '<i class="fa fa-check" id="pendingAuth" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "reviewerStatusPending") {
                        self.setState({ reviewer: "Pending", disable: true })
                        //  document.getElementById('pendingReviewer').innerHTML = '<i class="fa fa-check" id="pendingReviewer" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "smeStatusPending") {
                        self.setState({ sme: "Pending", disable: true })
                        //  document.getElementById('pendingAuth').innerHTML = '<i class="fa fa-check" id="pendingAuth" aria-hidden="true"></i>'
                    } else {
                        document.getElementById('status').innerHTML = '<div>{response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)}</div>'
                    }
                } else {
                    // console.log("checkerror", response.data.message)
                    document.getElementById('status').innerText = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    getUserProfile = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getProfile', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    let user = response.data.user;
                    self.setState(
                        {
                            name: user.name,
                            ageGroup: user.ageGroup,
                            experience: user.experience,
                            company: user.company,
                            city: user.city,
                            gender: user.gender
                        }
                    );
                    if ((user.profilePicture === null) && (user.gender === "0")) {
                        self.setState({
                            profilePicture: "uploads/1652942537335Avatar%20Users2_37.png",
                        })
                    } else if ((user.profilePicture === null) && (user.gender === "1")) {
                        self.setState({
                            profilePicture: "uploads/1652942450250Avatar Users2_31.png",
                        })
                    } else {
                        self.setState({
                            profilePicture: user.profilePicture,
                        })
                    }
                    if (self.state.role === "generalUser") {
                        // console.log("general user")
                        self.setState({ userRole: "User" })
                    } else {
                        //  console.log("author user")
                        const data = self.state.role.charAt(0).toUpperCase() + self.state.role.slice(1)
                        self.setState({ userRole: data })
                    }
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    componentDidUpdate() {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, window.location.href);
        });
    }
    logoutLocal = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.apiUrl + 'auth/logout', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    localStorage.clear();
                    self.clearAllCookies();
                    self.setState(
                        {
                            log: true
                        })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    clearAllCookies = () => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        }
    }
    logoutFromApp = () => {
        localStorage.clear();
        this.setState({
            log: true
        })
    }
    changePassword = (e) => {
        e.preventDefault();
        var encryptedOldPassword = simpleCrypto.encrypt(this.state.oldPassword);
        var encryptedNewPassword = simpleCrypto.encrypt(this.state.newPassword);
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "oldPassword": encryptedOldPassword,
            "newPassword": encryptedNewPassword
        }
        var self = this;
        axios.post(config.apiUrl + 'auth/userChangePassword', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    //console.log("Password changed successfully!!")
                    localStorage.clear()
                    self.setState({
                        redirectToLogin: true
                    })
                } else {
                    console.log(response.data.message)
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        const { log, redirectToLogin, features } = this.state;
        if (redirectToLogin) {
            return <Redirect to="/" />
        }
        if (log) {
            return <Redirect to="/" />
        }
        var feature = features.map(data => data.feature)
        return (
            <div>
                <nav className="bottom_bar">
                    <Nav className="w-100">
                        <div className=" d-flex flex-row justify-content-around w-100">
                            <NavLink to={"/home"} className="nav-link element" activeClassName="element-3">
                                <div className=" row d-flex flex-column justify-content-center align-items-center">
                                    <img alt="" src={homeicon} className="menu-Icon" />
                                    {/* <div className="menu-text-color">Home</div> */}
                                </div>
                            </NavLink>
                            <NavLink to={"/jitbits"} className="nav-link element" activeClassName="element-3">
                                <div className=" row d-flex flex-column justify-content-center align-items-center">
                                    <img alt="" src={articleicon} className="menu-Icon" />
                                    {/* <div className="menu-text-color">JitBits</div> */}
                                </div>
                            </NavLink>
                            <NavLink to={"/smeconnect"} className="nav-link element" activeClassName="element-3">
                                <div className=" row d-flex flex-column justify-content-center align-items-center">
                                    <img alt="" src={appointments} className="menu-Icon1" />
                                    {/* <div className="menu-text-color">FavBits</div> */}
                                </div>
                            </NavLink>
                            <NavLink to={"/authors/recommended"} className="nav-link element" activeClassName="element-3">
                                <div className=" row d-flex flex-column justify-content-center align-items-center">
                                    <img alt="" src={scm} className="menu-Icon" />
                                    {/* <div className="menu-text-color">FavBits</div> */}
                                </div>
                            </NavLink>
                            <a className="nav-link element" onClick={this.openNav}>
                                <div className="row d-flex flex-column justify-content-center align-items-center">
                                    <img alt="" src={menuicon} className="menu-Icon" />
                                    {/* <div className="menu-text-color">More</div> */}
                                </div>
                            </a>
                        </div>
                    </Nav>
                </nav>
                <div onBlur={this.closeMenu}>
                    <div id="mySidenav" class="sidenav" style={{ width: this.state.navwidth }}>
                        <a class="closebtn" onClick={this.closeNav}>&times;</a>
                        <center>
                            <img alt="" src={config.userUrl + this.state.profilePicture} className="user-img" />
                            <div className="user-name">{this.state.name}</div>
                            <div className="user-id">({this.state.userRole}, {localStorage.getItem("planType") == 1 ? "Anticipator" : (localStorage.getItem("planType") == 2 ? "Integrator" : (localStorage.getItem("planType") == 3 ? "Collaborator" : "Orchestrator"))})</div></center>
                        {this.state.planType == 1 ?
                            <div>
                                <a className="sidenav-text"><img alt="" src={usericon} className="usericon" />&nbsp; &nbsp; User <img onClick={this.showUser} src={this.state.user ? rightarrow : downarrow} className="downarrow" />
                                    {this.state.user ? <span>
                                        <a className="toggle-text" onClick={this.showProfile}>&nbsp;&nbsp;Profile</a> {this.state.profile ? <span><Link to="/editprofile"><b className="profile-edit">Edit</b></Link>
                                            <img alt="" src={line} className="line_image" />
                                            <div className="user_profile_alignment"> <a className="profile-text-user">Name: {this.state.name}</a><br />
                                                < a className="profile-text-user"> Age Group: {this.state.ageGroup}</a><br />
                                                <a className="profile-text-user">Company:
                                                    {this.state.company !== null && this.state.company > this.state.company.substring(0, 18) ? <>{this.state.company.substring(0, 18)}...</> : <>{this.state.company}</>}</a><br />
                                                <a className="profile-text-user">Experience: {this.state.experience}</a><br />
                                                <a className="profile-text-user mt-2">City: {this.state.city}</a></div></span> : null}
                                        <a className="toggle-text" onClick={this.showToggleUser}>&nbsp;&nbsp;Express Interest</a>{this.state.toggleUser ? <span>
                                            <img alt="" src={line1} style={{ width: "6%", marginTop: "-4px", marginLeft: "2.65rem" }} />
                                            <div style={{ marginTop: "-4rem" }}>
                                                < a disabled={this.state.disable} className={this.state.disable ? "profile-text disabled" : "profile-text not-disabled"}  >Opt for Author &nbsp;<span>{this.state.author === "Pending" ? <i class="fa fa-check" id="false" aria-hidden="true"></i> : (this.state.author === "Approved" ? <i class="fa fa-check" style={{ color: "green" }} id="true" aria-hidden="true"></i> : "")} </span></a><br />
                                                <a disabled={this.state.disable} className={this.state.disable ? "profile-text disabled" : "profile-text not-disabled"} >Opt for Reviewer &nbsp;<span>{this.state.reviewer === "Pending" ? <i class="fa fa-check" id="false" aria-hidden="true"></i> : (this.state.reviewer === "Approved" ? <i class="fa fa-check" style={{ color: "green" }} id="true" aria-hidden="true"></i> : "")} </span></a><br />
                                                <a disabled={this.state.disable} className={this.state.disable ? "profile-text disabled" : "profile-text not-disabled"} >Opt for SME &nbsp;<span>{this.state.sme === "Pending" ? <i class="fa fa-check" id="false" aria-hidden="true"></i> : (this.state.sme === "Approved" ? <i class="fa fa-check" id="true" aria-hidden="true"></i> : "")} </span></a><br />
                                            </div></span> : null}
                                        <div className="toggle-text" onClick={this.showPassword}>&nbsp;&nbsp;Change Password</div>{this.state.password ? <span>
                                            <img alt="" src={line2} style={{ width: "8%", marginTop: "-4px", marginLeft: "2.65rem" }} />
                                            <Form onSubmit={this.changePassword}>
                                                <div style={{ marginTop: "-2.9rem", marginLeft: "2px" }}>
                                                    <div className="form-group">
                                                        <input type="password"
                                                            name="oldPassword"
                                                            className="form-control _password"
                                                            value={this.state.oldPassword}
                                                            placeholder="Old Password"
                                                            onChange={this.onChange}
                                                            onBlur={this.handleBlur}
                                                        />
                                                    </div>
                                                    <div className="form-group mt-2">
                                                        <input type="password"
                                                            name="newPassword"
                                                            className="form-control _password"
                                                            value={this.state.newPassword}
                                                            placeholder="New Password"
                                                            onChange={this.onChange}
                                                            onBlur={this.handleBlur}
                                                        />
                                                        <div className="div">{this.state.newPasswordError && (
                                                            <div className="error">{this.state.newPasswordError}</div>
                                                        )}</div>
                                                        <Button variant="default" type="submit" size="sm" className="done-btn">Done</Button></div></div>
                                            </Form>
                                            <div id="status"></div></span> : null}

                                    </span> : null}
                                </a>
                                <hr className="hr-width" />
                                <a className="sidenav-text"><img alt="" src={appointments} className="usericon" />&nbsp; &nbsp; {feature[4]} <img onClick={this.showQA} src={this.state.qa ? rightarrow : downarrow} className="downarrow" />
                                    {this.state.qa ? <span>
                                        {features.slice(4, 5).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/smeconnect" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                        {features.slice(4, 5).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/smeplanned" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                        {localStorage.getItem('smeType') == 2 ?
                                            <>
                                                <span>{features.slice(4, 5).map(data => data.subFeature !== null ? data.subFeature.slice(2, 3).map((item, i) => <Link to="/smecreateevent" className="toggle-text link">&nbsp;{item}</Link>) : "")}</span>
                                                <span>{features.slice(4, 5).map(data => data.subFeature !== null ? data.subFeature.slice(3, 4).map((item, i) => <Link to="/viewemyvents" className="toggle-text link">&nbsp;{item}</Link>) : "")}</span>
                                            </> : ""}

                                    </span> : null} </a>
                                <hr className="hr-width" />
                                <Link to="/favbits" className="sidenav-text"><img alt="" src={bookmarks} className="favicon" />&nbsp; &nbsp; &nbsp;FavBits </Link><hr className="hr-width" />
                                <a className="sidenav-text"><img alt="" src={opportunity} className="usericon" />&nbsp; &nbsp; {feature[0]} <img onClick={this.showOpportunity} src={this.state.opportunity ? rightarrow : downarrow} className="downarrow" />
                                    {this.state.opportunity ? <span>
                                        {features.slice(0, 1).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/createopportunity" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                        {features.slice(0, 1).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/viewopportunity" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                    </span> : null} </a> <hr className="hr-width" />
                                <a className="sidenav-text"><img alt="" src={referral} className="usericon" />&nbsp; &nbsp; {feature[1]} <img onClick={this.showReferral} src={this.state.referral ? rightarrow : downarrow} className="downarrow" />
                                    {this.state.referral ? <span>
                                        {features.slice(1, 2).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/referafriend" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                        {features.slice(1, 2).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/referralstatus" className="toggle-text link">&nbsp;{item}</Link>) : "")}

                                    </span> : null} </a> <hr className="hr-width" />

                                {/* <Link to="/polls/currentpolls" className="sidenav-text"><img alt="" src={poll} className="pollicon" />&nbsp; &nbsp; {feature[1]}</Link><hr className="hr-width" /> */}
                                {/* <Link to="/authors/recommended" className="sidenav-text"><img alt="" src={scm} className="usericon" />&nbsp; &nbsp; Connect</Link>
                                <hr className="hr-width" /> */}
                                <Link to="/interests" className="sidenav-text"><img alt="" src={preference} className="usericon" />&nbsp; &nbsp; Topics</Link><hr className="hr-width" />
                                <Link to="/pricing" className="sidenav-text"><img alt="" src={membership} className="pricingicon" />&nbsp; &nbsp;{feature[3]}</Link><hr className="hr-width" />
                                <Link to="/purchases" className="sidenav-text"><img alt="" src={pricing} className="pricingicon" />&nbsp; &nbsp;Transactions</Link><hr className="hr-width" />
                                <Link to="/settings" className="sidenav-text"><img alt="" src={settings} className="usericon" />&nbsp; &nbsp; Backdrop</Link><hr className="hr-width" />
                                <a className="sidenav-text"><img alt="" src={bug} className="usericon" />&nbsp; &nbsp; {feature[6]} <img onClick={this.showIncident} src={this.state.incident ? rightarrow : downarrow} className="downarrow" />
                                    {this.state.incident ? <span>
                                        {features.slice(6, 7).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/error" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                        {features.slice(6, 7).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/showAllIncidents" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                    </span> : null} </a><hr className="hr-width" />
                                <a href="#" className="sidenav-text" onClick={this.showLogout}><img alt="" src={logout} className="logouticon" />&nbsp; &nbsp; Logout</a>
                                <div id="logout" className="modal-pop">
                                    <form className="modal-content-log">
                                        <div className="_container">
                                            <img alt="" src={logout1} className="logouticon1" />
                                            <p className="mt-2">Do you really want to logout from your account?</p>
                                            <center>                            <Button variant="default" onClick={this.logoutLocal} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                <Button variant="default" onClick={this.showLogoutNone} className="deletebutton">No</Button></center>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            : (this.state.planType == 2 ?
                                <div>
                                    <a className="sidenav-text"><img alt="" src={usericon} className="usericon" />&nbsp; &nbsp; User <img onClick={this.showUser} src={this.state.user ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.user ? <span>
                                            <a className="toggle-text" onClick={this.showProfile}>&nbsp;&nbsp;Profile</a> {this.state.profile ? <span><Link to="/editprofile"><b className="profile-edit">Edit</b></Link>
                                                <img alt="" src={line} className="line_image" />
                                                <div className="user_profile_alignment"> <a className="profile-text-user">Name: {this.state.name}</a><br />
                                                    < a className="profile-text-user"> Age Group: {this.state.ageGroup}</a><br />
                                                    <a className="profile-text-user">Company:
                                                        {this.state.company !== null && this.state.company > this.state.company.substring(0, 18) ? <>{this.state.company.substring(0, 18)}...</> : <>{this.state.company}</>}</a><br />
                                                    <a className="profile-text-user">Experience: {this.state.experience}</a><br />
                                                    <a className="profile-text-user mt-2">City: {this.state.city}</a></div></span> : null}
                                            <a className="toggle-text" onClick={this.showToggleUser}>&nbsp;&nbsp;Express Interest</a>{this.state.toggleUser ? <span>
                                                <img alt="" src={line1} style={{ width: "6%", marginTop: "-4px", marginLeft: "2.65rem" }} />
                                                <div style={{ marginTop: "-4rem" }}>
                                                    < a disabled={this.state.disable} className={this.state.disable ? "profile-text disabled" : "profile-text not-disabled"}  >Opt for Author &nbsp;<span>{this.state.author === "Pending" ? <i class="fa fa-check" id="false" aria-hidden="true"></i> : (this.state.author === "Approved" ? <i class="fa fa-check" style={{ color: "green" }} id="true" aria-hidden="true"></i> : "")} </span></a><br />
                                                    <a disabled={this.state.disable} className={this.state.disable ? "profile-text disabled" : "profile-text not-disabled"} >Opt for Reviewer &nbsp;<span>{this.state.reviewer === "Pending" ? <i class="fa fa-check" id="false" aria-hidden="true"></i> : (this.state.reviewer === "Approved" ? <i class="fa fa-check" style={{ color: "green" }} id="true" aria-hidden="true"></i> : "")} </span></a><br />
                                                    <a disabled={this.state.disable} className={this.state.disable ? "profile-text disabled" : "profile-text not-disabled"} >Opt for SME &nbsp;<span>{this.state.sme === "Pending" ? <i class="fa fa-check" id="false" aria-hidden="true"></i> : (this.state.sme === "Approved" ? <i class="fa fa-check" id="true" aria-hidden="true"></i> : "")} </span></a><br />
                                                </div></span> : null}
                                            <div className="toggle-text" onClick={this.showPassword}>&nbsp;&nbsp;Change Password</div>{this.state.password ? <span>
                                                <img alt="" src={line2} style={{ width: "8%", marginTop: "-4px", marginLeft: "2.65rem" }} />
                                                <Form onSubmit={this.changePassword}>
                                                    <div style={{ marginTop: "-2.9rem", marginLeft: "2px" }}>
                                                        <div className="form-group">
                                                            <input type="password"
                                                                name="oldPassword"
                                                                className="form-control _password"
                                                                value={this.state.oldPassword}
                                                                placeholder="Old Password"
                                                                onChange={this.onChange}
                                                                onBlur={this.handleBlur}
                                                            />
                                                        </div>
                                                        <div className="form-group mt-2">
                                                            <input type="password"
                                                                name="newPassword"
                                                                className="form-control _password"
                                                                value={this.state.newPassword}
                                                                placeholder="New Password"
                                                                onChange={this.onChange}
                                                                onBlur={this.handleBlur}
                                                            />
                                                            <div className="div">{this.state.newPasswordError && (
                                                                <div className="error">{this.state.newPasswordError}</div>
                                                            )}</div>
                                                            <Button variant="default" type="submit" size="sm" className="done-btn">Done</Button></div></div>
                                                </Form>
                                                <div id="status"></div></span> : null}

                                        </span> : null}
                                    </a>
                                    {/* <hr className="hr-width" />
                                    <a className="sidenav-text"><img alt="" src={qna} className="usericon" />&nbsp; &nbsp; {feature[0]} <img onClick={this.showQA} src={this.state.qa ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.qa ? <span>
                                            {features.slice(0, 1).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/askquestion" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                            {features.slice(0, 1).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/checkanswers" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                            {features.slice(0, 1).map(data => data.subFeature !== null ? data.subFeature.slice(2, 3).map((item, i) => <Link to="/myquestions" className="toggle-text link">&nbsp;{item}</Link>) : "")}

                                        </span> : null} </a> */}
                                    <hr className="hr-width" />
                                    <a className="sidenav-text"><img alt="" src={appointments} className="usericon" />&nbsp; &nbsp; {feature[7]} <img onClick={this.showQA} src={this.state.qa ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.qa ? <span>
                                            {features.slice(7, 8).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/smeconnect" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                            {features.slice(7, 8).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/smeplanned" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                            {localStorage.getItem('smeType') == 2 ?
                                                <>
                                                    <span>{features.slice(7, 8).map(data => data.subFeature !== null ? data.subFeature.slice(2, 3).map((item, i) => <Link to="/smecreateevent" className="toggle-text link">&nbsp;{item}</Link>) : "")}</span>
                                                    <span>{features.slice(7, 8).map(data => data.subFeature !== null ? data.subFeature.slice(3, 4).map((item, i) => <Link to="/viewmyevents" className="toggle-text link">&nbsp;{item}</Link>) : "")}</span>
                                                </> : ""}

                                        </span> : null} </a>
                                    <hr className="hr-width" />
                                    <a className="sidenav-text"><img alt="" src={registersme} className="usericon" />&nbsp; &nbsp; {feature[8]} <img onClick={this.showSME} src={this.state.showSceemSme ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.showSceemSme ? <span>
                                            {localStorage.getItem('smeStatus') === '0' || localStorage.getItem('smeStatus') === '2' ?
                                                <> {features.slice(8, 9).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/smeregister" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}</> : ""}
                                            {localStorage.getItem('smeStatus') === '3' ?
                                                <>{features.slice(8, 9).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/smeupdateregister" className="toggle-text link">&nbsp;{item}</Link>) : "")}</> : ""}
                                            {localStorage.getItem('smeStatus') === '1' ?
                                                <>{features.slice(8, 9).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/smeupdateregister" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                                    {features.slice(8, 9).map(data => data.subFeature !== null ? data.subFeature.slice(2, 3).map((item, i) => <Link to="/smeconfigurefees" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                                    {features.slice(8, 9).map(data => data.subFeature !== null ? data.subFeature.slice(3, 4).map((item, i) => <Link to="/smepromoteprofile" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                                    {features.slice(8, 9).map(data => data.subFeature !== null ? data.subFeature.slice(4, 5).map((item, i) => <Link to="/smerequests" className="toggle-text link">&nbsp;{item}</Link>) : "")}</> : ""}

                                        </span> : null} </a>
                                    <hr className="hr-width" />
                                    <Link to="/favbits" className="sidenav-text"><img alt="" src={bookmarks} className="favicon" />&nbsp; &nbsp; &nbsp;FavBits </Link><hr className="hr-width" />
                                    <a className="sidenav-text"><img alt="" src={opportunity} className="usericon" />&nbsp; &nbsp; {feature[1]} <img onClick={this.showOpportunity} src={this.state.opportunity ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.opportunity ? <span>
                                            {features.slice(1, 2).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/createopportunity" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                            {features.slice(1, 2).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/viewopportunity" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                        </span> : null} </a> <hr className="hr-width" />
                                    <a className="sidenav-text"><img alt="" src={advertisementmenu} className="usericon" />&nbsp; &nbsp; {feature[2]} <img onClick={this.showAdvertisement} src={this.state.advertisement ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.advertisement ? <span>
                                            {features.slice(2, 3).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/requestforadvertisement" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                            {features.slice(2, 3).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/viewadvertisements" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                        </span> : null} </a><hr className="hr-width" />
                                    <a className="sidenav-text"><img alt="" src={referral} className="usericon" />&nbsp; &nbsp; {feature[3]} <img onClick={this.showReferral} src={this.state.referral ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.referral ? <span>
                                            {features.slice(3, 4).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/referafriend" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                            {features.slice(3, 4).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/referralstatus" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                        </span> : null} </a> <hr className="hr-width" />
                                    <Link to="/sceemlore" className="sidenav-text"><img alt="" src={sceemlore} className="pollicon" />&nbsp; &nbsp; {feature[5]} </Link><hr className="hr-width" />
                                    {/* <Link to="/polls/currentpolls" className="sidenav-text"><img alt="" src={poll} className="pollicon" />&nbsp; &nbsp; {feature[4]} </Link><hr className="hr-width" /> 
                                    <Link to="/authors/recommended" className="sidenav-text"><img alt="" src={scm} className="usericon" />&nbsp; &nbsp; Connect </Link>
                                    <hr className="hr-width" />*/}
                                    <Link to="/interests" className="sidenav-text"><img alt="" src={preference} className="usericon" />&nbsp; &nbsp; Topics </Link><hr className="hr-width" />
                                    <Link to="/pricing" className="sidenav-text"><img alt="" src={membership} className="pricingicon" />&nbsp; &nbsp;{feature[6]} </Link><hr className="hr-width" />
                                    {/* <a className="sidenav-text"><img alt="" src={referral} className="usericon" />&nbsp; &nbsp; {feature[6]} <img onClick={this.showGroup} src={this.state.group ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.group ? <span>
                                            {features.slice(6, 7).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/addgroup" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                            {features.slice(6, 7).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/viewallgroups" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                        </span> : null} </a> <hr className="hr-width" /> */}
                                    <Link to="/purchases" className="sidenav-text"><img alt="" src={pricing} className="pricingicon" />&nbsp; &nbsp;Transactions </Link><hr className="hr-width" />
                                    <Link to="/settings" className="sidenav-text"><img alt="" src={settings} className="usericon" />&nbsp; &nbsp; Backdrop </Link><hr className="hr-width" />
                                    <a className="sidenav-text"><img alt="" src={bug} className="usericon" />&nbsp; &nbsp; {feature[9]} <img onClick={this.showIncident} src={this.state.incident ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.incident ? <span>
                                            {features.slice(9, 10).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/error" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                            {features.slice(9, 10).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/showAllIncidents" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                        </span> : null} </a><hr className="hr-width" />
                                    <a href="#" className="sidenav-text" onClick={this.showLogout}><img alt="" src={logout} className="logouticon" />&nbsp; &nbsp; Logout </a>
                                    <div id="logout" className="modal-pop">
                                        <form className="modal-content-log">
                                            <div className="_container">
                                                <img alt="" src={logout1} className="logouticon1" />
                                                <p className="mt-2">Do you really want to logout from your account?</p>
                                                <center>                            <Button variant="default" onClick={this.logoutLocal} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                    <Button variant="default" onClick={this.showLogoutNone} className="deletebutton">No</Button></center>
                                            </div>
                                        </form>
                                    </div>
                                </div> : <div>
                                    <a className="sidenav-text"><img alt="" src={usericon} className="usericon" />&nbsp; &nbsp; User <img onClick={this.showUser} src={this.state.user ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.user ? <span>
                                            <a className="toggle-text" onClick={this.showProfile}>&nbsp;&nbsp;Profile</a> {this.state.profile ? <span><Link to="/editprofile"><b className="profile-edit">Edit</b></Link>
                                                <img alt="" src={line} className="line_image" />
                                                <div className="user_profile_alignment"> <a className="profile-text-user">Name: {this.state.name}</a><br />
                                                    < a className="profile-text-user"> Age Group: {this.state.ageGroup}</a><br />
                                                    <a className="profile-text-user">Company:
                                                        {this.state.company !== null && this.state.company > this.state.company.substring(0, 18) ? <>{this.state.company.substring(0, 18)}...</> : <>{this.state.company}</>}</a><br />
                                                    <a className="profile-text-user">Experience: {this.state.experience}</a><br />
                                                    <a className="profile-text-user mt-2">City: {this.state.city}</a></div></span> : null}
                                            <a className="toggle-text" onClick={this.showToggleUser}>&nbsp;&nbsp;Express Interest</a>{this.state.toggleUser ? <span>
                                                <img alt="" src={line1} style={{ width: "6%", marginTop: "-4px", marginLeft: "2.65rem" }} />
                                                <div style={{ marginTop: "-4rem" }}>
                                                    < a disabled={this.state.disable} className={this.state.disable ? "profile-text disabled" : "profile-text not-disabled"}  >Opt for Author &nbsp;<span>{this.state.author === "Pending" ? <i class="fa fa-check" id="false" aria-hidden="true"></i> : (this.state.author === "Approved" ? <i class="fa fa-check" style={{ color: "green" }} id="true" aria-hidden="true"></i> : "")} </span></a><br />
                                                    <a disabled={this.state.disable} className={this.state.disable ? "profile-text disabled" : "profile-text not-disabled"} >Opt for Reviewer &nbsp;<span>{this.state.reviewer === "Pending" ? <i class="fa fa-check" id="false" aria-hidden="true"></i> : (this.state.reviewer === "Approved" ? <i class="fa fa-check" style={{ color: "green" }} id="true" aria-hidden="true"></i> : "")} </span></a><br />
                                                    <a disabled={this.state.disable} className={this.state.disable ? "profile-text disabled" : "profile-text not-disabled"} >Opt for SME &nbsp;<span>{this.state.sme === "Pending" ? <i class="fa fa-check" id="false" aria-hidden="true"></i> : (this.state.sme === "Approved" ? <i class="fa fa-check" id="true" aria-hidden="true"></i> : "")} </span></a><br />
                                                </div></span> : null}
                                            <div className="toggle-text" onClick={this.showPassword}>&nbsp;&nbsp;Change Password</div>{this.state.password ? <span>
                                                <img alt="" src={line2} style={{ width: "8%", marginTop: "-4px", marginLeft: "2.65rem" }} />
                                                <Form onSubmit={this.changePassword}>
                                                    <div style={{ marginTop: "-2.9rem", marginLeft: "2px" }}>
                                                        <div className="form-group">
                                                            <input type="password"
                                                                name="oldPassword"
                                                                className="form-control _password"
                                                                value={this.state.oldPassword}
                                                                placeholder="Old Password"
                                                                onChange={this.onChange}
                                                                onBlur={this.handleBlur}
                                                            />
                                                        </div>
                                                        <div className="form-group mt-2">
                                                            <input type="password"
                                                                name="newPassword"
                                                                className="form-control _password"
                                                                value={this.state.newPassword}
                                                                placeholder="New Password"
                                                                onChange={this.onChange}
                                                                onBlur={this.handleBlur}
                                                            />
                                                            <div className="div">{this.state.newPasswordError && (
                                                                <div className="error">{this.state.newPasswordError}</div>
                                                            )}</div>
                                                            <Button variant="default" type="submit" size="sm" className="done-btn">Done</Button></div></div>
                                                </Form>
                                                <div id="status"></div></span> : null}

                                        </span> : null}
                                    </a>
                                    {/* <hr className="hr-width" />
                                    <a className="sidenav-text"><img alt="" src={qna} className="usericon" />&nbsp; &nbsp; {feature[0]} <img onClick={this.showQA} src={this.state.qa ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.qa ? <span>
                                            {features.slice(0, 1).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/askquestion" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                            {features.slice(0, 1).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/checkanswers" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                            {features.slice(0, 1).map(data => data.subFeature !== null ? data.subFeature.slice(2, 3).map((item, i) => <Link to="/myquestions" className="toggle-text link">&nbsp;{item}</Link>) : "")}

                                        </span> : null} </a> */}
                                    <hr className="hr-width" />
                                    <a className="sidenav-text"><img alt="" src={appointments} className="usericon" />&nbsp; &nbsp; {feature[7]} <img onClick={this.showQA} src={this.state.qa ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.qa ? <span>
                                            {features.slice(7, 8).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/smeconnect" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                            {features.slice(7, 8).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/smeplanned" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                            {localStorage.getItem('smeType') == 2 ?
                                                <>
                                                    <span>{features.slice(7, 8).map(data => data.subFeature !== null ? data.subFeature.slice(2, 3).map((item, i) => <Link to="/smecreateevent" className="toggle-text link">&nbsp;{item}</Link>) : "")}</span>
                                                    <span>{features.slice(7, 8).map(data => data.subFeature !== null ? data.subFeature.slice(3, 4).map((item, i) => <Link to="/viewmyevents" className="toggle-text link">&nbsp;{item}</Link>) : "")}</span>
                                                </> : ""}

                                        </span> : null} </a>
                                    <hr className="hr-width" />
                                    <a className="sidenav-text"><img alt="" src={registersme} className="usericon" />&nbsp; &nbsp; {feature[8]} <img onClick={this.showSME} src={this.state.showSceemSme ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.showSceemSme ? <span>
                                            {localStorage.getItem('smeStatus') === '0' || localStorage.getItem('smeStatus') === '2' ?
                                                <span>{features.slice(8, 9).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/smeregister" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}</span> : ""}
                                            {localStorage.getItem('smeStatus') === '3' ?
                                                <span>{features.slice(8, 9).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/smeupdateregister" className="toggle-text link">&nbsp;{item}</Link>) : "")}</span> : ""}
                                            {localStorage.getItem('smeStatus') === '1' ?
                                                <span>{features.slice(8, 9).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/smeupdateregister" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                                    {features.slice(8, 9).map(data => data.subFeature !== null ? data.subFeature.slice(2, 3).map((item, i) => <Link to="/smeconfigurefees" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                                    {features.slice(8, 9).map(data => data.subFeature !== null ? data.subFeature.slice(3, 4).map((item, i) => <Link to="/smepromoteprofile" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                                    {features.slice(8, 9).map(data => data.subFeature !== null ? data.subFeature.slice(4, 5).map((item, i) => <Link to="/smerequests" className="toggle-text link">&nbsp;{item}</Link>) : "")}</span> : ""}

                                        </span> : null} </a>
                                    <hr className="hr-width" />
                                    <Link to="/favbits" className="sidenav-text"><img alt="" src={bookmarks} className="favicon" />&nbsp; &nbsp; &nbsp;FavBits </Link><hr className="hr-width" />
                                    <a className="sidenav-text"><img alt="" src={opportunity} className="usericon" />&nbsp; &nbsp; {feature[1]} <img onClick={this.showOpportunity} src={this.state.opportunity ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.opportunity ? <span>
                                            {features.slice(1, 2).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/createopportunity" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                            {features.slice(1, 2).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/viewopportunity" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                        </span> : null} </a> <hr className="hr-width" />
                                    <a className="sidenav-text"><img alt="" src={advertisementmenu} className="usericon" />&nbsp; &nbsp; {feature[2]} <img onClick={this.showAdvertisement} src={this.state.advertisement ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.advertisement ? <span>
                                            {features.slice(2, 3).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/requestforadvertisement" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                            {features.slice(2, 3).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/viewadvertisements" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                        </span> : null} </a><hr className="hr-width" />
                                    <a className="sidenav-text"><img alt="" src={referral} className="usericon" />&nbsp; &nbsp; {feature[3]} <img onClick={this.showReferral} src={this.state.referral ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.referral ? <span>
                                            {features.slice(3, 4).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/referafriend" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                            {features.slice(3, 4).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/referralstatus" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                        </span> : null} </a> <hr className="hr-width" />
                                    {/* <Link to="/polls/currentpolls" className="sidenav-text"><img alt="" src={poll} className="pollicon" />&nbsp; &nbsp; {feature[4]} </Link><hr className="hr-width" /> */}
                                    <Link to="/sceemlore" className="sidenav-text"><img alt="" src={sceemlore} className="pollicon" />&nbsp; &nbsp; {feature[5]} </Link><hr className="hr-width" />
                                    {/* <Link to="/authors/recommended" className="sidenav-text"><img alt="" src={scm} className="usericon" />&nbsp; &nbsp; Connect </Link>
                                    <hr className="hr-width" /> */}
                                    <Link to="/interests" className="sidenav-text"><img alt="" src={preference} className="usericon" />&nbsp; &nbsp; Topics </Link><hr className="hr-width" />
                                    <Link to="/pricing" className="sidenav-text"><img alt="" src={membership} className="pricingicon" />&nbsp; &nbsp;{feature[6]} </Link><hr className="hr-width" />
                                    {/* <a className="sidenav-text"><img alt="" src={referral} className="usericon" />&nbsp; &nbsp; {feature[7]} <img onClick={this.showGroup} src={this.state.group ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.group ? <span>
                                            {features.slice(7, 8).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/addgroup" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                            {features.slice(7, 8).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/viewallgroups" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                        </span> : null} </a> <hr className="hr-width" /> */}
                                    <Link to="/purchases" className="sidenav-text"><img alt="" src={pricing} className="pricingicon" />&nbsp; &nbsp;Transactions </Link><hr className="hr-width" />
                                    <Link to="/settings" className="sidenav-text"><img alt="" src={settings} className="usericon" />&nbsp; &nbsp; Backdrop </Link><hr className="hr-width" />
                                    {/* <Link to="/error" className="sidenav-text"><img alt="" src={bug} className="usericon" />&nbsp; &nbsp; Incidents </Link><hr className="hr-width" /> */}
                                    <a className="sidenav-text"><img alt="" src={bug} className="usericon" />&nbsp; &nbsp; {feature[9]} <img onClick={this.showIncident} src={this.state.incident ? rightarrow : downarrow} className="downarrow" />
                                        {this.state.incident ? <span>
                                            {features.slice(9, 10).map(data => data.subFeature !== null ? data.subFeature.slice(0, 1).map((item, i) => <Link to="/error" className="toggle-text link">&nbsp;&nbsp;{item}</Link>) : "")}
                                            {features.slice(9, 10).map(data => data.subFeature !== null ? data.subFeature.slice(1, 2).map((item, i) => <Link to="/showAllIncidents" className="toggle-text link">&nbsp;{item}</Link>) : "")}
                                        </span> : null} </a><hr className="hr-width" />
                                    <a href="#" className="sidenav-text" onClick={this.showLogout}><img alt="" src={logout} className="logouticon" />&nbsp; &nbsp; Logout </a>
                                    <div id="logout" className="modal-pop">
                                        <form className="modal-content-log">
                                            <div className="_container">
                                                <img alt="" src={logout1} className="logouticon1" />
                                                <p className="mt-2">Do you really want to logout from your account?</p>
                                                <center>                            <Button variant="default" onClick={this.logoutLocal} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                    <Button variant="default" onClick={this.showLogoutNone} className="deletebutton">No</Button></center>
                                            </div>
                                        </form>
                                    </div>
                                </div>)}
                    </div>
                </div>
                {/* )} */}
            </div >
        )
    }
}

{/* <a className="sidenav-text"><img alt="" src={qna} className="usericon" />&nbsp; &nbsp; Groups<img onClick={this.showGroups} src={this.state.group ? rightarrow : downarrow} className="downarrow" />
                            {this.state.group ? <span>
                                <Link to="/addgroup" className="toggle-text link">&nbsp;&nbsp;Create Group</Link>
                                <Link to="/viewallgroups" className="toggle-text link">&nbsp;&nbsp;View Groups</Link>
                           <Link to="/invitetogroup" className="toggle-text link">&nbsp;&nbsp;Invite People</Link> 
                            </span> : null} </a> <hr className="hr-width" /> */}

