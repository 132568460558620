import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import circle from '../images/circle.png';
import circleblue from '../images/circle-blue.png';
import back from '../images/backarrow.png';
import './SignUp.css';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import DataPrivacy from "../TermsAndConditions/DataPrivacy";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validEmailRegex = /^[a-z0-9+_.-]+@[a-z0-9.-]+$/;
const validPasswordRegex = /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/;
const validPhoneNoRegex = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/i;

const Register = (props) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [registerFlow, setRegisterFlow] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [verifyEmailPage, setVerifyEmailPage] = useState(false);
    const [loginPage, setLoginPage] = useState(false);
    const [checked, setChecked] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [showTerms, setShowTerms] = useState(false)
    const [showPrivacy, setShowPrivacy] = useState(false)
    const [touchedEmail, setTouchedEmail] = useState(false);

    useEffect(() => {
    }, []);

    const handleCheck = () => {
        setChecked(!checked);
    }

    const onChange = (event) => {
        const { name, value } = event.target;
        if (name === "name") setName(value);
        else if (name === "email") setEmail(value);
        else if (name === "password") setPassword(value);
        else if (name === "confirmPassword") setConfirmPassword(value);
    }

    const onChangeEmail = (event) => {
        const { name, value } = event.target;
        setEmail(value);
       // console.log(email);
    }

    const handleBlur = (event) => {
        const { name } = event.target;
        if (name === "email") setTouchedEmail(true);
        validateField(name);
    }
    const displayTerms = () => {
        setShowTerms(!showTerms)
    }
    const displayPrivacy = () => {
        setShowPrivacy(!showPrivacy)
    }
    const handleSubmit = (event) => {
        event.preventDefault();
       // console.log({ name, email, password, confirmPassword });
        localStorage.setItem('name', JSON.stringify(name));
        const formFields = ["name", "email", "password", "confirmPassword"];
        let isValid = true;
        formFields.forEach((field) => {
            isValid = validateField(field) && isValid;
        });
        if (!isValid) {
           // console.log("Please enter valid details");
            document.getElementById('status').innerHTML = "<div>Please enter valid details</div>";
        } else {
            postDetails();
        }
    }

    const sendOtp = () => {
        const headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        };

        const payload = {
            "email": email,
            "registerFlow": registerFlow
        };

        axios.post(config.apiUrl + 'auth/sendOtp', payload, { headers: headers })
            .then((response) => {
                const msg = "Otp sent successfully!!";
                if (response.data.status === true) {
                    //console.log("Otp sent successfully!!");
                   // console.log("payload", payload);
                    document.getElementById('otp').innerText = msg;
                } else {
                    console.log(response.data.message);
                   // console.log("err", payload);
                }
            })
            .catch((error) => {
              //  console.log("payload", payload);
                console.log(error);
            });
    }

    const checkEmail = () => {
        const headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        };

        const payload = {
            "email": email
        };

        axios.post(config.apiUrl + 'auth/checkEmailStatus', payload, { headers: headers })
            .then((response) => {
                if (response.data.status === true) {
                    document.getElementById('true').innerHTML = '<i class="fa fa-check" id="true" aria-hidden="true"></i>';
                    setEmailError("");
                    setErrMsg(true);
                    document.getElementById('error-msg').innerText = "";
                    document.getElementById('false').innerHTML = '';
                } else {
                   // console.log(response.data.message);
                    if (response.data.message === "Email not exist") {
                        setErrMsg(false);
                        setEmailError("Please enter email");
                    } else {
                        setErrMsg(false);
                        setEmailError("Email already registered!");
                    }
                    document.getElementById('false').innerHTML = '<i class="fa fa-times" id="false" aria-hidden="true"></i>';
                }
            })
            .catch((error) => {
                //console.log("payload", payload);
                console.log(error);
            });
    }

    const postDetails = () => {
        const headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        };

        const encryptedPassword = simpleCrypto.encrypt(password);
        const encryptedConfirmPassword = simpleCrypto.encrypt(confirmPassword);

        const payload = {
            "name": name,
            "email": email,
            "password": encryptedPassword,
            "confirmPassword": encryptedConfirmPassword,
            "registerFlow": registerFlow
        };

        axios.post(config.apiUrl + 'auth/register', payload, { headers: headers })
            .then((response) => {
                if (response.data.userStatus === "verified") {
                    //console.log("User Registered successfully!!");
                    //console.log("payload", payload);
                    setLoginPage(true);
                } else {
                    setVerifyEmailPage(true);
                    sendOtp();
                    console.log(response.data.message);
                }
            })
            .catch((error) => {
                console.log("payload", payload,error);
                document.getElementById('error').innerText = error;
            });
    }

    const validateField = (name) => {
        let isValid = false;
        if (name === "name") isValid = validateName();
        else if (name === "email") isValid = validateEmailAddress();
        else if (name === "password") isValid = validatePassword();
        else if (name === "confirmPassword") isValid = validateConfirmPassword();
        return isValid;
    }

    const validateName = () => {
        let nameError = "";
        const value = name;
        if (value.trim() === "") nameError = "Name must be at least 5 characters long!";
        else if (value.length < 5)
            nameError = 'Name must be at least 5 characters long!';
        else if (value.length > 50)
            nameError = 'Name should not be more than 50 characters long!';
        setNameError(nameError);
        return nameError === "";
    }

    const validateEmailAddress = () => {
        let emailError = "";
        const value = email.trim();
        if (value === "") emailError = "Email is required";
        else if (!validEmailRegex.test(value))
            emailError = "Please enter a valid email";
        setEmailError(emailError);
        if (!emailError) {
            checkEmail();
        }
        return emailError === "";
    }

    const validatePassword = () => {
        let passwordError = "";
        const value = password;
        if (value.trim === "") passwordError = "Password is required";
        else if (!validPasswordRegex.test(value))
            passwordError = "Min 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        setPasswordError(passwordError);
        return passwordError === "";
    }

    const validateConfirmPassword = () => {
        let confirmPasswordError = "";
        if (password !== confirmPassword)
            confirmPasswordError = "Password does not match";
        setConfirmPasswordError(confirmPasswordError);
        return confirmPasswordError === "";
    }

    const showPasswordFn = () => {
        setShowPassword(!showPassword);
    }

    const showConfirmPasswordFn = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    if (verifyEmailPage) {
        return <Redirect
            to={{
                pathname: "/verify",
                state: { email, registerFlow, name }
            }} />
    }

    if (loginPage) {
        return <Redirect
            to={{
                pathname: "/",
                state: { email, registerFlow, name }
            }} />
    }


    return (
        <div>

            {showTerms ?
                <TermsAndConditions terms={showTerms} displayTerms={displayTerms} /> :
                showPrivacy ?
                    <DataPrivacy privacy={showPrivacy} displayPrivacy={displayPrivacy} /> :
                <div>
                    <div className="topbar"> <img alt="" src={loginlogo} className="login-logo mt-2" />
                        <img alt="" src={circle} className="-Circle" />
                        <Link to="/"><img alt="" src={back} className="back_arrow_" /></Link>
                        <p className="mt-2 signup-text">Sign up for&nbsp;<span className="_sceem">Sceem</span></p>
                    </div>
                    <img alt="" src={circleblue} className="circle_Blue" />
                    <Form className="form-signup">
                        <center>
                            <Row style={{ width: "100%" }}>
                                <Col xs={12}>
                                    <div style={{ width: "94%" }} >
                                        <div className="login-credentials">Register</div><br />
                                        <div className="box-form">
                                        <div className="form-group mt-2">
                                                    <input
                                                        type="email"
                                                        className="form-control text-lowercase form-bg"
                                                        aria-describedby="text"
                                                        value={email}
                                                        name="email"
                                                        placeholder="Email*"
                                                        onChange={onChangeEmail}
                                                        onBlur={handleBlur}
                                                    />
                                                    {/* <span id="true" className="showPassword tick-color-green"></span>
                                                    <span id="false" className="tick-color-red showPassword"></span>
                                                    <div className="signup-error-top div">{emailError && (
                                                        <div className="error">{emailError}</div>
                                                    )}</div>
                                                    <div id="error-msg" className="div error"></div> */}
                                                    {touchedEmail && !emailError && ( // Only render tick or cross if email has been touched and there's no email error
                                                        <span className="tick-color-green showPassword"><i className="fa fa-check" aria-hidden="true"></i></span>
                                                    )}
                                                    {touchedEmail && emailError && ( // Only render tick or cross if email has been touched and there's an email error
                                                        <span className="tick-color-red showPassword"><i className="fa fa-times" aria-hidden="true"></i></span>
                                                    )}
                                                    <div className="signup-error-top div">
                                                        {emailError && (
                                                            <div className="error">{emailError}</div>
                                                        )}
                                                    </div>
                                                    <div id="error-msg" className="div error"></div>
                                                </div>
                                            <div className="form-group">
                                                <input type={showPassword ? "text" : "password"}
                                                    name="password"
                                                    className="form-control form-bg"
                                                    value={password}
                                                    placeholder="Password*"
                                                    disabled={touchedEmail && !emailError ? false : true}
                                                    // disabled={errMsg === true ? false : true}
                                                    onChange={onChange}
                                                    onBlur={handleBlur}
                                                />
                                                <span className="showPassword"
                                                    onClick={showPasswordFn}
                                                >
                                                    {showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                                </span>
                                                <div className="div">{passwordError && (
                                                    <div className="error">{passwordError}</div>
                                                )}</div>
                                            </div>
                                            <div className="form-group mt-2">
                                                <input type={showConfirmPassword ? "text" : "password"}
                                                    name="confirmPassword"
                                                    className="form-control form-bg"
                                                    value={confirmPassword}
                                                    placeholder="Confirm Password*"
                                                    disabled={touchedEmail && !emailError ? false : true}
                                                    // disabled={errMsg === true ? false : true}
                                                    onChange={onChange}
                                                    onBlur={handleBlur}
                                                />
                                                <span className="showPassword"
                                                    onClick={showConfirmPasswordFn}
                                                >
                                                    {showConfirmPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                                </span>
                                                <div className="div">{confirmPasswordError && (
                                                    <div className="error">{confirmPasswordError}</div>
                                                )}</div>
                                            </div>
                                            <div className="form-group mt-2">
                                                <input type="text"
                                                    className="form-control form-bg"
                                                    aria-describedby="text"
                                                    value={name}
                                                    name="name"
                                                    placeholder="Name*"
                                                    disabled={touchedEmail && !emailError ? false : true}
                                                    onChange={onChange}
                                                    onBlur={handleBlur}
                                                />
                                                <div className="div">{nameError && (
                                                    <div className="error">{nameError}</div>
                                                )}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="signup-terms"><input type="checkbox" checked={checked} onClick={handleCheck} /> <span className="signup-agree-text">I agree to the <span onClick={displayTerms} className="signup-termslink-color">Terms & Conditions </span>and <span onClick={displayPrivacy} className="signup-termslink-color">Privacy Policy</span></span></div><br />
                        </center>
                    </Form>
                    <div style={{ marginTop: "0.8rem" }}>
                        <div id="status" className="mb-1 errormsg"></div>
                        <div id="error" className="mb-1 errormsg"></div>
                        {(email !== "" && password !== "" && confirmPassword !== "" && name !== "") ?
                            <Button variant="default" type="submit" disabled={!checked} onClick={handleSubmit} className="btn-block register_Btn">Sign Up</Button> : <Button variant="default" type="submit" disabled={true} className="btn-block register_Btn">Sign Up</Button>}<br />
                    </div>
                </div >
            }
        </div>
    );
}

export default Register;