import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import loginlogo from '../images/login-logo.png';
import axios from 'axios';
import { config } from '../Config/Config';
import BottomMenu from "../BottomMenu/BottomMenu";
import { Link, Redirect } from 'react-router-dom';
import smefullday from '../images/smefullday.svg';
import smemorning from '../images/smemorning.svg';
import smeafternoon from '../images/smeafternoon.svg';
import back from '../images/backarrow.png';
import SimpleCrypto from "simple-crypto-js";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function SMEOfferings(props) {
    const [allOfferings, setAllOfferings] = useState([]);
    const [selectedOfferings, setSelectedOfferings] = useState([]); // State variable to store selected offering IDs
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
    const [allDays, setAllDays] = useState([]);
    const [allTimeSlots, setAllTimeSlots] = useState([]);
    const [desc, setDesc] = useState('');
    const [showDescScreen, setShowDescScreen] = useState(true);
    const [agree, setAgree] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [isFirstTime, setIsFirstTime] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [linkedinLink, setLinkedinLink] = useState('');
    const [topics, setTopics] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [commercialPlans, setCommercialPlans] = useState([
        { "id": 1, "planName": "Free" },
        { "id": 2, "planName": "500" },
        { "id": 3, "planName": "750" },
        { "id": 4, "planName": "1000" }
    ]);
    const [intent, setIntent] = useState(props?.location?.state?.authorText ? props?.location?.state?.authorText : "As an SME (Subject Matter Expert) for 'sceem,' I'm dedicated to empowering through coaching, consulting, mentoring, and networking. Sharing profound insights, I guide individuals to mastery, offering personalized coaching to hone skills and achieve goals. Consulting involves strategic advice rooted in expertise, fostering innovation. Mentoring fosters talent, empowering others to reach full potential. Networking promotes collaboration, advancing our field collectively. <br /><br />Upholding professionalism, integrity, and dedication, I commit to advancing knowledge and practice within my domain.");
    const [redirect, setRedirect] = useState(false);
    const [userTopics, setUserTopics] = useState([]);
    const [roleType, setRoleType] = useState("SME");
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPlanId, setSelectedPlanId] = useState(1);
    useEffect(() => {
        getAllRegisterTopics();
        //getTopics();
        //  getAllRoleRequests();
        getAllSmeOfferings();
        getAllCommercialPlans();
        getAllDays();
        getAllTimeSlots();
        getSmeUserData();
    }, []);
    const handleCheck = () => {
        setAgree(!agree)
    }
    const closePopup = () => {
        setShowPopup(!showPopup)
    }

    const getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    setAllOfferings(response.data.offerings)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllDays = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllDays', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                    setAllDays(response.data.days)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllCommercialPlans = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllCommercialPlans', { headers: headers })
            .then(function (response) {
              // console.log(response);
                if (response.data.status === true) {
                    setCommercialPlans(response.data.commercialPlans)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllTimeSlots = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllTimeSlots', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    setAllTimeSlots(response.data.timeSlots)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    const getSmeUserData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getSmeUserDataById', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                    if (response.data.data.length == 0) {
                        setIsFirstTime(true)
                        setIsNew(true)
                        setShowPopup(true)
                    } else {
                        setIsFirstTime(false)
                        setIsNew(false)
                        setDesc(response.data.data[0].desc)
                        setSelectedDays(response.data.data[0].day)
                        setLinkedinLink(response.data.data[0].linkedin)
                        setSelectedTimeSlot(response.data.data[0].time)
                        setSelectedOptions(response.data?.topics?.map(topic => topic.id));
                        setSelectedPlanId(response.data.data[0].commercial)
                        setSelectedOfferings(response.data.data[0].offering)
                    }
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const postSmeOffering = (statusId) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }

        if (isFirstTime) {
            localStorage.setItem('smeStatus', 3)
        }
        // let selOptions = [];
        // selOptions = selectedOptions.map(opt => opt.value)
        var payload;
        isFirstTime ?
            payload = {
                "isFirstTime": isFirstTime,
                "offering": selectedOfferings,
                "bookings": 0,
                "linkedin": linkedinLink,
                "day": selectedDays,
                "commercial": parseInt(selectedPlanId),
                "topicId": selectedOptions,
                "status": statusId,
                "desc": desc,
                "time": parseInt(selectedTimeSlot)
            } :
            payload = {
                "isFirstTime": isFirstTime,
                "offering": selectedOfferings,
                "linkedin": linkedinLink,
                "day": selectedDays,
                "commercial": parseInt(selectedPlanId),
                "topicId": selectedOptions,
                "status": statusId,
                "desc": desc,
                "time": parseInt(selectedTimeSlot)
            }
       // console.log("selctedDays", payload)
        axios.post(config.userUrl + 'user/addSmeOffering', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                  //  console.log("success")
                    if (isFirstTime) {
                        saveInterest(true)
                    }
                    setShowProfile(true)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const handleOfferingClick = (id) => {
        if (selectedOfferings.includes(id)) {
            setSelectedOfferings(selectedOfferings.filter(offeringId => offeringId !== id)); // Deselect offering if already selected
        } else {
            if (selectedOfferings.length < 2) {
                setSelectedOfferings([...selectedOfferings, id]); // Select offering if not already selected and less than two offerings are selected
            }
        }
    };
    const handleSelectTopics = (id) => {
        if (selectedOptions.includes(id)) {
            setSelectedOptions(selectedOptions.filter(topicId => topicId !== id));
        } else {
            if (selectedOptions.length < 3) {
                setSelectedOptions([...selectedOptions, id]);
            }
        }
    };

    // const handleDayChange = (day) => {
    //     setSelectedDay(day);
    // };

    // const handleTimeSlotChange = (timeSlot) => {
    //     setSelectedTimeSlot(timeSlot);
    // };

    const handleDescChange = (e) => {
        setDesc(e.target.value);
    };

    const handleSubmit = (statusId) => {
       // console.log("value", desc, selectedTimeSlot, selectedDays, selectedOfferings)
        postSmeOffering(statusId);
    };

    const handleNext = () => {
        if (isNew) {
            setShowPopup(true)
        } else {
            setShowDescScreen(false);
        }
    };

    const handlePrevious = () => {
        setShowDescScreen(true);
    };
    const handleDayChange = (dayId) => {
        if (dayId === 'all') {
            if (selectedDays.length === allDays.length) {
                setSelectedDays([]);
            } else {
                setSelectedDays(allDays.map(day => day.id));
            }
        } else {
            if (selectedDays.includes(dayId)) {
                setSelectedDays(selectedDays.filter(id => id !== dayId));
            } else {
                setSelectedDays([...selectedDays, dayId]);
            }
        }
    };
    const getAllRoleRequests = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllRoleRequests', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setUserTopics(response.data.allRequest)
                    var newArr = []
                    for (var i = 0; i < response.data.topicDetails.length; i++) {
                        newArr[i] = ({ id: response.data.topicDetails[i].id, topicName: response.data.topicDetails[i].topicName })
                    }


                  //  console.log("new", newArr)
                    // setSelectedOptions(newArr)
                    setIsLoading(false)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getAllRegisterTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllRegisterTopics', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                    setTopics(response.data.topics)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getUserTopics', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setTopics(response.data.topics)
                    // const transformedArray = response.data.topics.map(topic => ({
                    //     value: topic.id,
                    //     label: topic.topicName
                    // }));
                    // console.log(transformedArray);
                    // setTopics(transformedArray)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const saveRole = () => {
        setShowPopup(false)
        setIsNew(false)
    }
    const saveInterest = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        // let selOptions = [];
        // selOptions = selectedOptions.map(opt => opt.value)
        var payload = {
            "flag": data,
            "topicId": selectedOptions,
            "intent": intent,
            "roleType": roleType,
            "termsAndCondition": agree
        }
        //console.log("payload", payload)
        axios.post(config.userUrl + 'user/saveRoleRequest', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    // setShowPopup(false)
                    // setIsNew(false)
                 //   console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    if (showProfile)
        return <Redirect to="/home" />
    return (
        <div >
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="screen-topgap-sme">
                {showDescScreen ? (
                    // Description screen
                    // <div className="ml-2 mr-2" style={{ border: "1px solid gray", height: "72vh", borderRadius: "20px" }}>
                    //     <div className="mt-3 abt-u_">Description</div>
                    //     <textarea 
                    //         className="ml-1"
                    //         placeholder="Enter description..."
                    //         value={desc}
                    //         onChange={handleDescChange}
                    //         style={{ width: "80%", height: "100px", resize: "none" }}
                    //     />
                    //     <div className="mt-5" style={{display:"flex",justifyContent:"space-between"}}>
                    //         <Button 
                    //             variant="default" 
                    //             className="mr-1 prev-btn-sme" 
                    //             onClick={handleNext} 
                    //             disabled={!desc} 
                    //         >
                    //             Next
                    //         </Button>
                    //     </div>
                    // </div>
                    <div>
                        <Modal show={showPopup} size="sm"
                            aria-labelledby="contained-modal-title-vcenter"

                            centered>
                            <Modal.Body>
                                <div className="mr-1">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}> <div>
                                        <div className="your-fav-sme"> Expressing interest as {roleType}</div></div><i onClick={() => setShowPopup(false)} style={{ marginTop: "-5px" }} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>


                                    <div className="mr-1">
                                        <div className="ml-2"><b style={{ fontSize: "13.5px" }}>Declaration by {roleType}</b></div>
                                        <div className="form-group">
                                            <div
                                                dangerouslySetInnerHTML={{ __html: intent }}
                                                readOnly
                                                className="richTextFieldreviewSme"
                                            />
                                        </div>
                                    </div>
                                    <div className="ml-2 mr-1" ><input type="checkbox" style={{ accentColor: "#31197C" }} checked={agree} onClick={handleCheck} /> &nbsp;<span style={{ fontWeight: "700", fontSize: "13.5px" }}>I agree</span></div><br /><br />

                                    <center>
                                        {agree ?
                                            <Button variant="default" onClick={() => saveRole(true)} className="_rolebtn_new">Proceed</Button>
                                            : <Button variant="default" disabled="true" onClick={() => saveRole(false)} className="_rolebtn_new">Proceed</Button>}
                                    </center>
                                    {/* <Link to="/home"><Button variant="default"  className="_rolebtn_new">Cancel</Button></Link> */}

                                    {/* <input type="checkbox" checked={agree} onClick={handleCheck} /> &nbsp;<span style={{ fontWeight: "700", fontSize: "13.5px" }}>I agree</span>
                                    <center><Button variant="default" className="mt-1 q-submit-btn" onClick={closePopup}>&nbsp;Submit &nbsp;</Button></center> */}
                                </div>
                            </Modal.Body>
                        </Modal>
                        <div className="your-jitbits">{isFirstTime ? "About yourself" : "About yourself"}</div>
                        <textarea type="text"
                            className="web-font form-control"
                            aria-describedby="text"
                            style={{ margin: "0px 20px", width: "89%", overflowY: "none", border: "1px solid gray", borderRadius: "15px", resize: "none" }}
                            name="linkedinLink"
                            rows={2}
                            value={linkedinLink}
                            onChange={(e) => setLinkedinLink(e.target.value)}
                            placeholder="Linkedin Link"
                        />
                        <div className="mt-3 register-sme-box">
                            <textarea type="text"
                                className="web-font form-control"
                                aria-describedby="text"
                                name="desc"
                                style={{ overflowY: "none", border: 'none', borderRadius: "20px", width: "100%", resize: "none" }}
                                value={desc}
                                onChange={handleDescChange}
                                rows={17}
                                placeholder="Add text about self"
                            />
                            <Button
                                variant="default"
                                className="mr-1 sme-next-btn"
                                onClick={handleNext}
                                disabled={!desc || !linkedinLink}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                ) : (
                    // SME offerings screen
                    <div>
                        <div className="your-jitbits flex">
                            <div onClick={handlePrevious}><img src={back} style={{ width: "30px" }} /></div>
                            <div className="mt-1 ml-1">{isFirstTime ? "About yourself" : "About yourself"}</div>
                        </div>
                        <div className="sme-connect-box">
                            <div className="mt-1 mb-2 abt-u_" style={{ marginTop: "-7px" }}>Your expertise (Select 3)</div>
                            {/* <div className="ml-2 mr-2 mb-3"> */}

                            <div className="mt-2 ml-2 mr-2" style={{ border: "1px solid gray", height: "82px", borderRadius: "20px", overflow: "hidden" }}>
                                <div className="interests_main">
                                    <ScrollMenu>
                                        <center className="flex" style={{ padding: "0.8px 4px", gap: "3px", marginTop: "1px" }}>
                                            {topics.map((data, i) => (
                                                <div
                                                    className="mt-2"
                                                    key={data.id}
                                                    onClick={() => handleSelectTopics(data.id)}
                                                    style={{
                                                        width: "calc(5.4rem - 2px)", // Account for the border width
                                                        border: selectedOptions.includes(data.id) ? "2px solid #ff942c" : "",
                                                        borderRadius: "15px",
                                                        padding: "4px",
                                                        cursor: "pointer",
                                                        boxSizing: "border-box" // Ensure border-box for each item
                                                    }}
                                                >
                                                    <img src={data.topicName.replace(/ *\([^]*\) */g, "") + ".png"} style={{ width: "25px" }} alt={data.topicName} /><br />
                                                    <div className="sme-off-text">{data.topicName.replace(/ *\([^]*\) */g, "")}</div>
                                                </div>
                                            ))}
                                        </center>
                                    </ScrollMenu>
                                </div>
                            </div>
                            <div className="mt-2 abt-u_">Choose your offering (Max 2) </div>
                            <div className="mt-2 ml-2 mr-2 mb-2" style={{ border: "1px solid gray", borderRadius: "20px", height: "fit-content", padding: "2px" }}>
                                <center className="flex" style={{ gap: "3px" }}>
                                    {allOfferings.map((data, i) => (
                                        <div
                                            key={data.id}
                                            onClick={() => handleOfferingClick(data.id)}
                                            style={{
                                                border: `${selectedOfferings.includes(data.id) ? "2px solid #ff942c" : "2px solid transparent"}`,
                                                width: "4.5rem",
                                                borderRadius: "15px",
                                                cursor: "pointer",
                                                boxSizing: "border-box"
                                            }}
                                        >
                                            <img src={'../' + data.text + '.svg'} alt={data.text} /><br />
                                            <div className="sme-off-text">{data.text}</div>
                                        </div>
                                    ))}
                                </center>
                                <div className="mb-1"></div>
                            </div>

                            <div className="mt-2 abt-u_">Slot (30 mins) IST</div>
                            <div className="mt-2 ml-2 mr-2 mb-2" style={{ border: "1px solid gray", borderRadius: "20px" }}>
                                <div className="mb-1" style={{ marginTop: "-5px" }}>
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        {allTimeSlots.slice(0, 1).map((data, i) => (
                                            <Col className="slot-time" onClick={() => setSelectedTimeSlot(data.id)}><center><div style={selectedTimeSlot === data.id ? { width: "71%", border: "2px solid #ff942c", borderRadius: "10px", padding: "0px 2px 2px 2px" } : { border: "none", borderRadius: "10px", padding: "0px 2px 2px 2px" }}><img src={smefullday} /><TimeRange time={data.time} /></div></center></Col>
                                        ))}
                                        {allTimeSlots.slice(1, 2).map((data, i) => (
                                            <Col className="slot-time" onClick={() => setSelectedTimeSlot(data.id)}><center><div style={selectedTimeSlot === data.id ? { width: "71%", border: "2px solid #ff942c", borderRadius: "10px", padding: "0px 2px 2px 2px" } : { border: "none", borderRadius: "10px", padding: "0px 2px 2px 2px" }}><img src={smemorning} /><TimeRange time={data.time} /></div></center></Col>
                                        ))}
                                        {allTimeSlots.slice(2, 3).map((data, i) => (
                                            <Col className="slot-time" onClick={() => setSelectedTimeSlot(data.id)}><center><div style={selectedTimeSlot === data.id ? { width: "71%", border: "2px solid #ff942c", borderRadius: "10px", padding: "0px 2px 2px 2px" } : { border: "none", borderRadius: "10px", padding: "0px 2px 2px 2px" }}><img src={smeafternoon} /><TimeRange time={data.time} /></div></center></Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                            <div className="mt-2 abt-u_">Choose days you can connect</div>
                            <div className="mt-2 ml-2 mr-2 mb-2" style={{ border: "1px solid gray", borderRadius: "20px" }}>
                                {/* {allDays.map((data, i) => (
                                        <div key={data.day} className="ml-1 sme-txt-size">
                                            <input
                                                type="radio"
                                                name="days"
                                                value={data.day}
                                                checked={selectedDay === data.id}
                                                onChange={() => setSelectedDay(data.id)}
                                                style={{ borderWidth: "3px", border: "3px solid black" }}
                                            />
                                            {data.day}
                                        </div>
                                    ))} */}

                                {/* <div className="ml-1 mb-2 flex" style={{ gap: "3px", flexWrap: "wrap" }}>
                                    <div className="ml-1 sme-txt-size" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            name="allDays"
                                            value="all"
                                            style={{ accentColor: "#31197C", marginTop: "2px" }}
                                            checked={selectedDays.length === allDays.length}
                                            onChange={() => handleDayChange('all')}
                                        />
                                        <span className="ml-1" style={{ marginTop: "2px" }}>All Days</span>
                                    </div>

                                    <div className="weekdays-checkboxes" style={{ display: 'flex', alignItems: 'center' }}>
                                        {allDays.slice(0, 5).map((data, i) => (
                                            <div key={data.id} className="ml-1 sme-txt-size" style={{ display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    name="days"
                                                    value={data.id}
                                                    checked={selectedDays.includes(data.id)}
                                                    onChange={() => handleDayChange(data.id)}
                                                    style={{ accentColor: "#31197C", marginTop: "2px", marginLeft: "7px" }}
                                                />
                                                <span className="ml-1" style={{ marginTop: "2px" }}>{data.day}</span>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="weekend-checkboxes" style={{ display: "flex", gap: "7px", flexWrap: "wrap" }}>
                                        {allDays.slice(5).map((data, i) => (
                                            <div key={data.id} className="ml-1 sme-txt-size" style={{ display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    name="days"
                                                    value={data.id}
                                                    checked={selectedDays.includes(data.id)}
                                                    onChange={() => handleDayChange(data.id)}
                                                    style={{ accentColor: "#31197C", marginTop: "2px" }}
                                                />
                                                <span className="ml-1" style={{ marginTop: "2px" }}>{data.day}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div> */}
                                <div className="ml-1 mb-2 flex" style={{ gap: "1px", flexWrap: "wrap", alignItems: "flex-start" }}>
                                    {/* All Days */}
                                    <div className="sme-txt-size" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            name="allDays"
                                            value="all"
                                            style={{ accentColor: "#31197C", marginTop: "2px", marginLeft: "5px" }}
                                            checked={selectedDays.length === allDays.length}
                                            onChange={() => handleDayChange('all')}
                                        />
                                        <span className="ml-1" style={{ marginTop: "2px" }}>All Days</span>
                                    </div>

                                    {/* Mon-Fri */}
                                    {/* <div className="weekdays-checkboxes" style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: "wrap" }}>
                                        {allDays.slice(0, 5).map((data, i) => (
                                            <div key={data.id} className="ml-1 sme-txt-size" style={{ display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    name="days"
                                                    value={data.id}
                                                    checked={selectedDays.includes(data.id)}
                                                    onChange={() => handleDayChange(data.id)}
                                                    style={{ accentColor: "#31197C", marginTop: "2px"}}
                                                />
                                                <span className="ml-1" style={{ marginTop: "2px" }}>{data.day}</span>
                                            </div>
                                        ))}
                                    </div> */}
                                    <div className="weekend-checkboxes" style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                                        {allDays.slice(0, 5).map((data, i) => (
                                            <div key={data.id} className="ml-1 sme-txt-size" style={{ display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    name="days"
                                                    value={data.id}
                                                    checked={selectedDays.includes(data.id)}
                                                    onChange={() => handleDayChange(data.id)}
                                                    style={{ accentColor: "#31197C", marginTop: "1px" }}
                                                />
                                                <span className="ml-1" style={{ marginTop: "1px" }}>{data.day}</span>
                                            </div>
                                        ))}
                                    </div>

                                    {/* Sat-Sun */}
                                    <div className="weekend-checkboxes" style={{ display: "flex", gap: "27px", flexWrap: "wrap" }}>
                                        {allDays.slice(5).map((data, i) => (
                                            <div key={data.id} className="ml-1 sme-txt-size" style={{ display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    name="days"
                                                    value={data.id}
                                                    checked={selectedDays.includes(data.id)}
                                                    onChange={() => handleDayChange(data.id)}
                                                    style={{ accentColor: "#31197C", marginTop: "1px" }}
                                                />
                                                <span className="ml-1" style={{ marginTop: "1px" }}>{data.day}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="mt-2o abt-u_">Fees (INR)</div>
                            <div className="mt-2 ml-2 mr-2 mb-2" style={{ border: "1px solid gray", borderRadius: "15px" }}>
                                <div className="ml-2 mr-2 mb-2 flex" style={{ justifyContent: "space-between" }}>

                                    {commercialPlans.map((data, i) => (
                                        <div className="ml-1 sme-txt-size" style={{ display: "flex" }}>
                                            <input
                                                type="radio"
                                                name="plans"
                                                value={data.id}
                                                checked={selectedPlanId === data.id}
                                                disabled={data.id === 2 || data.id === 3 || data.id === 4}
                                                onChange={() => setSelectedPlanId(data.id)}
                                                style={{ accentColor: "#31197C" }}
                                            />
                                            <span className="ml-1" style={{ marginTop: "-1px" }}>{data.plan}</span>
                                        </div>
                                    ))}
                                </div>
                            </div> */}
                            <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                {/* <Button variant="default" className="ml-2 mt-1 prev-btn-sme" onClick={handlePrevious}>Previous</Button> */}
                                <div></div>
                                <Button variant="default" className="mr-2 mb-1 sme-connect-next-btn" style={{ position: "absolute", bottom: "4.5rem", right: "5px" }} onClick={() => handleSubmit(isFirstTime ? 2 : 1)}>{isFirstTime ? "Register" : "Update"}</Button>

                            </div>
                            <div className="mb-2"></div>
                        </div>
                    </div>
                )}
            </div>
            <div className="mb-bottom"></div>
            <BottomMenu />
        </div >
    )
}

function TimeRange({ time }) {
    const openingBracketIndex = time.indexOf(' (');
    const dayPart = openingBracketIndex !== -1 ? time.slice(0, openingBracketIndex) : time;
    const closingBracketIndex = openingBracketIndex !== -1 ? time.indexOf(')', openingBracketIndex) : -1;
    const displayTimePart = closingBracketIndex !== -1 ? time.slice(openingBracketIndex + 2, closingBracketIndex) : '';

    return (
        <div style={{ marginTop: "-3px", lineHeight: "13px" }}>
            <span style={{ fontSize: "11px" }}>{dayPart}</span><br />
            <span style={{ whiteSpace: "nowrap", fontSize: "11px" }}>{displayTimePart}</span>
        </div>
    );
}

{/* <div className="ml-1 mb-2 flex" style={{gap: "10px", flexWrap: "wrap" }}>
<div className="ml-1 sme-txt-size" style={{display: 'flex', alignItems: 'center' }}>
    <input
        type="checkbox"
        name="allDays"
        className="ml-3"
        value="all"
        style={{ accentColor: "#31197C", marginTop: "2px" }}
        checked={selectedDays.length === allDays.length}
        onChange={() => handleDayChange('all')}
    />
    <span className="ml-1" style={{ marginTop: "2px" }}>All Days</span>
</div>

<div className="weekdays-checkboxes" style={{ display: 'flex', alignItems: 'center'}}>
    {allDays.slice(0, 3).map((data, i) => (
        <div key={data.id} className="ml-1 sme-txt-size" style={{ display: 'flex', alignItems: 'center' }}>
            <input
                type="checkbox"
                name="days"
                value={data.id}
                checked={selectedDays.includes(data.id)}
                onChange={() => handleDayChange(data.id)}
                style={{ accentColor: "#31197C", marginTop: "2px",marginLeft:"30px" }}
            />
            <span className="ml-1" style={{ marginTop: "2px" }}>{data.day}</span>
        </div>
    ))}
</div>

<div className="weekend-checkboxes" style={{ marginLeft:"10%",display:"flex",gap: "30px", flexWrap: "wrap"}}>
    {allDays.slice(3).map((data, i) => (
        <div key={data.id} className="ml-1 sme-txt-size" style={{ display: 'flex', alignItems: 'center' }}>
            <input
                type="checkbox"
                name="days"
                value={data.id}
                checked={selectedDays.includes(data.id)}
                onChange={() => handleDayChange(data.id)}
                style={{ accentColor: "#31197C", marginTop: "2px" }}
            />
            <span className="ml-1" style={{ marginTop: "2px" }}>{data.day}</span>
        </div>
    ))}
</div>
</div> */}

{/* <div className="ml-1 mb-2 flex" style={{ gap: "1px", flexWrap: "wrap", justifyContent: "center" }}>
<div className="sme-txt-size" style={{ display: 'flex', alignItems: 'center' }}>
    <input
        type="checkbox"
        name="allDays"
        value="all"
        style={{ accentColor: "#31197C", marginTop: "2px" }}
        checked={selectedDays.length === allDays.length}
        onChange={() => handleDayChange('all')}
    />
    <span className="ml-1" style={{ marginTop: "2px" }}>All Days</span>
</div>

<div className="weekdays-checkboxes" style={{ display: 'flex', alignItems: 'center' }}>
    {allDays.slice(0, 3).map((data, i) => (
        <div key={data.id} className="ml-1 sme-txt-size" style={{ display: 'flex', alignItems: 'center' }}>
            <input
                type="checkbox"
                name="days"
                value={data.id}
                checked={selectedDays.includes(data.id)}
                onChange={() => handleDayChange(data.id)}
                style={{ accentColor: "#31197C", marginTop: "2px", marginLeft: "1.8rem" }}
            />
            <span className="ml-1" style={{ marginTop: "2px" }}>{data.day}</span>
        </div>
    ))}
</div>

<div className="weekend-checkboxes" style={{ display: "flex", gap: "30px", flexWrap: "wrap" }}>
    {allDays.slice(3).map((data, i) => (
        <div key={data.id} className="ml-1 sme-txt-size" style={{ display: 'flex', alignItems: 'center' }}>
            <input
                type="checkbox"
                name="days"
                value={data.id}
                checked={selectedDays.includes(data.id)}
                onChange={() => handleDayChange(data.id)}
                style={{ accentColor: "#31197C", marginTop: "1px" }}
            />
            <span className="ml-1" style={{ marginTop: "1px" }}>{data.day}</span>
        </div>
    ))}
</div>
</div> */}