import React, { Component } from "react";
import { Button, Form, Col, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import forgotImg from '../images/forgotImg.png';
import './Login.css';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)
// var encryptor = require('simple-encryptor')(process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY);

const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/
export default class NewPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.location.state.email,
            authKey: this.props.location.state.authKey,
            password: "",
            confirmPassword: "",
            showPassword: false,
            showConfirmPassword: false,
            passwordError: "",
            confirmPasswordError: "",
            redirect: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
        this.validateField = this.validateField.bind(this);
    }
    onChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }
    handleBlur(event) {
        const { name } = event.target;
        this.validateField(name);
        return;
    }
    showPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };
    showConfirmPassword = () => {
        this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    };
    validatePassword() {
        let passwordError = "";
        const value = this.state.password;
        if (value.trim === "") passwordError = "Password is required";
        else if (!validPasswordRegex.test(value))
            passwordError = "Minimum 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        this.setState({
            passwordError
        });
        return passwordError === "";
    }

    validateConfirmPassword() {
        let confirmPasswordError = "";
        if (this.state.password !== this.state.confirmPassword)
            confirmPasswordError = "Password does not match";
        this.setState({
            confirmPasswordError
        });
        return confirmPasswordError === "";
    }
    validateField(name) {
        let isValid = false;
        if (name === "password") isValid = this.validatePassword();
        else if (name === "confirmPassword")
            isValid = this.validateConfirmPassword();
        return isValid;
    }
    handleSubmit(event) {
        event.preventDefault();
       // console.log(this.state);
        let formFileds = [
            "password",
            "confirmPassword"
        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = this.validateField(field) && isValid;
        });
        if (!isValid) {
           // console.log("Please enter the details")
            document.getElementById('status').innerHTML = "<div>Please enter valid password</div>"
        } else {
            this.resetPassword()
        }
    }
    componentDidMount() {
       // console.log("key")
    }
    resetPassword = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var encryptedPassword = simpleCrypto.encrypt(this.state.password);
        // var encryptedConfirmPassword = encryptor.encrypt(this.state.confirmPassword);
        var self = this;
        var payload = {
            "email": this.state.email,
            "password": encryptedPassword,
            "authKey": this.state.authKey
        }
        axios.post(config.apiUrl + 'auth/forgotPassword/resetPassword', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                   // console.log("Password reset success!!")
                    self.setState({
                        redirect: true
                    })
                } else {
                    console.log(response.data.message)
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
                console.log("error", payload,error)
                document.getElementById('error').innerText = error
            });
    }
    render() {
        const { redirect } = this.state
        if (redirect) {
            return <Redirect
                to={{
                    pathname: "/",
                    state: { email: this.state.email }
                }} />
        }
        return (
            <div>
                <img alt="" src={loginlogo} className="login-logo" />
                <p className="forgot-text newpassword newpassword-left"><span style={{ color: "#FF942C" }}>New</span> password</p>
                <p className="mt-4 forgot-text-sm newpassword-left">Reset your password to recovery<br /> & login to your account.</p>
                <Form className="forgot-email" onSubmit={this.handleSubmit}>
                    <center>
                        <div style={{ width: "80%" }}>
                            <div className="form-group">
                                <input type={this.state.showPassword ? "text" : "password"}
                                    name="password"
                                    className="form-control form-bg"
                                    value={this.state.password}
                                    placeholder="New Password"
                                    onChange={this.onChange}
                                    onBlur={this.handleBlur}
                                />
                                <span className="showPassword"
                                    onClick={this.showPassword}
                                >
                                    {this.state.showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                </span>
                                <div className="div">{this.state.passwordError && (
                                    <div className="error">{this.state.passwordError}</div>
                                )}</div>
                            </div>
                            <div className="form-group mt-4">
                                <input type={this.state.showConfirmPassword ? "text" : "password"}
                                    name="confirmPassword"
                                    className="form-control form-bg"
                                    value={this.state.confirmPassword}
                                    placeholder="Retype Password"
                                    onChange={this.onChange}
                                    onBlur={this.handleBlur}
                                />
                                <span className="showPassword"
                                    onClick={this.showConfirmPassword}
                                >
                                    {this.state.showConfirmPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                </span>
                                <div className="div">{this.state.confirmPasswordError && (
                                    <div className="error">{this.state.confirmPasswordError}</div>
                                )}</div>
                            </div>
                            <div id="error" className="errormsg"></div>
                        </div><br />
                        <Button variant="default" type="submit" className="btn-block login-btn">Confirm</Button><br />
                    </center>
                </Form>
            </div>
        );
    }
}
