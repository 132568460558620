import React, { Component } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import loginlogo from '../images/login-logo.png';
import PricingData from "./PricingData.json";
import BottomMenu from "../BottomMenu/BottomMenu";
import './Pricing.css';
import { Link, Redirect } from 'react-router-dom';
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
// import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "../LandingPage/arrowsOnBottomOrTop.css";
import "../LandingPage/hideScrollbar.css";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            plans: [],
            navigate: false,
            planName: "",
            pricingPlan: false
        }

    }
    componentDidMount() {
        this.getSubscription();
    }
    getSubscription = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getSubscription', { headers: headers })
            .then(function (response) {
               // console.log("res", response)
                if (response.data.status === true) {
                    if (response.data.message == "free plan") {
                        self.setState({
                            pricingPlan: false
                        })
                    } else {
                        self.setState({
                            pricingPlan: true,
                            plans: response.data.subscriptionHistory
                        })
                    }
                } else {
                    console.log("error")
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    navigateToSubscription = (pName) => {
        this.setState({ navigate: true, planName: pName })
    }
    render() {
        if (this.state.navigate) {
            return <Redirect
                to={{
                    pathname: "/subscription",
                    state: { pName: this.state.planName }
                }} />
        }
        if (this.state.pricingPlan) {
            return (
                <div>
                    <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>

                    <div className="screen-topgap">
                        <Row style={{ width: "100%" }}>
                            {this.state.plans.map((data, id) => {
                                return (
                                    <Col xs={12} className="pricing-ml">
                                        <div><span className="your-jitbits">Current Subscription</span> ({data.endDate.split("T")[0].split("-").reverse().join("-")})</div><br /><br /><br /><br />
                                        <Card className="no-scroll-pricing ">
                                            <center className="free">{data.planName}<br /></center>
                                            {data.planName == "Integrator" ? <div>
                                                <div className="mt-4"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Anticipator Plus</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Multiply rewards by 2 times</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Get verified tag</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Play Games</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Register as a SME</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Access to Sceemlore</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Ask a sceemER</span></div>
                                            </div> : (data.planName == "Anticipator" ? <div>
                                                <div className="mt-4"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Read, Save articles</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Download articles</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Answer polls</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Enjoy sceemWORD</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Publish opportunities</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Connect and follow sceemERs</span></div>
                                            </div> : (data.planName == "Collaborator" ? <div>
                                                <div className="mt-4"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Integrator Plus</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Multiply rewards by 2.5 times</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Become an author or reviewer</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Create Polls</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Advertise 6 times/year</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">1 year free entropi registration</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Promote your business on entropi</span></div>
                                            </div>
                                                : <div>
                                                    <div className="mt-4"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Collaborator Plus</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Multiply rewards by 3 times</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Business consultancy</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Technology consultancy</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Product consultancy</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Complete access to entropi</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Open API integration from entropi</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">1 year Access to Byrds/FeddUp/Conerqube</span></div>
                                                </div>))}

                                            <center className="money-color"><span className="mt-1 rupee">&#8377;</span> {data.planAmount}/year</center>
                                        </Card><br />
                                        <center className="start ml-3 mt-3"><Button onClick={() => this.navigateToSubscription(data.planName)} variant="default" className="get-btn">Upgrade</Button></center>

                                    </Col>
                                )
                            })}
                        </Row>
                    </div ><br />
                    < BottomMenu />
                </div >
            )
        } else {
            return (
                <div>
                    <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                    <div style={{ marginTop: "4rem" }}>
                        <Row style={{ width: "100%" }}>
                            {PricingData.pricing.slice(0, 1).map((data, id) => {
                                return (
                                    <Col xs={12} className="pricing-ml">
                                        <div><span className="your-jitbits">Current Subscription</span> </div><br /><br /><br /><br />
                                        <Card className="no-scroll-pricing ">
                                            <center className="free">{data.planName}</center>
                                            <div className="mt-4"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">{data.planDescription[0]}</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">{data.planDescription[1]}</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">{data.planDescription[2]}</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">{data.planDescription[3]}</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">{data.planDescription[4]}</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">{data.planDescription[5]}</span></div>
                                            <div className="mt-2">{data.planDescription[6] ? <AiOutlineCheck className="ml-2 react-icon" /> : <span></span>}<span className="ml-2 pricing-inner-text">{data.planDescription[6]}</span></div>
                                            <div className="mt-2">{data.planDescription[7] ? <AiOutlineCheck className="ml-2 react-icon" /> : <span></span>}<span className="ml-2 pricing-inner-text">{data.planDescription[7]}</span></div>
                                            <center className="money-color"><span className="mt-1 rupee">&#8377;</span> {data.planAmount}</center>
                                        </Card><br />
                                        <center className="start ml-3 mt-3"><Button variant="default" onClick={() => this.navigateToSubscription(data.planName)} className="get-btn">Upgrade</Button></center>

                                    </Col>
                                )
                            })}
                        </Row>
                    </div ><br />
                    < BottomMenu />
                </div >
            )
        }
    }
}
