import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import sceemlogo from '../images/sceem-logo.svg';
import announcement from '../images/announcement.svg';

export default function AnnouncementPopup({ msg,  closeAnnouncementPopup }) {
    const [sec, setSec] = useState()
    const [showAnnouncement, setShowAnnouncement] = useState(true)
    useEffect(() => {
      //  console.log("announcementpopup", msg)
        let timeleft = 10;
        var downloadTimer = setInterval(function () {
            if (timeleft <= 1) {
                clearInterval(downloadTimer);
            } else {
                setSec(timeleft + " seconds")
                // document.getElementById("countdown").innerHTML = timeleft + " seconds";
            }
            timeleft -= 1;
        }, 1000);
    }, [])
    useEffect(()=>{
        setTimeout(() => {
            setShowAnnouncement(false)
        }, 10000)
    },[])
    return (
        // <Modal show={true} onHide={() => closeAnnouncementPopup} size="sm" centered >
        //     <Modal.Body>
        //         <center><img src={sceemlogo} className="announcement-sceem-logo" /></center>
        //         <h5 className="mt-2"><b>Announcement</b></h5>
        //         <center>   <img src={announcement} className="announcement-sceem-icon" /></center>
        //         <p className="mt-2">{msg}</p>
        //         {showAnnouncement ? <center><div>{sec}</div></center> :
        //             <Button variant="default" onClick={closeAnnouncementPopup} className="deletebutton">Ok</Button>}
        //     </Modal.Body>
        // </Modal>
        <div className='popup-box' style={{zIndex:1000}}>
            <div className='_box-popup'>
                {/* <span className="close-icon-new" onClick={closeAnnouncementPopup}> &times;</span> */}
                <center>
                    <center><img src={sceemlogo} className="announcement-sceem-logo" /></center>
                    <h5 className="mt-2"><b>Announcement</b></h5>
                    <center>   <img src={announcement} className="announcement-sceem-icon" /></center>
                    <p className="mt-2">{msg}</p>
                    {showAnnouncement ? <center><div>{sec}</div></center> :
                        <Button variant="default" onClick={closeAnnouncementPopup} className="deletebutton">Ok</Button>}
                </center>
            </div>
        </div>
    )
}


