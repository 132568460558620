import React, { Component } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import './Interests.css';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class FavAuthors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            profileIcons: [],
            isLoading: true,
            checked: false,
            profileUrl: "",
            verified: false,
            redirect: false,
            checkStatus: false

        }
    }
    componentDidMount() {
        this.getProfileIcons();
        this.checkStatus();
    }
    getProfileIcons = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getProfilePictures', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                  //  console.log("success");
                    self.setState({
                        profileIcons: response.data.getProfilePictures,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });

    };
    checkStatus = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/checkUserStatus', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    if (response.data.userStatus === "profileAdded") {
                        self.setState({ checkStatus: true })
                    } else {
                        self.setState({ checkStatus: false })
                    }
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    checkUserStatus = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/checkUserStatus', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    if (response.data.userStatus === "profileAdded") {
                        self.setState({ verified: true })
                    } else {
                        self.setState({ redirect: true })
                    }
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    updateProfilePicture = () => {
        const { profileUrl } = this.state;
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "profileUrl": profileUrl
        }
       // console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + 'user/updateProfilePicture', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    localStorage.setItem("profilePic", profileUrl)
                    self.checkUserStatus();
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });

    };

    selectAvatar = (e) => {
        this.setState({
            profileUrl: e.target.value,
        })
    }
    render() {
        const { redirect, verified, checked, profileIcons, profileUrl } = this.state;
        if (verified) {
            return <Redirect to="/profile" />
        }
        if (redirect) {
            return <Redirect to="/userdetails" />
        }

        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <div style={{ marginTop: "4rem" }}>
                    <div className="explore-text mt-4 ml-4">Add your Avatar</div>
                    <center>
                        <div className="ml-4 error">{this.state.msg}</div>
                        <Row style={{ width: "100%" }}>
                            {profileIcons.map((data, id) => {
                                return (
                                    <Col xs={4}>
                                        <input type="radio"
                                            value={data.profileUrl}
                                            name="profileUrl"
                                            onChange={this.selectAvatar}
                                            className="check_checkbox"
                                        /><div class="single_check_card">
                                            <img alt="" src={config.userUrl + data.profileUrl} className="favauthor" />
                                        </div>

                                    </Col>
                                )
                            })}
                        </Row>
                    </center>
                </div>{this.state.checkStatus ?
                    <div>   <Button variant="default" onClick={this.updateProfilePicture} className="landing-btn float">Next</Button>

                        <br /><br /><br /><br /><br /></div> : <div>   <Button variant="default" onClick={this.updateProfilePicture} className="landing-btn float">Save</Button>

                        <br /><br /><br /><br /><br />
                        <BottomMenu /></div>}
            </div >
        )
    }
}
