// InstallButton.js
import React, { useState, useEffect } from 'react';
import download from "../images/download.svg";
import logo from '../images/logo-new.png';
function InstallButton() {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showInstallButton, setShowInstallButton] = useState(false);
    const [isAppInstalled, setIsAppInstalled] = useState(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            // Prevent the default browser install prompt
            event.preventDefault();
            // Store the event so it can be triggered later
            setDeferredPrompt(event);
            setShowInstallButton(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    useEffect(() => {
        // Check if the app is already installed
        if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
            setIsAppInstalled(true);
        }
    }, []);

    useEffect(() => {
        let popupInterval;

        if (!isAppInstalled) {
            // Show the install button every 2 minutes (120,000 milliseconds)
            popupInterval = setInterval(() => {
                setShowInstallButton(true);

                // Set a timer to hide the popup after 30 seconds (30,000 milliseconds)
                setTimeout(() => {
                    setShowInstallButton(false);
                }, 10000);
            }, 20000);

            return () => {
                clearInterval(popupInterval);
            };
        }
    }, [isAppInstalled]);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            // Show the installation prompt
            deferredPrompt.prompt();

            // Wait for the user's choice
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                   // console.log('User accepted the install prompt');
                    setIsAppInstalled(true)
                } else {
                   // console.log('User dismissed the install prompt');
                }
            });
        }
    };

    return (
        <div>
            {showInstallButton && (
                <div className="install-popup">
                    <div id="installButton" className='insBtn'>
                        <div className='inner-insBtn'>
                            <img src={logo} style={{ width: "3rem" }} />
                            <span className='whitespace-nowrap ml-1'> Install Sceem App</span>
                        </div>
                        <p className='install-text' onClick={handleInstallClick}>Install</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default InstallButton;
