import React, { Component } from "react";
import { Button, Form,Modal,Card} from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import circle from '../images/circle.png';
import circleblue from '../images/circle-blue.png';
import './Login.css'
import Loading from "../Loading/Loading";
import axios from 'axios';
import Version from '../../package.json';
import { withSnackbar, enqueueSnackbar } from "notistack";
import { config } from '../Config/Config';
import updateNotificationEndpoint from './UpdateNotificationEndPoint';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            token: "",
            showPassword: false,
            redirect: false,
            profileAdded: false,
            descriptionAdded: false,
            topicsAdded: false,
            followersAdded: false,
            verify: false,
            registerFlow: true,
            isLoading: true,
            loginError: "",
            res: [],
            name: "",
            version: "",
            currentVersion: null,
            showUpdateButton: false,
            roleName: ""
        };
        // this.currentPathname = null;
        // this.currentSearch = null;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    // componentDidMount() {
    //     this.fetchVersionNumber();
    //   }

    //   fetchVersionNumber = async () => {
    //     // Simulated fetch operation, replace this with your actual logic
    //     const newVersion = await this.getVersionNumber();
    //     if (newVersion !== this.state.version) {
    //       this.setState({ showUpdateButton: true });
    //     }
    //   };

    //   getVersionNumber = async () => {
    //     // Simulated fetch operation, replace this with your actual logic
    //     return '1.1.0'; // For example, fetch the version from a server or compare it with a local value
    //   };

    //   handleUpdateClick = () => {
    //     const updatedVersion = this.incrementVersion(this.state.version);
    //     this.setState({ version: updatedVersion });
    //     // Perform any other update logic here
    //   };

    //   incrementVersion = (currentVersion) => {
    //     const [major, minor, patch] = currentVersion.split('.').map(Number);
    //     let newPatch = patch + 1;
    //     if (newPatch > 9) {
    //       newPatch = 0;
    //       const newMinor = minor + 1;
    //       return `${major}.${newMinor}.0`;
    //     } else {
    //       return `${major}.${minor}.${newPatch}`;
    //     }
    //   };
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    componentDidMount() {
        window.addEventListener("beforeunload", this.logoutLocal);

    }
    // handleUpdateClick = () => {
    //     localStorage.clear();
    //     window.location.reload(true);
    // };
    handleSubmit(event) {
        event.preventDefault();
        localStorage.setItem('email', this.state.email);
        this.validateLoginDetails();
        localStorage.setItem('name', JSON.stringify(this.state.name));
    }
    // getLatestVersion = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "applicationType": "mobile"
    //     }
    //     var self = this;
    //     axios.get(config.userUrl + 'user/getLatestVersion')
    //         .then(function (response) {
    //             if (response.data.status === true) {
    //                 self.setState({ version: response.data.versionDetails.version })
    //                 localStorage.setItem('version', response.data.versionDetails.version)
    //             } else {
    //                 console.log("error")
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("error", error);
    //         });
    // };
    checkUserStatus = (accessToken) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + accessToken,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/checkUserStatus', { headers: headers })
            .then(function (response) {
                //console.log(headers, response);
                //const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                   // console.log("successfull");
                    if (response.data.userStatus === "verified") {
                        self.setState({ profileAdded: true })
                    } else if (response.data.userStatus === "profileAdded") {
                        self.setState({ redirect: true })
                    } else if (response.data.userStatus === "descriptionAdded") {
                        self.setState({ descriptionAdded: true })
                    } else if (response.data.userStatus === "topicsAdded") {
                        self.setState({ topicsAdded: true })
                    } else {
                        self.setState({ followersAdded: true })
                        // this.getAdvertisements();
                    }
                } else {
                  //  console.log("error")
                    document.getElementById('status').innerText = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };

    verifyOtp = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var payload = {
            "email": this.state.email,
            "registerFlow": this.state.registerFlow
        }
        axios.post(config.apiUrl + 'auth/sendOtp', payload, { headers: headers })
            .then(function (response) {
                const msg = "Otp sent successfully!!";
                if (response.data.status === true) {
                  //  console.log("Otp sent successfully!!")
                  //  console.log("payload", payload)
                    document.getElementById('otp').innerText = msg
                } else {
                    console.log(response.data.message)
                  //  console.log("err", payload)
                }
            })
            .catch(function (error) {
                console.log("error", payload,error)
            });
    }
    validateLoginDetails = (accessToken) => {
        var encryptedPassword = simpleCrypto.encrypt(this.state.password);
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + accessToken,
            "applicationType": "mobile"
        }
        var payload = {
            "email": this.state.email,
            "password": encryptedPassword
        }

        var self = this
        axios.post(config.apiUrl + 'auth/login', payload, { headers: headers })
            .then(function (response) {
                //console.log("login");
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true && response.data.role !== "admin") {
                //    console.log("Login successfull");
                    updateNotificationEndpoint(response.data.uuid)
                    localStorage.setItem("junkStore", simpleCrypto.encrypt(response.data.accessToken))
                    localStorage.setItem('name', response.data.name)
                    localStorage.setItem("role", response.data.role)
                    localStorage.setItem("planType", response.data.merchantType)
                    localStorage.setItem("smeStatus", response.data.smeStatus)
                    localStorage.setItem("smeType", response.data.smeType)
                    self.setState({
                        token: response.data.accessToken,
                        name: response.data.name

                    })
                    const accessToken = response.data.accessToken
                    self.checkUserStatus(accessToken)
                    if (response.data.announcement.length != 0) {
                        localStorage.setItem("announcement", response.data.announcement[0].announcement)
                    } else {
                      //  console.log("No announcement")
                    }
                    if (response.data.message2) {
                        localStorage.setItem("msg", "resetPassword")
                    } else {
                        //console.log("No message")
                    }
                } else if (response.data.status === true && response.data.role === "admin") {
                    // console.log(msg)
                    document.getElementById('status').innerHTML = "<div>Admin do not have access to login</div>"
                    window.location.href = "/"
                } else if (response.data.status === false && response.data.userStatus === "unverified") {
                    self.setState({ verify: true })
                } else {
                  //  console.log(msg)
                    document.getElementById('status').innerText = msg

                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    showPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };
    // componentDidMount() {
    //     // this.getLatestVersion();
    //     const storedVersion = localStorage.getItem('version');
    //     if (storedVersion && storedVersion === this.state.version) {
    //         console.log("version store", storedVersion, this.state.version)
    //         this.setState({ showUpdateButton: false });
    //     } else {
    //         this.setState({ showUpdateButton: true });
    //     }
    // }

    // componentDidMount() {
    //     setTimeout(() => { this.setState({ isLoading: false }) }, 2400);
    //     const { history } = this.props;
    //     history.listen((newLocation, action) => {
    //         if (action === "PUSH") {
    //             if (
    //                 newLocation.pathname !== this.currentPathname ||
    //                 newLocation.search !== this.currentSearch
    //             ) {
    //                 this.currentPathname = newLocation.pathname;
    //                 this.currentSearch = newLocation.search;

    //                 history.push({
    //                     pathname: newLocation.pathname,
    //                     search: newLocation.search
    //                 });
    //             }
    //         } else {
    //             history.go(1);
    //         }
    //     });
    // }

    // componentWillUnmount() {
    //     window.onpopstate = null;
    // }

    componentDidMount() {
        const storedVersion = localStorage.getItem('version');
        if (storedVersion) {
            if (storedVersion !== Version.version) {
                // console.log("Version mismatch. Stored version:", storedVersion, "Current version:", Version.version);
                this.setState({ currentVersion: Version.version, showUpdateButton: true });
            } else {
                // console.log("Version matches. Version:", Version.version);
                this.setState({ currentVersion: Version.version });
                localStorage.setItem('version', Version.version);
            }
        } else {
            // console.log("No version found in localStorage. Setting current version:", Version.version);
            this.setState({ currentVersion: Version.version });
            localStorage.setItem('version', Version.version);
        }
    }



    handleUpdateButtonClick = () => {
        this.setState({ showUpdateButton: false });
        if ('caches' in window) {
            caches.keys().then(cacheNames => {
                cacheNames.forEach(cacheName => {
                    caches.delete(cacheName);
                });
              //  console.log('Cache cleared successfully.');
            });
        } else {
           // console.log('Your browser does not support caching.');
        }
        localStorage.setItem('version', this.state.currentVersion);
        window.location.reload("true")
    };
    openNav = () => {
        document.getElementById("bar").style.width = "100%";
    }
    render() {
        const { redirect, verify, profileAdded, descriptionAdded, topicsAdded, followersAdded, isLoading, version, showUpdateButton } = this.state;
        if (profileAdded) {
          //  console.log("redirect1", this.state.redirect);
            return <Redirect
                to={{
                    pathname: "/addprofiledetails",
                    state: { email: this.state.email, token: this.state.token, password: this.state.password }
                }}
            />
        }
        if (redirect) {
           // console.log("redirect1", this.state.redirect);
            return <Redirect
                to={{
                    pathname: "/profile",
                    state: { email: this.state.email, token: this.state.token, password: this.state.password }
                }}
            />
        }
        if (descriptionAdded) {
           // console.log("desc", this.state.descriptionAdded);
            return <Redirect
                to={{
                    pathname: "/interests",
                    state: { email: this.state.email, token: this.state.token, password: this.state.password }
                }}
            />
        }
        if (topicsAdded) {
           // console.log("topics", this.state.topicsAdded);
            return <Redirect
                to={{
                    pathname: "/authors/recommended",
                    state: { email: this.state.email, token: this.state.token, password: this.state.password }
                }}
            />
        }
        if (followersAdded) {
            //console.log("followers", this.state.followersAdded);
            return <Redirect
                to={{
                    pathname: "/home",
                    state: { email: this.state.email, token: this.state.token, password: this.state.password }
                }}
            />
        }
        // if (!showUpdateButton) //show snackbar with refresh button
        //     enqueueSnackbar("A new version was released", {
        //         persist: true,
        //         variant: "success",
        //         action: this.handleUpdateButtonClick(),
        //     });
        if (verify) {
            return (
                <div >
                    <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                    <img alt="" src={circle} className="-circle" />
                    <p className="welcome-text">Welcome to</p>
                    <p className="sceem">Sceem <span style={{ fontSize: "16px" }}>Knowledge Management</span></p>
                    <img alt="" src={circleblue} className="circle_blue" />
                    <Form onSubmit={this.handleSubmit} className="form-login">
                        <center>
                            <div style={{ width: "80%" }} >
                                <div className="form-group">
                                    <input type="email"
                                        className="form-control text-lowercase form-bg"
                                        aria-describedby="text"
                                        value={this.state.email}
                                        name="email"
                                        placeholder="Email"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div >
                                    <input type={this.state.showPassword ? "text" : "password"}
                                        name="password"
                                        className="form-control form-bg"
                                        value={this.state.password}
                                        placeholder="Password*"
                                        onChange={this.onChange}
                                    />
                                    <span className="showPassword"
                                        onClick={this.showPassword}
                                    >
                                        {this.state.showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                    </span>
                                </div>
                            </div>
                            <div id="status" className="mt-2 error center"></div><br />
                            <div className="error center mb-4"> You are already registered. Please verify OTP</div><br />
                            <Link to={{
                                pathname: "/verify",
                                state: { email: this.state.email, registerFlow: this.state.registerFlow }
                            }}><Button variant="default" type="submit" className="btn-block login-btn" onClick={this.verifyOtp}>Verify</Button></Link><br />

                            {/* <p>Don't have an account ?<Link to="/signup"><span className="_signup_txt"> Sign Up</span></Link></p> */}
                        </center>
                    </Form>
                </div>

            )
        } else {
            return (
                <div >
                    <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                    {/* <img alt="" src={loginlogo} className="login-logo" /> */}
                    <img alt="" src={circle} className="-circle" />
                    <p className="welcome-text">Welcome to</p>
                    <p className="sceem">Sceem <span style={{ fontSize: "16px" }}>Knowledge Management</span></p>
                    <img alt="" src={circleblue} className="circle_blue" />
                    <Form onSubmit={this.handleSubmit} className="form-login">
                        <center>
                            <div style={{ width: "80%" }} >
                                <div className="form-group">
                                    <input type="email"
                                        class="form-control form-bg text-lowercase"
                                        aria-describedby="text"
                                        value={this.state.email}
                                        name="email"
                                        placeholder="Email"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div >
                                    <input type={this.state.showPassword ? "text" : "password"}
                                        name="password"
                                        className="form-control form-bg"
                                        value={this.state.password}
                                        placeholder="Password*"
                                        onChange={this.onChange}
                                    />
                                    <span className="showPassword"
                                        onClick={this.showPassword}
                                    >
                                        {this.state.showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                    </span>
                                </div>
                            </div>
                            <div id="status" className="mt-2 error center"></div>
                            <Link to="/forgot" className=" _forgot-password">Forgot Password?</Link><br />
                            <><Button variant="default" type="submit" className="btn-block login-btn" >Login</Button></>
                            <div className="mt-2"></div>
                            {/* <span className="flex"><hr style={{ width: "18%" }} /> <p>or login with</p> <hr style={{ width: "18%" }} /> </span>
                            <div><Button variant="default" className="btn-outline"><img alt="" src={google} className="google" /> &nbsp; Google</Button></div><br /> */}
                            <p>Don't have an account ?<Link to="/signup"><span className="_signup_txt"> Sign Up</span></Link></p>
                            {/* <p>Please login using mobile</p> */}
                            {/* <p className="version">{`Version ${checkVersion.newMajor}.${checkVersion.newMinor}.${checkVersion.newPatch}`}</p> */}
                            {/* <div className="version">
                                <p>Current Version: {version}</p>
                                {showUpdateButton && <button onClick={this.handleUpdateClick}>Update</button>}
                            </div> */}
                            <p className="version">Version: {localStorage.getItem('version') ? localStorage.getItem('version') : Version.version}</p>
                            {/* {showUpdateButton &&
                                <div className="update-popup">
                                    <div id="installButton" className='updBtn' onClick={this.handleUpdateButtonClick}>
                                        <div className='inner-updBtn'>
                                            <span className='whitespace-nowrap ml-1'>A new version available. Update</span>
                                        </div>
                                    </div>
                                </div>} */}
                            <Modal show={showUpdateButton} size="lg"
                                aria-labelledby="contained-modal-title-vcenter"

                                centered>
                                <Modal.Body>
                                    <center>
                                        <div className="art-poll-text-h">Version Update</div>
                                        <div className="mt-3 mb-3">   A new version available. Please click on update</div>
                                        <div><Button variant="default" className="mt-1 q-submit-btn" onClick={this.handleUpdateButtonClick}>&nbsp;Update &nbsp;</Button></div>

                                    </center>
                                </Modal.Body>
                            </Modal>
                        </center>
                    </Form>
                </div>

            );
        }
    }
}