import React, { Component } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import loginlogo from '../images/login-logo.png';
import PricingData from "./PricingData.json";
import BottomMenu from "../BottomMenu/BottomMenu";
import './Pricing.css';
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "../LandingPage/arrowsOnBottomOrTop.css";
import "../LandingPage/hideScrollbar.css";
import axios from 'axios';
import { config } from '../Config/Config';
import { Redirect } from 'react-router-dom';
import bhim from '../images/BHIM-UPI.jpg';
import qrcode from '../images/QrCode.jpeg';
import phonepe from '../images/phonepe.png';
import gpay from '../images/Gpay.jpg';
import amazonpay from '../images/amazon-pay.jpg';
import paytm from '../images/paytm.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default class UPI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            planId: this.props.location.state.planId || "",
            planName: this.props.location.state.planName || "",
            pName: "",
            amount: "",
            plans: [],
            picture: "",
            redirect: false,
            qr: "",
            companyName: "",
            amount: this.props.location.state.amount || "",
            details: this.props.location.state.details || "",
            vpa: "",
        }

    }
    componentDidMount() {
        this.payment();
       // console.log("am", this.props.location.state.amount + ".00")
    }
    showAlreadyPurchasedPlanNone = () => {
        var self = this;
        self.setState({
            redirect: true
        })
        document.getElementById('alreadyPurchased').style.display = 'none';

    }
    showNone = () => {
        var self = this;
        self.setState({
            redirect: true
        })
        document.getElementById('upgrade').style.display = 'none';

    }
    closeModal() {
        document.getElementById('sameplan').style.display = 'none';
    }
    showVerificationPending = () => {
        this.showNone();
        document.getElementById('pending').style.display = 'flex';
    }
    hideVerificationPending = () => {
        var self = this;
        self.setState({
            redirect: true
        })
        document.getElementById('pending').style.display = 'none';
    }
    showAlreadyPurchasedPlan = () => {
        document.getElementById('alreadyPurchased').style.display = 'flex';
    }
    hideAlreadyPurchasedPlan = () => {
        var self = this;
        self.setState({
            redirect: true
        })
        document.getElementById('alreadyPurchased').style.display = 'none';
    }
    // payment = () => {
    //     // let headers = {
    //     //     "Content-Type": 'application/json',
    //     //     "Authorization": "Bearer " + this.state.token,
    //     //     "applicationType": "mobile"
    //     // }
    //     var payload = {
    //         // "amount": Number(this.state.amount),
    //         // "currency": "INR",
    //         // "planName": this.state.planName,
    //         // "planDuration": "yearly"

    //         "name": config.companyName,
    //         "vpa": config.vpa,
    //         "amount": this.state.amount + ".00",
    //         "txnReference": "Test 123",
    //         "note": "Test payment"
    //     }
    //     var self = this;
    //     axios.post('https://upiqr.in/api/qr', payload)
    //         .then((response) => {
    //             // this.handleOpenRazorpay({ data: response.data });
    //             console.log("response qrcode", response)
    //             self.setState({ qr: response.data })
    //         }).catch((error) => {
    //             console.log(error, "error")
    //         });
    // };
    payment = () => {
        // let headers = {
        //     "Content-Type": 'application/json',
        //     "Authorization": "Bearer " + this.state.token,
        //     "applicationType": "mobile"
        // }
        const qrcode = require('qrcode');
        const vpa = config.vpa;
        const amount = this.state.amount+'.00';
        const upiUrl = `upi://pay?pa=${encodeURIComponent(vpa)}&mc=yourMerchantCode&tid=yourTransactionId&tr=yourReferenceId&tn=Payment%20Description&am=${encodeURIComponent(amount)}&cu=INR&url=yourCallbackUrl`;
        
        
        qrcode.toDataURL(upiUrl, (err, dataUrl) => {
            if (err) throw err;
          //  console.log('Data URI:', dataUrl);
            this.setState({qr:dataUrl})
        });
    };
    postSubscription = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "planId": this.state.planId,
            "planName": this.state.planName,
            "planAmount": parseInt(this.state.amount),
            "transactionImage": this.state.picture,
            "details":this.state.details,
        }
       // console.log("Subscription payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/addSubscription', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    if (response.data.message == "you have already purchase this subscription") {
                        self.showAlreadyPurchasedPlan()
                       // document.getElementById('sameplan').style.display = 'flex';
                      //  console.log("Error", payload);
                    } else {
                        localStorage.setItem("merchantType", response.data.merchantType)
                        // document.getElementById('upgrade').style.display = 'flex';
                        //console.log("success", response.data.message, payload);
                        self.showVerificationPending()
                    }
                } else {
                    console.log("error")
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    handleImage = async (e) => {
        //console.log("fgh", e.target.files)
        if (e.target.files && e.target.files[0]) {
            const res = await this.uploadImage(e.target.files[0]);
            if (res) {
              //  console.log("res", res)
                this.setState({ picture: res })
            }
        }
    }
    showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }
    render() {
        const { redirect, imgRef, currentPricing, picture } = this.state;
        // if (currentPricing) {
        //     return <Redirect to="/currentpricing" />
        // }
        // if (redirect) {
        //     return <Redirect to="/upidetails" />
        // }
        if (redirect) {
            return <Redirect to="/home" />
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <div className="screen-topgap">
                    <span className="your-jitbits">UPI Payment</span>
                    <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                        {/* <form>
                            <label className="mt-1 ml-1">Enter UPI ID</label>
                            <input type="text" placeholder="Ex: MobileNumber@upi" className="form-control" name="upiId" onChange={this.onChange} value={this.state.upiId} />
                        </form> */}
                        <center className="mt-3">
                            <div className="nebutech-analytics-llp"><b>{config.companyName.toUpperCase()}</b></div>
                            {/* <div style={{ width: "50%" }} dangerouslySetInnerHTML={{ __html: this.state.qr }} /> */}
                            <img src={this.state.qr} style={{width:"50%"}} />
                            <div className="mt-1 scan">{config.vpa}</div>
                            <div className="mt-3 scan">Scan and Pay with any BHIM UPI app</div>
                            <div className="mt-2 scan">{this.state.planName}</div>
                            <div className="mt-1 nebutech-analytics-llp"><b>&#8377; &nbsp;{this.state.amount}</b></div>
                            <div><img src={bhim} style={{ width: "200px", marginTop: "-0.2rem" }} /></div>
                            <center className="mt-2 gppa"><img src={gpay} style={{ width: "75px", height: "50px", marginTop: "-0.2rem" }} /><img src={phonepe} style={{ width: "90px", height: "45px", marginTop: "-0.4rem" }} /><img src={paytm} style={{ width: "70px", marginTop: "-0.2rem" }} /><img src={amazonpay} style={{ width: "80px", marginTop: "-0.1rem" }} /></center>
                        </center>
                        <div><Button variant="default" onClick={() => this.showPublish()} className="upload-trans">Upload Transaction</Button></div>


                    </div>
                </div >
                <div id="publish" className="modal-pop">
                    <form className="modal-content-log-height">
                        <div className="_container">
                            <center style={{ height: "150px" }}>
                                <label htmlFor="upload-button">
                                    {picture != "" ? (
                                        <img src={picture} style={{ width: "100px", height: "100px" }} />
                                    ) : (
                                        <>
                                            <i class="fa fa-upload fa-2x" aria-hidden="true"></i>
                                            <div className="up-load">Upload Image</div>
                                        </>
                                    )}
                                </label>
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    id="upload-button"
                                    name="image"
                                    onChange={(e) => this.handleImage(e)}
                                />
                            </center>
                            <center>{this.state.picture !== "" ? <Button variant="default" onClick={() => this.postSubscription()} className="mb-2 cancelbutton">Yes</Button>
                                : <Button variant="default" disabled={true} className="mb-2 cancelbutton">Yes</Button>}&nbsp;&nbsp;
                                <Button variant="default" onClick={this.showNone} className="mb-2 deletebutton">No</Button></center>
                        </div>
                    </form>
                </div>
                <div id="pending" className="modal-pop">
                    <form className="modal-content-log">
                        <div className="_container">
                            <div className="verification-pending">Verification Pending</div>
                            <center>
                                <Button variant="default" onClick={this.hideVerificationPending} className="mb-2 mt-2 deletebutton">Ok</Button></center>
                        </div>
                    </form>
                </div>
                <div id="alreadyPurchased" className="modal-pop">
                    <form className="modal-content-log">
                        <div className="_container">
                            <div className="verification-pending">Already Purchased Same Plan</div>
                            <center>
                                <Button variant="default" onClick={this.hideAlreadyPurchasedPlan} className="mb-2 mt-2 deletebutton">Ok</Button></center>
                        </div>
                    </form>
                </div>

                < BottomMenu />
            </div >
        )
    }
}
