import React, { useState, useEffect } from "react";
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import './Interests.css';
import axios from 'axios';
import { config } from '../Config/Config';
import { Redirect } from 'react-router-dom';
import moment from "moment-timezone";
import SimpleCrypto from "simple-crypto-js";
import { AiOutlineShareAlt } from "react-icons/ai";
import { enqueueSnackbar } from 'notistack';
import { Button } from "react-bootstrap";

var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY;
var simpleCrypto = new SimpleCrypto(key);

export default function DetailedEvent(props) {

    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')));
    const [eventData, setEventData] = useState([]);
    const [allOfferings, setAllOfferings] = useState([]);
    const [smeUserId, setSmeUserId] = useState('');
    const [bookingDate, setBookingDate] = useState('');
    const [time, setTime] = useState('');
    const [registeredStatus, setRegisteredStatus] = useState();
    const [eventId, setEventId] = useState();
    const [link, setLink] = useState();
    const [userId, setUserId] = useState('');
    const [register, setRegister] = useState(false);
    const [hideBtn, setHideBtn] = useState(false);
    const [showHome, setShowHome] = useState(false);

    useEffect(() => {
        function base64UrlDecode(base64Url) {
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const padding = '='.repeat((4 - (base64.length % 4)) % 4);
            base64 += padding;
            const decoded = atob(base64);
            return decodeURIComponent(escape(decoded));
        }

        function decodeJwt(token) {
            const [header, payload] = token.split('.');
            const decodedPayload = base64UrlDecode(payload);
            const payloadObject = JSON.parse(decodedPayload);
            return payloadObject;
        }

        const decodedToken = decodeJwt(token);
        setUserId(decodedToken.userInfo.userId);

        // Fetch event data and check registration status
        getAllSmeOfferings();
        getSmeEventById();
        checkUserRegisteredOrNot();
    }, [token]);

    const onClickUrl = (url) => {
        window.open(url);
    }

    const checkUserRegisteredOrNot = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };
        var payload = {
            "eventId": props.match.params.id
        };
        axios.post(config.userUrl + 'user/checkUserRegisteredOrNot', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setRegister(response.data.isRegistered[0]?.isregistered == 1 ? true : false);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    const getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setAllOfferings(response.data.offerings);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    const getSmeEventById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };
        axios.get(config.userUrl + `user/getSmeEventById/${props.match.params.id}`, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setEventData(response.data.eventData);
                    const event = response.data.eventData[0];
                    setSmeUserId(event.smeUserId);
                    setBookingDate(event.bookingDate);
                    setTime(event.time);
                    setEventId(event.id);
                    setLink(event.link);
                } else {
                    console.log(response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    const registerForSmeEventByUser = (i) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };
        var payload = {
            "bookingDate": bookingDate,
            "smeUserId": smeUserId,
            "eventId": parseInt(eventId),
            "offeringId": eventData[0]?.offeringId,
            "agenda": eventData[0]?.agenda,
            "time": time,
            "link": link,
            "registeredStatus": i
        };
        console.log("payload", payload);

        axios.post(config.userUrl + 'user/registerForSmeEventByUser', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setShowHome(true);
                    enqueueSnackbar("Event Registered Successfully", { variant: "success" });
                } else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const shareAdv = (d) => {
        if (navigator.share) {
            navigator.share({
                url: `https://www.sceem.org/adv/${d}/${props.match.params.id}`,
            }).then(() => {
            }).catch(err => {
                console.log("Error while using Web share API:", err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
    };

    const getOffText = (offeringId) => {
        const item = allOfferings.find(txt => txt.id === offeringId);
        return item ? item.text : "";
    };

    if (showHome) {
        return <Redirect to={{ pathname: '/home' }} />;
    }
    const addMinutesToTime = (timeStr, minutesToAdd) => {
        // Parse the time string into hours and minutes
        const [time, period] = timeStr.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        // Convert 12-hour format to 24-hour format if necessary
        if (period === 'PM' && hours !== 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);

        date.setMinutes(date.getMinutes() + minutesToAdd);

        let newHours = date.getHours();
        const newMinutes = date.getMinutes().toString().padStart(2, '0');

        const periodString = newHours >= 12 ? 'PM' : 'AM';

        newHours = newHours % 12 || 12;

        return `${newHours}:${newMinutes} ${periodString}`;
    };
    return (
        <div>
            <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
            {eventData.length !== 0 && eventData.map((e, i) => (
                <div key={i}>
                    <div style={{
                        //  background: "linear-gradient(to bottom, #fff5d6 0%, #fffbec 50%, #fffcf1 100%)",
                       
                        // background: "linear-gradient(to top,#fff9e6 0%, #ffebcc 50%,#ffe0b2 100%)",
                   
                        borderColor: "#fffbec",
                        borderRadius: "40px 40px 0 0",
                    }} className="bg_event">
                        <div style={{ marginTop: "2.5rem", textAlign: "right", marginRight: "0.5rem" }}></div>
                        <center><img src={config.userUrl + e.profilePicture} style={{ marginTop: "-0.5rem", width: "40%", marginTop: "0.5rem", borderRadius: "10px" }} /></center>
                        <center><div style={{ fontSize: "20px", fontWeight: 600, fontStyle: "Noto Sans" }}>{e.name}</div></center>
                  
                    {/* <div style={{
                         // background: "linear-gradient(to top, #fff5d6 0%, #fffbec 50%, #fffcf1 100%)",
                        //   background:"linear-gradient(to bottom, #ffffcc 1%, #ff9933 200%)", 
                      
                        //background: "linear-gradient(to bottom,#fff9e6 0%, #ffebcc 50%,#ffe0b2 100%)",
                        padding: "0.5rem",
                        marginTop: "-1px",
                        borderColor: "#fffbec",
                        borderRadius: "45px 45px 0 0",
                        height: "58vh"
                    }} > */}
                        <center>
                            <div className="mt-2 detailed-event-text" style={{ borderTop: "2px solid #ffffff",borderRadius: "40px 40px 0 0",paddingTop:"25px"}}>{getOffText(e.offeringId)} event</div>
                            <div>On</div>
                            <div style={{ marginTop: "-3px" }} className="detailed-event-text">{moment(e.bookingDate).format('DD-MM-YYYY')}</div>
                            <div className="mt-2">From</div>
                            <div style={{ marginTop: "-3px" }} className="detailed-event-text">{e.time} to {addMinutesToTime(e.time, 30)}</div><br/>
                            <div className="mt-2 detailed-event-text" style={{ borderTop: "2px solid #ffffff",borderRadius: "40px 40px 0 0",paddingTop:"20px"}}>About</div>
                            <div>{e.agenda}</div>
                            <div>
                                {e.registeredcount == 0 ? (
                                    // <div><span style={{ fontSize: "24px" }}>Open</span> for registration</div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div style={{ position: "absolute", bottom: "7rem", color: "black", fontSize: "15px", fontFamily: "Noto Sans" }}>
                                            <span style={{ fontSize: "24px" }}>Open</span> for registration
                                        </div>
                                    </div>
                                ) : register ? (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div style={{ position: "absolute", bottom: "7rem", color: "black", fontSize: "15px", fontFamily: "Noto Sans" }}>{e.link}</div>
                                    </div>
                                ) : e.registeredcount != 0 ? (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div style={{ position: "absolute", bottom: "7rem", color: "black", fontSize: "15px", fontFamily: "Noto Sans" }}>
                                            <span style={{ fontSize: "24px" }}>{e.registeredcount}</span> participant(s) registered
                                        </div>
                                    </div>
                                ) : ""}
                            </div>
                        </center>
                        {/* <p className="terms-sm"><b>Type:</b> {e.type === 1 ? "Free" : "Paid"}</p> */}

                        {userId !== e.smeUserId ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Button value="default" style={{ width: "auto", position: "absolute", bottom: "4.2rem", background: "#31197C", border: "1px solid #31197C", color: "white", fontSize: "14px", fontFamily: "Noto Sans" }} onClick={() => registerForSmeEventByUser(1)} disabled={register} className="btn">{register ? "Registered" : "Register"}</Button>
                            </div> : <></>
                        }
                    </div>
                </div>
            ))}
            <br /><br />
            <BottomMenu />
        </div>
        //         <div>
        //     <div className="top_bar">
        //         <img alt="" src={loginlogo} className="login-logo" />
        //     </div>

        //     {eventData.length !== 0 && eventData.map((e, i) => (
        //         <div key={i}>
        //             <div style={{ 
        //                 background: "linear-gradient(to bottom, #ff942c, lightorange)", 
        //                 padding: "1rem", 
        //                 borderRadius: "10px 10px 0 0"
        //             }}>
        //                 <center>
        //                     <img 
        //                         src={config.userUrl + e.profilePicture} 
        //                         style={{ marginTop: "-0.5rem", width: "40%", borderRadius: "10px" }} 
        //                         alt={e.name} 
        //                     />
        //                     <div style={{ fontSize: "18px", fontWeight: 600, fontStyle: "Noto Sans" }}>{e.name}</div>
        //                 </center>
        //             </div>

        //             <div style={{ 
        //                 background: "linear-gradient(to bottom, lightorange, orange)", 
        //                 padding: "1rem", 
        //                 borderRadius: "0 0 10px 10px" 
        //             }}>
        //                 <center>
        //                     <div className="mt-4" style={{ fontSize: "20px", fontWeight: 600, fontStyle: "Noto Sans" }}>{getOffText(e.offeringId)} Event</div>
        //                     <div>On</div>
        //                     <div style={{ marginTop: "-3px", fontSize: "20px", fontWeight: 600, fontStyle: "Noto Sans" }}>
        //                         {moment(e.bookingDate).format('DD-MM-YYYY')}
        //                     </div>
        //                     <div className="mt-2">From</div>
        //                     <div style={{ marginTop: "-3px", fontSize: "20px", fontWeight: 600, fontStyle: "Noto Sans" }}>
        //                         {e.time} to {addMinutesToTime(e.time, 30)}
        //                     </div>
        //                     <div className="mt-2" style={{ fontSize: "18px", fontWeight: 600, fontStyle: "Noto Sans" }}>About</div>
        //                     <div>{e.agenda}</div>
        //                     <div>
        //                         {e.registeredcount === 0 ? (
        //                             <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        //                                 <div style={{ position: "absolute", bottom: "7rem", color: "black", fontSize: "15px", fontFamily: "Noto Sans" }}>
        //                                     <span style={{ fontSize: "24px" }}>Open</span> for registration
        //                                 </div>
        //                             </div>
        //                         ) : register ? (
        //                             <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        //                                 <div style={{ position: "absolute", bottom: "7rem", color: "black", fontSize: "15px", fontFamily: "Noto Sans" }}>{e.link}</div>
        //                             </div>
        //                         ) : e.registeredcount !== 0 ? (
        //                             <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        //                                 <div style={{ position: "absolute", bottom: "7rem", color: "black", fontSize: "15px", fontFamily: "Noto Sans" }}>
        //                                     <span style={{ fontSize: "24px" }}>{e.registeredcount}</span> participant(s) registered
        //                                 </div>
        //                             </div>
        //                         ) : ""}
        //                     </div>
        //                 </center>

        //                 {userId !== e.smeUserId ? (
        //                     <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        //                         <Button 
        //                             value="default" 
        //                             style={{ width: "auto", position: "absolute", bottom: "4.2rem", background: "#31197C", border: "1px solid #31197C", color: "white", fontSize: "14px", fontFamily: "Noto Sans" }} 
        //                             onClick={() => registerForSmeEventByUser(1)} 
        //                             disabled={register} 
        //                             className="btn"
        //                         >
        //                             {register ? "Registered" : "Register"}
        //                         </Button>
        //                     </div>
        //                 ) : null}
        //             </div>
        //         </div>
        //     ))}

        //     <br /><br /><br />
        //     <BottomMenu />
        // </div>
    );
}