import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { Redirect, Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import verifyemail from '../images/verifyemail.png';
import './Login.css';
import back from '../images/backarrow.png';
import axios from 'axios';
import { config } from '../Config/Config';

export default class AlternateVerify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.location.state.email,
            registerFlow: this.props.location.state.registerFlow,
            unSortArr: [],
            sortedArr: [],
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
            newPassword: false,
            authKey: "",
            redirect: false,
            isGenerated: true,
            errMsg: ""
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
       // console.log("hello", this.props.location.state.email)
    }
    handleChange(value1, event) {
        this.setState({ [value1]: event.target.value });
    }
    handleSubmit(event) {
        const { isGenerated, unSortArr, sortedArr } = this.state;
        const data = new FormData(event.target);
        //  console.log(this.state);
        event.preventDefault()
        var enteredArr = [this.state.otp1, this.state.otp2, this.state.otp3, this.state.otp4, this.state.otp5, this.state.otp6]

        this.arrayEquals = (sortedArr, enteredArr) => {
            return Array.isArray(sortedArr) &&
                Array.isArray(enteredArr) &&
                sortedArr.length === enteredArr.length &&
                sortedArr.every((val, index) => val === enteredArr[index]);
        }
        if (this.arrayEquals(sortedArr, enteredArr)) {
           // console.log("equal")
            this.verifyForgotPasswordEmail();
        } else {
           // console.log("notequal")
            this.setState({ isGenerated: true, otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "" })
        }

    }
    verifyOtp = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var self = this;
        var payload = {
            "email": this.state.email,
            "otp": this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6
        }
        axios.post(config.apiUrl + 'auth/verifyEmail', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
                if (response.data.status === true) {
                  //  console.log("Email verified successfully!!")
                  //  console.log("payload", payload)
                    self.setState({
                        redirect: true
                    })
                } else {
                    console.log(response.data.message)
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
                console.log("error", payload,error)
                document.getElementById('status').innerText = error
            });
    }


    verifyForgotPasswordEmail = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var self = this;
        var payload = {
            "email": this.state.email,
            "alternate": true,
            "otp": [this.state.otp1, this.state.otp2, this.state.otp3, this.state.otp4, this.state.otp5, this.state.otp6]
        }
        axios.post(config.apiUrl + 'auth/forgotPassword/verifyEmail', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
                if (response.data.status === true) {
                  //  console.log("Forgot Password Email verified successfully!!")
                   // console.log("payload", payload, response)
                    self.setState({
                        newPassword: true,
                        authKey: response.data.authkey
                    })
                } else {
                    console.log(response.data.message)
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
                console.log("error", payload,error)
            });
    }
    inputfocus = (elmnt) => {
        if (elmnt.key === "Backspace" || elmnt.key === "Delete") {
            const next = elmnt.target.tabIndex - 2;
          //  console.log("ext", elmnt.target.tabIndex, next)
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            const next = elmnt.target.tabIndex;
            //console.log("next", elmnt.target.tabIndex, next);
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        }

    }

    render() {
        const { newPassword, unSortArr, redirect, isGenerated } = this.state

        if (redirect) {
            return <Link
                to={{
                    pathname: "/",
                    state: { email: this.state.email }
                }} />
        }
        if (newPassword) {
            return <Redirect
                to={{
                    pathname: "/newpassword",
                    state: { email: this.state.email, authKey: this.state.authKey }
                }} />
        }
        if (isGenerated) {
            this.setState({ isGenerated: false })
            //console.log("isGenerated", isGenerated)
            const ran = () => [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].sort((x, z) => {
                let ren = Math.random();
                if (ren == 0.5) return 0;
                return ren > 0.5 ? 1 : -1
            })
            var unSort = [];
            unSort = Array(6).fill(null).map(x => ran()[(Math.random() * 9).toFixed()]).join(',');
            var sortedNewArr = [...unSort.split(",")]
            sortedNewArr.sort()
            this.setState({ unSortArr: unSort, sortedArr: sortedNewArr.sort() })
           // console.log("unSort", unSort, "sorted", sortedNewArr)
            return unSort;
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <Link to="/"><img alt="" src={back} className="mt-2 back_arrow_" /></Link>
                <center>
                    {/* <div className="forgot-text">Arrange the numbers</div> */}
                    <div className="alternateverify-text">Arrange the numbers in <b style={{ fontSize: "23px", fontWeight: "700" }}>ascending</b> order.</div><br />
                    <div className="numbers-text">{unSortArr}</div></center>
                <Form className="forgot-email" onSubmit={this.handleSubmit}>
                    <center>
                        <div style={{ width: "80%" }} className="form-group verify">
                            <input
                                name="otp1"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp1}
                                onKeyPress={this.keyPressed}
                                onChange={e => this.handleChange("otp1", e)}
                                tabIndex="1" maxLength={1} onKeyUp={e => this.inputfocus(e)}

                            />
                            <input
                                name="otp2"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp2}
                                onChange={e => this.handleChange("otp2", e)}
                                tabIndex="2" maxLength={1} onKeyUp={e => this.inputfocus(e)}

                            />
                            <input
                                name="otp3"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp3}
                                onChange={e => this.handleChange("otp3", e)}
                                tabIndex="3" maxLength="1" onKeyUp={e => this.inputfocus(e)}

                            />
                            <input
                                name="otp4"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp4}
                                onChange={e => this.handleChange("otp4", e)}
                                tabIndex="4" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                            />

                            <input
                                name="otp5"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp5}
                                onChange={e => this.handleChange("otp5", e)}
                                tabIndex="5" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                            />
                            <input
                                name="otp6"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp6}
                                onChange={e => this.handleChange("otp6", e)}
                                tabIndex="6" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                            />
                        </div><div id="status" className="errormsg"></div>
                        <br /><br />
                        <Button variant="default" type="submit" className="btn-block login-btn">Next</Button><br />
                        <div id="otp" className="errormsg">{this.state.errMsg}</div>

                    </center>
                </Form>
            </div>
        );
    }

}
