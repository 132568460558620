// import React, { useState } from 'react';

// const BankDetailsCalendar = ({ selectedDate, handleDateClick }) => {
//     const [currentMonth, setCurrentMonth] = useState(new Date());

//     const renderDaysOfWeek = () => {
//         const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
//         return daysOfWeek.map((day) => (
//             <div key={day} className="day-of-week">
//                 {day}
//             </div>
//         ));
//     };

//     const renderCalendarDates = () => {
//         const today = new Date();
//         const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
//         const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
//         const startingDay = firstDayOfMonth.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
//         const currentMonthYear = currentMonth.getFullYear();
//         const currentMonthNumber = currentMonth.getMonth();
//         const dates = [];

//         let startingPoint = startingDay === 0 ? 6 : startingDay - 1;

//         // If all dates in the current month are past dates, render the next month's dates by default
//         if (today >= firstDayOfMonth && today > new Date(currentMonthYear, currentMonthNumber, daysInMonth)) {
//             setCurrentMonth(new Date(currentMonthYear, currentMonthNumber + 1, 1)); // Move to the next month
//             return; // Return empty dates since the rendering will be done after the state update
//         }

//         for (let i = 1 - startingPoint; i <= 25; i++) {
//             const currentDate = new Date(currentMonthYear, currentMonthNumber, i);
//             const isPastDate = currentDate < today;
//             const dayOfWeek = currentDate.toLocaleDateString('default', { weekday: 'long' });
//             const dayNumber = currentDate.getDay() === 0 ? 7 : currentDate.getDay(); // Adjust Sunday to 7 instead of 0
//             // const isDisabled = !selectedDaysArr.includes(dayNumber);

//             dates.push(
//                 <div
//                     key={i}
//                     className={`calendar-date ${selectedDate === i ? 'selected' : ''}`}
//                     onClick={() =>handleDateClick(i, currentMonthNumber + 1, dayOfWeek)}>
//                     {i > 0 ? i : ''}
//                 </div>
//             );
//         }

//         return dates;
//     };

//     return (

//         <div className="mt-2 calendar">
//             <div className="calendar-header">
//                 {/* <div className="month-heading">{currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}</div> */}
             
//             </div>
//             <div>
//                 <div style={{ border: "1px solid gray", borderRadius: "20px", marginTop: "-5px" }}>
//                     {/* <div className="days-of-week">{renderDaysOfWeek()}</div> */}
//                     <div className="calendar-dates">{renderCalendarDates()}</div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default BankDetailsCalendar;

import React, { useState, useEffect } from 'react';

const BankDetailsCalendar = ({ selectedDate, handleDateClick }) => {

    const renderCalendarDates = () => {
        const daysInMonth = 25; // Render dates up to 25
        const dates = [];

        for (let i = 1; i <= daysInMonth; i++) {
            const isSelected = selectedDate === i;

            dates.push(
                <div
                    key={i}
                    className={`calendar-date ${isSelected ? 'selected' : ''}`}
                    onClick={() => handleDateClick(i)}>
                    {i}
                </div>
            );
        }

        return dates;
    };

    return (
        <div className="mt-2 calendar">
            <div className="calendar-header">
            </div>
            <div>
                <div style={{ border: "1px solid gray", borderRadius: "20px", marginTop: "-5px" }}>
                    <div className="calendar-dates">{renderCalendarDates()}</div>
                </div>
            </div>
        </div>
    );
};

export default BankDetailsCalendar;