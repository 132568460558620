import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import back from '../images/backarrow.png';
import axios from 'axios';
import { config } from '../Config/Config';
import BottomMenu from "../BottomMenu/BottomMenu";
import SimpleCrypto from "simple-crypto-js";
import OtpInput from 'react-otp-input';
// import Wheel from "../components/wheel";
// import Spin from "./Spin";
import WheelComponent from './WC';
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const myComponentStyle = {
    width: '22px',
    height: '30px',
    color: 'black',
    paddingBottom: 4,
    borderRadius: '6px',
    marginLeft: '1px',
    marginRight: '1px',
    fontWeight: 'bold',
    fontSize: '16px',
}
const myContainerStyle = {
    width: '22px',
    height: '30px',
    color: 'black',
    paddingBottom: 4,
    borderRadius: '6px',
    marginLeft: '1px',
    marginRight: '1px',
    fontWeight: 'bold',
    fontSize: '16px',
}

export default function SpinTheWheel(props) {
    const [isFinished, setFinished] = useState(false)
    const [wholeWord, setWholeWord] = useState("")
    const [firstFilled, setFirstFilled] = useState()
    const [secondFilled, setSecondFilled] = useState()
    const [word, setWord] = useState([])
    const [dupWord, setDupWord] = useState([])
    const [term, setTerm] = useState("")
    const [jumbledTerm, setJumbledTerm] = useState("")
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [noOfTrials, setNoOfTrials] = useState();
    const [spinScore, setSpinScore] = useState(0);
    const [sco, setSco] = useState()
    const [userTrials, setUserTrials] = useState()
    const [gameName, setGameName] = useState("")
    const [gameDescription, setGameDescription] = useState("")
    const [gamePicture, setGamePicture] = useState("")
    const [rewardPoints, setRewardPoints] = useState()
    const [gameRewardPoints, setGameRewardPoints] = useState()
    const [termDefinition, setTermDefinition] = useState("")
    const [termUsage, setTermUsage] = useState("")
    const [termId, setTermId] = useState("")
    const [sort, setSort] = useState("")
    const [checkWord, setCheckWord] = useState("")
    const [words, setWords] = useState()
    const [currentSpin, setCurrentSpin] = useState(0)
    const [howToPlay, setHowToPlay] = useState("")
    const [arr, setArr] = useState([
        { "id": 1, "letter": "a", "score": 175 },
        { "id": 2, "letter": "e", "score": 250 },
        { "id": 3, "letter": "i", "score": 150 },
        { "id": 4, "letter": "o", "score": 125 },
        { "id": 5, "letter": "u", "score": 75 },
    ])

    useEffect(() => {

        getTermRandomly()
        getGameById();
    }, []);
    // console.log(spinScore, "spin")
    const getTermRandomly = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getTermRandomly', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setWholeWord(response.data.terms.term)
                    let dumWord = response.data.terms.term.split('')
                    setDupWord(dumWord)
                    let temp = []
                    let count = 0
                    dumWord.map((a, index) => {
                        if (!isVowel(a)) {
                            temp[index] = a;
                        } else {
                            if (count < 2) {
                                count++
                                temp[index] = ''
                            } else {
                                temp[index] = a
                            }
                        }
                    })
                    setWord(temp)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const getGameById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + `user/getGameById/${props.match.params.id}`, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setGameName(response.data.game[0].gameName)
                    setGameDescription(response.data.game[0].description)
                    setGamePicture(response.data.game[0].picture)
                    setRewardPoints(response.data.game[0].rewardPoints)
                    setNoOfTrials(response.data.game[0].numberOfTrials)
                    setUserTrials(response.data.game[0].numberOfTrials)
                    setHowToPlay(response.data.game[0].howToPlay)
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    // const handleSubmit = (event) => {
    //     event.preventDefault()
    //     updateGameRewardPoint();
    // }

    const updateGameRewardPoint = (ar) => {
      //  console.log("dup", ar)
        if (dupWord.join('') == ar) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + token,
                "applicationType": "mobile"
            }
            var payload = {
                "gameId": Number(props.match.params.id),
                "gameRewardPoint": spinScore + 100
            }
           // console.log("payload", payload)
            axios.post(config.userUrl + 'user/updateGameRewardPoint', payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status === true) {
                     //   console.log("success!!")
                        showSuccess()
                    } else {
                        console.log("error")
                    }
                })
                .catch(function (error) {
                    console.log("error", payload,error)
                });
        } else {
           // console.log("error")
            if (dupWord.join('').length == arr.join('').length && noOfTrials == 0) {
                let headers = {
                    "Content-Type": 'application/json',
                    "Authorization": "Bearer " + token,
                    "applicationType": "mobile"
                }
                var payload = {
                    "gameId": Number(props.match.params.id),
                    "gameRewardPoint": 100
                }
              //  console.log("payload", payload)
                axios.post(config.userUrl + 'user/updateGameRewardPoint', payload, { headers: headers })
                    .then(function (response) {
                        if (response.data.status === true) {
                          //  console.log("success!!")
                            showEarned()
                        } else {
                            console.log("error")
                        }
                    })
                    .catch(function (error) {
                        console.log("payload", payload,error)
                    });
                // showWrong()
            } else {
                let headers = {
                    "Content-Type": 'application/json',
                    "Authorization": "Bearer " + token,
                    "applicationType": "mobile"
                }
                var payload = {
                    "gameId": Number(props.match.params.id),
                    "gameRewardPoint": 100
                }
               // console.log("payload", payload)
                axios.post(config.userUrl + 'user/updateGameRewardPoint', payload, { headers: headers })
                    .then(function (response) {
                        if (response.data.status === true) {
                           // console.log("success!!")
                            showEarned()
                        } else {
                            console.log("error")
                        }
                    })
                    .catch(function (error) {
                        console.log("payload", payload,error)
                    });
                // showFailure()
                let dumWord = wholeWord.split('')
                setDupWord(dumWord)
                let temp = []
                let count = 0
                dumWord.map((a, index) => {
                    if (!isVowel(a)) {
                        temp[index] = a;
                    } else {
                        if (count < 2) {
                            count++
                            temp[index] = ''
                        } else {
                            temp[index] = a
                        }
                    }
                })
                setWord(temp)
            }
        }
    }
    const showHowToPlay = () => {
        document.getElementById('play').style.display = 'flex';
    }
    const hideHowToPlay = () => {
        document.getElementById('play').style.display = 'none';
    }
    const showSuccess = () => {
        document.getElementById('success').style.display = 'flex';
    }
    const hideSuccess = () => {
        document.getElementById('success').style.display = 'none';
        window.location.replace('/games')
    }
    const showEarned = () => {
        document.getElementById('earned').style.display = 'flex';
    }
    const hideEarned = () => {
        document.getElementById('earned').style.display = 'none';
        window.location.replace('/home')
    }
    const showFailure = () => {
        document.getElementById('failure').style.display = 'flex';
    }
    const hideFailure = () => {
        document.getElementById('failure').style.display = 'none';
        window.location.replace('/games')
    }
    const trialsExpired = () => {
        document.getElementById('trial').style.display = 'flex';
    }
    const trialsExpiredNone = () => {
        document.getElementById('trial').style.display = 'none';
    }
    const showCorrect = () => {
        document.getElementById('correct').style.display = 'flex';
    }
    const hideCorrect = () => {
        document.getElementById('correct').style.display = 'none';
    }
    const showWrong = () => {
        document.getElementById('wrong').style.display = 'flex';
    }
    const hideWrong = () => {
        document.getElementById('wrong').style.display = 'none';
    }
    const showScore = () => {
        document.getElementById('score').style.display = 'flex';
    }
    const hideScore = () => {
        document.getElementById('score').style.display = 'none';
    }
    const segments = [
        // '200',
        // '100',
        // '250',
        // '175',
        // '125',
        // '100'
        '200',
        '100',
        '150',
        '75',
        '125',
        '0'
    ]
    const segColors = [
        '#FF942C',
        '#3DA5E0',
        '#815CD1',
        '#F9AA1F',
        '#31197C',
        '#EE4040',
    ]
    const isVowel = (word) => {
        let alphabet = word.toLowerCase()
        let isVow = false
        switch (alphabet) {
            case 'a':
                isVow = true;
                break;
            case 'e':
                isVow = true;
                break;
            case 'i':
                isVow = true;
                break;
            case 'o':
                isVow = true;
                break;
            case 'u':
                isVow = true;
                break;
            default:
                isVow = false;
                break;
        }
        return isVow
    }
    const checkLetter = (i, val) => {
        if (spinScore >= val) {

            let arr = [...word]
            let idx = arr.findIndex(item => item == "");
           // console.log("idx", idx)
            arr[idx] = i;
            // if (Number(firstFilled) > -1) {
            //     setSecondFilled(idx)
            // } else {
            //     setFirstFilled(idx)
            // }
            if (dupWord.join('') == arr.join('')) {
                setSpinScore(spinScore - val)
                setCheckWord(arr.join(''))
                setWord([...arr])
                updateGameRewardPoint(arr.join(''))
                //console.log("correct word")
            } else {
              //  console.log("wrong word")
                if (spinScore >= val) {
                    if (dupWord.join('').length == arr.join('').length) {
                        setSpinScore(spinScore - val)
                        setCheckWord(arr.join(''))
                        updateGameRewardPoint(checkWord)
                        // updateGameRewardPoint(arr.join(''))
                        // setSubmitBtn(false)
                        // showWrong()
                    } else {
                        setSpinScore(spinScore - val)
                        setCheckWord(arr.join(''))
                    }
                }
                setWord([...arr])
            }
        } else {
            if (noOfTrials == 0) {
                showWrong()
            } else {
                showScore()
            }
        }
    }

    //console.log(word, arr)
    useEffect(() => {
        setSpinScore(spinScore + currentSpin)
    }, [currentSpin])
    const handleSpinScore = (data) => {
        setCurrentSpin(data)
     //   console.log("s", currentSpin)
        // console.log('spinScore', Number(data)+Number(spinScore))
        // setSpinScore(Number(data) + Number(spinScore))
    }
    return (
        <div>
            <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
            <Link to="/games"><img alt="" src={back} className="back_arrow" /></Link>
            <center> <img alt="" src={gamePicture} className="spin-game-image" />
                <div style={{ fontFamily: "Noto Sans" }} className="spin-game-text">{gameDescription}<br /> <span style={{ fontSize: "18px" }}>And earn {rewardPoints} Reward Points</span></div>

                <Button variant="default" className="showPlay-btn" onClick={() => showHowToPlay()}>How to Play</Button></center><br/>
            
            <Form>
                <center>
                    <center style={{ display: "flex" }} className="mt-2 game_boxes">
                        {word.map((alphabet, index) => {
                            // return <OtpInput
                            //     value={alphabet}
                            //     onChange={(e) => {
                            //         console.log(e)
                            //         let temp = [...word]
                            //         temp[index] = e
                            //         setWord(temp)
                            //     }}
                            //     disable={true}
                            //     numInputs={1}
                            //     inputType="text"
                            //     inputStyle={myComponentStyle}
                            //     renderSeparator={<span></span>}
                            //     renderInput={(props) => <input {...props} />}
                            // />

                            return <input type="text"
                                value={alphabet}
                                onChange={(e) => {
                                    let temp = [...word]
                                    temp[index] = e
                                    setWord(temp)
                                }}
                                className={word?.findIndex(item => item == "") == index ? "inp-border form-control otp-width" : "form-control otp-width"}
                                // className={word?.findIndex(item => item == "") == index ? "inp-border form-control otp-width" : word?.findIndex(item => item == " " )==index ? "ip-border form-control otp-width" : "form-control otp-width"}
                                // className={dupWord[index]== word[index]? "form-control otp-width" : "inp-border form-control otp-width"}
                                // className={index == firstFilled || index == secondFilled ? "inp-border form-control otp-width" : "form-control otp-width"}
                                disabled={true}
                            />
                        })
                        }
                    </center>

                    <br />
                    <div className="mt-3 game-grid">
                        {arr.map((lett, i) => (
                            <div className="vowel-btn" onClick={() => checkLetter(lett.letter, lett.score)}>{lett.letter}</div>
                        ))}
                    </div>
                    <div className="game-grid">
                        {arr.map((lett, i) => (
                            <div style={{ fontFamily: "Noto Sans", fontSize: "16px", fontWeight: 600 }}>{lett.score}</div>
                        ))}
                    </div>
                    <div className="score">Score: {spinScore}</div>
                    <div style={{ fontFamily: "Noto Sans", color: "black", fontSize: "14px" }}>Trials Left: {noOfTrials}/{userTrials}</div>
                    <h1></h1>
                    <div>
                        <WheelComponent segments={segments} segColors={segColors} trails={noOfTrials} trialsExpiredNone={trialsExpiredNone} setTrails={setNoOfTrials} score={spinScore} setScore={handleSpinScore} />
                    </div>
                    <div id="otp" className="errormsg"></div>
                    <div id="tryanother" className="errormsg"></div>

                </center>
                {/* <div>
                    <Button onClick={() => updateGameRewardPoint(checkWord)} variant="default" disabled={submitBtn} style={{ position: "absolute", background: "#31197C", color: "white", fontFamily: "Noto Sans", bottom: "4rem", right: "20px" }} className="">Submit</Button>
                </div> */}
            </Form>
            <div id="correct" className="modal-pop">
                <form className="modal-content-log">
                    <div className="_container">
                        <p className="mt-2">Your guess is correct!</p>
                        <center><Button variant="default" onClick={hideCorrect} className="deletebutton">Ok</Button></center>
                    </div>
                </form>
            </div>
            <div id="wrong" className="modal-pop">
                <form className="modal-content-log">
                    <div className="_container">
                        <p className="mt-2">You have reached maximum no.of trails. Please try after sometime. </p>
                        <center><Button variant="default" onClick={hideWrong} className="deletebutton">Ok</Button></center>
                    </div>
                </form>
            </div>
            <div id="score" className="modal-pop">
                <form className="modal-content-log">
                    <div className="_container">
                        <p className="mt-2">Spin the wheel and try to earn points to buy</p>
                        <center><Button variant="default" onClick={hideScore} className="deletebutton">Ok</Button></center>
                    </div>
                </form>
            </div>
            <div id="play" className="modal-pop">
                <form className="modal-content-log">
                    <div className="_container-howtoplay">
                        <center className="mt-1" style={{ color: "#31197C", fontSize: "20px" }}>How to Play</center>
                        {/* <div className="mt-2">{howToPlay}</div> */}
                        <div className="mt-2"  style={{ wordWrap: "break-word", width: "100%" }} dangerouslySetInnerHTML={{ __html: howToPlay }}></div>
                        <center><Button variant="default" onClick={hideHowToPlay} className="mt-2 deletebutton">Ok</Button></center>
                    </div>
                </form>
            </div>
            <div id="success" className="modal-pop">
                <form className="modal-content-log">
                    <div className="_container">
                        <p className="mt-2">You have earned {spinScore + 100}  Reward Points.</p>
                        <center><Button variant="default" onClick={hideSuccess} className="deletebutton">Ok</Button></center>
                    </div>
                </form>
            </div>
            <div id="earned" className="modal-pop">
                <form className="modal-content-log">
                    <div className="_container">
                        <p className="mt-2">Oops! Your guess is wrong. But, you have earned 100 Reward Points.</p>
                        <center><Button variant="default" onClick={hideEarned} className="deletebutton">Ok</Button></center>
                    </div>
                </form>
            </div>
            <div id="failure" className="modal-pop">
                <form className="modal-content-log">
                    <div className="_container">
                        <p className="mt-2" style={{ color: "#31197C", fontSize: "20px" }}>Oops! Your guess is wrong</p>
                        <center><Button variant="default" onClick={hideFailure} className="deletebutton">Ok</Button></center>
                    </div>
                </form>
            </div>
            <div id="trial" className="modal-pop">
                <form className="modal-content-log">
                    <div className="_container">
                        <p className="mt-2" style={{ color: "#31197C", fontSize: "20px" }}>Oops! Your guess is wrong</p>
                        <center><Button variant="default" onClick={trialsExpiredNone} className="deletebutton">Ok</Button></center>
                    </div>
                </form>
            </div>
            <BottomMenu />

        </div >
    );
}




{/* <img src={chain} alt="" style={{ width: "100%" }} />
 <img src={featuresS} alt="" style={{ width: "100%" }} />
 <img src={topicsCovered} alt="" style={{ width: "100%" }} /> */}
