import React, { Component } from "react";
import { Button, Form, Col, Card, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import back from '../images/backarrow.png';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class CreateGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            groupName: "",
            typeOfGroup: "",
            description: "",
            image: "",
            email: "",
            webPage: "",
            groupNameError: '',
            typeOfGroupError: '',
            descriptionError: '',
            logoError: "",
            checked: false,
            home: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.validateGroupName = this.validateGroupName.bind(this);
        this.validateDescription = this.validateDescription.bind(this);
        this.validateType = this.validateType.bind(this);
        this.validateLogo = this.validateLogo.bind(this);
        this.validateField = this.validateField.bind(this);
    }
    componentDidMount() {
    }
    onChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }

    handleBlur(event) {
        const { name } = event.target;
        this.validateField(name);
        return;
    }

    handleSubmit(event) {
        event.preventDefault();
        //console.log(this.state);
        let formFileds = [
            "groupName",
            "description",
            "typeOfGroup",
            "image"

        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = this.validateField(field) && isValid;
        });
        this.createGroup()
    }
    createGroup = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "groupName": this.state.groupName,
            "typeOfGroup": this.state.typeOfGroup,
            "description": this.state.description,
            "logo": this.state.image,
        }
        var self = this
        axios.post(config.userUrl + 'user/createGroup', payload, { headers: headers })
            .then(function (response) {
                // console.log("response", response)
                if (response.data.status === true) {
                    // console.log(response, "group created successfully!!")
                    self.setState({ home: true })
                }
            })
            .catch(function (error) {
                console.log("payload", payload, error)
                document.getElementById('error').innerText = error
            });
    }
    validateField(name) {
        let isValid = false;
        if (name === "groupName") isValid = this.validateGroupName();
        else if (name === "typeOfGroup") isValid = this.validateType();
        else if (name === "description") isValid = this.validateDescription();
        else if (name === "image") isValid = this.validateLogo();
        return isValid;
    }

    validateGroupName() {
        let groupNameError = "";
        const value = this.state.groupName;
        if (value.trim() === "") groupNameError = "Please add Group Name";
        this.setState({
            groupNameError
        });
        return groupNameError === "";
    }
    validateDescription() {
        let descriptionError = "";
        const value = this.state.description;
        if (value.trim() === "") descriptionError = "Please add Group description";
        this.setState({
            descriptionError
        });
        return descriptionError === "";
    }
    validateLogo() {
        let logoError = "";
        const value = this.state.image;
        if (value.trim() === "") logoError = "Please add Group Logo";
        this.setState({
            logoError
        });
        return logoError === "";
    }
    validateType() {
        let typeOfGroupError = "";
        const value = this.state.typeOfGroup;
        if (value.trim() === "") typeOfGroupError = "Please add type of group";
        this.setState({
            typeOfGroupError
        });
        return typeOfGroupError === "";
    }
    uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    handleImage = async (e) => {
       // console.log("fgh", e.target.files)
        if (e.target.files && e.target.files[0]) {
            const res = await this.uploadImage(e.target.files[0]);
            if (res) {
               // console.log("res", res)
                this.setState({ image: res })
            }
        }
    }
    handleCheck = () => {
        this.setState({ checked: !this.state.checked })
    }
    render() {
        const { home, allTopics, image, checked, imgRef } = this.state
        // console.log("home", home)
        if (home) {
            return <Redirect to="/home" />
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
                <Form onSubmit={this.handleSubmit} style={{ marginTop: "5rem" }}>
                    <div className="create-group">  <Link to="/home"><img alt="" src={back} className="back_arrow_" /></Link> <div className="mt-1 ml-4">Create Group</div></div>

                    <div style={{ marginLeft: "5%", marginRight: "5%", width: "90%" }} >
                        <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                            <div className="form-group mt-2">
                                <input type="text"
                                    className="form-control form-bg-opp"
                                    aria-describedby="text"
                                    value={this.state.groupName}
                                    name="groupName"
                                    placeholder="Group Name"
                                    onChange={this.onChange}
                                    onBlur={this.handleBlur}
                                />
                                <div className="div">{this.state.groupNameError && (
                                    <div className="error">{this.state.groupNameError}</div>
                                )}</div>
                            </div>

                            <div className="form-group mt-2">
                                <textarea rows={2} type="text"
                                    className="form-control"
                                    style={{ resize: "none", fontSize: "12px" }}
                                    aria-describedby="text"
                                    value={this.state.description}
                                    name="description"
                                    placeholder="Group Description"
                                    onChange={this.onChange}
                                    onBlur={this.handleBlur}
                                />
                                <div className="div">{this.state.descriptionError && (
                                    <div className="error">{this.state.descriptionError}</div>
                                )}</div>
                            </div>
                            <div className="form-check form-check-inline form_check mt-4" style={{ display: "flex" }}>
                                <input name="typeOfGroup" className="form-check" type="radio" value="Public" checked={this.state.typeOfGroup === "Public"} onChange={this.onChange} />
                                <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                    Public
                                </label>
                                <input name="typeOfGroup" className="ml-3 form-check" type="radio" value="Private" checked={this.state.typeOfGroup === "Private"} onChange={this.onChange} />
                                <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                    Private
                                </label>
                            </div>
                            {(this.state.typeOfGroup.length == null) ?
                                <div className="div error">{this.state.typeOfGroupError}</div> : <div></div>}
                        </div>
                            <div style={{ marginTop: "1rem", height: "120px", textAlign: "center", objectFit: "contain" }}>
                                <label htmlFor="upload-button">
                                    {image != "" ? (
                                        <img src={image} style={{ width: "100px", height: "100px" }} />
                                    ) : (
                                        <>
                                            <i class="fa fa-upload fa-2x" aria-hidden="true"></i>
                                            <div className="up-load">Upload Image</div>
                                        </>
                                    )}
                                </label>
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    id="upload-button"
                                    ref={imgRef}
                                    onChange={(e) => this.handleImage(e)}
                                />
                            </div>
                            <div className="form-group">
                                <input type="email"
                                    className="form-control form-bg-opp"
                                    aria-describedby="text"
                                    value={this.state.email}
                                    name="Email"
                                    placeholder="Email"
                                    onChange={this.onChange}
                                    onBlur={this.handleBlur}
                                />
                            </div>
                            <div className="form-group mt-1">
                                <input type="text"
                                    className="form-control form-bg-opp"
                                    aria-describedby="text"
                                    value={this.state.webPage}
                                    name="webPage"
                                    placeholder="Web Page"
                                    onChange={this.onChange}
                                    onBlur={this.handleBlur}
                                />
                            </div>
                            <div className="Terms-Txt">
                                <span className="Terms-Txt-Color">Terms of usage:</span>This group is to promote and spread knowledge on supply chain and it related ecosystem. </div>
                            <div className="group-terms"><input type="checkbox" checked={this.state.checked} onClick={this.handleCheck} /> <span className="group-agree-text">Accept not to share content which may not be relevant or copyright protected or something which has direct political or religious beliefs</span></div><br />

                        </Card>

                    </div >
                    {/* <div className="ml-4"><input type="checkbox" checked={this.state.checkboxToshowDetails} onClick={this.handleCheck} /> <span className="signup-agree-text"> &nbsp;Show contact details</span></div> */}
                    < center >
                        {/* <div id="status" className="errormsg"></div> */}
                        {/* <div id="error" className="errormsg"></div> */}
                        {((this.state.groupName !== "") && (this.state.checked == true) && (this.state.image !== "") && (this.state.typeOfGroup !== "") && (this.state.description !== "")) ?
                            <Button variant="default" type="submit" style={{ position: "absolute", bottom: "2rem", left: "5%", right: "5%" }} className="btn-block opportunity-btn mt-4">Create</Button>
                            : <Button variant="default" type="submit" disabled={true} style={{ position: "absolute", bottom: "2rem", left: "5%", right: "5%" }} className="btn-block opportunity-btn mt-4">Create</Button>
                        }<br />

                    </center >
                </Form >
            </div >
        );
    }
}
