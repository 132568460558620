import React, { useEffect, useState } from 'react';
import './PaymentDetails.css';
import BottomMenu from '../BottomMenu/BottomMenu';
import { Button, Card, Col, Row, Modal, Carousel } from "react-bootstrap";
import { Link, useLocation, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import axios from 'axios';
import moment from 'moment-timezone';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function PromoteSmeProfile() {
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [showPopup, setShowPopup] = useState(false);
    const [amount, setAmount] = useState(2000);
    const [name, setName] = useState('');
    const [profilePicture, setProfilePicture] = useState('');
    const [topics, setTopics] = useState([])
    const [topicId, setTopicId] = useState([])
    const [subscriptionStartDate, setSubscriptionStartDate] = useState();
    const [subscriptionEndDate, setSubscriptionEndDate] = useState();
    const [payment, setPayment] = useState(false);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        getSmeAdditionalAmount();
    }, [])
    const updateSmeAdditionalAmount = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "additionalAmount": amount,
            "subscriptionStartDate": new Date(Date.now())
        }
      //  console.log("p", payload)
        axios.post(config.userUrl + 'user/updateSmeAdditionalAmount', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                  //  console.log("successfully added fees", payload)
                    setShowPopup(true)
                } else {
                    console.log("else")
                }

            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getSmeAdditionalAmount = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getSmeAdditionalAmount', { headers: headers })
            .then(function (response) {
             //  console.log(response);
                if (response.data.status === true) {
                    setSubscriptionStartDate(response.data.smeData[0]?.subscriptionStartDate)
                    setSubscriptionEndDate(response.data.smeData[0]?.subscriptionEndDate)
                    setName(response.data.smeData[0]?.name)
                    setProfilePicture(response.data.smeData[0]?.profilePicture)
                    setTopicId(response.data.smeData[0]?.topicId)
                    setTopics(response.data.topics)
                    if (response.data.smeData[0]?.additionalAmount !== 0) {
                        setPayment(true)
                    }
                } else {
                    console.log("else")
                }

            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    const goToHome = () => {
        setRedirect(true)
    }
    const getSmeTopics = (t) => {
        const items = topics
            ?.filter(data => t.includes(data.id))
            .map(data => data.topicName);

        return items;
    };
    const getSmeTopicIcon = (t) => {
        const items = topics
            ?.filter(data => t.includes(data.id))
            .map(data => "../" + data.topicName + ".png");

        return items;
    };
    return (
        <div>
            {redirect && <Redirect to="/home" />}
            {payment ?
                <div>
                    <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                    <div className="mt-5">
                        <div className='your-jitbits'>Promote Profile</div>
                        <div className='m-3' style={{border: "1px solid gray", borderRadius: "10px", height: "max-content" }}>
                            <div className='m-2'>
                                <div className='promote-prof-text' style={{ height: "4rem" }}>Subscription Valid till {moment(subscriptionEndDate).format("DD-MMM-YYYY")} Rs.{amount} paid on {moment(subscriptionStartDate).format("DD-MMM-YYYY")}.</div>
                                <div className='promote-prof-text'>This option provides extra visibility to your profile. It appears on <strong>sceem</strong> homepage as shown below.  Users can click and view
                                    your detailed profile to directly book a slot to obtain your services.
                                </div>
                                <div className='mt-2'>
                                    <div style={{ color: "white", background: "#31197C", textAlign: "center", borderTopRightRadius: "10px", width: "100%", padding: "5px 10px", fontSize: "18px", fontWeight: 700, fontFamily: "Noto Sans" }}>Connect with {name}</div>
                                    <div style={{ marginTop: "-2px", width: "100%", backgroundColor: "#c0b4f4", height: "auto", padding: "8px 4px", borderBottomRightRadius: "15px", }}>
                                        <Row style={{ padding: "5px", marginLeft: "6px", borderBottomRightRadius: "50px", borderTopRightRadius: "50px", background: "linear-gradient(to right bottom, #efe2ef, #e9e2f2, #e1e3f5, #d9e4f6, #d1e5f5, #d1e5f5, #d1e5f5, #d1e5f5, #d9e4f6, #e1e3f5, #e9e2f2, #efe2ef)", width: "96%", }}>
                                            <Col xs={9} style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}>
                                                <div style={{ height: "3.5rem" }}>
                                                    <div style={{ lineHeight: "16px" }}>

                                                        <div style={{ marginLeft: "-8px" }}>
                                                            <div>
                                                                {getSmeTopicIcon(topicId).map((imageUrl, index) => (
                                                                    <span key={index} >
                                                                        <img src={imageUrl} style={{ width: "16px" }} alt="Topic icon" />&nbsp;
                                                                        <span style={{ fontSize: "12px", color: "black" }}>{getSmeTopics(topicId)[index]}</span><br />
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={3} style={{ border: "none" }}>
                                                <center style={{ marginTop: "-1px" }}>
                                                    <img src={config.userUrl + profilePicture} style={{ float: "right", marginRight: "-10px", width: "57px" }} />
                                                </center>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='mt-2 promote-prof-text'>This is an annual fixed payment subscription above membership fee.</div>
                                <div className='mt-3 promote-prof-text'><strong>Note:  Payment once made cannot be returned or refunded.</strong></div>
                            </div>
                        </div>
                    </div>
                </div> :
                <div>
                    <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                    <div className="mt-5">
                        <div className='your-jitbits'>Promote Profile</div>
                        <div className='m-3' style={{ fontFamily:"Noto Sans",border: "1px solid gray", borderRadius: "10px", height: "max-content" }}>
                            <div className='m-2'>
                                <div className='promote-prof-text' style={{ height: "4rem" }}>Annual Subscription Rs.{amount}</div>
                                <div className='promote-prof-text' >This option provides extra visibility to your profile. It appears on <strong>sceem</strong> homepage as shown below.  Users can click and view
                                    your detailed profile to directly book a slot to obtain your services.
                                </div>
                                <div className='mt-2'>
                                    <div style={{ color: "white", background: "#31197C", textAlign: "center", borderTopRightRadius: "10px", width: "100%", padding: "5px 10px", fontSize: "18px", fontWeight: 700, fontFamily: "Noto Sans" }}>Connect with {name}</div>
                                    <div style={{ marginTop: "-2px", width: "100%", backgroundColor: "#c0b4f4", height: "auto", padding: "8px 4px", borderBottomRightRadius: "15px", }}>
                                        <Row style={{ padding: "5px", marginLeft: "6px", borderBottomRightRadius: "50px", borderTopRightRadius: "50px", background: "linear-gradient(to right bottom, #efe2ef, #e9e2f2, #e1e3f5, #d9e4f6, #d1e5f5, #d1e5f5, #d1e5f5, #d1e5f5, #d9e4f6, #e1e3f5, #e9e2f2, #efe2ef)", width: "96%", }}>
                                            <Col xs={9} style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}>
                                                <div style={{ height: "3.5rem" }}>
                                                    <div style={{ lineHeight: "16px" }}>

                                                        <div style={{ marginLeft: "-8px" }}>
                                                            <div>
                                                                {getSmeTopicIcon(topicId).map((imageUrl, index) => (
                                                                    <span key={index} >
                                                                        <img src={imageUrl} style={{ width: "16px" }} alt="Topic icon" />&nbsp;
                                                                        <span style={{ fontSize: "12px", color: "black" }}>{getSmeTopics(topicId)[index]}</span><br />
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={3} style={{ border: "none" }}>
                                                <center style={{ marginTop: "-1px" }}>
                                                    <img src={config.userUrl + profilePicture} style={{ float: "right", marginRight: "-10px", width: "57px" }} />
                                                </center>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='mt-2 promote-prof-text'>This is an annual fixed payment subscription above membership fee.</div>
                                <div className='mt-3 promote-prof-text'><strong>Note:  Payment once made cannot be returned or refunded.</strong></div>
                            </div>
                            <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                <div></div>
                                <Button variant="default" className="mt-1 payment-submit-button" onClick={updateSmeAdditionalAmount}>Pay Now</Button>
                            </div>
                        </div>
                        <Modal show={showPopup} size="lg"
                            aria-labelledby="contained-modal-title-vcenter"

                            centered>
                            <Modal.Body style={{ background: "#EFF5FB", borderRadius: "16px" }}>
                                <div>
                                    <div className="ml-1">  </div>
                                    <div style={{ fontSize: "18px", marginTop: "-4px", textAlign: "center" }}>Payment Status</div>
                                </div>
                                <div className="mt-3 mb-4" style={{ fontSize: "14px", textAlign: "center" }}>
                                    Your payment completed successfully.
                                </div>
                                <center className='mt-2'>
                                    <Button variant="default" className="mt-1 prev-btn-sme" onClick={() => goToHome()}>Ok</Button>
                                </center>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>}
            <BottomMenu />
        </div >
    );
};
