import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import {  Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import './Interests.css';
import happylearning from '../images/happy learning.svg';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default class ThanksForRegistering extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            redirect: false,
            id: this.props.match.params.id
        }
    }
    componentDidMount() {
       // console.log("id", this.state.id)
    }
    handleSubmit = () => {
        this.setState({ redirect: true })
    }
    render() {
        const { redirect } = this.state;
        if (redirect) {
           // console.log("redirect1", this.state.redirect);
            return <Redirect
                to={{
                    pathname: `/eachcard/${this.state.id}`,
                    state: { offset: 0, cardNumber: 1 }
                }}
            />
        }
        return (
            <div >
                <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                <div className="screen-topgap">
                    <div className="ml-3 mr-3" >
                        <div className="sceemlore-heading"></div>
                    </div>
                    <div className="ml-2 mr-2">
                        <Card className="sceemlore-lg-card">
                            <center className="center-sceemlore-text">Thanks for Registering<br/><br/>
                           <img src={happylearning} style={{width:"85%"}} /></center>
                        </Card>
                    </div>
                    <Button variant="default" onClick={this.handleSubmit} className="card-register-btn">Submit</Button>
                </div>
                <div className="mb-bottom"></div>
                <BottomMenu />
            </div >
        )
    }
}
