import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { NavLink, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import './RecommendedAuthors.css';
import axios from 'axios';
import { config } from '../Config/Config';
import back from '../images/backarrow.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class FollowingAuthors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            authors: [],
            authorIds: [],
            userFollowedAuthors: [],
            isLoading: true,
            isFirstTime: false,
            redirect: false,
            getUserFollowedAuthors: []
        }
        this.onAddingItem = this.onAddingItem.bind(this)
    }
    handleBack = () => {
        this.props.history.goBack()
    }
    onAddingItem = (e, value) => {
        const { userFollowedAuthors } = this.state
        //console.log("item", value)
        const updated = userFollowedAuthors.map(data => (data.uuid !== value) ?
            data : { ...data, isAdded: !data.isAdded })
      //  console.log("update", updated)
        this.setState({ userFollowedAuthors: updated, isLoading: false })

    }
    componentDidMount = () => {
        this.getUserFollowedAuthors();
     //   console.log("userFollowesAuthors", this.state.userFollowedAuthors)
    }

    getUserFollowedAuthors = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var localFollowers = localStorage.getItem('getUserFollowedAuthors')
       // console.log("local", localFollowers)
        var followersData = JSON.parse(localFollowers)
      //  console.log("followers", !followersData.latestUserFollowedAuthors[0].isAdded)
        const exp = new Date()
        if ((localFollowers === null) || (exp.getTime() > followersData.expiry)) {
            var self = this
            axios.get(config.userUrl + 'user/getUserFollowedAuthors', { headers: headers })
                .then(function (response) {
                   // console.log(response);
                    if (response.data.status === true) {
                       // console.log("getUserFollowedAuthors");
                        var item = {
                            "latestUserFollowedAuthors": JSON.stringify(response.data.getUserFollowedAuthors),
                            "expiry": exp.getTime() + 60 * 60 * 100
                        }
                        localStorage.setItem("getUserFollowedAuthors", JSON.stringify(item))
                        //console.log("Json", JSON.stringify(item))
                        self.setState({
                            userFollowedAuthors: response.data.userFollowedPeople
                        })
                        if (response.data.userFollowedPeople.length === 0) {
                           // console.log("FIRSTTIME USER")
                            self.setState({
                                isFirstTime: true
                            })
                        } else {
                            console.log("USER")
                        }
                    }
                })
                .catch(function (error) {
                    console.log("Error");
                });
        } else {
          //  console.log("follow")
            this.setState({ userFollowedAuthors: JSON.parse(followersData.latestUserFollowedAuthors) })
        }
    };
    unfollowAuthors = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this;
        const { userFollowedAuthors } = this.state;
        let authorId = [];
        authorId = userFollowedAuthors.filter(item => (item.isAdded === true) ? item.uuid : null)

        var newUserFollowedAuthors = userFollowedAuthors.filter(item => (item.isAdded === true) ? null : item.uuid)
       // console.log("authorId", newUserFollowedAuthors, authorId)
        var payload = {
            "authorIds": authorId.map(item => item.uuid),
            "isFirstTime": this.state.isFirstTime
        }
        const exp = new Date()
        axios.post(config.userUrl + 'user/unfollowAuthors', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                   // console.log("success", payload);
                    // self.getUserFollowedAuthors();
                    // var item = {
                    //     "latestUserFollowedAuthors": JSON.stringify(userFollowedAuthors),
                    //     "expiry": exp.getTime() + 60 * 60 * 100
                    // }
                    // localStorage.setItem("getUserFollowedAuthors", JSON.stringify(item))
                    // console.log("Json", JSON.stringify(item))

                    var item = {
                        "latestUserFollowedAuthors": JSON.stringify(newUserFollowedAuthors),
                        "expiry": exp.getTime() + 60 * 60 * 100
                    }
                    localStorage.setItem("getUserFollowedAuthors", JSON.stringify(item))
                   // console.log("new", JSON.stringify(item))
                    self.setState({
                        userFollowedAuthors: newUserFollowedAuthors,
                        redirect: true
                    })

                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        const { redirect, userFollowedAuthors, authors, isLoading } = this.state
        let authorsId = [];
        authorsId = userFollowedAuthors?.filter(item => (item.isAdded === true) ? item.uuid : null)
        function checkUserFollowedAuthors(checkItem) {
            return (userFollowedAuthors?.filter(item => item.uuid === checkItem.uuid).length !== 0)
        }
        if (isLoading) {
          //  console.log("isLoading")
            authors.forEach(item => checkUserFollowedAuthors(item) ? item.isAdded === true : item.isAdded === false)
        }
        if (redirect) {
            return <Redirect to="/home" />
        }

       // console.log("userFollowedAuthors", this.state.userFollowedAuthors)
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
                <div style={{ marginTop: "6.5rem" }}>
                </div>
                <div className="recommended-fixed">
                    {/* <img alt="" src={back} className="landing-Image" onClick={this.handleBack} /><br /><br /> */}
                    <div style={{ marginTop: "13px", display: "flex", marginLeft: "15px" }}>
                        <NavLink to="/authors/recommended" activeClassName="profile-active" className="ml-3 link" >
                            <div className="followers-Text ml-1 mr-1">Recommended </div></NavLink>

                        <NavLink to="/authors/following" activeClassName="profile-active" className="rec-left link" >
                            <div className="followers-Text ml-1 mr-1">Following</div></NavLink>

                    </div>
                </div><br />
                {userFollowedAuthors?.length !== 0 ?
                    <div>
                        {userFollowedAuthors?.map((data, i) => {
                            return (
                                <div>
                                    <Row style={{ width: "100%", marginRight: "2px", marginLeft: "10px" }}>
                                        <Col xs={3}>
                                            <img alt="" src={config.userUrl + data.profilePicture} className="ml-2 rec-profile" />
                                        </Col>
                                        <Col xs={5}>
                                            <div className="rec-name">{data.name}</div>
                                            {/* <div className="rec-follow">10 followers / 15 likes</div> */}
                                        </Col>
                                        <Col xs={1}></Col>
                                        <Col xs={3} key={i} value={data.uuid}
                                            onClick={(e) => this.onAddingItem(e, data.uuid)}>{data.isAdded ?
                                                <i class="fa fa-plus-circle fa-2x plus" aria-hidden="true"></i> :
                                                <i class="fa fa-minus-circle fa-2x plus" aria-hidden="true"></i>}
                                        </Col>
                                    </Row><hr className="ml-3 mr-3" />
                                </div>
                            )
                        })}
                        <div>
                            <Button variant="default" onClick={this.unfollowAuthors} className="landing-btn float">Save</Button>
                        </div>
                    </div> : <center className="mt-4">You are not following any authors </center>}
                    <br/><br/><br/><br/>
                <BottomMenu />
            </div>
        )
    }
}
    // for (var i = 0; i < userFollowedAuthors.length; i++) {
        //     if (userFollowedAuthors[i] === value) {
        //         userFollowedAuthors.splice(i, 1);
        //     }
        //     this.setState({ userFollowedAuthors: userFollowedAuthors, isLoading: false })
        // }