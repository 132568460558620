import React, { Component } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import './Interests.css';
import { config } from '../Config/Config';
import back from '../images/backarrow.png';
import sceemcertificate from '../images/sceem-certificate.svg';
import appreciationcertificate from '../images/appreciation-cert.svg';
import badgelogo from '../images/badge.svg';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default class ReviewScreens extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            redirect: false,
            title: "",
            id: this.props.match.params.id,
            cardTitle: "",
            cardDescription: "",
            cardPicture: "",
            cardNumber: "",
            offset: "",
            topicName: "",
            rating: "",
            ratingCompleted: false,
            sceemloreScreen: false,
            cName: "",
            cTitle: "",
            cTopicName: "",
            cVersion: "",
            cDate: ""
        }
        this.goBack = this.goBack.bind(this)
    }
    goBack = () => {
        this.setState({ sceemloreScreen: true })
    }
    componentDidMount() {
        //console.log("id",this.state.id, this.state.cardNumber)
        this.getSceemloreByUser(0)
    }
    getSceemloreByUser = (val) => {
        //console.log("val", val)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "cardManagementId": this.state.id,
            "offset": val,
            "cardView": val + 1
        }
      //  console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + 'user/getSceemloreByUser', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    if (response.data.cardCompleted) {
                        self.setState({
                            cName: response.data.name,
                            cTitle: response.data.title,
                            cTopicName: response.data.topicName,
                            cDate: response.data.date,
                            cVersion: response.data.version,
                            redirect: true
                        })
                    } else {
                        self.setState({
                            cardTitle: response.data.sceemloreDetail[0].cardTitle,
                            cardDescription: response.data.sceemloreDetail[0].cardDescription,
                            cardPicture: response.data.sceemloreDetail[0].cardPicture,
                            cardNumber: response.data.sceemloreDetail[0].cardNumber,
                            title: response.data.title,
                            topicName: response.data.topicName
                        })
                        if (response.data.sceemloreDetail[0].cardNumber == 1) {
                            self.setState({ back: true })
                        } else {
                            self.setState({ back: false })
                        }
                    }
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    getSceemloreBackByUser = (val) => {
       // console.log("backval", val)
        if (val != 0) {
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + this.state.token,
                "applicationType": "mobile"
            }
            var payload = {
                "cardManagementId": this.state.id,
                "offset": val - 1,
                "cardView": val
            }
         //   console.log("payload", payload)
            var self = this;
            axios.post(config.userUrl + 'user/getSceemloreByUser', payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status === true) {
                        if (response.data.cardCompleted) {
                            self.setState({
                                cName: response.data.name,
                                cTitle: response.data.title,
                                cTopicName: response.data.topicName,
                                cDate: response.data.date,
                                cVersion: response.data.version, 
                                redirect: true
                            })
                        } else {
                            self.setState({
                                cardTitle: response.data.sceemloreDetail[0].cardTitle,
                                cardDescription: response.data.sceemloreDetail[0].cardDescription,
                                cardPicture: response.data.sceemloreDetail[0].cardPicture,
                                cardNumber: response.data.sceemloreDetail[0].cardNumber,
                                title: response.data.title,
                                topicName: response.data.topicName
                            })
                            if (response.data.sceemloreDetail[0].cardNumber == 1) {
                                self.setState({ back: true })
                            } else {
                                self.setState({ back: false })
                            }
                        }
                    } else {
                        console.log(response.data)
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        } else {
            console.log("back")
        }
    };

    render() {
        const { redirect, ratingCompleted, title, topicName, sceemloreScreen, cardTitle, cardDescription, cardPicture, cardNumber, cName, cTitle, cTopicName, cVersion, cDate } = this.state;
        if (ratingCompleted) {
            //console.log("redirect1", this.state.ratingCompleted);
            return <Redirect to="/sceemlore" />
        }
        if (sceemloreScreen) {
        //  console.log("redirect1", this.state.sceemloreScreen);
            return <Redirect to="/sceemlore" />
        }
        if (redirect) {
          //  console.log("redirect1", this.state.redirect);
            return (
                <div>
                    <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                    <Link to="/sceemlore"><img alt="" src={back} className="sceemlore-back_arrow" /></Link><br /><br />
                    <div className="screen-topgap">
                        <div className="ml-2 mr-2">
                            <Card className="sceemlore-c-card">
                                <Card className="sceemlore-c-inner-card"><br />
                                    {/* <center className="congrats-review"> <img src={congrats} style={{ width: "70%" }} /></center> */}
                                    <center>
                                        <img src={sceemcertificate} className="sceem-certificate" />
                                        <div className="certificate">Certificate</div>
                                        <div className="certificate-of">of</div>
                                        <img src={appreciationcertificate} className="appreciation-certificate" />
                                        <div className="certificate-cName">{cName}</div>
                                        <div className="certificate-completed"><span className="normal-weight">Has successfully completed</span></div>
                                        <div className="title-and-version"><span className="normal-weight">sceemlore titled </span><span className="weight"><i>{cTitle}</i></span><span className="normal-weight"> of version</span> <span className="weight"> <i>{cVersion}</i></span></div>
                                        <div className="century-schoolbook"><span className="normal-weight">under topic </span><span className="title-and-version weight"><i>{cTopicName.replace(/ *\([^]*\) */g, "")}</i></span></div>
                                        <div className="certificate-on"><span className="normal-weight">on </span><span className="title-and-version weight color-blue"><i>{cDate.split("T")[0].split("-").reverse().join("-")}</i></span></div></center>
                                    <img src={badgelogo} className="badge-logo-sceem"/>
                                </Card>
                            </Card>
                        </div>
                        {/* <button onClick={this.goBack} class="scm-back-left"><i class="fa">&#xf060;</i></button> */}
                    </div>
                    <div className="mb-bottom"></div>
                    <BottomMenu />
                </div>

            )
        }
        return (
            <div >
                <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                <div className="screen-topgap">
                    <div className="ml-3 mr-3" >
                        <div className="sceemlore-heading"> {title}</div>
                    </div>
                    <div className="mt-3 ml-2 mr-2">
                        <Card className="sceemlore-lg-card">
                            <div className="sceem-lore">
                                <center className="center-sceemlore-text-title">Card {cardNumber}: {cardTitle}<br />
                                    <img src={cardPicture} style={{ marginTop: "0.7rem", width: "230px", height: "210px" }} /></center>
                            </div>
                            <hr />
                            <div className="sceem-lore-auto">
                                <center className="sceemlore-card-h-desc">{cardDescription}</center>
                            </div>
                        </Card>
                    </div>
                    {/* <Button variant="default" onClick={() => this.getSceemloreByUser((cardNumber-1))} className="card-prev-register-btn">Prev</Button> */}
                    {/* <Button variant="default" onClick={() => this.getSceemloreByUser(cardNumber)} className="card-register-btn">Next</Button> */}
                    {/* <div style={{ display: "flex" }}>
                        {this.state.back == false ?
                            <i onClick={() => this.getSceemloreBackByUser(cardNumber - 1)} class="fa prevIcon" aria-hidden="true">&#xf190;</i>
                            : null}
                        <i onClick={() => this.getSceemloreByUser(cardNumber)} class="fa nextIcon" aria-hidden="true">&#xf18e;</i>
                  
                </div > */}

                    <div style={{ display: "flex" }}>
                        {this.state.back == false ?
                            <button onClick={() => this.getSceemloreBackByUser(cardNumber - 1)} class="scm-lore-left"><i class="fa" style={{ color: "lightgrey" }}>&#xf060;</i></button>
                            : null}
                        <button onClick={() => this.getSceemloreByUser(cardNumber)} class="scm-lore-right"><i class="fa " style={{ color: "lightgrey" }}>&#xf061;</i></button>
                    </div >
                    <div className="mb-bottom"></div>
                    <BottomMenu />
                </div >
            </div>
        )
    }
}
