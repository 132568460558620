export const config = {
    "apiUrl": "https://sceem-auth-ssxk6.ondigitalocean.app/",
    "userUrl": "https://sceem-user-l3s5f.ondigitalocean.app/",
    "PASSWORD_ENCRYPTION_KEY": "zxcvbnmasdfghjkl",
    "feddupUserUrl":"https://api.feddup.me/",
    "sceemLpUrl":"https://www.sceem.org/",
    "companyName":"Nebutech Analytics LLP",
    "vpa":"NEBUTECH.08@cmsidfc",
    "companyId":"4ccced30-7315-11ee-ab46-f521a67e26cf",
    "productId":"3e2a5320-7316-11ee-ab46-f521a67e26cf",
    "versionId":"3e2b8ba0-7316-11ee-ab46-f521a67e26cf",
    "publicKey": "BEz-g6U_R277kUju-s8xXL_4qEaDBkHs8vV-aC0My7TFWEWzFml9O8ZQeeLu7IF-cVmJ5auta0Nk6a0OHxWVFs0"
}
// export const config = {
//     "apiUrl":  "https://sceem-auth-ssxk6.ondigitalocean.app/",
//     "userUrl": "http://localhost:8080/",
//     "PASSWORD_ENCRYPTION_KEY": "zxcvbnmasdfghjkl",
//     "feddupUserUrl":"https://api.feddup.me/",
//     "sceemLpUrl":"https://www.sceem.org/",
//     "companyName":"Nebutech Analytics LLP",
//     "vpa":"NEBUTECH.08@cmsidfc",
//     "publicKey": "BEz-g6U_R277kUju-s8xXL_4qEaDBkHs8vV-aC0My7TFWEWzFml9O8ZQeeLu7IF-cVmJ5auta0Nk6a0OHxWVFs0",
//     "companyId":"4ccced30-7315-11ee-ab46-f521a67e26cf",
//     "productId":"3e2a5320-7316-11ee-ab46-f521a67e26cf",
//     "versionId":"3e2b8ba0-7316-11ee-ab46-f521a67e26cf",
// }