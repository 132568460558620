import React, { Component } from 'react';
import loginlogo from '../images/login-logo.png';
import page from '../images/404.png';
import './Interests.css';
import fedduplogo from '../images/fedduplogo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


class ReportIssue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            title: "",
            description: "",
            home: false
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    submitFeedback = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "title": this.state.title,
            "description": this.state.description,

        }
        console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl + "user/createIncident", payload, { headers: headers })
            .then(function (response) {
                // console.log("response", response)
                if (response.data.status === true) {
                    // console.log(response, "group created successfully!!")
                    self.setState({ home: true })
                }
            })
            .catch(function (error) {
                console.log("payload", payload, error)
                document.getElementById('error').innerText = error
            });
    }

    render() {
        const { home, hasError, title, description } = this.state
        if (home) {
            return <Redirect to="/home" />
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <center><img alt="" src={page} style={{ marginTop: "1.2rem", width: "60%", height: "50%" }} /></center>
                 <p className="mt-1 notfound">Oops! <br />Something went wrong.</p>
                <div className='form-group' >
                    <div style={{ color: "#ff942c", fontSize: "22px", marginLeft: "20px", fontWeight: 600, fontFamily: "Noto Sans" }}>Report Issue</div>
                    <input type="text"
                        className="mt-1 web-font form-control"
                        aria-describedby="text"
                        style={{ margin: "0px 20px", width: "89%", overflowY: "none", border: "1px solid gray", borderRadius: "6px", resize: "none" }}
                        name="title"
                        value={title}
                        onChange={this.onChange}
                        placeholder="Title"
                    />
                    <div className="mt-2">
                        <textarea type="text"
                            className="web-font form-control"
                            aria-describedby="text"
                            name="description"
                            style={{ margin: "0px 20px", width: "89%", overflowY: "none", border: "1px solid gray", borderRadius: "6px", resize: "none" }}
                            value={description}
                            onChange={this.onChange}
                            rows={5}
                            placeholder="Description"
                        />
                    </div>
                    <center>
                        <Button variant="default" disabled={!this.state.title || !this.state.description} onClick={this.submitFeedback} className="btn-block opportunity-btn mt-3">Submit</Button>
                    </center>
                </div >
                <br /><br />
                <BottomMenu />
            </div>
        );
    }
}

export default ReportIssue;