import React, { useEffect, useState } from "react";
import loginlogo from '../images/login-logo.png';
import knowledge from '../images/knowledge.png';
import termQues from '../images/term1.svg';
import termDef from '../images/term2.svg';
import termlast from '../images/termlast.svg';
import './speech-bubble.css';
import { Link, Redirect } from 'react-router-dom';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import { Button, Col, Row } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function ShowTerm(props) {
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [redirect, setRedirect] = useState(false)
    const [termDefinition, setTermDefinition] = useState("")
    const [termUsage, setTermUsage] = useState("")
    const [term, setTerm] = useState("")

    useEffect(() => {
        getTermById();
    }, []);

    const getTermById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + `user/getTermById/${props.match.params.id}`, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setTerm(response.data.terms[0].term)
                    setTermDefinition(response.data.terms[0].definition)
                    setTermUsage(response.data.terms[0].usage)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
   // console.log("selected")
    if (redirect) {
        return <Redirect to="/home" />
    }
    return (
        <div>
            <div className="top_bar"><img src={loginlogo} className="login-logo" />
            </div>
            <div className="screen-topgap-term" >
                {/* <div className="your-jitbits">Term</div> */}
                {/* <center>
                    <img src={knowledge} style={{ width: "40%",marginTop:"-1.5rem" }} />
                </center>
                <div className="ml-3 mr-4">
                    <p className="mt-2" style={{ color: "#31197C",fontFamily: "Raleway, sans-serif", fontWeight: 600, fontSize: "20px",marginTop:"-0.5rem" }}> {term}</p>
                    <div className="term-f-scroll">
                    <p className="mt-2 " style={{fontFamily: "Raleway, sans-serif"}}><span style={{ fontSize: "16px", color: "#31197C", fontFamily: "Raleway, sans-serif", fontWeight: "600" }}>Definition:</span> {termDefinition}</p>
                    <p className="mt-2"  style={{fontFamily: "Raleway, sans-serif"}}><span style={{ fontSize: "16px", color: "#31197C", fontFamily: "Raleway, sans-serif",fontWeight: "600" }}>Explanation:</span> {termUsage}</p>
                    </div>
                </div> */}
                {/* <div class="one-bubble circle round">{term}</div>
                <div class="speech-bubble r round">{termDefinition}</div> */}
                {/* <div class="speech-bubble r round">{termDefinition}</div> */}
                <div className="ml-2 mr-2">
                    <div className="term_borderText">Sceem WORD</div>
                    <div className="mt-3 term_Border">
                        {/* <div className="your-jitbits">Sceem WORD</div> */}
                        <div style={{ display: "flex" }} className="mt-2">
                            <img src={termQues} style={{ marginTop: "-10px", marginRight: "-4px", width: "32%", height: "100%" }} />
                            <div class="speech-bubble round circle l"><b><i>WHAT IS</i> <br />{term} ?</b></div>
                        </div>
                        <div style={{ display: "flex", marginTop: "0.4rem" }}>
                            <div class="ml-3 term-bubble r round">{termDefinition}</div>
                            <img src={termDef} className="ml-4" style={{ width: "20%", height: "100%", marginTop: "0.6rem" }} />
                        </div>
                        <div style={{ marginTop: "0.4rem" }}>
                            <div class="ml-3 mr-2 mb-2">
                                <img src={termlast} style={{ width: "100%" }} />
                                <div style={{ fontFamily: "Raleway, sans-serif" }} className="term_Usage">{termUsage}</div>
                            </div>
                        </div>
                        <div style={{ marginBottom: "3rem" }}></div>
                    </div>
                </div>
                {/* <br /><br /><br/><br/> */}
                <div style={{ marginBottom: "4.5rem" }}></div>
            </div>

            <BottomMenu />

        </div>
    );
};