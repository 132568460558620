
import React, { useState, useEffect } from "react";
import { Card, Col, Row, Popover, OverlayTrigger } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import '../JitBits/JitBits.css';
import quiz from '../images/quizgame.svg';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default function Games() {
    const [games, setGames] = useState([])
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [isLoading, setIsLoading] = useState(true)
    const [overlay, setOverlay] = useState(false);

    useEffect(() => {
        getAllPublishedGame();
    }, []);

    const getAllPublishedGame = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllPublishedGames', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setGames(response.data.games)
                    setIsLoading(false)
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    if (isLoading) {
        //  console.debug("renders: Loading...");
        return <div className="App"></div>;
    }
    return (
        <div >
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="screen-topgap">
                <div className="your-jitbits">Games</div>
                <div className="ml-3 mr-3 mt-1 top-jit">
                    {games.length !== 0 ?
                        <div className="game-overflow">
                            <Link to="/quiz" className="ml-2 mr-2 link">
                                <Card style={{ display: "flex",marginTop:"-0.5px" }} className="games_card">
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        <Col xs={4}>
                                            <div className="g-image"><center><img src={quiz} className="mt-2 games-img_quiz" /></center></div>
                                        </Col>
                                        <Col xs={8}>
                                            <div className="ml-2 favbits-title-new">Quiz Game</div>
                                            <div className="ml-2 game-Text">Answer questions and win rewards</div>
                                            <div className="ml-2 game-rewards"><b>Reward Points: 10</b></div>

                                        </Col>
                                    </Row>
                                </Card>
                            </Link>
                            {games.slice(0, 1).map((data, i) => (
                                <Link to={{
                                    pathname: `/game/${data.id}`,
                                    state: { data: games[i] }
                                }} className="ml-2 mr-2 link">
                                    <Card style={{ display: "flex" }} className="games_card">
                                        <Row style={{ width: "100%" }} className="no-gutters">
                                            <Col xs={4}>
                                                <div className="g-image"><center><img src={data.picture} className="mt-1 games-img" /></center></div>
                                            </Col>
                                            <Col xs={8}>
                                                {(data.gameName.length) > 24 ?
                                                    <OverlayTrigger
                                                        placement="top"
                                                        trigger={overlay ?
                                                            ""
                                                            : "click"}

                                                        rootClose
                                                        overlay={(
                                                            <Popover
                                                                id="test">
                                                                <Popover.Content >
                                                                    <div className="ml-2 favbits-title-new">{data.gameName}</div>
                                                                </Popover.Content>
                                                            </Popover>
                                                        )}>
                                                        {overlay ?
                                                            "" :

                                                            <div className="ml-2 favbits-title-new">{data.gameName.substring(0, 24)}...</div>
                                                        }
                                                    </OverlayTrigger>
                                                    :

                                                    <div className="ml-2 favbits-title-new">{data.gameName}</div>}
                                                <div className="ml-2 game-Text">{data.description > data.description.substring(0, 26) ? data.description.substring(0, 26) + "..." : data.description}</div>
                                                <div className="ml-2 game-rewards"><b>No.of Trails: {data.numberOfTrials}</b></div>
                                                <div className="ml-2 game-rewards"><b>Reward Points: {data.rewardPoints}</b></div>

                                            </Col>
                                        </Row>
                                    </Card>
                                </Link>
                            ))}
                            {games.slice(1, 2).map((data, i) => (
                                <Link to={{
                                    pathname: `/scrambledgame/${data.id}`,
                                    state: { data: games[i] }
                                }} className="ml-2 mr-2 link">
                                    <Card style={{ display: "flex" }} className="games_card">
                                        <Row style={{ width: "100%" }} className="no-gutters">
                                            <Col xs={4}>
                                                <div className="g-image"><center><img src={data.picture} className="mt-1 games-img" /></center></div>
                                            </Col>
                                            <Col xs={8}>
                                                <div className="ml-2 favbits-title-new">{data.gameName}</div>
                                                <div className="ml-2 game-Text">{data.description > data.description.substring(0, 26) ? data.description.substring(0, 26) + "..." : data.description}</div>
                                                <div className="ml-2 game-rewards"><b>No.of Trails: {data.numberOfTrials}</b></div>
                                                <div className="ml-2 game-rewards"><b>Reward Points: {data.rewardPoints}</b></div>

                                            </Col>
                                        </Row>
                                    </Card>
                                </Link>
                            ))}

                            {games.slice(2, 3).map((data, i) => (
                                <Link to={{
                                    pathname: `/spinthewheel/${data.id}`,
                                    state: { data: games[i] }
                                }} className="ml-2 mr-2 link">
                                    <Card style={{ display: "flex" }} className="games_card">
                                        <Row style={{ width: "100%" }} className="no-gutters">
                                            <Col xs={4}>
                                                <div className="g-image"><center>     <img src={data.picture} className="mt-1 games-img" /></center></div>
                                            </Col>
                                            <Col xs={8}>
                                                <div className="ml-2 favbits-title-new">{data.gameName}</div>
                                                <div className="ml-2 game-Text">{data.description > data.description.substring(0, 26) ? data.description.substring(0, 26) + "..." : data.description}</div>
                                                <div className="ml-2 game-rewards"><b>No.of Trails: {data.numberOfTrials}</b></div>
                                                <div className="ml-2 game-rewards"><b>Reward Points: {data.rewardPoints}</b></div>

                                            </Col>
                                        </Row>
                                    </Card>
                                </Link>
                            ))}
                            {games.slice(3, 4).map((data, i) => (
                                <Link to={{
                                    pathname: `/sequencegame/${data.id}`,
                                    state: { data: games[i] }
                                }} className="ml-2 mr-2 link">
                                    <Card style={{ display: "flex" }} className="games_card">
                                        <Row style={{ width: "100%" }} className="no-gutters">
                                            <Col xs={4}>
                                                <div className="g-image"><center style={{ marginTop: "3px" }}> <img src={data.picture} className="games-img" /></center></div>
                                            </Col>
                                            <Col xs={8}>
                                                <div className="ml-2 favbits-title-new">{data.gameName}</div>
                                                <div className="ml-2 game-Text">{data.description > data.description.substring(0, 26) ? data.description.substring(0, 26) + "..." : data.description}</div>
                                                <div className="ml-2 game-rewards"><b>No.of Trails: {data.numberOfTrials}</b></div>
                                                <div className="ml-2 game-rewards"><b>Reward Points: {data.rewardPoints}</b></div>

                                            </Col>
                                        </Row>
                                    </Card>
                                </Link>
                            ))}



                        </div> : <center className="mt-5">No games to display</center>}

                </div>
            </div>
            <div className="mb-bottom"></div>
            <BottomMenu />
        </div >
    )
}
