import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, NavLink } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class MyQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questionDetails: [],
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            isLoading: true
        }
    }
    componentDidMount() {
        this.getQuestionDetails();
    }
    getQuestionDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getQuestionDetailsByUser', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status == true) {
                   // console.log("success");
                    self.setState({
                        questionDetails: response.data.questionData,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });

    };
    render() {
        const { isLoading, questionDetails } = this.state;
        if (isLoading) {
            //console.debug("renders: Loading...");
            return <div className="App"></div>;
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                {/* <div style={{ marginTop: "3rem" }}><br /> */}
                {/* <span><div className="your-fav">
                        <NavLink to="/checkanswers" activeClassName="question-active" className="link" style={{ color: "#FF942C" }}>Expert Answers</NavLink>
                        <NavLink to="/myquestions" activeClassName="question-active" className="your-notification mr-4 link " style={{ float: "right" }}>My Questions</NavLink></div></span> */}
                <div className="ml-4 mr-4" style={{ marginTop: "4.5rem" }}>
                    <div className="recommended-fixed">
                        {/* <img alt="" src={back} className="landing-Image" onClick={this.handleBack} /><br /><br /> */}
                        <div style={{ display: "flex", marginLeft: "15px" }}>
                            <NavLink to="/checkanswers" activeClassName="profile-active" className="link" >
                                <div className="followers-Text">Check Answers </div></NavLink>

                            <NavLink to="/myquestions" activeClassName="profile-active" className="rec-left link" >
                                <div className="followers-Text">My Questions</div></NavLink>

                        </div>
                    </div><br />
                    <div className="ml-1 mt-2 mr-1" >
                        {questionDetails.length !== 0 ?
                            <div>
                                {questionDetails.map((data, i) => (
                                    <Card>
                                        <Row style={{ width: "100%" }}>
                                            <Col xs={10}>
                                                <div className="ml-2 mb-2 favbits-title">Q: {data.question}</div>
                                                <div className="ml-2 mb-2" style={{ fontSize: "10px" }} >{data.createdAt.split("T")[0].split("-").reverse().join("-")}</div>
                                                {/* {data.question !== null && data.question > data.question.substring(0, 40) ?
                                                <div className="favbits-title">{data.question.substring(0, 40)}...</div> :
                                                <div className="favbits-title">{data.question}</div>} */}
                                            </Col>
                                            <Col xs={2}>
                                                <img alt="" src={"../" + data.topicDetails[0].topicName.replace(/ *\([^]*\) */g, "") + ".png"} className="ml-4 favbits-topicicon" />
                                            </Col>
                                        </Row>
                                    </Card>
                                ))}
                            </div> : <center className="mt-5">You have not raised any Question.</center>}
                    </div>
                </div>
                <div className="mb-bottom"></div>
                <BottomMenu />
            </div >
        )
    }
}

// parseMarkdownText(markdownText) {
//     var newMarkdownText = markdownText
//         .match(/^#(.*$)/gim)
//     newMarkdownText.map(el => el.slice(1)).join("</a><br>");
//     console.log("text", newMarkdownText.map(el => el.slice(1)).join("</a><br>"))
//     return newMarkdownText;
// }