import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import procurement from '../images/procurement.png';
import logistics from '../images/logistics.png';
import operations from '../images/operations.png';
import change from '../images/change.PNG';
import BottomMenu from "../BottomMenu/BottomMenu";
import './Settings.css';

export default class Preferences extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <p className="settings">Preferences</p>
                <Row style={{ width: "100%" }}>
                    <Col xs={6}>
                        <Card className="change-card">
                            <center>
                                <a className="close-int">&times;</a><br />
                                <img alt="" src={procurement} className="procurement" />
                                <div className="change-text">Preferences</div>
                            </center>
                        </Card>
                    </Col>
                    <Col xs={6} >
                        <Card className="change-card change-card-left">
                            <center>
                                <a className="close-int">&times;</a><br />
                                <img alt="" src={logistics} className="procurement" />
                                <div className="change-text">Logistics</div>
                            </center>
                        </Card>
                    </Col>
                    <Col xs={6}>
                        <Card className="change-card">
                            <center>
                                <a className="close-int">&times;</a><br />
                                <img alt="" src={operations} className="procurement" />
                                <div className="change-text">Operations</div>
                            </center>
                        </Card>
                    </Col>
                    <Col xs={6} >
                        <Link to="/interests">
                            <Card className="change-card change-card-left"><br />
                                <center>
                                    <img alt="" src={change} className="procurement" />
                                    <div className="change-text">Change your Interests</div>
                                </center>
                            </Card>
                        </Link>
                    </Col>
                </Row>
                <BottomMenu />
            </div>
        )
    }
}