import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Card } from "react-bootstrap";
import loginlogo from '../images/login-logo.png';
import axios from 'axios';
import { config } from '../Config/Config';
import BottomMenu from "../BottomMenu/BottomMenu";
import { Link, Redirect } from 'react-router-dom';
import smefullday from '../images/smefullday.svg';
import smemorning from '../images/smemorning.svg';
import mentoring from '../images/Mentoring.svg';
import moment from "moment-timezone";
import back from '../images/backarrow.png';
import smeafternoon from '../images/smeafternoon.svg';
import smebookings from '../images/smebookings1.svg';
import smerating from '../images/smerating.svg';
import SimpleCrypto from "simple-crypto-js";
import Calendar from "./Calendar";
import SMEProfile from "./SMEProfile";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function SMEConnect({ location }) {
    const [allOfferings, setAllOfferings] = useState([]);
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
    const [allDays, setAllDays] = useState([]);
    const [allTimeSlots, setAllTimeSlots] = useState([]);
    const [desc, setDesc] = useState('');
    const [userId, setUserId] = useState('');
    const [showDescScreen, setShowDescScreen] = useState(true);
    const [agree, setAgree] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [isFirstTime, setIsFirstTime] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [selectedOfferingId, setSelectedOfferingId] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [dateSlot, setDateSlot] = useState();
    const [day, setDay] = useState();
    const [userDate, setUserDate] = useState()
    const [smeData, setSmeData] = useState([])
    const [topics, setTopics] = useState([]);
    const [smeAmount, setSmeAmount] = useState();
    const [selectedTopic, setSelectedTopic] = useState('');
    // const [smeData, setSmeData] = useState([
    //     { "id": 1, "name": "pidex", "profilePicture": "uploads/1652942537335Avatar Users2_37.png", "bookings": 4, "avg_assessment": 4.5 },
    //     { "id": 2, "name": "pidex", "profilePicture": "uploads/1652942537335Avatar Users2_37.png", "bookings": 5, "avg_assessment": 4 },
    //     { "id": 3, "name": "pidex", "profilePicture": "uploads/1652942537335Avatar Users2_37.png", "bookings": 3, "avg_assessment": 4.5 },
    //     { "id": 4, "name": "pidex", "profilePicture": "uploads/1652942537335Avatar Users2_37.png", "bookings": 0, "avg_assessment": 2.5 },
    //     { "id": 5, "name": "pidex", "profilePicture": "uploads/1652942187490Avatar Users2_16.png", "bookings": 1, "avg_assessment": 3.5 },
    //     { "id": 6, "name": "pidex", "profilePicture": "uploads/1652942187490Avatar Users2_16.png", "bookings": 6, "avg_assessment": 5 },
    //     { "id": 7, "name": "pidex", "profilePicture": "uploads/1652942187490Avatar Users2_16.png", "bookings": 8, "avg_assessment": null },
    // ]);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [commercialPlans, setCommercialPlans] = useState([]);
    const [selectedPlanId, setSelectedPlanId] = useState(1);
    // const sortedSmeData = smeData.slice().sort((a, b) => {
    //     const bookingComparison = (b.bookings || 0) - (a.bookings || 0);
    //     if (bookingComparison !== 0) {
    //         return bookingComparison;
    //     }
    //     return (b.avg_assessment || 0) - (a.avg_assessment || 0);
    // });
    useEffect(() => {
        const state = location.state;
        // userId,selectedPlanId,selectedOfferingId,selectedDate,selectedMonth,parseInt(day),new Date(userDate),selectedTimeSlot)
       // console.log("return", state?.selectedPlanId, state?.id, state?.sDate, state?.sMonth, state?.day, state?.selectedTimeSlot, state?.selectedDate)
        if (state) {
            let sId = state?.id
          //  console.log("state", state.sDate)
            setSelectedOfferingId(state.id);
            setSelectedDate(parseInt(state.sDate));
            setSmeAmount(state.smeAmount)
          //  console.log("date", state.selectedDate, state.sDate, state.sMonth, parseInt(state.sMonth ? state.sMonth : moment(state.selectedDate).format("MM"))?.toString()?.padStart(2, '0'))
            setDateSlot(formatDate(state.selectedDate))
            // setSelectedMonth(parseInt(state.sMonth?state.sMonth:moment(state.selectedDate).format("MM")));
            setSelectedMonth(parseInt(state.sMonth ? state.sMonth : moment(state.selectedDate).format("MM"))?.toString()?.padStart(2, '0'));
            setSelectedTimeSlot(state.selectedTimeSlot);
            setSelectedPlanId(state.selectedPlanId);
            setSelectedTopic(state.selectedTopic);
            setDay(state.day)
            let headers = {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + token,
                "applicationType": "mobile"
            }
            var payload = {
                "offering": state.id,
                "day": state.day,
                "topicId": state.selectedTopic,
                "time": state.selectedTimeSlot,
                "commercial": state.selectedPlanId ? state.selectedPlanId : 1
            }
          //  console.log("payload", payload)
            axios.post(config.userUrl + 'user/filterAllSme', payload, { headers: headers })
                .then(function (response) {
                  //  console.log(response);
                    if (response.data.status === true) {
                      //  console.log("success data", response, response.data?.filteredData)
                        let data = response.data?.filteredData.slice().sort((a, b) => {
                            const bookingComparison = (b.bookings || 0) - (a.bookings || 0);
                            if (bookingComparison !== 0) {
                                return bookingComparison;
                            }
                            return (b.avg_assessment || 0) - (a.avg_assessment || 0);
                        })
                        const addOfferingFeeToData = (data, sId) => {
                            return data.map(entry => {
                                const feesArray = JSON.parse(entry.fees);
                                const matchingFee = feesArray.find(feeEntry => feeEntry.option === sId);
                                return {
                                    ...entry,
                                    offeringFee: matchingFee ? matchingFee.fees : null // Add offeringFee to the entry
                                };
                            });
                        };

                        const updatedData = addOfferingFeeToData(data, sId);
                        setSmeData(updatedData)
                        // setShowDescScreen(false)
                        handleShowDescScreenFalse()
                    }
                })
                .catch(function (error) {
                    console.log("Error");
                });
        };
       // console.log("No state", state?.sDate, location?.state)
        getAllSmeOfferings();
        getAllCommercialPlans();
        getAllTimeSlots();
        getAllRegisterTopics();
        // const currentYear = new Date().getFullYear();
        // const newDate = new Date(currentYear, parseInt(location?.state?.sMonth) - 1, parseInt(location?.state?.sDate));
        // const changedDate = moment(newDate).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
        // setSelectedDate(new Date(changedDate))
        // setUserDate(userDate ? new Date(userDate) : new Date(changedDate))
        // console.log("changed", changedDate)
        // setSelectedOfferingId(state?.id);
        // setSelectedDate(state?.sDate);
        // console.log("date esle", state?.selectedDate, state?.sDate, state?.sMonth)
        // setDateSlot(formatDate(new Date(state?.selectedDate)))
        // setSelectedMonth(state?.sMonth);
        // setSelectedTimeSlot(state?.selectedTimeSlot);
        // setSelectedPlanId(state?.selectedPlanId);
    }, [location.state]);
    const handleShowDescScreen = (date) => {
       // console.log("date back", date, moment(new Date(date)).format("DD"), moment(new Date(date)).format("MM"))
        setShowDescScreen(true)
        // setSelectedDate(new Date(date))
        setSelectedDate(parseInt(moment(new Date(date)).format("DD")))
        const monthNumber = parseInt(moment(new Date(date)).format("M"), 10);
        const formattedMonth = monthNumber < 10 ? monthNumber : monthNumber.toString().replace(/^0+/, '');
        setUserDate(new Date(date))
       // console.log("back", formattedMonth, typeof parseInt(formattedMonth), typeof parseInt(moment(new Date(date)).format("DD")));
        setSelectedMonth(parseInt(formattedMonth));
        setDateSlot(formatDate(new Date(date)))
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return "Invalid Date";
        }
        const formattedDate = date.toISOString();
        return formattedDate;
    }
    const getAllRegisterTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllRegisterTopics', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    setTopics(response.data.topics)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    setAllOfferings(response.data.offerings)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllCommercialPlans = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllCommercialPlans', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    setCommercialPlans(response.data.commercialPlans)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const handleDateClick = (date, month, day) => {
       // console.log("date click type", typeof date, typeof month);
        setSelectedDate(date);
        setSelectedMonth(month);
      //  console.log("booking date", date, typeof month, month, day); // Use month here
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
      //  console.log(currentYear, currentYear + "-" + month + "-" + date);
        // setUserDate(currentYear + "-" + month + "-" + date)
        const date1 = new Date(currentYear, month - 1, date);
        const formattedDate = date1.toISOString(); // Convert date to ISO string format
     //   console.log("formattedDate", formattedDate);
        const formattedDateTime = formattedDate.slice(0, 10) + ' 06:21:04.224+00';
      //  console.log("formattedDateTime", formattedDateTime);
        setDateSlot(formattedDate);
     //   console.log("day", day);
        const dayNumber = mapDayToNumber(day);
        setDay(dayNumber); // Store the selected day

        const dateObject = new Date(formattedDate);

        // Convert to India Standard Time (IST)
        const indianDateObject = new Date(dateObject.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));

       // console.log(indianDateObject.toString());
        // setUserDate(indianDateObject.toString())
        setUserDate(indianDateObject.toString())


    };

    const mapDayToNumber = (day) => {
     //   console.log("daysfcvgbhnjm", day)
        switch (day?.toLowerCase()) {
            case 'sunday':
                return 7;
            case 'monday':
                return 1;
            case 'tuesday':
                return 2;
            case 'wednesday':
                return 3;
            case 'thursday':
                return 4;
            case 'friday':
                return 5;
            case 'saturday':
                return 6;
            default:
                return -1; // Invalid day
        }
    };

    const getAllTimeSlots = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllTimeSlots', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    setAllTimeSlots(response.data.timeSlots)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const filterSme = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "offering": parseInt(selectedOfferingId),
            "day": parseInt(day),
            "time": selectedTimeSlot,
            "commercial": parseInt(selectedPlanId),
            "topicId": parseInt(selectedTopic)
        }
       // console.log("payload", payload)
        axios.post(config.userUrl + 'user/filterAllSme', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                  //  console.log("success data", response)
                    let data = response.data?.filteredData.slice().sort((a, b) => {
                        const bookingComparison = (b.bookings || 0) - (a.bookings || 0);
                        if (bookingComparison !== 0) {
                            return bookingComparison;
                        }
                        return (b.avg_assessment || 0) - (a.avg_assessment || 0);
                    })
                    const addOfferingFeeToData = (data, selectedOfferingId) => {
                        return data.map(entry => {
                            const feesArray = JSON.parse(entry.fees);
                            const matchingFee = feesArray.find(feeEntry => feeEntry.option === selectedOfferingId);
                            return {
                                ...entry,
                                offeringFee: matchingFee ? matchingFee.fees : null // Add offeringFee to the entry
                            };
                        });
                    };

                    const updatedData = addOfferingFeeToData(data, selectedOfferingId);
                    setSmeData(updatedData)
                    handleShowDescScreenFalse();
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const handleOfferingClick = (id) => {
        setSelectedOfferingId(id);
    };
    const handleSelectTopic = (id) => {
        setSelectedTopic(id);
        // if (selectedOptions.includes(id)) {
        //     setSelectedOptions(selectedOptions.filter(topicId => topicId !== id));
        // } else {
        //     if (selectedOptions.length < 3) {
        //         setSelectedOptions([...selectedOptions, id]);
        //     }
        // }
    };
    const handleShowDescScreenFalse = () => {
        setShowDescScreen(false);
        const state = location?.state;
       // console.log("state connect", userId, selectedPlanId, selectedOfferingId, selectedDate, selectedMonth, parseInt(day), new Date(userDate), selectedTimeSlot)
        // setSelectedOfferingId(state?.id);
        // setSelectedDate(state?.sDate);
        // console.log("false ", state?.selectedDate, state?.sDate, state?.sMonth)
        // setDateSlot(formatDate(state.selectedDate))
        // setSelectedMonth(state?.sMonth);
        // setSelectedTimeSlot(state?.selectedTimeSlot);
        // setSelectedPlanId(state?.selectedPlanId);
        // setDay(state?.day)
    };
    const toggleSelection = (userId) => {
        setSelectedUserIds(prevSelected => {
            if (prevSelected.includes(userId)) {
                return prevSelected.filter(id => id !== userId); // Deselect if already selected
            } else {
                return [...prevSelected, userId]; // Select if not already selected
            }
        });
    };
    const handleProfile = (data, date) => {
        const state = location.state;
       // console.log("id", data.userId, date)
       // console.log("handleProfile", state?.selectedPlanId, state?.id, state?.sDate, state?.sMonth, state?.day, state?.selectedTimeSlot, state?.selectedDate)
        setUserId(data.userId)
        setSmeAmount(data.offeringFee)
        setShowProfile(true)
        // setSelectedDate(new Date(date))
    }
    const formatRatingNumber = (number) => {
        const roundedNumber = Math.round(number * 100) / 100; // Round to two decimal places
        const decimalPart = roundedNumber - Math.floor(roundedNumber);

        if (decimalPart === 0) {
            return Math.floor(roundedNumber); // Remove decimal part if it's zero
        } else {
            return roundedNumber.toFixed(1); // Keep two decimal places otherwise
        }
    }
    if (showProfile) {
        return <Redirect to={{
            pathname: "/smeprofile",
            state: { userId: userId, selectedTopic: selectedTopic,smeAmount:smeAmount, selectedPlanId: selectedPlanId, id: selectedOfferingId, sDate: selectedDate ? selectedDate : moment(location?.state?.selectedDate).format("DD"), sMonth: selectedMonth ? selectedMonth : moment(location?.state?.selectedDate).format("MM"), day: parseInt(day), userDate: new Date(userDate) ? new Date(userDate) : location?.state?.selectedDate, userTimeSlot: selectedTimeSlot }
        }} />
    }
    return (
        <div >
            {/* {showProfile && <SMEProfile userId={userId} selectedPlanId={selectedPlanId} id={selectedOfferingId} sDate={selectedDate ? selectedDate : moment(location?.state?.selectedDate).format("DD")} sMonth={selectedMonth ? selectedMonth :  moment(location?.state?.selectedDate).format("MM")} day={parseInt(day)} userDate={new Date(userDate) ? new Date(userDate) : location?.state?.selectedDate} userTimeSlot={selectedTimeSlot}/>} */}
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="screen-topgap-sme">
                {showDescScreen ? (
                    <div>
                        <div className="your-jitbits">Specifications to Connect</div>
                        <div className="sme-connect-box" style={{ maxWidth: "100%", overflowX: "hidden" }}>
                            <div>
                                <div className="mb-2 abt-u_" style={{ marginTop: "-2px" }}>Expertise you are seeking</div>
                                <div className="ml-2 mr-2" style={{ marginTop: "-5px", border: "1px solid gray", height: "82px", borderRadius: "20px", overflow: "hidden" }}>
                                    <div className="interests_main">
                                        <ScrollMenu>
                                            <center className="flex" style={{ padding: "0.8px 4px", gap: "3px", marginTop: "1px" }}>
                                                {topics.map((data, i) => (
                                                    <div
                                                        className="mt-2"
                                                        key={data.id}
                                                        onClick={() => handleSelectTopic(data.id)}
                                                        style={{
                                                            width: "calc(5.4rem - 2px)",
                                                            border: selectedTopic === data.id ? "2px solid #ff942c" : "",
                                                            borderRadius: "15px",
                                                            padding: "4px",
                                                            cursor: "pointer",
                                                            boxSizing: "border-box"
                                                        }}
                                                    >
                                                        <img src={data.topicName.replace(/ *\([^]*\) */g, "") + ".png"} style={{ width: "25px" }} alt={data.topicName} /><br />
                                                        <div className="sme-off-text">{data.topicName.replace(/ *\([^]*\) */g, "")}</div>
                                                    </div>
                                                ))}
                                            </center>
                                        </ScrollMenu>
                                    </div>
                                </div>
                            </div>
                            <div className="abt-u_">Objective</div>
                            <div className="ml-2 mr-2" style={{ border: "1px solid gray", borderRadius: "20px", height: "fit-content", padding: "2px" }}>
                                <center className="mb-1 flex" style={{ gap: "3px" }}>
                                    {allOfferings.map((data, i) => (
                                        <div
                                            key={data.id}
                                            onClick={() => handleOfferingClick(data.id)}
                                            style={{
                                                width: "4.5rem",
                                                border: selectedOfferingId === data.id ? "2px solid #ff942c" : "2px solid transparent",
                                                borderRadius: "15px",
                                                cursor: "pointer",
                                                boxSizing: "border-box"
                                            }}
                                        >
                                            <img src={data.text.replace(/ *\([^]*\) */g, "") + ".svg"} alt={data.text} /><br />
                                            <div className="sme-off-text">{data.text}</div>
                                        </div>
                                    ))}
                                </center>
                            </div>


                            <div className="abt-u_">Slot (30 min) in IST</div>
                            <div className="ml-2 mr-2 mb-2" style={{ border: "1px solid gray", borderRadius: "20px", height: "fit-content" }}>
                                <div className="mb-1" style={{ marginTop: "-4px" }}>
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        {allTimeSlots.map((data, i) => (
                                            <Col key={data.id} className="slot-time" onClick={() => setSelectedTimeSlot(data.id)}>
                                                <center>
                                                    <div style={{
                                                        width: "80%",
                                                        border: selectedTimeSlot === data.id ? "2px solid #ff942c" : "2px solid transparent", // Set border for all items
                                                        borderRadius: "10px",
                                                        padding: "2px"
                                                    }}>
                                                        <img src={data.id === 1 ? smefullday : data.id === 2 ? smemorning : smeafternoon} alt={data.id} />
                                                        <TimeRange time={data.time} />
                                                    </div>
                                                </center>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                            <div style={{ marginTop: "-6px" }}>
                                <Calendar
                                    selectedDate={selectedDate}
                                    selectedMonth={selectedMonth}
                                    handleDateClick={handleDateClick}
                                />
                            </div>
                            <div className="abt-u_">Fees (INR)</div>
                            <div className="ml-2 mr-2 mb-2" style={{ border: "1px solid gray", borderRadius: "15px" }}>
                                <div className="ml-2 mr-2 mb-2 flex" style={{ justifyContent: "space-between" }}>

                                    {commercialPlans.map((data, i) => (
                                        <div className="ml-1 sme-txt-size" style={{ display: "flex" }}>
                                            <input
                                                type="radio"
                                                name="plans"
                                                value={data.id}
                                                checked={selectedPlanId === data.id}
                                                // disabled={data.id === 2 || data.id === 3 || data.id === 4}
                                                onChange={() => setSelectedPlanId(data.id)}
                                                style={{ accentColor: "#31197C" }}
                                            />
                                            <span className="ml-1" style={{ marginTop: "-1px" }}>{data.plan}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mt-2">
                                <Button
                                    variant="default"
                                    className="mr-2 mb-1 sme-connect-next-btn"
                                    onClick={filterSme}
                                    disabled={!selectedOfferingId || !selectedTopic || !selectedPlanId || !selectedTimeSlot || !dateSlot}
                                >
                                    Select
                                </Button>
                            </div>
                            <div className="mt-5"></div>
                        </div>
                    </div>
                ) : (
                    // SME offerings screen
                    <div>
                        <div className="your-jitbits" style={{ display: "flex" }}><div onClick={() => handleShowDescScreen(dateSlot)}><img src={back} style={{ width: "30px" }} /></div><div className="ml-1 mt-1">Recommended SMEs</div></div>

                        <div className="ml-2 mr-2 mb-2" style={{ border: "1px solid gray", borderRadius: "20px" }}>
                            {smeData?.length !== 0 ?
                                <div className="sme-map ml-1 mr-1">
                                    <Row style={{ width: "100%" }} className="mt-1 no-gutters">
                                        {smeData?.map((data, i) => {
                                            const backgroundColor = i % 6 < 3 ? "linear-gradient(to right bottom, #efe2ef, #e9e2f2, #e1e3f5, #d9e4f6, #d1e5f5, #d1e5f5, #d1e5f5, #d1e5f5, #d9e4f6, #e1e3f5, #e9e2f2, #efe2ef)" : "linear-gradient(to right bottom, #d1e5f5, #d9e4f6, #e1e3f5, #e9e2f2, #efe2ef, #efe2ef, #e9e2f2, #e1e3f5, #d9e4f6, #d1e5f5, #d1e5f5, #d1e5f5)";
                                            return (
                                                <Col xs={4} onClick={() => handleProfile(data, userDate)}>
                                                    <Card style={{ borderRadius: "10px", border: "none", background: backgroundColor, boxShadow: "2px 2px 6px #bebebe,-2px -2px 6px #ffffff", margin: "4px" }}>
                                                        <Row style={{ width: "100%" }} className="no-gutters">
                                                            <Col>
                                                                <center>
                                                                    <div style={{ padding: "1px 6px", marginTop: "-2px", lineHeight: "20px", fontSize: "14px", fontFamily: "Noto Sans", fontWeight: 600, height: "40px", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical" }}>
                                                                        {data.name}
                                                                    </div>
                                                                </center>
                                                                <center className="mt-1"> <img alt="" src={config.userUrl + data.profilePicture} style={{ width: "50px" }} /></center>

                                                                <div style={{ marginTop: "-2px", fontSize: "12px", display: "flex", justifyContent: "space-around" }}>
                                                                    <span><img src={smebookings} style={{ width: "28px" }} />&nbsp;<b>{data.bookings}</b></span>
                                                                    <span><img src={smerating} style={{ width: "28px" }} />&nbsp;<b>{formatRatingNumber(data.avg_assessment)}</b> </span>
                                                                </div>
                                                                <center>
                                                                    <div className="mb-1" style={{ fontSize: "16px", fontFamily: "Noto Sans", fontWeight: 600 }}>Fees: {data.offeringFee}</div>
                                                                </center>
                                                            </Col>
                                                        </Row>

                                                    </Card>
                                                </Col>
                                                // <Col xs={4} onClick={() => handleProfile(data.userId, userDate)}>
                                                //     <Card style={{ borderRadius: "10px", background: backgroundColor, margin: "2px" }}>
                                                //         <Row style={{ width: "100%" }} className="no-gutters">
                                                //             <Col>
                                                //                 <center> <img alt="" src={config.userUrl + data.profilePicture} style={{ width: "40px" }} /></center>
                                                //             </Col>
                                                //             <Col>
                                                //                 <div style={{ marginTop: "-4px", fontSize: "12px" }}>
                                                //                     <span><img src={smebookings} style={{ width: "28px" }} />&nbsp;<b>{data.bookings}</b></span>
                                                //                     <span><img src={smerating} style={{ width: "28px" }} />&nbsp;<b>{formatRatingNumber(data.avg_assessment)}</b> </span>
                                                //                 </div>
                                                //             </Col>
                                                //         </Row>


                                                //         <center>
                                                //             <div className="mb-1" style={{ marginTop: "-2px", fontSize: "14px", fontFamily: "Noto Sans", fontWeight: 600 }}>{data.name}</div>
                                                //         </center>
                                                //     </Card>
                                                // </Col>
                                            )
                                        })}
                                    </Row>

                                </div> : <div className="sme-map1"><center><div style={{ marginTop: "30vh" }}>No Sme's found</div></center>
                                </div>}

                        </div>
                    </div>
                )}
            </div>
            <div className="mb-bottom"></div>
            <BottomMenu />
        </div >
    )
}

function TimeRange({ time }) {
    const openingBracketIndex = time.indexOf(' (');
    const dayPart = openingBracketIndex !== -1 ? time.slice(0, openingBracketIndex) : time;
    const closingBracketIndex = openingBracketIndex !== -1 ? time.indexOf(')', openingBracketIndex) : -1;
    const displayTimePart = closingBracketIndex !== -1 ? time.slice(openingBracketIndex + 2, closingBracketIndex) : '';

    return (
        <div style={{ marginTop: "-3px", lineHeight: "13px" }}>
            <span style={{ fontSize: "11px" }}>{dayPart}</span><br />
            <span style={{ whiteSpace: "nowrap", fontSize: "11px" }}>{displayTimePart}</span>
        </div>
    );
}
