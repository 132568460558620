import React, { useEffect, useState } from "react";
import loginlogo from '../images/login-logo.png';
import back from '../images/backarrow.png';
import { Card, Col, Row } from "react-bootstrap";
import BottomMenu from "../BottomMenu/BottomMenu";
import './Terms.css';
import axios from 'axios';
import { config } from '../Config/Config';
// import likes from '../images/sceem-likes.png';
import writearticle from '../images/sceem-writearticle.png';
import readarticle from '../images/sceem-readarticle.png';
import followers from '../images/sceem-followers.png';
import following from '../images/sceem-following.png';
import pollscreated from '../images/sceem-polls created.png';
import pollsparticipate from '../images/sceem-polls participate.png';
import months from '../images/sceem-months.png';
import { Bar } from 'react-chartjs-2';
import sceemlore from '../images/sceemlore-logo.svg';
import articlesPublished from '../images/articles published.png';
import articlesRead from '../images/articles read.png';
import qna from '../images/QnA.svg';
import opportunities from '../images/opportunity.png';
import campaigns from '../images/campaigns.png';
import aboutPolls from '../images/about-polls.png';
import eminentAuthors from '../images/eminent authors.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function About() {

    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [campaignsCount, setCampaignsCount] = useState("");
    const [likesCount, setLikesCount] = useState("");
    const [pollsCount, setPollsCount] = useState("");
    const [publishedPostsCount, setPublishedPostsCount] = useState("");
    const [registeredUserCount, setRegisteredUserCount] = useState("");
    const [totalArticleViews, setTotalArticleViews] = useState("");
    const [totalQuestions, setTotalQuestions] = useState("");
    const [opportunitiesCount, setOpportunitiesCount] = useState("");
    const [cardManagementCount, setCardManagementCount] = useState("");
    const [topicDetails, setTopicDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        getAboutPageDetails();
    }, []);

    const getAboutPageDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/aboutPageDetails', { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                    setCampaignsCount(response.data.campaignsCount);
                    setLikesCount(response.data.likesCount);
                    setPollsCount(response.data.pollsCount);
                    setPublishedPostsCount(response.data.publishedPostsCount);
                    setRegisteredUserCount(response.data.registeredUserCount);
                    setTopicDetails(response.data.topicDetails);
                    setTotalArticleViews(response.data.totalArticleViews);
                    setTotalQuestions(response.data.totalQuestions);
                    setOpportunitiesCount(response.data.totalOpportunitiesCount);
                    setCardManagementCount(response.data.cardManagementCount)
                    setIsLoading(false);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };

    if (isLoading) {
        //console.debug("renders: Loading...");
        return <h3 className="App"></h3>;
    }

    let filterTopics = [];
    for (let i = 0; i < topicDetails.length; i++) {
        if (topicDetails[i].postsCount >= 2) {
            filterTopics.push(topicDetails[i]);
        }
    }
   // console.log("labels", filterTopics)
    function kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num)
    }
    const data = {
        labels: [topicDetails[0].topicName.replace(/ *\([^]*\) */g, ""), topicDetails[1].topicName.replace(/ *\([^]*\) */g, ""), topicDetails[2].topicName.replace(/ *\([^]*\) */g, ""), topicDetails[3].topicName.replace(/ *\([^]*\) */g, "")],
        datasets: [
            {
                data: [topicDetails[0].postsCount, topicDetails[14].postsCount, topicDetails[2].postsCount, topicDetails[3].postsCount],
                fill: true,
                backgroundColor: ["#31197C"],
                borderColor: "rgba(75,192,192,1)",
                barThickness: 25
            }
        ]
    }
    return (
        <div>
            <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
            <p className="your-jitbits mt-5">Statistics</p>
            <div className="container">
                <div className="row">
                    <div className="ml-3 col-xs-4">
                        <Card className="articles-Published">
                            <center>
                                <img alt="" src={articlesPublished} style={{ width: "32px", height: "32px" }} />
                                <div className="bold-text">{kFormatter(publishedPostsCount)}</div>
                                <div className="text-in-about">Published</div>
                            </center>
                        </Card>
                    </div>
                    <div className="ml-2 col-xs-4">
                        <Card className="articles-Published">
                            <center>
                                <img alt="" src={articlesRead} style={{ width: "32px", height: "32px" }} />
                                <div className="bold-text">{kFormatter(totalArticleViews)}</div>
                                <div className="text-in-about">Read</div>
                            </center>
                        </Card>
                    </div>
                    <div className="ml-2 col-xs-4">
                        <Card className="articles-Published">
                            <center>
                                <img alt="" src={opportunities} style={{ width: "40px", height: "40px" }} />
                                <div style={{ marginTop: "-10px" }}>
                                    <div className="bold-text">{kFormatter(opportunitiesCount)}</div>
                                    <div className="text-in-about">Opportunities</div>
                                </div>
                            </center>
                        </Card>
                    </div>
                </div>
            </div>
            <Card className="mt-2 articles-graph">
                <div> <center style={{ marginTop: "-8px" }} className="text-in-about">Top Articles per Topic</center>
                    <Bar data={data}
                        options={{
                            scales: {
                                scaleOverride: true,
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            label: {
                                display: true
                            },
                            plugins: {
                                legend: { display: false }
                            },
                            tooltips: {
                                callbacks: {
                                    label: function (tooltipItem) {
                                        return tooltipItem.yLabel;
                                    }
                                }
                            }

                        }
                        }
                    />
                </div>
            </Card>
            <div className="mt-1 container">
                <div className="row" >
                    <div className="ml-3 col-xs-4">
                        <Card className="campaigns-polls">
                            <div className="row">
                                <Col xs={4}>
                                    <center className="ml-3"> <img alt="" src={campaigns} style={{ width: "32px", height: "32px", marginTop: "-6px" }} /></center>
                                </Col>
                                <Col xs={8}>
                                    <center style={{ marginTop: "-8px" }}>
                                        <div className="bold-text">{kFormatter(campaignsCount)}</div>

                                    </center>
                                </Col>
                                <div className="text-in-about">Advertisements</div>
                            </div>
                        </Card>
                    </div>
                    <div className="ml-1 col-xs-4">
                        <Card className="campaigns-polls">
                            <div className="row">
                                <Col xs={4}>
                                    <center className="ml-3"> <img alt="" src={aboutPolls} style={{ width: "32px", height: "32px", marginTop: "-6px" }} /></center>
                                </Col>
                                <Col xs={8}>
                                    <center style={{ marginTop: "-8px" }}>
                                        <div className="bold-text">{kFormatter(pollsCount)}</div>

                                    </center>
                                </Col>
                                <div className="text-in-about">Polls</div>
                            </div>
                        </Card>
                    </div>
                    <div className="ml-1 col-xs-4">
                        <Card className="campaigns-polls">
                            <div className="row">
                                <Col xs={4}>
                                    <center className="ml-3"> <img alt="" src={qna} style={{ width: "32px", height: "32px", marginTop: "-6px" }} /></center>
                                </Col>
                                <Col xs={8}>
                                    <center style={{ marginTop: "-8px" }}>
                                        <div className="bold-text">{kFormatter(totalQuestions)}</div>

                                    </center>
                                </Col>
                                <div className="text-in-about">Q&A</div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            {/* <div className="mt-1 container">
                <div className="row" >
                    <div className="ml-3 col-xs-4">
                        <Card className="questions-">
                            <div className="row">
                                <Col xs={3}>
                                    <center className="ml-3 mt-2"> <img alt="" src={qna} style={{ width: "42px", height: "42px" }} /></center>
                                </Col>
                                <Col xs={9}>
                                    <center style={{ marginTop: "-10px" }}>
                                        <div className="bold-text">{kFormatter(totalQuestions)}</div>
                                        <div className="text-in-about">Q&A</div>
                                    </center>
                                </Col>
                            </div>
                        </Card>
                    </div>
                    <div className="ml-2 col-xs-8">
                        <Card className="questions-authors">
                            <div className="row">
                                <Col xs={3}>
                                    <center className="ml-3 mt-2"> <img alt="" src={eminentAuthors} style={{ width: "42px", height: "42px" }} /></center>
                                </Col>
                                <Col xs={9}>
                                    <center style={{ marginTop: "-10px" }}>
                                        <div className="bold-text">{kFormatter(registeredUserCount)}</div>
                                        <div className="text-in-about">Eminent Authors, Reviewers & Readers</div>
                                    </center>
                                </Col>
                            </div>
                        </Card>
                    </div>
                </div>
            </div> */}
            <div className="mt-1 container">
                <div className="row" >
                    <div className="ml-3 col-xs-8">
                        <Card className="eminent-e">
                            <div className="row">
                                <Col xs={4}>
                                    <center className="ml-3"> <img alt="" src={eminentAuthors} style={{ width: "32px", height: "32px", marginTop: "-18px" }} /></center>
                                </Col>
                                <Col xs={8}>
                                    <center style={{ marginTop: "-20px" }}>
                                        <div className="bold-text">{kFormatter(registeredUserCount)}</div>

                                    </center>
                                </Col>
                                <center className="text-in-about">Enterprises, Entrepreneurs, Experts, Enthusiasts</center>
                            </div>
                        </Card>
                    </div>
                    <div className="ml-1 col-xs-4">
                        <Card className="campaigns-polls">
                            <div className="row">
                                <Col xs={4}>
                                    <center className="ml-3"> <img alt="" src={sceemlore} style={{ width: "32px", height: "32px", marginTop: "-6px" }} /></center>
                                </Col>
                                <Col xs={8}>
                                    <center style={{ marginTop: "-8px" }}>
                                        <div className="bold-text">{kFormatter(cardManagementCount)}</div>
                                    </center>
                                </Col>
                                <div className="text-in-about">Sceemlore</div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            {/* <Card className="mt-2 eminent-authors">
                <div className="row">
                    <Col xs={4}>
                        <center className="ml-1 mt-2"> <img alt="" src={eminentAuthors} style={{ width: "42px", height: "42px" }} /></center>
                    </Col>
                    <Col xs={8}>
                        <center style={{ marginTop: "-10px", marginLeft: "-20px" }}>
                            <div className="bold-text">{kFormatter(registeredUserCount)}</div>
                            <div className="text-in-about">Enterprises, Entrepreneurs, Experts, Enthusiasts</div>
                        </center>
                    </Col>
                </div>
            </Card>
            <div className="ml-1 col-xs-4">
                <Card className="campaigns-polls">
                    <div className="row">
                        <Col xs={4}>
                            <center className="ml-3"> <img alt="" src={qna} style={{ width: "32px", height: "32px", marginTop: "-10px" }} /></center>
                        </Col>
                        <Col xs={8}>
                            <center style={{ marginTop: "-12px" }}>
                                <div className="bold-text">{kFormatter(cardManagementCount)}</div>

                            </center>
                        </Col>
                        <div className="text-in-about">Sceemlore</div>
                    </div>
                </Card>
            </div> */}
            <div className="mb-bottom"></div>

            <BottomMenu />
        </div >
    )
}

// import React, { Component } from "react";
// import loginlogo from '../images/login-logo.png';
// import back from '../images/backarrow.png';
// import { Card, Col, Row } from "react-bootstrap";
// import BottomMenu from "../BottomMenu/BottomMenu";
// import './Terms.css';
// import axios from 'axios';
// import { config } from '../Config/Config';
// import likes from '../images/sceem-likes.png';
// import writearticle from '../images/sceem-writearticle.png';
// import readarticle from '../images/sceem-readarticle.png';
// import followers from '../images/sceem-followers.png';
// import following from '../images/sceem-following.png';
// import pollscreated from '../images/sceem-polls created.png';
// import pollsparticipate from '../images/sceem-polls participate.png';
// import months from '../images/sceem-months.png';
// import { Bar } from 'react-chartjs-2';

// export default class About extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             token: localStorage.getItem('token'),
//             campaignsCount: "",
//             likesCount: "",
//             pollsCount: "",
//             publishedPostsCount: "",
//             registeredUserCount: "",
//             totalArticleViews: "",
//             topicDetails: [],
//             isLoading: true
//         }

//     }
//     componentDidMount() {
//         this.getAboutPageDetails();
//     }
//     getAboutPageDetails = () => {
//         let headers = {
//             "Content-Type": 'application/json',
//             "Authorization": "Bearer " + this.state.token,
//             "applicationType": "mobile"
//         }
//         var self = this
//         axios.get(config.userUrl + 'user/aboutPageDetails', { headers: headers })
//             .then(function (response) {
//                 console.log(response);
//                 if (response.data.status === true) {
//                     self.setState({
//                         campaignsCount: response.data.campaignsCount,
//                         likesCount: response.data.likesCount,
//                         pollsCount: response.data.pollsCount,
//                         publishedPostsCount: response.data.publishedPostsCount,
//                         registeredUserCount: response.data.registeredUserCount,
//                         topicDetails: response.data.topicDetails,
//                         totalArticleViews: response.data.totalArticleViews,
//                         isLoading: false,
//                     })
//                 }
//             })
//             .catch(function (error) {
//                 console.log("Error");
//             });
//     };
//     render() {
//         const { topicDetails, isLoading } = this.state;

//         kFormatter = (num) => {
//             return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
//         }

//         if (isLoading) {
//             return <div className="App"></div>;
//         }
//         const data = {
//             labels: [topicDetails[0].topicName, topicDetails[1].topicName, topicDetails[2].topicName, topicDetails[3].topicName],
//             datasets: [
//                 {

//                     data: [topicDetails[0].postsCount, topicDetails[1].postsCount, topicDetails[2].postsCount, topicDetails[3].postsCount],
//                     fill: true,
//                     backgroundColor: ["#31197C"],
//                     borderColor: "rgba(75,192,192,1)",
//                     barThickness: 4
//                 }
//             ]
//         }
//         return (
//             <div className="aboutcolor">
//                 <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
//                 <br />
//                 <p className="terms-heading-about"></p>
//                 <img alt="" src="square.png" class="square-new" />

//                 <Card className="write-articles-count neomorphic-shadows-in">
//                     <h6 style={{ display: "flex" }}> <div className="new-cir4"><img alt="" src={writearticle} style={{ width: "25px", marginTop: "6px", marginLeft: "6px" }} /></div>  &nbsp;JitBits</h6>
//                     <center style={{ display: "flex", marginTop: "-5px" }}><h3 className="bold-text">kFormatter({this.state.publishedPostsCount})</h3> <span className="ml-2 mt-1" style={{ color: "green" }}>Articles Published</span></center>
//                 </Card>
//                 <Card className="likes-count neomorphic-shadows">
//                     <h6 style={{ display: "flex" }}><div className="new-cir3"><img alt="" src={likes} style={{ width: "23px", marginTop: "4px", marginLeft: "5px" }} /></div>  &nbsp;Likes</h6>
//                     <center style={{ marginTop: "-5px" }}>   <h3 className="bold-text">{this.state.likesCount}.6K</h3></center>
//                 </Card>
//                 <Card className="articles-count neomorphic-shadows">
//                     {/* <h6 style={{ display: "flex" }}> <div className="new-cir4"><img alt="" src={writearticle} style={{ width: "35px", marginTop: "8px", marginLeft: "8px" }} /></div>  &nbsp;Articles</h6>
//                     <center><h3 className="bold-text">{this.state.publishedPostsCount}</h3></center> */}

//                     <div> <center style={{ marginTop: "-15px", color: "green" }}>Articles per Topic</center>
//                         <Bar data={data}
//                             options={{
//                                 scales: {
//                                     scaleOverride: true,
//                                     x: {
//                                         grid: {
//                                             display: false,
//                                         },
//                                     },
//                                     y: {
//                                         grid: {
//                                             display: false,
//                                         },
//                                     },
//                                 },
//                                 label: {
//                                     display: true
//                                 },
//                                 plugins: {
//                                     legend: { display: false }
//                                 },
//                                 tooltips: {
//                                     callbacks: {
//                                         label: function (tooltipItem) {
//                                             return tooltipItem.yLabel;
//                                         }
//                                     }
//                                 }

//                             }
//                             }
//                         />
//                     </div>
//                 </Card>
//                 <Card className="polls-count neomorphic-shadows-in">
//                     <h6 style={{ display: "flex" }}><div className="new-cir1"><img alt="" src={pollsparticipate} style={{ width: "24px", marginTop: "6px", marginLeft: "6px" }} /></div>  &nbsp;Polls</h6>
//                     <center style={{ marginTop: "-5px" }}><h3 className="bold-text">{this.state.pollsCount}.6K</h3></center>
//                 </Card>
//                 <Card className="article-read-count">
//                     <h6 style={{ display: "flex" }}><div className="new-cir5"><img alt="" src={readarticle} style={{ width: "24px", marginTop: "6px", marginLeft: "6px" }} /></div>  &nbsp; Reach</h6>
//                     <center style={{ display: "flex", marginTop: "-5px" }}><h3 className="bold-text">{this.state.totalArticleViews}.6K</h3> <span className="ml-2 mt-1" style={{ color: "green" }}>Articles Read</span></center>
//                 </Card>

//                 <Card className="campaigns-count neomorphic-shadows">
//                     <h6 style={{ display: "flex" }}><div className="new-cir"><img alt="" src={readarticle} style={{ width: "25px", marginTop: "6px", marginLeft: "6px" }} /></div>  &nbsp;Campaigns</h6>
//                     <center style={{ marginTop: "-5px" }}><h3 className="bold-text">{this.state.campaignsCount}.6K</h3></center>
//                 </Card>
//                 <Card className="reach-count neomorphic-shadows">
//                     <h6 style={{ display: "flex" }}> <div className="new-cir2"><img alt="" src={followers} style={{ width: "25px", marginTop: "5px", marginLeft: "6px" }} /></div>  &nbsp;User Base </h6>
//                     <center style={{ display: "flex", marginTop: "-5px" }}><h3 className="bold-text">{this.state.registeredUserCount}.6K</h3> <span className="ml-2 mt-1" style={{ color: "green" }}>Eminent Authors, Reviewers & Readers</span></center>
//                 </Card>
//                 <img alt="" src="circle.png" class="circle-new" />
//                 <img alt="" src="circle-yellow.png" class="circle-yellow-new" />
//                 <div class="circle-reverse-yellow-new" />

//                 <BottomMenu />
//             </div >
//         )
//     }
// }
// shots.reduce((acc, shot) => acc = acc > shot.amount ? acc : shot.amount, 0);
{/* <div className="ml-4">  Campaigns Count: {this.state.campaignsCount}<br />
                    Likes Count: {this.state.likesCount}<br />
                    Polls Count: {this.state.pollsCount}<br />
                    Published Posts Count: {this.state.publishedPostsCount}<br />
                    Registered User Count: {this.state.registeredUserCount}<br />
                    <table class="table table-borderless Table" style={{ width: "80%" }}>
                        <thead>
                            <tr className="poll-table-heading">
                                <th scope="col">Topic Name</th>
                                <th scope="col">Count</th>
                            </tr>
                        </thead>
                        {this.state.topicDetails.map((data, i) => (
                            <tbody className="poll-table-text pollCard">
                                <td>{data.topicName}</td>
                                <td>{data.postsCount}</td>
                            </tbody>
                        ))}<br />
                    </table>
                </div> */}


                // <p className="terms-heading-about">About Sceem</p>
                // <img alt="" src="square.png" class="square-new" />
                // <Card className="likes-count neomorphic-shadows">
                //     <h6 style={{ display: "flex" }}><div className="new-cir3"><img alt="" src={likes} style={{ width: "25px", marginTop: "4px", marginLeft: "5px" }} /></div>  &nbsp;Likes</h6>
                //     <center>   <h3 className="bold-text">{this.state.likesCount}</h3></center>
                // </Card>
                // <Card className="campaigns-count neomorphic-shadows">
                //     <h6 style={{ display: "flex" }}><div className="new-cir"><img alt="" src={readarticle} style={{ width: "30px", marginTop: "8px", marginLeft: "7px" }} /></div>  &nbsp;Campaigns</h6>
                //     <center><h3 className="bold-text">{this.state.campaignsCount}</h3></center>
                // </Card>
                // <Card className="articles-count neomorphic-shadows">
                //     <h6 style={{ display: "flex" }}> <div className="new-cir4"><img alt="" src={writearticle} style={{ width: "35px", marginTop: "8px", marginLeft: "8px" }} /></div>  &nbsp;Articles</h6>
                //     <center><h3 className="bold-text">{this.state.publishedPostsCount}</h3></center>
                // </Card>
                // <Card className="polls-count neomorphic-shadows-in">
                //     <h6 style={{ display: "flex" }}><div className="new-cir1"><img alt="" src={pollsparticipate} style={{ width: "24px", marginTop: "6px", marginLeft: "6px" }} /></div>  &nbsp;Polls</h6>
                //     <center><h3 className="bold-text">{this.state.pollsCount}</h3></center>
                // </Card>
                // <Card className="reach-count neomorphic-shadows">
                //     <h6 style={{ display: "flex" }}> <div className="new-cir2"><img alt="" src={followers} style={{ width: "30px", marginTop: "7px", marginLeft: "8px" }} /></div>  &nbsp;Users </h6>
                //     <center><h3 className="bold-text">{this.state.registeredUserCount}</h3></center>
                // </Card>
                // <img alt="" src="circle.png" class="circle-new" />
                // <img alt="" src="circle-yellow.png" class="circle-yellow-new" />
                // {/* <div class="circle-reverse-yellow-new" /> */}