import React, { Component } from "react";
import { Button, Form, Col, Card, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import back from '../images/backarrow.png';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const validEmailRegex =
    /^[a-z0-9+_.-]+@[a-z0-9.-]+$/

class InviteToGroup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            formValues: [{ name: "", email: "" }],
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            home: false,
            error: "Please select the details",
            emailError: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(i, e) {
        let formValues = this.state.formValues;
        formValues[i][e.target.name] = e.target.value;
        this.setState({ formValues });
    }

    addFormFields() {
        this.setState(({
            formValues: [...this.state.formValues, { name: "", email: "" }]
        }))
    }

    removeFormFields(i) {
        let formValues = this.state.formValues;
        formValues.splice(i, 1);
        this.setState({ formValues });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.sendInvitation();
    }
    sendInvitation = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        // var names = [this.state.name1, this.state.name2, this.state.name3, this.state.name4, this.state.name5]
        // var filteredNames = names.filter(function (el) {
        //     return el != "";
        // });
        // var email = [this.state.email1, this.state.email2, this.state.email3, this.state.email4, this.state.email5]
        // var filteredEmail = email.filter(function (el) {
        //     return el != "";
        // });

        var newName = [];
        newName = this.state.formValues.map(item => (item.name == "") ? "" : item.name)
        var filteredName = newName.filter(function (el) {
            return el != "";
        });

        var newEmail = [];
        newEmail = this.state.formValues.map(item => (item.email == "") ? "" : item.email)
        var filteredEmail = newEmail.filter(function (el) {
            return el != "";
        });
        var payload = {
            "name": filteredName,
            "email": filteredEmail
        }
        //console.log("payload", payload, (!filteredName.length), (!filteredEmail.length))
        var self = this
        if (!filteredName.length) {
           // console.log("Please enter the name")
            document.getElementById('status').innerHTML = "<div>Please enter the name</div>"
        } else if (!filteredEmail.length) {
          //  console.log("Please enter email")
            document.getElementById('status').innerHTML = "<div>Please enter the email</div>"
        } else {
            axios.post(config.userUrl + 'user/sendInviteLink', payload, { headers: headers })
                .then(function (response) {
                  //  console.log("response", response)
                    if (response.data.status === true) {
                     //   console.log(response, "Invitation sent!!")
                        self.setState({ home: true })
                    }
                })
                .catch(function (error) {
                    console.log("error", payload,error)
                });
        }
    }
    render() {
        const { home } = this.state
        if (home) {
            return <Redirect to="/home" />
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
                <Form onSubmit={this.handleSubmit} style={{ marginTop: "5rem" }}>
                    <Link to="/home"><img alt="" src={back} className="back_arrow_" /></Link><div className="screen-topgap your-fav ml-5"> <div className="ml-4">Invite People To Group</div></div>

                    <div className="form-group">
                        <div
                            dangerouslySetInnerHTML={{ __html: "Thanks for inviting your friend to this professional learning network. We all can make sceem a collaborative platform. You can invite 5 of your friends at a time." }}
                            readOnly
                            className="richTextFieldrefer"
                        />
                    </div>
                    {this.state.formValues.map((element, index) => (
                        <div style={{ marginLeft: "5%", marginRight: "5%", width: "90%" }}>
                            <Row style={{ width: "100%" }} key={index}>
                                <Col xs={6}>
                                    <input type="text"
                                        style={{ width: "118%" }}
                                        className="mt-3 form-control form-bg-opp"
                                        aria-describedby="text"
                                        placeholder="Enter Name"
                                        name="name" value={element.name || ""} onChange={e => this.handleChange(index, e)}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <input type="email"
                                        style={{ width: "118%" }}
                                        className="mt-3 ml-1 form-control form-bg-opp"
                                        aria-describedby="text"
                                        placeholder="Email"
                                        name="email" value={element.email || ""} onChange={e => this.handleChange(index, e)}
                                    />
                                </Col>
                            </Row>
                        </div>
                    ))}
                    {this.state.formValues.length <= 4 ?
                        <center><i class="fa fa-plus-circle fa-lg" onClick={() => this.addFormFields()} style={{ color: "#31197C" }} aria-hidden="true"></i></center> : null}
                    <center id="status" style={{ position: "absolute", left: "37%", bottom: "5rem" }} className="mt-2 errormsg"></center>
                    <center> {this.state.formValues.length < 1 ?
                        <Button variant="default" disabled={true} style={{ position: "absolute", left: "5%", bottom: "1rem" }} type="submit" className="btn-block opportunity-btn mt-4">Refer</Button> :
                        <Button variant="default" type="submit" style={{ position: "absolute", left: "5%", bottom: "1rem" }} className="btn-block opportunity-btn mt-4">Refer</Button>}<br />
                    </center>
                </Form>
            </div>
        );
    }
}
export default InviteToGroup;

// import React, { Component } from "react";
// import { Button, Form, Col, Card, Row, Image } from "react-bootstrap";
// import { Link, Redirect } from 'react-router-dom';
// import loginlogo from '../images/login-logo.png';
// import back from '../images/backarrow.png';
// import axios from 'axios';
// import { config } from '../Config/Config';
// import SimpleCrypto from "simple-crypto-js";
// var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
// var simpleCrypto = new SimpleCrypto(key)


// const validEmailRegex =
//     /^[a-z0-9+_.-]+@[a-z0-9.-]+$/
// export default class ReferAFriend extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
//             name1: "",
//             name2: "",
//             name3: "",
//             name4: "",
//             name5: "",
//             email1: "",
//             email2: "",
//             email3: "",
//             email4: "",
//             email5: "",
//             home: false,
//             error: "Please select the details",
//             emailError: ""
//         };
//         this.handleSubmit = this.handleSubmit.bind(this);
//         this.onChange = this.onChange.bind(this);
//         this.validateEmailAddress = this.validateEmailAddress.bind(this);
//         this.handleBlur = this.handleBlur.bind(this);
//         this.validateField = this.validateField.bind(this);
//     }
//     validateEmailAddress() {
//         let emailError = "";
//         const value = this.state.email1;
//         if (value.trim === "") emailError = "Email is required";
//         else if (!validEmailRegex.test(value))
//             emailError = "Please Enter Correct Email";
//         this.setState({
//             emailError
//         });
//         return emailError === "";
//     }
//     validateField(name) {
//         let isValid = false;
//         if (name === "email1") isValid = this.validateEmailAddress();
//         return isValid;
//     }
//     onChange(event) {
//         const { name, value } = event.target;
//         this.setState({
//             [name]: value
//         });
//         return;
//     }
//     handleBlur(event) {
//         const { name } = event.target;
//         this.validateField(name);
//         return;
//     }
//     handleSubmit(event) {
//         event.preventDefault();
//         console.log(this.state);
//         let formFileds = [
//             "email1"

//         ];
//         let isValid = true;
//         formFileds.forEach(field => {
//             isValid = this.validateField(field) && isValid;
//         });
//         if (!isValid) {
//             console.log("Please enter the details")
//             //document.getElementById('status').innerHTML = "<div>Please enter valid details</div>"
//         } else {
//             this.sendInvitation();
//         }
//     }
//     sendInvitation = (e) => {
//         let headers = {
//             "Content-Type": 'application/json',
//             "Authorization": "Bearer " + this.state.token,
//             "applicationType": "mobile"
//         }
//         var names = [this.state.name1, this.state.name2, this.state.name3, this.state.name4, this.state.name5]
//         var filteredNames = names.filter(function (el) {
//             return el != "";
//         });
//         var email = [this.state.email1, this.state.email2, this.state.email3, this.state.email4, this.state.email5]
//         var filteredEmail = email.filter(function (el) {
//             return el != "";
//         });
//         var payload = {
//             "name": filteredNames,
//             "email": filteredEmail
//         }
//         console.log("payload", payload)
//         var self = this
//         axios.post(config.userUrl + 'user/sendInviteLink', payload, { headers: headers })
//             .then(function (response) {
//                 console.log("response", response)
//                 if (response.data.status === true) {
//                     console.log(response, "Invitation sent!!")
//                     self.setState({ home: true })
//                 }
//             })
//             .catch(function (error) {
//                 console.log("payload", payload)
//                 console.log(error);
//             });
//     }
//     render() {
//         const { home } = this.state
//         console.log("home", home)
//         if (home) {
//             return <Redirect to="/home" />
//         }
//         return (
//             <div>
//                 <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
//                 <Form onSubmit={this.handleSubmit} style={{ marginTop: "5rem" }}>
//                     <Link to="/home"><img alt="" src={back} className="back_arrow_" /></Link><div className="your-fav ml-5"> <div className="ml-4">Refer A Friend</div></div>

//                     <div style={{ marginLeft: "5%", marginRight: "5%", width: "90%" }} >
//                         <Row style={{ width: "100%" }} className="mt-3">
//                             <Col xs={6}>
//                                 <div className="form-group">
//                                     <input type="text"
//                                         style={{ width: "118%" }}
//                                         className="form-control form-bg-opp"
//                                         aria-describedby="text"
//                                         value={this.state.name1}
//                                         name="name1"
//                                         placeholder="Enter Name"
//                                         onChange={this.onChange}
//                                     />
//                                 </div>
//                             </Col>
//                             <Col xs={6}>
//                                 <div className="form-group">
//                                     <input type="email"
//                                         style={{ width: "118%" }}
//                                         className="ml-1 form-control form-bg-opp"
//                                         aria-describedby="text"
//                                         value={this.state.email1}
//                                         name="email1"
//                                         placeholder="Email"
//                                         onChange={this.onChange}
//                                         onBlur={this.handleBlur}
//                                     />
//                                     {/* <div className="div">{this.state.emailError && (
//                                         <div className="error">{this.state.emailError}</div>
//                                     )}</div> */}
//                                 </div>
//                             </Col>
//                         </Row>
//                         <Row style={{ width: "100%" }} className="mt-3">
//                             <Col xs={6}>
//                                 <div className="form-group">
//                                     <input type="text"
//                                         style={{ width: "118%" }}
//                                         className="form-control form-bg-opp"
//                                         aria-describedby="text"
//                                         value={this.state.name2}
//                                         name="name2"
//                                         placeholder="Enter Name"
//                                         onChange={this.onChange}
//                                     />
//                                 </div>
//                             </Col>
//                             <Col xs={6}>
//                                 <div className="form-group">
//                                     <input type="email"
//                                         style={{ width: "118%" }}
//                                         className="ml-1 form-control form-bg-opp"
//                                         aria-describedby="text"
//                                         value={this.state.email2}
//                                         name="email2"
//                                         placeholder="Email"
//                                         onChange={this.onChange}
//                                     />
//                                 </div>
//                             </Col>
//                         </Row>
//                         <Row style={{ width: "100%" }} className="mt-3">
//                             <Col xs={6}>
//                                 <div className="form-group">
//                                     <input type="text"
//                                         style={{ width: "118%" }}
//                                         className="form-control form-bg-opp"
//                                         aria-describedby="text"
//                                         value={this.state.name3}
//                                         name="name3"
//                                         placeholder="Enter Name"
//                                         onChange={this.onChange}
//                                     />
//                                 </div>
//                             </Col>
//                             <Col xs={6}>
//                                 <div className="form-group">
//                                     <input type="email"
//                                         style={{ width: "118%" }}
//                                         className="ml-1 form-control form-bg-opp"
//                                         aria-describedby="text"
//                                         value={this.state.email3}
//                                         name="email3"
//                                         placeholder="Email"
//                                         onChange={this.onChange}
//                                     />
//                                 </div>
//                             </Col>
//                         </Row>
//                         <Row style={{ width: "100%" }} className="mt-3">
//                             <Col xs={6}>
//                                 <div className="form-group">
//                                     <input type="text"
//                                         style={{ width: "118%" }}
//                                         className="form-control form-bg-opp"
//                                         aria-describedby="text"
//                                         value={this.state.name4}
//                                         name="name4"
//                                         placeholder="Enter Name"
//                                         onChange={this.onChange}
//                                     />
//                                 </div>
//                             </Col>
//                             <Col xs={6}>
//                                 <div className="form-group">
//                                     <input type="email"
//                                         style={{ width: "118%" }}
//                                         className="ml-1 form-control form-bg-opp"
//                                         aria-describedby="text"
//                                         value={this.state.email4}
//                                         name="email4"
//                                         placeholder="Email"
//                                         onChange={this.onChange}
//                                     />
//                                 </div>
//                             </Col>
//                         </Row>
//                         <Row style={{ width: "100%" }} className="mt-3">
//                             <Col xs={6}>
//                                 <div className="form-group">
//                                     <input type="text"
//                                         style={{ width: "118%" }}
//                                         className="form-control form-bg-opp"
//                                         aria-describedby="text"
//                                         value={this.state.name5}
//                                         name="name5"
//                                         placeholder="Enter Name"
//                                         onChange={this.onChange}
//                                     />
//                                 </div>
//                             </Col>
//                             <Col xs={6}>
//                                 <div className="form-group">
//                                     <input type="email"
//                                         style={{ width: "118%" }}
//                                         className="ml-1 form-control form-bg-opp"
//                                         aria-describedby="text"
//                                         value={this.state.email5}
//                                         name="email5"
//                                         placeholder="Email"
//                                         onChange={this.onChange}
//                                     />
//                                 </div>
//                             </Col>
//                         </Row>
//                     </div >
//                     <center>
//                         <Button variant="default" type="submit" className="btn-block opportunity-btn mt-4">Refer</Button><br />
//                     </center>
//                 </Form >
//             </div >
//         );
//     }
// }
