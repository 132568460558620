import React from 'react'


function CircularText({ text, arc, radius }) {

    const characters = text.split('');
    const degree = arc / characters.length;

    return (
      
            <h4>
                {characters.map((char, i) => (
                    <span
                        key={`heading-span-${i}`}
                        style={{
                            height: `${radius}px`,
                            transform: `rotate(${degree * i - arc / 2}deg)`,
                            transformOrigin: `0 ${radius}px 0`,
                        }}>
                        {char}
                    </span>
                ))}
            </h4>
    );
}

export default CircularText


                // {console.log("u",radius * (1 + Math.cos(angle * (Math.PI / 100))))}
                // {console.log("b",radius * (1 + Math.sin(angle * (Math.PI / 100))))}
                // {characters.map((char, i) => (
                //     <span
                //         key={`heading-span-${i}`}
                //         style={{
                //                 // const xPos = radius * (1 + Math.cos(angle * (Math.PI / 100)));
                //                 // const yPos = radius * (1 + Math.sin(angle * (Math.PI / 100)));
                //            // transform: `translate(${radius * (1 + Math.cos(angle * (Math.PI / 100)))}px, ${radius * (1 + Math.sin(angle * (Math.PI / 100)))}px)`,
                            
                //             height: `${radius}px`,
                //             transform: `rotate(${(degree * i - arc / 2)}deg)`,
                //             transformOrigin: `0 0 0`,
                //         }}>


