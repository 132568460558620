// import React, { Component } from "react";
// import { Button, Navbar, Nav, Card, Container, Col, Row, Image } from "react-bootstrap";
// import { Link, Redirect } from 'react-router-dom';
// import loginlogo from '../images/login-logo.png';
// import you from '../images/you.PNG';
// import './Articles.css';
// import ArticlesJson from './Articles.json';
// import BottomMenu from "../BottomMenu/BottomMenu";
// import { HiOutlineShare } from "react-icons/hi";
// import { AiOutlineHeart } from "react-icons/ai";
// import { BiComment, BiBookmark } from "react-icons/bi";
// import TopMenu from "../TopMenu/TopMenu";

// export default class Articles extends Component {
//     constructor(props) {
//         super(props);

//     }
//     render() {
//         return (
//             <div>
//                 <TopMenu />
//                 <div className="article-top">
//                     {ArticlesJson.articles.slice(0, 15).map((data, id) => {
//                         return (

//                             <Link to={`/article`} className="link">
//                                 <Card className="article-card">
//                                     <Col xs={12}>
//                                         {/* <div><img src={data.authorImg} className="mt-4 auth-icon" /><span className="author-name"> {data.authorName}</span><span className="follow-author">Follow</span><br /><span>{data.pubDate}</span></div> */}
//                                         <Row style={{ width: "100%" }}>
//                                             <Col xs={3}><img src={data.authorImg} className="mt-4 auth-Icon" /></Col>
//                                             <Col xs={9}><div className="mt-4"><span className="mt-4 author-name"> {data.authorName}</span><span className="follow-author">Follow</span><br /><span className="pub-Date">{data.pubDate}</span></div></Col>
//                                         </Row>
//                                         <div className="heading-art">{data.heading}</div>
//                                         <center><img src={data.image} className="img_art" /></center>
//                                         <div className="color-grey"> <span><AiOutlineHeart size="20px" />{data.likes} Likes</span>
//                                             <span className="ml-2"><HiOutlineShare size="20px" /> Share</span>
//                                             <span className="ml-2"><BiBookmark size="20px" /> Bookmark</span>
//                                         </div>
//                                     </Col>

//                                 </Card><br />
//                             </Link>
//                         )
//                     })}
//                 </div>
//                 <BottomMenu />
//             </div >
//         )
//     }
// }

import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import './JitBits.css';
import article from '../images/article-new.jpeg';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default class JitBits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            isLoading: true
        }
        this.goBack = this.goBack.bind(this)
    }
    goBack = () => {
        window.history.back();
    }
    componentDidMount() {
        this.getJitBits();
    }
    getJitBits = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getJitBits', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    self.setState({ posts: response.data.posts, isLoading: false })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        const { posts, isLoading } = this.state;

        if (isLoading) {
         //   console.debug("renders: Loading...");
            return <div className="App"></div>;
        }
        return (
            <div >
                <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                <div className="screen-topgap">
                    <div className="your-jitbits">JitBits Here</div>
                    <div className="ml-3 mr-3 top-jit" >
                        {this.state.posts?.length !== 0 ?
                            <div>

                                {this.state.posts.map((data, i) => (
                                    <Link to={{ pathname: `/article/${data.title.replace(/ /g, "_")}/${data.id}` }} className="ml-2 mr-2 link">
                                        <Card style={{ display: "flex" }} className="jitbits_card">
                                            <Row style={{ width: "100%" }} className="no-gutters">
                                                <Col xs={4}>
                                                    <img src={config.userUrl + data.image} className="ml-2 mt-1 favbits-article-jit" />
                                                </Col>
                                                <Col xs={8}>
                                                    {/* {data.title !== null && data.title > data.title.substring(0, 55) ?
                                                        <div className="ml-2 favbits-title-new">{data.title.substring(0, 55)}...</div> :
                                                        <div className="ml-2 favbits-title-new">{data.title}</div>} */}
                                                    <div className="ml-1 titleText-favbits">{data.title}</div>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <div className="ml-1 jitbits-art-text"><b>{data.topicName.replace(/ *\([^]*\) */g, "")}</b> </div>
                                                    </div>
                                                    <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <div style={{marginTop:"-2px"}}><img alt="" src={config.userUrl + data.authorProfilePicture} className="ml-2 jitbits-img-Author-art" /><span className="ml-1 mt-2 jitbits-art-text">{data.authorName}</span></div>
                                                        <div className="mt-2 jitbits-publish">{data.publishOn.split("T")[0].split("-").reverse().join("-")}</div>
                                                    </div>

                                                    {/* <Row style={{ width: "100%" }} className="no-gutters">
                                                        <Col xs={7}>
                                                            <div><img alt="" src={config.userUrl + data.authorProfilePicture} className="ml-2 jitbits-img-Author" /><span className="ml-1 jitbits-art-text">{data.authorName}</span></div>
                                                        </Col>
                                                        <Col xs={5}>
                                                        </Col>
                                                    </Row> */}

                                                </Col>
                                            </Row>
                                            {/* <Row style={{ width: "100%" }}>
                                        <Col xs={6}>
                                          
                                        </Col>
                                        <Col xs={6}>
                                        </Col>
                                        <img alt="" src={data.topicName.toLowerCase().replace(/ /g, '') + ".png"} className="jitbits-img-topicIcon" />
                                    </Row> */}
                                        </Card>
                                    </Link>
                                ))}
                            </div> : <center className="mt-5">No Jitbits to display</center>}
                    </div>
                </div>
                <div className="mb-bottom"></div>
                <BottomMenu />
            </div >
        )
    }
}
{/* {this.state.posts.map((data, i) => (
                            <Link to={{ pathname: `/article/${data.id}` }} className="link">
                                <Card className="jitbits-card mr-2">
                                    <Card.Img variant="top" alt="" src={data.image} className="jitbits-article-img" />
                                    {data.title !== null && data.title > data.title.substring(0, 25) ?
                                        <Card.Title className="jitbits-article-heading">{data.title.substring(0, 25)}...</Card.Title>
                                        : <Card.Title className="jitbits-article-heading">{data.title}</Card.Title>}
                                    <div style={{ display: "flex" }}>
                                        <img alt="" src={data.authorProfilePicture} alt="" className="jitbits-img-Author" /> <div className="jitbits-text ml-2">{data.authorName}</div> <img alt="" src={data.topicName.toLowerCase().replace(/ /g, '') + ".png"} className="jitbits-img-topicIcon" />
                                    </div>
                                </Card>
                            </Link>
                        ))} */}
{/* {this.state.posts.map((data, i) => (
                            <Link to={{ pathname: `/article/${data.id}` }} className="ml-2 mr-2 link">
                                <Card style={{ display: "flex" }} className="jitbits_card">
                                    <Row style={{ width: "100%" }}>
                                        <Col xs={3}>
                                            <img alt="" src={data.image} className="ml-3 favbits-author-auth" />
                                        </Col>
                                        <Col xs={9}>
                                            {data.title !== null && data.title > data.title.substring(0, 35) ?
                                                <div className="favbits-title-new">{data.title.substring(0, 35)}...</div> :
                                                <div className="favbits-title-new">{data.title}</div>}
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col xs={6}>
                                            <img alt="" src={data.authorProfilePicture} className="jitbits-img-Author" />
                                        </Col>
                                        <Col xs={6}>
                                        </Col>
                                        <img alt="" src={data.topicName.toLowerCase().replace(/ /g, '') + ".png"} className="jitbits-img-topicIcon" />
                                    </Row>
                                </Card>
                            </Link>
                        ))} */}
