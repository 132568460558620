import React, { Component } from "react";
import { Button, Form, OverlayTrigger, Popover, Card, Image } from "react-bootstrap";
import loginlogo from '../images/login-logo.png';
import axios from 'axios';
import { config } from '../Config/Config';
import activecircle from '../images/active-cir.svg';
import needinfocircle from '../images/needinfo-cir.svg';
import rejectedcircle from '../images/rejected-empty-cir.svg';
import BottomMenu from "../BottomMenu/BottomMenu";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class ReferralStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            referral: [],
            overlay: false,
            isLoading: true

        }
    }

    componentDidMount() {
        this.getReferrals()
    }
    getReferrals = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getInvitationData', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status == true) {
                    self.setState({ referral: response.data.getInvitationData, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    overlayBox = (id) => {
      //  console.log("id")
        // this.setState({ overlay: true })
    }
    render() {
        const { isLoading, overlay, referral } = this.state;
        if (isLoading) {
            return <div className="App"></div>;
        }
        // const updatedDate = referral.map((i, idx) => {
        //     var a = new Date(i.updatedAt)
        //     var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        //     return updated;
        // })
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
                <div className="screen-topgap">
                    <div className="your-jitbits">Referrals</div>
                    <div className="ml-3 mr-3 mt-3 top-jit">
                        {referral.length === 0 ? <center><h4>No Referrals.</h4></center> :
                            <div>
                                {referral.map((data, i) => (
                                    // <Card style={{ display: "flex" }} className="referrals-card">
                                    //     <div className="ml-1 mt-3">&nbsp; <b>{data.invitationUserName}</b> ({data.email})
                                    //     <span style={{ float: "right", marginRight: "10px" }}> &nbsp;
                                    //     {data.invitationStatus == 2 ? <i class="fa fa-question fa-lg" style={{ color: "red" }} aria-hidden="true"></i>
                                    //      : (data.invitationStatus == 1 ? <i style={{ color: "green" }} class="fa fa-check fa-lg" aria-hidden="true"></i>
                                    //       : <i style={{ color: "red" }} class="fa fa-times fa-lg" aria-hidden="true"></i>)}</span></div>

                                    // </Card>
                                    <Card style={{ display: "flex" }} className="ref-status-card">
                                        <div>

                                            {(data.invitationUserName.length + data.email.length) > 30 ?
                                                <OverlayTrigger
                                                    placement="top"
                                                    trigger={overlay ?
                                                        ""
                                                        : "click"}

                                                    rootClose
                                                    overlay={(
                                                        <Popover
                                                            id="test">
                                                            <Popover.Content >
                                                                <div style={{marginTop:"-5px"}}><b>{data.email}</b></div>
                                                            </Popover.Content>
                                                        </Popover>
                                                    )}>
                                                    {overlay ?
                                                        "" :
                                                        <div>
                                                            <span style={{ float: "left", marginLeft: "1px" }}>
                                                                {data.invitationStatus == 2 ? <img src={needinfocircle} className="ref-circles" />
                                                                    : (data.invitationStatus == 1 ? <img src={activecircle} className="ref-circles" />
                                                                        : <img src={rejectedcircle} className="ref-circles" />)}</span><b className="ml-5">{data.invitationUserName}</b> ({data.email.substring(0, 21)}...)
                                                        </div>}
                                                </OverlayTrigger>
                                                :
                                                <div>
                                                    <span style={{ float: "left", marginLeft: "1px" }}>
                                                        {data.invitationStatus == 2 ? <img src={needinfocircle} className="ref-circles" />
                                                            : (data.invitationStatus == 1 ? <img src={activecircle} className="ref-circles" />
                                                                : <img src={rejectedcircle} className="ref-circles" />)}</span><b className="ml-5">{data.invitationUserName}</b> ({data.email})
                                                </div>}
                                        </div>
                                        {/* <span style={{ float: "left", marginLeft: "1px" }}>
                                                {data.invitationStatus == 2 ? <img src={pendingcircle} className="adv-circles" />
                                                    : (data.invitationStatus == 1 ? <img src={activecircle} className="adv-circles" />
                                                        : <img src={rejectedcircle} className="adv-circles" />)}</span><b className="ml-4"> &nbsp;&nbsp;&nbsp;&nbsp;{data.invitationUserName}</b> ({ data.email }) </div> */}
                                        {/* <div className="ml-3"><span style={{ width: "30%" }}><b>Email &nbsp;- </b></span>&nbsp; {data.email} </div>
                                    <div className="ml-3"><span style={{ width: "30%" }}><b>Status -</b></span>&nbsp; {data.invitationStatus == 2 ? "Pending" : (data.invitationStatus == 1 ? "Accepted" : "Already An User")}</div> */}
                                    </Card>
                                ))}
                            </div>
                        }
                    </div>
                </div >
                <div className="mb-bottom"></div>
                <BottomMenu />
            </div >

        );
    };
}