import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import './Poll.css';
import BottomMenu from "../BottomMenu/BottomMenu";
// import Poll from "./Poll.json";
// import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "../LandingPage/arrowsOnBottomOrTop.css";
import "../LandingPage/hideScrollbar.css";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class PollHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            polls: [],
            pollHistory: []
        }
    }
    componentDidMount() {
        this.getPolls();
        this.getHistoryPolls();
    }
    getPolls = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getPolls', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                    //console.log("success");
                    self.setState({
                        polls: response.data.polls
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });

    };
    getHistoryPolls = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getHistoryPolls', { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                  //  console.log("success");
                    self.setState({
                        pollHistory: response.data.pollHistory
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });

    };


    render() {
        const { polls, pollHistory } = this.state;
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <div style={{ marginTop: "5.3rem" }}>
                </div>
                <p className="poll-latest-news">Polls</p>
                {/* <div className="poll-main">
                    <ScrollMenu>
                        {Poll.poll.slice(0, 6).map((data, id) => {
                            return (
                                <div className="scroll-polls">
                                    <Link to={`/pollquestion/:id`}>
                                        <Col xs={5}><img alt="" src={data.image} className="poll-img" />
                                            <p className="poll-heading">{data.topic}</p></Col>
                                    </Link>
                                </div>
                            )
                        })}
                    </ScrollMenu>
                </div><br /> */}
                <div className="ml-4 mr-4">
                    {polls.map((data, id) => {
                        return (
                            <Card className="popular-card">
                                <Link to={`/pollquestion/${data.id}`} style={{ color: "black" }} className="link">
                                    <Row>
                                        <Col xs={2}>
                                            <img alt="" src={data.image} style={{ width: "30px" }} />
                                        </Col>
                                        <Col xs={6}>

                                            {data.question !== null && data.question > data.question.substring(0, 16) ?
                                                <div className="ques-size">{data.question.substring(0, 16)}...</div> :
                                                <div className="ques-size">{data.question}</div>}
                                        </Col>
                                        <Col xs={4}>
                                            <div className="ml-2 date-font-size">{data.date}</div>
                                        </Col>

                                    </Row>
                                </Link>
                            </Card>
                        )
                    })}
                </div>

                <p className="latest-news">Your Poll History</p>
                <div className="ml-4 mr-4">
                    {pollHistory.map((poll, i) => {
                        return (
                            <Link to={`/pollresults/${poll.id}`} style={{ color: "black" }} className="link">
                                <Card className="history-card">
                                    <span><div className="poll-text">{poll.question}</div></span>
                                    {/* <span> {poll.question !== null && poll.question > poll.question.substring(0, 26) ?
                                        <div className="poll-text">{poll.question.substring(0, 26)}...</div> :
                                        <div className="poll-text">{poll.question}</div>}</span> */}
                                </Card>
                            </Link>
                        )
                    })}
                </div><br /><br /><br />
                <BottomMenu />
            </div >
        )
    }
}
