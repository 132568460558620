import React, { useState } from "react";
import loginlogo from '../images/login-logo.png';
import back from '../images/backarrow.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import './Terms.css';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default function DataPrivacy({ showPrivacy, displayPrivacy }) {

    const [token, setToken] = useState(localStorage.getItem('junkStore'))
    const handleBack = () => {
        if (displayPrivacy) {
            displayPrivacy()
        } else {
            window.location.href = "/signup"
        }
    }

    return (
        <div>
            <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
            {token ? null :
                <img alt="" src={back} onClick={handleBack} className="back_arrow" />}
            <p className={token ? "terms-heading1" : "terms-heading"}>Data Privacy</p>
            <div className="ml-4 mr-2">
                <b className="heading">Your Information is <span style={{ color: "#31197C" }}>Safe</span></b>
                <p className="terms_Text">We at Nebutech Analytics LLP (hereinafter mentioned as "NEBUTECH") are committed to protect our customer's personal information and/or sensitive personal data
                    and strive to maintain the privacy of your personal informtion.For your information, "Personal information" is any information that can be used by itself to
                    uniquely identify,contact,or locate a person,or can be used with information available from other sources to uniquely identify an individual. For the purpose of
                    this policy, sensitive personal data or information has been considered as apart of personal information.</p>
                <p className="terms_Text">NEBUTECH's Privacy Policy is designed and developed to address the privacy and security of your personal information provided to us.
                    This Privacy Policy describes the personal information which we may collect and provides our approach towards handling or
                    dealing with the same.The policy is designed to make you understand:</p>
                <p><span className="circle-dp"></span>kinds of personal information that may be collected by us</p>
                <p><span className="circle-dp"></span>measures taken by us to ensure its privacy and security</p>
                <p><span className="circle-dp"></span>duration for which it may be retained by us; and</p>
                <p><span className="circle-dp"></span>how you may access and control its use</p>
                <b className="heading mt-2">Collection of <span style={{ color: "#31197C" }}>Personal Information</span></b>
                <p className="terms_Text">NEBUTECH and its authorized third parties will connect information pertaining to your identity, demographics, and related evidentiary
                    documentation.For the purpose of this document, a 'Third Party' is a service provider who associates with NEBUTECH and is involved in handling, managing,
                    storing, processing, protecting and transmitting information of NEBUTECH.
                    This definition also includes all sub-contractors, consultants and/or representatives of the Third party.
                    We may also collect your personal information when you use our service or websites or otherwise interact with us during the
                    course of our relationship.
                    Personal information collected and held by us may include tour name, date of birth, current addresses, mobilephone number,
                    email address, occupation and information contained for any payments if required. NEBUTECH and its authorized third parties collect, store,
                    process following types of Sensitive Personal Information such as password, financial information ( details of Bank account, credit card,
                    debit card, or other payment instrument details), physiological information for providing our products, services and for use of our website.
                    We may also hold information related to your utilization of our services which may include your browsing history on our website, location details
                    and additional information provided by you while using our services.
                    We may keep a log of the activities performed by you on our network and websites by using various internet techniques such as web cookies,
                    web beacons, server log files, etc. for analytical purposes and for analysis of the amiability of various features on our site.This Information
                    may be used to provide you with a better experience at our portal along with evidentiary purposes. At any time while you are surfing our site,
                    if you do not wish to share surfing information, you may opt out of receiving the cookies from our site by making appropriate changes to your
                    browser privacy settings.
                    In case you do not provide your information or consent for usuage of personal information or later on withdraw your consent for usuage of the
                    personal information so collected, NEBUTECH reserves the right to not provide the services or to withdraw the services for which the said
                    information was sought.</p>
                <b className="heading" style={{ color: "#31197C" }}>Disclosure and Transfer <span style={{ color: "black" }}>of Personal Information</span></b>
                <p className="terms_Text">Internal Use: As explained in the section “Collection of Personal Information” NEBUTECH and its employees may utilize some or all available
                    personal information for intern, marginTop: "5px"al assessments, measures, operations and related activities.
                    Authorized Third Parties: NEBUTECH may at its discretion employ, contract or include third parties (as defined in section 1: Collection of
                    personal information) external to itself for strategic, tactical and operational purposes. Such agencies though external to NEBUTECH, will
                    always be entities which are covered by contractual agreements. These agreements in turn include NEBUTECH’s guidelines to the management,
                    treatment and secrecy of personal information.
                    We may transfer your personal information or other information collected, stored, processed by us to any other entity or organization located
                    in India or outside India only in case it is necessary for providing services to you or if you have consented
                    (at the time of collection of information) to the same. This may also include sharing of aggregated information with them in order for them
                    to understand our environment and consequently, provide you with better services. While sharing your personal information with third parties,
                    adequate measures shall be taken to ensure that reasonable security practices are followed at the third party.
                    We may obtain your consent for sharing your personal information in several ways, such as in writing, online, through “click-through”
                    agreements; orally, including through interactive voice response; or when your consent is part of the terms and conditions pursuant to
                    which we provide you service. We, however assure you that NEBUTECH does not disclose your personal information to unaffiliated
                    third parties (parties outside NEBUTECH corporate network and its Strategic and Business Partners) which could lead to invasion of your privacy.
                    Government Agencies: We may also share your personal information with Government agencies
                    or other authorized law enforcement agencies (LEAs) mandated under law to obtain such information for the purpose of verification of
                    identity or for prevention, detection, investigation including but not limited to cyber incidents, prosecution, and punishment of offences.</p>
                <b className="heading">Security <span style={{ color: "#31197C" }}>Practices and Procedures</span></b>
                <p className="terms_Text">We adopt reasonable security practices and procedures, in line with international standard, to include, technical, operational, managerial
                    and physical security controls in order to protect your personal information from unauthorized access, or disclosure while it is under our control.
                    Our security practices and procedures limit access to personal information on need-only basis. Further, our employees are bound by
                    Code of Conduct and Confidentiality Policies which obligate them to protect the confidentiality of personal information.
                    We take adequate steps to ensure that our third parties adopt reasonable level of security practices and procedures to ensure security of
                    personal information.
                    We may retain your personal information for as long as required to provide you with services or if otherwise required under any law.
                    When we dispose of your personal information, we use reasonable procedures to erase it or render it unreadable (for example, shredding
                    documents and wiping electronic media).
                    Internet Use - We maintain the security of our internet connections, however for reasons outside of our control, security risks may still arise.
                    Any personal information transmitted to us or from our online products or services will therefore be your own risk. However, we will strive to
                    ensure the security of your information. We observe reasonable security measures to protect your personal information against hacking
                    and virus dissemination.</p>
                <b className="heading">Update of <span style={{ color: "#31197C" }}>Personal Information</span></b>
                <p className="terms_Text">We strive to keep our records updated with your latest information. To this end, if you see any discrepancy in your personal information or
                    if a part of your personal information changes, we request you to write to us at support@nebutech.in and communicate the change(s)
                    for updating our records.
                    In case of non-compliance with the terms and conditions and privacy policy, NEBUTECH reserves the right to remove your non-compliant
                    information from its systems. Further, depending on the seriousness of the non-compliance, we may choose to discontinue some or all of
                    the services being provided to you by us.</p>
                <b className="heading">Feedback and <span style={{ color: "#31197C" }}>Concerns</span></b>
                <p className="terms_Text">We are committed to safeguard your personal information collected and handled by us and look forward to your continued support for the
                    same. In case of any feedback or concern regarding protection of your personal information, you can contact us at support@nebutech.in.
                    We will strive to address your feedback and concerns in a timely and effective manner.
                    NEBUTECH reserves the right to amend or modify this Privacy Policy at any time, as and when the need arises. We request you to visit our
                    website http://nebutech.company.in/ periodically for contemporary information and changes.</p>
            </div>
            <br /><br /><br />

            {token ? <BottomMenu /> : null}
        </div >
    )
}
