import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Modal, Carousel } from "react-bootstrap";
import { Link, useLocation, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import { enqueueSnackbar } from 'notistack'
import moment from 'moment-timezone';
import smemorning from '../images/smemorning.svg';
import smeafternoon from '../images/smeafternoon.svg';
import smebookings from '../images/smebookings1.svg';
import linkedinImg from '../images/linkedinblack.svg';
import hyphen from '../images/hyphen.svg';
import back from '../images/backarrow.png';
import curve from '../images/curve1.svg';
import smerating from '../images/smerating.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SimpleCrypto from "simple-crypto-js";
import { AiOutlineShareAlt } from "react-icons/ai";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


// export default function SMEProfile({ location, userId, selectedPlanId, id, sDate, sMonth, userDate, day, userTimeSlot }) {
// export default function SMEProfile({ location}) {
const SMEProfile = React.memo(function SMEProfile({ location }) {
    const [desc, setDesc] = useState('')
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
    const [allDays, setAllDays] = useState([]);
    const [allTimeSlots, setAllTimeSlots] = useState([]);
    const [allOfferings, setAllOfferings] = useState([]);
    const [daysToInclude, setDaysToInclude] = useState([]);
    const [selectedOfferings, setSelectedOfferings] = useState([]);
    const [bookings, setBookings] = useState();
    const [rating, setRating] = useState();
    const [uniqueCode, setUniqueCode] = useState('');
    const [profilePicture, setProfilePicture] = useState();
    const [name, setName] = useState();
    const [smeUserId, setSmeUserId] = useState();
    const [showConnect, setShowConnect] = useState(false)
    const [showHome, setShowHome] = useState(false)
    const [formatedNewDate, setFormattedNewDate] = useState('')
    const [userSlots, setUserSlots] = useState([]);
    const [topics, setTopics] = useState([])
    const [linkedin, setLinkedin] = useState()
    const [currDate, setCurrDate] = useState()
    const [selectedId, setSelectedId] = useState()
    const [loggedinUserId, setLoggedinUserId] = useState()
    const [showPopup, setShowPopup] = useState(false)
    const today = new Date();
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const [googleLink, setGoogleLink] = useState()
    const [agenda, setAgenda] = useState()
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState();
    const [userSelectedDate, setUserSelectedDate] = useState();
    const { userId, selectedOfferingId, selectedPlanId, id, selectedTopic, day, userDate, sDate, sMonth, smeAmount, userTimeSlot } = location.state;
    const [redirect, setRedirect] = useState(false);
    const [descriptionText, setDescriptionText] = useState('');
    const [commercial, setCommercial] = useState();
    const [showPaymentPopup, setShowPaymentPopup] = useState(false);

    const handleBack = () => {
        setRedirect(true);
        //  console.log("profile back redirect true", redirect, "id", id, "selectedDate", selectedDate, "userDate", userDate, "userTimeSlot", userTimeSlot, "selectedPlanId", selectedPlanId, "day", day)

    };
    const handleConnect = (date, plan) => {
        setShowConnect(true)
        setSelectedDate(new Date(date))
        setFormattedNewDate(moment(date).format("DD-MMM-YYYY"));
        setUserSelectedDate(new Date(date))
        //console.log("date",new Date(date))
        // if (plan !== 1) {
        //     setShowPaymentPopup(true)
        // } else {
        //     setShowConnect(true)
        // }
    }
    const bookASlot = (date) => {
        setShowConnect(true)
        setSelectedDate(new Date(date))
        setFormattedNewDate(moment(date).format("DD-MMM-YYYY"));
        setUserSelectedDate(new Date(date))
    }
    const handleFalseShowConnect = (date) => {
        setShowConnect(false)
        setShowPaymentPopup(false)
        setSelectedDate(new Date(date))
        // console.log("false", new Date(date))
        setFormattedNewDate(moment(date).format("DD-MMM-YYYY"));
        setUserSelectedDate(new Date(date))
    }
    const handleDateChange = date => {
        // console.log("asdfg", userDate, date)
        setSelectedDate(date);
        setUserSelectedDate(new Date(date))
        // console.log("date", date);
        if (date) {
            const formattedDate = formatDate(date);
            // console.log(formattedDate);
            setFormattedNewDate(moment(date).format("DD-MMM-YYYY"));
        } else {
            setFormattedNewDate(null);
        }
    };

    const formatDate = date => {
        if (!date || !(date instanceof Date)) {
            return ""; // Handle null or invalid date
        }
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };

    const handleClick = (slot) => {
        if (selectedDate) {
            setShowPopup(true)
            setSelectedSlot(slot);
        } else {
            enqueueSnackbar('Please select date', { variant: "error" })
            setShowPopup(false)
        }

    };

    useEffect(() => {
        const formattedDate = `${today.getDate()}-${months[today.getMonth()]}-${today.getFullYear()}`;
        setCurrDate(formattedDate)
        getSmeUserData();
        getAllSmeOfferings();
        getAllDays();
        getAllTimeSlots();
        function base64UrlDecode(base64Url) {
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const padding = '='.repeat((4 - (base64.length % 4)) % 4);
            base64 += padding;
            const decoded = atob(base64);
            return decodeURIComponent(escape(decoded));
        }

        function decodeJwt(token) {
            const [header, payload] = token.split('.');
            const decodedPayload = base64UrlDecode(payload);
            const payloadObject = JSON.parse(decodedPayload);
            return payloadObject;
        }

        const decodedToken = decodeJwt(token);
        setLoggedinUserId(decodedToken.userInfo.userId);
        // console.log("userId", userId, location?.state?.userDate, location?.state)
        setUserSelectedDate(new Date(userDate ? userDate : selectedDate))
        setSelectedDate(new Date(userDate ? userDate : selectedDate))
        const currentYear = new Date().getFullYear();
        const newDate = new Date(currentYear, parseInt(location?.state?.sMonth) - 1, parseInt(location?.state?.sDate));
        // const newDate = new Date(currentYear, parseInt(sMonth) - 1, parseInt(sDate));
        const fDate = moment(newDate).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
        const timezoneName = 'India Standard Time';

        const changedDate = fDate + ' (' + timezoneName + ')';
        setSelectedDate(new Date(changedDate))
        setUserSelectedDate(new Date(changedDate))
        setFormattedNewDate(moment(userDate ? userDate : changedDate).format("DD-MMM-YYYY"));
        //console.log("changed", changedDate)

        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = '';
        }
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    }, [])
    const getSmeBookings = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "smeUserId": id
        }
        axios.post(config.userUrl + 'user/getCombinedBookings', payload, { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    setUserSlots(response.data.filteredData)
                    // setLoggedinUserId(response.data.userId)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getText = (offeringId) => {
        const item = allOfferings.find(txt => txt.id === offeringId);
        return item ? item.text : "";
    }
    const getSmeUserData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "userId": userId
        }
        axios.post(config.userUrl + 'user/getSmeUserData', payload, { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    setDesc(response.data.data[0]?.desc)
                    setSelectedDay(response.data.data[0]?.day)
                    if (location.state?.userTimeSlot) {
                        setSelectedTimeSlot(location.state?.userTimeSlot)
                    } else {
                        setSelectedTimeSlot(response.data.data[0]?.time)
                    }
                    const days = response.data.data[0]?.day.map(day => day === 7 ? 0 : day);
                    setDaysToInclude(days)
                    setTopics(response.data.topics)
                    setSelectedOfferings(response.data.data[0].offering)
                    setLinkedin(response.data.data[0].linkedin)
                    setDescriptionText(response.data.data[0].description)
                    setRating(response.data.data[0].avg_assessment)
                    setSmeUserId(response.data.data[0].userId)
                    setProfilePicture(response.data.data[0].profilePicture)
                    setCommercial(response.data.data[0].commercial)
                    setName(response.data.data[0].name)
                    setBookings(response.data.data[0].bookings)
                    setUniqueCode(response.data.data[0].uniquecode)
                    getSmeBookings(response.data.data[0].userId);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    setAllOfferings(response.data.offerings)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllDays = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllDays', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    setAllDays(response.data.days)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllTimeSlots = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllTimeSlots', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    setAllTimeSlots(response.data.timeSlots)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const addSmeBooking = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "offeringId": id,
            "bookingDate": moment(selectedDate).format("YYYY-MM-DD"),
            "link": googleLink,
            "agenda": agenda,
            "topicId": selectedTopic,
            "smeUserId": smeUserId,
            "day": parseInt(selectedDay),
            "time": selectedSlot,
            "amount": smeAmount
        }
        //  console.log("payload", payload)

        axios.post(config.userUrl + 'user/addSmeBooking', payload, { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    console.log("success")
                    setShowPopup(false)
                    setShowHome(true)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const handleShowLinkPopup = (id) => {
        setSelectedId(id)
        setShowPopup(true)
    }
    const formatRatingNumber = (number) => {
        const roundedNumber = Math.round(number * 100) / 100; // Round to two decimal places
        const decimalPart = roundedNumber - Math.floor(roundedNumber);

        if (decimalPart === 0) {
            return Math.floor(roundedNumber); // Remove decimal part if it's zero
        } else {
            return roundedNumber.toFixed(1); // Keep two decimal places otherwise
        }
    }

    const onClickUrl = () => {
        window.open(linkedin)
    }
    // const shareSmeProfile = () => {
    //     window.open(config.sceemLpUrl + `sme/${smeUserId}`)
    // }
    const shareSmeProfile = () => {
        // var id = simpleCrypto.encrypt(userId);
        if (navigator.share) {
            navigator.share({
                url: config.sceemLpUrl + `profile/${uniqueCode}`,
            }).then(() => {
                //console.log('Thanks for sharing!');
            }).catch(err => {
                //console.log("Error while using Web share API:");
                console.log(err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
    };
    const renderTimeSlots = () => {
        const amSlots = [];
        const pmSlots = [];
        const interval = 30;
        let startHour, endHour;

        if (selectedTimeSlot === 1 || selectedTimeSlot === 2 || selectedTimeSlot === 3) {
            startHour = 8;
            endHour = 18;
        } else {
            return null;
        }
        for (let hour = startHour; hour < endHour; hour++) {
            for (let minute = 0; minute < 60; minute += interval) {
                const amPm = hour >= 12 ? 'PM' : 'AM';
                const formattedHour = (hour % 12 || 12).toString().padStart(2, '0'); // Add leading zeros
                const formattedMinute = minute.toString().padStart(2, '0'); // Add leading zeros
                const time = `${formattedHour}:${formattedMinute} ${amPm}`;

                if (amPm === 'AM') {
                    amSlots.push(time);
                } else {
                    pmSlots.push(time);
                }
            }
        }

        const filteredSlots = userSlots
            ?.filter(slot => moment(slot.bookingDate).format('DD-MM-YYYY').includes(moment(selectedDate).format('DD-MM-YYYY'))) // Filter by date
            .map(slot => slot.time);
        const isSlotDisabled = (slot) => {
            if (
                (selectedTimeSlot === 2 && (slot.includes('PM') || slot === '01:00 PM' || slot === '01:30 PM')) ||
                (selectedTimeSlot === 3 && (slot.includes('AM') || slot === '01:00 PM' || slot === '01:30 PM')) ||
                (selectedTimeSlot === 1 && (slot === '01:00 PM' || slot === '01:30 PM'))
            ) {
                return true;
            }
            return false;
        };

        // const renderedAmSlots = amSlots.map((slot, index) => {
        //     const slotIsBooked = filteredSlots?.includes(slot);
        //     const slotStatus = userSlots.find(userSlot => userSlot.time === slot)?.status || 0;
        //     const backgroundColor = slotIsBooked ?
        //         (slotStatus === 1 ? '#BFE6EB' : (slotStatus === 3 ? '#f9e1d5' : '#d7f1cf')) :
        //         (selectedSlot === slot ? '#d7f1cf' : (isSlotDisabled(slot) ? 'lightgray' : '#d7f1cf'));

        //     return (
        //         <div
        //             key={index}
        //             onClick={(slotIsBooked && slotStatus === 1) || isSlotDisabled(slot) ? null : () => handleClick(slot)}
        //             style={{
        //                 cursor: slotIsBooked || isSlotDisabled(slot) ? 'default' : 'pointer',
        //                 borderRadius: '10px',
        //                 padding: '2px 6px 6px 6px',
        //                 boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
        //                 margin: '4px auto',
        //                 width: 'calc(25% - 16px)',
        //                 textAlign: 'center',
        //                 fontSize: '16px',
        //                 fontFamily: 'Noto Sans',
        //                 position: 'relative',
        //                 display: 'flex',
        //                 justifyContent: 'center',
        //                 alignItems: 'center',
        //             }}
        //             className="ml-1 sme-slot-item"
        //         >
        //             <div
        //                 style={{
        //                     backgroundColor: 'red',
        //                     borderRadius: '10px',
        //                     width: '100%',
        //                     height: '100%',
        //                     display: 'flex',
        //                     justifyContent: 'center',
        //                     alignItems: 'center'
        //                 }}
        //             >
        //                 <div
        //                     style={{
        //                         backgroundColor: backgroundColor,
        //                         borderRadius: '10px',
        //                     }}
        //                 >
        //                     {slot}
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // });
        const renderedAmSlots = amSlots.map((slot, index) => {
            // const slotIsBooked = filteredSlots?.includes(slot);
            // const userSlot = userSlots.find(userSlot => userSlot.time === slot);
            // const isUserSlot = loggedinUserId === userSlot?.userId && userSlot?.time === slot
            // const anySlotStatusOne = userSlots.some(userSlot => userSlot.time === slot && userSlot.status === 1);
            const slotIsBooked = filteredSlots?.includes(slot);
            const userSlot = userSlots.find(userSlot => moment(userSlot.bookingDate).format('DD-MM-YYYY') === moment(selectedDate).format('DD-MM-YYYY') && userSlot.time === slot);
            const isUserSlot = loggedinUserId === userSlot?.userId && userSlot?.time === slot;
            const anySlotStatusOne = userSlots.some(userSlot => moment(userSlot.bookingDate).format('DD-MM-YYYY') === moment(selectedDate).format('DD-MM-YYYY') && userSlot.time === slot && userSlot.status === 1);


            let backgroundColor;
            //  console.log("status", userSlot?.status);
            // Priority check: any user's slot with status 1 is blue
            if (anySlotStatusOne) {
                backgroundColor = '#BFE6EB'; // blue for status 1
            } else if (slotIsBooked) {
                if (isUserSlot && userSlot?.status === 3) {
                    backgroundColor = '#f9fcb7'; // light yellow for user's slot with status 3
                } else {
                    backgroundColor = userSlot?.status === 3 ? '#f9e1d5' : '#d7f1cf'; // light orange for others with status 3, green for others
                }
            } else {
                // Non-booked slots
                backgroundColor = selectedSlot === slot ? '#d7f1cf' : (isSlotDisabled(slot) ? 'lightgray' : '#d7f1cf');
            }

            return (
                <div
                    key={index}
                    onClick={(slotIsBooked && anySlotStatusOne) || isSlotDisabled(slot) ? null : () => handleClick(slot)}
                    style={{
                        cursor: slotIsBooked || isSlotDisabled(slot) ? 'default' : 'pointer',
                        borderRadius: '10px',
                        padding: '2px 6px 6px 6px',
                        boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
                        margin: '4px auto',
                        width: 'calc(25% - 16px)',
                        textAlign: 'center',
                        fontSize: '16px',
                        fontFamily: 'Noto Sans',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    className="ml-1 sme-slot-item"
                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: backgroundColor,
                                borderRadius: '10px',
                            }}
                        >
                            {slot}
                        </div>
                    </div>
                </div>
            );
        });
        const pmSlotsWithout5 = pmSlots.filter(slot => slot !== '01:00 PM' && slot !== '01:30 PM');
        const renderedPmSlots = pmSlots.map((slot, index) => {
            const slotIsBooked = filteredSlots?.includes(slot);
            const userSlot = userSlots.find(userSlot => moment(userSlot.bookingDate).format('DD-MM-YYYY') === moment(selectedDate).format('DD-MM-YYYY') && userSlot.time === slot);
            const isUserSlot = loggedinUserId === userSlot?.userId && userSlot?.time === slot;
            const anySlotStatusOne = userSlots.some(userSlot => moment(userSlot.bookingDate).format('DD-MM-YYYY') === moment(selectedDate).format('DD-MM-YYYY') && userSlot.time === slot && userSlot.status === 1);

            let backgroundColor;
            // console.log("status", userSlot?.status);
            // Priority check: any user's slot with status 1 is blue
            if (anySlotStatusOne) {
                backgroundColor = '#BFE6EB'; // blue for status 1
            } else if (slotIsBooked) {
                if (isUserSlot && userSlot?.status === 3) {
                    backgroundColor = '#f9fcb7'; // light yellow for user's slot with status 3
                } else {
                    backgroundColor = userSlot?.status === 3 ? '#f9e1d5' : '#d7f1cf'; // light orange for others with status 3, green for others
                }
            } else {
                // Non-booked slots
                backgroundColor = selectedSlot === slot ? '#d7f1cf' : (isSlotDisabled(slot) ? 'lightgray' : '#d7f1cf');
            }

            return (
                <div
                    key={index}
                    onClick={(slotIsBooked && anySlotStatusOne) || isSlotDisabled(slot) ? null : () => handleClick(slot)}
                    style={{
                        cursor: slotIsBooked || isSlotDisabled(slot) ? 'default' : 'pointer',
                        borderRadius: '10px',
                        padding: '2px 6px 6px 6px',
                        boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
                        margin: '4px auto',
                        textAlign: 'center',
                        fontSize: '16px',
                        width: 'calc(25% - 16px)',
                        fontFamily: 'Noto Sans',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    className="ml-1 sme-slot-item"
                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: backgroundColor,
                                borderRadius: '10px',
                            }}
                        >
                            {slot}
                        </div>
                    </div>
                </div>
            );
        });
        // const renderedPmSlots = pmSlots.map((slot, index) => {
        //     const slotIsBooked = filteredSlots?.includes(slot);
        //     const slotStatus = userSlots.find(userSlot => userSlot.time === slot)?.status || 0;
        //     const backgroundColor = slotIsBooked ?
        //         (slotStatus === 1 ? '#BFE6EB' : (slotStatus === 3 ? '#f9e1d5' : '#d7f1cf')) :
        //         (selectedSlot === slot ? '#d7f1cf' : (isSlotDisabled(slot) ? 'lightgray' : '#d7f1cf'));

        //     return (
        //         <div
        //             key={index}
        //             onClick={(slotIsBooked && slotStatus === 1) || isSlotDisabled(slot) ? null : () => handleClick(slot)}
        //             style={{
        //                 cursor: slotIsBooked || isSlotDisabled(slot) ? 'default' : 'pointer',
        //                 borderRadius: '10px',
        //                 padding: '2px 6px 6px 6px',
        //                 boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
        //                 margin: '4px auto',
        //                 textAlign: 'center',
        //                 fontSize: '16px',
        //                 width: 'calc(25% - 16px)',
        //                 fontFamily: 'Noto Sans',
        //                 position: 'relative',
        //                 display: 'flex',
        //                 justifyContent: 'center',
        //                 alignItems: 'center',
        //             }}
        //             className="ml-1 sme-slot-item"
        //         >
        //             <div
        //                 style={{
        //                     backgroundColor: 'white',
        //                     borderRadius: '10px',
        //                     width: '100%',
        //                     height: '100%',
        //                     display: 'flex',
        //                     justifyContent: 'center',
        //                     alignItems: 'center',
        //                 }}
        //             >
        //                 <div
        //                     style={{
        //                         backgroundColor: backgroundColor,
        //                         borderRadius: '10px',
        //                     }}
        //                 >
        //                     {slot}
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // });
        return (
            <div>
                <div className="sme-booked-border-abc-B">
                    <div className="flex">
                        <div><img src={smemorning} className="mt-1 ml-2" /></div>
                        <div style={{ fontFamily: "Noto Sans", fontSize: "14px", fontWeight: 600, marginTop: "12px" }} className="ml-2"><img src={hyphen} style={{ width: "30px" }} />First Half</div>
                    </div>
                    <div className="sme-slot-container">
                        {renderedAmSlots}
                    </div>

                    <div className="flex">
                        <div><img src={smeafternoon} className="mt-1 ml-2" /></div>
                        <div style={{ fontFamily: "Noto Sans", fontSize: "14px", fontWeight: 600, marginTop: "12px" }} className="ml-2"><img src={hyphen} style={{ width: "30px" }} />Second Half</div>
                    </div>
                    <div className="sme-slot-container">
                        {renderedPmSlots}
                    </div>
                </div>
                <div className="mt-2" style={{ display: "flex", flexWrap: "wrap", gap: "0.3rem", padding: "2px 10px" }}>
                    <div className="status-indicator-container">
                        <div className="status-indicator" style={{ background: "lightgray" }}></div>
                        <span>Not Available</span>
                    </div>
                    <div className="status-indicator-container">
                        <div className="status-indicator" style={{ background: "#d7f1cf" }}></div>
                        <span>Available</span>
                    </div>
                    <div className="status-indicator-container">
                        <div className="status-indicator" style={{ background: "#BFE6EB" }}></div>
                        <span>Confirmed</span>
                    </div>
                    <div className="status-indicator-container">
                        <div className="status-indicator" style={{ background: "#F9D5D5" }}></div>
                        <span>Other's Reservation</span>
                    </div>
                    <div className="status-indicator-container">
                        <div className="status-indicator" style={{ background: "#f9fcb7" }}></div>
                        <span>Your Reservation</span>
                    </div>
                </div>
            </div>
        );
    };

    if (showHome) {
        return <Redirect to={{
            pathname: '/smeplanned',
            state: {
                selectedDate
            },
        }}
        />
    }
    if (redirect && id && selectedTopic && selectedDate && userTimeSlot && selectedPlanId && day) {
        const currentYear = new Date().getFullYear();
        const newDate = new Date(currentYear, parseInt(location?.state?.sMonth) - 1, parseInt(location?.state?.sDate));
        const fDate = moment(newDate).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
        const timezoneName = 'India Standard Time';

        const changedDate = fDate + ' (' + timezoneName + ')';

        // console.log("true", changedDate, userDate, selectedDate, userDate ? new Date(userDate) : (selectedDate ? new Date(selectedDate) : location?.state?.selectedDate ? location?.state?.selectedDate : new Date(changedDate)))
        // console.log("false", userDate, selectedDate, location?.state?.selectedDate, changedDate)
        // console.log("truefalse", userDate ? userDate : (selectedDate) ? selectedDate : (location?.state?.selectedDate) ? location?.state?.selectedDate : changedDate)
        // console.log("abc", userDate || selectedDate || location?.state?.selectedDate || changedDate);
        return (
            <Redirect
                to={{
                    pathname: '/smeconnect',
                    state: {
                        id,
                        selectedDate: new Date(changedDate), // Extract date from userDate string
                        selectedMonth: new Date(changedDate).getMonth() + 1, // Extract month from userDate string
                        selectedTimeSlot: userTimeSlot,
                        selectedTopic,
                        showDescScreen: false,
                        selectedPlanId: selectedPlanId,
                        day: day,
                        userDate: new Date(changedDate),
                        sDate: moment(new Date(changedDate)).format("DD"),
                        sMonth: moment(new Date(changedDate)).format("MM"),
                    },
                }}
            />
        )
    }
    return (
        <div>
            {!showConnect ?
                <div style={{ height: "96.5vh", overflow: "hidden", display: "flex", flexDirection: "column" }}>
                    <img src={curve} style={{ width: "100%", position: "absolute", top: "4.5rem", left: 0 }} />
                    <img src={back} alt="Back" className="back_arrow-sme-profile" onClick={handleBack} />

                    <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                    <div className="mt-5" style={{ flex: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
                        <div style={{ marginLeft: "1rem", marginRight: "1rem", flexShrink: 0 }}>
                            <Row className="no-gutters" style={{ width: "100%" }}>
                                <Col xs={4}>
                                    <img alt="" src={config.userUrl + profilePicture} style={{ marginLeft: "1.7rem", marginTop: "0.5rem", width: "85px" }} />
                                </Col>
                                <Col xs={1}></Col>
                                {/* <Col xs={4}>
                                    <Carousel indicators={false} slide={false} controls={false}>
                                        {topics.map((data, i) => (
                                            <Carousel.Item key={i} variant="dark" interval={2000}>
                                                <Card className="offerings-card-smeprofile_">
                                                    <center>
                                                        <div style={{ fontSize: "12px", fontWeight: 600 }}>Interests</div>
                                                        <div className="offerings-wrapper">
                                                            <img src={data.topicName} alt={data.topicName} style={{ marginTop: "4px", width: "25px" }} />
                                                            <div className="mt-1 offerings-text">{data.topicName}</div>
                                                        </div>
                                                    </center>
                                                </Card>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </Col> */}
                                <Col xs={7}>
                                    <Card className="offerings-card-smeprofile" style={{ marginLeft: "0.8rem", width: "94%" }}>
                                        <center style={{ fontSize: "12px", fontWeight: 600 }}>Offerings</center>
                                        <Row className="no-gutters" style={{ width: "100%", marginTop: "-3px" }}>
                                            <Col>
                                                {allOfferings.filter(data => selectedOfferings.includes(data.id)).slice(0, 1).map((data, i) => (
                                                    <center>
                                                        <div className="offerings-wrapper"
                                                            style={{
                                                                padding: "2px",
                                                                border: id === data.id ? "2px solid #ff942c" : "2px solid transparent",
                                                                borderRadius: "15px",
                                                                cursor: "pointer",
                                                                boxSizing: "border-box"
                                                            }}>
                                                            <img src={data.text + ".svg"} alt={data.text} style={{ width: "45px", marginTop: "-2px" }} />
                                                            <div className="offerings-text" style={{ marginTop: "-0.5px" }}>{data.text}</div>
                                                        </div>
                                                    </center>
                                                ))}
                                            </Col>
                                            <Col>
                                                {allOfferings.filter(data => selectedOfferings.includes(data.id)).slice(1, 2).map((data, i) => (
                                                    <center>
                                                        <div className="offerings-wrapper"
                                                            style={{
                                                                padding: "2px",
                                                                border: id === data.id ? "2px solid #ff942c" : "2px solid transparent",
                                                                borderRadius: "15px",
                                                                cursor: "pointer",
                                                                boxSizing: "border-box"
                                                            }}>
                                                            <img src={data.text + ".svg"} alt={data.text} style={{ width: "45px", marginTop: "-2px" }} />
                                                            <div className="offerings-text" style={{ marginTop: "-0.5px" }}>{data.text}</div>
                                                        </div>
                                                    </center>

                                                ))}
                                            </Col>
                                        </Row>

                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <div style={{ marginLeft: "1rem", marginRight: "1rem", flexShrink: 0 }}>
                            <Row className="no-gutters" style={{ width: "100%" }}>
                                <Col xs={5}>
                                    <center style={{ fontSize: "14px", color: "white", fontWeight: 600 }}>{name} </center>
                                    <center style={{ fontSize: "12px", color: "white" }}>{descriptionText}</center>
                                </Col>
                                <Col xs={4}>
                                    <Card style={{ background: "#d7deef", height: "5.5rem", backgroundImage: "linear-gradient(to right, #d7deef , #9DB2DD)", width: "78%", border: "none", borderRadius: "15px", padding: "auto", marginLeft: "10px", marginRight: "3px" }}>
                                        <center>
                                            <div style={{ fontSize: "12px", fontWeight: 600 }}>Assessment</div>
                                            <img src={smerating} style={{ width: "55px", marginTop: "-7px" }} alt="Assessment" />
                                            <div style={{ fontSize: "15px", fontWeight: 600, fontFamily: "Noto Sans", marginTop: "-7px" }} className="mb-1">{formatRatingNumber(rating)}</div>
                                        </center>
                                    </Card>
                                </Col>
                                <Col xs={3}>
                                    <Card style={{ width: "100%", height: "5.5rem", background: "#d7deef", backgroundImage: "linear-gradient(to right, #d7deef , #9DB2DD)", border: "none", borderRadius: "15px", padding: "auto" }}>
                                        <center>
                                            <div style={{ fontSize: "12px", fontWeight: 600 }}>Appointment</div>
                                            <img src={smebookings} style={{ width: "55px", marginTop: "-7px" }} alt="Appointment" />
                                            <div style={{ fontSize: "15px", fontWeight: 600, fontFamily: "Noto Sans", marginTop: "-7px" }} className="mb-1">{bookings}</div>
                                        </center>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <div style={{ marginLeft: "1rem", marginRight: "1rem", flexShrink: 0 }}>
                            <Row className="no-gutters" style={{ width: "100%" }}>
                                <Col xs={4}>
                                    {/* <img src={linkedinImg} className="ml-3 cursor-pointer" alt="LinkedIn" onClick={onClickUrl} /> */}
                                    <Button variant="default" className="mt-1 profile-btn-sme" style={{ borderBottom: "1px solid white" }}><Link to={`/viewsmeevents/${smeUserId}`}><div style={{ fontSize: "13px", fontWeight: 600, color: "white", fontFamily: "Noto Sans" }}>View Events</div></Link></Button>
                                </Col>
                                <Col xs={4}>
                                    <center style={{ marginLeft: "-10px", color: "white", fontFamily: "Noto Sans", fontSize: "14px" }}><b>Fees</b><br /> {commercial === 1 ? "Free" : "Paid"}</center>
                                </Col>
                                <Col xs={4} >
                                    <Button variant="default" className="ml-4 mt-1 profile-btn-sme" onClick={() => handleConnect(selectedDate, commercial)}>Connect</Button>
                                    <center className="ml-5 mt-1"><div onClick={() => shareSmeProfile()}><AiOutlineShareAlt size="23px" /></div></center>
                                </Col>
                            </Row>
                        </div>
                        <div style={{ marginLeft: "1rem", marginRight: "1rem", flex: 1, overflowY: "auto", overflowX: "hidden", marginTop: "6.5vh" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ fontSize: "16px", fontWeight: 600 }} className="ml-2">Expertise</div>
                                <div> <img src={linkedinImg} style={{ marginTop: "-10px" }} className="cursor-pointer" alt="LinkedIn" onClick={onClickUrl} /></div>
                            </div>

                            <div className="ml-2" style={{ display: "flex", flexWrap: "wrap" }}>
                                {topics.map((data, i) => (
                                    <div style={{
                                        fontSize: "12px", padding: "2px 1px", border: selectedTopic === data.id ? "2px solid #ff942c" : "2px solid transparent",
                                        borderRadius: "15px",
                                        cursor: "pointer", fontWeight: 500, marginTop: "-2px"
                                    }}>&nbsp;{data.topicName},&nbsp;</div>
                                ))}
                            </div>
                            <div style={{ fontSize: "16px", fontWeight: 600 }} className="mt-2 ml-2">About me</div>
                            <div className="mt-1 sme-intro-box-aboutme">
                                {desc}
                            </div>
                        </div>
                    </div>
                    <Modal show={showPaymentPopup} size="lg"
                        aria-labelledby="contained-modal-title-vcenter"

                        centered>
                        <Modal.Body style={{ background: "#EFF5FB", borderRadius: "16px" }}>
                            <div>
                                <div className="ml-1">  </div>
                                <div style={{ fontSize: "18px", marginTop: "-4px", textAlign: "center" }}>Payment Screen</div>
                            </div>
                            <div style={{ fontSize: "14px", textAlign: "center" }}>
                                You have selected a paid sme. Please pay the amount to book a slot.
                            </div>

                            <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                <div></div>
                                <Button variant="default" className="mt-1 prev-btn-sme" onClick={() => bookASlot(selectedDate)}>Submit</Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <div style={{ marginBottom: "3.6rem" }}></div>
                    <BottomMenu />
                </div>
                :
                <div>
                    <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                    <div className="mt-5">
                        <div className="your-jitbits flex"><div onClick={() => handleFalseShowConnect(selectedDate ? selectedDate : userDate)}><img src={back} style={{ width: "30px" }} /></div>&nbsp;<div className="mt-1">Available Slots On
                        </div>
                            <div style={{ marginLeft: "2px", color: "#ff942c", fontWeight: 600, marginTop: "-9px", border: "none" }}> {/* Adjust the width as needed */}
                                <DatePicker
                                    customInput={<CustomInput />}
                                    // selected={selectedDate ? selectedDate : new Date(Date.now())}
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    className="picker"
                                    dateFormat="dd-MMM-yyyy"
                                    placeholder="Select Date"
                                    minDate={new Date().setDate(new Date().getDate() + 1)}
                                    filterDate={(date) => daysToInclude.includes(date.getDay())}
                                />
                            </div>
                        </div>
                        <center>         <div style={{ fontSize: "18px", fontWeight: 600, color: "#ff942c", marginTop: "-20px" }}>{name}</div></center>

                        <div className="mb-2">
                            {!selectedDate ? <></> :
                                <div>
                                    {renderTimeSlots()}
                                </div>
                            }
                            <Modal show={showPopup} size="lg"
                                aria-labelledby="contained-modal-title-vcenter"

                                centered>
                                <Modal.Body style={{ background: "#EFF5FB", borderRadius: "16px" }}>
                                    <div className="sme-booking-slot-popup-planned">
                                        <div className="ml-1">  </div>
                                        <div style={{ fontSize: "18px", marginTop: "-4px", textAlign: "center" }}>Confirmation of your session</div>
                                        <div style={{ display: "flex", justifyContent: "space-between", color: "white", marginTop: "-9px", marginRight: "0.8rem", cursor: "pointer" }}> <div><i onClick={() => setShowPopup(false)} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div></div>
                                    </div>
                                    <div>
                                        <div className="sme-profile-bg-booking-req">
                                            <Row style={{ width: "100%" }} className="no-gutters">
                                                <Col xs={3}>
                                                    <center><img alt="" src={config.userUrl + profilePicture} style={{ width: "50px" }} /></center>
                                                    <center style={{ fontSize: "14px" }}>{name}</center>
                                                </Col>
                                                <Col xs={9}>
                                                    <center>
                                                        <div style={{ marginTop: "-6px" }}>{getText(id)} session </div> on  {topics.filter(data => data.id === selectedTopic)[0]?.topicName} <br />
                                                        ({selectedDate ? formatedNewDate : currDate} @ {selectedSlot})
                                                    </center>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    <input type="text"
                                        className="mt-2 web-font form-control"
                                        aria-describedby="text"
                                        name="googleLink"
                                        style={{ border: '1px solid gray', borderRadius: "5px", width: "100%" }}
                                        value={googleLink}
                                        onChange={(e) => setGoogleLink(e.target.value)}
                                        placeholder="Google Meet/Zoom Link"
                                    />
                                    <textarea type="text"
                                        className="mt-2 web-font form-control"
                                        aria-describedby="text"
                                        name="agenda"
                                        style={{ overflowY: "none", border: '1px solid gray', borderRadius: "5px", width: "100%", resize: "none" }}
                                        value={agenda}
                                        onChange={(e) => setAgenda(e.target.value)}
                                        rows={2}
                                        placeholder="Agenda"
                                    />
                                    <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div></div>
                                        <Button variant="default" disabled={!googleLink || !agenda} className="mt-1 prev-btn-sme" onClick={() => addSmeBooking()}>Confirm</Button>
                                    </div>
                                </Modal.Body>
                            </Modal>

                        </div>
                    </div>
                    <div style={{ marginBottom: "3.6rem" }}></div>
                    <BottomMenu />
                </div>
            }

        </div>
    )
})
export default SMEProfile;

const CustomInput = ({ value, onClick }) => (
    <button className="btn" onClick={onClick}>
        <i class="fa fa-calendar" id="true" aria-hidden="true"></i>
        <span className="_picker">        {value}</span>
    </button>
);



{/* <div className="mt-5">
                        <div style={{ display: "flex", marginTop: "-15px" }}><img alt="" src={config.userUrl + profilePicture} className="ml-3 jitbits-img-Author" /><span className="ml-2 jit-top">{name}</span></div>
                        <div className="ml-2 mr-2">
                            <Row style={{ width: "100%" }} className="no-gutters">
                                <Col xs={6}>
                                    <div className="" style={{ border: "1px solid gray", borderRadius: "20px", padding: "2px 3px", height: "100%" }}>
                                        <div style={{ display: "flex", width: "100%" }}>
                                            <div className="ml-4 mr-2 sme-txt-size">
                                                <center>Bookings<br /><img src={smebookings} /><br />{bookings}</center>
                                            </div>
                                            <div className="ml-2 mr-2 sme-txt-size">
                                                <center>Ratings<br /><img src={smerating} /><br />{rating}</center>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className="ml-2" style={{ border: "1px solid gray", borderRadius: "20px", padding: "2px 3px", height: "100%" }}>
                                        <div style={{ display: "flex", padding: "2px" }}>
                                            <div className="ml-2 mr-2" style={{ fontSize: "12px" }}>
                                                <span style={{ fontSize: "14px", fontWeight: 600 }}>Interests: </span><br />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="abt-u_">Services</div>
                        <div className="ml-2 mr-2">
                            <Row style={{ width: "100%" }} className="no-gutters">
                                <Col xs={6}>
                                    <div className="" style={{ border: "1px solid gray", borderRadius: "20px", padding: "2px 3px", height: "100%" }}>
                                        <div style={{ display: "flex", width: "100%" }}>
                                            {allOfferings.filter(data => selectedOfferings.includes(data.id))
                                                .map((data, i) => (
                                                    <center>
                                                        <div
                                                            key={data.id}
                                                            style={{
                                                                width: "4.5rem",
                                                                padding: "4px",
                                                                marginLeft: "7px",
                                                                marginTop: "10px",
                                                                cursor: "pointer"
                                                            }}
                                                        >
                                                            <img src={} alt={data.text} /><br />
                                                            <div className="mt-1 sme-off-text">{data.text}</div>
                                                        </div>
                                                    </center>
                                                ))}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className="ml-2" style={{ border: "1px solid gray", borderRadius: "20px", padding: "2px 3px", height: "100%" }}>
                                        <div style={{ display: "flex" }}>
                                            {allTimeSlots.filter(data => data.id === selectedTimeSlot).map((data, i) => (
                                                <div className="m-2 slot-time"><center><img src={selectedTimeSlot === 1 ? smefullday : (selectedTimeSlot === 2) ? smemorning : smeafternoon} style={selectedTimeSlot === data.id ? { border: "none", borderRadius: "15px", padding: "4px" } : { border: "none", borderRadius: "15px", padding: "4px" }} /><br />{data.time}</center></div>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="abt-u_">Linkedin</div>
                        <div className="ml-2 mr-2" style={{ border: "1px solid gray", borderRadius: "8px", padding: "2px 3px", height: "100%" }}>{linkedin ? linkedin : "Not added linkedin profile"}</div>
                        <div className="abt-u_">Introduction</div>
                        <div className="mt-1 sme-intro-box">
                            <textarea type="text"
                                className="web-font form-control"
                                aria-describedby="text"
                                name="desc"
                                style={{ overflowY: "none", background: 'white', border: '1px solid gray', borderRadius: "20px", width: "100%", resize: "none" }}
                                value={desc}
                                disabled={true}
                                rows={8}
                                placeholder="Text about self"
                                onChange={e => setDesc(e.target.value)}
                            />
                        </div>

                        <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button variant="default" className="ml-2 mt-1 prev-btn-sme" onClick={handleBack}>Back</Button>
                            <Button variant="default" className="mr-2 mt-1 prev-btn-sme" onClick={() => handleConnect(selectedDate)}>Connect</Button>
                        </div>
                    </div> */}




