import React, { Component } from 'react';
import loginlogo from '../images/login-logo.png';
import page from '../images/404.png';
import './Interests.css';
import fedduplogo from '../images/fedduplogo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            hasError: true,
            title: "",
            description: "",
            home: false,
            error:null
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true,error:error };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error("ErrorBoundary caught an error", error, errorInfo);
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    // submitFeedback = async () => {
    //     const tokenId = simpleCrypto.encrypt({ "companyId": config.companyId, "productId": config.productId });
    //     let headers = {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //     };
    //     var self = this;
    //     console.log("tpkenId", tokenId)
    //     // try {
    //     //     const response = await fetch(config.feddupUserUrl + `user/createProductFeedbackByUser`, {
    //     //         method: "POST",
    //     //         headers,
    //     //         body: JSON.stringify({
    //     //             "tokenId": tokenId,
    //     //             "title": this.state.title,
    //     //             "feedback": this.state.description,
    //     //             "feedbackImage": "",
    //     //             "tag": "",
    //     //             "versionId":config.versionId
    //     //         })
    //     //     });

    //     //     const data = await response.json();
    //     //     console.log("Response Data:", response,data);

    //     //     if (data.status === true) {
    //     //         self.setState({ home: true });
    //     //     } else {
    //     //         console.log("Feedback submission failed:", response,data);
    //     //     }
    //     // } catch (error) {
    //     //     console.log("Fetch Error:", error);
    //     // }
    // };
    // submitFeedback = async () => {
    //     const tokenId = simpleCrypto.encrypt({ "companyId": config.companyId, "productId": config.productId });
    //     let headers = {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //     };
    //     var self = this;
    //     try {
    //         console.log("Submitting feedback with payload:", {
    //             tokenId,
    //             title: this.state.title,
    //             feedback: this.state.description,
    //             feedbackImage: "",
    //             tag: ""
    //         });

    //         const response = await fetch(config.feddupUserUrl + 'user/createProductFeedbackByUser', {
    //             method: "POST",
    //             headers,
    //             body: JSON.stringify({
    //                 "tokenId": tokenId,
    //                 "title": this.state.title,
    //                 "feedback": this.state.description,
    //                 "feedbackImage": "",
    //                 "tag": ""
    //             })
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! status: ${response.status}`);
    //         }

    //         const data = await response.json();
    //         console.log("Response Data:", data);

    //         if (data.status === true) {
    //             self.setState({ home: true });
    //         } else {
    //             console.log("Feedback submission failed:", data);
    //         }
    //     } catch (error) {
    //         console.log("Fetch Error:", error);
    //     }
    // };
    submitFeedback = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "title": this.state.title,
            "description": this.state.description,

        }
        console.log("payload", payload)
        var self = this;
        axios.post(config.userUrl+"user/createIncident", payload, { headers: headers })
            .then(function (response) {
                // console.log("response", response)
                if (response.data.status === true) {
                    // console.log(response, "group created successfully!!")
                    self.setState({ home: true })
                }
            })
            .catch(function (error) {
                console.log("payload", payload, error)
                document.getElementById('error').innerText = error
            });
    }

    render() {
        const { home, hasError, title, description } = this.state
        if (home) {
            return <Redirect to="/home" />
        }
        if (hasError) {
            // You can render any custom fallback UI
            return (
                <div>
                    <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                    <center>         <img alt="" src={page} style={{ marginTop: "1.2rem", width: "60%", height: "50%" }} /></center>

                    <p className="mt-1 notfound">Oops! <br />Something went wrong.</p>
                    {/* <p className="notfound">Page not found error</p>
                <p className="below-notfound">...maybe the page you’re looking for is not found or never existed.</p> */}
                    <div className='form-group' >
                        <div style={{ color: "#ff942c", fontSize: "22px", marginLeft: "20px", fontWeight: 600, fontFamily: "Noto Sans" }}>Report Issue</div>
                        <input type="text"
                            className="mt-1 web-font form-control"
                            aria-describedby="text"
                            style={{ margin: "0px 20px", width: "89%", overflowY: "none", border: "1px solid gray", borderRadius: "6px", resize: "none" }}
                            name="title"
                            value={title}
                            onChange={this.onChange}
                            placeholder="Title"
                        />
                        <div className="mt-2">
                            <textarea type="text"
                                className="web-font form-control"
                                aria-describedby="text"
                                name="description"
                                style={{ margin: "0px 20px", width: "89%", overflowY: "none", border: "1px solid gray", borderRadius: "6px", resize: "none" }}
                                value={description}
                                onChange={this.onChange}
                                rows={5}
                                placeholder="Description"
                            />
                        </div>
                        <center>
                            <Button variant="default" disabled={!this.state.title || !this.state.description} onClick={this.submitFeedback} className="btn-block opportunity-btn mt-3">Submit</Button>
                         </center>
                    </div >
                    <br /><br />
                    <BottomMenu />
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;