import React, { Component } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import loginlogo from '../images/login-logo.png';
import PricingData from "./PricingData.json";
import BottomMenu from "../BottomMenu/BottomMenu";
import './Pricing.css';
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "../LandingPage/arrowsOnBottomOrTop.css";
import "../LandingPage/hideScrollbar.css";
import axios from 'axios';
import { config } from '../Config/Config';
import { Redirect } from 'react-router-dom';
import bhim from '../images/BHIM-UPI.jpg';
import qrcode from '../images/QrCode.jpeg';
import phonepe from '../images/phonepe.png';
import gpay from '../images/Gpay.jpg';
import amazonpay from '../images/amazon-pay.jpg';
import paytm from '../images/paytm.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class UpiDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            name: localStorage.getItem('name'),
            pName: "",
            plans: [],
            picture: "",
            redirect: false,
            houseNo: "",
            buildingName: "",
            address1: "",
            address2: "",
            city: "",
            phone: "",
            pincode: "",
            upiId: "",
            id: this.props.location.state.id,
            planId: this.props.location.state.planId,
            planName: this.props.location.state.planName,
            amount: this.props.location.state.amount,
            details: "",
            type: "",
            size: "",
            updateFlag: false,

        }

    }
    componentDidMount() {
        this.getPostalDetailsByUserId()
    }

    showNone = () => {
        var self = this;
        self.setState({
            redirect: true
        })
        document.getElementById('upgrade').style.display = 'none';

    }
    closeModal() {
        document.getElementById('sameplan').style.display = 'none';
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    // payment = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + this.state.token,
    //         "applicationType": "mobile"
    //     }
    //     var payload = {
    //         "amount": Number(this.state.amount),
    //         "currency": "INR",
    //         "planName": this.state.planName,
    //         "planDuration": "yearly"
    //     }
    //     axios.post(config.userUrl + 'user/createOrder', payload, { headers: headers })
    //         .then((response) => {
    //             this.handleOpenRazorpay({ data: response.data });
    //         }).catch((error) => {
    //             console.log(error, "error")
    //         });
    // };
    addPostalDetailsByUserId = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "houseNo": this.state.houseNo,
            "buildingName": this.state.buildingName,
            "address1": this.state.address1,
            "address2": this.state.address2,
            "city": this.state.city,
            "phone": this.state.phone,
            "pincode": this.state.pincode,
            "upiId": this.state.upiId,
            "updateFlag": this.state.updateFlag,
            "type": this.state.type,
            "size": this.state.size
        }
        var self = this;
        axios.post(config.userUrl + 'user/addPostalDetailsByUserId', payload, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                   // console.log('status')
                    self.setState({ redirect: true })
                }
            }).catch((error) => {
                console.log(error, "error")
            });
    };
    getPostalDetailsByUserId = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getPostalDetailsByUserId', { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    if (response.data.getPostalDetails.length > 0) {
                        self.setState({
                            houseNo: response.data.getPostalDetails[0].houseNo,
                            buildingName: response.data.getPostalDetails[0].buildingName,
                            address1: response.data.getPostalDetails[0].address1,
                            city: response.data.getPostalDetails[0].city,
                            phone: response.data.getPostalDetails[0].phone,
                            pincode: response.data.getPostalDetails[0].pincode,
                            upiId: response.data.getPostalDetails[0].upiId,
                        })
                    } else {
                        self.setState({ updateFlag: true })
                    }
                }
            }).catch((error) => {
                console.log(error, "error")
            });
    };
    uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    handleImage = async (e) => {
       // console.log("fgh", e.target.files)
        if (e.target.files && e.target.files[0]) {
            const res = await this.uploadImage(e.target.files[0]);
            if (res) {
               // console.log("res", res)
                this.setState({ picture: res })
            }
        }
    }
    showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }
    render() {
        const { redirect, imgRef, picture } = this.state;
        if (redirect) {
            return <Redirect
                to={{
                    pathname: "/qrcode",
                    state: { id: this.state.id, planId: this.state.planId, planName: this.state.planName, amount: this.state.amount, details: `${this.state.houseNo + " , " + this.state.buildingName + " , " + this.state.address1 + " , " + this.state.city + " , " + this.state.pincode + " , " + this.state.phone+" , " + this.state.type + " , " + this.state.size}` }
                }} />
        }
     //   { console.log("a", `${this.state.houseNo + " , " + this.state.buildingName + " , " + this.state.address1 + " , " + this.state.phone + " , " + this.state.city + " , " + this.state.pincode}`) }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <div className="screen-topgap">
                    <span className="your-jitbits">Postal Address</span>
                    <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                        <form>
                            <label className="mt-1 ml-1 input-text-h">Name</label>
                            <input type="text" readOnly className="ip form-control" name="name" onChange={this.onChange} value={this.state.name} />
                            <label className="ml-1 input-text-h">House No</label>
                            <input type="text input-text-h" className="ip form-control" name="houseNo" onChange={this.onChange} value={this.state.houseNo} />
                            <label className="ml-1 input-text-h">Building Name</label>
                            <input type="text" className="ip form-control" name="buildingName" onChange={this.onChange} value={this.state.buildingName} />
                            <label className="ml-1 input-text-h">Address Line </label>
                            <input type="text" className="ip form-control" name="address1" onChange={this.onChange} value={this.state.address1} />
                            <label className="ml-1 input-text-h">Phone No</label>
                            <input type="text" className="ip form-control" name="phone" onChange={this.onChange} value={this.state.phone} />
                            <label className="ml-1 input-text-h">City</label>
                            <input type="text" className="ip form-control" name="city" onChange={this.onChange} value={this.state.city} />
                            <label className="ml-1 input-text-h">Pincode</label>
                            <input type="text" className="ip form-control" name="pincode" onChange={this.onChange} value={this.state.pincode} />
                            <label className="ml-1 input-text-h">UPI ID (In case of Refund)</label>
                            <input type="text" className="ip form-control" name="upiId" onChange={this.onChange} value={this.state.upiId} />
                            <label className="ml-1 input-text-h">Type</label>
                            <select name="type" value={this.state.type} type="text" className="ip form-control"
                                onChange={this.onChange}>
                                <option value={""}>Select Tshirt Type</option>
                                <option key={1} value={"Collar"}>Collar</option>
                                <option key={2} value={"Round-Neck"}>Round-Neck</option>
                            </select>
                            <label className="ml-1 input-text-h">Tshirt Size</label>
                            <select name="size" value={this.state.size} type="text" className="ip form-control"
                                onChange={this.onChange}>
                                <option value={""}>Select Size</option>
                                <option key={1} value={"S"}>S</option>
                                <option key={2} value={"M"}>M</option>
                                <option key={3} value={"L"}>L</option>
                                <option key={4} value={"XL"}>XL</option>
                                <option key={5} value={"XXL"}>XXL</option>
                            </select>
                        </form>
                        {((this.state.houseNo !== "") && (this.state.buildingName !== "") && (this.state.address1 !== "") && (this.state.phone !== "") && (this.state.city !== "") && (this.state.pincode !== "")) && (this.state.upiId !== "") ?

                            <div><Button variant="default" className="mt-2 upload_transaction" onClick={this.addPostalDetailsByUserId}><div className="ml-3 mr-3">Submit</div></Button></div>
                            :
                            <div><Button variant="default" className="mt-2 upload_transaction" disabled="true"><div className="ml-3 mr-3">Submit</div></Button></div>}

                    </div>
                    <br /><br /><br /><br /><br />
                </div >
                {/* <div id="publish" className="modal-pop">
                    <form className="modal-content-log-height">
                        <div className="_container">
                            <center style={{height:"150px"}}>
                                <label htmlFor="upload-button">
                                    {picture != "" ? (
                                        <img src={picture} style={{ width: "100px", height: "100px" }} />
                                    ) : (
                                        <>
                                            <i class="fa fa-upload fa-2x" aria-hidden="true"></i>
                                            <div className="up-load">Upload Image</div>
                                        </>
                                    )}
                                </label>
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    id="upload-button"
                                    name="image"
                                    onChange={(e) => this.handleImage(e)}
                                />
                            </center>
                            <center><Button variant="default" onClick={() => this.handleImage()} className="mb-2 cancelbutton">Yes</Button>&nbsp;&nbsp;
                                <Button variant="default" onClick={this.showNone} className="mb-2 deletebutton">No</Button></center>
                        </div>
                    </form>
                </div> */}

                < BottomMenu />
            </div >
        )
    }
}
