import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { Redirect,Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import forgotImg from '../images/forgotImg.png';
import './Login.css';
import axios from 'axios';
import { config } from '../Config/Config';

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            registerFlow: false,
            redirect: false
        }
    }
    sendOtp = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var payload = {
            "email": this.state.email,
            "registerFlow": this.state.registerFlow
        }
        var self = this;
        axios.post(config.apiUrl + 'auth/sendOtp', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                  //  console.log("Otp sent successfully!!")
                  //  console.log("payload", payload)
                    self.setState({ redirect: true })
                } else {
                    console.log(response.data.message)
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
                alert("Error")
                console.log("error", payload,error)
            });
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    render() {
        const { redirect } = this.state
        if (redirect) {
            return <Redirect
                to={{
                    pathname: "/verify",
                    state: { email: this.state.email, registerFlow: this.state.registerFlow }
                }} />
        }
        return (
            <div>
                <div className="top_bar"><Link to="/login"><img alt="" src={loginlogo} className="login-logo" /></Link></div>
                <center> <img alt="" src={forgotImg} className="forgotImg" />
                    <p className="forgot-text">Forgot password?</p>
                    <p className="forgot-text-sm">Enter registered email address for recovery<br />your account</p></center>
                <Form className="forgot-email">
                    <center>
                        <div style={{ width: "80%" }}>
                            <input type="email"
                                className="form-control form-bg text-lowercase"
                                aria-describedby="text"
                                value={this.state.email}
                                name="email"
                                placeholder="Email"
                                onChange={this.onChange}
                            />
                        </div>
                        <div id="status" className="mt-2 errormsg"></div><br />
                        <div><Button variant="default" onClick={this.sendOtp} className="btn-block login-btn">Reset Password</Button><br /></div>

                    </center>
                </Form>
            </div>
        );
    }
}
