import React, { Component } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import loginlogo from '../images/login-logo.png';
import PricingData from "./PricingData.json";
import BottomMenu from "../BottomMenu/BottomMenu";
import './Pricing.css';
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "../LandingPage/arrowsOnBottomOrTop.css";
import "../LandingPage/hideScrollbar.css";
import axios from 'axios';
import { config } from '../Config/Config';
import { Redirect } from 'react-router-dom';
import bhim from '../images/BHIM-UPI.jpg';
import qrcode from '../images/QrCode.jpeg';
import phonepe from '../images/phonepe.png';
import gpay from '../images/Gpay.jpg';
import amazonpay from '../images/amazon-pay.jpg';
import paytm from '../images/paytm.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class RefundTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            name: localStorage.getItem('name'),
            planId: "plan_JcFdkkWqkF5ec3",
            planName: "Integrator",
            transactionId: "vRtBuwBGYt",
            createdOn: "2023-05-24T11:11:34.935Z",
            planAmount: "100",
            plans: [],
            picture: "",
            status: 6,
            redirect: false,
            upiId: "",
            reason: ""
        }

    }
    componentDidMount() {
    }

    postalDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "upiId": this.state.upiId,
            "reason": this.state.reason,
        }
        var self = this;
        axios.post(config.userUrl + 'user/askForRefund', payload, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                   // console.log('status')
                    self.setState({ redirect: true })
                }
            }).catch((error) => {
                console.log(error, "error")
            });
    };


    showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }
    render() {
        const { redirect, imgRef, picture } = this.state;
        if (redirect) {
            return <Redirect to="/currentpricing" />
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <div className="screen-topgap">
                    <span className="your-jitbits">Refunded Transaction</span>
                    <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                        <Row style={{ width: "100%" }}>
                            <Col xs={6}>
                                <p className="mt-3 purchase-t-left"><b>Transaction Id</b></p>
                                <p className="mt-2 purchase-t-left"><b>Plan Id</b></p>
                                <p className="mt-2 purchase-t-left"><b>Plan Name</b></p>
                                <p className="mt-2 purchase-t-left"><b>Currency</b></p>
                                <p className="mt-2 purchase-t-left"><b>Plan Amount</b></p>
                                <p className="mt-2 purchase-t-left"><b>Purchased On</b></p>
                                <p className="mt-2 purchase-t-left"><b>Status</b></p>
                                {/* <p className="mt-2 purchase-t-left"><b>Image</b></p> */}
                            </Col>
                            <Col xs={6}>
                                <p className="mt-3 purchase-t-left">{this.state.transactionId}</p>
                                <p className="mt-2 purchase-t-left">{this.state.planId}</p>
                                <p className="mt-2 purchase-t-left">{this.state.planName}</p>
                                <p className="mt-2 purchase-t-left">&#8377;</p>
                                <p className="mt-2 purchase-t-left">{this.state.planAmount} </p>
                                <p className="mt-2 purchase-t-left">{this.state.createdOn.split("T")[0].split("-").reverse().join("-")}</p>
                                <p className="mt-2 purchase-t-left">{this.state.status == 5 ? "Refunded" : (this.state.status == 2 ? "Completed" : (this.state.status == 3 ? "Accepted" : (this.state.status == 4 ? "Rejected" : (this.state.status == 6 ? "Refunded" : "Waiting"))))}</p>
                                {/* <p className="mt-2 purchase-t-left"><img src={qrcode} style={{width:"60%"}}/> </p> */}
                            </Col>
                        </Row>
                        <center>   <img src={qrcode} style={{ width: "60%" }} /></center>
                    </div>
                </div>
            </div>
        )
    }
}