import React, { Component } from "react";
import { Button, Form, Col, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import circle from '../images/circle.png';
import author1 from '../images/profile.png';
import upload from '../images/upload.png';
import circleblue from '../images/circle-blue.png';
import circleyellow from '../images/circle-reverse-yellow.png';
import './Login.css';
import axios from 'axios';
import { config } from '../Config/Config';
import TopMenu from "../TopMenu/TopMenu";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validPhoneNoRegex = RegExp(
    /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9,19}$/i
);
export default class UpdateProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: "",
            redirect: false,
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            name: localStorage.getItem('name'),
            email: localStorage.getItem('email'),
            // token:"",
            // name:"",
            // email:"",
            phone: "",
            ageGroup: "",
            gender: "",
            experience: "",
            company: "",
            designation: "",
            city: "",
            country: "",
            fourEs: "Enthusiasts",
            fourEsError: "",
            registerFlow: true,
            phoneNoError: '',
            ageGroupError: '',
            genderError: '',
            experienceError: '',
            companyError: '',
            designationError: '',
            cityError: '',
            countryError: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.validateName = this.validateName.bind(this);
        this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
        this.validateGender = this.validateGender.bind(this);
        this.validateAgeGroup = this.validateAgeGroup.bind(this);
        this.validateExperience = this.validateExperience.bind(this);
        this.validateCompany = this.validateCompany.bind(this);
        this.validateDesignation = this.validateDesignation.bind(this);
        this.validateCity = this.validateCity.bind(this);
        this.validateCountry = this.validateCountry.bind(this);
        this.validateFourEs = this.validateFourEs.bind(this);
        this.validateField = this.validateField.bind(this);
    }
    onChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }

    handleBlur(event) {
        const { name } = event.target;
        this.validateField(name);
        return;
    }

    handleSubmit(event) {
        event.preventDefault();
       // console.log(this.state);
        localStorage.setItem('name', this.state.name);
        let formFileds = [
            "name",
            "phone",
            "ageGroup",
            "gender",
            "experience",
            "company",
            "designation",
            "city",
            "country",
            "fourEs",

        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = this.validateField(field) && isValid;
        });
        if (!isValid) {
           // console.log("Please enter the details")
            document.getElementById('status').innerHTML = "<div>Please enter valid details</div>"
        } else {
            this.updateUserDetails()
        }
    }

    // addUserDescription = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + this.state.token,
    //         "applicationType": "mobile"
    //     }
    //     var payload = {
    //         "description": this.state.description
    //     }
    //     console.log(payload, "payload")
    //     var self = this
    //     axios.post(config.userUrl + 'user/addDescription', payload, { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
    //             if (response.data.status === true) {
    //                 console.log("success");
    //                 self.setState({ redirect: true })
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error");
    //         });
    // };

    updateUserDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "name": this.state.name,
            "phone": this.state.phone,
            "ageGroup": this.state.ageGroup,
            "gender": this.state.gender,
            "experience": this.state.experience,
            "company": this.state.company,
            "designation": this.state.designation,
            "city": this.state.city,
            "country": this.state.country,
            "fourEs": this.state.fourEs,
        }
        var self = this;
        axios.post(config.userUrl + 'user/updateProfile', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                  //  console.log("success", payload);
                    self.setState({ redirect: true })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    validateField(name) {
        let isValid = false;
        if (name === "name") isValid = this.validateName();
        else if (name === "phone") isValid = this.validatePhoneNumber();
        else if (name === "gender") isValid = this.validateGender();
        else if (name === "ageGroup") isValid = this.validateAgeGroup();
        else if (name === "company") isValid = this.validateCompany();
        else if (name === "experience") isValid = this.validateExperience();
        else if (name === "designation") isValid = this.validateDesignation();
        else if (name === "city") isValid = this.validateCity();
        else if (name === "country") isValid = this.validateCountry();
        else if (name === "fourEs") isValid = this.validateFourEs();
        return isValid;
    }

    validateName() {
        let nameError = "";
        const value = this.state.name;
        if (value.trim() === "") nameError = "Name must be at least 5 characters long!";
        else if (value.length < 5)
            nameError = 'Name must be at least 5 characters long!';
        else if (value.length > 50)
            nameError = 'Name should not be more than 50 characters long!';
        this.setState({
            nameError
        });
        return nameError === "";
    }

    validatePhoneNumber() {
        let phoneNoError = "";
        const value = this.state.phone;
        if (value.trim === "") phoneNoError = "Please enter valid phone number";
        else if (!validPhoneNoRegex.test(value))
            phoneNoError =
                "Please enter valid phone number";
        this.setState({
            phoneNoError
        });
        return phoneNoError === "";
    }

    validateAgeGroup() {
        let ageGroupError = "";
        const value = this.state.ageGroup;
        if (value.trim() === "") ageGroupError = "Please select the age group";
        else if (value.length < 5)
            ageGroupError = 'Please select the age group';
        this.setState({
            ageGroupError
        });
        return ageGroupError === "";
    }
    validateGender() {
        let genderError = "";
        const value = this.state.gender;
        if (value.trim() === "") genderError = "Please select the gender";
        this.setState({
            genderError
        });
        return genderError === "";
    }
    validateCompany() {
        let companyError = "";
        const value = this.state.company;
        if (value.trim() === "") companyError = "Please add the company";
        else if (value.length < 4)
            companyError = 'Please add the company';
        this.setState({
            companyError
        });
        return companyError === "";
    }
    validateExperience() {
        let experienceError = "";
        const value = this.state.experience;
        if (value.trim() === "") experienceError = "Please select the experience";
        else if (value.length < 3)
            experienceError = 'Please select the experience';
        this.setState({
            experienceError
        });
        return experienceError === "";
    }
    validateDesignation() {
        let designationError = "";
        const value = this.state.designation;
        if (value.trim() === "") designationError = "Please add the designation";
        else if (value.length < 5)
            designationError = 'Please add the designation';
        this.setState({
            designationError
        });
        return designationError === "";
    }
    validateCity() {
        let cityError = "";
        const value = this.state.city;
        if (value.trim() === "") cityError = "Please add the city";
        else if (value.length < 3)
            cityError = 'Please add the city';
        this.setState({
            cityError
        });
        return cityError === "";
    }
    validateCountry() {
        let countryError = "";
        const value = this.state.country;
        if (value.trim() === "") countryError = "Please add the country";
        else if (value.length < 4)
            countryError = 'Please add the country';
        this.setState({
            countryError
        });
        return countryError === "";
    }
    validateFourEs() {
        let fourEsError = "";
        const value = this.state.fourEs;
        if (value.trim() === "") fourEsError = "Please select any of the 4Es";
        this.setState({
            fourEsError
        });
        return fourEsError === "";
    }
    render() {
        var userName = this.state.name
        // var userName = this.state.name.split("@")[0];
        const { redirect } = this.state
        if (redirect) {
            return <Redirect
                to={{
                    pathname: "/profile",
                    state: { email: this.state.email, token: this.state.token }
                }}
            />
        }
        return (
            <div>
                <div className="prof-topbar"> <img alt="" src={loginlogo} className="login-logo mt-2" />
                    <img alt="" src={circle} className="-Circle" />
                    <p className="mt-2 add-prof-text">Add Profile</p>
                </div>
                <Form onSubmit={this.handleSubmit} className="form-profile-register">
                    <center>
                        <Row style={{ width: "100%" }}>
                            <Col xs={12}>
                                <div style={{ width: "95%" }} >
                                    <div className="login-credentials">Registration Details</div><br />
                                    <div className="box-form">
                                        <div className="form-group mt-2">
                                            <input type="email"
                                                className="form-control text-lowercase form-bg"
                                                aria-describedby="text"
                                                value={this.state.email}
                                                name="email"
                                                disabled={true}
                                                placeholder="Email*"
                                            />
                                            <div className="div">{this.state.emailError && (
                                                <div className="error">{this.state.emailError}</div>
                                            )}</div>
                                            <div id="error-msg" className="div error">
                                            </div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <input type="text"
                                                className="form-control form-bg"
                                                aria-describedby="text"
                                                value={this.state.name}
                                                name="name"
                                                placeholder="Name*"
                                                onChange={this.onChange}
                                                onBlur={this.handleBlur}
                                            />
                                            <div className="div">{this.state.nameError && (
                                                <div className="error">{this.state.nameError}</div>
                                            )}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12}>

                                <div style={{ width: "95%" }} >
                                    <div className="login-credentials">About you</div><br />
                                    <div className="box-form">
                                        <div className="form-group mt-2">
                                            <select name="fourEs" value={this.state.fourEs} onBlur={this.handleBlur} onChange={this.onChange} class="form-control form-bg">
                                                <option value="Enthusiasts">Enthusiasts</option>
                                                <option value="Enterprises">Enterprises</option>
                                                <option value="Entrepreneurs">Entrepreneurs</option>
                                                <option value="Experts">Experts</option>
                                                <option value="Enthusiasts">Enthusiasts</option>
                                            </select>
                                            <div className="div">{this.state.fourEsError && (
                                                <div className="error">{this.state.fourEsError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <input type="number"
                                                className="form-control form-bg"
                                                aria-describedby="number"
                                                value={this.state.phone}
                                                name="phone"
                                                placeholder="Mobile*"
                                                onChange={this.onChange}
                                                onBlur={this.handleBlur}
                                            />
                                            <div className="div">{this.state.phoneNoError && (
                                                <div className="error">{this.state.phoneNoError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <select name="gender" onBlur={this.handleBlur} onChange={this.onChange} class="form-control form-bg">
                                                <option value="">Gender*</option>
                                                <option value={0}>Male</option>
                                                <option value={1}>Female</option>
                                                <option value={2}>Others</option>
                                            </select>
                                            <div className="div">{this.state.genderError && (
                                                <div className="error">{this.state.genderError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <select name="ageGroup" value={this.state.ageGroup} onBlur={this.handleBlur} onChange={this.onChange} class="form-control form-bg">
                                                <option value="">Age Group*</option>
                                                <option value="18-25">18-25</option>
                                                <option value="25-35">25-35</option>
                                                <option value="35-55">35-55</option>
                                                <option value="55+">55+</option>
                                            </select>
                                            <div className="div">{this.state.ageGroupError && (
                                                <div className="error">{this.state.ageGroupError}</div>
                                            )}</div>
                                        </div>

                                        <div className="form-group mt-2">
                                            <input type="text"
                                                className="form-control form-bg"
                                                aria-describedby="text"
                                                value={this.state.city}
                                                name="city"
                                                placeholder="City*"
                                                onChange={this.onChange}
                                                onBlur={this.handleBlur}
                                            />
                                            <div className="div">{this.state.cityError && (
                                                <div className="error">{this.state.cityError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <input type="text"
                                                className="form-control form-bg"
                                                aria-describedby="text"
                                                value={this.state.country}
                                                name="country"
                                                placeholder="Country*"
                                                onChange={this.onChange}
                                                onBlur={this.handleBlur}
                                            />
                                            <div className="div mb-4">{this.state.countryError && (
                                                <div className="error">{this.state.countryError}</div>
                                            )}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div style={{ width: "95%" }}>
                                    <div className="login-credentials">About your work</div><br />
                                    <div className="box-form">
                                        <div className="form-group mt-2">
                                            <input type="text"
                                                className="form-control form-bg"
                                                aria-describedby="text"
                                                value={this.state.designation}
                                                name="designation"
                                                placeholder="Designation*"
                                                onChange={this.onChange}
                                                onBlur={this.handleBlur}
                                            />
                                            <div className="div">{this.state.designationError && (
                                                <div className="error">{this.state.designationError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <input type="text"
                                                className="form-control form-bg"
                                                aria-describedby="text"
                                                value={this.state.company}
                                                name="company"
                                                placeholder="Company*"
                                                onChange={this.onChange}
                                                onBlur={this.handleBlur}
                                            />
                                            <div className="div">{this.state.companyError && (
                                                <div className="error">{this.state.companyError}</div>
                                            )}</div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <select name="experience" value={this.state.experience} onBlur={this.handleBlur} onChange={this.onChange} class="form-control form-bg">
                                                <option value="">Experience*</option>
                                                <option value="0-5">0-5</option>
                                                <option value="5-10">5-10</option>
                                                <option value="10-15">10-15</option>
                                                <option value="15-20">15-20</option>
                                                <option value="20+">20+</option>
                                            </select>
                                            <div className="div">{this.state.experienceError && (
                                                <div className="error">{this.state.experienceError}</div>
                                            )}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div style={{ marginTop: "0.8rem" }}>
                            <div id="status" className="mb-1 errormsg"></div>
                            <div id="error" className="mb-1 errormsg"></div>
                            {(this.state.email !== "" && this.state.name !== "", this.state.phone !== "", this.state.ageGroup !== "" && this.state.gender !== "" && this.state.city !== "" && this.state.country !== "" && this.state.designation !== "", this.state.company !== "" && this.state.experience !== "") ?
                                <Button variant="default" type="submit" className="btn-block signup-btn">Submit</Button> : <Button variant="default" type="submit" disabled={true} className="btn-block signup-btn">Submit</Button>}<br />
                        </div>
                    </center>
                </Form>
            </div >
        );
    }
}
