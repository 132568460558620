import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import moment from 'moment-timezone';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import back from '../images/backarrow.png'
import SimpleCrypto from "simple-crypto-js";
import activecircle from '../images/orange-tick.svg';
import rejectedcircle from '../images/rejected-cir.svg';
import publishedcircle from '../images/published-cir.svg';
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class DetailedIncident extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            incident: [],
            redirect: false,
            isLoading: true
        }
        this.goBack = this.goBack.bind(this)
    }
    goBack = () => {
        this.setState({ redirect: true })
    }
    componentDidMount() {
        this.getIncidentById();
    }

    getIncidentById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this;
        axios.get(config.userUrl + `user/getIncidentById/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    self.setState({
                        incident: response.data.incident,
                        isLoading: false
                    })
                    console.log("data", moment(response.data.incident[0]?.resolutionDate).format("DD-MM-YYYY"))
                    // console.log("res", response.data)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    render() {
        const { incident, redirect } = this.state
        // const updatedDate = incident.map((i, idx) => {
        //     var a = new Date(i.applyByDate)
        //     var start = parseInt(a.getMonth() + 1) + "-" + a.getDate() + "-" + a.getFullYear();
        //     return start;
        // })

        //   const selectedStartDate = new Date(updatedDate)
        if (redirect) {
            return <Redirect to='/showAllIncidents' />
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>

                <div style={{ marginTop: "2.7rem", display: "flex" }}>
                    <div onClick={this.goBack}>  <img src={back} alt="Back" className="ml-3" style={{ width: "30px" }} /></div>
                    <div className="mt-1"><h5><b style={{ color: "#ff942C", marginLeft: "0.2rem" }}>Incident Details</b></h5></div>

                </div>
                {incident.map((data, i) => (
                    <div style={{ marginLeft: "5%", marginRight: "5%", width: "90%" }} >
                        <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                            <div className="form-group mt-1">
                                <div className="mb-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="view-opp-label">Title</div>
                                    <div className="view-opp-label">{moment(data.createdAt).format("DD-MMM")}</div>
                                    <div className="view-opp-label"> <img src={data.status == 1 ? activecircle : data.status == 2 ? publishedcircle : rejectedcircle} style={{ width: "20px" }} /></div>
                                </div>
                                <input type="text"
                                    className="form-control form-bg-opp"
                                    aria-describedby="text"
                                    value={data.title}
                                    readOnly
                                    name="title"
                                    placeholder="Title"
                                />
                                <label className="mt-2 view-opp-label">Description</label>
                                <textarea rows={4} type="text"
                                    className="form-control"
                                    style={{ resize: "none", fontSize: "12px" }}
                                    value={data.description}
                                    readOnly
                                    name="description"
                                    placeholder="Description"
                                />
                            </div>
                        </div>
                        </Card>
                        {data.status == 1 ? "" :
                            <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                                <div className="form-group">
                                    <div className="mt-2 mb-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div className="view-opp-label">Reason</div>
                                        <div className="view-opp-label">{moment(data.resolutionDate).format("DD-MMM")}</div>
                                    </div>
                                    <textarea rows={4} type="text"
                                        className="form-control"
                                        style={{ resize: "none", fontSize: "12px" }}
                                        value={data.reason}
                                        readOnly
                                        name="reason"
                                        placeholder="Reason"
                                    />

                                </div>
                            </div>
                            </Card >
                        }

                    </div >))}

                <div className="mb-bottom"></div>
                <BottomMenu />
            </div >
        )
    }
}
