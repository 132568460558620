import React, { Component } from "react";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import back from '../images/backarrow.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import './Terms.css';

export default class PrivacyAndSecurity extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <Link to="/settings"><img alt="" src={back} className="back_arrow" /></Link>
                <p className="terms-heading">Code of Conduct</p>
                <p className="terms-sm">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
                    nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
                    enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis
                    nisl ut aliquip ex ea commodo consequat.<br /><br />Duis autem vel eum iriure dolor in
                    hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat
                    nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit
                    praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem
                    ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod
                    incidunt ut laoreet dolore magna aliquam erat volutpat.<br /><br /> Ut wisi enim ad minim veniam,
                    uis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
                    onsequat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
                    nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
                    enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
                    lobortis nisl ut aliquip ex ea commodo consequat. <br />Duis autem vel eum iriure
                    dolor in hendrerit in vulputate velit esse molestie consequat, vel illum
                    dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto
                    odio dignissim qui blandit praesent luptatum zzril delenit augue duis
                    dolore te feugait nulla facilisi.</p><br /><br /><br />

                <BottomMenu />
            </div >
        )
    }
}
