import React, { Component } from "react";
import loginlogo from '../images/login-logo.png';
import page from '../images/404.png';
import './Interests.css';
import BottomMenu from "../BottomMenu/BottomMenu";

export default class PageNotFound extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
               <center> <img alt="" src={page} style={{ marginTop: "3rem", width: "90%", height: "70%" }} /></center>
                <p className="mt-2 oops">Oops!</p>
                <p className="notfound">Page not found</p>
                <p className="below-notfound">...maybe the page you’re looking for is not found or never existed.</p>
                <BottomMenu />
            </div >
        )
    }
}
// import React, { Component } from "react";
// import { Button, Navbar, Nav, Form, Container, Col, Row, Image } from "react-bootstrap";
// import { Link, Redirect } from 'react-router-dom';
// import loginlogo from '../images/login-logo.png';
// import rectangle from '../images/rectangle.png';
// import procurement from '../images/procurement.png';
// import logistics from '../images/logistics.png';
// import assets from '../images/assets.png';
// import operations from '../images/operations.png';
// import infotechnology from '../images/info-technology.png';
// import crm from '../images/crm.png';
// import erp from '../images/erp.png';
// import payments from '../images/payments&financing.png';
// import sourcing from '../images/sourcing.png';
// import vendormanagement from '../images/vendormanagement.png';
// import contactmanagement from '../images/contactmanagement.png';
// import inventorycontrol from '../images/inventorycontrol.png';
// import demandplanning from '../images/demandplanning.png';
// import compliance from '../images/compliance.png';
// import regulations from '../images/regulations.png';
// import internationaltrade from '../images/internationaltrade.png';
// import strategicsourcing from '../images/strategicsourcing.png';
// import disposalmanagement from '../images/disposalmanagement.png';
// import wastemanagement from '../images/wastemanagement.png';
// import sustainablegrowth from '../images/sustainablegrowth.png';
// import models from '../images/models.png';
// import './LandingPage.css';
// import homeicon from '../images/homeicon.png';
// import articleicon from '../images/articleicon.png';
// import search from '../images/search.png';
// import menuicon from '../images/menuicon.png';
// import axios from 'axios';
// import { config } from '../Config/Config';
// import AuthorsJson from './Authors.json';

// export default class LandingPage extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             token: localStorage.getItem("token"),
//             allTopics: [],
//             topicIds: []
//         }
//     }
//     onAddingItem = (item) => {
//         const isChecked = item.target.checked;
//         const value = item.target.value;
//         console.log("item", isChecked)
//         this.setState(prevState => ({ allTopics: prevState.allTopics.map(data => data.id === value ? { ...data, isAdded: isChecked } : data) }));
//         if (isChecked)
//             this.setState(prevState => ({ topicIds: [...prevState.topicIds, value] }));
//         else {
//             const newTopicIds = this.state.topicIds.filter(data => data !== value)
//             this.setState({ topicIds: newTopicIds }, () => console.log(this.state.topicIds));
//         }
//     }
//     componentDidMount = () => {
//         this.getAllTopics();
//     }
//     // handleChange = (item) => {
//     //     const checked = item.target.checked;
//     //     const value = item.target.value;
//     //     console.log("item", checked)
//     //     let { topicIds } = this.state;
//     //     this.setState(prevState => ({ allTopics: prevState.allTopics.map(data => data.id === value ? { ...data, isAdded: checked } : data) }));
//     //     if (checked) {
//     //         topicIds = [...topicIds, value];
//     //     } else {
//     //         const newTopicIds = topicIds.filter(el => el !== value)
//     //         this.setState({ topicIds: newTopicIds });
//     //     };
//     // }
//     getAllTopics = () => {
//         let headers = {
//             "Content-Type": 'application/json',
//             "Authorization": "Bearer " + this.state.token
//         }
//         var self = this
//         axios.get(config.userUrl + 'user/getAllTopics', { headers: headers })
//             .then(function (response) {
//                 console.log(response);
//                 if (response.data.status == true) {
//                     console.log("success");
//                     for (let i = 0; i < response.data.topics.length; i++) {
//                         response.data.topics[i].isAdded = false
//                     }
//                     self.setState({ allTopics: response.data.topics })
//                 }
//             })
//             .catch(function (error) {
//                 console.log("Error");
//             });
//     };
//     postTopics = () => {
//         let headers = {
//             "Content-Type": 'application/json',
//             "Authorization": "Bearer " + this.state.token
//         }
//         var payload = {
//             "topicIds": this.state.topicIds
//         }
//         var self = this
//         axios.post(config.userUrl + 'user/addTopics', payload, { headers: headers })
//             .then(function (response) {
//                 console.log(response);
//                 if (response.data.status == true) {
//                     console.log("Success", payload);
//                 }
//             })
//             .catch(function (error) {
//                 console.log("Error");
//             });
//     };
//     render() {
//         return (
//             <div>
//                 <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
//                 <img alt="" src={rectangle} className="rectangle" />
//                 <p className="landing-text">Welcome,</p><br />
//                 <p className="choose-text">Choose your interest,</p>
//                 <center>
//                     <div>
//                         <Container>
//                             {/* <Row style={{ width: "100%" }}>
//                                 {this.state.topics.map((data, i) => (
//                                     <Col xs={4} >
//                                         <label class="checkbox-checked">
//                                             <input type="checkbox"
//                                                 key={i}
//                                                 value={data.interest}
//                                                 checked={this.state.topics[i].isAdded}
//                                                 onChange={this.onAddingItem}
//                                                 className={this.state.topics[i].isAdded ? "display-checkbox" : "hide-checkbox"}
//                                                 style={{ float: "right" }} /><img alt="" src={data.icon} className="procurement" />
//                                             <p className="text mr-2">{data.interest}</p></label></Col>
//                                 ))}
//                             </Row> */}

//                             <Row style={{ width: "100%" }}>
//                                 {this.state.allTopics.map((data, i) => (
//                                     <Col xs={4}
//                                     ><input type="checkbox"
//                                         key={i}
//                                         value={data.id}
//                                         checked={this.state.allTopics[i].isAdded}
//                                         onClick={this.onAddingItem}
//                                         className={this.state.allTopics[i].isAdded ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }}
//                                         />
//                                         <img alt="" src={data.topicName.toLowerCase().replace(/ /g, '') + ".png"} className="procurement" />
//                                         <p className="text">{data.topicName}</p>
//                                     </Col>
//                                 ))}
//                             </Row>
//                         </Container>
//                     </div>
//                 </center>
//                 <p className="landing-text-sm">You can always change your interests in the profile &gt;&gt;&nbsp;Interests</p>
//                 {/* {this.state.topicIds.length == 0 ? <center>Please select any of the topics</center> : */}
//                 <Link to="/authors">
//                     <Button variant="default" onClick={this.postTopics} className="landing-btn float">Next</Button></Link>
//                 {/* } */}
//             </div>
//         )
//     }
// }