import React, { Component } from "react";
import {Button } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import likes from '../images/sceem-likes.png';
import writearticle from '../images/sceem-writearticle.png';
import readarticle from '../images/sceem-readarticle.png';
import qna from '../images/QnA.svg';
import followers from '../images/sceem-followers.png';
import following from '../images/sceem-following.png';
import opportunity from '../images/opportunity.png';
import referrals from '../images/referral.svg';
import pollscreated from '../images/sceem-polls created.png';
import pollsparticipate from '../images/sceem-polls participate.png';
import months from '../images/sceem-months.png';
import './Interests.css';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import Popup from "./Popup";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Userscreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            name: "",
            posts: [],
            profilePicture: "",
            role: localStorage.getItem('role'),
            userRole: "",
            followerCount: "",
            followingCount: "",
            likesCount: "",
            monthsActive: "",
            pollAnsweredCount: "",
            pollCreatedCount: "",
            answersCount: "",
            opportunityCount: "",
            questionsCount: "",
            postCount: "",
            referralCount: "",
            userArticleViews: "",
            description: "",
            author: "",
            reviewer: "",
            sme: "",
            disable: false,
            disableAuthor: false,
            disableReviewer: false,
            showPopup: false,
            authorText: "",
            roleAsAuthor: "",
            reviewerText: "",
            roleAsReviewer: "",
            roleAsSme: "",
            smeText: "",
            rewardPoints:'',
            showExpress: false,
            isLoading: true,
            userTopics: [],
            membership: ""
        }
    }
    componentDidMount = () => {
        this.getUserProfile();
        this.checkUserStatus();
        // this.getUserTopics();
    }
    optForReviewer = () => {
        document.getElementById('reviewer').style.display = 'flex'
    }
    showReviewerExpressInterestNone = () => {
        document.getElementById('reviewer').style.display = 'none'
    }
    optForAuthor = () => {
        document.getElementById('author').style.display = 'flex'
    }
    showAuthorExpressInterestNone = () => {
        document.getElementById('author').style.display = 'none'
    }
    postExpressInterest = (e, type) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "interestType": type
        }
        var self = this
        axios.post(config.userUrl + 'user/expressInterest', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    self.checkUserStatus()
                    self.showReviewerExpressInterestNone()
                    self.showAuthorExpressInterestNone()
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });

    };
    checkUserStatus = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/checkUserStatus', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    if (response.data.userStatus === "approvedForAuthor") {
                        self.setState({ author: "Approved", disableReviewer: true, disableSme: true })
                        document.getElementById('true').innerHTML = '<i class="fa fa-check" id="true" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "approvedForReviewer") {
                        self.setState({ reviewer: "Approved", disableAuthor: true, disableSme: true })
                        document.getElementById('true').innerHTML = '<i class="fa fa-check" id="true" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "approvedForSme") {
                        self.setState({ sme: "Approved", disableReviewer: true, disableAuthor: true })
                        document.getElementById('true').innerHTML = '<i class="fa fa-check" id="true" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "authorStatusPending") {
                        self.setState({ author: "Pending", disableReviewer: true, disableSme: true })
                        document.getElementById('pendingAuth').innerHTML = '<i class="fa fa-check" id="pendingAuth" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "reviewerStatusPending") {
                        self.setState({ reviewer: "Pending", disableAuthor: true, disableSme: true })
                        document.getElementById('pendingAuth').innerHTML = '<i class="fa fa-check" id="pendingAuth" aria-hidden="true"></i>'
                    } else if (response.data.userStatus === "smeStatusPending") {
                        self.setState({ sme: "Pending", disableReviewer: true, disableAuthor: true })
                        document.getElementById('pendingAuth').innerHTML = '<i class="fa fa-check" id="pendingAuth" aria-hidden="true"></i>'
                    }
                    else {
                        document.getElementById('status').innerText = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                    }
                } else {
                  //  console.log("checkerror", response.data.message)
                    document.getElementById('status').innerText = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };

    getUserProfile = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getProfile', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    let user = response.data;
                    self.setState(
                        {
                            followerCount: user.followerCount,
                            followingCount: user.followingCount,
                            answersCount: user.totalAnswerCount,
                            questionsCount: user.totalQuestionCount,
                            likesCount: user.likesCount,
                            monthsActive: user.monthsActive,
                            pollAnsweredCount: user.pollAnsweredCount,
                            pollCreatedCount: user.pollCreatedCount,
                            referralCount: user.referralCount,
                            opportunityCount: user.userOpportunityCount,
                            postCount: user.postCount,
                            userArticleViews: user.userArticleViews,
                            description: user.user.description,
                            membership: user.user.merchantType,
                            name:user.user.name,
                            rewardPoints:(Number(response.data.getTotalRewardPointsForIndividual[0].count)),

                        }
                    );
                    localStorage.setItem("planType", user.user.merchantType)
                    if (self.state.role === "generalUser") {
                        self.setState({ userRole: "User" })
                    } else {
                        const data = self.state.role.charAt(0).toUpperCase() + self.state.role.slice(1)
                        self.setState({ userRole: data })
                    }
                    if ((user.user.profilePicture === null) && (user.user.gender === "0")) {
                        self.setState({
                            profilePicture: "uploads/1652942537335Avatar%20Users2_37.png",
                        })
                    } else if ((user.user.profilePicture === null) && (user.user.gender === "1")) {
                        self.setState({
                            profilePicture: "uploads/1652942450250Avatar Users2_31.png",
                        })
                    } else {
                        self.setState({
                            profilePicture: user.user.profilePicture,
                        })
                    }
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    getUserTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllRoleRequests', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    self.setState({ userTopics: response.data.allRequest, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    // getUserProfileInfo = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + this.state.token
    //     }
    //     var self = this
    //     axios.get(config.userUrl + 'user/getUserProfileInfo', { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status == true) {
    //                 console.log("success");
    //                 const user = response.data;
    //                 self.setState({
    //                     posts: user.posts,
    //                     role: user.userRole,
    //                     description: user.description,
    //                     followerCount: user.followerCount,
    //                     followingCount: user.followingCount,
    //                     likeCount: user.likeCount
    //                 })
    //                 if ((user.userDetails.profilePicture == null) && (user.userDetails.gender == "0")) {
    //                     console.log("gender")
    //                     self.setState({
    //                         profilePicture: "../author4.svg",
    //                     })
    //                 } else if ((user.userDetails.profilePicture == null) && (user.userDetails.gender == "1")) {
    //                     self.setState({
    //                         profilePicture: "../author5.svg",
    //                     })
    //                 } else {
    //                     self.setState({
    //                         profilePicture: user.userDetails.profilePicture,
    //                     })
    //                 }
    //                 if (self.state.role === "generalUser") {
    //                     self.setState({ userRole: "User" })
    //                 } else {
    //                     const data = self.state.role.charAt(0).toUpperCase() + self.state.role.slice(1)
    //                     self.setState({ userRole: data })
    //                 }
    //             } else {
    //                 console.log(response.data)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error");
    //         });
    // };
    togglePopup = () => {
        if (this.state.author === "Approved") {
            this.setState({
                showPopup: !this.state.showPopup,
                roleAsAuthor: "Author",
                authorText: " Write articles with your experience and knowledge. It will be reviewed by expert reviewers before publication."
            });
        } else if (this.state.reviewer === "Approved") {
            this.setState({
                showPopup: !this.state.showPopup,
                roleAsReviewer: "Reviewer",
                reviewerText: "Review articles with your knowledge and expertise written by leading authors."
            });
        } else if (this.state.sme === "Approved") {
            this.setState({
                showPopup: !this.state.showPopup,
                roleAsSme: "SME",
                smeText: "Answer questions with your knowledge."
            });
        }
        else {
            this.setState({
                showPopup: !this.state.showPopup,
                roleAsAuthor: "Author",
                authorText: " Write articles with your experience and knowledge. It will be reviewed by expert reviewers before publication.",
                roleAsReviewer: "Reviewer",
                reviewerText: "Review articles with your knowledge and expertise written by leading authors.",
                roleAsSme: "SME",
                smeText: " Answer questions with your experience and knowledge."
            });
        }
      //  console.log("togglePopup")
    }
    toggleInterest = (data) => {
        if (data === "Author") {
            this.setState({
                showExpress: true,
                roleAsAuthor: data,
                authorText: "As an author, contribute to sceem community through conceptualisation, analysis, interpretation of data, in the topic of interest in the form of article. Drafting of the article is based on individual expertise and intellectual content. <br/><br/> Agreement to be accountable for all aspects of the work in the article in ensuring accuracy or integrity of any part of the work are appropriately addressed. Content of article has no commercial or promotional objective."
            });
        } else if (data === "Reviewer") {
            this.setState({
                showExpress: true,
                roleAsAuthor: data,
                authorText: "As a reviewer, contribute to sceem community through reviewing article concept, analysis, interpretation of data based on individual subject matter expertise. Provide constructive and objective feedback while approving or rejecting the article. <br/><br/> Agreement to be accountable for all aspects of the work in the review of article and seek clarifications if necessary related to the accuracy or integrity of any part of the work."
            });
        } else {
            this.setState({
                showExpress: true,
                roleAsAuthor: data,
                authorText: "As an SME (Subject Matter Expert) for 'sceem,' I'm dedicated to empowering through coaching, consulting, mentoring, and networking. Sharing profound insights, I guide individuals to mastery, offering personalized coaching to hone skills and achieve goals. Consulting involves strategic advice rooted in expertise, fostering innovation. Mentoring fosters talent, empowering others to reach full potential. Networking promotes collaboration, advancing our field collectively.<br /><br /> As an SME (Subject Matter Expert) for 'sceem,' I'm dedicated to empowering through coaching, consulting, mentoring, and networking. Sharing profound insights, I guide individuals to mastery, offering personalized coaching to hone skills and achieve goals. Consulting involves strategic advice rooted in expertise, fostering innovation. Mentoring fosters talent, empowering others to reach full potential. Networking promotes collaboration, advancing our field collectively." 
            });
        }
      //  console.log("roleee", data)
    }
    render() {
        const { showPopup, showExpress } = this.state;
        const kFormatter = (num) => {
            return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num)
        }
       // console.log("roleee", this.state.author)
        if (showPopup) {
            return <Popup closePopup={this.togglePopup.bind(this)} data={this.state} />
        }
        if (showExpress) {
            return <Redirect
                to={{
                    pathname: "/express",
                    state: { roleAsAuthor: this.state.roleAsAuthor, authorText: this.state.authorText }
                }} />
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <div className="screen-topgap">
                    <div className="your-jitbits">Profile</div>
                    <center><img alt="" src={config.userUrl + this.state.profilePicture} className=" Auth-img" /></center><Link to="/favauthor" className="edit-icon-mob"><i class="ml-1 fa fa-pencil" aria-hidden="true"></i></Link>
                    <center>
                        <div className="user-Name">{this.state.name} <span className="author-text mb-2" style={{color:"#31197C"}}>({this.state.userRole}, {this.state.membership == 1 ? "Anticipator" : (this.state.membership == 2 ? "Integrator" : (this.state.membership == 3 ? "Collaborator" : "Orchestrator"))})</span></div>
                        {/* <div className="author-text mb-2">{this.state.userRole}</div> */}
                        <div className="author-text ml-5 mr-5" style={{color:"#31197C"}}>{this.state.description}</div>
                        <div className="rewards-earned">Reward Points Earned: <span style={{color:"#31197C"}}>{this.state.rewardPoints}</span></div>
                    </center>

                    <center className="mt-3">
                        <div className="outer-circle-lg">
                            <div className="outer-circle">
                            {/* <div className="rewardPoints"><img src={likes} style={{ width: "28px" }} /><br />{kFormatter(this.state.rewardPoints)}</div> */}
                                <div className="readArticles"><img src={readarticle} style={{ width: "28px" }} /><br />{kFormatter(this.state.userArticleViews)}</div>
                                <div className="followerCount"><img src={followers} style={{ width: "28px" }} /><br />{this.state.followerCount}</div>
                                <div className="followingCount"><img src={following} style={{ width: "28px" }} /><br />{this.state.followingCount}</div>
                                <div className="pollAnsweredCount"><img src={pollsparticipate} style={{ width: "28px" }} /><br />{this.state.pollAnsweredCount}</div>
                                <div className="questionsCount"><img src={qna} style={{ width: "28px" }} /><br />{this.state.questionsCount}</div>
                                <div className="monthsActive"><img src={months} style={{ width: "28px" }} /><br />{this.state.monthsActive}</div>
                            </div>
                            <div className="inner-circle">
                                <img alt="" src={config.userUrl + this.state.profilePicture} className="img-userprof" />
                                <div className="answersCount"><img src={qna} style={{ width: "28px" }} /><br />{this.state.answersCount}</div>
                                <div className="likesCount"><img src={likes} style={{ width: "28px" }} /><br />{this.state.likesCount}</div>
                                <div className="opportunitiesCount"><img src={opportunity} style={{ width: "28px" }} /><br />{this.state.opportunityCount}</div>
                                <div className="postCount"><img src={writearticle} style={{ width: "28px" }} /><br />{this.state.postCount}</div>
                                <div className="pollCreatedCount"><img src={pollscreated} style={{ width: "28px" }} /><br />{this.state.pollCreatedCount}</div>
                                <div className="referralCount"><img src={referrals} style={{ width: "28px" }} /><br />{this.state.referralCount}</div>
                                {/* <div className="likesCount"><img src={likes} style={{ width: "28px" }} /><br />{this.state.likesCount}</div>
                                    <div className="postCount"><img src={writearticle} style={{ width: "28px" }} /><br />{this.state.postCount}</div>
                                    <div className="pollCreatedCount"><img src={pollscreated} style={{ width: "28px" }} /><br />{this.state.pollCreatedCount}</div>
                                    <div className="pollAnsweredCount"><img src={pollsparticipate} style={{ width: "28px" }} /><br />{this.state.pollAnsweredCount}</div>
                                    <div className="followerCount"><img src={followers} style={{ width: "28px" }} /><br />{this.state.followerCount}</div>
                                    <div className="followingCount"><img src={following} style={{ width: "28px" }} /><br />{this.state.followingCount}</div>
                                    <div className="monthsActive"><img src={months} style={{ width: "28px" }} /><br />{this.state.monthsActive}</div>
                                    <div className="readArticles"><img src={readarticle} style={{ width: "28px" }} /><br />{this.state.userArticleViews}</div> */}

                            </div>
                        </div>
                    </center>
                    {/* <div class="circle-new">
                        <div class="tick"></div>
                        <div class="tick"></div>
                        <div class="tick"></div>
                        <div class="tick"></div>
                    </div> */}
                    <br />
                    <center>Express Interest<br />
                        <Button variant="default" className="mt-3 _rolebtn" onClick={() => this.toggleInterest("Author")} disabled={this.state.disableAuthor} >Author <span id={this.state.author === "Pending" ? "pendingAuth" : (this.state.author === "Approved" ? "true" : "")} className={this.state.author === "Approved" ? "showPassword tick-color-green" : (this.state.author === "Pending" ? "showPassword tick-color-amber" : "")}
                        ></span></Button>
                        &nbsp;&nbsp;
                        <Button variant="default" onClick={() => this.toggleInterest("Reviewer")} disabled={this.state.disableReviewer} className="mt-3 _rolebtn" >Reviewer <span id={this.state.reviewer === "Pending" ? "pendingAuth" : (this.state.reviewer === "Approved" ? "true" : "")} className={this.state.reviewer === "Approved" ? "showPassword tick-color-green" : (this.state.reviewer === "Pending" ? "showPassword tick-color-amber" : "")}
                        ></span></Button> &nbsp;
                        <Button variant="default" onClick={() => this.toggleInterest("SME")} disabled={this.state.disableSme} className="mt-3 _rolebtn" >SME <span id={this.state.sme === "Pending" ? "pendingAuth" : (this.state.sme === "Approved" ? "true" : "")} className={this.state.sme === "Approved" ? "showPassword tick-color-green" : (this.state.sme === "Pending" ? "showPassword tick-color-amber" : "")}
                        ></span></Button>
                        <br />
                        <div className="mt-2 to-know-more">To Know more <span onClick={this.togglePopup} className="click">Click here</span></div>
                    </center>
                    <br /><br /><br />
                </div >
                <BottomMenu />
            </div >
        )
    }
}
