import React, { Component } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import loginlogo from '../images/login-logo.png';
import PricingData from "./PricingData.json";
import BottomMenu from "../BottomMenu/BottomMenu";
import './Pricing.css';
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "../LandingPage/arrowsOnBottomOrTop.css";
import "../LandingPage/hideScrollbar.css";
import axios from 'axios';
import { config } from '../Config/Config';
import { Redirect } from 'react-router-dom';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Subscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            planId: "",
            planName: "",
            pName: this.props.location.state.pName || "",
            amount: "",
            plans: [],
            id: "",
            redirect: false,
            upi: false
        }

    }
    componentDidMount() {
        this.getAllPlans();
    }
    getAllPlans = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getPlans', { headers: headers })
            .then(function (response) {
              //  console.log("res", response)
                if (response.data.status === true) {
                   // console.log("downloaded")
                    self.setState({
                        plans: response.data.planData
                    })
                } else {
                    console.log("error")
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    showNone = () => {
        var self = this;
        self.setState({
            redirect: true
        })
        document.getElementById('upgrade').style.display = 'none';

    }
    closeModal() {
        document.getElementById('sameplan').style.display = 'none';
    }
    handleOpenRazorpay = ({ data }) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
      //  console.log(data, "data")
        let options = {
            key: `${process.env.razorPay_key_id}`, // Enter the Key ID generated from the Dashboard    
            amount: data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise     
            currency: data.currency,
            name: "Sceem Payments",
            description: "",
            order_id: data.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1     
            handler: function (response) {
                var payload = {
                    "response": response,
                    "planDuration": data.planDuration,
                    "planName": data.planName,
                    "planAmount": data.amount,
                    "currency": data.currency
                }
               // console.log("a", response, payload)
                var self = this;
                axios.post(config.userUrl + 'user/paymentVerify', payload, { headers: headers })
                    .then((response) => {
                       // console.log(response);
                        if (response.data.message == "you have already purchase this subscription") {
                            document.getElementById('sameplan').style.display = 'flex';
                         //   console.log("Error", payload);
                        } else {
                            localStorage.setItem("merchantType", response.data.merchantType)
                            document.getElementById('upgrade').style.display = 'flex';
                          //  console.log("success", response.data.message, payload);
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                //  getPendingTransactions();
            },
        };
        const rzp = new window.Razorpay(options);
        rzp.open();
    };

    handlePayment = () => {
        this.setState({ upi: true })
        // let headers = {
        //     "Content-Type": 'application/json',
        //     "Authorization": "Bearer " + this.state.token,
        //     "applicationType": "mobile"
        // }
        // var payload = {
        //     "amount": Number(this.state.amount),
        //     "currency": "INR",
        //     "planName": this.state.planName,
        //     "planDuration": "yearly"
        // }
        // axios.post(config.userUrl + 'user/createOrder', payload, { headers: headers })
        //     .then((response) => {
        //         this.handleOpenRazorpay({ data: response.data });
        //     }).catch((error) => {
        //         console.log(error, "error")
        //     });
    };
    // postSubscription = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + this.state.token,
    //         "applicationType": "mobile"
    //     }
    //     var payload = {
    //         "planId": this.state.planId,
    //         "planName": this.state.planName,
    //         "planAmount": parseInt(this.state.amount)
    //     }
    //     console.log("payload", payload)
    //     var self = this
    //     axios.post(config.userUrl + 'user/addSubscription', payload, { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status == true) {
    //                 if (response.data.message == "you have already purchase this subscription") {
    //                     document.getElementById('sameplan').style.display = 'flex';
    //                     console.log("Error", payload);
    //                 } else {
    //                     localStorage.setItem("merchantType", response.data.merchantType)
    //                     document.getElementById('upgrade').style.display = 'flex';
    //                     console.log("success", response.data.message, payload);
    //                     self.setState({
    //                         redirect: true
    //                     })
    //                 }
    //             } else {
    //                 console.log("error")
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("error", error, payload);
    //         });
    // };
    render() {
        const { redirect, upi } = this.state;
        if (redirect) {
            return <Redirect to="/currentpricing" />
        }
        if (upi) {
            return <Redirect
                to={{
                    pathname: "/upidetails",
                    state: { id: this.state.id, planId: this.state.planId, planName: this.state.planName, amount: this.state.amount }
                }} />
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <div className="screen-topgap">
                    <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                        <span className="your-jitbits">Choose your Subscription Plan</span>
                        <div className="pricing-main">
                            <ScrollMenu>
                                <Row style={{ width: "100%" }}>
                                    {this.state.plans.slice(1, 2).map((data, id) => {
                                        return (
                                            <Col xs={10} name="id" onClick={() => this.setState({ id: data.id, planId: data.planId, planName: data.planName, amount: data.planAmount })}>
                                                <Card className={this.state.pName == "Integrator" ? "scroll-pricing-pp pricing-left-right" : "scroll-pricing pricing-left-right"}>
                                                    <center className="free">{data.planName}&nbsp;{this.state.planName == "Integrator" ? (<AiOutlineCheck className="react-icon" />) : ""}<br /></center>
                                                    <div className="mt-3"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Anticipator Plus</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Multiply rewards by 2 times</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Get verified tag</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Play Games</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Register as a SME</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Access to Sceemlore</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Ask a sceemER</span></div>
                                                    <center className="money-color"><span className="mt-1 rupee">&#8377;</span> {data.planAmount}/year</center>
                                                </Card><br />
                                                {/* <div className="start mt-3"><Button variant="default" className="ml-3 get-btn">Get Started</Button></div> */}
                                            </Col>
                                        )
                                    })}
                                </Row>
                                <Row style={{ width: "100%" }}>
                                    {this.state.plans.slice(2, 3).map((data, id) => {
                                        return (
                                            <Col xs={10} name="id" onClick={() => this.setState({ id: data.id, planId: data.planId, planName: data.planName, amount: data.planAmount })}>
                                                <Card className={this.state.pName == "Collaborator" ? "scroll-pricing-pp pricing-left-right" : "scroll-pricing pricing-left-right"}>
                                                    <center className="free">{data.planName}&nbsp;{this.state.planName == "Collaborator" ? (<AiOutlineCheck className="react-icon" />) : ""}<br /></center>
                                                    <div className="mt-3"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Integrator Plus</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Multiply rewards by 2.5 times</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Become an author or reviewer</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Create Polls</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Advertise 6 times/year</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">1 year free entropi registration</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Promote your business on entropi</span></div>
                                                    <center className="money-color"><span className="rupee">&#8377;</span> {data.planAmount}/year</center>
                                                </Card><br />
                                            </Col>
                                        )
                                    })}
                                </Row>
                                <Row style={{ width: "100%" }}>
                                    {this.state.plans.slice(3, 4).map((data, id) => {
                                        return (
                                            <Col xs={10} name="id" onClick={() => this.setState({ id: data.id, planId: data.planId, planName: data.planName, amount: data.planAmount })}>
                                                <Card className={this.state.pName == "Orchestrator" ? "scroll-pricing-pp pricing-left-right" : "scroll-pricing pricing-left-right"}>
                                                    <center className="free">{data.planName}&nbsp;{this.state.planName == "Orchestrator" ? (<AiOutlineCheck className="react-icon" />) : ""}<br /></center>
                                                    <div className="mt-4"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Collaborator Plus</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Multiply rewards by 3 times</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Business consultancy</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Technology consultancy</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Product consultancy</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Complete access to entropi</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Open API integration from entropi</span></div>
                                                    <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">1 year Access to Byrds/FeddUp/Conerqube</span></div>
                                                    <center className="money-color"><span className="rupee">&#8377;</span> {data.planAmount}/year</center>
                                                </Card><br />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </ScrollMenu>
                            {this.state.id !== "" ?
                                <center className="start mt-3"><Button variant="default" className="ml-3 get-btn" onClick={this.handlePayment}>Buy</Button></center> :
                                <center className="start mt-3"><Button variant="default" className="ml-3 get-btn" disabled={true}>Buy</Button></center>}
                            <div id="upgrade" className="modal-pop">
                                <form className="modal-content-log">
                                    <div className="_container">
                                        <p className="mt-2">Payment success!!</p>
                                        <center><Button variant="default" onClick={this.showNone} className="deletebutton">Ok</Button></center>
                                    </div>
                                </form>
                            </div>
                            <div id="sameplan" className="modal-pop">
                                <form className="modal-content-log">
                                    <div className="_container">
                                        <p className="mt-2">You have already purchased this plan.</p>
                                        <center><Button variant="default" onClick={this.closeModal} className="deletebutton">Ok</Button></center>
                                    </div>
                                </form>
                            </div>
                        </div ><br />
                    </div>
                </div >
                < BottomMenu />
            </div >
        )
    }
}
