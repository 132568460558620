import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { Redirect,Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import verifyemail from '../images/verifyemail.png';
import './Login.css';
import axios from 'axios';
import { config } from '../Config/Config';
import { osName, mobileVendor, deviceType, mobileModel } from 'react-device-detect';

export default class VerifyEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.location.state.email,
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            otp6: "",
            sort: false,
            try: false,
            registerFlow: this.props.location.state.registerFlow,
            newPassword: false,
            redirect: false,
            authKey: ""
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount() {
        //console.log("hello", this.props.location.state.email)
    }
    // handleChange(value1, event) {
    //     this.setState({ [value1]: event.target.value });
    // }
    handleChange(value1, event) {
        // Regular expression to allow only numbers
        const regex = /^[0-9\b]+$/;
        // Check if the entered value matches the regular expression
        if (event.target.value === '' || regex.test(event.target.value)) {
            // If it matches, update the state with the entered value
            this.setState({ [value1]: event.target.value });
        }
    }
    handleSubmit(event) {
        const data = new FormData(event.target);
       // console.log(this.state);
        event.preventDefault()
        if (this.props.location.state.name) {
            this.verifyOtp();
        } else if (this.props.location.state.name || this.props.location.state.registerFlow === true) {
            this.verifyOtp();
        }
        else {
            this.verifyForgotPasswordEmail();
        }
    }
    onSubmit(event) {
       // console.log(this.state);
        event.preventDefault()
        this.verifyForgotPasswordEmail();
    }

    verifyForgotPasswordEmail = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var self = this;
        var payload = {
            "email": this.state.email,
            "alternate": false,
            "otp": this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6
        }
        axios.post(config.apiUrl + 'auth/forgotPassword/verifyEmail', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
                if (response.data.status === true) {
                   // console.log("Forgot Password Email verified successfully!!")
                   // console.log("payload", payload, response)
                    self.setState({
                        newPassword: true,
                        authKey: response.data.authkey
                    })
                } else {
                    console.log(response.data.message)
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
                console.log("error", payload,error)
            });
    }
    verifyOtp = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var self = this;
        var payload = {
            "email": this.state.email,
            "otp": this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6
        }
        axios.post(config.apiUrl + 'auth/verifyEmail', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
                if (response.data.status === true) {
                   // console.log("Email verified successfully!!")
                   // console.log("payload", payload)
                    self.setState({
                        redirect: true
                    })
                } else {
                    console.log(response.data.message)
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
                console.log("error", payload,error)
                document.getElementById('status').innerText = error
            });
    }
    resendOtp = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var payload = {
            "email": this.state.email,
            "registerFlow": this.state.registerFlow
        }
        axios.post(config.apiUrl + 'auth/sendOtp', payload, { headers: headers })
            .then(function (response) {
                const msg = "Otp sent successfully!!";
                if (response.data.status === true) {
                    //console.log("Otp sent successfully!!")
                    //console.log("payload", payload)
                    document.getElementById('otp').innerText = msg
                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                console.log("error", payload,error)
            });
    }
    detectDevice = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var payload = {
            "email": this.state.email,
            "deviceType": deviceType,
            "mobileModel": mobileModel,
            "osName": osName,
            "mobileVendor": mobileVendor
        }
        var self = this
        axios.post(config.apiUrl + 'auth/verifyDevice ', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
                if (response.data.status === true) {
                   // console.log("success")
                    self.setState({
                        sort: true
                    })
                  //  console.log("payload", payload)
                } else {
                    document.getElementById('tryanother').innerText = msg
                   // console.log("err", payload)
                }
            })
            .catch(function (error) {
                console.log("error", payload,error)
            });
    }
    // sortNumbers = (e) => {
    //     this.setState({ sort: true })
    // }
    inputfocus = (elmnt) => {
        if (elmnt.key === "Backspace" || elmnt.key === "Delete") {
            const next = elmnt.target.tabIndex - 2;
           // console.log("ext", elmnt.target.tabIndex, next)
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            const next = elmnt.target.tabIndex;
        //    console.log("next", elmnt.target.tabIndex, next);
            if (next < 6) {
                elmnt.target.form.elements[next].focus()
            }
        }

    }

    render() {
        const { newPassword, sort, redirect } = this.state
        if (newPassword) {
            return <Redirect
                to={{
                    pathname: "/newpassword",
                    state: { email: this.state.email, authKey: this.state.authKey }
                }} />
        }
        if (redirect) {
            return <Redirect
                to={{
                    pathname: "/",
                    state: { email: this.state.email }
                }} />
        }
        if (sort) {
            return <Redirect
                to={{
                    pathname: "/alternateverify",
                    state: { email: this.state.email, authKey: this.state.authKey, registerFlow: this.state.registerFlow }
                }} />
        }
        // if (this.props.location.state.name) {
        return (
            <div>
                <div className="top_bar"><Link to="/login"><img alt="" src={loginlogo} className="login-logo" /></Link></div>
                <center> <img alt="" src={verifyemail} className="forgotImg" />
                    <p className="forgot-text">Verify your email</p>
                    <p className="forgot-text-sm">Please check your email to take 6 digit <br />code for continue.</p></center>
                <Form className="forgot-email" onSubmit={this.handleSubmit}>
                    <center>
                        <div style={{ width: "80%" }} className="form-group verify">
                            {/* <input name="otp1"
                                onInput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                type="number" maxlength="1"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp1}
                                onKeyPress={this.keyPressed}
                                onChange={e => this.handleChange("otp1", e)}
                                tabIndex="1" onKeyUp={e => this.inputfocus(e)}
                            /> */}

                            <input
                                name="otp1"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp1}
                                onKeyPress={this.keyPressed}
                                onChange={e => this.handleChange("otp1", e)}
                                tabIndex="1" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                                inputMode="numeric"

                            />
                            <input
                                name="otp2"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp2}
                                onChange={e => this.handleChange("otp2", e)}
                                tabIndex="2" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                                inputMode="numeric"

                            />
                            <input
                                name="otp3"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp3}
                                onChange={e => this.handleChange("otp3", e)}
                                tabIndex="3" maxLength="1" onKeyUp={e => this.inputfocus(e)}
                                inputMode="numeric"

                            />
                            <input
                                name="otp4"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp4}
                                onChange={e => this.handleChange("otp4", e)}
                                tabIndex="4" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                                inputMode="numeric"
                            />

                            <input
                                name="otp5"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp5}
                                onChange={e => this.handleChange("otp5", e)}
                                tabIndex="5" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                                inputMode="numeric"
                            />
                            <input
                                name="otp6"
                                type="text"
                                autoComplete="off"
                                className="form-control digit-width"
                                value={this.state.otp6}
                                onChange={e => this.handleChange("otp6", e)}
                                tabIndex="6" maxLength={1} onKeyUp={e => this.inputfocus(e)}
                                inputMode="numeric"
                            />
                        </div><div id="status" className="errormsg"></div>
                        <br /><br />
                        <Button variant="default" type="submit" className="btn-block login-btn">Verify</Button><br />
                        <p >Did not get the OTP?<span className="try" onClick={this.resendOtp}> Resend OTP</span></p>
                        <div id="otp" className="errormsg"></div>{this.state.registerFlow ?
                            null : <p >Facing issue with OTP?<span className="try" onClick={this.detectDevice}> Try another ways</span></p>}
                        <div id="tryanother" className="errormsg"></div>
                    </center>
                </Form>
            </div>
        );
    }
    // else {
    //     return (
    //         <div>
    //             <img src={loginlogo} className="login-logo" />
    //             <center> <img src={verifyemail} className="forgotImg" />
    //                 <p className="forgot-text">Verify your email</p>
    //                 <p className="forgot-text-sm">Please check your email to take 6 digit <br />code for continue.</p></center>
    //             <Form className="forgot-email" onSubmit={this.onSubmit}>
    //                 <center>
    //                     <div style={{ width: "80%" }} className="form-group verify">

    //                         <input
    //                             name="otp1"
    //                             type="number"
    //                             autoComplete="off"
    //                             className="form-control digit-width"
    //                             value={this.state.otp1}
    //                             onKeyPress={this.keyPressed}
    //                             onChange={e => this.handleChange("otp1", e)}
    //                             tabIndex="1" maxLength="1" onKeyUp={e => this.inputfocus(e)}

    //                         />
    //                         <input
    //                             name="otp2"
    //                             type="number"
    //                             autoComplete="off"
    //                             className="form-control digit-width"
    //                             value={this.state.otp2}
    //                             onChange={e => this.handleChange("otp2", e)}
    //                             tabIndex="2" maxLength="1" onKeyUp={e => this.inputfocus(e)}

    //                         />
    //                         <input
    //                             name="otp3"
    //                             type="number"
    //                             autoComplete="off"
    //                             className="form-control digit-width"
    //                             value={this.state.otp3}
    //                             onChange={e => this.handleChange("otp3", e)}
    //                             tabIndex="3" maxLength="1" onKeyUp={e => this.inputfocus(e)}

    //                         />
    //                         <input
    //                             name="otp4"
    //                             type="number"
    //                             autoComplete="off"
    //                             className="form-control digit-width"
    //                             value={this.state.otp4}
    //                             onChange={e => this.handleChange("otp4", e)}
    //                             tabIndex="4" maxLength="1" onKeyUp={e => this.inputfocus(e)}
    //                         />

    //                         <input
    //                             name="otp5"
    //                             type="number"
    //                             autoComplete="off"
    //                             className="form-control digit-width"
    //                             value={this.state.otp5}
    //                             onChange={e => this.handleChange("otp5", e)}
    //                             tabIndex="5" maxLength="1" onKeyUp={e => this.inputfocus(e)}
    //                         />
    //                         <input
    //                             name="otp6"
    //                             type="number"
    //                             autoComplete="off"
    //                             className="form-control digit-width"
    //                             value={this.state.otp6}
    //                             onChange={e => this.handleChange("otp6", e)}
    //                             tabIndex="6" maxLength="1" onKeyUp={e => this.inputfocus(e)}
    //                         /><br />

    //                     </div><div id="status" className="errormsg"></div><br /><br />

    //                     <Button variant="default" type="submit" className="btn-block login-btn">Verify</Button><br />
    //                     <p >Did not get the OTP?<span className="try" onClick={this.resendOtp}> Resend OTP</span></p>
    //                     <div id="otp" className="errormsg"></div>
    //                 </center>
    //             </Form>
    //         </div>
    //     );
    // }
    //}
}

    //     constructor(props) {
    //         super(props);
    //         this.state = {
    //             emailId: ""
    //         }
    //     }
    //     // onChange = (e) => {
    //     //     this.setState({ [e.target.name]: e.target.value })
    //     // }
    //     handleChange = (e) => {
    //         const { maxLength, value, name } = e.target;
    //         const [fieldName, fieldIndex] = name.split("-");
    //         let fieldIntIndex = parseInt(fieldIndex, 6);
    //         // Check if no of char in field == maxlength
    //         if (value.length >= maxLength) {
    //             // It should not be last input field
    //             if (fieldIntIndex < 6) {
    //                 // Get the next input field using it's name
    //                 const nextfield = document.querySelector(
    //                     `input[name=field-${fieldIntIndex + 1}]`
    //                 );
    //                 // If found, focus the next field
    //                 if (nextfield !== null) {
    //                     nextfield.focus();
    //                 }
    //             }
    //         }
    //         if (value.length <= maxLength) {
    //             // It should not be last input field
    //             if (fieldIntIndex <= 6) {
    //                 // Get the next input field using it's name
    //                 const prevField = document.querySelector(
    //                     `input[name=field-${fieldIntIndex - 1}]`
    //                 );
    //                 // If found, focus the next field
    //                 if (prevField !== null) {
    //                     prevField.focus();
    //                 }
    //             }
    //         }
    //     }
    //     render() {
    //         return (
    //             <div>
    //                 <img src={loginlogo} className="login-logo" />
    //                 <center> <img src={verifyemail} className="forgotImg" />
    //                     <p className="forgot-text">Verify your email</p>
    //                     <p className="forgot-text-sm">Please check your email to take 6 digit <br />code for continue.</p></center>
    //                 <Form className="forgot-email">
    //                     <center>
    //                         <div style={{ width: "80%" }}>
    //                             <div className="form-group verify">
    //                                 <InputField name="field-1" length="1"
    //                                     handleChange={this.handleChange} />
    //                                 <InputField name="field-2" length="1"
    //                                     handleChange={this.handleChange} />
    //                                 <InputField name="field-3" length="1"
    //                                     handleChange={this.handleChange} />
    //                                 <InputField name="field-4" length="1"
    //                                     handleChange={this.handleChange} />
    //                                 <InputField name="field-5" length="1"
    //                                     handleChange={this.handleChange} />
    //                                 <InputField name="field-6" length="1"
    //                                     handleChange={this.handleChange} />
    //                                 {/* <input type="number"
    //                                     name="1"
    //                                     className="form-control digit-width"
    //                                     maxLength={1}
    //                                     onChange={this.onChange}
    //                                 />
    //                                 <input type="number"
    //                                     name="2"
    //                                     maxLength="1"
    //                                     className="form-control digit-width"
    //                                     onChange={this.onChange}
    //                                 />
    //                                 <input type="number"
    //                                     name="3"
    //                                     maxLength="1"
    //                                     className="form-control digit-width"
    //                                     onChange={this.onChange}
    //                                 />
    //                                 <input type="number"
    //                                     name="4"
    //                                     maxLength="1"
    //                                     className="form-control digit-width"
    //                                     onChange={this.onChange}
    //                                 />
    //                                 <input type="number"
    //                                     name="5"
    //                                     maxLength="1"
    //                                     className="form-control digit-width"
    //                                     onChange={this.onChange}
    //                                 />
    //                                 <input type="number"
    //                                     name="6"
    //                                     maxLength={1}
    //                                     className="form-control digit-width"
    //                                     onChange={this.onChange}
    //                                 /> */}
    //                             </div>
    //                         </div><br /><br />
    //                         <Link to="/newpassword"> <Button variant="default" className="btn-block login-btn">Verify</Button></Link><br />
    //                         <p >Did not get the code?<span className="try">Resend code</span></p>
    //                     </center>
    //                 </Form>
    //             </div>
    //         );
    //     }
    // }

    // class InputField extends React.Component {
    //     render() {
    //         return (
    //             <input
    //                 type="text"
    //                 className="form-control digit-width"
    //                 name={this.props.name}
    //                 maxLength={this.props.length}
    //                 onChange={this.props.handleChange}
    //             ></input>
    //         );
    //     }
