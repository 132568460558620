import React from 'react'
import { useState, useEffect } from 'react';
import { Button, Card, FormControl } from "react-bootstrap";
import loginlogo from '../images/login-logo.png';
import profile from '../images/profilepicture.svg';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import './JitBits.css';
import { Link } from 'react-router-dom';
import back from '../images/backarrow.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function SearchResults(props) {
    const [user, setUser] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [userFollowedAuthors,setUserFollowedAuthors]=useState([])
    const [isFirstTime, setIsFirstTime] = useState(false)
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [profilePicture, setProfilePicture] = useState()
    const [followUserId, setFollowUserId] = useState("")
    const [follow, setFollow] = useState(true)
    const [followersCount, setFollowersCount] = useState()
    const [followers, setFollowers] = useState([])
    useEffect(() => {
        getUserProfile()
        getUserFollowedAuthors()
        //console.log("props", props.location.state)
    }, [])
    const getUserProfile = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "uuid": props.location.state.id
        }
        axios.post(config.userUrl + 'user/getProfileById', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    let user = response.data.user;
                   // console.log("user", user)
                    setFollowUserId(props.location.state.id)
                    setUser(user)
                    setFollow(response.data.follow)
                    setFollowers(response.data.followers)
                    setFollowersCount(response.data.followersCount[0]?.followerscount)
                    setIsLoading(false)
                    if ((user.profilePicture === null) && (user.gender === "0")) {
                        setProfilePicture("uploads/1652942537335Avatar%20Users2_37.png")
                    } else if ((user.profilePicture === null) && (user.gender === "1")) {
                        setProfilePicture("uploads/1652942450250Avatar Users2_31.png")
                    } else {
                        setProfilePicture(user.profilePicture)
                    }
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    // const followUsers = () => {
    //     console.log("user follow")
    //     // setFollow(!follow)
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + token,
    //         "applicationType": "mobile"
    //     }
    //     var payload = {
    //         "followUserIds": [followUserId]
    //     }
    //     axios.post(config.userUrl + 'user/followUsers', payload, { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status === true) {
    //                 console.log("success", payload, response);
    //                 // setFollow(!follow)
    //                 getUserProfile()
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error");
    //         });
    // }
    // const unfollowUsers = () => {
    //     console.log("user unfollow")
    //     // setFollow(!follow)
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + token,
    //         "applicationType": "mobile"
    //     }
    //     var payload = {
    //         "followUserIds": [followUserId]
    //     }
    //     axios.post(config.userUrl + 'user/unfollowUsers', payload, { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status === true) {
    //                 console.log("success", payload, response);
    //                 // setFollow(!follow)
    //                 getUserProfile()
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error");
    //         });
    // }
    const followAuthors = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        // const { authors, searchResults } = this.state;
        // let userArr = [...authors, ...searchResults];
        // let authorId = [];
        // authorId = userArr.filter(item => (item.isAdded === true) ? item.uuid : null)
        // console.log("authorId", authorId)
        let authorId = [{...data, isAdded: !data.isAdded}]
        var payload = {
            "authorIds": [data.uuid],
            "isFirstTime":isFirstTime // isFirstTime
        }
        var self = this
        const exp = new Date()
        // if (newUser) {
        //     var newUserArr = [];
        //     for (var i = 0; i < authorId.length; i++) {
        //         newUserArr[i] = ({ uuid: authorId[i].uuid, profilePicture: authorId[i].profilePicture, name: authorId[i].name, isAdded: !(authorId[i].isAdded) })
        //     }
        //     var item = {
        //         "latestUserFollowedAuthors": JSON.stringify(newUserArr),
        //         "expiry": exp.getTime() + 60 * 60 * 100
        //     }
        //     console.log("new", item)
        //     localStorage.setItem("getUserFollowedAuthors", JSON.stringify(item))
        // }
        axios.post(config.userUrl + 'user/followAuthors', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status === true) {
                  //  console.log("success", payload);
                    var localArr = localStorage.getItem("getUserFollowedAuthors")
                    var newLocalArr = JSON.parse(localArr)
                    var newAuthorArr = JSON.parse(newLocalArr.latestUserFollowedAuthors)
                    var authArr = [];
                    for (var i = 0; i < authorId.length; i++) {
                        authArr[i] = ({ uuid: authorId[i].uuid, profilePicture: authorId[i].profilePicture, name: authorId[i].name, isAdded: !(authorId[i].isAdded) })
                    }
                  //  console.log("newLocalArr", authorId, newLocalArr, authArr)
                    var userFollowedArr = newAuthorArr.concat(authArr)
                   // console.log("userArr", userFollowedArr)
                    var authorArray = userFollowedArr.filter((e, i, a) => a.findIndex(e2 => (e2.name === e.name)) === i)
                  //  console.log("userFollowedArr", userFollowedArr, authorArray)
                    var item = {
                        "latestUserFollowedAuthors": JSON.stringify(authorArray),
                        "expiry": exp.getTime() + 60 * 60 * 100
                    }
                  //  console.log("new", item)
                    localStorage.setItem("getUserFollowedAuthors", JSON.stringify(item))
                    getUserProfile()
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    const unfollowAuthors = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        let authorId = [];
        authorId = userFollowedAuthors.filter(item => (item.isAdded === true) ? item.uuid : null)

        var newUserFollowedAuthors = userFollowedAuthors.filter(item => (item.isAdded === true) ? null : item.uuid)
       // console.log("authorId", newUserFollowedAuthors, authorId)
        var payload = {
            "authorIds": [data.uuid],
            "isFirstTime": false
        }
        const exp = new Date()
        axios.post(config.userUrl + 'user/unfollowAuthors', payload, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                   // console.log("success", payload);
                    // self.getUserFollowedAuthors();
                    // var item = {
                    //     "latestUserFollowedAuthors": JSON.stringify(userFollowedAuthors),
                    //     "expiry": exp.getTime() + 60 * 60 * 100
                    // }
                    // localStorage.setItem("getUserFollowedAuthors", JSON.stringify(item))
                    // console.log("Json", JSON.stringify(item))

                    var item = {
                        "latestUserFollowedAuthors": JSON.stringify(newUserFollowedAuthors),
                        "expiry": exp.getTime() + 60 * 60 * 100
                    }
                    localStorage.setItem("getUserFollowedAuthors", JSON.stringify(item))
                   // console.log("new", JSON.stringify(item))
                    setUserFollowedAuthors(newUserFollowedAuthors)
                    getUserProfile()
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getUserFollowedAuthors = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var localFollowers = localStorage.getItem('getUserFollowedAuthors')
      //  console.log("local", localFollowers)
        var followersData = JSON.parse(localFollowers)
       // console.log("followers", !followersData.latestUserFollowedAuthors[0].isAdded)
        const exp = new Date()
        if ((localFollowers === null) || (exp.getTime() > followersData.expiry)) {
            var self = this
            axios.get(config.userUrl + 'user/getUserFollowedAuthors', { headers: headers })
                .then(function (response) {
                 //   console.log(response);
                    if (response.data.status === true) {
                    //    console.log("getUserFollowedAuthors");
                        var item = {
                            "latestUserFollowedAuthors": JSON.stringify(response.data.getUserFollowedAuthors),
                            "expiry": exp.getTime() + 60 * 60 * 100
                        }
                        localStorage.setItem("getUserFollowedAuthors", JSON.stringify(item))
                     //   console.log("Json", JSON.stringify(item))
                       setUserFollowedAuthors(response.data.userFollowedPeople)
                        if (response.data.userFollowedPeople.length === 0) {
                         //   console.log("FIRSTTIME USER")
                            setIsFirstTime(true)
                        } else {
                            console.log("USER")
                        }
                    }
                })
                .catch(function (error) {
                    console.log("Error");
                });
        } else {
           // console.log("follow")
         setUserFollowedAuthors(JSON.parse(followersData.latestUserFollowedAuthors))
        }
    };
    if (isLoading) {
        return <div></div>
    }
    return (

        <div>
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="screen-topgap">
                {/* <div className="your-jitbits">Search Results for "{user?.name}"</div> */}
                <Link to="/authors/recommended"><img alt="" src={back} className="back_arrow-user" /></Link>
                <div className="mt-2 ml-3 mr-3" style={{ border: "1px solid #31197c", borderRadius: "10px" }}>
                    <center className="mt-4"><img alt="" src={config.userUrl + profilePicture} className="profile-Auth-img" /></center>
                    <center>
                        <div className="mt-1 user-Name">{user?.name} <br /><span className="author-text mb-2" style={{ color: "#31197C" }}>({user.userRoleId == 1 ? "Admin" : user.userRoleId == 2 ? "General User" : user.userRoleId == 3 ? "Author" : user.userRoleId == 4 ? "Reviewer" : "SME"}, {user.merchantType == 1 ? "Anticipator" : (user.merchantType == 2 ? "Integrator" : (user.merchantType == 3 ? "Collaborator" : "Orchestrator"))})</span></div>
                        <div className="mt-1 author-text ml-5 mr-5" style={{ color: "#31197C" }}>{user?.description}</div>
                        <div className="mt-3 mb-2 rewards-earned">{user?.fourEs}</div>
                        {follow ?
                            <div>
                                <div style={{ fontSize: "12px" }}>UnFollow</div>
                                <i class="fa fa-minus-circle fa-lg" style={{ color: "#31197C" }} onClick={() => unfollowAuthors(user)} aria-hidden="true"></i>
                                <div>{followersCount} {followersCount == 1 ? "Follower" : "Followers"}</div>
                            </div>
                            :
                            <div><div style={{ fontSize: "12px" }}>Follow</div>
                                <i class="fa fa-plus-circle fa-lg" style={{ color: "#31197C" }} onClick={() => followAuthors(user)} aria-hidden="true"></i>
                                <div>{followersCount} {followersCount == 1 ? "Follower" : "Followers"}</div>
                            </div>
                        }<br />
                    </center>
                    {followers.slice(0, 4).map((data, i) =>
                        <Card className="ml-2 mr-2 mb-1">
                            <div className="ml-2 mr-2" style={{ display: "flex" }}><img style={{ width: "45px", padding: "2px 4px" }} src={(data.profilePicture === "" || data.profilePicture === null) ? profile : config.userUrl + data.profilePicture} />&nbsp;<span className="mt-2">{data.name}</span></div>
                        </Card>
                    )}
                    {/* {followers.length > 4 ? <div className="ml-3">...</div> : ""} */}
                    {followers.length <= 4 ?
                        <div style={{display:"none"}}>
                            <div className='clients-cir-text'>- {followers.length - 4} more</div>
                        </div>
                        : <div>
                            <div className='ml-3 clients-cir-text'>+ {followers.length - 4} more</div>
                        </div>
                    }
                    {/* <div className="mt-2 j-date mb-2">
                    <div><b>Joined On:</b> {moment(createdAt).format('DD-MM-YYYY')}</div>
                    <div><b>Last Login:</b> {moment(updatedAt).format('DD-MM-YYYY')}</div>
                </div> */}
                </div>

            </div>
            <div className="mb-bottom"></div>
            <BottomMenu />
        </div>

    )
}
