
import React, { useState, useEffect } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Link, NavLink } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import '../JitBits/JitBits.css';
import author from '../images/404.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default function Leaderboard() {
    const [leaderboard, setLeaderboard] = useState([
        // { "id": 1, "rank": 1, "name": "abc", "rewardPoints": 1000 },
        // { "id": 2, "rank": 2, "name": "qwerty", "rewardPoints": 900 },
        // { "id": 3, "rank": 3, "name": "xyz", "rewardPoints": 700 },
        // { "id": 4, "rank": 4, "name": "pqr", "rewardPoints": 600 },
        // { "id": 5, "rank": 5, "name": "mno", "rewardPoints": 300 },
        // { "id": 1, "rank": 1, "name": "abc", "rewardPoints": 1000 },
        // { "id": 2, "rank": 2, "name": "qwerty", "rewardPoints": 900 },
        // { "id": 3, "rank": 3, "name": "xyz", "rewardPoints": 700 },
        // { "id": 4, "rank": 4, "name": "pqr", "rewardPoints": 600 },
        // { "id": 5, "rank": 5, "name": "mno", "rewardPoints": 300 },     { "id": 1, "rank": 1, "name": "abc", "rewardPoints": 1000 },
        // { "id": 2, "rank": 2, "name": "qwerty", "rewardPoints": 900 },
        // { "id": 3, "rank": 3, "name": "xyz", "rewardPoints": 700 },
        // { "id": 4, "rank": 4, "name": "pqr", "rewardPoints": 600 },
        // { "id": 5, "rank": 5, "name": "mno", "rewardPoints": 300 },
    ])
    const [userPosition, setUserPosition] = useState()
    const [rewardPoints, setRewardPoints] = useState()
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        getLeaderboard();
    }, []);

    const getLeaderboard = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getTopTenRank', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setLeaderboard(response.data.topRank)
                    setUserPosition(response.data.userPosition[0].userRank)
                    setRewardPoints(response.data.userPosition[0].rewardPoints)
                    setIsLoading(false)
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    // if (isLoading) {
    //     console.debug("renders: Loading...");
    //     return <div className="App"></div>;
    // }
    return (

        <div >
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="leaderboard-topgap">
                {/* <div className="your-jitbits">Leaderboard <span style={{float:"right",marginRight:"18px"}}><Link to="/rewardshistory">Redeemed Rewards</Link></span></div>
                 */}
                {/* <div style={{ marginTop: "6.5rem" }}>
                </div> */}
                {/* <div className="current-poll-fixed">
                    <div style={{ marginTop: "10px", display: "flex" }}>
                        <NavLink to="/history/leaderboard" activeClassName="poll-active" className="ml-4 link" >
                            <div className="currentpoll-Text ml-1 mr-1">Leaderboard</div></NavLink>
                        <NavLink to="/history/rewardshistory" activeClassName="poll-active" className="poll-rec-left link" >
                            <div className="currentpoll-Text ml-1 mr-2">Redeemed Rewards </div></NavLink>
                    </div >
                </div><br /> */}
                {/* <div className="newMembers-fixed">
                    <div style={{ marginTop: "13px", display: "flex", marginLeft: "15px" }}>
                        <NavLink to="/history/leaderboard" activeClassName="profile-active" className="ml-2 link" >
                            <div className="followers-Text ml-1 mr-1">Leaderboard </div></NavLink>
                        <NavLink to="/history/rewardshistory" activeClassName="profile-active" className="rec-left link" >
                            <div className="followers-Text ml-1 mr-1">Claims History</div></NavLink>

                    </div>
                </div> */}

                <div className="ml-4 mr-4 lb-entire-card">
                    <div style={{ fontSize: "16px", marginTop: "-5px", fontWeight: 600, fontFamily: "Noto Sans", color: "#FF942C" }}><center>Leaderboard</center></div>
                    <Row style={{ width: "100%" }} className="mt-1 no-gutters">
                        <Col xs={4}>
                            {leaderboard.slice(1, 2).map((data, i) => (
                                <div>
                                    <div className="bg-rank2 rank-2">2</div>
                                    <center className="mt-4">
                                        <img src={data.profilePicture === null ? author : config.userUrl + data.profilePicture} style={{ borderRadius: "50%", width: "55px" }} />
                                        <div style={{ fontSize: "14px", fontWeight: 600, fontFamily: "Noto Sans" }}>{data.name}</div>
                                        <div style={{ fontSize: "13px", fontWeight: 600, fontFamily: "Noto Sans", marginTop: "-3px" }}>{data.rewardPoints}</div>
                                    </center>
                                </div>
                            ))}
                        </Col>

                        <Col xs={4} style={{ marginTop: "-5px" }}>
                            {leaderboard.slice(0, 1).map((data, i) => (
                                <div>
                                    <div className="bg-rank1 rank-1">1</div>
                                    <center>
                                        <img src={data.profilePicture === null ? author : config.userUrl + data.profilePicture} style={{ borderRadius: "50%", width: "75px" }} />
                                        <div style={{ fontSize: "14px", fontWeight: 600, fontFamily: "Noto Sans" }}>{data.name}</div>
                                        <div style={{ fontSize: "13px", fontWeight: 600, fontFamily: "Noto Sans", marginTop: "-3px" }}>{data.rewardPoints}</div>
                                    </center>
                                </div>
                            ))}
                        </Col>
                        <Col xs={4}>
                            {leaderboard.slice(2, 3).map((data, i) => (
                                <div>
                                    <div className="bg-rank3 rank-3">3</div>
                                    <center className="mt-4">
                                        <img src={data.profilePicture === null ? author : config.userUrl + data.profilePicture} style={{ borderRadius: "50%", width: "55px" }} />
                                        <div style={{ fontSize: "14px", fontWeight: 600, fontFamily: "Noto Sans" }}>{data.name}</div>
                                        <div style={{ fontSize: "13px", fontWeight: 600, fontFamily: "Noto Sans", marginTop: "-3px" }}>{data.rewardPoints}</div>
                                    </center>
                                </div>
                            ))}
                        </Col>
                    </Row>
                    <div className="lb-card" >
                        <div>{leaderboard.slice(3, 10).map((data, i) => (
                            <Row style={{ width: "100%" }} className="no-gutters">
                                <Col xs={3}>
                                    <img src={data.profilePicture === null ? author : config.userUrl + data.profilePicture} style={{ marginLeft: "5px", width: "45px" }} />
                                </Col>
                                <Col xs={7}>
                                    <div style={{ textAlign: "left" }}>
                                        <div style={{ fontSize: "14px", fontWeight: 600, fontFamily: "Noto Sans" }}>{data.name}</div>
                                        <div style={{ fontSize: "12px", fontFamily: "Noto Sans", marginTop: "-3px" }}>{data.rewardPoints}</div>
                                    </div>
                                </Col>
                                <Col xs={2}>
                                    <div className="ml-3 rank-4">{data.userRank}</div>
                                </Col>
                            </Row>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
            <div> <div style={{ marginLeft: "21px", color: "#ff942c", fontWeight: "600" }}>Your Rank: {userPosition} <span className="ml-4" style={{ marginRight: "21px", float: "right" }}>{rewardPoints}</span></div> </div>

            <div>
                <Link to="/rewardpointshistory">
                    <Button variant="default" className="abc" style={{ marginTop: "2px", marginLeft: "21px", float: "left", background: "#31197C", color: "white", fontFamily: "Noto Sans" }}>View Details</Button>
                </Link>
            </div><div>
                <Link to="/redeem">
                    <Button variant="default" style={{ marginTop: "2px", marginRight: "21px", float: "right", background: "#31197C", color: "white", fontFamily: "Noto Sans" }}>Redeem</Button>
                </Link>
            </div>
            <div className="mb-bottom"></div>
            <BottomMenu />
        </div >
    )
}
{/* <div className="ml-4" >
                    <div>
                        {leaderboard.length === 0 ? <center><h4>No Data.</h4></center> :
                            <div class="mt-3 tableFixed" >
                                <table class="table-striped" >
                                    <thead style={{ background: "#eee" }}>
                                        <tr  >
                                            <th scope="col" className="th-heading" style={{ width: "15%" }}>Rank</th>
                                            <th scope="col" className="th-heading" style={{ width: "50%" }}>Name</th>
                                            <th scope="col" className="th-heading" style={{ width: "35%" }}>Reward Points</th>
                                        </tr>
                                    </thead>
                                    <tbody>{leaderboard.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text">{data.userRank}</td>
                                            <td className="td-text">{data.name}</td>
                                            <td className="td-text">{data.rewardPoints}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div> */}