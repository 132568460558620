import React, { Component } from "react";
import { Button, Navbar, Nav, Form, Container, Col, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import procurement from '../images/procurement.png';
import logistics from '../images/logistics.png';
import assets from '../images/assets.png';
import operations from '../images/operations.png';
import infotechnology from '../images/info-technology.png';
import crm from '../images/crm.png';
import erp from '../images/erp.png';
import payments from '../images/payments&financing.png';
import sourcing from '../images/sourcing.png';
import vendormanagement from '../images/vendormanagement.png';
import contactmanagement from '../images/contactmanagement.png';
import inventorycontrol from '../images/inventorycontrol.png';
import demandplanning from '../images/demandplanning.png';
import compliance from '../images/compliance.png';
import regulations from '../images/regulations.png';
import internationaltrade from '../images/internationaltrade.png';
import strategicsourcing from '../images/strategicsourcing.png';
import disposalmanagement from '../images/disposalmanagement.png';
import wastemanagement from '../images/wastemanagement.png';
import sustainablegrowth from '../images/sustainablegrowth.png';
import models from '../images/models.png';
import procurementg from '../images/procurement-g.png';
import logisticsg from '../images/logistics-g.png';
import assetsg from '../images/assets-g.png';
import operationsg from '../images/operations-g.png';
import infotechnologyg from '../images/info-technology-g.png';
import crmg from '../images/crm-g.png';
import erpg from '../images/erp-g.png';
import paymentsg from '../images/payments&financing-g.png';
import sourcingg from '../images/sourcing-g.png';
import vendormanagementg from '../images/vendormanagement-g.png';
import contactmanagementg from '../images/contactmanagement-g.png';
import inventorycontrolg from '../images/inventorycontrol-g.png';
import demandplanningg from '../images/demandplanning-g.png';
import complianceg from '../images/compliance-g.png';
import regulationsg from '../images/regulations-g.png';
import internationaltradeg from '../images/internationaltrade-g.png';
import strategicsourcingg from '../images/strategicsourcing-g.png';
import disposalmanagementg from '../images/disposalmanagement-g.png';
import wastemanagementg from '../images/wastemanagement-g.png';
import sustainablegrowthg from '../images/sustainablegrowth-g.png';
import modelsg from '../images/models-g.png';
import './Poll.css';
import BottomMenu from "../BottomMenu/BottomMenu";

export default class PollTopic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            procurement: false,
            logistics: false,
            assets: false,
            operations: false,
            infotechnology: false,
            crm: false,
            erp: false,
            payments: false,
            sourcing: false,
            vendormanagement: false,
            contactmanagement: false,
            inventorycontrol: false,
            demandplanning: false,
            compliance: false,
            regulations: false,
            internationaltrade: false,
            strategicsourcing: false,
            disposalmanagement: false,
            wastemanagement: false,
            sustainablegrowth: false,
            models: false
        }

    }
    showProcurement = (e) => {
       // console.log(this.state.procurement)
        this.setState({ procurement: !this.state.procurement })
    }
    showLogistics = () => {
        this.setState({ logistics: !this.state.logistics })
    }
    showAssets = () => {
        this.setState({ assets: !this.state.assets })
    }
    showOperations = () => {
        this.setState({ operations: !this.state.operations })
    }
    showInformationTechnology = () => {
        this.setState({ infotechnology: !this.state.infotechnology })
    }
    showCrm = () => {
        this.setState({ crm: !this.state.crm })
    }
    showErp = () => {
        this.setState({ erp: !this.state.erp })
    }
    showPaymentsFinancing = () => {
        this.setState({ payments: !this.state.payments })
    }
    showSourcing = () => {
        this.setState({ sourcing: !this.state.sourcing })
    }
    showVendorManagement = () => {
        this.setState({ vendormanagement: !this.state.vendormanagement })
    }
    showContactManagement = () => {
        this.setState({ contactmanagement: !this.state.contactmanagement })
    }
    showInventoryControl = () => {
        this.setState({ inventorycontrol: !this.state.inventorycontrol })
    }
    showDemandPlanning = () => {
        this.setState({ demandplanning: !this.state.demandplanning })
    }
    showCompliance = () => {
        this.setState({ compliance: !this.state.compliance })
    }
    showRegulations = () => {
        this.setState({ regulations: !this.state.regulations })
    }
    showInternationalTrade = () => {
        this.setState({ internationaltrade: !this.state.internationaltrade })
    }
    showStrategicSourcing = () => {
        this.setState({ strategicsourcing: !this.state.strategicsourcing })
    }
    showDisposalManagement = () => {
        this.setState({ disposalmanagement: !this.state.disposalmanagement })
    }
    showWasteManagement = () => {
        this.setState({ wastemanagement: !this.state.wastemanagement })
    }
    showSustainableGrowth = () => {
        this.setState({ sustainablegrowth: !this.state.sustainablegrowth })
    }
    showModels = () => {
        this.setState({ models: !this.state.models })
    }
    render() {
        return (
            <div>
                <div className="top_bar"><img src={loginlogo} className="login-logo mt-2" /></div>
                <p className="latest-news" style={{ marginTop: "6.3rem" }}>Select your Poll topic</p>
                <center>
                    <div>
                        <Container className="mt-4">
                            <Row style={{ width: "100%" }}>
                                <Col xs={4} onClick={this.showProcurement}><input type="checkbox" name="procurement" value="procurement" checked className={this.state.procurement ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.procurement ? procurement : procurementg} className="procurementg" />
                                    <p className="text">Procurement</p></Col>
                                <Col xs={4} onClick={this.showLogistics}><input type="checkbox" name="logistics" value="logistics" checked className={this.state.logistics ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.logistics ? logistics : logisticsg} className="procurementg" />
                                    <p className="text">Logistics</p></Col>
                                <Col xs={4} onClick={this.showAssets}><input type="checkbox" name="logistics" value="assets" checked className={this.state.assets ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.assets ? assets : assetsg} className="procurementg" />
                                    <p className="text">Assets</p></Col>
                                <div className="_vl"></div> <div className="_vl-1"></div>
                                <hr className="_hr" />
                                <Col xs={4} onClick={this.showOperations}><input type="checkbox" checked className={this.state.operations ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.operations ? operations : operationsg} className="procurementg" />
                                    <p className="text">Operations</p></Col>
                                <Col xs={4} onClick={this.showInformationTechnology}><input type="checkbox" checked className={this.state.infotechnology ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.infotechnology ? infotechnology : infotechnologyg} className="procurementg" />
                                    <p className="text">Information Technology</p></Col>
                                <Col xs={4} onClick={this.showCrm}><input type="checkbox" checked className={this.state.crm ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.crm ? crm : crmg} className="procurementg" />
                                    <p className="text">CRM</p></Col>
                                <hr className="_hr" />
                                <Col xs={4} onClick={this.showErp}><input type="checkbox" checked className={this.state.erp ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.erp ? erp : erpg} className="procurementg" />
                                    <p className="text">ERP</p></Col>
                                <Col xs={4} onClick={this.showPaymentsFinancing}><input type="checkbox" checked className={this.state.payments ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.payments ? payments : paymentsg} className="procurementg" />
                                    <p className="text">Payments & Financing</p></Col>
                                <Col xs={4} onClick={this.showSourcing}><input type="checkbox" checked className={this.state.sourcing ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.sourcing ? sourcing : sourcingg} className="procurementg" />
                                    <p className="text">Sourcing</p></Col>
                                <hr className="_hr" />
                                <Col xs={4} onClick={this.showVendorManagement}><input type="checkbox" checked className={this.state.vendormanagement ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.vendormanagement ? vendormanagement : vendormanagementg} className="procurementg" />
                                    <p className="text">Vendor Management</p></Col>
                                <Col xs={4} onClick={this.showContactManagement}><input type="checkbox" checked className={this.state.contactmanagement ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.contactmanagement ? contactmanagement : contactmanagementg} className="procurementg" />
                                    <p className="text">Contact Management</p></Col>
                                <Col xs={4} onClick={this.showInventoryControl}><input type="checkbox" checked className={this.state.inventorycontrol ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.inventorycontrol ? inventorycontrol : inventorycontrolg} className="procurementg" />
                                    <p className="text">Inventory Control</p></Col>
                                <hr className="_hr" />
                                <Col xs={4} onClick={this.showDemandPlanning}><input type="checkbox" checked className={this.state.demandplanning ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.demandplanning ? demandplanning : demandplanningg} className="procurementg" />
                                    <p className="text">Demand Planning</p></Col>
                                <Col xs={4} onClick={this.showCompliance}><input type="checkbox" checked className={this.state.compliance ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.compliance ? compliance : complianceg} className="procurementg" />
                                    <p className="text">Compliance</p></Col>
                                <Col xs={4} onClick={this.showRegulations}><input type="checkbox" checked className={this.state.regulations ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.regulations ? regulations : regulationsg} className="procurementg" />
                                    <p className="text">Regulations</p></Col>
                                <hr className="_hr" />
                                <Col xs={4} onClick={this.showInternationalTrade}><input type="checkbox" checked className={this.state.internationaltrade ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.internationaltrade ? internationaltrade : internationaltradeg} className="procurementg" />
                                    <p className="text">International Trade</p></Col>
                                <Col xs={4} onClick={this.showStrategicSourcing}><input type="checkbox" checked className={this.state.strategicsourcing ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.strategicsourcing ? strategicsourcing : strategicsourcingg} className="procurementg" />
                                    <p className="text">Strategic Sourcing</p></Col>
                                <Col xs={4} onClick={this.showDisposalManagement}><input type="checkbox" checked className={this.state.disposalmanagement ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.disposalmanagement ? disposalmanagement : disposalmanagementg} className="procurementg" />
                                    <p className="text">Disposal Management</p></Col>
                                <hr className="_hr" />
                                <Col xs={4} onClick={this.showWasteManagement}><input type="checkbox" checked className={this.state.wastemanagement ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.wastemanagement ? wastemanagement : wastemanagementg} className="procurementg" />
                                    <p className="text">Waste Management</p></Col>
                                <Col xs={4} onClick={this.showSustainableGrowth}><input type="checkbox" checked className={this.state.sustainablegrowth ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.sustainablegrowth ? sustainablegrowth : sustainablegrowthg} className="procurementg" />
                                    <p className="text">Sustainable  Growth</p></Col>
                                <Col xs={4} onClick={this.showModels}><input type="checkbox" checked className={this.state.models ? "display-checkbox" : "hide-checkbox"} style={{ float: "right" }} /><img src={this.state.models ? models : modelsg} className="procurementg" />
                                    <p className="text">Models</p></Col>
                            </Row>

                        </Container>
                    </div>
                    <Link to="/pollquestion/:id"><Button variant="default" className="landing-btn"><b>Let's Start</b></Button></Link>
                </center><br /><br /><br />
                <BottomMenu />

            </div>
        )
    }
}