import React, { useState } from 'react';

const Calendar = ({ selectedDate, selectedMonth, handleDateClick }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  // const [selectedMonth, setSelectedMonth] = useState(null);
  // const [selectedDate, setSelectedDate] = useState(null);

  // const handleDateClick = (date) => {
  //   setSelectedDate(date);
  //   setSelectedMonth(currentMonth.getMonth() + 1);
  //   console.log("booking date", date, currentMonth.getMonth() + 1)
  // };

  const isPreviousMonthDisabled = () => {
    const now = new Date();
    const prevMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1);
    return currentMonth.getMonth() === now.getMonth() && currentMonth.getFullYear() === now.getFullYear();
  };
  // const isPreviousMonthDisabled = () => {
  //   const now = new Date();
  //   const lastDayOfPrevMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 0).getDate();

  //   for (let i = 1; i <= lastDayOfPrevMonth; i++) {
  //     const prevMonthDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, i);
  //     if (prevMonthDate >= now) {
  //       return false;
  //     }
  //   }
    
  //   return true;
  // };
  // const isNextMonthDisabled = () => {
  //   const now = new Date();
  //   const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
  
  //   // Check if any date in the next month is in the future
  //   for (let i = 1; i <= daysInMonth; i++) {
  //     const nextMonthDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, i);
  //     if (nextMonthDate > now) {
  //       return false;
  //     }
  //   }
  
  //   return true;
  // };
  const isNextMonthDisabled = () => {
    const now = new Date();
    const nextMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1);
    return nextMonth.getMonth() > now.getMonth() + 1 || nextMonth.getFullYear() > now.getFullYear();
  };
  // const goToNextMonth = () => {
  //   const nextMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1);
  //   if (nextMonth.getMonth() === new Date().getMonth() + 2) {
  //     return;
  //   }
  //   setCurrentMonth(nextMonth);
  //   handleDateClick(null, nextMonth.getMonth() + 1);
  //   // setSelectedDate(null);
  // };

  // const goToPreviousMonth = () => {
  //   const previousMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1);
  //   if (previousMonth.getMonth() < new Date().getMonth()) {
  //     return;
  //   }
  //   setCurrentMonth(previousMonth);
  //   handleDateClick(null, previousMonth.getMonth() + 1);
  //   // setSelectedDate(null);
  // };

  const goToNextMonth = () => {
    const nextMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1);
    if (nextMonth >= new Date()) {
      setCurrentMonth(nextMonth);
      handleDateClick(null, nextMonth.getMonth() + 1); // Update selectedMonth
     // console.log("next", nextMonth.getMonth() + 1)
    }
    else {
      handleDateClick(null, currentMonth.getMonth() + 1); // Keep selectedMonth as the current month
     // console.log("else next", currentMonth.getMonth() + 1)
    }
  };
  const goToPreviousMonth = () => {
    const previousMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1);
  
    // Check if the previous month is not in the past
    if (previousMonth >= new Date()) {
      setCurrentMonth(previousMonth);
      handleDateClick(null, previousMonth.getMonth() + 1); // Update selectedMonth
     // console.log("previous", previousMonth.getMonth() + 1);
    } else {
      // Move to the previous month by default
      setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
      handleDateClick(null, currentMonth.getMonth()); // Update selectedMonth to the current month
     // console.log("else previous", currentMonth.getMonth());
    }
  };
  // const goToPreviousMonth = () => {
  //   const previousMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1);
  //   if (previousMonth.getMonth() >= new Date().getMonth()) {
  //     setCurrentMonth(previousMonth);
  //     handleDateClick(null, previousMonth.getMonth() + 1); // Update selectedMonth
  //     console.log("previous", previousMonth.getMonth() + 1)
  //   }
  //   else {
  //     handleDateClick(null, currentMonth.getMonth() + 1); // Keep selectedMonth as the current month
  //     console.log("else previous", currentMonth.getMonth() + 1)
  //   }
  // };
  const renderDaysOfWeek = () => {
    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat','Sun'];
    return daysOfWeek.map((day) => (
      <div key={day} className="day-of-week">
        {day}
      </div>
    ));
  };

  // const renderCalendarDates = () => {
  //   const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
  //   const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
  //   const startingDay = firstDayOfMonth.getDay();
  //   const today = new Date().getDate();
  //   const currentMonthYear = currentMonth.getFullYear();
  //   const currentMonthNumber = currentMonth.getMonth();
  //   const dates = [];

  //   for (let i = 1 - startingDay; i <= daysInMonth; i++) {
  //     const currentDate = new Date(currentMonthYear, currentMonthNumber, i);
  //     const isPastDate = currentDate < new Date();
  //     dates.push(
  //       <div
  //         key={i}
  //         className={`calendar-date ${selectedDate === i ? 'selected' : ''} ${isPastDate ? 'past-date' : ''}`}
  //         onClick={() => !isPastDate && handleDateClick(i, currentMonthNumber + 1)}>
  //         {i > 0 ? i : ''}
  //       </div>
  //     );
  //   }

  //   return dates;
  // }
  const renderCalendarDates = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
    const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
    const startingDay = firstDayOfMonth.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    const currentMonthYear = currentMonth.getFullYear();
    const currentMonthNumber = currentMonth.getMonth();
    const dates = [];
  
    let startingPoint = startingDay === 0 ? 6 : startingDay - 1;
  
    // If all dates in the current month are past dates, render the next month's dates by default
    if (today >= firstDayOfMonth && today > new Date(currentMonthYear, currentMonthNumber, daysInMonth)) {
      setCurrentMonth(new Date(currentMonthYear, currentMonthNumber + 1, 1)); // Move to the next month
      return; // Return empty dates since the rendering will be done after the state update
    }
  
    for (let i = 1 - startingPoint; i <= daysInMonth; i++) {
      const currentDate = new Date(currentMonthYear, currentMonthNumber, i);
      const isPastDate = currentDate < today;
      const dayOfWeek = currentDate.toLocaleDateString('default', { weekday: 'long' });
  
      dates.push(
        <div
          key={i}
          className={`calendar-date ${selectedDate === i ? 'selected' : ''} ${isPastDate ? 'past-date' : ''}`}
          onClick={() => !isPastDate && handleDateClick(i, currentMonthNumber + 1, dayOfWeek)}>
          {i > 0 ? i : ''}
        </div>
      );
    }
  
    return dates;
  };
  // const renderCalendarDates = () => {
  //   const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
  //   const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
  //   const startingDay = firstDayOfMonth.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
  //   const today = new Date().getDate();
  //   const currentMonthYear = currentMonth.getFullYear();
  //   const currentMonthNumber = currentMonth.getMonth();
  //   const dates = [];
  
  //   // Adjust starting point based on where Monday falls
  //   let startingPoint = startingDay === 0 ? 6 : startingDay - 1; // Convert Sunday to 6, others - 1
  
  //   for (let i = 1 - startingPoint; i <= daysInMonth; i++) {
  //     const currentDate = new Date(currentMonthYear, currentMonthNumber, i);
  //     const isPastDate = currentDate < new Date();
  //     const dayOfWeek = currentDate.toLocaleDateString('default', { weekday: 'long' }); // Get the day of the week
  //     dates.push(
  //       <div
  //         key={i}
  //         className={`calendar-date ${selectedDate === i ? 'selected' : ''} ${isPastDate ? 'past-date' : ''}`}
  //         onClick={() => !isPastDate && handleDateClick(i, currentMonthNumber + 1, dayOfWeek)}>
  //         {i > 0 ? i : ''}
  //       </div>
  //     );
  //   }
  
  //   return dates;
  // };
  // const renderCalendarDates = () => {
  //   const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
  //   const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
  //   const startingDay = firstDayOfMonth.getDay();
  //   const today = new Date().getDate();
  //   const currentMonthYear = currentMonth.getFullYear();
  //   const currentMonthNumber = currentMonth.getMonth();
  //   const dates = [];

  //   for (let i = 1 - startingDay; i <= daysInMonth; i++) {
  //     const currentDate = new Date(currentMonthYear, currentMonthNumber, i);
  //     const isPastDate = currentDate < new Date();
  //     const dayOfWeek = currentDate.toLocaleDateString('default', { weekday: 'long' }); // Get the day of the week
  //     dates.push(
  //       <div
  //         key={i}
  //         className={`calendar-date ${selectedDate === i ? 'selected' : ''} ${isPastDate ? 'past-date' : ''}`}
  //         onClick={() => !isPastDate && handleDateClick(i, currentMonthNumber + 1, dayOfWeek)}>
  //         {i > 0 ? i : ''}
  //       </div>
  //     );
  //   }

  //   return dates;
  // };
  return (

    <div className="calendar">
      <div className="calendar-header">
        <div className="month-heading">{currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}</div>
        <div className="nav-arrows">
          <button onClick={goToPreviousMonth} disabled={isPreviousMonthDisabled()}>&lt;</button>
          <button onClick={goToNextMonth} disabled={isNextMonthDisabled()}>&gt;</button>
        </div>
      </div>
      <div className="ml-1 mr-1">
        <div className="ml-1 mr-1" style={{ border: "1px solid gray", borderRadius: "20px",marginTop:"-5px" }}>
          <div className="days-of-week">{renderDaysOfWeek()}</div>
          <div className="calendar-dates">{renderCalendarDates()}</div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;