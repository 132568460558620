import React, { useState } from 'react';

const SMECalendar = ({ selectedDate, selectedMonth, handleDateClick, selectedDaysArr }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());

    // const isPreviousMonthDisabled = () => {
    //     const now = new Date();
    //     const lastDayOfPrevMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 0).getDate();
    //     for (let i = 1; i <= lastDayOfPrevMonth; i++) {
    //         const prevMonthDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, i);
    //         if (prevMonthDate >= now) {
    //             return false;
    //         }
    //     }

    //     return true;
    // };
    // const isNextMonthDisabled = () => {
    //     const now = new Date();
    //     const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
    //     // Check if any date in the next month is in the future
    //     for (let i = 1; i <= daysInMonth; i++) {
    //         const nextMonthDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, i);
    //         if (nextMonthDate > now) {
    //             return false;
    //         }
    //     }

    //     return true;
    // };
    const isPreviousMonthDisabled = () => {
        const now = new Date();
        const firstDayOfCurrentMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
        return firstDayOfCurrentMonth <= now;
    };

    const isNextMonthDisabled = () => {
        const now = new Date();
        const lastDayOfCurrentMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
        const firstDayOfNextMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);
        return lastDayOfCurrentMonth < now || firstDayOfNextMonth > new Date(now.getFullYear(), now.getMonth() + 1, 1);
    };

    const goToNextMonth = () => {
        const nextMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1);
        if (nextMonth >= new Date()) {
            setCurrentMonth(nextMonth);
            handleDateClick(null, nextMonth.getMonth() + 1); // Update selectedMonth
           // console.log("next", nextMonth.getMonth() + 1)
        }
        else {
            handleDateClick(null, currentMonth.getMonth() + 1); // Keep selectedMonth as the current month
          //  console.log("else next", currentMonth.getMonth() + 1)
        }
    };
    const goToPreviousMonth = () => {
        const previousMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1);

        // Check if the previous month is not in the past
        if (previousMonth >= new Date()) {
            setCurrentMonth(previousMonth);
            handleDateClick(null, previousMonth.getMonth() + 1); // Update selectedMonth
         //   console.log("previous", previousMonth.getMonth() + 1);
        } else {
            // Move to the previous month by default
            setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
            handleDateClick(null, currentMonth.getMonth()); // Update selectedMonth to the current month
          //  console.log("else previous", currentMonth.getMonth());
        }
    };
    const renderDaysOfWeek = () => {
        const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        return daysOfWeek.map((day) => (
            <div key={day} className="day-of-week">
                {day}
            </div>
        ));
    };

    const renderCalendarDates = () => {
        const today = new Date();
        const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
        const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
        const startingDay = firstDayOfMonth.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
        const currentMonthYear = currentMonth.getFullYear();
        const currentMonthNumber = currentMonth.getMonth();
        const dates = [];

        let startingPoint = startingDay === 0 ? 6 : startingDay - 1;

        // If all dates in the current month are past dates, render the next month's dates by default
        if (today >= firstDayOfMonth && today > new Date(currentMonthYear, currentMonthNumber, daysInMonth)) {
            setCurrentMonth(new Date(currentMonthYear, currentMonthNumber + 1, 1)); // Move to the next month
            return; // Return empty dates since the rendering will be done after the state update
        }

        for (let i = 1 - startingPoint; i <= daysInMonth; i++) {
            const currentDate = new Date(currentMonthYear, currentMonthNumber, i);
            const isPastDate = currentDate < today;
            const dayOfWeek = currentDate.toLocaleDateString('default', { weekday: 'long' });
            const dayNumber = currentDate.getDay() === 0 ? 7 : currentDate.getDay(); // Adjust Sunday to 7 instead of 0
            const isDisabled = !selectedDaysArr.includes(dayNumber);

            dates.push(
                <div
                    key={i}
                    className={`calendar-date ${selectedDate === i ? 'selected' : ''} ${isPastDate ? 'past-date' : ''} ${isDisabled ? 'past-date' : ''}`}
                    onClick={() => !isPastDate && !isDisabled && handleDateClick(i, currentMonthNumber + 1, dayOfWeek)}>
                    {i > 0 ? i : ''}
                </div>
            );
        }

        return dates;
    };

    return (

        <div className="calendar">
            <div className="calendar-header">
                <div className="month-heading">{currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}</div>
                <div className="nav-arrows">
                    <button onClick={goToPreviousMonth} disabled={isPreviousMonthDisabled()}>&lt;</button>
                    <button onClick={goToNextMonth} disabled={isNextMonthDisabled()}>&gt;</button>
                </div>
            </div>
            <div className="ml-1 mr-1">
                <div className="ml-1 mr-1" style={{ border: "1px solid gray", borderRadius: "20px", marginTop: "-5px" }}>
                    <div className="days-of-week">{renderDaysOfWeek()}</div>
                    <div className="calendar-dates">{renderCalendarDates()}</div>
                </div>
            </div>
        </div>
    );
};

export default SMECalendar;