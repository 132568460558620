import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Modal, Carousel } from "react-bootstrap";
import { Link, useLocation, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import { enqueueSnackbar } from 'notistack'
import moment from 'moment-timezone';
import smemorning from '../images/smemorning.svg';
import smefullday from '../images/smefullday.svg';
import smeafternoon from '../images/smeafternoon.svg';
import smebookings from '../images/smebookings1.svg';
import linkedinImg from '../images/linkedin.svg';
import hyphen from '../images/hyphen.svg';
import back from '../images/backarrow.png';
import curve from '../images/curve1.svg';
import smerating from '../images/smerating.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SimpleCrypto from "simple-crypto-js";
import SMECalendar from "./SMECalendar";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

function SmeEventData(props) {
    const [desc, setDesc] = useState('')
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedDaysArr, setSelectedDaysArr] = useState([]);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
    const [timeArr, setTimeArr] = useState([])
    const [allDays, setAllDays] = useState([]);
    const [allTimeSlots, setAllTimeSlots] = useState([]);
    const [allOfferings, setAllOfferings] = useState([]);
    const [selectedOfferings, setSelectedOfferings] = useState([]);
    const [bookings, setBookings] = useState();
    const [smeAmount, setSmeAmount] = useState();
    const [feesArr, setFeesArr] = useState([])
    const [rating, setRating] = useState();
    const [profilePicture, setProfilePicture] = useState();
    const [name, setName] = useState();
    const [smeUserId, setSmeUserId] = useState(props.match.params.id);
    const [showConnect, setShowConnect] = useState(true)
    const [showHome, setShowHome] = useState(false)
    const [formatedNewDate, setFormattedNewDate] = useState('')
    const [userSlots, setUserSlots] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState();
    const [topics, setTopics] = useState([])
    const [linkedin, setLinkedin] = useState()
    const [currDate, setCurrDate] = useState()
    const [daysToInclude, setDaysToInclude] = useState()
    const [selectedId, setSelectedId] = useState()
    const [showPopup, setShowPopup] = useState(false)
    const today = new Date();
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const [googleLink, setGoogleLink] = useState()
    const [agenda, setAgenda] = useState()
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState();
    const [userSelectedDate, setUserSelectedDate] = useState();
    const [redirect, setRedirect] = useState(false);
    const [descriptionText, setDescriptionText] = useState('');
    const [commercial, setCommercial] = useState();
    const [selectedOfferingId, setSelectedOfferingId] = useState()
    const [selectedTopicId, setSelectedTopicId] = useState()
    const [showSmeConnect, setShowSmeConnect] = useState(false);
    const [loggedinUserId, setLoggedinUserId] = useState()
    const [selectedPlanId, setSelectedPlanId] = useState()
    const [commercialPlans, setCommercialPlans] = useState([])
    const [selectedCommercialId, setSelectedCommercialId] = useState([])
    const [showPaymentPopup, setShowPaymentPopup] = useState(false);

    const handleBack = () => {
        setRedirect(true);
    };
    const handleConnect = () => {
        const topic = feesArr.find(item => item.topicId === selectedTopicId);
        if (topic) {
            const fee = topic.fees.find(item => item.option === selectedOfferingId);
            if (fee) {
                setSmeAmount(fee.fees);
                //  console.log("smeAmount",fee.fees);
            } else {
                //  console.log(`No option found with offId ${selectedOfferingId}`);
            }
        } else {
            //  console.log(`No topic found with topicId ${selectedTopicId}`);
        }
        setShowConnect(true)
        setShowSmeConnect(true)
        // if (selectedPlanId !== 1) {
        //     setShowPaymentPopup(true)
        // } else {
        //     setShowSmeConnect(true)
        // }
        // console.log("2sme", showConnect, showSmeConnect, selectedDate)

    }
    const bookASlot = () => {
        setShowConnect(true)
        setShowSmeConnect(true)
        // console.log("bookaslot", showConnect, showSmeConnect, selectedDate)
    }
    const handleSmeConnect = (date) => {
        setShowConnect(true)
        setShowSmeConnect(false)
        //  console.log("1sme", showConnect, showSmeConnect)
    }
    const handleFalseShowConnect = (date) => {
        setShowConnect(true)
        setShowSmeConnect(false)
        setShowPaymentPopup(false)
        setSelectedDate(parseInt(moment(date).format("DD")))
        setSelectedMonth(parseInt(moment(date).format("MM"))?.toString()?.padStart(2, '0'));
        // setFormattedNewDate(moment(date).format("DD-MMM-YYYY"));
        setUserSelectedDate(new Date(date))
        //  console.log("2back", showConnect, showSmeConnect)
    }
    const handleBackToProfile = () => {
        setShowConnect(false)
        setShowSmeConnect(false)
        //  console.log("1back", showConnect, showSmeConnect)
    }
    const handleDateChange = date => {
        //  console.log("asdfg", date)
        setSelectedDate(parseInt(moment(date).format("DD")))
        setSelectedMonth(parseInt(moment(date).format('MM').toString().replace(/^0+/, '')));
        setUserSelectedDate(new Date(date))
        //   console.log("date123", parseInt(moment(date).format("DD")), date, parseInt(moment(date).format('MM').toString().replace(/^0+/, '')))
        if (date) {
            const formattedDate = formatDate(date);
            //  console.log(formattedDate);
            setFormattedNewDate(moment(date).format("DD-MMM-YYYY"));
        } else {
            setFormattedNewDate(null);
        }
    };

    // const formatDate = date => {
    //     if (!date || !(date instanceof Date)) {
    //         return ""; // Handle null or invalid date
    //     }
    //     const options = { day: '2-digit', month: 'short', year: 'numeric' };
    //     return new Intl.DateTimeFormat('en-GB', options).format(date);
    // };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return "Invalid Date";
        }
        const formattedDate = date.toISOString();
        return formattedDate;
    }

    const handleClick = (slot) => {
        if (userSelectedDate) {
            setShowPopup(true)
            setSelectedSlot(slot);
        } else {
            enqueueSnackbar('Please select date', { variant: "error" })
            setShowPopup(false)
        }

    };

    useEffect(() => {
        const formattedDate = `${today.getDate()}-${months[today.getMonth()]}-${today.getFullYear()}`;
        setCurrDate(formattedDate)
        getSmeUserData();
        getAllSmeOfferings();
        getAllDays();
        getAllCommercialPlans();
        getAllTimeSlots();
        setUserSelectedDate(new Date(Date.now()))
        setSelectedDate(new Date(Date.now()))
        const currentYear = new Date().getFullYear();
        setSelectedDate(new Date(Date.now()))
        setFormattedNewDate(moment(Date.now()).format("DD-MMM-YYYY"));
        //  console.log("time", timeArr)
        function base64UrlDecode(base64Url) {
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const padding = '='.repeat((4 - (base64.length % 4)) % 4);
            base64 += padding;
            const decoded = atob(base64);
            return decodeURIComponent(escape(decoded));
        }

        function decodeJwt(token) {
            const [header, payload] = token.split('.');
            const decodedPayload = base64UrlDecode(payload);
            const payloadObject = JSON.parse(decodedPayload);
            return payloadObject;
        }

        const decodedToken = decodeJwt(token);
        setLoggedinUserId(decodedToken.userInfo.userId);

    }, [])
    const getSmeBookings = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "smeUserId": id
        }
        axios.post(config.userUrl + 'user/getCombinedBookings', payload, { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    setUserSlots(response.data.filteredData)
                    // setLoggedinUserId(response.data.userId)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getText = (offeringId) => {
        const item = allOfferings.find(txt => txt.id === offeringId);
        return item ? item.text : "";
    }
    const getSmeUserData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getSmeUserDataById', { headers: headers })
            .then(function (response) {
                //  console.log("day", response, response.data.data[0]?.day);
                if (response.data.status === true) {
                    setDesc(response.data.data[0]?.desc)
                    setSelectedDaysArr(response.data.data[0]?.day)
                    setTimeArr([response.data.data[0]?.time])
                    setSelectedCommercialId([response.data.data[0].commercial])
                    setSelectedTimeSlot(response.data.data[0]?.time)
                    setTopics(response.data.topics)
                    setSelectedOfferings(response.data.data[0].offering)
                    setLinkedin(response.data.data[0].linkedin)
                    setDescriptionText(response.data.data[0].description)
                    setRating(response.data.data[0].avg_assessment)
                    setSmeUserId(response.data.data[0].userId)
                    setProfilePicture(response.data.data[0].profilePicture)
                    setCommercial(response.data.data[0].commercial)
                    setSelectedPlanId(response.data.data[0].commercial)
                    setName(response.data.data[0].name)
                    setFeesArr(response.data.data[0]?.feesArr)
                    setBookings(response.data.data[0].bookings)
                    getSmeBookings(response.data.data[0].userId);
                    const days = response.data.data[0]?.day.map(day => day === 7 ? 0 : day);
                    setDaysToInclude(days)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    setAllOfferings(response.data.offerings)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllDays = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllDays', { headers: headers })
            .then(function (response) {
                //   console.log(response);
                if (response.data.status === true) {
                    setAllDays(response.data.days)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllTimeSlots = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllTimeSlots', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setAllTimeSlots(response.data.timeSlots)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const addSmeBooking = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        //console.log("selectedOfferingId:", selectedOfferingId);
        //console.log("selectedTopicId:", selectedTopicId);
        const monthValue = moment(userSelectedDate).format("MM");
        const month = monthValue - 1;
        var payload = {
            "offeringId": selectedOfferingId,
            "bookingDate": moment(userSelectedDate).format("YYYY-MM-DD"),
            "month": parseInt(month),
            "link": googleLink,
            "agenda": agenda,
            "topicId": selectedTopicId,
            "smeUserId": smeUserId,
            "day": parseInt(selectedDay),
            "time": selectedSlot,
            "amount": smeAmount
        }
        console.log("payload", payload)

        axios.post(config.userUrl + 'user/createEventBySme', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    // console.log("success")
                    setShowPopup(false)
                    setShowHome(true)
                }else {
                    // Handle other successful responses where status is false
                    enqueueSnackbar(response.data.message, { variant: "error" });
                  }
                })
                .catch(function (error) {
                  if (error.response) {
                    if (error.response.status === 400) {
                      enqueueSnackbar('You have already created 2 events this month', { variant: "error" });
                    } else {
                      enqueueSnackbar(error.response.data.message || 'An error occurred', { variant: "error" });
                    }
                  } else if (error.request) {
                    enqueueSnackbar('No response from the server. Please try again later.', { variant: "error" });
                  } else {
                    enqueueSnackbar('An error occurred while setting up the request.', { variant: "error" });
                  }
                  console.log("Error", error);
                });
    };
    const handleShowLinkPopup = (id) => {
        setSelectedId(id)
        setShowPopup(true)
    }
    const formatRatingNumber = (number) => {
        const roundedNumber = Math.round(number * 100) / 100; // Round to two decimal places
        const decimalPart = roundedNumber - Math.floor(roundedNumber);

        if (decimalPart === 0) {
            return Math.floor(roundedNumber); // Remove decimal part if it's zero
        } else {
            return roundedNumber.toFixed(1); // Keep two decimal places otherwise
        }
    }
    const handleOfferingClick = (id) => {
        setSelectedOfferingId(id);
    };
    const handleSelectTopic = (id) => {
        setSelectedTopicId(id);
    };
    const getAllCommercialPlans = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllCommercialPlans', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setCommercialPlans(response.data.commercialPlans)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const onClickUrl = () => {
        window.open(linkedin)
    }
    // const renderTimeSlots = () => {
    //     const amSlots = [];
    //     const pmSlots = [];
    //     const interval = 30;
    //     let startHour, endHour;

    //     if (selectedTimeSlot === 1 || selectedTimeSlot === 2 || selectedTimeSlot === 3) {
    //         startHour = 8;
    //         endHour = 18;
    //     } else {
    //         return null;
    //     }
    //     for (let hour = startHour; hour < endHour; hour++) {
    //         for (let minute = 0; minute < 60; minute += interval) {
    //             const amPm = hour >= 12 ? 'PM' : 'AM';
    //             const formattedHour = (hour % 12 || 12).toString().padStart(2, '0'); // Add leading zeros
    //             const formattedMinute = minute.toString().padStart(2, '0'); // Add leading zeros
    //             const time = `${formattedHour}:${formattedMinute} ${amPm}`;

    //             if (amPm === 'AM') {
    //                 amSlots.push(time);
    //             } else {
    //                 pmSlots.push(time);
    //             }
    //         }
    //     }

    //     const filteredSlots = userSlots
    //         ?.filter(slot => moment(slot.bookingDate).format('DD-MM-YYYY').includes(moment(userSelectedDate).format('DD-MM-YYYY'))) // Filter by date
    //         .map(slot => slot.time);
    //     const isSlotDisabled = (slot) => {
    //         if (
    //             (selectedTimeSlot === 2 && (slot.includes('PM') || slot === '01:00 PM' || slot === '01:30 PM')) ||
    //             (selectedTimeSlot === 3 && (slot.includes('AM') || slot === '01:00 PM' || slot === '01:30 PM')) ||
    //             (selectedTimeSlot === 1 && (slot === '01:00 PM' || slot === '01:30 PM'))
    //         ) {
    //             return true;
    //         }
    //         return false;
    //     };

    //     const renderedAmSlots = amSlots.map((slot, index) => {
    //         const slotIsBooked = filteredSlots?.includes(slot);
    //         const slotStatus = userSlots.find(userSlot => userSlot.time === slot)?.status || 0;
    //         const backgroundColor = slotIsBooked ?
    //             (slotStatus === 1 ? '#BFE6EB' : (slotStatus === 3 ? '#f9e1d5' : '#d7f1cf')) :
    //             (selectedSlot === slot ? '#d7f1cf' : (isSlotDisabled(slot) ? 'lightgray' : '#d7f1cf'));

    //         return (
    //             <div
    //                 key={index}
    //                 onClick={(slotIsBooked && slotStatus === 1) || isSlotDisabled(slot) ? null : () => handleClick(slot)}
    //                 style={{
    //                     cursor: slotIsBooked || isSlotDisabled(slot) ? 'default' : 'pointer',
    //                     borderRadius: '10px',
    //                     padding: '2px 6px 6px 6px',
    //                     boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
    //                     margin: '4px auto',
    //                     width: 'calc(25% - 16px)',
    //                     textAlign: 'center',
    //                     fontSize: '16px',
    //                     fontFamily: 'Noto Sans',
    //                     position: 'relative',
    //                     display: 'flex',
    //                     justifyContent: 'center',
    //                     alignItems: 'center',
    //                 }}
    //                 className="ml-1 sme-slot-item"
    //             >
    //                 <div
    //                     style={{
    //                         backgroundColor: 'white',
    //                         borderRadius: '10px',
    //                         width: '100%',
    //                         height: '100%',
    //                         display: 'flex',
    //                         justifyContent: 'center',
    //                         alignItems: 'center'
    //                     }}
    //                 >
    //                     <div
    //                         style={{
    //                             backgroundColor: backgroundColor,
    //                             borderRadius: '10px',
    //                         }}
    //                     >
    //                         {slot}
    //                     </div>
    //                 </div>
    //             </div>
    //         );
    //     });
    //     const pmSlotsWithout5 = pmSlots.filter(slot => slot !== '01:00 PM' && slot !== '01:30 PM');
    //     const renderedPmSlots = pmSlots.map((slot, index) => {
    //         const slotIsBooked = filteredSlots?.includes(slot);
    //         const slotStatus = userSlots.find(userSlot => userSlot.time === slot)?.status || 0;
    //         const backgroundColor = slotIsBooked ?
    //             (slotStatus === 1 ? '#BFE6EB' : (slotStatus === 3 ? '#f9e1d5' : '#d7f1cf')) :
    //             (selectedSlot === slot ? '#d7f1cf' : (isSlotDisabled(slot) ? 'lightgray' : '#d7f1cf'));

    //         return (
    //             <div
    //                 key={index}
    //                 onClick={(slotIsBooked && slotStatus === 1) || isSlotDisabled(slot) ? null : () => handleClick(slot)}
    //                 style={{
    //                     cursor: slotIsBooked || isSlotDisabled(slot) ? 'default' : 'pointer',
    //                     borderRadius: '10px',
    //                     padding: '2px 6px 6px 6px',
    //                     boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
    //                     margin: '4px auto',
    //                     textAlign: 'center',
    //                     fontSize: '16px',
    //                     width: 'calc(25% - 16px)',
    //                     fontFamily: 'Noto Sans',
    //                     position: 'relative',
    //                     display: 'flex',
    //                     justifyContent: 'center',
    //                     alignItems: 'center',
    //                 }}
    //                 className="ml-1 sme-slot-item"
    //             >
    //                 <div
    //                     style={{
    //                         backgroundColor: 'white',
    //                         borderRadius: '10px',
    //                         width: '100%',
    //                         height: '100%',
    //                         display: 'flex',
    //                         justifyContent: 'center',
    //                         alignItems: 'center',
    //                     }}
    //                 >
    //                     <div
    //                         style={{
    //                             backgroundColor: backgroundColor,
    //                             borderRadius: '10px',
    //                         }}
    //                     >
    //                         {slot}
    //                     </div>
    //                 </div>
    //             </div>
    //         );
    //     });
    //     return (
    //         <div className="sme-planned-border-abc">
    //             <div className="flex">
    //                 <div><img src={smemorning} className="mt-1 ml-2" /></div>
    //                 <div style={{ fontFamily: "Noto Sans", fontSize: "14px", fontWeight: 600, marginTop: "12px" }} className="ml-2"><img src={hyphen} style={{ width: "30px" }} />First Half</div>
    //             </div>
    //             <div className="sme-slot-container">
    //                 {renderedAmSlots}
    //             </div>
    //             <div className="flex">
    //                 <div><img src={smeafternoon} className="mt-1 ml-2" /></div>
    //                 <div style={{ fontFamily: "Noto Sans", fontSize: "14px", fontWeight: 600, marginTop: "12px" }} className="ml-2"><img src={hyphen} style={{ width: "30px" }} />Second Half</div>
    //             </div>
    //             <div className="sme-slot-container">
    //                 {renderedPmSlots}
    //             </div>
    //         </div>
    //     );
    // };
    const renderTimeSlots = () => {
        const amSlots = [];
        const pmSlots = [];
        const interval = 30;
        let startHour, endHour;

        if (selectedTimeSlot === 1 || selectedTimeSlot === 2 || selectedTimeSlot === 3) {
            startHour = 8;
            endHour = 18;
        } else {
            return null;
        }
        for (let hour = startHour; hour < endHour; hour++) {
            for (let minute = 0; minute < 60; minute += interval) {
                const amPm = hour >= 12 ? 'PM' : 'AM';
                const formattedHour = (hour % 12 || 12).toString().padStart(2, '0'); // Add leading zeros
                const formattedMinute = minute.toString().padStart(2, '0'); // Add leading zeros
                const time = `${formattedHour}:${formattedMinute} ${amPm}`;

                if (amPm === 'AM') {
                    amSlots.push(time);
                } else {
                    pmSlots.push(time);
                }
            }
        }

        const filteredSlots = userSlots
            ?.filter(slot => moment(slot.bookingDate).format('DD-MM-YYYY').includes(moment(userSelectedDate).format('DD-MM-YYYY'))) // Filter by date
            .map(slot => slot.time);
        const isSlotDisabled = (slot) => {
            if (
                (selectedTimeSlot === 2 && (slot.includes('PM') || slot === '01:00 PM' || slot === '01:30 PM')) ||
                (selectedTimeSlot === 3 && (slot.includes('AM') || slot === '01:00 PM' || slot === '01:30 PM')) ||
                (selectedTimeSlot === 1 && (slot === '01:00 PM' || slot === '01:30 PM'))
            ) {
                return true;
            }
            return false;
        };

        const renderedAmSlots = amSlots.map((slot, index) => {
            const slotIsBooked = filteredSlots?.includes(slot);
            const userSlot = userSlots.find(userSlot => moment(userSlot.bookingDate).format('DD-MM-YYYY') === moment(userSelectedDate).format('DD-MM-YYYY') && userSlot.time === slot);
            const isUserSlot = loggedinUserId === userSlot?.userId && userSlot?.time === slot;
            const anySlotStatusOne = userSlots.some(userSlot => moment(userSlot.bookingDate).format('DD-MM-YYYY') === moment(userSelectedDate).format('DD-MM-YYYY') && userSlot.time === slot && userSlot.status === 1);


            let backgroundColor;
            //  console.log("status", userSlot?.status);
            // Priority check: any user's slot with status 1 is blue
            if (anySlotStatusOne) {
                backgroundColor = '#BFE6EB'; // blue for status 1
            } else if (slotIsBooked) {
                if (isUserSlot && userSlot?.status === 3) {
                    backgroundColor = '#f9fcb7'; // light yellow for user's slot with status 3
                } else {
                    backgroundColor = userSlot?.status === 3 ? '#f9e1d5' : '#d7f1cf'; // light orange for others with status 3, green for others
                }
            } else {
                // Non-booked slots
                backgroundColor = selectedSlot === slot ? '#d7f1cf' : (isSlotDisabled(slot) ? 'lightgray' : '#d7f1cf');
            }
            return (
                <div
                    key={index}
                    onClick={(slotIsBooked && anySlotStatusOne) || isSlotDisabled(slot) ? null : () => handleClick(slot)}
                    style={{
                        cursor: slotIsBooked || isSlotDisabled(slot) ? 'default' : 'pointer',
                        borderRadius: '10px',
                        padding: '2px 6px 6px 6px',
                        boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
                        margin: '4px auto',
                        width: 'calc(25% - 16px)',
                        textAlign: 'center',
                        fontSize: '16px',
                        fontFamily: 'Noto Sans',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    className="ml-1 sme-slot-item"
                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: backgroundColor,
                                borderRadius: '10px',
                            }}
                        >
                            {slot}
                        </div>
                    </div>
                </div>
            );
        });
        const pmSlotsWithout5 = pmSlots.filter(slot => slot !== '01:00 PM' && slot !== '01:30 PM');
        const renderedPmSlots = pmSlots.map((slot, index) => {
            const slotIsBooked = filteredSlots?.includes(slot);
            const userSlot = userSlots.find(userSlot => moment(userSlot.bookingDate).format('DD-MM-YYYY') === moment(userSelectedDate).format('DD-MM-YYYY') && userSlot.time === slot);
            const isUserSlot = loggedinUserId === userSlot?.userId && userSlot?.time === slot;
            const anySlotStatusOne = userSlots.some(userSlot => moment(userSlot.bookingDate).format('DD-MM-YYYY') === moment(userSelectedDate).format('DD-MM-YYYY') && userSlot.time === slot && userSlot.status === 1);


            let backgroundColor;
            if (anySlotStatusOne) {
                backgroundColor = '#BFE6EB'; // blue for status 1
            } else if (slotIsBooked) {
                if (isUserSlot && userSlot?.status === 3) {
                    backgroundColor = '#f9fcb7'; // light yellow for user's slot with status 3
                } else {
                    backgroundColor = userSlot?.status === 3 ? '#f9e1d5' : '#d7f1cf'; // light orange for others with status 3, green for others
                }
            } else {
                // Non-booked slots
                backgroundColor = selectedSlot === slot ? '#d7f1cf' : (isSlotDisabled(slot) ? 'lightgray' : '#d7f1cf');
            }

            return (
                <div
                    key={index}
                    onClick={(slotIsBooked && anySlotStatusOne) || isSlotDisabled(slot) ? null : () => handleClick(slot)}
                    style={{
                        cursor: slotIsBooked || isSlotDisabled(slot) ? 'default' : 'pointer',
                        borderRadius: '10px',
                        padding: '2px 6px 6px 6px',
                        boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
                        margin: '4px auto',
                        textAlign: 'center',
                        fontSize: '16px',
                        width: 'calc(25% - 16px)',
                        fontFamily: 'Noto Sans',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    className="ml-1 sme-slot-item"
                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: backgroundColor,
                                borderRadius: '10px',
                            }}
                        >
                            {slot}
                        </div>
                    </div>
                </div>
            );
        });


        return (
            <div>
                <div className="sme-booked-border-abc-B">
                    <div className="flex">
                        <div><img src={smemorning} className="mt-1 ml-2" /></div>
                        <div style={{ fontFamily: "Noto Sans", fontSize: "14px", fontWeight: 600, marginTop: "12px" }} className="ml-2"><img src={hyphen} style={{ width: "30px" }} />First Half</div>
                    </div>
                    <div className="sme-slot-container">
                        {renderedAmSlots}
                    </div>

                    <div className="flex">
                        <div><img src={smeafternoon} className="mt-1 ml-2" /></div>
                        <div style={{ fontFamily: "Noto Sans", fontSize: "14px", fontWeight: 600, marginTop: "12px" }} className="ml-2"><img src={hyphen} style={{ width: "30px" }} />Second Half</div>
                    </div>
                    <div className="sme-slot-container">
                        {renderedPmSlots}
                    </div>
                </div>
                <div className="mt-2" style={{ display: "flex", flexWrap: "wrap", gap: "0.3rem", padding: "2px 10px" }}>
                    <div className="status-indicator-container">
                        <div className="status-indicator" style={{ background: "lightgray" }}></div>
                        <span>Not Available</span>
                    </div>
                    <div className="status-indicator-container">
                        <div className="status-indicator" style={{ background: "#d7f1cf" }}></div>
                        <span>Available</span>
                    </div>
                    <div className="status-indicator-container">
                        <div className="status-indicator" style={{ background: "#BFE6EB" }}></div>
                        <span>Confirmed</span>
                    </div>
                    <div className="status-indicator-container">
                        <div className="status-indicator" style={{ background: "#F9D5D5" }}></div>
                        <span>Other's Reservation</span>
                    </div>
                    <div className="status-indicator-container">
                        <div className="status-indicator" style={{ background: "#f9fcb7" }}></div>
                        <span>Your Reservation</span>
                    </div>
                </div>
            </div>
        );
    };
    const mapDayToNumber = (day) => {
        // console.log("daysfcvgbhnjm", day)
        switch (day?.toLowerCase()) {
            case 'sunday':
                return 7;
            case 'monday':
                return 1;
            case 'tuesday':
                return 2;
            case 'wednesday':
                return 3;
            case 'thursday':
                return 4;
            case 'friday':
                return 5;
            case 'saturday':
                return 6;
            default:
                return -1; // Invalid day
        }
    };
    const handleDateClick = (date, month, day) => {
        // console.log("date click type", typeof date, typeof month, date, month, day);
        setSelectedDate(date);
        setSelectedMonth(month);
        // setSelectedDay(day)
        const dayNumber = mapDayToNumber(day);
        setSelectedDay(dayNumber);
        const inputDateString = `${day}, ${month}/${date}`;
        setUserSelectedDate(moment(inputDateString, 'dddd, MM/DD').toDate())
        //  console.log("new", moment(inputDateString, 'dddd, MM/DD').toDate())
        if (date) {
            // const formattedDate = formatDate(date);
            // console.log(formattedDate);
            let fDate = moment(inputDateString, 'dddd, MM/DD').toDate()
            setFormattedNewDate(moment(fDate).format("DD-MMM-YYYY"));
            // console.log("fadte", fDate)
        } else {
            setFormattedNewDate(null);
        }
    }
    if (redirect) {
        return <Redirect to='/home' />
    }
    if (showHome) {
        return <Redirect to={{
            pathname: '/viewmyevents',
        }}
        />
    }
    return (
        <>
            {!showConnect ?
                <></>
                // <div style={{ height: "96.5vh", overflow: "hidden", display: "flex", flexDirection: "column" }}>
                //     <img src={curve} style={{ width: "100%", position: "absolute", top: "4.5rem", left: 0 }} />
                //     <img src={back} alt="Back" className="back_arrow-sme-profile" onClick={handleBack} />

                //     <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                //     <div className="mt-5" style={{ flex: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
                //         <div style={{ marginLeft: "1rem", marginRight: "1rem", flexShrink: 0 }}>
                //             <Row className="no-gutters" style={{ width: "100%" }}>
                //                 <Col xs={4}>
                //                     <img alt="" src={config.userUrl + profilePicture} style={{ marginLeft: "1.7rem", marginTop: "0.5rem", width: "85px" }} />
                //                 </Col>
                //                 <Col xs={1}></Col>
                //                 <Col xs={7}>
                //                     <Card className="offerings-card-smeprofile" style={{ marginLeft: "0.8rem", width: "94%" }}>
                //                         <center style={{ fontSize: "12px", fontWeight: 600 }}>Offerings</center>
                //                         <Row className="no-gutters" style={{ width: "100%" }}>
                //                             <Col>
                //                                 {allOfferings.filter(data => selectedOfferings.includes(data.id)).slice(0, 1).map((data, i) => (
                //                                     <center>
                //                                         <div className="offerings-wrapper">
                //                                             <img src={"../" + data.text + ".svg"} alt={data.text} style={{ width: "45px", marginTop: "-8px" }} />
                //                                             <div className="offerings-text">{data.text}</div>
                //                                         </div>
                //                                     </center>
                //                                 ))}
                //                             </Col>
                //                             <Col>
                //                                 {allOfferings.filter(data => selectedOfferings.includes(data.id)).slice(1, 2).map((data, i) => (
                //                                     <center>
                //                                         <div className="offerings-wrapper">
                //                                             <img src={"../" + data.text + ".svg"} alt={data.text} style={{ width: "45px", marginTop: "-8px" }} />
                //                                             <div className="offerings-text">{data.text}</div>
                //                                         </div>
                //                                     </center>

                //                                 ))}
                //                             </Col>
                //                         </Row>

                //                     </Card>
                //                 </Col>
                //             </Row>
                //         </div>
                //         <div style={{ marginLeft: "1rem", marginRight: "1rem", flexShrink: 0 }}>
                //             <Row className="no-gutters" style={{ width: "100%" }}>
                //                 <Col xs={5}>
                //                     <center style={{ fontSize: "14px", color: "white", fontWeight: 600 }}>{name} </center>
                //                     <center style={{ fontSize: "12px", color: "white" }}>{descriptionText}</center>
                //                 </Col>
                //                 <Col xs={4}>
                //                     <Card style={{ background: "#d7deef", height: "5.5rem", backgroundImage: "linear-gradient(to right, #d7deef , #9DB2DD)", width: "78%", border: "none", borderRadius: "15px", padding: "auto", marginLeft: "10px", marginRight: "3px" }}>
                //                         <center>
                //                             <div style={{ fontSize: "12px", fontWeight: 600 }}>Assessment</div>
                //                             <img src={smerating} style={{ width: "55px", marginTop: "-7px" }} alt="Assessment" />
                //                             <div style={{ fontSize: "15px", fontWeight: 600, fontFamily: "Noto Sans", marginTop: "-7px" }} className="mb-1">{formatRatingNumber(rating)}</div>
                //                         </center>
                //                     </Card>
                //                 </Col>
                //                 <Col xs={3}>
                //                     <Card style={{ width: "100%", height: "5.5rem", background: "#d7deef", backgroundImage: "linear-gradient(to right, #d7deef , #9DB2DD)", border: "none", borderRadius: "15px", padding: "auto" }}>
                //                         <center>
                //                             <div style={{ fontSize: "12px", fontWeight: 600 }}>Appointment</div>
                //                             <img src={smebookings} style={{ width: "55px", marginTop: "-7px" }} alt="Appointment" />
                //                             <div style={{ fontSize: "15px", fontWeight: 600, fontFamily: "Noto Sans", marginTop: "-7px" }} className="mb-1">{bookings}</div>
                //                         </center>
                //                     </Card>
                //                 </Col>
                //             </Row>
                //         </div>
                //         <div style={{ marginLeft: "1rem", marginRight: "1rem", flexShrink: 0 }}>
                //             <Row className="no-gutters" style={{ width: "100%" }}>
                //                 <Col xs={4}>
                //                     <img src={linkedinImg} className="ml-3 cursor-pointer" alt="LinkedIn" onClick={onClickUrl} />
                //                 </Col>
                //                 <Col xs={4}>
                //                     <center style={{ marginLeft: "-10px", color: "white", fontFamily: "Noto Sans", fontSize: "14px" }}><b>Fees</b><br /> {commercial === 1 ? "Free" : "Paid"}</center>
                //                 </Col>
                //                 <Col xs={4} >
                //                     <Button variant="default" className="ml-4 mt-1 profile-btn-sme" onClick={handleSmeConnect}>Connect</Button>
                //                 </Col>
                //             </Row>
                //         </div>
                //         <div style={{ marginLeft: "1rem", marginRight: "1rem", flex: 1, overflowY: "auto", overflowX: "hidden", marginTop: "6.5vh" }}>
                //             <div>
                //                 <div style={{ fontSize: "16px", fontWeight: 600 }} className="ml-2">Expertise</div>
                //                 <div className="ml-2" style={{ display: "flex", flexWrap: "wrap" }}>
                //                     {topics.map((data, i) => (
                //                         <div key={i} style={{ fontSize: "12px", fontWeight: 500 }}>{data.topicName.replace(/ *\([^]*\) */g, "")},&nbsp;</div>
                //                     ))}
                //                 </div>
                //                 <div style={{ fontSize: "16px", fontWeight: 600 }} className="mt-2 ml-2">About me</div>
                //                 <div className="mt-1 sme-intro-box-aboutme">
                //                     {desc}
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                //     <div style={{ marginBottom: "3.6rem" }}></div>
                //     <BottomMenu />
                // </div>
                : (showSmeConnect) ?
                    <div>
                        <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                        <div className="mt-5">
                            <div className="your-jitbits flex"><div onClick={() => handleFalseShowConnect(userSelectedDate ? userSelectedDate : new Date(Date.now()))}><img src={back} style={{ width: "30px" }} /></div>&nbsp;<div className="mt-1">Available Slots On
                            </div><div style={{ marginLeft: "2px", color: "#ff942c", fontWeight: 600, marginTop: "-9px", border: "none" }}> {/* Adjust the width as needed */}
                                    <DatePicker
                                        customInput={<CustomInput />}
                                        // selected={selectedDate ? selectedDate : new Date(Date.now())}
                                        selected={userSelectedDate}
                                        onChange={handleDateChange}
                                        className="picker"
                                        dateFormat="dd-MMM-yyyy"
                                        placeholder="Select Date"
                                        minDate={new Date().setDate(new Date().getDate() + 1)}
                                        filterDate={(date) => daysToInclude.includes(date.getDay())}
                                    />
                                </div>
                            </div>              <center><div style={{ fontSize: "18px", fontWeight: 600, color: "#ff942c", marginTop: "-20px" }}>{name}</div></center>

                            <div className="mb-2">
                                {!userSelectedDate ? <></> :
                                    <div>
                                        {renderTimeSlots()}
                                    </div>
                                }
                                <Modal show={showPopup} size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"

                                    centered>
                                    <Modal.Body style={{ background: "#EFF5FB", borderRadius: "16px" }}>
                                        <div className="sme-booking-slot-popup-planned">
                                            <div className="ml-1">  </div>
                                            <div style={{ fontSize: "18px", marginTop: "-4px", textAlign: "center" }}>Confirmation of your event</div>
                                            <div style={{ display: "flex", justifyContent: "space-between", color: "white", marginTop: "-9px", marginRight: "0.8rem", cursor: "pointer" }}> <div><i onClick={() => setShowPopup(false)} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div></div>
                                        </div>
                                        <div>
                                            <div className="sme-profile-bg-booking-req">
                                                <Row style={{ width: "100%" }} className="no-gutters">
                                                    <Col xs={3}>
                                                        <center><img alt="" src={config.userUrl + profilePicture} style={{ width: "50px" }} /></center>
                                                        <center style={{ fontSize: "14px" }}>{name}</center>
                                                    </Col>
                                                    <Col xs={9}>
                                                        <center>
                                                            <div style={{ marginTop: "-6px" }}>{getText(selectedOfferingId)} session</div>on {topics.filter(data => data.id === selectedTopicId)[0]?.topicName}<br />
                                                            ({userSelectedDate ? formatedNewDate : currDate} @ {selectedSlot})

                                                        </center>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                        <input type="text"
                                            className="mt-2 web-font form-control"
                                            aria-describedby="text"
                                            name="googleLink"
                                            style={{ border: '1px solid gray', borderRadius: "5px", width: "100%" }}
                                            value={googleLink}
                                            onChange={(e) => setGoogleLink(e.target.value)}
                                            placeholder="Google Meet/Zoom Link"
                                        />
                                        <textarea type="text"
                                            className="mt-2 web-font form-control"
                                            aria-describedby="text"
                                            name="agenda"
                                            style={{ overflowY: "none", border: '1px solid gray', borderRadius: "5px", width: "100%", resize: "none" }}
                                            value={agenda}
                                            onChange={(e) => setAgenda(e.target.value)}
                                            rows={2}
                                            placeholder="Agenda"
                                        />
                                        <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div></div>
                                            <Button variant="default" disabled={!googleLink || !agenda} className="mt-1 prev-btn-sme" onClick={() => addSmeBooking()}>Confirm</Button>
                                        </div>
                                    </Modal.Body>
                                </Modal>

                            </div>
                        </div>
                        <div style={{ marginBottom: "3.6rem" }}></div>
                        <BottomMenu />
                    </div> :
                    <div>
                        <div>
                            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                            <div className="screen-topgap-sme">
                                <div className="mt-1 your-jitbits"> <span style={{ marginTop: "2px" }}>Create Event</span></div>
                                <div className="ml-2 mr-2" style={{ border: "1px solid gray", marginTop: "-8px", borderRadius: "20px" }}>
                                    <div className="mb-1 abt-u_" style={{ marginTop: "-1px" }}>Expertise you are seeking</div>
                                    <div className="ml-2 mr-2" style={{ border: "1px solid gray", marginTop: "-2px", borderRadius: "20px" }}>
                                        <center className="mb-1 ml-1" style={{ display: "flex", width: "100%" }}>
                                            {topics.map((data, i) => (
                                                <div
                                                    key={data.id}
                                                    onClick={() => handleSelectTopic(data.id)}
                                                    style={{
                                                        width: "calc(5.4rem - 2px)",
                                                        border: selectedTopicId === data.id ? "2px solid #ff942c" : "2px solid transparent",
                                                        borderRadius: "15px",
                                                        padding: "4px",
                                                        cursor: "pointer",
                                                        boxSizing: "border-box"
                                                    }}
                                                >
                                                    <img src={"../" + data.topicName + ".png"} alt={data.text} style={{ width: "28px" }} /><br />
                                                    <div className="sme-off-text">{data.topicName}</div>
                                                </div>
                                            ))}
                                        </center>
                                    </div>
                                    <div className="abt-u_">Objective</div>
                                    <div className="ml-2 mr-2" style={{ border: "1px solid gray", borderRadius: "20px", height: "fit-content", padding: "2px" }}>
                                        <center className="mb-1 flex" style={{ gap: "3px" }}>
                                            {allOfferings.filter(data => selectedOfferings.includes(data.id)).map((data, i) => (
                                                <div
                                                    key={data.id}
                                                    onClick={() => handleOfferingClick(data.id)}
                                                    style={{
                                                        width: "4.5rem",
                                                        border: selectedOfferingId === data.id ? "2px solid #ff942c" : "2px solid transparent",
                                                        borderRadius: "15px",
                                                        cursor: "pointer",
                                                        boxSizing: "border-box"
                                                    }}
                                                >
                                                    <img src={"../" + data.text.replace(/ *\([^]*\) */g, "") + ".svg"} alt={data.text} /><br />
                                                    <div className="sme-off-text">{data.text}</div>
                                                </div>
                                            ))}
                                        </center>
                                    </div>

                                    <div className="abt-u_">Slot (30 min) in IST </div>
                                    <div className="ml-2 mr-2 mb-2" style={{ border: "1px solid gray", borderRadius: "20px" }}>
                                        <div className="mb-1" style={{ marginTop: "-4px", display: "flex", width: "100%" }} >
                                            {allTimeSlots.filter(data => timeArr?.includes(data.id)).map((data, i) => (
                                                <div className="ml-2 slot-time"><center><div style={selectedTimeSlot === data.id ? { width: "6rem", border: "2px solid #ff942c", borderRadius: "10px", padding: "2px" } : { border: "none", borderRadius: "10px", padding: "2px" }}><img src={selectedTimeSlot === 1 ? smefullday : selectedTimeSlot === 2 ? smemorning : smeafternoon} /><TimeRange time={data.time} /></div></center></div>
                                            ))}
                                            {/* {allTimeSlots.slice(1, 2).map((data, i) => (
                                                <Col className="slot-time"><center><div style={selectedTimeSlot === data.id ? { width: "80%", border: "2px solid #ff942c", borderRadius: "10px", padding: "2px" } : { border: "none", borderRadius: "10px", padding: "2px" }}><img src={smemorning} /><TimeRange time={data.time} /></div></center></Col>
                                            ))}
                                            {allTimeSlots.slice(2, 3).map((data, i) => (
                                                <Col className="slot-time"><center><div style={selectedTimeSlot === data.id ? { width: "80%", border: "2px solid #ff942c", borderRadius: "10px", padding: "2px" } : { border: "none", borderRadius: "10px", padding: "2px" }}><img src={smeafternoon} /><TimeRange time={data.time} /></div></center></Col>
                                            ))} */}
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "-5px" }}>
                                        <SMECalendar
                                            selectedDate={selectedDate}
                                            selectedMonth={selectedMonth}
                                            handleDateClick={handleDateClick}
                                            selectedDaysArr={selectedDaysArr}
                                        />
                                    </div>
                                    <div className="abt-u_">Fees (INR)</div>
                                    <div className="ml-2 mr-2 mb-2" style={{ border: "1px solid gray", borderRadius: "15px" }}>
                                        <div className="ml-2 mr-2 mb-2 flex" style={{ display: "flex" }}>

                                            {/* {commercialPlans.filter(data => selectedCommercialId?.includes(data.id)).map((data, i) => (
                                                <div className="ml-1 sme-txt-size" style={{ display: "flex" }}>
                                                    <input
                                                        type="radio"
                                                        name="plans"
                                                        value={data.id}
                                                        checked={selectedPlanId === data.id}
                                                        style={{ accentColor: "#31197C" }}
                                                    />
                                                    <span className="ml-1" style={{ marginTop: "-1px" }}>{data.plan}</span>
                                                </div>
                                            ))} */}
                                            {commercialPlans.filter(data => !selectedCommercialId?.includes(data.id)).map((data, i) => (
                                                <div className="ml-1 sme-txt-size" style={{ display: "flex" }}>
                                                    <input
                                                        type="radio"
                                                        name="plans"
                                                        value={data.id}
                                                        checked={selectedPlanId !== data.id}
                                                        style={{ accentColor: "#31197C" }}
                                                    />
                                                    <span className="ml-1" style={{ marginTop: "-1px" }}>{data.plan}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <Button
                                            variant="default"
                                            className="mr-2 mb-1 sme-connect-next-btn"
                                            onClick={() => handleConnect(selectedDate)}
                                            disabled={!selectedOfferingId || !selectedTopicId || !selectedPlanId || !selectedMonth || !selectedDate}
                                        >
                                            Select
                                        </Button>
                                    </div>

                                    <div className="mt-5"></div>

                                </div>
                            </div>
                            <Modal show={showPaymentPopup} size="lg"
                                aria-labelledby="contained-modal-title-vcenter"

                                centered>
                                <Modal.Body style={{ background: "#EFF5FB", borderRadius: "16px" }}>
                                    <div>
                                        <div className="ml-1">  </div>
                                        <div style={{ fontSize: "18px", marginTop: "-4px", textAlign: "center" }}>Payment Screen</div>
                                    </div>
                                    <div style={{ fontSize: "14px", textAlign: "center" }}>
                                        You have selected a paid sme. Please pay the amount to book a slot.
                                    </div>

                                    <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div></div>
                                        <Button variant="default" className="mt-1 prev-btn-sme" onClick={() => bookASlot(selectedDate)}>Submit</Button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                        <div style={{ marginBottom: "3.6rem" }}></div>
                        <BottomMenu />
                    </div>
            }

        </ >
    )
}
export default SmeEventData;

const CustomInput = ({ value, onClick }) => (
    <button className="btn" onClick={onClick}>
        <i class="fa fa-calendar" id="true" aria-hidden="true"></i>
        <span className="_picker">        {value}</span>
    </button>
);
function TimeRange({ time }) {
    const openingBracketIndex = time.indexOf(' (');
    const dayPart = openingBracketIndex !== -1 ? time.slice(0, openingBracketIndex) : time;
    const closingBracketIndex = openingBracketIndex !== -1 ? time.indexOf(')', openingBracketIndex) : -1;
    const displayTimePart = closingBracketIndex !== -1 ? time.slice(openingBracketIndex + 2, closingBracketIndex) : '';

    return (
        <div style={{ marginTop: "-3px", lineHeight: "13px" }}>
            <span style={{ fontSize: "11px" }}>{dayPart}</span><br />
            <span style={{ whiteSpace: "nowrap", fontSize: "11px" }}>{displayTimePart}</span>
        </div>
    );
}
