import React, { Component } from "react";
import {  Button } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import './Interests.css';
import { config } from '../Config/Config';
import back from '../images/backarrow.png';
import StarRatings from 'react-star-ratings';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default class SceemloreById extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardManagementData: [],
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            id: this.props.match.params.id,
            isLoading: false,
            redirect: false
        }
        this.goBack = this.goBack.bind(this)
    }
    goBack = () => {
        window.history.back();
    }
    componentDidMount() {
       // console.log("params", this.props)
        this.getCardManagementData();
    }
    getCardManagementData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "cardManagementId": this.props.match.params.id
        }
        var self = this
        axios.post(config.userUrl + 'user/getCardManagementData', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    self.setState({
                        cardManagementData: response.data.cardManagementData,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    userRegisterForSceemlore = (data) => {
        localStorage.setItem("title", data.title)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "managementId": this.props.match.params.id
        }
        var self = this
        axios.post(config.userUrl + 'user/userRegisterForSceemlore', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    self.setState({
                        redirect: true
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        const { cardManagementData, redirect, isLoading } = this.state;

        if (isLoading) {
          //  console.debug("renders: Loading...");
            return <div className="App"></div>;
        }
        if (redirect) {
          //  console.log("redirect1", this.state.redirect);
            return <Redirect
                to={{
                    pathname: `/thanksforregistering/${this.state.id}`
                }}
            />
        }
        return (
            <div >
                <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                <Link to="/sceemlore"><img alt="" src={back} className="sceemlore-back_arrow" /></Link>
                <div className="mt-2 ml-3 mr-3" >
                    {cardManagementData.map((data, i) => (
                        <div>
                            <div className="sceemlore-heading-title">{data.title}</div>
                            <div className="mt-2 s-cards">{data.topicName}</div>
                            <div className="mt-2 favbits-title-new">Description</div>
                            <div className="mt-2 s-cards">{data.description}</div>
                            <div className="sceemlore-admin">
                                <img alt="" src={config.userUrl + data.profilePicture} style={{ width: "50px" }} />
                                <div className="sceemlore-admin-name">{data.name}</div>
                                <div className="s-cards">{data.cards} Cards</div>
                                <div className="s-cards">{data.learners} Learners</div>
                                <div style={{ marginTop: "-8px" }}>
                                    <StarRatings
                                        rating={data.avgrating == null ? 0 : parseFloat(data.avgrating)}
                                        starRatedColor="#FF942C"
                                        numberOfStars={5}
                                        starDimension="20px"
                                        starSpacing="0"
                                    />
                                </div>
                            </div>
                            <Button variant="default" onClick={() => this.userRegisterForSceemlore(data)} className="card-register-btn">Register</Button>
                        </div>
                    ))}
                </div>
                <div className="mb-bottom"></div>
                <BottomMenu />
            </div >
        )
    }
}
