import axios from "axios";
import { config } from "../Config/Config";
import SimpleCrypto from "simple-crypto-js";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const updateNotificationEndpoint = (empId) => {
   // console.log("Employee", empId)
    if ('Notification' in window) {
        if (Notification.permission === "default") {
            Notification.requestPermission()
                .then(permission => {
                    if (permission === 'granted') {
                      //  console.log('Granted');
                        navigator.serviceWorker.ready
                            .then(registration => {
                                return registration.pushManager.subscribe({
                                    userVisibleOnly: true,
                                    applicationServerKey: config.publicKey
                                });
                            })
                            .then(subscription => {
                              //  console.log("subs endpoint", subscription);
                                addSubs(subscription, empId);
                            })
                            .catch(error => {
                                console.error('Error subscribing to push notifications:', error);
                            });
                    }
                });
        }
        else if (Notification.permission === "granted") {
           // console.log("Already granted");
            navigator.serviceWorker.ready
                .then(registration => {
                    return registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: config.publicKey
                    });
                })
                .then(subscription => {
                  //  console.log("subs endpoint", subscription);
                    addSubs(subscription, empId);
                })
                .catch(error => {
                    console.error('Error subscribing to push notifications:', error);
                })
        }
    }
}
const addSubs = (subscription, empId) => {
  //  console.log('Push Subscription:', subscription);
    let headers = {
        "Content-Type": 'application/json',
        "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("junkStore")),
        "applicationType": "mobile"
    }
    let payload = {
        "userId": empId,
        "endpoint": JSON.stringify(subscription)
    }
    axios.post(config.userUrl + 'user/updateEmpNotificationEndpoint', payload, { headers: headers })
        .then(response => {
          //  console.log("notification endpoints updated succesfully", response.data);
        }).catch(error => {
            console.error('Error loading Admins Meetings: ', error);
        });
}
export default updateNotificationEndpoint;