import React, { useState, useEffect } from "react";
import Select from "react-select";
import loginlogo from '../images/login-logo.png';
import { Link, Redirect } from 'react-router-dom';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import { Button, Col, Row } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function AskQuestions(props) {
    const [token, setToken] = React.useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')),);
    const [redirect, setRedirect] = React.useState(false);
    const [question, setQuestion] = React.useState('');

    // useEffect(() => {
    //     console.log("newrole", props)
    // }, []);
    const options = [
        {
            label: "Industry 4.0",
            options: [
                {
                    value: 1, label: 'Emerging Tech (IoT, 5G, AIML, AR/VR, 3D Printing, Cloud, Blockchain)', icon: "../Emerging Tech.png"
                },
                {
                    value: 2, label: 'Workforce 4.0 (Drones, Cobots, Robots)', icon: "../Workforce 4.0.png"
                },
                {
                    value: 3, label: 'Security (Cybersecurity, Info security, Forensics, RFID, QR Code)', icon: "../Security.png"
                },
                {
                    value: 4, label: 'Finance trends (DeFi, FinTech, Payments, Credit Risk, Crowd Financing, Trade)', icon: "../Finance trends.png"
                }

            ]
        },
        {
            label: "Go Green",
            options: [
                {
                    value: 5, label: 'Circular Supply', icon: "../Circular Supply.png"
                },
                {
                    value: 6, label: 'Circular Economy', icon: "../Circular Economy.png"
                },
                {
                    value: 7, label: 'Waste Disposal', icon: "../Waste Disposal.png"
                }
            ]
        },
        {
            label: "Leadership",
            options: [
                {
                    value: 8, label: 'Business Transform (M&A, Organisation Design, Strategic Sourcing)', icon: "../Business Transform.png"
                },
                {
                    value: 9, label: 'Models (Maturity, Integration, Lean, JIT, Kaizen)', icon: "../Models.png"
                },
                {
                    value: 10, label: 'Business Continuity (Risk Management, Disaster Management)', icon: "../Business Continuity.png"
                }
            ]
        },
        {
            label: "Fulfilment",
            options: [
                {
                    value: 11, label: 'Logistics (Optimisation, Wearhouse)', icon: "../Logistics.png"
                },
                {
                    value: 12, label: 'Procurement (Indirect, Direct, Planning, Forecasting, Inventory)', icon: "../Procurement.png"
                }
            ]
        },
        {
            label: "Legal",
            options: [
                {
                    value: 13, label: 'Regulations', icon: "../Regulations.png"
                },
                {
                    value: 14, label: 'Compliance', icon: "../Compliance.png"
                }
            ]
        },
        {
            label: "Governance",
            options: [
                {
                    value: 15, label: 'Management Office (Contract, Vendor, Program)', icon: "../Management Office.png"
                },
                {
                    value: 16, label: 'Audit (Internal & External)', icon: "../Audit.png"
                }
            ]
        },
        {
            label: "People",
            options: [
                {
                    value: 17, label: 'Growth Mindset (Learning & Development, Certification, Talent Globalisation)', icon: "../Growth Mindset.png"
                },
                {
                    value: 18, label: 'Workspace 4.0 (Safety, Security, WFH, Employee trends)', icon: "../Workspace 4.0.png"
                }
            ]
        },

        {
            label: "Global Trends",
            options: [
                {
                    value: 19, label: 'Smart Governance (Smart Cities, e-Government, e-Procurement, e-Tender)', icon: "../Smart Governance.png"
                },
                {
                    value: 20, label: 'Sharing Economy (Assets, Information, Standards, Markets, Skills, Talent)', icon: "../Sharing Economy.png"
                },
                {
                    value: 21, label: 'NextGen (Supply Chain as Service (SCaaS), Agile Practices, Global Supply Chain)', icon: "../NextGen.png"
                },
                {
                    value: 22, label: 'Localisation (MSME, Cottage, Large-scale)', icon: "../Localisation.png"
                }
            ]
        },
        {
            label: "Customer Centric",
            options: [
                {
                    value: 23, label: 'Last-mile delivery', icon: "../Last-mile delivery.png"
                },
                {
                    value: 24, label: 'Support resiliency', icon: "../Support resiliency.png"
                },
                {
                    value: 25, label: 'Flawless delivery', icon: "../Flawless delivery.png"
                }
            ]
        },
    ];
    const [selectedOptions, setSelectedOptions] = React.useState([]);


    const postQuestionDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        let selOptions = [];
        selOptions = selectedOptions.value
       // console.log("selOptions", selectedOptions, selOptions)
        var payload = {
            "topicId": [selectedOptions.value],
            "question": question
        }
       // console.log("payload", payload)
        axios.post(config.userUrl + 'user/postQuestionDetails', payload, { headers: headers })
            .then(function (response) {
              //  console.log(response);
                if (response.data.status == true) {
                    setRedirect(true)
                  //  console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };

   // console.log("selected", selectedOptions)
    if (redirect) {
        return <Redirect to="/home" />
    }
    return (
        <div>
            <div className="top_bar"><img src={loginlogo} className="login-logo" />
                {token ?
                    <Link to="/usernotifications"><i class="fa fa-bell fa-lg bell-icon" aria-hidden="true"></i></Link> : null}
            </div>
            <div className="ml-1 screen-topgap">
                <div className="your-fav"> Ask a Question</div>
                <div className="ml-3 mr-4">
                    <div className="mb-2"><b style={{ fontSize: "13.5px" }}>Select a topic for your question</b></div>
                    <Select
                        value={selectedOptions}
                        onChange={(e) => setSelectedOptions(e)}
                        options={options}
                        name="label"
                        styles={{
                            groupHeading: (base) => ({
                                ...base,
                                flex: '1 1',
                                color: 'black',
                                margin: 0,
                                fontSize: "15px",
                                fontWeight: 600,
                                background: "#ECECEC"
                            }),
                        }}
                        // formatGroupLabel={formatGroupLabel}
                        // defaultValue={options[1]}
                        // isOptionDisabled={() => selectedOptions.length >= 3}
                        getOptionLabel={e => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={e.icon} style={{ width: "25px" }} />
                                <span style={{ marginLeft: 5 }}>{e.label}</span>
                            </div>
                        )}
                        isSearchable={false}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </div>
            </div><br />
            <div className="role-position-new">
                <div className="ml-4"><b style={{ fontSize: "13.5px" }}>Question</b></div>
                <div className="form-group ml-4 mr-4">
                    <textarea type="text"
                        className="form-control"
                        aria-describedby="text"
                        rows="5"
                        value={question}
                        name="question"
                        onChange={(e) => setQuestion(e.target.value)}
                    />
                </div>
            </div>
            {/* <div className="form-group">
                <textarea type="text"
                    className="form-control"
                    aria-describedby="text"
                    rows="5"
                    value={question}
                    name="question"
                    onChange={(e) => setQuestion(e.target.value)}
                />
            </div> */}
            <center>{selectedOptions.length !== 0 && question.length !== 0 ? <Button variant="default" onClick={postQuestionDetails} style={{ position: "absolute", left: "30%", right: "30%", bottom: "5.5rem" }} className="_rolebtn_new">Submit</Button>
                : <Button variant="default" disabled={true} onClick={postQuestionDetails} style={{ position: "absolute", left: "30%", right: "30%", bottom: "5.5rem" }} className="_rolebtn_new">Submit</Button>}
            </center>

            <BottomMenu />
        </div>
    );
};
{/* <Link to="/terms"><span className="signup-termslink-color">Terms & Conditions </span></Link>and <Link to="/dataprivacy"><span className="signup-termslink-color">Privacy Policy</span></Link></span> */ }

// {((data.topicName === userTopics[0].topicName) || (data.topicName === userTopics[1].topicName) || (data.topicName === userTopics[2].topicName)) ?
//     <div>
//         <div>{description !== "" ?
//             <div ><Button variant="default" onClick={() => reviewPost(data.id, 1)} className="sendreview-btn mt-1 ml-4">Accept</Button><Button onClick={() => reviewPost(data.id, 0)} className="save-btn mt-1 ml-3" variant="default">Reject</Button></div> :
//             <div><Button variant="default" disabled="true" onClick={() => reviewPost(data.id, 1)} className="sendreview-btn mt-1 ml-4">Accept</Button><Button onClick={() => reviewPost(data.id, 0)} disabled="true" className="save-btn mt-1 ml-3" variant="default">Reject</Button></div>}
//         </div>
//     </div> :
//     <div className="float_Right">
//         <Button variant="default" disabled="true" onClick={() => reviewPost(data.id, 1)} className="sendreview-btn mt-1 ml-4">Accept</Button>
//         <Button onClick={() => reviewPost(data.id, 0)} disabled="true" className="save-btn mt-1 ml-3" variant="default">Reject</Button></div>
// }


{/* {termsAndCondition && selectedOptions.length !== 0 ?
                        <>
                            <Button variant="default" onClick={saveInterest} style={{ position: "absolute", left: "25%", right: "25%", bottom: "4.9rem" }} className="_rolebtn_new">Proceed</Button></>
                        : <><Button variant="default" disabled="true" onClick={saveInterest} style={{ position: "absolute", left: "25%", right: "25%", bottom: "4.9rem" }} className="_rolebtn_new">Proceed</Button>
                        </>} */}