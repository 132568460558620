import React, { Component } from "react";
import { Button, Card, Col } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import bluelogo from '../images/bluelogo.png';
import './Poll.css';
import moment from "moment-timezone";
import BottomMenu from "../BottomMenu/BottomMenu";
// import Poll from "./Poll.json";
// import { PollData, PollOutput } from './PollData';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import { AiOutlineShareAlt } from "react-icons/ai";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class PollQuestion extends Component {
    state = {
        token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
        userAnswer: null,
        currentIndex: 0,
        options: [],
        count: [],
        pollEnd: false,
        disabled: true,
        completed: 0,
        pollQuestion: [],
        pollId: this.props.match.params.id,
        isLoading: true,
        redirect: false,
        createdAt:""
    }
    // loadQuiz = () => {
    //     const { currentIndex, pollQuestion } = this.state
    //     this.setState(() => {
    //         return {
    //             question: pollQuestion[currentIndex].question,
    //             options: pollQuestion[currentIndex].options,
    //             topic: pollQuestion[currentIndex].topic
    //         }
    //     }
    //     )
    // }

    componentDidMount() {
       // console.log("poll-id", this.state.pollId)
        this.getPollById();
        // setInterval(() =>
        //     this.setState({ completed: (Math.floor(Math.random() * 100) + 1), 2000})
        // );
        // setInterval(() => {
        //     this.setState(prevState => {
        //         return {
        //             completed: (Math.floor(Math.random() * 100) + 1),
        //         };
        //     });
        // }, 2000);
    }

    getPollById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
       // console.log("token", this.state.token)
        var self = this
        axios.get(config.userUrl + `user/getPollById/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
               // console.log(response);
                if (response.data.status === true) {
                 //   console.log("success!!");
                    self.setState({
                        question: response.data.pollQuestion,
                        options: response.data.pollData,
                        topic: response.data.topicName,
                        createdAt:response.data.pollDetails[0].createdAt,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    sharePoll=(d)=>{
        if (navigator.share) {
            navigator.share({
                url: `https://www.sceem.org/poll/${d}/${this.props.match.params.id}`,
            }).then(() => {
               // console.log('Thanks for sharing!', d);
            }).catch(err => {
                console.log("Error while using Web share API:",err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }  
    }
    submitPoll = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${this.state.token}`,
            "applicationType": "mobile"
        }
        var payload = {
            "pollId": parseInt(this.state.pollId),
            "pollQuestionOptionId": this.state.userAnswer
        }
        var self = this
        axios.post(config.userUrl + 'user/submitPoll', payload, { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        redirect: true
                    })
                    //console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });

    };
    checkAnswer = answer => {
        const { options } = this.state
        // var opt = options.findIndex(op => {
        //     if (op.id === answer) { return true; }
        // });
        this.setState({
            userAnswer: answer,
            disabled: false
        })
    }

    finishHandler = () => {
        // if (this.state.currentIndex === PollData.length - 1) {
        //     this.setState({
        //         pollEnd: true
        //     })
        // }
        const { currentIndex } = this.state
        this.submitPoll();
        // this.setState(() => {
        //     return {
        //         question: PollOutput[currentIndex].question,
        //         options: PollOutput[currentIndex].options,
        //         count: PollOutput[currentIndex].count,
        //         uAns: PollOutput[currentIndex].uAns,
        //         pollEnd: true
        //     }
        // }
        // )

    }
    render() {

        const { isLoading, pollQuestion, redirect, question, options, count, topic, userAnswer, pollEnd,createdAt } = this.state
        if (isLoading) {
            return <div></div>
        }
       // console.log("userAnswer", userAnswer);
        if (redirect) {
            return <Redirect to={`/pollresults/${this.state.pollId}`} />
        }
        return (

            <div className="poll-bg"> <div >
                <div className="topblue_bar"><img alt="" src={bluelogo} className="login-logo" />
               </div>
                <div className="enable-center">
                    <div className="answers-card">
                        <div style={{display:"flex",justifyContent:"space-between",marginTop:"-1.5rem",marginLeft:"1.8rem",marginRight:"1.5rem"}}>
                        <center className="topic-top"><span className="topic-name">{topic.replace(/ *\([^]*\) */g, "")}</span></center>
                        <div className="poll-share-icon"> <AiOutlineShareAlt onClick={()=>this.sharePoll(moment(createdAt).format("YYYYMMDD"))}/></div></div>
                        <Col xs={12}>
                            <Card className="mt-3 poll-answer-card">
                                <div className="poll-question">{question}</div>
                                {options.map((option, idx) => {
                                    return (
                                        <div onClick={() => this.checkAnswer(option.id)} className={`polloptions-card ${userAnswer === option.id ? "selected" : null}`}><div className="poll-text">{option.option}</div></div>
                                    )
                                })}

                                {/* {options.map((option, id) => {
                                return (
                                    <Card key={option[id]} onClick={() => this.checkAnswer(option[id])} className={`polloptions-card ${userAnswer == option[id] ? "selected" : null}`}><div className="poll-text">{option[id].option}</div></Card>
                                )
                            })} */}
                                {/* <Card ><div className="poll-text">{options[0].option}</div></Card>
                            <Card ><div className="poll-text">{options[1].option}</div></Card>
                            <Card><div className="poll-text">{options[2].option}</div></Card>
                            <Card ><div className="poll-text">{options[3].option}</div></Card> */}
                                {/* <Card key={option[0].id} onClick={() => this.checkAnswer(option[0].id)} className={`polloptions-card ${userAnswer == option[0].id ? "selected" : null}`}><div className="poll-text">{options[0]}</div></Card>
                            <Card key={option[0].id} onClick={() => this.checkAnswer(option[0].id)} className={`polloptions-card ${userAnswer == option[0].id ? "selected" : null}`}><div className="poll-text">{options[1]}</div></Card>
                            <Card key={option[0].id} onClick={() => this.checkAnswer(option[0].id)} className={`polloptions-card ${userAnswer == option[0].id ? "selected" : null}`}><div className="poll-text">{options[2]}</div></Card>
                            <Card key={option[0].id} onClick={() => this.checkAnswer(option[0].id)} className={`polloptions-card ${userAnswer == option[0].id ? "selected" : null}`}><div className="poll-text">{options[3]}</div></Card> */}

                            </Card></Col>
                        {/* 
                    <Col xs={12}>
                        {Poll.poll.slice(0, 1).map((data, i) => {
                            return (
                                <Card className="poll-card">
                                    <div className="poll-question">{data.question}</div>
                                    {data.options.map((option, id) => {
                                        return (
                                            <Card key={id} onClick={() => this.checkAnswer(option)} className={`polloptions-card ${data.userAnswer == option ? "selected" : null}`}><div className="poll-text">{option}</div></Card>
                                        )
                                    })}
                                    <Card className={`polloptions-card ${data.userAnswer == data.options[i] ? "selected" : null}`}><div className="poll-text">{data.options[0]}</div></Card>
                                    <Card className="polloptions-card"><div className="poll-text">{data.options[1]}</div></Card>
                                    <Card className="polloptions-card"><div className="poll-text">{data.options[2]}</div></Card>
                                    <Card className="polloptions-card"><div className="poll-text">{data.options[3]}</div></Card>
                                    <br /><br />
                                </Card>
                            )
                        })}</Col> */}
                        <br />
                        <center><Button variant="default" className="mb-2 poll-submit-btn" onClick={this.submitPoll}><b> &nbsp;Submit &nbsp;</b></Button></center>
                    </div>
                </div>
            </div >
                <br /><br /><br />
                <BottomMenu />
            </div >
        )
    }
}


// import React, { Component } from "react";
// import { Button, ProgressBar, Nav, Card, Container, Col, Row, Image } from "react-bootstrap";
// import { Link, Redirect } from 'react-router-dom';
// import bluelogo from '../images/bluelogo.png';
// import advertisement from '../images/advertisement.png';
// import notification from '../images/Notification.png';
// import './Poll.css';
// import AuthorsJson from '../LandingPage/Authors.json';
// import BottomMenu from "../BottomMenu/BottomMenu";
// import Poll from "./Poll.json";
// import { PollData, PollOutput } from './PollData';
// import axios from 'axios';
// import { config } from '../Config/Config';

// export default class PollQuestion extends Component {
//     state = {
//         token: localStorage.getItem('token'),
//         userAnswer: null,
//         currentIndex: 0,
//         options: [],
//         count: [],
//         pollEnd: false,
//         disabled: true,
//         completed: 0,
//         pollQuestion: []
//     }
//     loadQuiz = () => {
//         const { currentIndex, pollQuestion } = this.state
//         this.setState(() => {
//             return {
//                 question: pollQuestion[currentIndex].question,
//                 options: pollQuestion[currentIndex].options,
//                 topic: pollQuestion[currentIndex].topic
//             }
//         }
//         )
//     }

//     componentDidMount() {
//         this.getPollById();
//         // setInterval(() =>
//         //     this.setState({ completed: (Math.floor(Math.random() * 100) + 1), 2000})
//         // );
//         // setInterval(() => {
//         //     this.setState(prevState => {
//         //         return {
//         //             completed: (Math.floor(Math.random() * 100) + 1),
//         //         };
//         //     });
//         // }, 2000);
//     }

//     getPollById = () => {
//         let headers = {
//             "Content-Type": 'application/json',
//             "Authorization": "Bearer " + this.state.token
//         }
//         var self = this
//         axios.get(config.userUrl + `user/getPollById/${this.props.match.params.id}`, { headers: headers })
//             .then(function (response) {
//                 console.log(response);
//                 if (response.data.status == true) {
//                     console.log("success!!");
//                     self.setState({
//                         question: response.data.pollData[0].question,
//                         options: response.data.pollData[0].options,
//                         topic: response.data.pollData[0].topic
//                     })
//                 } else {
//                     console.log(response.data)
//                 }
//             })
//             .catch(function (error) {
//                 console.log("Error");
//             });
//     };
//     submitPoll = () => {
//         let headers = {
//             "Content-Type": 'application/json',
//             "Authorization": "Bearer " + this.state.token
//         }
//         console.log("token", this.state.token)
//         var self = this
//         axios.post(config.userUrl + 'user/submitPoll', { headers: headers })
//             .then(function (response) {
//                 console.log(response);
//                 if (response.data.status == true) {
//                     console.log("success");
//                 } else {
//                     console.log(response.data)
//                 }
//             })
//             .catch(function (error) {
//                 console.log("Error");
//             });

//     };
//     checkAnswer = answer => {
//         const { options } = this.state
//         var opt = options.findIndex(op => {
//             if (op === answer) { return true; }
//         });
//         this.setState({
//             userAnswer: opt,
//             disabled: false
//         })
//     }

//     finishHandler = () => {
//         // if (this.state.currentIndex === PollData.length - 1) {
//         //     this.setState({
//         //         pollEnd: true
//         //     })
//         // }
//         const { currentIndex } = this.state
//         this.submitPoll();
//         // this.setState(() => {
//         //     return {
//         //         question: PollOutput[currentIndex].question,
//         //         options: PollOutput[currentIndex].options,
//         //         count: PollOutput[currentIndex].count,
//         //         uAns: PollOutput[currentIndex].uAns,
//         //         pollEnd: true
//         //     }
//         // }
//         // )

//     }
//     render() {

//         const { pollQuestion, question, options, count, topic, userAnswer, pollEnd } = this.state
//         if (pollEnd) {
//             return (
//                 <div> <div style={{ backgroundColor: "#31197C" }}>
//                     <div className="topblue_bar"><img alt="" src={bluelogo} className="login-logo" />
//                         <img alt="" src={notification} className="bell-icon" /> </div>
//                     <br /><br /><br /><br />
//                     <div className="answers-card">
//                         <center className="topic-top"><span className="topic">Topic </span><span className="topic-name">"{topic}"</span></center>
//                         <Col xs={12}>
//                             <Card className="mt-2 poll-answer-card">
//                                 <div className="poll-question">{question}</div>
//                                 <Progressbar id={0} selected={userAnswer} options={options[0]} percentage={count[0]} />
//                                 <Progressbar id={1} selected={userAnswer} options={options[1]} percentage={count[1]} />
//                                 <Progressbar id={2} selected={userAnswer} options={options[2]} percentage={count[2]} />
//                                 <Progressbar id={3} selected={userAnswer} options={options[3]} percentage={count[3]} />


//                                 {/* <Card className={`polloptions-card ${userAnswer === options[0] ? "isSelected" : null}`}><div className="poll-text">{options[0]} <span className="float-count">{count[0]}</span></div></Card>
//                                 <Card className={`polloptions-card ${userAnswer === options[1] ? "isSelected" : null}`}><div className="poll-text">{options[1]} <span className="float-count">{count[1]}</span></div></Card>
//                                 <Card className={`polloptions-card ${userAnswer === options[2] ? "isSelected" : null}`}><div className="poll-text">{options[2]} <span className="float-count">{count[2]}</span></div></Card>
//                                 <Card className={`polloptions-card ${userAnswer === options[3] ? "isSelected" : null}`}><div className="poll-text">{options[3]} <span className="float-count">{count[3]}</span></div></Card> */}

//                             </Card></Col>
//                         <Link to="/pollhome"> <center><Button variant="default" className="mt-3 goback-poll-btn"><b> &nbsp;Go Back to Polls &nbsp;</b></Button></center></Link>
//                     </div><br /></div >
//                     <br /><br />
//                     <BottomMenu />
//                 </div >
//             )
//         }
//         return (

//             <div> <div style={{ backgroundColor: "#31197C" }}>
//                 <div className="topblue_bar"><img alt="" src={bluelogo} className="login-logo" /></div>
//                 <br /><br /><br /><br />
//                 <center className="answers-card">
//                     <div className="topic-top"><span className="topic">Topic </span><span className="topic-name">{topic}</span></div>
//                     <Col xs={12}>
//                         <Card className="mt-2 poll-answer-card">
//                             <div className="poll-question">{question}</div>
//                             {options.map((option, id) => {
//                                 return (
//                                     <Card key={id} onClick={() => this.checkAnswer(option)} className={`polloptions-card ${userAnswer == id ? "selected" : null}`}><div className="poll-text">{option}</div></Card>
//                                 )
//                             })}
//                         </Card></Col>
//                     {/*
//                     <Col xs={12}>
//                         {Poll.poll.slice(0, 1).map((data, i) => {
//                             return (
//                                 <Card className="poll-card">
//                                     <div className="poll-question">{data.question}</div>
//                                     {data.options.map((option, id) => {
//                                         return (
//                                             <Card key={id} onClick={() => this.checkAnswer(option)} className={`polloptions-card ${data.userAnswer == option ? "selected" : null}`}><div className="poll-text">{option}</div></Card>
//                                         )
//                                     })}
//                                     <Card className={`polloptions-card ${data.userAnswer == data.options[i] ? "selected" : null}`}><div className="poll-text">{data.options[0]}</div></Card>
//                                     <Card className="polloptions-card"><div className="poll-text">{data.options[1]}</div></Card>
//                                     <Card className="polloptions-card"><div className="poll-text">{data.options[2]}</div></Card>
//                                     <Card className="polloptions-card"><div className="poll-text">{data.options[3]}</div></Card>
//                                     <br /><br />
//                                 </Card>
//                             )
//                         })}</Col> */}
//                     <Button variant="default" className="mt-3 mb-2 poll-submit-btn" onClick={this.submitPoll}><b> &nbsp;Submit &nbsp;</b></Button>
//                 </center><br /></div >
//                 <br /><br /><br />
//                 <BottomMenu />
//             </div >
//         )
//     }
// }

// const Progressbar = (props) => {
//     const { percentage, options, selected, id } = props;

//     const containerStyles = {
//         height: 50,
//         width: '80%',
//         backgroundColor: "white",
//         borderRadius: 10,
//         margin: 10,
//         border: "2px solid lightgrey"
//     }
//     const fillerStyles = {
//         height: '100.5%',
//         width: `${percentage}%`,
//         backgroundColor: "#EEEBFF",
//         transition: 'width 1s ease-in-out',
//         border: "1px solid #b0a4f5",
//         borderRadius: 8,
//         marginTop: '-0.09px',
//         textAlign: 'right'
//     }
//     const isSelected = {
//         border: "2px solid #DB6C00",
//         height: 50,
//         width: '80%',
//         backgroundColor: "white",
//         borderRadius: 10,
//         margin: 10
//     }
//     const labelStyles = {
//         color: 'black',
//         borderColor: "#362B79",
//         fontWeight: 600
//     }
//     return (
//         <div style={selected === id ? isSelected : containerStyles}><span style={{ marginTop: "8px", marginRight: "4px", fontWeight: "600", float: "right" }}>{`${percentage}%`}</span>
//             <div style={fillerStyles} >
//                 <span style={labelStyles} ><span style={{ marginLeft: "5px", marginTop: "8px", marginBottom: "8px", float: "left" }}>{options}</span></span>
//             </div>
//         </div>
//     );
// };
