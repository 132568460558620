import React, { Component } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import loginlogo from '../images/login-logo.png';
import PricingData from "./PricingData.json";
import BottomMenu from "../BottomMenu/BottomMenu";
import './Pricing.css';
import { Link, Redirect } from 'react-router-dom';
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
// import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "../LandingPage/arrowsOnBottomOrTop.css";
import "../LandingPage/hideScrollbar.css";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class CurrentPricingPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            plans: [],
            navigate: false,
            planName: "",
            pricingPlan: false
        }

    }
    componentDidMount() {
        this.getSubscription();
    }
    getSubscription = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getSubscription', { headers: headers })
            .then(function (response) {
                //.log("res", response)
                if (response.data.status === true) {
                    if (response.data.message == "free plan") {
                        self.setState({
                            pricingPlan: false
                        })
                    } else {
                        self.setState({
                            pricingPlan: true,
                            plans: response.data.subscriptionHistory
                        })
                    }
                } else {
                    console.log("error")
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    render() {
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>

                <div className="screen-topgap">
                    <Row style={{ width: "100%" }}>
                        {this.state.plans.map((data, id) => {
                            return (
                                <Col xs={12} className="pricing-ml">
                                    <div><span className="your-jitbits">Current Subscription</span> ({data.endDate.split("T")[0].split("-").reverse().join("-")})</div><br /><br /><br /><br />
                                    <Card className="no-scroll-pricing ">
                                        <center className="free">{data.planName}<br /></center>
                                        {data.planName == "Integrator" ? <div>
                                            <div className="mt-4"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Anticipator Plus</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Register as supplier/customer</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Product and services page</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Request for suppliers</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Request for product/services</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Advertise for 6 times in an year</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Share contact with selected</span></div>
                                        </div> : (data.planName == "Anticipator" ? <div>
                                            <div className="mt-4"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Read, Write and Review articles</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Connect and Follow authors</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Download your favourite articles</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Answer to polls</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Share an opportunity</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Promote your CV</span></div>
                                        </div> : (data.planName == "Collaborator" ? <div>
                                            <div className="mt-4"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Integrator Plus</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Connect with experts</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Create groups for initiatives</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Product promotions</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Product whitepapers</span></div>
                                            <div className="mt-2"><AiOutlineCheck className="ml-1 react-icon" /><span className="ml-1 pricing-inner-text">Search on keywords</span></div>
                                        </div>
                                            : <div>
                                                <div className="mt-4"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Collaborator Plus</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Open APIs for integration</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Investment opportunity</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Business consultancy</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Technology consultancy</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Product consultancy</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Global trends</span></div>
                                                <div className="mt-2"><AiOutlineCheck className="ml-2 react-icon" /><span className="ml-2 pricing-inner-text">Whitepapers and marketing data</span></div>
                                            </div>))}

                                        <center className="money-color"><span className="mt-1 rupee">&#8377;</span> {data.planAmount}/year</center>
                                    </Card><br />
                                    <center className="start ml-3 mt-3"><Button disabled={true} variant="default" className="get-btn">Bought</Button></center>

                                </Col>
                            )
                        })}
                    </Row>
                </div ><br />
                < BottomMenu />
            </div >
        )
    }
}
